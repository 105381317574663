import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils  from "../utils";
import { TextField } from "@mui/material";

class AltenarBetdetails extends React.Component {

  constructor(props) {

    super(props);
    this.altenarSession = {};
    this.config = { server: {} };

    this.state = { barcode: "", bet: {} };

    this.adminClient = new AdminClient();
    
    this.handlePrint = this.handlePrint.bind(this);
    this.goBack = this.goBack.bind(this);

  }

  goBack(evt) {
    this.props.history.goBack();
  }

  componentDidMount() {
    
    console.log("Calimaco: AltenarBetdetails : game " + utils.getSessionItem("current-game"));

    let promise = this.adminClient.getAgentBetDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), utils.getSessionItem("current-game"));
    let thisAux=this;
    promise.then(
      function (response) {
        console.log("Calimaco: AltenarBetdetails : getAgentBetDetails: data " + JSON.stringify(response.data));
        thisAux.setState({bet:response.data});
      },
      function (err) {
        console.log("calimaco: AltenarBetdetails: getAgentBetDetails result: " + JSON.stringify(err));
      });

  }


  handlePrint() {
    window.open("/agents/altenar_print?game="+utils.getSessionItem("current-game"), "_blank");
  }


  render() {
    const { t } = this.props;

    return (
      <div className="container `m-2">

        <div className="row  m-1" id="ticket_details">
          <div className="card ">
            <div className="card-title m-2">

            </div>
            <div className="card-body m-0 p-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.game }} disabled id="bet_game" label={t("Ticket id")} type="text" value={this.state.bet.game} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.code }} disabled id="bet_code" label={t("Bet code")} type="text" value={this.state.bet.code} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.status }} disabled id="betstatus" label={t("Bet status")} type="text" value={this.state.bet.status} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.ticket_status }} disabled id="ticketstatus" label={t("Ticket status")} type="text" value={this.state.bet.ticket_status} />
                </div>
                <div className="col-12 col-md-4">
                  <TextField InputLabelProps={{ shrink: this.state.bet.odds }} disabled id="ticket_odds" label={t("Odds")} type="text" value={this.state.bet.odds} />
                </div>
                <div className="col-12 col-md-4">
                  <TextField InputLabelProps={{ shrink: this.state.bet.wager }} disabled id="ticket_wager" label={t("Wager")} type="text" value={utils.formatCurrency(this.state.bet.wager / 100, this.state.bet.currency)} />
                </div>
                  <div className="col-12 col-md-4">
                    <TextField InputLabelProps={{ shrink: this.state.bet.total_prize }} disabled id="ticket_winning" label={t("Total prize")} type="text" value={utils.formatCurrency(this.state.bet.total_prize / 100, this.state.bet.currency)} />
                  </div>
                  <>
                    <div className="col-12  float-center" >
                      <button type="button" className="btn btn-primary" onClick={this.handlePrint}>{t("Print") }</button>
                      <button type="button" className="btn btn-secondary" onClick={this.goBack}>{t("Close")}</button>
                    </div>
                  </>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}



export default withTranslation()(withRouter(AltenarBetdetails))

import { TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import BasicSelect from '../../components/basic/BasicSelect'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from '../../utils';
import ProgressBar from '../../components/basic/ProgressBar'
import UploaderField from '../../components/basic/UploaderField';
import useFormValidation from '../../hooks/parsleyValidation'


function UsersUpdateBatch(props) {
    const { t } = props;
    const { formValidation } = useFormValidation("Form_usersBatch"); // Mover el hook aquí
    const company = utils.getCurrentCompany().company;
    const companyDetails = utils.getCurrentCompany(company);
    const session = JSON.parse(utils.getSessionItem("user")).session
    const [configPlayerData, setConfigPlayerData] = useState({ noEditFields: [] })
    const [userBatch, setUserBatch] = useState({
        verified: "-1",
        regulatory_status: "-1",
        deposits_disabled: "-1",
        payouts_disabled: "-1",
        play_disabled: "-1",
        promotions_disabled: "-1",
        agent: "-1",
        note: "",
        note_title: "",
        department: "",
        players: ""
    })

    const [barProgress, setBarProgress] = useState({ show: false, text: "", percentage: "0%", textOK: false, textNOK: false })
    const [onProgress, setOnProgress] = useState(false);

    useEffect(() => {
        let adminClient = new AdminClient();
        adminClient.getConfiPlayerData(session, company).then((response) => {
            setConfigPlayerData(response.data)
        })

    }, [])


    const onChangeHandler = (evt) => {
        let evtInfo;
        if (evt.target !== undefined) {
            evtInfo = evt.target;
        }
        else {
            evtInfo = evt;
        }
        const { name, value } = evtInfo;

        setUserBatch((prevState) => ({ ...prevState, [name]: value }));

    }

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)

        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = percentage + '%';
            auxBarProgress.text = t(text) + ": " + data.partial + "/" + data.total;
            if (data.textOK) auxBarProgress.textOK = t("Users correct") + ": " + data.textOK
            if (data.textNOK) auxBarProgress.textNOK = JSON.parse(data.textNOK).length !== 0 ? t("Users errors") + ": " + data.textNOK : false
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });

    };

    const sendBatch = (file) => {
        setOnProgress(true);
        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = '0%'
            auxBarProgress.text = t('Updating users')
            auxBarProgress.textOK = false;
            auxBarProgress.textNOK = false;
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });
        const { t } = props;
        let adminClient = new AdminClient();
        let players = userBatch.players.split(/\r?\n/);
        let promise = adminClient.usersUpdatetBatch(session,
            utils.getSessionItem("Company"),
            players, userBatch.verified, userBatch.regulatory_status, userBatch.deposits_disabled, userBatch.payouts_disabled, userBatch.play_disabled,
            userBatch.promotions_disabled, userBatch.agent, userBatch.note, userBatch.note_title, userBatch.department,
            handleResponse, file);

        promise.then(
            function (msg) {
                setBarProgress(prevBarProgress => {
                    let auxBarProgress = { ...prevBarProgress }
                    auxBarProgress.percentage = '100%'
                    auxBarProgress.show = true;
                    auxBarProgress.text = t('All players updated')
                    const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                    return updatedBarProgress;
                });
                setOnProgress(false);
            }
            , function (err) {
                props.showAlert(t("Users update"), <p>{t("Error: ") + err.message}</p>);
                setOnProgress(false);
            });

    }
    const handleSend = () => {
        if (formValidation.validate()) {
            var players = userBatch.players.split(/\r?\n/);
            props.showAlert(t("Players update"), <p>{t("We are going to process") + " " + players.length + " " + t("players_m")}</p>,
                [<button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Cancel")}</button>,
                <button type="button" className="btn btn-primary"
                    onClick={(evt) => {
                        sendBatch();
                        props.handleCloseAlert()
                    }}>{t("Ok")}</button>]
            );
        }

    }


    const dataHeader = {
        backLink: "",
        title: t("User Batch"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_usersBatch">
                <div className="row">
                    {!configPlayerData.noEditFields.includes("verified") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Verified")}
                                idProperty="verified"
                                name="verified"
                                data="verified"
                                addOpts={[{ verified: "-1", name: t("No change") }, { verified: "0", name: t("Unverified") }, { verified: "1", name: t("Verified") }]}
                                onChange={onChangeHandler}
                                value={userBatch.verified}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("regulatory_status") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Status")}
                                idProperty="status"
                                name="regulatory_status"
                                data="status"
                                basicInfoName="RegulatoryStatus"
                                addOpts={[{ status: "-1", name: t("No change") }]}
                                onChange={onChangeHandler}
                                value={userBatch.regulatory_status}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("deposits_disabled") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Deposits enabled")}
                                idProperty="deposits"
                                name="deposits_disabled"
                                data="deposits"
                                addOpts={[{ deposits: "-1", name: t("No change") }, { deposits: "0", name: t("No") }, { deposits: "1", name: t("Yes") }]}
                                onChange={onChangeHandler}
                                value={userBatch.deposits_disabled}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("payouts_disabled") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Payouts enabled")}
                                idProperty="payouts"
                                name="payouts_disabled"
                                data="payouts"
                                addOpts={[{ payouts: "-1", name: t("No change") }, { payouts: "0", name: t("No") }, { payouts: "1", name: t("Yes") }]}
                                onChange={onChangeHandler}
                                value={userBatch.payouts_disabled}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("play_disabled") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Play enabled")}
                                idProperty="play"
                                name="play_disabled"
                                data="play"
                                addOpts={[{ play: "-1", name: t("No change") }, { play: "0", name: t("No") }, { play: "1", name: t("Yes") }]}
                                onChange={onChangeHandler}
                                value={userBatch.play_disabled}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("promotions_disabled") &&
                        <div className="col-6">
                            <BasicSelect
                                label={t("Promotions enabled")}
                                idProperty="promotion"
                                name="promotions_disabled"
                                data="promotion"
                                addOpts={[{ promotion: "-1", name: t("No change") }, { promotion: "0", name: t("No") }, { promotion: "1", name: t("Yes") }]}
                                onChange={onChangeHandler}
                                value={userBatch.promotions_disabled}
                            />
                        </div>}
                    {!configPlayerData.noEditFields.includes("agent") &&
                        <div className='col-6'>
                            <BasicSelect
                                idProperty="user"
                                name="agent"
                                label={t("Agent")}
                                addOpts={[{ user: "-1", name: t("No change") }]}
                                basicInfoName="Agents"
                                onChange={onChangeHandler}
                                value={userBatch.agent}
                            //multiple
                            />
                        </div>}
                    <div className="col-12">
                        <TextField type="text" required={companyDetails.config?.backoffice?.editPlayer?.note === "true"}
                            name="note_title" label={t("Note title")}
                            onChange={onChangeHandler} value={userBatch.note_title}
                        > </TextField>
                    </div>
                    <div className="col-12">
                        <BasicSelect
                            label={t("Departments")}
                            idProperty="department"
                            name="department"
                            data="departments"
                            basicInfoName="Departments"
                            onChange={onChangeHandler}
                            value={userBatch.department}
                            required={companyDetails.config?.backoffice?.editPlayer?.note === "true"}

                        />
                    </div>
                    <div className="col-12">
                        <TextField type="text" name="note" multiline label={t("Note body")} onChange={onChangeHandler} value={userBatch.note}
                            required={companyDetails.config?.backoffice?.editPlayer?.note === "true"}
                        > </TextField>
                    </div>
                    <div className="col-12">
                        <TextField type="text" name="players" multiline label={t("Players")} onChange={onChangeHandler} value={userBatch.players}
                            placeholder="db.player1&#10;db.player2&#10;db.player3 "
                        ></TextField>
                    </div>

                    <div className="col-12 ">
                        <button type="button" className="btn btn-primary" disabled={onProgress} onClick={handleSend}>{t("Send")}</button>
                        <UploaderField
                            label="CSV"
                            acceptedFiles=".csv"
                            showAlert={props.showAlert}
                            handleCloseAlert={props.handleCloseAlert}
                            callBackFunction={sendBatch}
                            disabled={onProgress}
                        />
                    </div>

                    {barProgress.show &&
                        <ProgressBar percentage={barProgress.percentage}
                            text={barProgress.text}
                            textOK={barProgress.textOK}
                            textNOK={barProgress.textNOK}
                        />
                    }

                </div>
            </form>
        </LayoutSection>
    )
}

export default withTranslation()(UsersUpdateBatch)
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BasicSelect from '../../components/basic/BasicSelect';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { TextField } from '@mui/material';
import ProgressBar from '../../components/basic/ProgressBar';
import useFormValidation from '../../hooks/parsleyValidation';
import AdminClient from '../../AdminClient';
import utils from "../../utils";
import UploaderField from '../../components/basic/UploaderField';

const PromotionsCancel = (props) => {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;

    const { formValidation } = useFormValidation('Form_batch_promotion')

    const [batchDetails, setBatchDetails] = useState({
        promotion: "",
        player_list: ""
    })
    const [progressBar, setProgressBar] = useState({
        show: false,
        percentage: '0%',
        text: t('Processing')
    })

    const goBack = () => {
        props.history.goBack();
    }
    const dataHeader = {
        backLink: goBack,
        title: t('Cancel promotions'),
        urlHelp: '',
        idDoc: props.idDoc
    }
    const onChangeHandler = (evt) => {
        const { name, value } = evt.target || evt;
        setBatchDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)
        let textNOkFormatted;
        if (data.textNOK !== undefined) {
            textNOkFormatted = JSON.parse(data.textNOK).map(item => `Data: ${item.userId}, Description: ${item.error}`).join(' || ');
        }

        setProgressBar({
            show: true,
            percentage: percentage + '%',
            text: t(text) + ": " + data.partial + "/" + data.total,
            textOK: t("Users correct") + ": " + data.textOK,
            textNOK: t("Users with errors") + ": " + textNOkFormatted
        })
    }

    const update = () => {
        if (formValidation.validate()) {
            let players = batchDetails.player_list.split(/\r?\n/);
            let playersFiltrado = players.filter(elemento => elemento.trim() !== '');
            send(playersFiltrado);
        }
    }
    const send = (data) => {
        let file = undefined;
        let players;
        if (!Array.isArray(data)) {
            file = data
        }
        else {
            players = data;
        }

        adminClient.cancelPromotion(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem("Company"),
            batchDetails.promotion,
            JSON.stringify(players),
            file,
            handleResponse
        ).then(
            function (msg) {
                if (msg.result === 'OK') {
                    props.showAlert(t("Batch promotion"), t("Process Executed"))
                    setProgressBar((prevState) => ({ ...prevState, text: t("Process Executed") }));

                } else {
                    props.showAlert(t("Batch promotion"), t("Processing Error") + msg.description)
                }
            },
            function (err) {
                props.showAlert(t("Batch promotion"), t("Processing Error") + err.description)
            });

    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_batch_promotion">

                <div className="row">
                    <div className="col-12">
                        <BasicSelect
                            idProperty='promotion'
                            nameProperty='promotion'
                            basicInfoName='Promotions'
                            value={batchDetails.promotion}
                            name='promotion'
                            label={t('Promotions')}
                            onChange={onChangeHandler}
                            multiple={false}
                            noEmptyValue={true}
                            disableClearable={false}
                        />
                    </div>

                    <div className="col-12">
                        <TextField multiline placeholder="db, player1, playerPromotionId&#10;db, player2, playerPromotionId&#10;db, player3, playerPromotionId"
                            label={t("Players list")} name="player_list" type="text"
                            onChange={onChangeHandler} value={batchDetails.player_list}
                            required
                        />
                    </div>
                </div>
            </form>

            <div className="row">
                {utils.isGranted('SAVE_PROMOTION_CANCEL') &&

                    <div className="col-12">
                        <UploaderField
                            label="CSV"
                            acceptedFiles=".csv"
                            showAlert={props.showAlert}
                            handleCloseAlert={props.handleCloseAlert}
                            callBackFunction={send}
                        />
                        <button className="btn btn-primary" onClick={update}>{t("Execute")}</button>
                        {progressBar.show &&
                            <ProgressBar percentage={progressBar.percentage} text={progressBar.text} textOK={progressBar.textOK} textNOK={progressBar.textNOK} />
                        }

                    </div>
                }
            </div>
        </LayoutSection>
    );
};
export default withTranslation()(PromotionsCancel)

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tab from '../../components/basic/BasicTabs';
import Report from '../../components/basic/Report';
import AgentsMenu from '../../components/AgentsMenu';
function Activity(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const user = JSON.parse(utils.getSessionItem("user")).user;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_AgentsActivity')

    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        title: t('Agents activity'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    const [tabsContent, setTabsContent] = useState([
        {
            title: t("My tokens"),
            content: <Report reportCSS="min-vh-auto" windowRef="windowRefAgentAccounts" reportId="agent_accounts" session={session}  ></Report>
        },
        {
            title: t("Tokens activity"),
            content: <Report windowRef="windowRefAgentOperations" reportId="agent_operations" session={session}  ></Report>
        }
    ]);

    useEffect(function () {
        let tabAux = [
            {
                title: t("My tokens"),
                content: <Report reportCSS="min-vh-auto" windowRef="windowRefAgentAccounts" reportId="agent_accounts" session={session}  ></Report>
            },
            {
                title: t("Tokens activity"),
                content: <Report windowRef="windowRefAgentOperations" reportId="agent_operations" session={session}  ></Report>
            }
        ]
        setTabsContent(tabAux);
    }, []);

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <div className='col-12'>
                    <Tab active={0}>
                        {
                            tabsContent.map((tab, idx) =>
                                <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                    {tab.content}
                                </Tab.TabPane>
                            )
                        }
                    </Tab>
                </div>

                {/* <Report className="no-min-height mb-4" windowRef="windowRefAgentSummary" reportId="agents_detailed_summary" session={session}  ></Report>
            <Report className="no-min-height mb-4" windowRef="windowRefAgentSubAgentesSummary" reportId="agents_subagents_summary" session={session}  ></Report> */}

            </LayoutSection>
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(Activity);
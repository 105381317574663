import { Alert, InputAdornment, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import BasicSelect from '../../components/basic/BasicSelect'
import useFormValidation from '../../hooks/parsleyValidation';
import Report from '../../components/basic/Report'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

function JackpotDetails(props) {



    let jackpotId = utils.getSessionItem("current-category,jackpot").split("_")[1];
    let category = utils.getSessionItem("current-category")
    const { formValidation } = useFormValidation("Form_jackpotDetails")

    const [jackpotDetail, setJackpotDetail] = useState({ category: category, jackpot: jackpotId, name: "", status: "ENABLED", amount: 0, period: 0, min_amount: 0, max_amount: 0, contribution: 0, multiplier: 100, next_amount: 0, next_amount_percentage: 0, jackpotMultiplier: 0, betAmount: 0 });

    const [addOrUpdate, setAddOrUpdate] = useState("add");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    const company = utils.getCurrentCompany().company;

    let adminClient = new AdminClient();

    useEffect(function () {
        if (category === '-1') {
            props.history.push('/home');
        }
        if (jackpotId !== undefined && jackpotId !== "-1") {
            adminClient.getJackpotDetails(session, company, JSON.stringify(jackpotDetail)).then((res) => {
                let info = res.data[0];
                info['jackpotMultiplier'] = (100 * info.multiplier * info.contribution * 100) / 1000000
                info['betAmount'] = info['period'] / info['jackpotMultiplier']
                setJackpotDetail(info);
                let filter = [
                    { "field": "jackpot", "operator": "=", "value": jackpotId },
                    { "field": "category", "operator": "=", "value": category }
                ];
                utils.setSessionItem("ReportFilter-jackpot_winnings", JSON.stringify(filter));
                utils.setSessionItem("ReportFilterView-jackpot_winnings", JSON.stringify([]));
                setAddOrUpdate("update");
            })
        }
        else {
            adminClient.getJackpotCurrencies(session, company).then((res) => {
                let currenciesArray = res.data;
                let base_currency = utils.getCurrentCompany().base_currency;
                const base_jackpot_currency = currenciesArray.find(obj => obj.currency === base_currency);
                setJackpotDetail((prevCategory) => ({ ...prevCategory, multiplier: base_jackpot_currency.multiplier }));
            })
        }
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let newJackpotInfo = { ...jackpotDetail };
        newJackpotInfo[name] = value;
        setJackpotDetail(newJackpotInfo);
    };


    const onChangeHandlerJackpotContribution = (e) => {
        let { name, value } = e.target || e;
        let newJackpotInfo = { ...jackpotDetail };
        if (value != 0) {
            value = value * 100
        }
        newJackpotInfo[name] = value;
        newJackpotInfo['jackpotMultiplier'] = (100 * newJackpotInfo.multiplier * value * 100) / 1000000
        newJackpotInfo['period'] = newJackpotInfo['betAmount'] * newJackpotInfo['jackpotMultiplier']
        setJackpotDetail(newJackpotInfo);
    }

    const onChangeHandlerJackpotBetAmount = (e) => {
        let { name, value } = e.target || e;
        let newJackpotInfo = { ...jackpotDetail };
        newJackpotInfo['betAmount'] = value;
        newJackpotInfo['period'] = newJackpotInfo['betAmount'] * newJackpotInfo['jackpotMultiplier']
        setJackpotDetail(newJackpotInfo);
    }
    const onChangeAmountsHandler = (e) => {
        let { name, value } = e.target || e;
        let newJackpotInfo = { ...jackpotDetail };
        if (value != 0) {
            value = value * 100 * jackpotDetail.multiplier
        }
        newJackpotInfo[name] = value;
        setJackpotDetail(newJackpotInfo);
    }

    const handleDelete = () => {
        let promise = adminClient.deleteJackpot(
            session,
            company,
            JSON.stringify(jackpotDetail)
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t("Jackpot config"), t("Deleted succesfull"))
                    goBack();
                } else {
                    props.showAlert(t("Jackpot config"), t("Deleting error") + msg.description)
                }
            }, function (err) {
                console.log("Save operation error " + err.code);
                props.showAlert(t("Jackpot config"), t("Deleting error") + t(""))
            });
    };

    const handleSave = () => {

        if (formValidation.validate()) {
            let promise = adminClient[addOrUpdate + "Jackpot"](session, company, JSON.stringify(jackpotDetail))

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Jackpot config"), t("Saved succesfull"))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        let filter = [
                            { "field": "jackpot", "operator": "=", "value": jackpotDetail.jackpot },
                            { "field": "category", "operator": "=", "value": jackpotDetail.category }
                        ];
                        utils.setSessionItem("ReportFilter-jackpot_winnings", JSON.stringify(filter));
                        utils.setSessionItem("ReportFilterView-jackpot_winnings", JSON.stringify([]));
                    } else {
                        props.showAlert(t("Jackpot config"), t("Saving error") + msg.description)
                    }
                }, function (error) {
                    props.showAlert(t("Jackpot config"), t("Saving error") + error.description)
                });
        }
    };

    const goBack = () => {
        props.history.push('/marketing/jackpots/categories_details')
    }

    const getJackpotResult = () => {

        let jackpotResultMessage = t("Jackpot Result")
        return jackpotResultMessage.
            replace(/<VOLUMEN DE APUESTAS>/, jackpotDetail.betAmount).
            replace(/<APUESTA DE UN>/, utils.formatCurrency(1, utils.getCurrentCompany().base_currency)).
            replace(/<PREMIO MEDIO>/, utils.formatCurrency(((jackpotDetail.period * jackpotDetail.contribution) / (jackpotDetail.jackpotMultiplier * 10000)), utils.getCurrentCompany().base_currency)).
            replace(/<UN MILLON>/, utils.formatCurrency(1000000, utils.getCurrentCompany().base_currency)).
            replace(/<VECES JACKPOT>/, 1000000 * jackpotDetail.jackpotMultiplier / jackpotDetail.period)

    }
    const { t } = props
    const dataHeader = {
        backLink: goBack,
        title: t("Jackpot config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_jackpotDetails">
                <div className="row">
                    <div className="col-12 col-md-3  ">
                        <TextField
                            label={t("ID")}
                            required
                            disabled={addOrUpdate === 'update'}
                            inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            name="jackpot"
                            type="text"
                            value={jackpotDetail.jackpot}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-6 ">
                        <TextField
                            label={t("Name")}
                            name="name"
                            required
                            type="text"
                            value={jackpotDetail.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-3 ">
                        <BasicSelect
                            idProperty="name"
                            name="status"
                            label={t("Status")}
                            basicInfoName="EnabledDisabled"
                            onChange={onChangeHandler}
                            value={jackpotDetail.status}
                            translate={{ property: "name", prefix: "" }}
                            disableClearable
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-1 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit"><strong>{t("Contribution")}</strong></Typography>
                                    <em>{t('Contribution title help')}</em><br />{t('Contribution content help')}
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Contribution")}
                                name="contribution"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { "data-parsley-min": 0 }
                                }}
                                value={jackpotDetail.contribution / 100}
                                onChange={onChangeHandlerJackpotContribution}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className="col-12 col-md-3 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit"><strong>{t("Bet Amount")}</strong></Typography>
                                    <em>{t('Period title help')}</em><br />{t('Period content help')}
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Bet Amount")}
                                name="betAmount"
                                type="number"
                                value={(jackpotDetail.betAmount)}
                                onChange={onChangeHandlerJackpotBetAmount}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className="col-12 col-md-3 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t("Max amount jackpot")}</Typography>
                                    <em>{t("Max amount jackpot title help")}</em><br />{t("Max amount jackpot content help")}
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Max amount jackpot")}
                                name="max_amount"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>,
                                    inputProps: {
                                        "data-parsley-min": jackpotDetail.min_amount / (jackpotDetail.multiplier * 100),
                                        "data-parsley-min-message": t('Max amount validation message')
                                    }
                                }}
                                value={jackpotDetail.max_amount / (jackpotDetail.multiplier * 100)}
                                onChange={onChangeAmountsHandler}
                                onBlur={() => {
                                    formValidation.validate()
                                }}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className="col-12 col-md-3 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t("Min amount jackpot")}</Typography>
                                    <em>{t("Min amount jackpot title help")}</em><br />{t("Min amount jackpot content help")}
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Min amount jackpot")}
                                name="min_amount"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>,
                                    inputProps: {
                                        "data-parsley-max": jackpotDetail.max_amount / (jackpotDetail.multiplier * 100),
                                        "data-parsley-max-message": t('Min amount validation message')
                                    }
                                }}
                                value={jackpotDetail.min_amount / (jackpotDetail.multiplier * 100)}
                                onChange={onChangeAmountsHandler}
                                onBlur={() => {
                                    formValidation.validate()
                                }}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className="col-12 col-md-2 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t("Next amount %")}</Typography>
                                    <em>{t("Next amount % title help")}</em><br />{t("Next amount % content help")}
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Next amount %")}
                                name="next_amount_percentage"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { "data-parsley-min": 0 }
                                }}
                                value={jackpotDetail.next_amount_percentage}
                                onChange={onChangeHandler}
                            />
                        </HtmlTooltip>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t("Amount")}</Typography>
                                    <em>{t("Amount jackpot help")}</em>
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Amount")}
                                name="amount"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>
                                }}
                                value={jackpotDetail.amount / (jackpotDetail.multiplier * 100)}
                                onChange={onChangeAmountsHandler}
                            />
                        </HtmlTooltip>
                    </div>
                    <div className="col-12 col-md-3 ">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{t("Next amount")}</Typography>
                                    <em>{t("Next amount help")}</em>
                                </React.Fragment>
                            }
                        >

                            <TextField
                                label={t("Next amount")}
                                name="next_amount"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>,
                                    inputProps: { "data-parsley-min": 0 }
                                }}
                                value={jackpotDetail.next_amount / (jackpotDetail.multiplier * 100)}
                                onChange={onChangeAmountsHandler}
                            />
                        </HtmlTooltip>
                    </div>
                    {jackpotDetail.jackpotMultiplier !== 0 && !isNaN(jackpotDetail.period) && jackpotDetail.period !== 0 && !isNaN(jackpotDetail.contribution) && jackpotDetail.contribution !== 0 &&
                        <div className="col-12 col-md-6">
                            <Alert severity="warning">{getJackpotResult()}</Alert>
                        </div>}
                </div>
            </form>

            <div className="row">
                <div className="col-12">

                    <button type="button" className="btn btn-primary" onClick={handleSave}>{t("Save")}</button>
                    <button type="button" hidden={(addOrUpdate === "add") ? true : false} className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button>
                </div>
            </div>
            {addOrUpdate === 'update' && <div className="col-12 col-md-12">
                <Report windowRef="windowRefJackpot" reportId="jackpot_winnings" viewFilter="jackpot_winnings"></Report>
            </div>
            }

        </LayoutSection>
    )
}

export default withTranslation()(JackpotDetails)

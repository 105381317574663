import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import BasicSelect from '../../components/basic/BasicSelect';
import TableAndDetails from '../../components/basic/TableAndDetails';
import * as utilsBasicInfo from '../../libs/utilsBasicInfo'
import BasicTable from '../../components/basic/BasicTable';
import { Checkbox, TextField } from '@mui/material';


function AllowedCountries(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [allowedCountriesTable, setAllowedCountriesTable] = useState([]);



    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Allowed countries'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        utilsBasicInfo.loadBasicInfo("Countries", "countries")
        adminClient.getAllowedCountries(session, company).then(
            async function (result) {
                setAllowedCountries(result.data)
                setAllowedCountriesTable(result.data)
            },
            function (err) {
                console.error(err);
            }
        );
    }, []);

    const save = (evt) => {
        adminClient.updateAllowedCountries(session, company, JSON.stringify(allowedCountries)).then(
            (msg) => {
                if (msg.err === undefined) {
                    setAllowedCountriesTable([...allowedCountries])
                    props.showAlert(t('Allowed countries'), t('Saved succesfull'))

                } else {
                    props.showAlert(t('Allowed countries'), t('Saving error '))
                }
            },
            (error) => {
                props.showAlert(t('Allowed countries'), t('Saving error ') + error.description)
            }
        )
    }

    const handleCheck = (evt) => {
        setAllowedCountries((prevState) => {
            const { name, checked, id } = evt.target;
            let value = 0;
            if (checked) value = 1;
            let oldState = [...prevState];
            const objetoEncontrado = oldState.find(objeto => objeto.country === id);
            objetoEncontrado[name] = value;
            return oldState
        });
    }

    const handleCheckAll = (evt, checkedElements) => {
        setAllowedCountries((prevState) => {
            const { name, checked } = evt.target;
            let value = 0;
            if (checked) value = 1;
            console.log(JSON.stringify(checked))
            let oldState = [...prevState]
            checkedElements.forEach((check) => {
                const objetoEncontrado = oldState.find(objeto => objeto.country === check.country)
                objetoEncontrado[name] = value;
            }
            )
            return oldState
        })
    }

    const columnsDef = [
        {
            Header: t("Country"),
            accessor: "country",
            typeCell: "basicInfo",
            renderData:
            {
                "typeRender": "basicInfo",
                "renderData": {
                    "basicInfoName": "Countries",
                    "idProperty": "country",
                    "nameProperty": "name",
                    "data": "countries"
                }
            },
            filter: "basicInfo"
            //disableFilters:true            
        },
        {
            Header: t("Can register"),
            accessor: "register",
            typeCell: "checkbox",
            Cell: (props) => {
                let register = parseInt(props.row.original.register);
                return <Checkbox checked={register === 1} name="register" id={props.row.original.country} onChange={handleCheck}></Checkbox>
            },
            Filter: (props) => {

                if (props.data.length > 0) {
                    let checked = props.filteredRows.map((row) => row.original)
                    let allChecked = checked.every(objeto => objeto.register === 1);
                    return <Checkbox checked={allChecked} name="register" onChange={(evt) => { handleCheckAll(evt, checked) }}></Checkbox>
                }
                else return true;
            }
        },
        {
            Header: t("Can login"),
            accessor: "login",
            typeCell: "checkbox",
            Cell: (props) => {
                let login = parseInt(props.row.original.login);
                return <Checkbox checked={login === 1} name="login" id={props.row.original.country} onChange={handleCheck}></Checkbox>
            },
            Filter: (props) => {
                if (props.data.length > 0) {
                    let checked = props.filteredRows.map((row) => row.original)
                    let allChecked = checked.every(objeto => objeto.login === 1);
                    return <Checkbox checked={allChecked} name="login" onChange={(evt) => { handleCheckAll(evt, checked) }}></Checkbox>
                }
                else return true;
            }
        }
    ];

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <BasicTable data={allowedCountriesTable} columns={columnsDef} showFilterColumn={true} />
            <button
                type="button"
                className="btn btn-primary"
                onClick={save}
                id="saveLobbyBtn"
            >
                {t('Save')}
            </button>
        </LayoutSection>
    )
}
export default withTranslation()(AllowedCountries);
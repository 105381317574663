import React from "react";
import { withTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function WeeksCheckbox(props) {
    const { t } = props;


    let weeksArray = [];
    let weeksNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    for (let i = 0; i < 7; i++) {
        weeksArray.push(i);
    }

    const weeksOptions = weeksArray.map((i) => {
        return (<FormControlLabel
            labelPlacement="top"
            control={
                <Checkbox
                    onChange={(e) => props.handleChange(i, e)}
                    checked={
                        props.week === undefined
                            ? false
                            : props.week.charAt(i) === "1"
                    }
                />
            }
            label={t("WEEK_DAY_"+weeksNames[i])}
        />)
    });


    return (
        <>
            <InputLabel id="HOURS-select-label" label={t("Hours")}>
                {" "}
            </InputLabel>
            <FormControl
                variant="outlined"
                labelid="WEEKDAYS-select-label"
                label={t("Week days")}
                margin={"none"}
                style={{ width: "100%", marginBottom: 32 }}
            >
                <FormGroup variant="outlined" row>
                    {weeksOptions}
                    <FormControlLabel
                        labelPlacement="top"
                        control={
                            <Checkbox
                                onChange={props.handleChangeAll}
                                checked={props.week === "1111111"}
                            />
                        }
                        label={t("All")}
                    />
                </FormGroup>
            </FormControl>
        </>
    );
}


export default withTranslation()(WeeksCheckbox);

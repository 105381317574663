import React, { useCallback } from 'react';
import { getSmoothStepPath, EdgeLabelRenderer, BaseEdge, useStore } from 'reactflow';
import { getEdgeParams, getHandleCoordsByPosition } from './utilsFlows';


const FlowEdge = (props) => {

    const {
        id,
        sourceX,
        sourceY,
        sourcePosition,
        markerEnd,
        data,
        style,
        source,
        target
    } = props

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { tx, ty, targetPos } = getEdgeParams(sourceNode, targetNode);

    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX: sourceX,
        sourceY: sourceY,
        sourcePosition: sourcePosition,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        data.deleteEdge(id)
    };

    return (
        <>
            <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        //transform: `translate(-125%, -50%) translate(${targetX}px,${targetY}px)`,
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: style.stroke,
                        color: 'white',
                        padding: 3,
                        borderRadius: 5,
                        fontSize: 8
                    }}
                    className="nodrag nopan"
                >
                    {data.label}
                </div>
            </EdgeLabelRenderer>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(25%, -50%) translate(${sourceX}px,${sourceY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan downloadImg"
                >
                    <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                        ×
                    </button>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default FlowEdge;
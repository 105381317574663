import React from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function downloadImage(dataUrl) {
    const a = document.createElement('a');

    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

const imageWidth = 1920;
const imageHeight = 1080;

function FlowDownloadImg(props) {
    const { t } = props
    const { getNodes } = useReactFlow();
    const filter = (node) => {
        const exclusionClasses = ['downloadImg', 'targetConnector', 'sourceConnector'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    const onClick = () => {
        // we calculate a transform for the nodes so that all nodes are visible
        // we then overwrite the transform of the `.react-flow__viewport` element
        // with the style option of the html-to-image library
        const nodesBounds = getRectOfNodes(getNodes());
        const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);


        toPng(document.querySelector('.react-flow__viewport'), {
            backgroundColor: '#ffffff',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: imageWidth,
                height: imageHeight,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
            quality: 0.70,
            filter: filter
        }).then(downloadImage);

    };

    return (
        // <button className="btn btn-primary" onClick={onClick}>{t("DownloadImage")}</button>
        <FontAwesomeIcon onClick={onClick} icon='download' />
    );
}

export default withTranslation()(FlowDownloadImg);

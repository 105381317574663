import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../basic/BasicSelect';
import FlowStatusReacts from './FlowStatusReacts';
import AlertMessage from '../NavigationAndLayouts/AlertMessage';
import { useAlert } from '../../hooks/Alert';



const FlowStatusDetails = (props) => {

    const { t } = props
    const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const [statusDetails, setStatusDetails] = useState(
        {
            id: '',
            data: { isNew: true, status: '', name: '', config: { init: { groups: [] }, diagram: { color: '#00FFC0', position: center }, reacts: {} } },
            type: 'statusNode',
            position: center
        })
    const { formValidation } = useFormValidation('Form_StatusDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');

    const [selected, alertOpen, showAlert, closeAlert] = useAlert()


    const handleStatus = (evt) => {
        const { name, value } = evt.target || evt;
        const deep = name.split(".");
        let newState = { ...statusDetails };
        let currentObj = newState;
        if (name === "data.status") currentObj['id'] = value

        for (let i = 0; i < deep.length - 1; i++) {
            if (!currentObj.hasOwnProperty(deep[i])) {
                currentObj[deep[i]] = {};
            }
            currentObj = currentObj[deep[i]];
        }
        currentObj[deep[deep.length - 1]] = value;
        setStatusDetails(newState);
    };

    const saveStatus = () => {
        if (formValidation.validate()) {

            props.onUpdateStatus(statusDetails)
            if (addOrUpdate === 'add') {
                setAddOrUpdate('update')
            }
            else props.handleCloseAlert()
        }
    }


    const deleteStatus = () => {
        showAlert(t('Delete') + " " + t('Status'), <p>{t('Delete') + " " + t('Status') + " "} <strong>{statusDetails.data.name} </strong></p>, [
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    onDeleteStatus()
                    closeAlert()
                }}>
                {t('Ok')}</button>,
            <button type='button' className='btn btn-secondary' onClick={closeAlert}>{t('Cancel')}</button>])
    }
    const onDeleteStatus = () => {
        props.onUpdateStatus(statusDetails, true)
        props.handleCloseAlert()
    }

    useEffect(() => {
        if (props.statusData !== undefined) {
            setStatusDetails(props.statusData)
            setAddOrUpdate('update')
        }
    }, [props.statusData])


    return (<>
        <form id="Form_StatusDetails">
            <div className="row">
                <div className="col-12 col-md-3">
                    <TextField
                        label={t('ID')}
                        name="data.status"
                        required
                        disabled={addOrUpdate === 'update'}
                        InputProps={addOrUpdate === 'add' ? {
                            inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                        } : {}}

                        type="text"
                        value={statusDetails.data.status}
                        onChange={handleStatus}
                    />
                </div>
                <div className="col-12  col-md-9 ">
                    <TextField
                        label={t("Name")}
                        type="text"
                        name="data.name"
                        required
                        value={statusDetails?.data?.name}
                        onChange={handleStatus} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-2">
                    <TextField
                        label={t("Color")}
                        value={statusDetails?.data?.config?.diagram?.color}
                        type={"color"}
                        className='pick'
                        name="data.config.diagram.color"
                        onChange={handleStatus}
                    />
                </div>
                <div className="col-12 col-sm-8">
                    <BasicSelect
                        idProperty='group'
                        basicInfoName='Groups'
                        value={statusDetails?.data?.config?.init?.groups || []}
                        name='data.config.init.groups'
                        label={t('Groups')}
                        onChange={handleStatus}
                        multiple={true}
                    />
                </div>
                <div className="col-12 col-sm-2">
                    <TextField
                        label={t('Priority')}
                        value={statusDetails?.data?.config?.init?.priority || ''}
                        name="data.config.init.priority"
                        onChange={handleStatus}
                    />
                </div>
            </div>

        </form>
        <div className="row">
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={saveStatus}>{t("Save")}</button>
                <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
                <button type="button" className="btn btn-secondary" onClick={deleteStatus}>{t("Delete")}</button>
            </div>
        </div>
        {addOrUpdate === 'update' && <>
            <label><strong>{t('Reacts')}</strong></label>
            <FlowStatusReacts key={statusDetails.id + "_reacts"} status={statusDetails.id} flow={props.flow} reacts={statusDetails?.data?.config?.reacts}
                showAlert={showAlert}
                handleCloseAlert={closeAlert}
                onUpdateStatusReacts={handleStatus} />

            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={saveStatus}>{t("Save")}</button>
                    <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
                    <button type="button" className="btn btn-secondary" onClick={deleteStatus}>{t("Delete")}</button>
                </div>
            </div>
        </>
        }
        <AlertMessage key={'FlowStatus_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
    </>
    );
}

export default withTranslation()(FlowStatusDetails)
import { TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import File from "./File";
import utils from '../../utils';
import { useEffect } from "react";
import { CKEditor } from 'ckeditor4-react';
import { isEqual } from "lodash";


function CMS(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const { t } = props;
    let titleName = (props.prefix || "") + "title";
    let bodyName = (props.prefix || "") + "body";
    let imageName = (props.prefix || "") + "image";
    let urlImageName = (props.prefix || "") + "url";



    const [cmsInfo, setCmsInfo] = useState(props.cmsInfo);
    const [ckEditor, setCkEditor] = useState({ status: false, editor: undefined });



    useEffect(() => {
        if (ckEditor.status) {
            let oldData = JSON.stringify(ckEditor.editor.getData())
            let newData = JSON.stringify(props.cmsInfo[bodyName])

            if (!isEqual(oldData, newData)) {
                ckEditor.editor.setData(props.cmsInfo[bodyName])
            }
        }

        setCmsInfo(props.cmsInfo);
    }, [JSON.stringify(props.cmsInfo), JSON.stringify(ckEditor.status)]);

    const handleEditorChange = (event) => {
        setCkEditor((prevState) => {
            if (prevState !== undefined) {
                const data = event.editor.getData();
                props.onChangeHandler({ target: { name: bodyName, value: data, lang: props.lang } })
                return prevState
            }
        })
    };

    const handleTitleChange = (event) => {
        const data = event.target.value;
        props.onChangeHandler({ target: { name: titleName, value: data, lang: props.lang } })
    };

    const handleUrlTitleChange = (event) => {
        const data = event.target.value;
        props.onChangeHandler({ target: { name: urlImageName, value: data, lang: props.lang } })

    };
    return (
        <div className="row CMSComponent">
            <div className="col-12">

                <div className="col-12  ">
                    <TextField label={props.titleLabel} type="text" name={titleName} value={cmsInfo[titleName]} onChange={handleTitleChange} />
                </div>
                {props.urlImageEnable &&
                    <div className="col-12  ">
                        <TextField label={props.urlImageLabel} type="text" disabled={props.disabled} name={titleName} value={cmsInfo[urlImageName]} onChange={handleUrlTitleChange} />
                    </div>
                }


                <div className='col-12' style={{ marginBottom: "15px" }}>
                    <File
                        dropEnable={true}
                        showFile={true}
                        showError={true}
                        numFiles={1}
                        viewerEnable={false}
                        deleteEnable={true}
                        saveData={(urls) => props.saveImageCMS(urls, props.lang, imageName)}
                        fileName={[cmsInfo[imageName]]}
                        fileUrlPref={companyDetails.config.cdn_url + "../"}
                        getFileUrl={(data) => {
                            return props.pathImage + props.id + "/" + data.fileName;
                        }}
                        type={props.type}
                        enableCopyUrl={true}
                        showAlert={props.showAlert}
                        owner={JSON.stringify(props.details)}
                    />
                </div>


                <ul className="nav nav-tabs" id="cmsBodyTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="cms-body-tab" data-bs-toggle="tab" data-bs-target="#cms-body" type="button" role="tab" aria-controls="cms-body" aria-selected="false">{props.bodyLabel}</button>
                    </li>
                </ul>

                <div className="tab-content" id="cmsBodyContent">
                    <div className="tab-pane fade show active" id="cms-body" role="tabpanel" aria-labelledby="cms-body-tab"></div>
                    <CKEditor
                        config={{ height: 200, allowedContent: true, versionCheck: false }}
                        onChange={handleEditorChange}
                        onInstanceReady={(evt) => {
                            setCkEditor({ status: true, editor: evt.editor });
                        }}
                    />
                </div>
            </div>
        </div>

    );
}


export default withTranslation()(CMS);

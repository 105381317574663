import React from "react";

const EmptyLayout = (props) => {

  return (
    <div>
      <>{props.children}</>

    </div>
  )
};

export default EmptyLayout;

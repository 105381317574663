import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicTable from '../../components/basic/BasicTable';

function CreateManualGroupPopUp(props) {
    const { t } = props;
    const [groupDetails, setGroupDetails] = useState({
        group: -1,
        company: utils.getSessionItem("Company"),
        risk_weighting: 0,
        loyalty_weighting: 0,
        criteria: "",
        type: "MANUAL",
        category: "",
        css: "",
        name: "",
        description: ""
    })
    const adminClient = new AdminClient();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const company = utils.getSessionItem("Company");
    const { formValidation: formValidationGroup } = useFormValidation('Form_CaliaGroupsPopUp')
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setGroupDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const createGroup = async () => {
        if (formValidationGroup.validate()) {
            props.handleCloseAlert()
            props.showAlert("LOADING", t("Waiting data ..."));
            let responseSave = await adminClient.saveGroup(session, company, JSON.stringify(groupDetails), JSON.stringify([]))
            if (responseSave.result === 'OK') {
                console.log(responseSave)
                await adminClient.addAIGroup(session, company, responseSave.data.group, groupDetails.name, groupDetails.description, props.sql)
                adminClient.addManualMembers(session, company, responseSave.data.group, JSON.stringify(props.usersArray)).then((response) => {
                    props.handleCloseAlert()
                    if (response.result === 'OK') {
                        props.showAlert(t('Create group'), t('Saved succesfull'), [
                            <button type='button' className='btn btn-primary' onClick={() => {
                                props.handleCloseAlert()
                                utils.setSessionItem("current-group", responseSave.data.group)
                                window.open('/configuration/groups/details', '_blank');
                            }}>{t('Go to group')}</button>,
                            <button type='button' className='btn btn-secondary' onClick={() => props.handleCloseAlert()}>{t('Close')}</button>
                        ])
                    } else {
                        props.showAlert(t('Create group'), t('Saving error ') + response.description)
                    }
                })
            }
            console.log(groupDetails)
        }
    }
    return (
        <div>
            <form id="Form_CaliaGroupsPopUp" onS>
                <div className="row">
                    <div className="col-12">
                        <TextField
                            label={t('Group name')}
                            name="name"
                            type="text"
                            required
                            onChange={onChangeHandler}
                            value={groupDetails.name}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField
                            label={t('Group description')}
                            name="description"
                            type="text"
                            multiline
                            required
                            onChange={onChangeHandler}
                            value={groupDetails.description}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={createGroup}
                >
                    {t('Create')}
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={props.handleCloseAlert}
                >
                    {t('Close')}
                </button>
            </div>

        </div>
    )
}

function ExploreDataCALIA(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_CaliaGroups')
    const adminClient = new AdminClient();
    const { t } = props;
    const [dataResult, setDataResult] = useState({
        request: "",
        response: ""
    })
    const [dataTable, setDataTable] = useState({
        columns: [],
        data: []
    })

    const [advanceVisible, setAdvanceVisible] = useState(false)

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setDataResult((prevState) => ({ ...prevState, [name]: value }));
    };

    const requestData = (evt) => {
        setAdvanceVisible(false)
        setDataTable({
            columns: [],
            data: []
        })
        setDataResult((prevState) => ({ ...prevState, response: "" }));


        if (formValidation.validate()) {
            props.showAlert("LOADING", t("Waiting data ..."));

            adminClient.CALIA_requestExploreData(session, company, dataResult.request).then(
                (msg) => {
                    props.handleCloseAlert();
                    console.log(msg)
                    if (msg.response !== undefined) {
                        setDataResult((prevState) => ({ ...prevState, response: msg.response }));
                        console.log(msg.data)
                    } else {
                        props.showAlert(t('CALIA Explore Data'), t('Request error ') + msg.msg)
                    }
                },
                (error) => {
                    props.handleCloseAlert();

                    props.showAlert(t('CALIA Explore Data'), t('Request error ') + error.description);

                }
            )
        }
    }
    const goDetails = (type, row) => {
        switch (type) {
            case "USERS":
                utils.setSessionItem("current-user", row.db + "." + row.user);
                window.open('/support/user_details/' + row.user, '_blank');
                break;

            default:
                break;
        }

    }

    useEffect(() => {
        if (dataResult.response !== "") {
            if (dataResult.response.sqlData) {
                const data = dataResult.response.sqlData;
                if (data.length > 0) {
                    let keys = Object.keys(data[0]);
                    const columns = keys.map((clave) => {
                        return {
                            Header: t((clave.charAt(0).toUpperCase() + clave.slice(1)).replace("_", " ")),
                            accessor: clave,
                            typeCell: 'text',
                            Filter: true,
                            Cell: (props) => {
                                return (
                                    <label
                                        onClick={() =>
                                            goDetails("USERS", props.cell.row.original)
                                        }
                                    >
                                        {props.cell.row.original[clave]}
                                    </label>
                                );
                            },
                        };
                    });

                    setDataTable({ data: data, columns: columns });
                }
            }
        }
    }, [dataResult.response])


    const dataHeader = {
        // backLink: goBack,
        title: t('CALIA Explore Data'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const createGroup = () => {
        props.showAlert(t("Create manual group"), <CreateManualGroupPopUp {...props} sql={dataResult.response.manualGroup.query.group_sql} usersArray={dataResult.response.manualGroupData} />, [], 'm')
        console.log(dataResult.response.manualGroup.query.group_sql)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        requestData();
    };
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_CaliaGroups" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12">
                        <TextField
                            label={t('Request data')}
                            name="request"
                            required
                            type="text"
                            value={dataResult.request}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={requestData}
                    >
                        {t('Request data')}
                    </button>

                </div>
            </div>
            {dataResult.response !== "" && <>
                {dataTable.columns.length > 0 ?
                    <>
                        <BasicTable data={dataTable.data} columns={dataTable.columns} showFilterColumn={true} />
                    </>
                    :
                    <>
                        <h4>{t("No he podido encontrar níngun resultado")}</h4>
                    </>

                }
                <hr></hr>

                <div className="row" >
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={createGroup}
                    >
                        {t('Create manual group')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setAdvanceVisible(!advanceVisible)}>
                        {t('Información avanzada')}
                    </button>
                </div>
                {advanceVisible &&
                    <div className="row" >
                        <div className="col-6">
                            <TextField
                                label={t('reference')}
                                name="response"
                                inputProps={{ readOnly: true }}
                                type="text"
                                value={dataResult.response.reference}
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                label={t('tokens')}
                                name="response"
                                inputProps={{ readOnly: true }}
                                type="text"
                                value={dataResult.response.tokens}
                            />
                        </div>


                        <div className="col-12">
                            <TextField
                                label={t('Response')}
                                name="response"
                                multiline
                                inputProps={{ readOnly: true }}
                                type="text"
                                value={dataResult.response.manualGroup.query.group_sql}
                            />
                        </div>

                    </div>
                }

            </>}
        </LayoutSection>
    )
}
export default withTranslation()(ExploreDataCALIA);
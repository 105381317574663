// import { useState,useEffect } from "react";

import React from "react"

// export default  function useDebounce(value, delay) {
//     // State and setters for debounced value
//     const [debouncedValue, setDebouncedValue] = useState(value);
//     useEffect(
//       () => {
//         // Update debounced value after delay
//         const handler = setTimeout(() => {
//           setDebouncedValue(value);
//         }, delay);
//         // Cancel the timeout if value changes (also on delay change or unmount)
//         // This is how we prevent debounced value from updating if value is changed ...
//         // .. within the delay period. Timeout gets cleared and restarted.
//         return () => {
//           clearTimeout(handler);
//         };
//       },
//       [value, delay] // Only re-call effect if value or delay changes
//     );
//     return debouncedValue;
//   }

export function useGetLatest(obj) {
  const ref = React.useRef()
  ref.current = obj

  return React.useCallback(() => ref.current, [])
}

export function useAsyncDebounce(defaultFn, defaultWait = 0) {
  const debounceRef = React.useRef({})

  const getDefaultFn = useGetLatest(defaultFn)
  const getDefaultWait = useGetLatest(defaultWait)

  return React.useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve
          debounceRef.current.reject = reject
        })
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout)
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout
        try {
          debounceRef.current.resolve(await getDefaultFn()(...args))
        } catch (err) {
          debounceRef.current.reject(err)
        } finally {
          delete debounceRef.current.promise
        }
      }, getDefaultWait())

      return debounceRef.current.promise
    },
    [getDefaultFn, getDefaultWait]
  )
} 
import React, { useState } from 'react';
import Editor from '../../components/basic/Editor';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import BasicSelect from '../../components/basic/BasicSelect';
import { TextField } from '@mui/material';
import { withTranslation } from 'react-i18next';
import useFormValidation from '../../hooks/parsleyValidation';

const SendPopups = (props) => {
    const { t } = props;
    const adminClient = new AdminClient();
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    const { formValidation } = useFormValidation("Form_flowDetails")

    const [notificationData, setNotificationData] = useState({
        group: "",
        banner: {
            banner: "",
            group: "",
        },
        type: "POPUP",
        subject: "",
        text: "",
        sub_type: ""
    })

    const goBack = () => {
        props.history.push('')
    }
    const dataHeader = {
        backLink: goBack,
        title: t("Send popup"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    const onChangeHandlerBanner = async (evt) => {
        let bannerResponse = await adminClient.getBannerDetails(session, company, evt.value)
        onChangeHandler({ name: "banner", value: bannerResponse.data.banner });
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setNotificationData((prevState) => ({ ...prevState, [name]: value }));

    };

    const send = () => {
        if (formValidation.validate()) {
            if (notificationData.sub_type === 'BANNER') {
                notificationData.subject = undefined;
                notificationData.text = undefined;
            } else {
                notificationData.banner = undefined;
            }
            let notification = JSON.stringify(notificationData);
            adminClient.sendNotification(session, company, notification).then(
                function (msg) {
                    props.showAlert("Send popup", t("Send succesfull"))
                }, function (err) {
                    props.showAlert("Error", err)
                }
            );
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_flowDetails">
                <div className="row">
                    <div className="col-12  ">
                        <BasicSelect
                            idProperty='type'
                            value={notificationData.sub_type}
                            label={t('Popup type')}
                            name="sub_type"
                            onChange={onChangeHandler}
                            addOpts={[{ type: 'BANNER', name: 'Banner' }, { type: 'TEXT', name: 'Text' }]}
                            translate={{ property: 'name', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                            required
                        />
                    </div>
                </div>
                {notificationData.sub_type === 'BANNER' &&
                    <div className="row">
                        <div className="col-12" >
                            {/* <SelectBanners label={t("Select banner")} company={utils.getCurrentCompany().company} value={notificationData.banner} onChange={this.handleUpdateBanner}></SelectBanners> */}
                            <BasicSelect
                                label={t("Select banner")}
                                idProperty="banner"
                                name="banners"
                                data="banners"
                                basicInfoName="Banners"
                                onChange={onChangeHandlerBanner}
                                value={notificationData.banner?.banner || ""}
                                required
                            />
                        </div>
                        {notificationData.banner !== undefined && notificationData.banner.config !== undefined &&

                            <div className="col-12 calimaco_banner_preview "  >
                                <div className="col-12"  >
                                    <img alt="" src={utils.getCurrentCompany().config.cdn_url + "../" + notificationData.banner.config.image}></img>
                                </div>
                                <div className="col-12"  >
                                    {notificationData.banner.config.url}
                                </div>
                            </div>
                        }
                    </div>
                }
                {notificationData.sub_type === 'TEXT' &&
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <TextField name="subject" onChange={onChangeHandler} label={t("Subject")} type="text" value={notificationData.subject} />
                        </div>
                        <div className="col-12 col-md-12" style={{ marginBottom: "20px" }}>
                            <Editor
                                onChangeHandler={(evt) => onChangeHandler({ name: "text", value: evt })}
                                info={notificationData.text}
                                oneTime={true}
                            />
                        </div>
                    </div>

                }

                <BasicSelect
                    idProperty="group"
                    name="group"
                    label={t("Group")}
                    basicInfoName="Groups"
                    onChange={onChangeHandler}
                    value={notificationData.group}
                    required
                />
            </form>

            {notificationData.sub_type !== undefined &&
                <button className="btn btn-primary" onClick={send}>{t("Send")}</button>
            }

        </LayoutSection >
    );
};
export default withTranslation()(SendPopups);
import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../utils";
import TournamentPrize from "../components/TournamentPrize";
import Report from "../components/basic/Report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { DateAndTimePicker } from "../components/DateAndTimePicker";
import { CKEditor } from 'ckeditor4-react';
import Alert from "../components/Alert";
import moment from 'moment';
import AllowedForbiddenList from "../components/basic/AllowedForbiddenList";
import TreeSelector from "../components/basic/TreeSelector";

import File from "../components/basic/File";
import BasicSelect from "../components/basic/BasicSelect";
import Tab from "../components/basic/BasicTabs";
import CMS from "../components/basic/CMS";
import WebContentData from "../components/basic/WebContentData";
import DocButton from "../components/basic/DocButton";

class MarketingTournamentDetails extends React.Component {


  //Eso

  constructor(props) {

    super(props);

    const { t } = this.props;
    this.goBack = this.goBack.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateType = this.handleUpdateType.bind(this);
    this.handleUpdateTournament = this.handleUpdateTournament.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrizeClick = this.handlePrizeClick.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleDuplicateTournament = this.handleDuplicateTournament.bind(this);
    this.handleDuplicateName = this.handleDuplicateName.bind(this);
    this.submitDuplicate2 = this.submitDuplicate2.bind(this);

    this.submitDuplicate = this.submitDuplicate.bind(this);
    this.loadDuplicatedTournament = this.loadDuplicatedTournament.bind(this);
    this.handleUpdateMinRounds = this.handleUpdateMinRounds.bind(this);
    this.handleVerifyTournament = this.handleVerifyTournament.bind(this);

    this.handleUpdateName = this.handleUpdateName.bind(this);

    this.handleUpdateInitDate2 = this.handleUpdateInitDate2.bind(this);
    this.handleUpdateEndDate2 = this.handleUpdateEndDate2.bind(this);
    this.handleUpdateMinAmount = this.handleUpdateMinAmount.bind(this);
    this.handleUpdateAceptanceType = this.handleUpdateAceptanceType.bind(this);

    this.handleUpdateFilterMinOdds = this.handleUpdateFilterMinOdds.bind(this);
    this.handleUpdateTerms = this.handleUpdateTerms.bind(this);

    this.handlePrizeDeleteNew = this.handlePrizeDeleteNew.bind(this)


    this.updateMachines = this.updateMachines;

    this.handleUpdateSummaryTitle = this.handleUpdateSummaryTitle.bind(this);
    this.handleUpdateSummaryImage = this.handleUpdateSummaryImage.bind(this);
    this.handleUpdateTitle = this.handleUpdateTitle.bind(this);
    this.handleUpdateImage = this.handleUpdateImage.bind(this);
    this.handleUpdateBody = this.handleUpdateBody.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleUpdateFilterEvent = this.handleUpdateFilterEvent.bind(this);
    this.handleUpdateFilterSport = this.handleUpdateFilterSport.bind(this);
    this.handleUpdateFilterChampionShip = this.handleUpdateFilterChampionShip.bind(this);
    this.handleUpdateFilterGender = this.handleUpdateFilterGender.bind(this);

    this.setCkEditors = this.setCkEditors.bind(this);

    this.handlePrizeSave = this.handlePrizeSave.bind(this);
    this.handleNewPrize = this.handleNewPrize.bind(this);
    this.handlePrizeSaveNew = this.handlePrizeSaveNew.bind(this);

    this.saveDataCMS = this.saveDataCMS.bind(this);
    this.saveDataCMSSumary = this.saveDataCMSSumary.bind(this);
    this.handleUpdatePointsMultiplier = this.handleUpdatePointsMultiplier.bind(this);

    this.onChangeHandlerCMS = this.onChangeHandlerCMS.bind(this);
    this.onChangeHandlerCMS2 = this.onChangeHandlerCMS2.bind(this);

    this.adminClient = new AdminClient();

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }
    console.log("current-tournament = " + utils.getSessionItem("current-tournament"));

    var tournamentId = utils.getSessionItem("current-tournament")

    let urlName = this.props.location.pathname.split('/')
    if (urlName.length > 4) tournamentId = urlName[urlName.length - 1]

    var isNew = tournamentId === "-1";

    if (isNew) {
      tournamentId = '';
    }

    var statusOptions = [
      { value: 'ENABLED', label: t("TOURNAMENT_STATUS_ENABLED") },
      { value: 'DISABLED', label: t("TOURNAMENT_STATUS_DISABLED") },
      { value: 'FINISHED', label: t("TOURNAMENT_STATUS_FINISHED") }
    ];


    var acceptanceTypes = [

      { value: 'TOURNAMENT_DATE', label: t("TOURNAMENT_ACCEPTANCE_TYPE_TOURNAMENT_DATE") },
      { value: 'ACCEPTANCE_DATE', label: t("TOURNAMENT_ACCEPTANCE_TYPE_ACCEPTANCE_DATE") }

    ]

    var filter = [{ "field": "t.tournament", "operator": "=", "value": tournamentId }];
    utils.setSessionItem("ReportFilter-tournament-details", JSON.stringify(filter));

    var companyAux = utils.getCompany(utils.getSessionItem("Company"));

    const langs = utils.getCompany(utils.getSessionItem("Company")).langs.split(",")
    var cmsInit = langs.length === 1
      ? {
        summary_title: "",
        summary_body: "",
        summary_image: "",
        title: "",
        body: "",
        image: "",
      }
      : langs.reduce((result, lang) => {
        result[lang] = {
          summary_title: "",
          summary_body: "",
          summary_image: "",
          title: "",
          body: "",
          image: "",
        };
        return result;
      }, {});
    this.state = {
      company: companyAux,
      acceptanceTypes: acceptanceTypes,
      isNew: isNew,
      tournament: {

        name: "",
        status: statusOptions[0].value,
        type: "",
        tournament: tournamentId,
        company: utils.getSessionItem("Company"),
        cms: cmsInit,
        machines: [],
        machines_availables: [],
        groups: [],
        groups_availables: [],
        min_amount: 0,
        min_rounds: 0,
        end_date: new Date(),
        init_date: new Date(),
        acceptance_type: "NONE",
        terms: ""
      },
      machines: [],
      machines_availables: [],
      base_availables: [],
      tournamentPrizes: [],
      groups_allowed: [],
      groups_availables: [],
      groups_forbbiden: [],
      typeOptions: [],
      statusOptions: statusOptions,
      langs: langs
    };



  }

  componentDidMount() {

    const { t } = this.props

    if (!this.state.isNew) {




      //console.log("Calling getTournamentDetails " + tournamentId[0] + " " + tournamentId[1]);
      console.log("Calling getTournamentDetails " + this.state.tournament.company + " " + this.state.tournament.tournament);

      var thisAux = this;
      Promise.all([
        this.adminClient.getTournamentDetails(JSON.parse(utils.getSessionItem("user")).session, this.state.tournament.company, this.state.tournament.tournament),
        this.adminClient.getTournamentType(JSON.parse(utils.getSessionItem("user")).session, this.state.tournament.company)
      ]
      ).then(
        function (result) {

          this.state.typeOptions = result[1].data;
          var tournament = result[0].data.tournament;

          utils.setSessionItem("current-tournament", tournament.tournament)


          tournament.init_date = utils.formatDate(tournament.init_date);
          tournament.end_date = utils.formatDate(tournament.end_date);

          thisAux.setCkEditors(tournament);



          console.log("Tournament details  = " + JSON.stringify(tournament));


          var groups_allowed = [];
          var groups_forbbiden = [];
          tournament.groups.forEach(element => {
            console.log("element : " + JSON.stringify(element))
            if (element.relationship === 'ALLOWED') {
              groups_allowed.push(element);
            } else {
              groups_forbbiden.push(element);
            }

          });



          if (tournament.min_amount === undefined || tournament.min_amount === null) {
            tournament.min_amount = 0;
          }

          if (tournament.cms === undefined) {
            tournament.cms = thisAux.state.tournament.cms;
          }
          else {
            if (thisAux.state.langs.length > 1) {
              let existeClave = false;
              let cmsParse;
              let claves = Object.keys(tournament.cms)
              for (const lang of thisAux.state.langs) {
                if (claves.includes(lang)) {
                  existeClave = lang;
                  break; // Puedes salir del bucle tan pronto como encuentres una coincidencia
                }
              }
              if (!existeClave) {
                cmsParse = thisAux.state.langs.reduce((obj, key) => {
                  obj[key] = JSON.parse(JSON.stringify(tournament.cms));
                  return obj;
                }, {});
              }
              else {
                cmsParse = thisAux.state.langs.reduce((obj, key) => {
                  if (tournament.cms[key] !== undefined) {
                    obj[key] = tournament.cms[key]
                  }
                  else {
                    obj[key] = JSON.parse(JSON.stringify(tournament.cms[existeClave]));
                  }
                  return obj;
                }, {});
              }

              tournament.cms = cmsParse
            }
          }

          console.log("Tournament = " + JSON.stringify(tournament));

          let allMachines = result[0].data.machines_availables.concat(tournament.machines);
          var machinesData = {};
          allMachines.forEach(element => {
            machinesData[element.machine] = element;
          });

          var machinesChecked = [];
          if (tournament.machines !== undefined && tournament.machines !== undefined) {
            tournament.machines.forEach(element => {
              machinesChecked.push(element.machine);
            });
          }


          this.setState({
            tournament: tournament,
            duplicateTournament: this.state.tournament.tournament + " - NEW",
            tournamentPrizes: tournament.prizes,
            groups_allowed: groups_allowed,
            groups_forbbiden: groups_forbbiden,
            groups_availables: result[0].data.groups_availables,
            machines: machinesChecked,
            machines_availables: machinesData
          });
        }.bind(this),
        function (err) {
          this.showAlert(t("Error"), t("Cannot get the tournament"))
        }.bind(this)

      );
    } else {
      console.log("Calling getNewTournamentDetails " + this.state.tournament.company + " " + this.state.tournament.tournamentId);

      var filter = [{ "field": "t.tournament", "operator": "=", "value": this.state.tournament.tournament }];

      utils.setSessionItem("ReportFilter-tournament-details", JSON.stringify(filter));

      Promise.all([
        this.adminClient.getNewTournamentDetails(JSON.parse(utils.getSessionItem("user")).session, this.state.tournament.company, this.state.tournament.tournament),
        this.adminClient.getTournamentType(JSON.parse(utils.getSessionItem("user")).session, this.state.tournament.company)
      ]
      ).then(
        function (result) {

          this.state.typeOptions = result[1].data;
          this.state.tournament.type = result[1].data[0].type
          var machinesData = {};
          result[0].data.machines_availables.forEach(element => {
            machinesData[element.machine] = element;
          });


          this.setState({
            groups_availables: result[0].data.groups_availables,
            //machines_availables: result[0].data.machines_availables          
            machines_availables: machinesData
          });
        }.bind(this),
        function (err) {
          console.error(err);
        }

      );
    }
  }


  handleUpdateTerms(evt) {
    var tournament = this.state.tournament;
    tournament.config.terms = evt.currentTarget.value;

    this.setState({ tournament: tournament });
    this.state.termsCKEditor.setData(evt.currentTarget.value);

  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }


  setCkEditors(tournament) {

    if (tournament.cms !== undefined) {
      console.log("Setting ckeditors contents...");

      this.state.termsCKEditor.setData(tournament.cms.terms);

    } else {
      setTimeout(function () { this.setCkEditors(tournament); }.bind(this), 1000);
    }
  }

  handleUpdateSummaryTitle(evt) {
    var tournament = this.state.tournament;
    tournament.cms.summary_title = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateSummaryImage(evt) {
    var tournament = this.state.tournament;
    tournament.cms.summary_image = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateTitle(evt) {
    var tournament = this.state.tournament;
    tournament.cms.title = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateImage(evt) {
    var tournament = this.state.tournament;
    tournament.cms.image = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateBody(evt) {
    var tournament = this.state.tournament;
    tournament.cms.body = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateTournament(evt) {
    var tournament = this.state.tournament;
    tournament.tournament = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  updateMachines(checked) {

    this.setState({ machines: checked });
  }

  replacer(key, value) {
    if (key === 'name') {
      return value.replaceAll("&", ".");
    } else {
      return value;
    }
  }


  handleDuplicateTournament(evt) {
    this.setState({ duplicateTournament: evt.target.value });
  }

  handleDuplicateName(evt) {
    this.setState({ duplicateName: evt.target.value });
  }

  handleDuplicate() {
    const { t } = this.props;

    this.showPopup(t("Duplicate tournament"),
      <>
        <TextField id="new_tournament" key="new_tournament" label={t("New Identifier")} defaultValue={this.state.duplicateTournament} onChange={this.handleDuplicateTournament} />
        <TextField id="new_tournament_name" key="new_tournament_name" label={t("New Name")} defaultValue={this.state.duplicateName} onChange={this.handleDuplicateName} />
        <button type="button" className="btn btn-primary" onClick={this.submitDuplicate2}>{t("Duplicate")}</button>
        <button type="button" className="btn btn-secondary" onClick={this.closePopup}>{t("Cancel")}</button>
      </>
    );
    window.scrollTo(0, 0);
  }

  loadDuplicatedTournament() {
    utils.setSessionItem("current-tournament-id", this.state.tournament.company + "." + this.state.duplicateTournament);
    console.log("Reloading page");
    window.location.reload();
  }

  async submitDuplicate() {

    var tournament = this.state.tournament;
    var error = false;
    const { t } = this.props;
    var errorContent = <></>;

    if (this.state.duplicateTournament === undefined || this.state.duplicateTournament.trim().length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Duplicate tournament ID can not be empty")}</p></>
    } else {

      var response = await this.adminClient.tournamentExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.duplicateTournament);

      if (response.exists === 1) {
        error = true;
        errorContent = <>{errorContent}<p>{t("Tournament ID exists")}</p></>
      }
    }

    if (this.state.duplicateName === undefined || this.state.duplicateName.trim().length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Duplicate name can not be empty")}</p></>
    }

    if (error) {

      this.setState({ showModal: true, errorTitle: t("Error"), errorContent: errorContent })
    } else {

      this.setState({ showModal: false });

      var thisAux = this;

      var promise = this.adminClient.duplicateTournament(JSON.parse(utils.getSessionItem("user")).session, tournament.company, tournament.tournament, this.state.duplicateTournament, this.state.duplicateName);
      promise.then(
        function (msg) {
          if (msg.result === 'OK') {
            thisAux.closePopup();
            thisAux.setState({ showModal: true, errorTitle: t("Tournament duplicated"), errorContent: <p>{t("Tournament duplicated ok")}</p> });

          } else {
            thisAux.setState({ showModal: true, errorTitle: t("Tournament duplicated error"), errorContent: <p>{t("Tournament duplicated error explanation") + " : " + msg.description}</p> });
          }
        }, function (err) {
          thisAux.setState({ showModal: true, errorTitle: t("Tournament duplicated error"), errorContent: <p>{t("Tournament duplicated error explanation") + " : " + err.description}</p> });
        });
    }
  }

  async submitDuplicate2() {
    var response = await this.adminClient.tournamentExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.duplicateTournament);
    const { t } = this.props;
    if (response.exists === 1) {
      this.closePopup();
      this.props.showAlert(t("Duplicate tournament"),
        <>
          <p>{t("Tournament ID exists")}</p>
        </>);
    }
    else {
      let myState = this.state;
      myState.tournament.tournament = this.state.duplicateTournament;
      myState.tournament.name = this.state.duplicateName === undefined ? "new name" : this.state.duplicateName;
      this.setState(myState)
      this.closePopup();
    }

  }

  async handleSubmit() {
    const { t } = this.props;

    var tournament = JSON.parse(JSON.stringify(this.state.tournament));



    tournament.groups_allowed = this.state.groups_allowed;
    tournament.groups_forbbiden = this.state.groups_forbbiden;
    tournament.prizes = this.state.tournamentPrizes;
    tournament.machines = this.state.machines;




    console.log("tournament.init_date = " + tournament.init_date);
    tournament.init_date = utils.unformatDate(new Date(tournament.init_date));

    console.log("tournament.init_date = " + tournament.init_date);
    tournament.end_date = utils.unformatDate(new Date(tournament.end_date));


    var offset = moment['tz'](moment(new Date()), utils.getCurrentCompany().config.timezone).utcOffset() * 60000;



    var error = false;
    var errorContent = <></>;
    console.log("Tournament to be save: " + JSON.stringify(tournament));

    if (tournament.tournament.trim().length === 0) {
      error = true;
      errorContent = <><p>{t("Tournament ID can not be empty")}</p></>
    }
    if (this.state.isNew) {
      var response = await this.adminClient.tournamentExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.tournament.tournament);

      if (response.exists === 1) {
        error = true;
        errorContent = <>{errorContent}<p>{t("Tournament ID exists")}</p></>
      }
    }
    if (tournament.name.trim().length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Tournament name can not be empty")}</p></>
    }
    if (tournament.prizes === undefined || tournament.prizes.length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("You have to configure at least one prize")}</p></>
    }

    if (tournament.groups_allowed === undefined || tournament.groups_allowed.length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("You have to configure at least allowed group")}</p></>
    }


    if (error) {
      this.setState({ showModal: true, errorTitle: t("Error"), errorContent: errorContent });
    } else {



      var thisAux = this;


      var data = JSON.stringify(tournament, this.replacer).replace(/&/g, "<amp>");
      console.log("calimaco: tournament to be saved: " + data);


      var promise = this.adminClient.saveTournament(JSON.parse(utils.getSessionItem("user")).session, data);
      promise.then(
        function (msg) {
          console.log("Update result " + JSON.stringify(msg));
          if (msg.result === 'OK') {

            thisAux.setState({ isNew: false, showModal: true, errorTitle: t("Tournament saved"), errorContent: <p>{t("Tournament saved ok")}</p> });


          } else {

            thisAux.setState({ showModal: true, errorTitle: t("Tournament saved error"), errorContent: <p>{t("Tournament saved error explanation") + " : " + msg.description}</p> });

          }
        }, function (err) {
          console.log("Save operation error " + err.code);
          thisAux.setState({ showModal: true, errorTitle: t("Tournament saved error"), errorContent: <p>{t("Tournament saved error explanation") + " : " + err.description}</p> });

        });
    }
  }

  handleUpdateStatus(event) {


    var tournament = this.state.tournament;
    tournament.status = event.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateFilterEvent(event) {
    var tournament = this.state.tournament;
    tournament.bet_filter_event = event.target.value;
    this.setState({ tournament: tournament });
  }



  handleUpdateFilterSport(event) {
    var tournament = this.state.tournament;
    tournament.bet_filter_sport = event.target.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateFilterGender(event) {
    var tournament = this.state.tournament;
    tournament.bet_filter_gender = event.target.value;
    this.setState({ tournament: tournament });
  }


  handleUpdateFilterMinOdds(event) {
    var tournament = this.state.tournament;
    tournament.bet_filter_min_odds = event.target.value;
    this.setState({ tournament: tournament });
  }

  handleUpdateFilterChampionShip(event) {
    var tournament = this.state.tournament;
    tournament.bet_filter_championship = event.target.value;
    this.setState({ tournament: tournament });
  }




  handleUpdateType(event) {


    var tournament = this.state.tournament;
    tournament.type = event.value;

    this.setState({ tournament: tournament });
  }

  handleUpdateName(evt) {
    var tournament = this.state.tournament;
    tournament.name = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }


  handleUpdateMinAmount(evt) {
    var tournament = this.state.tournament;
    tournament.min_amount = evt.currentTarget.value * 100;
    this.setState({ tournament: tournament });
  }

  handleUpdateMinRounds(evt) {
    var tournament = this.state.tournament;
    tournament.min_rounds = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }
  handleUpdateAceptanceType(evt) {
    var tournament = this.state.tournament;
    tournament.acceptance_type = evt.value;
    this.setState({ tournament: tournament });
  }



  handleUpdateInitDate2(date) {
    var tournament = this.state.tournament;
    console.log("Data = " + date);
    tournament.init_date = new Date(date);
    this.setState({ tournament: tournament });
  }
  handleUpdateEndDate2(date) {
    var tournament = this.state.tournament;
    tournament.end_date = date;
    this.setState({ tournament: tournament });
  }

  handleUpdatePointsMultiplier(evt) {
    var tournament = this.state.tournament;
    tournament.points_multiplier = evt.currentTarget.value;
    this.setState({ tournament: tournament });
  }

  goBack(evt) {
    this.props.history.push('/marketing/tournaments/tournaments');
  }

  handlePrizeSave() {
    console.log("Prizes  : " + JSON.stringify(this.state.tournamentPrizes));
  }

  handlePrizeSaveNew(prize) {
    console.log("From details new prize = " + JSON.stringify(prize));
    var tournamentPrizes = this.state.tournamentPrizes;


    var found = false;
    for (var i = 0; i < tournamentPrizes.length; i++) {
      var element = tournamentPrizes[i];
      if (element.from_position === prize.from_position) {
        found = true;
        break;
      }
    };
    if (!found) {
      tournamentPrizes.push(prize);
    }
    this.setState({ tournamentPrizes: tournamentPrizes });
    this.closePopup();
  }

  handlePrizeDeleteNew(prize) {
    var tournamentPrizes = this.state.tournamentPrizes;

    this.adminClient.deleteTournamentPrize(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(prize));

    var found = -1;
    for (var i = 0; i < tournamentPrizes.length; i++) {
      var element = tournamentPrizes[i];
      if (element.from_position === prize.from_position) {
        found = i;
        break;
      }
    };
    if (found !== -1) {
      delete tournamentPrizes[i];
    }
    this.setState({ tournamentPrizes: tournamentPrizes });
    this.closePopup();
  }

  handleNewPrize(evt) {
    const { t } = this.props;
    var prize = {};
    this.showPopup(t("Prize definition"), <TournamentPrize handleSave={this.handlePrizeSaveNew} handleDelete={this.handlePrizeDeleteNew} showAlert={this.showAlert} closeAlert={this.closeAlert}
      closePopup={this.closePopup} prize={prize} ></TournamentPrize>);
    window.scrollTo(0, 0);
  }


  handlePrizeClick(evt) {
    var from_position = Number(evt.currentTarget.id.substring("from_position_".length));
    console.log("from_position = " + from_position);
    console.log("this.state.tournamentPrizes = " + JSON.stringify(this.state.tournamentPrizes));
    const { t } = this.props;

    var prize = {};
    for (var i = 0; i < this.state.tournamentPrizes.length; i++) {
      var element = this.state.tournamentPrizes[i];
      if (element.from_position === from_position) {
        prize = element;
        break;
      }

    };

    console.log("Prize = " + JSON.stringify(prize));
    this.showPopup(t("Prize definition"), <TournamentPrize handleSave={this.handlePrizeSave} handleDelete={this.handlePrizeDeleteNew} showAlert={this.showAlert} closeAlert={this.closeAlert}
      closePopup={this.closePopup} prize={prize} ></TournamentPrize>);
    window.scrollTo(0, 0);

  }

  showPopup(title, obj) {
    this.setState({ popup: obj, popupTitle: title });
    document.getElementById("popup").classList.remove("d-none");
  }



  showAlert(title, obj) {

    this.setState({ showModal: true, errorTitle: title, errorContent: obj });
  }


  closeAlert() {
    this.setState({ showModal: false });
  }

  closePopup() {
    this.setState({ showModal: false, popup: undefined, popupTitle: undefined });
    document.getElementById("popup").classList.add("d-none");

  }


  /* handleFilter(evt) {
     var filter = evt.target.value;
     this.setState({ filter: filter });
 
     var availables = [];
     this.state.base_availables.forEach(element => {
       if ((filter.length > 0 && (element.name.toUpperCase().indexOf(filter.toUpperCase()) >= 0 || element.provider.toUpperCase().indexOf(filter.toUpperCase()) >= 0)) ||
         (filter.length === 0)) {
         availables.push(element);
       }
 
     });
     this.setState({ machines_availables: availables });
 
   }*/

  saveDataCMS(urls, lang) {
    let tournament = this.state.tournament;
    if (lang === undefined) {

      if (urls.length === 0) tournament.cms.image = "";
      else tournament.cms.image = urls[0];
    }
    else {
      if (urls.length === 0) tournament.cms[lang].image = "";
      else tournament.cms[lang].image = urls[0];
    }
    this.setState({ tournament: tournament, cms_image: urls[0] });
  }
  saveDataCMSSumary(urls, lang) {
    let tournament = this.state.tournament;
    if (lang === undefined) {

      if (urls.length === 0) tournament.cms.summary_image = "";
      else tournament.cms.summary_image = urls[0];
    }
    else {
      if (urls.length === 0) tournament.cms[lang].summary_image = "";
      else tournament.cms[lang].summary_image = urls[0];
    }
    this.setState({ tournament: tournament, summary_image: urls[0] });
  }


  onChangeHandlerCMS(e) {
    const { name, value, lang } = e.target || e;
    let tournament = this.state.tournament;

    if (lang === undefined) {
      tournament.cms[name] = value;
      this.setState({ tournament: tournament });
    }
    else {
      tournament.cms[lang][name] = value;
      this.setState({ tournament: tournament });
    }
  }
  onChangeHandlerCMS2(value) {
    let tournament = this.state.tournament;
    tournament.cms = value;
    this.setState({ tournament: tournament });

  }

  async handleVerifyTournament(evt) {
    if (this.state.isNew) {
      var thisAux = this;
      var p = this.adminClient.tournamentExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.tournament.tournament);
      p.then(function (data) {
        console.log("handleVerifyTournament " + data.exists);
        if (data.exists === 1) {
          const { t } = thisAux.props;
          thisAux.setState({ showModal: true, errorTitle: t("Error"), errorContent: <p>{t("Tournament ID exists")}</p> });
        }
      }, function (err) {

      });
    }
  }

  updateGroups(allowed, forbidden) {

    this.setState({ groups_allowed: allowed, groups_forbbiden: forbidden })
  }

  render() {
    const { t } = this.props;

    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="col-12   float-left pt-3 ">
          <div className="card">
            <div className="card-header">
              <a href="#" className="float-start" onClick={this.goBack}><span><FontAwesomeIcon icon="arrow-left" /></span></a>
              {t("Tournament details")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="card-body m-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <TextField id="tournament"
                    key="tournament" label={t("Identifier")}
                    disabled={!this.state.isNew}
                    onBlur={this.handleVerifyTournament}
                    value={this.state.tournament.tournament} onChange={this.handleUpdateTournament} />
                </div>

                <div className="col-12 col-md-6">
                  <TextField id="name" key="name" type="text" disabled={false} label={t("Name")} value={this.state.tournament.name} onChange={this.handleUpdateName} />
                </div>

              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  {/* <FormControl
                    variant="outlined"
                    margin={"none"}
                    style={{ width: "100%", marginBottom: 32 }}
                  >
                    <InputLabel id="status-select-label">{t("Status")}</InputLabel>
                    < Select labelId="status-select-label" label={t("Status")} id="tournament_status" key="tournament_status" value={this.state.tournament.status} onChange={this.handleUpdateStatus} >
                      {this.state.statusOptions.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
                      })}
                    </Select>
                  </FormControl> */}
                  <BasicSelect
                    idProperty='value'
                    nameProperty='label'
                    value={this.state.tournament.status}
                    label={t('Status')}
                    onChange={this.handleUpdateStatus}
                    addOpts={this.state.statusOptions}
                    translate={{ property: 'value', prefix: 'TOURNAMENT_STATUS_' }}
                    multiple={false}
                    noEmptyValue={false}
                    disableClearable={false}
                  />
                </div>
                <div className="col-12 col-md-6">
                  {/* <FormControl
                    variant="outlined"
                    margin={"none"}
                    style={{ width: "100%", marginBottom: 32 }}
                  >
                    <InputLabel id="type-select-label">{t("Type")}</InputLabel>
                    < Select labelId="type-select-label" label={t("Type")} id="tournamentType" onChange={this.handleUpdateType}
                      value={this.state.tournament.type}
                    >

                      {this.state.typeOptions.map(item => {
                        return (<MenuItem key={item.type} value={item.type}>{t(item.name)}</MenuItem>);
                      })}

                    </Select>
                  </FormControl> */}

                  <BasicSelect
                    idProperty='type'
                    value={this.state.tournament.type}
                    label={t('Type')}
                    onChange={this.handleUpdateType}
                    addOpts={this.state.typeOptions}
                    translate={{ property: 'name', prefix: '' }}
                    multiple={false}
                    noEmptyValue={false}
                    disableClearable={true}
                  />
                </div>

              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <DateAndTimePicker
                    label={t("Init Date")}
                    value={this.state.tournament.init_date}
                    showTime={true}
                    callBackFunction={this.handleUpdateInitDate2}
                  />

                </div>

                <div className="col-12 col-md-6">
                  <DateAndTimePicker
                    label={t("End Date")}
                    value={this.state.tournament.end_date}
                    showTime={true}
                    callBackFunction={this.handleUpdateEndDate2}
                  />

                </div>
              </div>
              <div className="row">

                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.tournament.min_amount !== undefined }} required label={t("Min round amount")} type="number" value={this.state.tournament.min_amount / 100} onChange={this.handleUpdateMinAmount} />
                </div>

                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.tournament.min_rounds !== undefined }} required label={t("Min rounds")} type="number" value={this.state.tournament.min_rounds} onChange={this.handleUpdateMinRounds} />
                </div>
                <div className="col-12 col-md-6">
                  {/* <FormControl
                    variant="outlined"
                    margin={"none"}
                    className="mt-0"
                  >
                    <InputLabel id="status-select-label">{t("Acceptance Type")}</InputLabel>
                    < Select labelId="status-select-label" label={t("Acceptance Type")} id="tournament_acceptance" key="tournament_acceptance" value={this.state.tournament.acceptance_type} onChange={this.handleUpdateAceptanceType} >
                      {this.state.acceptanceTypes.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
                      })}
                    </Select>
                  </FormControl> */}

                  <BasicSelect
                    idProperty='value'
                    nameProperty='label'
                    value={this.state.tournament.acceptance_type}
                    name='value'
                    label={t('Acceptance Type')}
                    onChange={this.handleUpdateAceptanceType}
                    addOpts={this.state.acceptanceTypes}
                    translate={{ property: 'label', prefix: '' }}
                    multiple={false}
                    noEmptyValue={false}
                    disableClearable={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.tournament.points_multiplier !== undefined }} label={t("Points Multiplier")} type="number" value={this.state.tournament.points_multiplier} onChange={this.handleUpdatePointsMultiplier} />
                </div>

              </div>

              <div className="row">

                <div className="col-12">
                  <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>{t("Save")}</button>
                  <button type="button" className="btn btn-secondary" onClick={this.handleDuplicate}>{t("Duplicate")}</button>
                </div>
              </div>

              <div className="row">

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="prizes-tab" data-bs-toggle="tab" data-bs-target="#prizes" type="button" role="tab" aria-controls="prizes" aria-selected="true">{t("Prizes")}</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="groups-tab" data-bs-toggle="tab" data-bs-target="#groups" type="button" role="tab" aria-controls="groups" aria-selected="false">{t("Groups")}</button>
                  </li>
                  {!(this.state?.company?.config?.backoffice?.tournaments?.machinesTabNotShow !== undefined &&
                    this.state.company.config?.backoffice?.tournaments?.machinesTabNotShow.includes(this.state.tournament.type))
                    &&
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="machines-tab" data-bs-toggle="tab" data-bs-target="#machines" type="button" role="tab" aria-controls="machines" aria-selected="false">{t("Machines")}</button>
                    </li>
                  }
                  {this.state?.company?.config?.backoffice?.tournaments?.betFilterTabShow !== undefined &&
                    this.state.company.config?.backoffice?.tournaments?.betFilterTabShow.includes(this.state.tournament.type) &&
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="bet_filter-tab" data-bs-toggle="tab" data-bs-target="#bet_filter" type="button" role="tab" aria-controls="bet_filter" aria-selected="false">{t("Bet filter")}</button>
                    </li>
                  }
                  {!this.state.isNew &&
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="ranking-tab" data-bs-toggle="tab" data-bs-target="#ranking" type="button" role="tab" aria-controls="ranking" aria-selected="false">{t("Ranking")}</button>
                    </li>
                  }
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="cms-tab" data-bs-toggle="tab" data-bs-target="#cms" type="button" role="tab" aria-controls="cms" aria-selected="false">{t("CMS")}</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="terms-tab" data-bs-toggle="tab" data-bs-target="#terms" type="button" role="tab" aria-controls="terms" aria-selected="false">{t("Terms and conditions")}</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="prizes" role="tabpanel" aria-labelledby="prizes-tab">
                    <button type="button" className="btn btn-primary" onClick={this.handleNewPrize}>{t("New prize")}</button>
                    <div className="table-responsive">
                      <table className="table table-striped table-hover p-6">
                        <thead>
                          <tr>
                            <th className="integer">{t("From")}</th>
                            <th className="integer">{t("To")}</th>
                            <th className="currency">{t("Prize Cash")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tournamentPrizes.map(item => (
                            <tr id={"from_position_" + item.from_position} onClick={this.handlePrizeClick}>
                              <td className="integer">{item.from_position}</td>
                              <td className="integer">{item.to_position}</td>
                              <td className="currency">{item.prize_amount / 100}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  </div>






                  <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                    <AllowedForbiddenList
                      allowed={this.state.groups_allowed}
                      availables={this.state.groups_availables}
                      forbidden={this.state.groups_forbbiden}
                      idProperty={"group"}
                      nameProperty={"name"}
                      handleUpdateGroups={this.updateGroups.bind(this)}
                    ></AllowedForbiddenList>
                  </div>


                  {this.state?.company?.config?.backoffice?.tournaments?.machinesTabNotShow !== undefined &&
                    !this.state.company.config?.backoffice?.tournaments?.machinesTabNotShow.includes(this.state.tournament.type) &&

                    <div className="tab-pane fade" id="machines" role="tabpanel" aria-labelledby="machines-tab">

                      <TreeSelector
                        label={t("Machine")}
                        nodes={this.state.machines_availables}
                        checked={this.state.machines}
                        onCheck={this.updateMachines.bind(this)}
                        typeTree="machine"
                      ></TreeSelector>
                      {/* <DoubleList
                      configured={this.state.machines}
                      availables={this.state.machines_availables}
                      idProperty={"machine"}
                      configuredListName={t("Includeds")}
                      useTranslationsId={false}
                      usePlusInfo={true}
                      dataInfo={["type","provider","sub_provider"]}
                      handleUpdateList={this.updateMachines.bind(this)}
                      >                        
                      </DoubleList> */}
                    </div>
                  }

                  {this.state?.company?.config?.backoffice?.tournaments?.betFilterTabShow !== undefined &&
                    this.state.company.config?.backoffice?.tournaments?.betFilterTabShow.includes(this.state.tournament.type) &&
                    <div className="tab-pane fade" id="bet_filter" role="tabpanel" aria-labelledby="bet_filter-tab">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <TextField id="bet_filter_event"
                            key="bet_filter_event" label={t("Event")}
                            value={this.state.tournament.bet_filter_event} onChange={this.handleUpdateFilterEvent} />
                        </div>
                        <div className="col-12 col-md-6">
                          <TextField id="bet_filter_sport"
                            key="bet_filter_sport" label={t("Sport")}
                            value={this.state.tournament.bet_filter_sport} onChange={this.handleUpdateFilterSport} />
                        </div>
                        <div className="col-12 col-md-6">
                          <TextField id="bet_filter_championship"
                            key="bet_filter_championship" label={t("Championship")}
                            value={this.state.tournament.bet_filter_championship} onChange={this.handleUpdateFilterChampionShip} />
                        </div>
                        <div className="col-12 col-md-6">
                          <TextField id="bet_filter_gender"
                            key="bet_filter_gener" label={t("Gender")}
                            value={this.state.tournament.bet_filter_gender} onChange={this.handleUpdateFilterGender} />
                        </div>
                        <div className="col-12 col-md-6">
                          <TextField id="bet_filter_min_odds"
                            key="bet_filter_gener" label={t("Min odds")}
                            type="number"
                            value={this.state.tournament.bet_filter_min_odds} onChange={this.handleUpdateFilterMinOdds} />
                        </div>
                      </div>
                    </div>

                  }
                  {!this.state.isNew &&

                    <div className="tab-pane fade" id="ranking" role="tabpanel" aria-labelledby="players-tab">
                      <Report className="mt-3" reportId="tournament_ranking" filterId="tournament-details"
                        view="table" ></Report>
                    </div>
                  }



                  <div className="tab-pane fade" id="cms" role="tabpanel" aria-labelledby="cms-tab">
                    <WebContentData
                      details={this.state.tournament}
                      cms={this.state.tournament.cms}
                      id={this.state.tournament.tournament}
                      type="TOURNAMENT"
                      pathImage="/cms/img/tournaments/"
                      showAlert={this.props.showAlert}
                      onChangeHandler={this.onChangeHandlerCMS2}
                      urlImageEnable
                    />
                    {/* <Tab key="tabMachineDetailsI18n" active={0}>
                      {this.state.langs.map((lang, idx) =>
                        <Tab.TabPane key={`TabMachineDetails-${idx}`} tab={lang}>
                          <div className="row">

                            <CMS
                              lang={this.state.langs.length > 1 ? lang : undefined}
                              details={this.state.tournament}
                              onChangeHandler={this.onChangeHandlerCMS}
                              cmsInfo={this.state.tournament.cms[lang] || this.state.tournament.cms}
                              prefix="summary_"
                              titleLabel={t("Summary title")}
                              bodyLabel={t("Summary body")}
                              urlImageLabel={t("Summary url image")}
                              urlImageEnable
                              saveImageCMS={this.saveDataCMSSumary}
                              pathImage="/cms/img/tournaments/"
                              id={this.state.tournament.tournament}
                              type="TOURNAMENT"
                              showAlert={this.props.showAlert}
                            />
                            <CMS
                              lang={this.state.langs.length > 1 ? lang : undefined}
                              details={this.state.tournament}
                              onChangeHandler={this.onChangeHandlerCMS}
                              cmsInfo={this.state.tournament.cms[lang] || this.state.tournament.cms}
                              titleLabel={t("Title")}
                              bodyLabel={t("Body")}
                              urlImageLabel={t("Url image")}
                              urlImageEnable
                              saveImageCMS={this.saveDataCMS}
                              pathImage="/cms/img/tournaments/"
                              id={this.state.tournament.tournament}
                              type="TOURNAMENT"
                              showAlert={this.props.showAlert}
                            />
                          </div>
                        </Tab.TabPane>
                      )}
                    </Tab> */}
                  </div>


                  <div className="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                    <div className="row">
                      <div className="col-12  ">


                        <CKEditor

                          config={{ height: 1200, allowedContent: true, versionCheck: false }}
                          initData=""
                          onChange={(event) => {
                            const data = event.editor.getData();
                            var tournament = this.state.tournament;
                            tournament.cms.terms = "" + data;
                            this.setState({ tournament: tournament });

                          }}
                          onInstanceReady={(evt) => {
                            this.setState({ termsCKEditor: evt.editor });
                            console.log('Terms: ckeditor is ready! ' + evt.editor);
                          }}

                        />


                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="popup d-none" id="popup">
            <div className="content">
              <div className="card mt-3">
                <div className="card-header">
                  {this.state.popupTitle}
                </div>
                <div className="card-body m-0  min-vw-50">
                  {this.state.popup}
                </div>
              </div>
            </div>
          </div>


          <Alert isOpen={this.state.showModal} title={this.state.errorTitle} content={this.state.errorContent} close={this.handleCloseModal} />


        </div>
      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(MarketingTournamentDetails))



import React, { useState, useEffect, useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

import utils from '../../utils';
import * as utilsBasicInfo from '../../libs/utilsBasicInfo'
import { clone } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@material-ui/core";
import { useFormFieldValidation } from "../../hooks/parsleyValidation";

function BasicSelect(props) {
  const [selectedOption, setSelectedOption] = useState(props.multiple ? [] : "");
  const [options, setOptions] = useState([])
  const [iniOptions, setIniOptions] = useState([])
  const [searchField, setSearchField] = useState(null)
  const { t } = props;
  const nameProperty = props.nameProperty || "name";
  const idProperty = props.idProperty || "id";
  const dataName = props.data || 'data';
  const disableClearable = props.disableClearable || props.noEmptyValue || props.required || false;
  const noEmptyValue = (!props.multiple && props.noEmptyValue) || false;
  const hideOnlyOneOption = props.hideOnlyOneOption || false

  //Para el refresco de los errores
  const selectRef = useRef()
  const fieldValidation = useFormFieldValidation(selectRef?.current)


  const defaultFilterOptions = (item, filters) => {
    let result = true;
    for (let index = 0; index < filters.length; index++) {
      let { property, value } = filters[index]
      if (Array.isArray(value)) {
        let valuesToCheck = value
        result = result && valuesToCheck.includes(item[property])
      }
      else result = result && item[property] === value
    }
    if (result) return item
  }

  let filterOptionsFunction = props.filterOptionsFunction || defaultFilterOptions;

  const translateData = (data) => {
    if (data === undefined) return []
    else {
      try {
        return data.map((opt) => {
          let newOpt = opt;
          if (props.translate)
            newOpt['caption'] = t(props.translate['prefix'] + opt[props.translate['property']])
          else newOpt['caption'] = opt[nameProperty]
          return newOpt;
        })
      } catch (err) {
        console.error("BasicSelect " + props.basicInfoName + " " + err)
        return []
      }
    }
  }

  const prepareOptions = (data, filters) => {
    let dataLoad = data;
    let selectAllElement = {}
    if (filters !== undefined && filters.length > 0)
      dataLoad = dataLoad.filter((item) => filterOptionsFunction(item, filters));
    dataLoad = translateData(dataLoad)
    if (props.addOpts !== undefined) {
      let optAdd = translateData(props.addOpts);
      dataLoad = optAdd.concat(dataLoad)
    }
    if (props.multiple) {
      selectAllElement[idProperty] = 'all'
      selectAllElement['caption'] = 'Select all'
      dataLoad.unshift(selectAllElement)
    }
    if (noEmptyValue) {
      let value = props.value;
      if (value === "" || value === undefined || value === null || value.length === 0) {
        value = dataLoad[0][idProperty]
      }
      props.onChange({ value: props.value || dataLoad[0][idProperty], name: props.name, id: props.idProperty })
    }
    return dataLoad;
  }

  const findValue = (valueToFind, opts) => {
    let val;
    if (valueToFind !== undefined && opts.length) {
      val = Array.isArray(valueToFind)
        ? opts.filter((x) => valueToFind.includes(x[idProperty]))
        : opts.find((x) => x[idProperty] == valueToFind);
    }
    if ((val === undefined || val.length === 0)) {
      return props.multiple ? val = [] : val = ''
    }
    return val;
  }
  useEffect(function getSelectedOption() {
    let val = findValue(props.value, options)
    setSelectedOption(val);
  }, [props.value, options]);


  useEffect(() => {

    if (iniOptions.length > 0) {
      let dataLoad = prepareOptions(iniOptions, props.filters)
      setOptions(dataLoad)
      let val = findValue(props.value, dataLoad)
      if (val.length === 0 || val === '') {
        setSelectedOption(val);
        props.onChange({ value: val, name: props.name, id: props.id })
      } else {

        val = Array.isArray(val)
          ? val.map((o) => o[idProperty])
          : val[idProperty]
        setSelectedOption(val);
        props.onChange({ value: val, name: props.name, id: props.id })
      }
      //Comprobamos si hay que inicializar value a 0                  
    }
  }, [JSON.stringify(props.filters)])

  useEffect(() => {

    if (props.basicInfoName === undefined) {
      let dataLoad = prepareOptions([], props.filters)
      setOptions(dataLoad)
      if (props.value !== undefined && props.value !== '') { setSelectedOption(props.value) }
    }
    else {
      let dataLoad = utils.getSessionItem(`basicInfo-${props.basicInfoName}`);
      //Comprobamos si los datos estan en memoria del navegador
      if (dataLoad == undefined && dataLoad === null) {

        let promise = utilsBasicInfo.getBasicInfo(props.basicInfoName, dataName, JSON.stringify(props.params))
        if (promise !== null) {
          promise.then(
            function (response) {

              setIniOptions(response)
              dataLoad = prepareOptions(response, props.filters)
              setOptions(dataLoad)
              if (props.value !== undefined && props.value !== '' && props.value !== null && props.value !== undefined && props.value.length !== 0) {
                let val = findValue(props.value, dataLoad)
                if (val.length === 0 && val === '') {
                  setSelectedOption(val);
                  props.onChange({ value: val, name: props.name, id: props.id })
                } else {

                  val = Array.isArray(val)
                    ? val.map((o) => o[idProperty])
                    : val[idProperty]
                  setSelectedOption(val);
                  props.onChange({ value: val, name: props.name, id: props.id })
                }

                // if (val === '' || val.length === 0)
                //   props.onChange({ value: (props.multiple) ? [] : "", name: props.name, id: props.id })
                // setSelectedOption(val)
              }

            }.bind(this),
            function (Err) {
              console.log("Err = " + Err);
            }
          );
        }

      }
      else {
        dataLoad = JSON.parse(dataLoad);
        setIniOptions(dataLoad)
        dataLoad = prepareOptions(dataLoad, props.filters)
        setOptions(dataLoad)
        if (props.value !== undefined && props.value !== '') { setSelectedOption(props.value) }
      }
    }
  }, [JSON.stringify(props.params), JSON.stringify(props.addOpts)]);

  const filterOptions = (options, { inputValue }) => {
    let finalOptions = options;
    finalOptions = finalOptions.filter((option) => {
      if (option['caption'].toLowerCase().includes(inputValue.toLowerCase()) || option[idProperty] === 'all') return option
    })

    return finalOptions;
  }


  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option['caption'].toString().includes(string) ||
      option[idProperty].toString().includes(string)
    ) {
      result = true;
    }
    return result;
  }


  const onChange = (evt, newOpts, reason, opt) => {

    let string = searchField;
    if (reason === "selectOption" || reason === "removeOption") {
      let { option } = opt
      if (option && option[idProperty] === "all") {
        let filteredOptions = clone(options);

        filteredOptions = filteredOptions.filter(
          filteredOption =>
            isIncludingString(string, filteredOption) &&
            !newOpts.includes(filteredOption)
        );

        string = null;
        newOpts = newOpts
          .concat(filteredOptions)
          .filter(newOpt => newOpt[idProperty] !== "all");
      }
    } else if (reason === "clear") {
      newOpts = []
    }
    setSearchField(string)
    setSelectedOption(newOpts)
    let val = Array.isArray(newOpts)
      ? newOpts.map((o) => o[idProperty])
      : newOpts[idProperty]
    if ((val === undefined || val.length === 0)) {
      props.multiple ? val = [] : val = ''
    }

    props.onChange({ value: val, name: props.name, id: props.id, objSelection: newOpts })

  }
  const onInputChange = (evt, value) => {

    setSearchField(value)
  };

  useEffect(() => {

    if (options.length > 0 && selectRef.current != null && props.handleNotifiyError !== undefined && fieldValidation !== undefined && !props.disabled) {

      fieldValidation.validate()
      props.handleNotifiyError(fieldValidation)
    }

  })

  return (
    <>
      {(!hideOnlyOneOption || (hideOnlyOneOption && options.length > 1)) &&
        <Autocomplete
          required={props.required}
          disableClearable={disableClearable}
          disabled={props.disabled}
          style={{ padding: 0, width: props.width }}
          id={props.id}
          name={props.name}
          label={props.label}
          options={options}
          getOptionLabel={(option) => option['caption'] || ''}
          multiple={props.multiple}
          filterOptions={filterOptions}
          disableCloseOnSelect={props.multiple ? true : false}
          value={selectedOption}
          onChange={onChange}
          isOptionEqualToValue={(option, value) => {
            return option[idProperty] === (value[idProperty] || value);
          }}
          onInputChange={onInputChange}
          renderInput={((params) => {
            let validations = { ...props.validations }
            let inputP = { ...params.inputProps, ...validations }
            params.inputProps = { ...inputP }
            // }
            return <TextField {...params} inputRef={selectRef} required={props.required} onChange={() => {
              if (props.handleNotifiyError !== undefined && fieldValidation !== undefined) {

                fieldValidation.validate()
                props.handleNotifiyError(fieldValidation)

              }
            }} label={props.label} />
          })}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index}
                label={option['caption'] || ''}
                size="small"
                variant="outlined"
                deleteIcon={<FontAwesomeIcon
                  icon={faTimes}
                />}
                onDelete={{ ...getTagProps({ index }) }.onDelete}
              />

            ))
          }
        />
      }
    </>
  );
}
export default withTranslation()(BasicSelect);

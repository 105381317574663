import React from "react";
import { withTranslation } from "react-i18next";
import { useAlert } from "../../hooks/Alert";
import AlertMessage from "./AlertMessage";

const LoginLayout = (props) => {
  const { t } = props;
  const [selected, alertOpen, showAlert, closeAlert] = useAlert();

  return (
    <main>
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 col-lg-3 loginPanel">
            <div className="col-12 justify-content-center text-center pt-3">
              <img src={process.env.REACT_APP_LOGO} className="logo_login"></img>
            </div>
            <div className="container loginInputs">
              {React.Children.map(props.children, (child) =>
                React.cloneElement(child, {
                  showAlert: showAlert,
                  handleCloseAlert: closeAlert,
                })
              )}
            </div>

            <div className="col-12 ">
              <p className="text-end">v {process.env.REACT_APP_VERSION}</p>
            </div>
            <AlertMessage
              isActive={alertOpen}
              content={selected.content}
              title={selected.title}
              buttons={selected.buttons}
              handleCloseAlert={closeAlert}
              size={selected.size}
            />
          </div>
        </div>
      </div>
      <link rel="stylesheet" href="/customized/css/customized.css"></link>
    </main>
  );
};

export default withTranslation()(LoginLayout);

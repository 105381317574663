import React, { useState, useEffect } from 'react'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from '../../components/basic/BasicTabs';
import BasicTable from '../../components/basic/BasicTable';

function MissionList(props) {

  const { t } = props
  const [tabsContent, setTabsContent] = useState(
    [
      { title: t("Current Missions"), content: "" },
      { title: t("Future Missions"), content: "" },
      { title: t("Past Missions"), content: "" }
    ])

  const handleClick = (id) => {
    console.log("Mission  id = " + id);
    utils.setSessionItem("current-mission", id);
    props.history.push('/marketing/missions/details');
  }

  const handleDetailsRow = (row) => {
    handleClick(row.original.id)
  }
  const handleNewMission = () => {
    console.log("New Mission")
    handleClick(-1)
  }

  useEffect(() => {

    let clientAPI = new AdminClient();
    let promise = clientAPI.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), "missions", null, null);

    promise.then((msg) => {

      let currentMissions = [];
      let futureMissions = [];
      let pastMissions = [];

      const missionColumnsDef = [
        { Header: t("ID"), accessor: 'id', typeCell: 'text', filter: 'text' },
        { Header: t("Init Date"), accessor: 'init_date', typeCell: 'time', filter: 'time' },
        { Header: t("End Date"), accessor: 'end_date', typeCell: 'time', filter: 'time' },
        { Header: t("Name"), accessor: 'name', typeCell: 'text', filter: 'text' },
        { Header: t("Status"), accessor: 'status', typeCell: 'translate', filter: 'translate' }
      ]

      if (msg.result === "OK") {

        let now = utils.unformatDate(new Date())

        // msg.data.data.forEach(mission => {
        //   if (new Date(mission.init_date) < new Date(now) && new Date(mission.end_date) > new Date(now)) {
        //     currentMissions.push(mission);
        //   } else {
        //     if (new Date(mission.end_date) < new Date(now)) {
        //       pastMissions.push(mission);
        //     } else {
        //       futureMissions.push(mission);
        //     }
        //   }
        // });
        msg.data.data.forEach(mission => {
          if (utils.getDateServerToTZObj(mission.init_date).getTime() < utils.getDateTZTodayObj().getTime() && utils.getDateServerToTZObj(mission.end_date).getTime() > utils.getDateTZTodayObj().getTime()) {
            currentMissions.push(mission);
          } else {
            if (utils.getDateServerToTZObj(mission.end_date).getTime() < utils.getDateTZTodayObj().getTime()) {
              pastMissions.push(mission);
            } else {
              futureMissions.push(mission);
            }
          }
        });
      }
      //Configuración contenido de las tabs
      let tabContent = [
        { title: t("Current Missions"), content: <BasicTable key="table-current-mission" data={currentMissions} columns={missionColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Future Missions"), content: <BasicTable key="table-future-mission" data={futureMissions} columns={missionColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Past Missions"), content: <BasicTable key="table-past-mission" data={pastMissions} columns={missionColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> }
      ]

      setTabsContent(tabContent);

    }
      , (err) => {
        console.log("Get missions error " + err.code);
        props.showAlert("Error", `${err.code}: ${err.message}`);
      }
    );

  }, [])

  //Configuarción Cabecera Sección
  const dataHeader = {
    backLink: "",
    title: t("Missions"),
    urlHelp: "",
    idDoc: props.idDoc,
    buttons: [
      { name: t("New mission"), handle: handleNewMission, icon: <FontAwesomeIcon icon="plus-square" /> }
    ]
  }


  return (
    <>
      <LayoutSection dataHeader={dataHeader}>
        <Tab active={0}>
          {
            tabsContent.map((tab, idx) =>
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                {tab.content}
              </Tab.TabPane>
            )
          }
        </Tab>
      </LayoutSection>
    </>
  )
}

export default withTranslation()(MissionList)
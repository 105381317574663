import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from "../../AdminClient"
import utils from "../../utils"
import BasicTable from '../../components/basic/BasicTable';
import Tooltip from '@mui/material/Tooltip';

import { cellTableRender } from '../../libs/utilsReports'


function UserChanges(props) {

    const [dataTable, setDataTable] = useState({})
    const { t } = props

    useEffect(() => {


        const generateUserChangesColumnsDef = ({ config: { table: confTable } }) => {

            const { fields, labels, styles } = confTable;
            let columnsDef = [];
            for (let i = 0; i < fields.length; i++) {
                columnsDef.push({

                    Header: t(labels[i]),
                    accessor: fields[i],
                    typeCell: styles[i],
                    numberCell: i,
                    className: styles[i],
                    disableSortBy: true,
                    Cell: (props) => {
                        // return props.value

                        let columnId = props.cell.column.id;
                        let rowId = parseInt(props.row.id)
                        let className = "positive";
                        let showTooltip = false;
                        let cell = props;
                        if (rowId < props.rows.length - 1 && columnId !== 'admin_alias' && columnId !== 'updated_date') {
                            //if (rowId < props.rows.length - 1) {

                            let newCellValue = props.rows[rowId + 1].values[columnId]
                            if (newCellValue !== cell.value) {

                                let newCell = Object.assign({}, cell);
                                let newDataCell = { value: newCellValue }
                                if (newCellValue === null || newCellValue === '') newDataCell = { value: t("No value"), typeCell: 'text', className: 'text' }
                                Object.assign(newCell, newDataCell);
                                className = "negative";
                                showTooltip = true
                                //cell=newCell
                                return (

                                    <>
                                        {showTooltip && <Tooltip title={
                                            <span style={{ fontSize: '1rem' }}>
                                                {t("The value has changed from")} {cellTableRender(newCell)}  {t("To value")} {(cell.value !== null && cell.value !== '') ? cellTableRender(cell) : t("No value")}</span>} enterTouchDelay={0}>
                                            <p className={className}><strong>{cellTableRender(cell)}</strong></p>
                                        </Tooltip>}
                                    </>
                                );

                            } return cellTableRender(cell)
                        }
                        return (cellTableRender(cell));
                    }

                    // Cell:({value,column:{typeCell}})=>{
                    //     console.log(value);
                    //     console.log(typeCell);
                    //     return "Render sobre escrito "+ value;
                    // }

                })
            }
            return columnsDef;
        }
        async function loadData() {

            let adminClient = new AdminClient();
            let user = JSON.parse(utils.getSessionItem("userDetails")).user;
            return await adminClient.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), 'user_changes', null, user.user, user.db)
        }

        loadData().then((result) => {
            let columns = []
            let data = []

            columns = generateUserChangesColumnsDef(result.data.def, t);
            data = result.data.data;
            setDataTable({ columns: columns, data: data })
        })

    }, [t])

    return (
        <>
            {dataTable.columns !== undefined &&
                <BasicTable data={dataTable.data} columns={dataTable.columns} />}
        </>
    )

}

export default withTranslation()(UserChanges)


import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import useFormValidation from "../../hooks/parsleyValidation";
import BasicSelect from "../../components/basic/BasicSelect";

function PromotionsTagsDetails(props) {
  const company = utils.getSessionItem("Company");
  const session = JSON.parse(utils.getSessionItem("user")).session;
  const adminClient = new AdminClient();
  const { t } = props;
  const { formValidation } = useFormValidation("Form_PromotionsTagsDetails");
  const [addOrUpdate, setAddOrUpdate] = useState("add");
  const [tag, setTag] = useState({
    tag: "",
    name: "",
    company: utils.getSessionItem("Company"),
    promotions: [],
  });

  const tagId = utils.getSessionItem("current-tag");
  //HEADER
  const goBack = () => {
    props.history.push("/reports/report/promotions_tags/table");
  };
  const dataHeader = {
    backLink: goBack,
    title: t("Tag details"),
    urlHelp: "",
    idDoc: props.idDoc
  };
  //FIN HEADER
  //Carga de datos
  useEffect(function () {
    if (tagId !== undefined && tagId !== "-1") {
      adminClient.getPromotionsTagDetails(session, company, tagId).then(
        async function (result) {
          let tagDet = result.data;
          tagDet.promotions = result.promotions;
          setTag(tagDet);
          setAddOrUpdate("update");
        },
        function (err) {
          console.error(err);
        }
      );
    } else {
      setAddOrUpdate("add");
    }
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target || e;
    setTag((prevCategory) => ({ ...prevCategory, [name]: value }));
  };


  //Manejadores modificacion
  const save = (evt) => {
    if (formValidation.validate()) {
      adminClient["savePromotionTag"](
        session,
        company,
        JSON.stringify(tag)
      ).then(
        (msg) => {
          if (msg.result === "OK") {
            props.showAlert(t("Tag details"), t("Saved succesfull"));
            if (addOrUpdate === "add") {
              setAddOrUpdate("update");
            }
          } else {
            props.showAlert(
              t("Tag details"),
              t("Saving error ") + msg.description
            );
          }
        },
        (error) => {
          props.showAlert(
            t("Tag details"),
            t("Saving error ") + error.description
          );
        }
      );
    }
  };
  const remove = (evt) => {
    adminClient["deletePromotionTag"](
      session,
      company,
      JSON.stringify(tag)
    ).then(
      (msg) => {
        if (msg.result === "OK") {
          props.showAlert(t("Tag details"), t("Deleted succesfull"));
          goBack()
        } else {
          props.showAlert(
            t("Tag details"),
            t("Deleting error ") + msg.description
          );
        }
      },
      (error) => {
        props.showAlert(
          t("Tag details"),
          t("Deleting error ") + error.description
        );
      }
    );
  };
  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <form id="Form_PromotionsTagsDetails">
        <div className="row">
          <div className="col-12 col-md-3">
            <TextField
              label={t("ID")}
              name="tag"
              required
              disabled={addOrUpdate === 'update'}
              inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
              type="text"
              value={tag.tag}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-12  col-md-9  ">
            <TextField
              name="name"
              label={t("Name")}
              type="text"
              required
              onChange={onChangeHandler}
              value={tag.name}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <BasicSelect
              idProperty='promotion'
              nameProperty='promotion'
              basicInfoName='Promotions'
              value={tag.promotions}
              name='promotions'
              label={t('Promotions')}
              onChange={onChangeHandler}
              multiple={true}
              noEmptyValue={false}
              disableClearable={false}
            />
          </div>
        </div>
      </form>
      {utils.isGranted('SAVE_PROMOTION_TAGS') &&
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-primary" onClick={save}>
              {t("Save")}
            </button>
            <button type="button" className="btn btn-secondary" onClick={remove}>
              {t("Delete")}
            </button>
          </div>
        </div>}
    </LayoutSection>
  );
}
export default withTranslation()(PromotionsTagsDetails);

import React from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import utils from '../../utils';
import { useEffect } from "react";
import CMS from "./CMS";
import Tab from "./BasicTabs";


function WebContentData(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const langs = companyDetails.langs.split(",");
    const disabled = props.disabled || false;
    const cmsInit = langs.length === 1
        ? {
            summary_title: "",
            summary_body: "",
            summary_image: "",
            title: "",
            body: "",
            image: "",
        }
        : langs.reduce((result, lang) => {
            result[lang] = {
                summary_title: "",
                summary_body: "",
                summary_image: "",
                title: "",
                body: "",
                image: "",
            };
            return result;
        }, {});
    const [CMSDetails, setCMSDetails] = useState(cmsInit);

    useEffect(() => {
        if (props.cms !== undefined && Object.keys(props.cms).length > 0) {

            if (langs.length > 1) {
                let existeClave = false;
                let cmsParse;
                let claves = Object.keys(props.cms)
                for (const lang of langs) {
                    if (claves.includes(lang)) {
                        existeClave = lang;
                        break; // Puedes salir del bucle tan pronto como encuentres una coincidencia
                    }
                }
                if (!existeClave) {
                    cmsParse = langs.reduce((obj, key) => {
                        obj[key] = JSON.parse(JSON.stringify(props.cms));
                        return obj;
                    }, {});
                }
                else {
                    cmsParse = langs.reduce((obj, key) => {
                        if (props.cms[key] !== undefined) {
                            obj[key] = props.cms[key]
                        }
                        else {
                            obj[key] = JSON.parse(JSON.stringify(props.cms[existeClave]));
                        }
                        return obj;
                    }, {});
                }

                setCMSDetails(cmsParse);
            }
            else {
                setCMSDetails(props.cms)
            }
        }
    }, [JSON.stringify(props.cms)]);

    const { t } = props;


    const onChangeHandlerConfigCMS = (e) => {
        const { name, value, lang } = e.target || e;
        setCMSDetails((prevState) => {
            let oldState = { ...prevState }
            let updatedCMS = createUpdatedCMS(oldState, name, value, lang);
            props.onChangeHandler(updatedCMS);
            return updatedCMS
        });
    };

    const saveDataCMS = (urls, lang, name) => {
        setCMSDetails((prevState) => {
            let oldState = { ...prevState }
            let updatedCMS = createUpdatedCMS(oldState, name, urls[0], lang);
            props.onChangeHandler(updatedCMS);
            return updatedCMS
        })
    };

    const createUpdatedCMS = (oldState, name, value, lang) => {
        let object = oldState;
        if (lang !== undefined) {
            object[lang][name] = value;
        }
        else {
            object[name] = value;
        }
        return object;
    };

    return (
        <Tab key="tabMachineDetailsI18n" active={0}>
            {langs.map((lang, idx) =>
                <Tab.TabPane key={`TabMachineDetails-${idx}`} tab={lang}>
                    <div className="row">
                        {!props.noSummary &&
                            <CMS
                                lang={langs.length > 1 ? lang : undefined}
                                details={props.details}
                                onChangeHandler={onChangeHandlerConfigCMS}
                                cmsInfo={CMSDetails[lang] || CMSDetails}
                                prefix="summary_"
                                titleLabel={t("Summary title")}
                                bodyLabel={t("Summary body")}
                                urlImageLabel={t("Summary url")}
                                urlImageEnable={props.urlImageEnable}
                                saveImageCMS={saveDataCMS}
                                pathImage={props.pathImage}
                                id={props.id}
                                type={props.type}
                                showAlert={props.showAlert}
                                disabled={disabled}
                            />
                        }
                        <CMS
                            lang={langs.length > 1 ? lang : undefined}
                            details={props.details}
                            onChangeHandler={onChangeHandlerConfigCMS}
                            cmsInfo={CMSDetails[lang] || CMSDetails}
                            titleLabel={t("Title")}
                            bodyLabel={t("Body")}
                            urlImageLabel={t("Url")}
                            urlImageEnable={props.urlImageEnable}
                            saveImageCMS={saveDataCMS}
                            pathImage={props.pathImage}
                            id={props.id}
                            type={props.type}
                            showAlert={props.showAlert}
                            disabled={disabled}
                        />
                    </div>
                </Tab.TabPane>
            )}
        </Tab>

    );
}


export default withTranslation()(WebContentData);

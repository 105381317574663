import AdminClient from "../AdminClient";
const utils=require('../utils')



export async function searchUserById(locationPath){

    let userIdSession=''
    if(utils.getSessionItem("current-user")!==undefined && utils.getSessionItem("current-user")!==null )
      userIdSession = utils.getSessionItem("current-user").split(".")[1];   
  
    let path = locationPath.split("/");
    let userId = path[path.length - 1];  
    if(userId!==undefined && userId!=='' && !isNaN(userId) && userId!==userIdSession){
  
        let adminClient=new AdminClient();            
        let userData=await adminClient.userSearch(
          JSON.parse(utils.getSessionItem("user")).session,
          utils.getSessionItem('Company'),
          "",
          undefined,
          userId,
          undefined,
          undefined,
          undefined,
          [],
          [],
          undefined,
          null,
          null,
          undefined,
          undefined,
          0
        )
        if(userData.users.length>0)
          utils.setSessionItem("current-user", userData.users[0].db + "." + userData.users[0].user)
        else return false;  
    }  
    return true
  }  
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../basic/BasicSelect';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import AlertMessage from '../NavigationAndLayouts/AlertMessage';
import { useAlert } from '../../hooks/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const BalanceUserAgent = (props) => {
    const adminClient = new AdminClient();
    const { t, userAdmin, userAccount } = props
    const session = userAdmin.session;
    const company = utils.getSessionItem('Company');
    const [balanceData, setBalanceData] = useState({})

    useEffect(async () => {
        let agentBalance = await adminClient.getBalanceUser(session, company)
        let userBalance = await adminClient.getCurrentUserBalance(session, company, userAccount.db, userAccount.user, 'CASH')
        setBalanceData({ "agentBalance": agentBalance, "userBalance": userBalance })
    }, [])
    return (<>

        <div className="row">
            <div className="col-12 col-md-6 "><TextField label={t('Balance') + " " + t('Agent') + ":"} disabled value={utils.formatCurrency((balanceData.agentBalance?.data?.amount || 0) / 100, balanceData.agentBalance?.data?.currency)}></TextField></div>
            <div className="col-12 col-md-6 "><TextField label={t('Balance') + " " + t('Player') + ":"} disabled value={utils.formatCurrency((balanceData.userBalance?.data[0]?.amount || 0) / 100, balanceData.userBalance?.data[0].currency)}></TextField></div>
        </div>

    </>)
}


const Operations = (props) => {

    const userAdmin = JSON.parse(utils.getSessionItem('user'))
    const session = userAdmin.session;
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const userAccount = props.userAccount
    const { t } = props;
    const { formValidation: formDepositValidation } = useFormValidation("FormDepositValidation");
    const adminClient = new AdminClient();
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()
    const [isFieldRequired, setIsFieldRequired] = useState(false); // Estado para controlar si el campo es requerido
    const optionsOperations = useMemo(() => {

        let options = []
        if (utils.isGranted('AGENTS_USER_DEPOSIT')) options.push({ type: "SEND", name: t('ACTION_SEND') + " " + t("To value") + " " + " (" + userAccount.alias + ")" })
        if (utils.isGranted('AGENTS_USER_NEGATIVE_DEPOSIT')) options.push({ type: "RETRIEVE", name: t("ACTION_RETRIEVE") + " " + t("From value") + " " + " (" + userAccount.alias + ")" })
        return options
    }, [userAccount])

    const [creditInfo, setCreditInfo] = useState({
        idempotence: props.idempotence,
        actionType: props.actionType || "SEND",
        amount: 10,
        status: 'NEW',
        method: 'AGENTS',
        comments: ""
    });

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name !== undefined && value !== undefined) {
            setCreditInfo((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const showResults = (result) => {
        const partes = result.idempotence.split("_");
        const idempotence = partes[partes.length - 1];
        const extra = JSON.parse(result.extra);
        return <>
            <div className='col-12'>
                <h3 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }} > {t("Nº transacction")}: </h3>
                <h4 style={{ display: 'inline-block', width: '50%' }}>{idempotence}</h4>
            </div>
            <hr></hr>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Alias")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.alias}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Amount")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.amount / 100}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Date")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.updated_date}</span>
            </div>
            {extra !== undefined && extra !== null &&
                <div className='col-12'>
                    <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Cajero")}:</h5>
                    <span style={{ display: 'inline-block', width: '50%' }}>{extra.operator}</span>
                </div>
            }
        </>
    }

    const getBalanceRender = () => {

        return <BalanceUserAgent t={t} userAdmin={userAdmin} userAccount={userAccount} />
    }

    const showCorrectAlert = (data) => {
        showAlert(t('Operation result'),
            <>{props.showClose && getBalanceRender()}
                <div className="row center">
                    {t('Operation OK')}
                </div>
            </>
            , [<button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    if (props.showClose) props.showClose()
                    else closeAlert()
                    creditInfo.idempotence = utils.generateUID()
                    if (props.updateOperations) props.updateOperations(data);
                }}>
                {t('Ok')}</button>]);
        document.getElementById("btn_deposit").classList.remove("hide");
    }

    const doDeposit = (evt) => {

        let amountDeposit = creditInfo.amount
        if (creditInfo.actionType === "RETRIEVE") amountDeposit = -creditInfo.amount
        document.getElementById("btn_deposit").classList.add("hide");
        adminClient.manualDeposit(
            session,
            company,
            userAccount.db,
            userAccount.user,
            creditInfo.method,
            amountDeposit * 100,
            creditInfo.status,
            creditInfo.comments,
            creditInfo.idempotence

        ).then(
            async (msg) => {

                if (msg.result === 'OK') {
                    const channel = new BroadcastChannel('updateBalance');
                    channel.postMessage("updateBalance")
                    showCorrectAlert({ action: creditInfo.actionType, type: "player" })

                } else {
                    if (msg.code === -34) {
                        showCorrectAlert()
                    } else {
                        document.getElementById("btn_deposit").classList.remove("hide");
                        showAlert(t('Operation result'), t('Operation error ') + t(msg.description), [<button type='button' className='btn btn-primary'
                            onClick={(evt) => {
                                closeAlert()
                                creditInfo.idempotence = utils.generateUID()
                            }}>
                            {t('Ok')}</button>]);
                    }
                }
            },
            (error) => {
                if (error.code === -34) {
                    showCorrectAlert()
                } else {
                    document.getElementById("btn_deposit").classList.remove("hide");
                    showAlert(t('Operation result'), t('Operation error ') + t(error.description), [<button type='button' className='btn btn-primary'
                        onClick={(evt) => {
                            closeAlert()
                            creditInfo.idempotence = utils.generateUID()
                        }}>
                        {t('Ok')}</button>]);
                }
            }
        )
    }


    const deposit = (evt) => {
        if (formDepositValidation.validate()) {
            if (companyDetails.config?.backoffice?.agents?.deposit?.idempotence && !isFieldRequired) {

                if (formDepositValidation.validate()) {

                    adminClient.validateIdempotenceManualDeposit(
                        session,
                        company,
                        creditInfo.idempotence

                    ).then(
                        (msg) => {
                            let result = msg.data;
                            if (result?.length > 0) {
                                showAlert(t('Nº transacction in use'), showResults(result[0]), undefined, "s3")
                            } else {
                                doDeposit()
                            }
                        },
                        (error) => {
                            showAlert(t('User accounts'), t('Check transaction number error ') + error.description);
                        }
                    )
                }


            }
            else {
                doDeposit()
            }
        }
    }



    return (<>
        <form id="FormDepositValidation">
            <div className="row">
                {props.showActionType &&
                    <div className="col-12 col-md-3 col-xl-3">
                        <BasicSelect
                            idProperty='type'
                            value={creditInfo.actionType}
                            name='actionType'
                            label={"(" + JSON.parse(utils.getSessionItem("user")).alias + ") " + t('I want to')}
                            addOpts={optionsOperations}
                            onChange={onChangeHandler}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={true}
                        />
                    </div>
                }
                {props.showClose && getBalanceRender()}
                <div className="col-12 col-md-3 col-xl-3">

                    <TextField id="loyalty_weighting" label={t("Amount")} type="number" name="amount"
                        value={creditInfo.amount} InputProps={{ inputProps: { step: "0.01", 'data-parsley-amount-greater': 0 } }} required onChange={onChangeHandler}
                    />
                </div>
                <div className="col-12 col-md-3 col-xl-3">
                    <TextField disabled value={userAccount.currency}></TextField>
                </div>
                {companyDetails.config?.backoffice?.agents?.deposit?.idempotence &&
                    <>
                        <div className="col-12 col-md-3 col-xl-3">
                            <TextField label={t("AGENTS_DEPOSIT_IDEMPOTENCE")} required={!isFieldRequired} value={creditInfo.idempotence} name="idempotence" onChange={onChangeHandler}></TextField>
                        </div>
                        <div className="col-12 col-md-3 col-xl-3">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isFieldRequired}
                                        onChange={() => setIsFieldRequired(!isFieldRequired)}
                                    />
                                }
                                label={t("Cash")}
                            />
                        </div>

                    </>
                }
                <div className="col-12 col-md-12 agents-deposit-comment">
                    <TextField multiline id="comment" label={t("Comment")} type="text" name="comments"
                        value={creditInfo.comments} onChange={onChangeHandler} />
                </div>
            </div >

        </form>
        <div className="row">
            <div className="col-12 ">
                <button className="btn btn-primary" id="btn_deposit" onClick={deposit}>{t("BTN_execute")}</button>
                {props.showClose &&
                    <button className="btn btn-secondary" onClick={props.showClose}>{t("Close")}</button>
                }
            </div>
        </div>
        <AlertMessage key={'SendAndRetrieve_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
    </>
    )
}

const ChangePassword = (props) => {

    const userAccount = props.userAccount
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassUser || 11

    const { formValidation: formPassValidation } = useFormValidation("FormPassword");
    const [showPassword, setShowPassword] = useState({ password: false, password2: false })
    const [password, setPassword] = useState("")
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()

    const handleClickShowPassword = (evt) => {
        const { name } = evt.currentTarget
        setShowPassword((prevState) => ({ ...prevState, [name]: !prevState[name] }))
    }

    const onChangePasswordHandler = (e) => {
        const { value } = e.target || e;
        setPassword(value);
    }

    const updatePassword = () => {

        if (formPassValidation.validate()) {
            adminClient
                .updatePasswordAgent(
                    session,
                    company,
                    userAccount.db,
                    userAccount.user,
                    password
                )
                .then(
                    function (data) {
                        if (data.result === "OK") {
                            showAlert(
                                t("Change Password"),
                                t("Password updated succesfully"), [<button type='button' className='btn btn-primary'
                                    onClick={(evt) => {
                                        if (props.showClose) props.showClose()
                                        else closeAlert()
                                    }}>
                                    {t('Ok')}</button>]
                            );
                        }
                        else showAlert(t('Change Password'), t('Password updated error') + data.description)
                    },
                    function (err) {
                        showAlert(t('Change Password'), t('Password updated error') + err.description)
                    }
                );
        }
    };

    return (<>

        <form id="FormPassword">
            <div className="row">
                <div className="col-12  col-md-6">
                    <TextField
                        label={t("New password")}
                        type={showPassword.password ? 'text' : 'password'}
                        name="password"
                        onChange={onChangePasswordHandler}
                        required

                        inputProps={
                            {
                                "data-parsley-pattern": "^[a-zA-Z0-9]+$",
                                "data-parsley-pattern-message": t('Password letters and numbers'),
                                "minLength": minLength,
                                id: "password"
                            }

                        }
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={handleClickShowPassword}
                                        onMouseUp={handleClickShowPassword}
                                        edge="end"
                                        name="password"
                                    >
                                        {showPassword.password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                    </IconButton>
                                </InputAdornment>

                        }}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <TextField
                        label={t("Re password")}
                        required
                        type={showPassword.password2 ? 'text' : 'password'}
                        inputProps={{
                            "data-parsley-check-text-fields-equal":
                                "[isEqual,password]",
                            "data-parsley-check-text-fields-equal-message":
                                t("New password different than re password")
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={handleClickShowPassword}
                                        onMouseUp={handleClickShowPassword}
                                        edge="end"
                                        name="password2"
                                    >
                                        {showPassword.password2 ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                    </IconButton>
                                </InputAdornment>

                        }}
                        name="pasword2"
                        onChange={onChangePasswordHandler}

                    />
                </div>
            </div>
        </form>
        <div className="row">
            <div className="col-12 ">
                {utils.isGranted("AGENTS_UPDATE_PASSWORD") &&

                    <button className="btn btn-primary" onClick={updatePassword}>{t("Update")}</button>
                }
                {props.showClose &&
                    <button className="btn btn-secondary" onClick={props.showClose}>{t("Close")}</button>
                }
            </div>
        </div>
        <AlertMessage key={'Password_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
    </>)


}


const ToolBar = (props) => {

    const companyDetails = useMemo(() => utils.getCurrentCompany(), []);
    const useAgentsSearch = companyDetails.config.backoffice?.agents?.agentsUserSearch || false
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const goDetails = (user) => {
        utils.setSessionItem("current-user", user);
        let userAux = user.split(".");
        if (!useAgentsSearch) {
            props.history.push('/cashiers/user_accounts')
        } else window.open('/agents/user_accounts/' + userAux[0] + "/" + userAux[1], "_blank")

    }


    const goPassword = (rowUser) => {
        props.showAlert(t("Change Password"), <Password userAccount={rowUser} showClose={props.handleCloseAlert} />, true)
    }

    const goOperationTokens = async (rowUser, actionType, title) => {
        let dataUser = await adminClient.userDetails(session, rowUser.db + "." + rowUser.user)
        rowUser.currency = dataUser.user.currency
        let idempotence = utils.generateUID()
        props.showAlert(title, <SendAndRetrieve idempotence={idempotence} userAccount={rowUser} updateOperations={props.finishOperation} actionType={actionType} showClose={props.handleCloseAlert} />, true)
    }

    return (<>
        <div className="desktop-icons">
            {utils.isGranted('AGENTS_USER_DEPOSIT') && <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'SEND', t("ACTION_SEND"))}><FontAwesomeIcon className='icon' icon="plus-circle" /></IconButton>}
            {utils.isGranted('AGENTS_USER_NEGATIVE_DEPOSIT') && <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'RETRIEVE', t("ACTION_RETRIEVE"))}><FontAwesomeIcon className='icon' icon="minus-circle" /></IconButton>}
            <IconButton className='icon-button' onClick={() => goPassword(props.row)}><FontAwesomeIcon className='icon' icon="key" /></IconButton>
            <IconButton className='icon-button' onClick={() => goDetails(props.row.db + "." + props.row.user)}><FontAwesomeIcon className='icon' icon="eye" /></IconButton>
        </div>
        <div className="mobile-menu">
            {utils.isGranted('AGENTS_USER_DEPOSIT') && <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'SEND', t("ACTION_SEND"))}><FontAwesomeIcon className='icon' icon="plus-circle" /></IconButton>}
            {utils.isGranted('AGENTS_USER_NEGATIVE_DEPOSIT') && <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'RETRIEVE', t("ACTION_RETRIEVE"))}><FontAwesomeIcon className='icon' icon="minus-circle" /></IconButton>}
            <IconButton onClick={handleClick} className="icon-button"><FontAwesomeIcon icon='bars' className="icon" /></IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    goPassword(props.row)
                    handleClose()
                }}><FontAwesomeIcon icon='key' /></MenuItem>
                <MenuItem onClick={() => {
                    goDetails(props.row.db + "." + props.row.user)
                    handleClose()
                }}><FontAwesomeIcon icon='eye' /></MenuItem>
            </Menu>
        </div>
    </>)

}

const SendAndRetrieve = withTranslation()(Operations)
const Password = withTranslation()(ChangePassword)

const PlayerToolBar = withTranslation()(ToolBar)
export { SendAndRetrieve, Password, PlayerToolBar }


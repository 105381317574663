import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tab from '../../components/basic/BasicTabs';
import BasicSelect from '../../components/basic/BasicSelect';
import TreeAdminUsers from '../../components/TreeAdminUsers';
import Report from '../../components/basic/Report';
function Activity(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const user = JSON.parse(utils.getSessionItem("user")).user;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_AgentsActivity')
    const [creditInfo, setCreditInfo] = useState({
        parents: [],
        childs: [],
        to_from: "to destionation",
        actionType: "SEND",
        target: { alias: "" },
        currency: "",
        amount: 10
    });
    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Agents activity'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(() => {
        var promise = adminClient.getAdminUserHierarchy(session, user, company);
        promise.then(
            function (result) {
                setCreditInfo((prevState) => ({ ...prevState, parents: result.parents, childs: result.childs }));


            },
            function (err) {
                console.error(err);
            }
        )
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name !== undefined && value !== undefined) {
            setCreditInfo((prevState) => ({ ...prevState, [name]: value }));
        }
        else {
            let info = JSON.parse(e);
            setCreditInfo((prevState) => ({ ...prevState, target: info, selectedChild: info.user }));

        }
    };

    const refreshReports = () => {
        console.log("Calling refreshReports");
        window["windowRefAgentAccounts"].executeReport(0);
        window['windowRefAgentOperations'].executeReport(0);
        window['windowRefAgentSummary'].executeReport(0);
        window['windowRefAgentSubAgentesSummary'].executeReport(0);

    }
    const save = (evt) => {
        if (formValidation.validate()) {

            var adminClient = new AdminClient();
            if (creditInfo.actionType === 'SEND') {
                if (creditInfo.target.user === undefined) {
                    props.showAlert(t("Send tokens"), <p>{t("Select a target")}</p>)
                }
                else {
                    var promise = adminClient.sendTokens(session, creditInfo.target.user, company, creditInfo.amount * 100, creditInfo.currency);
                    promise.then(
                        function (response) {
                            refreshReports();
                            props.showAlert(t("Send tokens"), <p>{t("Tokens sent succesfully")}</p>)
                        },
                        function (err) {
                            console.error(err);
                            props.showAlert(t("Send tokens error"), <p>{t("Error sending tokens: ") + t("ERROR_" + err.description)}</p>)
                        }
                    )
                }
            } else {
                if (creditInfo.target.user === undefined) {
                    props.showAlert(t("Send tokens"), <p>{t("Select a target")}</p>)
                }
                else {
                    var promise = adminClient.retrieveTokens(session, creditInfo.target.user, company, creditInfo.amount * 100, creditInfo.currency);
                    promise.then(
                        function (response) {
                            if (response.result === "OK") {
                                refreshReports();
                                props.showAlert(t("Send tokens"), <p>{t("Tokens retrieved succesfully")}</p>)
                            } else {
                                props.showAlert(t("Send tokens error"), <p>{t("Error retrieving tokens: ") + t("ERROR_" + response.code)}</p>)
                            }
                        },
                        function (err) {
                            console.error(err);
                            props.showAlert(t("Send tokens error"), <p>{t("Error sending tokens: ") + t("ERROR_" + err.description)}</p>)
                        }
                    )
                }
            }
        }
    }
    const tokensRender = () => {
        return (
            <>
                <form id="Form_AgentsActivity">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <BasicSelect
                                idProperty='type'
                                value={creditInfo.actionType}
                                name='actionType'
                                label={t('I want to')}
                                addOpts={[{ type: "SEND", name: "SEND" }, { type: "RETRIEVE", name: "RETRIEVE" }]}
                                onChange={onChangeHandler}
                                translate={{ property: 'name', prefix: 'ACTION_' }}
                                multiple={false}
                                noEmptyValue={false}
                                disableClearable={true}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <BasicSelect
                                idProperty='type'
                                value={creditInfo.targetType}
                                name='targetType'
                                label={t(creditInfo.to_from)}
                                addOpts={[{ type: "CHILD", name: "Supervised" }, { type: "PARENT", name: "Responsible" }]}
                                onChange={onChangeHandler}
                                translate={{ property: 'name', prefix: '' }}
                                multiple={false}
                                noEmptyValue={false}
                                disableClearable={true}
                            />
                        </div>
                        {creditInfo.targetType === "PARENT" &&
                            <div className="col-12 col-md-4">
                                <BasicSelect
                                    idProperty='user'
                                    nameProperty="alias"
                                    value={creditInfo.target.user}
                                    name='alias'
                                    label={t('Alias')}
                                    addOpts={creditInfo.parents}
                                    onChange={onChangeHandler}
                                    required
                                />
                            </div>
                        }
                        {creditInfo.targetType === "CHILD" &&
                            <div className="col-12 col-md-4 mb-3">
                                <div className="tree-users">
                                    <TreeAdminUsers selectedItem={creditInfo.selectedChild} users={creditInfo.childs} onChange={onChangeHandler}></TreeAdminUsers>
                                </div>
                            </div>

                        }
                        <div className="col-12 col-md-6">
                            <TextField id="loyalty_weighting" label={t("the amount")} name="amount" type="text" defaultValue={utils.formatNumber(creditInfo.amount, 2)} onChange={onChangeHandler} />
                        </div>
                        <div className="col-12 col-md-6">
                            <BasicSelect
                                idProperty='currency'
                                nameProperty='currency'
                                data='currencies'
                                name="currency"
                                basicInfoName='CurrenciesCompany'
                                value={creditInfo.amount}
                                label={t('in currency')}
                                onChange={onChangeHandler}
                                required
                            />
                        </div>
                    </div>
                </form>
                {utils.isGranted('SEND_TOKENS') &&
                    <div className="row">
                        <div className="col-12 ">
                            <button className="btn btn-primary" onClick={save}>{t("ACTION_" + creditInfo.actionType)}</button>
                        </div>
                    </div>
                }
            </>
        );
    }
    const [tabsContent, setTabsContent] = useState([
        {
            title: t("My tokens"),
            content: <Report reportCSS="min-vh-auto" windowRef="windowRefAgentAccounts" reportId="agent_accounts" session={session}  ></Report>
        },
        {
            title: t("Transfer Tokens"), content:
                <>{tokensRender()}</>
        },
        {
            title: t("Tokens activity"),
            content: <Report windowRef="windowRefAgentOperations" reportId="agent_operations" session={session}  ></Report>
        }
    ]);

    useEffect(function () {
        let tabAux = [
            {
                title: t("My tokens"),
                content: <Report reportCSS="min-vh-auto" windowRef="windowRefAgentAccounts" reportId="agent_accounts" session={session}  ></Report>
            },
            {
                title: t("Transfer Tokens"), content:
                    <>{tokensRender()}</>

            },
            {
                title: t("Tokens activity"),
                content: <Report windowRef="windowRefAgentOperations" reportId="agent_operations" session={session}  ></Report>
            }
        ]
        setTabsContent(tabAux);
    }, [JSON.stringify(creditInfo)]);

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <Report className="no-min-height mb-4" windowRef="windowRefAgentSummary" reportId="agents_detailed_summary" session={session}  ></Report>
            <Report className="no-min-height mb-4" windowRef="windowRefAgentSubAgentesSummary" reportId="agents_subagents_summary" session={session}  ></Report>
            <div className='col-12'>
                <Tab active={0}>
                    {
                        tabsContent.map((tab, idx) =>
                            <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                {tab.content}
                            </Tab.TabPane>
                        )
                    }
                </Tab>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(Activity);
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';
import EditorJSON from "../../components/basic/EditorJSON";
import DateAndTimePicker from "../../components/DateAndTimePicker";



function UserNoteDetails(props) {

    const { t } = props;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    const noteId = utils.getSessionItem("current-note")
    const player = JSON.parse(utils.getSessionItem("userDetails")).user;

    const [noteDetails, setNoteDetails] = useState(
        {
            db: player.db,
            user: player.user,
            company: player.company,
            note: noteId
        }
    );

    const [addOrUpdate, setAddOrUpdate] = useState("");


    const adminClient = new AdminClient();


    useEffect(function () {
        if (noteId !== "-1") {
            adminClient.getNoteDetails(session, noteId.split(".")[0], noteId.split(".")[1]).then(
                (res) => {
                    setNoteDetails(res.data);
                    setAddOrUpdate("update");
                }, (err) => {
                    console.log(err);
                }
            );
        }

    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setNoteDetails((prevState) => ({ ...prevState, [name]: value }));
    };



    const handleSubmit = () => {
        if (formValidation.validate()) {

            let promise = adminClient.saveNote(
                session,
                JSON.stringify(noteDetails)
            );

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Correct"), <p>{t("Note saved")}</p>);
                        goBack()
                    }

                }, function (err) {
                    console.log("Note error" + err);
                    props.showAlert(t("Error"), <p>{t("Error in save Note")}</p>)
                }
            );

        }

    };


    const goBack = () => {
        props.history.push("/support/report/user_notes/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Note details"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("Form_NoteDetails");


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_NoteDetails">


                {addOrUpdate === "update" &&
                    <div className="row">
                        <div className="col-12 col-md-4 ">
                            <TextField disabled label={t("Identifier")} type="text" value={noteDetails.db + "." + noteDetails.note} />
                        </div>

                        <div className="col-12 col-md-4 ">
                            <DateAndTimePicker
                                disabled
                                renderInput={(props) => <TextField  {...props} />}
                                label={t("Created date")}
                                value={utils.formatDate(noteDetails.created_date || '')}
                                showTime={true}
                            />
                        </div>
                    </div>
                }


                <div className="row">
                    <div className="col-12">
                        <TextField required InputLabelProps={{ shrink: noteDetails.title !== undefined }} label={t("Title")} type="text" value={noteDetails.title} name="title" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12">
                        <BasicSelect
                            label="Departments"
                            idProperty="department"
                            name="department"
                            data="departments"
                            basicInfoName="Departments"
                            onChange={onChangeHandler}
                            value={noteDetails.department}
                        />
                    </div>
                    <div className="col-12">
                        <TextField required InputLabelProps={{ shrink: noteDetails.content !== undefined }} multiline label={t("Content")} type="text" name="content" value={noteDetails.content} onChange={onChangeHandler} />
                    </div>

                </div>

            </form>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                </div>
            </div>
        </LayoutSection>
    );
}

export default withTranslation()(UserNoteDetails);

import React from "react"
import DashboardPage from '../pages/General/DashboardPage'

import PageNotFound from '../pages/General/PageNotFound';
import Login from '../pages/General/Login';
import Logout from '../pages/General/Logout';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//CAMBIO REPORTS
//import ReportPage from '../pages/ReportPage';
import ReportPage from "../pages/Reports/Report";
//CAMBIO REPORTS

import AppRoute from './AppRoute';
import PrivateLayout from './NavigationAndLayouts/PrivateLayout';
import Loading from '../pages/General/Loading';
import UserSearch from "../pages/Users/Search";
import UserAccounts from "../pages/Users/Accounts";
import UserGroups from "../pages/Users/Groups";
import UserManualDeposit from "../pages/Users/ManualDeposits";
import UserManualPayout from "../pages/Users/ManualPayout";
import UserTicketDetails from "../pages/Users/TicketDetails";
import UserNotification from "../pages/UserNotification";
import UserChatDetails from "../pages/UserChatDetails";
import UserSessionDetails from "../pages/Users/SessionDetails";

import UserFileDetails from "../pages/Users/FileDetails";
import UserPromotionsDetails from "../pages/Users/PromotionDetails";
import MarketingPromotionDetails from "../pages/MarketingPromotionDetails";
import MarketingTournamentDetails from "../pages/MarketingTournamentDetails";
import BannersDetails from "../pages/CMS/BannersDetails";
import AnalysisOperationDetails from "../pages/AnalysisOperationDetails";
import AdminProfile from "../pages/General/AdminProfile";
import UserPaymentAccountDetails from "../pages/Users/PaymentAccountDetails";
import UserAgentsSearch from "../pages/Agents/UserSearch";
import AgentsUserAccounts from "../pages/Agents/UserAccounts";
import UserManualPromotion from "../pages/Users/ManualPromotion";
import NewUser from "../pages/Agents/NewUser";
import Activity from "../pages/Agents/Activity"
import UserAutoexclusionsDetails from "../pages/Users/AutoexclusionsDetails";
import UserConsentsDetails from "../pages/Users/ConsentsDetails";
import UserPromotions from "../pages/Users/Promotions";
import GlobalGroupDetails from "../pages/Setup/GlobalGroupDetails";
import PrivateProfileLayout from "./NavigationAndLayouts/PrivateProfileLayout";
import UserNoteDetails from "../pages/Users/NoteDetails";
import UserVirtualGoodDetails2 from "../pages/UserVirtualGoodDetails";
import UserVirtualGoodDetails from "../pages/Users/VirtualGoods";
import Monitor from "../pages/Monitor/Monitor";
import UserManualAdjustment from "../pages/Users/ManualAdjustment";
import BCWinningsBatch from "../pages/BCWinningsBatch";
import MonitorAlertDetails from "../pages/Monitor/MonitorAlertDetails";
import MachinesUpdateBatch from "../pages/Setup/MachinesUpdateBatch";
import DepositsRevisionBatch from "../pages/Support/DepositsRevisionBatch";
import UserUpdatePassword from "../pages/Users/UpdatePassword";
import UserChanges from "../pages/Users/Changes";
import { AuthProvider } from "../context/AuthContext";
import EzugiWinningsBatch from "../pages/EzugiWinningsBatch";
import TournamentList from "../pages/Marketing/TournamentList";
import MissionList from "../pages/Marketing/MissionList";
import PromotionList from "../pages/Marketing/PromotionList";
import MachineDetails from "../pages/Setup/MachineDetails";
import ComponentTestPage from "../pages/Test/ComponentTestPage";
import UtilsTestPage from "../pages/Test/UtilsTestPage";
import DateAndTimePickerPage from "../pages/Test/DateAndTimePickerTestPage";

import AltenarPage from "../pages/AltenarPage";
import AltenarPrint from "../pages/AltenarPrint";
import EmptyLayout from "./NavigationAndLayouts/EmptyLayout";
import AltenarRedeem from "../pages/AltenarRedeem";
import PendingBankDocumentation from "../pages/Support/PendingBankDocumentation";
import PendingBankDetails from "../pages/Support/PendingBankDetails";
import DepartmentsDetails from "../pages/Setup/DepartmentsDetails";
import UsersUpdateBatch from "../pages/Users/UsersUpdateBatch";

import i18nDetails from "../pages/Setup/I18nDetails";
import i18nCompaniesDetails from "../pages/Setup/I18nCompaniesDetails";
import EmailContentDetails from "../pages/Setup/EmailContentDetails";

//CAMBIO REPORTS
//import ReportPage from "../pages/ReportPage";
//import ReportPage from "../pages/Reports/Report";
//CAMBIO REPORTS
import ExchangesDetails from "../pages/Setup/ExchangesDetails";
import AffiliateDetails from "../pages/Marketing/AffiliateDetails";

import LockedNationalIdDetails from "../pages/Risk/LockedNationalIdDetails";

import PopupsDetails from "../pages/Marketing/PopupsDetails";
import PopupsLobbyDetails from "../pages/Marketing/PopupsLobbiesDetails";
import UserAddressesDetails from "../pages/Users/AddressesDetails";
import LoginFirst from "../pages/General/LoginFirst";
import LoginLayout from "./NavigationAndLayouts/LoginLayout";
import JackpotCategoryDetails from "../pages/Marketing/JackpotCategoryDetails";
import JackpotDetails from "../pages/Marketing/JackpotDetails";
import ConfigExternalLists from "../pages/Setup/ExternalListsDetails";
import GroupDetails from "../pages/Setup/GroupDetails";
import PaymentMethodsDetails from "../pages/Setup/PaymentMethodsDetails";
import PromotionsTagsDetails from "../pages/Marketing/PromotionsTagsDetails";
import MissionDetails from "../pages/Marketing/MissionDetails";
import lockedIpsDetails from "../pages/Risk/LockedIpsDetails";
import AllowedPlayersDetails from "../pages/Risk/AllowedPlayersDetails";
import ManualAdjustmentBatch from "../pages/Support/ManualAdjustmentBatch";




import AdminUsersDetails from "../pages/Setup/AdminUsersDetails";
import Banners from "../pages/CMS/Banners";
import BannersContainersDetails from "../pages/CMS/BannersContainersDetails";
import AllowedCountries from "../pages/Risk/AllowedCountries";
import FragmentsDetails from "../pages/CMS/FragmentsDetails";
import RedirectionsDetails from "../pages/CMS/RedirectionsDetails";
import LobbyGames from "../pages/CMS/LobbyGames";
import AltenarBetdetails from "../pages/AltenarBetdetails";
import ReportBasicPage from "../pages/Reports/ReportBasic";
import AccountsSetDetails from "../pages/Setup/AccountsSetDetails";
import InternalTasksDetails from "../pages/Setup/InternalTasksDetails";
import ProvidersDetails from "../pages/Setup/ProvidersDetails";
import RoleDetails from "../pages/Setup/RoleDetails";
import ServicesDetails from "../pages/Setup/ServicesDetails";
import TagDetails from "../pages/Setup/TagDetails";
import VirtualGoodDetails from "../pages/Setup/VirtualGoodDetails";
import WebhookDetails from "../pages/Setup/WebhookDetails";
import BetDetails from "../pages/Users/BetDetails";
import DepositsDetails from "../pages/Cashflow/DepositsDetails";
import PayoutsDetails from "../pages/Cashflow/PayoutsDetails";
import PayoutsBatch from "../pages/Cashflow/PayoutsBatch";
import Payouts from "../pages/Cashflow/Payouts";
import DepositsBatch from "../pages/Support/DepositsBatch";
import FlowsDetails from "../pages/Marketing/FlowsDetails";
import PendingDocumentation from "../pages/Support/PendingDocumentation";

import GroupsCalia from "../pages/CALIA/Groups";
import PluginPage from "../pages/General/Plugin";
import ExploreData from "../pages/CALIA/ExploreData";
import SendPopups from "../pages/Marketing/SendPopups";
import PromotionChoiceDetails from "../pages/Marketing/PromotionChoiceDetails";
import PromotionLobbyDetails from "../pages/Marketing/PromotionLobbyDetails";
import PromotionsBatch from "../pages/Marketing/PromotionsBatch";
import PromotionsTSBatch from "../pages/Marketing/PromotionsTSBatch";
import TournamentLobbyDetails from "../pages/Marketing/TournamentLobbyDetails";
import EmailTemplatesDetails from "../pages/Marketing/EmailTemplatesDetails";
import TermsConditionsDetails from "../pages/Setup/TermsConditionsDetails";
import BatchDepositUpdate from "../pages/Cashflow/BatchDepositUpdate";
import OperationPrint from "../pages/Cashiers/OperationPrint";

import UserDetails from "../pages/Users/Details"
import TournamentDetails from "../pages/Marketing/TournamentDetails";
import NegativeBalanceReset from "../pages/Support/NegativeBalanceReset";
import PromotionsCancel from "../pages/Marketing/PromotionsCancel";
import IncidentDetails from "../pages/Support/IncidentDetails";
import CompanyConfig from "../pages/Setup/CompanyConfig";
import SubagentDetails from "../pages/Agents/SubagentDetails";
import NewAgent from "../pages/Agents/NewAgent";
import AgentsSearch from "../pages/Agents/AgentsSearch";
import Global_Monitor from "../pages/Monitor/Global_Monitor";
import MyUsers from "../pages/Agents/MyUsers";
import MyAgents from "../pages/Agents/MyAgents";
/*Cashiers*/
import CashiersUserAccounts from "../pages/Cashiers/UserAccountsCashier";
import CashierActivity from "../pages/Cashiers/ActivityCashier"
import ValleTest from "../pages/Test/ValleTest";
import ValleTest2 from "../pages/Test/ValleTest2";
import AgentControl from "../pages/Agents/AgentControl";
import SimulCastPage from "../pages/SimulCastPage";
import UserAutoexclusionsVerticalDetails from "../pages/Users/AutoexclusionsVerticalDetails";
import Autoexclusions from "../pages/Users/Autoexclusions";
import CreateRetail from "../pages/Retail/CreateRetail";
import PayRetail from "../pages/Retail/PayRetail";
import VoucherPrint from "../pages/Retail/VoucherPrint";
import UserLimits from "../pages/Users/Limits";
import UserWagerLimits from "../pages/Users/WagerLimits"
import ChatCalia from "../pages/CALIA/Chat";
import MartaTest from "../pages/Test/MartaTest"
import AgentControlTree from "../pages/Agents/AgentControlTree";
import AlertBO from "../pages/Monitor/AlertBO";

class Routes extends React.Component {

    render() {
        return (
            <AuthProvider>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Loading} />
                        <Route exact path="/login" component={Login} />
                        <AppRoute exact path="/loginFirstTime" component={LoginFirst} layout={LoginLayout} />
                        <AppRoute exact path="/reports/report/:id/:view" component={ReportPage} layout={PrivateLayout} />
                        <AppRoute exact path="/reports/reportBasic/:id/:view" component={ReportBasicPage} layout={PrivateLayout} />

                        <AppRoute exact path="/dashboards/:id" component={DashboardPage} layout={PrivateLayout} />

                        <AppRoute exact path="/profile" component={AdminProfile} layout={PrivateLayout} />

                        <AppRoute exact path="/logout" component={Logout} layout={PrivateLayout} />
                        <AppRoute exact path="/home" component={DashboardPage} layout={PrivateLayout} />
                        <AppRoute exact path="/defaultHome" component={EmptyLayout} layout={PrivateLayout} />

                        <AppRoute exact path="/configuration/providers/details" component={ProvidersDetails} layout={PrivateLayout} idDoc={'ProvidersDetails'} />
                        <AppRoute exact path="/configuration/roles/details" component={RoleDetails} layout={PrivateLayout} idDoc={'RoleDetails'} />
                        <AppRoute exact path="/configuration/users/details" component={AdminUsersDetails} layout={PrivateLayout} idDoc={'AdminUsersDetails'} />
                        <AppRoute exact path="/configuration/accounts_sets/details" component={AccountsSetDetails} layout={PrivateLayout} idDoc={'AccountsSetDetails'} />
                        <AppRoute exact path="/configuration/machines/details" component={MachineDetails} layout={PrivateLayout} idDoc={'MachineDetails'} />
                        <AppRoute exact path="/configuration/groups/details" component={GroupDetails} layout={PrivateLayout} idDoc={'GroupDetails'} />
                        <AppRoute exact path="/configuration/global_groups/details" component={GlobalGroupDetails} layout={PrivateLayout} idDoc={'GlobalGroupDetails'} />
                        <AppRoute exact path="/configuration/webhooks/details" component={WebhookDetails} layout={PrivateLayout} idDoc={'WebhookDetails'} />
                        <AppRoute exact path="/configuration/payment_methods/details" component={PaymentMethodsDetails} layout={PrivateLayout} idDoc={'PaymentMethodsDetails'} />

                        <AppRoute exact path="/configuration/services/details" component={ServicesDetails} layout={PrivateLayout} idDoc={'ServicesDetails'} />
                        <AppRoute exact path="/configuration/external_lists/details" component={ConfigExternalLists} layout={PrivateLayout} idDoc={'ConfigExternalLists'} />

                        <AppRoute exact path="/configuration/internal_tasks/details" component={InternalTasksDetails} layout={PrivateLayout} idDoc={'InternalTasksDetails'} />
                        <AppRoute exact path="/configuration/virtual_goods/details" component={VirtualGoodDetails} layout={PrivateLayout} idDoc={'VirtualGoodDetails'} />

                        <AppRoute exact path="/configuration/machinesUpdateBatch" component={MachinesUpdateBatch} layout={PrivateLayout} idDoc={'MachinesUpdateBatch'} />
                        <AppRoute exact path="/configuration/promotions/tags_details" component={PromotionsTagsDetails} layout={PrivateLayout} idDoc={'PromotionsTagsDetails'} />
                        <AppRoute exact path="/configuration/email_content/details" component={EmailContentDetails} layout={PrivateLayout} idDoc={'EmailContentDetails'} />

                        <AppRoute exact path="/configuration/tags/details" component={TagDetails} layout={PrivateLayout} idDoc={'TagDetails'} />

                        <AppRoute exact path="/configuration/departments/details" component={DepartmentsDetails} layout={PrivateLayout} idDoc={'DepartmentsDetails'} />
                        <AppRoute exact path="/configuration/exchanges/details" component={ExchangesDetails} layout={PrivateLayout} idDoc={'ExchangesDetails'} />

                        <AppRoute exact path="/configuration/i18n/details" component={i18nDetails} layout={PrivateLayout} idDoc={'i18nDetails'} />
                        <AppRoute exact path="/configuration/i18nCompanies/details" component={i18nCompaniesDetails} layout={PrivateLayout} idDoc={'i18nCompaniesDetails'} />

                        <AppRoute exact path="/configuration/terms_and_conditions/details" component={TermsConditionsDetails} layout={PrivateLayout} idDoc={'TermsConditionsDetails'} />
                        <AppRoute exact path="/configuration/companyConfig" component={CompanyConfig} layout={PrivateLayout} idDoc={'CompanyConfig'} />


                        <AppRoute exact path="/marketing/promotions/promotions" component={PromotionList} layout={PrivateLayout} idDoc={'PromotionList'} />
                        <AppRoute exact path="/marketing/promotions/details" component={MarketingPromotionDetails} layout={PrivateLayout} idDoc={'MarketingPromotionDetails'} />
                        <AppRoute exact path="/marketing/promotions/details/:promoId" component={MarketingPromotionDetails} layout={PrivateLayout} idDoc={'MarketingPromotionDetails'} />
                        <AppRoute exact path="/marketing/affiliates/details" component={AffiliateDetails} layout={PrivateLayout} idDoc={'AffiliateDetails'} />
                        <AppRoute exact path="/marketing/tournaments/tournaments" component={TournamentList} layout={PrivateLayout} idDoc={'TournamentList'} />
                        <AppRoute exact path="/marketing/missions/missions" component={MissionList} layout={PrivateLayout} idDoc={'MissionList'} />
                        <AppRoute exact path="/marketing/missions/details" component={MissionDetails} layout={PrivateLayout} idDoc={'MissionDetails'} />

                        <AppRoute exact path="/marketing/flows/flow_details" component={FlowsDetails} layout={PrivateLayout} idDoc={'FlowsDetails'} />
                        <AppRoute exact path="/marketing/tournaments/details2" component={MarketingTournamentDetails} layout={PrivateLayout} idDoc={'MarketingTournamentDetails'} />
                        <AppRoute exact path="/marketing/tournaments/details" component={TournamentDetails} layout={PrivateLayout} idDoc={'TournamentDetails'} />
                        <AppRoute exact path="/marketing/tournaments/details/:tourId" component={MarketingTournamentDetails} layout={PrivateLayout} idDoc={'MarketingTournamentDetails'} />
                        <AppRoute exact path="/marketing/promotions/lobby_details" component={PromotionLobbyDetails} layout={PrivateLayout} idDoc={'PromotionLobbyDetails'} />
                        <AppRoute exact path="/marketing/promotions/batch" component={PromotionsBatch} layout={PrivateLayout} idDoc={'PromotionsBatch'} />
                        <AppRoute exact path="/marketing/promotions/batchTS" component={PromotionsTSBatch} layout={PrivateLayout} idDoc={'PromotionsTSBatch'} />

                        <AppRoute exact path="/marketing/promotions/choices_details" component={PromotionChoiceDetails} layout={PrivateLayout} idDoc={'PromotionChoiceDetails'} />
                        <AppRoute exact path="/marketing/promotions/cancel" component={PromotionsCancel} layout={PrivateLayout} idDoc={'PromotionsCancel'} />

                        <AppRoute exact path="/marketing/tournaments/lobby_details" component={TournamentLobbyDetails} layout={PrivateLayout} idDoc={'TournamentLobbyDetails'} />
                        <AppRoute exact path="/marketing/emails/templates_details" component={EmailTemplatesDetails} layout={PrivateLayout} idDoc={'EmailTemplatesDetails'} />
                        <AppRoute exact path="/marketing/popups/send" component={SendPopups} layout={PrivateLayout} idDoc={'SendPopups'} />

                        <AppRoute exact path="/marketing/popups/details" component={PopupsDetails} layout={PrivateLayout} idDoc={'PopupsDetails'} />
                        <AppRoute exact path="/marketing/popups/popup_lobby_details" component={PopupsLobbyDetails} layout={PrivateLayout} idDoc={'PopupsLobbyDetails'} />
                        <AppRoute exact path="/marketing/jackpots/categories_details" component={JackpotCategoryDetails} layout={PrivateLayout} idDoc={'JackpotCategoryDetails'} />

                        <AppRoute exact path="/marketing/jackpots/categories/details" component={JackpotDetails} layout={PrivateLayout} idDoc={'JackpotDetails'} />

                        <AppRoute exact path="/cms/lobbies/details" component={LobbyGames} layout={PrivateLayout} idDoc={'LobbyGames'} />

                        <AppRoute exact path="/cms/banners/banner_details" component={BannersDetails} layout={PrivateLayout} idDoc={'BannersDetails'} />
                        <AppRoute exact path="/cms/fragments/fragment_details" component={FragmentsDetails} layout={PrivateLayout} idDoc={'FragmentsDetails'} />
                        <AppRoute exact path="/cms/banners/banner_container_details" component={BannersContainersDetails} layout={PrivateLayout} idDoc={'BannersContainersDetails'} />
                        <AppRoute exact path="/cms/banners" component={Banners} layout={PrivateLayout} idDoc={'Banners'} />
                        <AppRoute exact path="/cms/redirections/details" component={RedirectionsDetails} layout={PrivateLayout} idDoc={'RedirectionsDetails'} />

                        <AppRoute exact path="/cashflow/payouts/details/:userId" component={PayoutsDetails} layout={PrivateProfileLayout} idDoc={'PayoutsDetails'} />
                        <AppRoute exact path="/cashflow/payouts/details" component={PayoutsDetails} layout={PrivateProfileLayout} idDoc={'PayoutsDetails'} />
                        <AppRoute exact path="/cashflow/deposits/details" component={DepositsDetails} layout={PrivateProfileLayout} idDoc={'DepositsDetails'} />
                        <AppRoute exact path="/cashflow/payouts" component={Payouts} layout={PrivateLayout} idDoc={'Payouts'} />
                        <AppRoute exact path="/cashflow/payoutsBatch" component={PayoutsBatch} layout={PrivateLayout} idDoc={'PayoutsBatch'} />
                        <AppRoute exact path="/cashflow/BatchDepositUpdate" component={BatchDepositUpdate} layout={PrivateLayout} idDoc={'BatchDepositUpdate'} />


                        <AppRoute exact path="/support/locked_national_ids/details" component={LockedNationalIdDetails} layout={PrivateLayout} idDoc={'LockedNationalIdDetails'} />

                        <AppRoute exact path="/support/allowed_countries" component={AllowedCountries} layout={PrivateLayout} idDoc={'AllowedCountries'} />

                        <AppRoute exact path="/support/user_search" component={UserSearch} layout={PrivateLayout} idDoc={'UserSearch'} />
                        <AppRoute exact path="/support/user_details" component={UserDetails} layout={PrivateProfileLayout} idDoc={'UserDetails'} />
                        <AppRoute exact path="/support/user_details/:userId" component={UserDetails} layout={PrivateProfileLayout} idDoc={'UserDetails'} />

                        <AppRoute exact path="/support/user_accounts" component={UserAccounts} layout={PrivateProfileLayout} idDoc={'UserAccounts'} />
                        <AppRoute exact path="/support/user_promotions" component={UserPromotions} layout={PrivateProfileLayout} idDoc={'UserPromotions'} />
                        <AppRoute exact path="/support/user_chat_details" component={UserChatDetails} layout={PrivateProfileLayout} idDoc={'UserChatDetails'} />
                        <AppRoute exact path="/support/user_payment_accounts_details" component={UserPaymentAccountDetails} layout={PrivateProfileLayout} idDoc={'UserPaymentAccountDetails'} />
                        <AppRoute exact path="/support/user_autoexclusions_details" component={UserAutoexclusionsDetails} layout={PrivateProfileLayout} idDoc={'UserAutoexclusionsDetails'} />
                        <AppRoute exact path="/support/user_autoexclusions_vertical_details" component={UserAutoexclusionsVerticalDetails} layout={PrivateProfileLayout} idDoc={'UserAutoexclusionsVerticalDetails'} />
                        <AppRoute exact path="/support/user_consents_details" component={UserConsentsDetails} layout={PrivateProfileLayout} idDoc={'UserConsentsDetails'} />
                        <AppRoute exact path="/support/user_virtual_good_details2" component={UserVirtualGoodDetails2} layout={PrivateProfileLayout} idDoc={'UserVirtualGoodDetails2'} />
                        <AppRoute exact path="/support/user_virtual_good_details" component={UserVirtualGoodDetails} layout={PrivateProfileLayout} idDoc={'UserVirtualGoodDetails'} />
                        <AppRoute exact path="/support/user_autoexclusions" component={Autoexclusions} layout={PrivateProfileLayout} idDoc={'Autoexclusions'} />

                        <AppRoute exact path="/support/pending_documentation" component={PendingDocumentation} layout={PrivateLayout} idDoc={'PendingDocumentation'} />
                        <AppRoute exact path="/support/pending_bank_documentation" component={PendingBankDocumentation} layout={PrivateLayout} idDoc={'PendingBankDocumentation'} />
                        <AppRoute exact path="/support/pending_bank_details" component={PendingBankDetails} layout={PrivateLayout} idDoc={'PendingBankDetails'} />
                        <AppRoute exact path="/support/bc_winnings_batch" component={BCWinningsBatch} layout={PrivateLayout} idDoc={'BCWinningsBatch'} />
                        <AppRoute exact path="/support/ezugi_winnings_batch" component={EzugiWinningsBatch} layout={PrivateLayout} idDoc={'EzugiWinningsBatch'} />
                        <AppRoute exact path="/support/manual_batch" component={ManualAdjustmentBatch} layout={PrivateLayout} idDoc={'ManualAdjustmentBatch'} />
                        <AppRoute exact path="/support/negative_balance_reset" component={NegativeBalanceReset} layout={PrivateLayout} idDoc={'NegativeBalanceReset'} />

                        <AppRoute exact path="/support/deposits_revision_batch" component={DepositsRevisionBatch} layout={PrivateLayout} idDoc={'DepositsRevisionBatch'} />
                        <AppRoute exact path="/support/manual_deposits_batch" component={DepositsBatch} layout={PrivateLayout} idDoc={'DepositsBatch'} />
                        <AppRoute exact path="/support/bet_details" component={BetDetails} layout={PrivateProfileLayout} idDoc={'BetDetails'} />
                        <AppRoute exact path="/support/users_update_batch" component={UsersUpdateBatch} layout={PrivateLayout} idDoc={'UsersUpdateBatch'} />

                        <AppRoute exact path="/support/user_update_password" component={UserUpdatePassword} layout={PrivateProfileLayout} idDoc={'UserUpdatePassword'} />

                        <AppRoute exact path="/support/locked_ips/details" component={lockedIpsDetails} layout={PrivateLayout} idDoc={'lockedIpsDetails'} />
                        <AppRoute exact path="/support/allowed_players/details" component={AllowedPlayersDetails} layout={PrivateLayout} idDoc={'AllowedPlayersDetails'} />

                        <AppRoute exact path="/monitor/alerts/details" component={MonitorAlertDetails} layout={PrivateLayout} idDoc={'MonitorAlertDetails'} />
                        <AppRoute exact path="/monitor/alertBO" component={AlertBO} layout={PrivateLayout} idDoc={'MonitorAlertBO'} />

                        <AppRoute exact path="/support/user_addresses_details" component={UserAddressesDetails} layout={PrivateProfileLayout} idDoc={'UserAddressesDetails'} />
                        <AppRoute exact path="/support/user_limits" component={UserLimits} layout={PrivateProfileLayout} idDoc={'UserLimits'} />
                        <AppRoute exact path="/support/user_wager_limits" component={UserWagerLimits} layout={PrivateProfileLayout} idDoc={'UserWagerLimits'} />
                        <AppRoute exact path="/support/user_ticket_details" component={UserTicketDetails} layout={PrivateProfileLayout} idDoc={'UserTicketDetails'} />
                        <AppRoute exact path="/support/user_manual_deposit" component={UserManualDeposit} layout={PrivateProfileLayout} idDoc={'UserManualDeposit'} />
                        <AppRoute exact path="/support/user_manual_payout" component={UserManualPayout} layout={PrivateProfileLayout} idDoc={'UserManualPayout'} />
                        <AppRoute exact path="/support/user_manual_adjustment" component={UserManualAdjustment} layout={PrivateProfileLayout} idDoc={'UserManualAdjustment'} />

                        <AppRoute exact path="/support/user_manual_promotion" component={UserManualPromotion} layout={PrivateProfileLayout} idDoc={'UserManualPromotion'} />

                        <AppRoute exact path="/support/user_notification" component={UserNotification} layout={PrivateProfileLayout} idDoc={'UserNotification'} />
                        <AppRoute exact path="/support/user_groups" component={UserGroups} layout={PrivateProfileLayout} idDoc={'UserGroups'} />
                        <AppRoute exact path="/support/user_file_details" component={UserFileDetails} layout={PrivateProfileLayout} idDoc={'UserFileDetails'} />
                        <AppRoute exact path="/support/user_note_details" component={UserNoteDetails} layout={PrivateProfileLayout} idDoc={'UserNoteDetails'} />
                        <AppRoute exact path="/support/user_session_details" component={UserSessionDetails} layout={PrivateProfileLayout} idDoc={'UserSessionDetails'} />
                        <AppRoute exact path="/support/report/:id/:view" component={ReportPage} layout={PrivateProfileLayout} idDoc={'ReportPage'} />
                        <AppRoute exact path="/support/reportBasic/:id/:view" component={ReportBasicPage} layout={PrivateProfileLayout} idDoc={'ReportBasicPage'} />
                        <AppRoute exact path="/support/user_promotion_details" component={UserPromotionsDetails} layout={PrivateProfileLayout} idDoc={'UserPromotionsDetails'} />
                        <AppRoute exact path="/support/user_changes" component={UserChanges} layout={PrivateProfileLayout} idDoc={'UserChanges'} />
                        <AppRoute exact path="/support/incidents/details" component={IncidentDetails} layout={PrivateLayout} idDoc={'IncidentDetails'} />

                        {/* POSIBLE BORRADO / BORRAR */}
                        <AppRoute exact path="/analysis/operation_details" component={AnalysisOperationDetails} layout={PrivateLayout} idDoc={'AnalysisOperationDetails'} />

                        <AppRoute exact path="/agents/user_search" component={UserAgentsSearch} layout={PrivateLayout} idDoc={'UserAgentsSearch'} />
                        <AppRoute exact path="/agents/agents_search" component={AgentsSearch} layout={PrivateLayout} idDoc={'AgentsSearch'} />
                        <AppRoute exact path="/agents/user_accounts/:db/:id" component={AgentsUserAccounts} layout={PrivateLayout} idDoc={'AgentsUserAccounts'} />
                        <AppRoute exact path="/agents/user_accounts" component={AgentsUserAccounts} layout={PrivateLayout} idDoc={'AgentsUserAccounts'} />
                        <AppRoute exact path="/agents/my_users" component={MyUsers} layout={PrivateLayout} idDoc={'MyUsers'} />
                        <AppRoute exact path="/agents/my_agents" component={MyAgents} layout={PrivateLayout} idDoc={'MyAgents'} />
                        <AppRoute exact path="/agents/agent_control" component={AgentControl} layout={PrivateLayout} idDoc={'AgentControl'} />
                        <AppRoute exact path="/agents/agent_control_tree" component={AgentControlTree} layout={PrivateLayout} />



                        <AppRoute exact path="/agents/new_user" component={NewUser} layout={PrivateLayout} idDoc={'NewUser'} />
                        <AppRoute exact path="/agents/altenar_page" component={AltenarPage} layout={PrivateLayout} idDoc={'AltenarPage'} />
                        <AppRoute exact path="/agents/simulcast_page" component={SimulCastPage} layout={PrivateLayout} idDoc={'SimulCastPage'} />
                        <AppRoute exact path="/agents/altenar_print" component={AltenarPrint} layout={EmptyLayout} idDoc={'AltenarPrint'} />
                        <AppRoute exact path="/agents/simulcast_print" component={AltenarPrint} layout={EmptyLayout} idDoc={'AltenarPrint'} />
                        <AppRoute exact path="/agents/altenar_redeem" component={AltenarRedeem} layout={PrivateLayout} idDoc={'AltenarRedeem'} />
                        <AppRoute exact path="/agents/simulcast_redeem" component={AltenarRedeem} layout={PrivateLayout} idDoc={'AltenarRedeem'} />
                        <AppRoute exact path="/agents/altenar_bet_details" component={AltenarBetdetails} layout={PrivateLayout} idDoc={'AltenarBetdetails'} />
                        <AppRoute exact path="/agents/simulcast_bet_details" component={AltenarBetdetails} layout={PrivateLayout} idDoc={'AltenarBetdetails'} />
                        <AppRoute exact path="/agents/new_agent" component={NewAgent} layout={PrivateLayout} idDoc={'NewAgent'} />
                        <AppRoute exact path="/agents/bet_details" component={BetDetails} layout={PrivateLayout} idDoc={'BetDetails'} />


                        <AppRoute exact path="/agents/activity" component={Activity} layout={PrivateLayout} idDoc={'Activity'} />
                        <AppRoute exact path="/agents/operationPrint" component={OperationPrint} layout={EmptyLayout} idDoc={'OperationPrint'} />
                        <AppRoute exact path="/agents/subagents/details" component={SubagentDetails} layout={PrivateLayout} idDoc={'SubagentDetails'} />
                        <AppRoute exact path="/agents/subagents/details/:id" component={SubagentDetails} layout={PrivateLayout} idDoc={'SubagentDetails'} />

                        <AppRoute exact path="/global_monitor/monitor" component={Global_Monitor} layout={PrivateLayout} idDoc={'Global_Monitor'} />
                        <AppRoute exact path="/monitor/monitor" component={Monitor} layout={PrivateLayout} idDoc={'Monitor'} />
                        <AppRoute exact path="/test" component={ComponentTestPage} layout={PrivateLayout} idDoc={'ComponentTestPage'} />
                        <AppRoute exact path="/test2" component={UtilsTestPage} layout={PrivateLayout} idDoc={'UtilsTestPage'} />
                        <AppRoute exact path="/test3" component={DateAndTimePickerPage} layout={PrivateLayout} idDoc={'DateAndTimePickerPage'} />
                        <AppRoute exact path="/valleTest" component={ValleTest} layout={PrivateLayout} idDoc={'ValleTest'} />
                        <AppRoute exact path="/valleTest2" component={ValleTest2} layout={PrivateLayout} idDoc={'ValleTest2'} />
                        <AppRoute exact path="/martaTest" component={MartaTest} layout={PrivateLayout} />

                        <AppRoute exact path="/calia/groups" component={GroupsCalia} layout={PrivateLayout} idDoc={'GroupsCalia'} />
                        <AppRoute exact path="/calia/exploreData" component={ExploreData} layout={PrivateLayout} idDoc={'ExploreData'} />


                        <AppRoute exact path="/plugin/:id/:url" component={PluginPage} layout={PrivateLayout} idDoc={'PluginPage'} />

                        /**Cashiers */
                        <AppRoute exact path="/cashiers/user_accounts" component={CashiersUserAccounts} layout={PrivateLayout} idDoc={'CashiersUserAccounts'} />
                        <AppRoute exact path="/cashiers/activity" component={CashierActivity} layout={PrivateLayout} idDoc={'CashierActivity'} />
                        <AppRoute exact path="/cashiers/operationPrint" component={OperationPrint} layout={EmptyLayout} idDoc={'OperationPrint'} />
                        <AppRoute exact path="/retail/createRetail" component={CreateRetail} layout={PrivateLayout} idDoc={'CreateRetail'} />
                        <AppRoute exact path="/retail/payRetail" component={PayRetail} layout={PrivateLayout} idDoc={'PayRetail'} />
                        <AppRoute exact path="/retail/voucherPrint" component={VoucherPrint} layout={EmptyLayout} idDoc={'VoucherPrint'} />

                        <AppRoute exact path="/calia/chat" component={ChatCalia} layout={PrivateLayout} idDoc={'ChatCalia'} />

                        <Route component={PageNotFound} />
                    </Switch>
                </Router>
            </AuthProvider>
        )
        //}
    }
}

export default Routes


import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
function PromotionChoiceDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PromotionChoiceDetails')
    const choiceId = utils.getSessionItem('current-choice')
    const [choiceDetails, setChoiceDetails] = useState({
        choice: "",
        description: ""
    });
    const goBack = () => {
        props.history.push("/reports/report/promotions_choices/table");
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Promotion Choice Details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (choiceId !== undefined && choiceId !== '-1') {
            adminClient.getPromotionChoiceDetails(session, company, choiceId).then(
                async function (result) {
                    setChoiceDetails(result.data);
                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setChoiceDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.savePromotionChoiceDetails(
                session,
                company,
                JSON.stringify(choiceDetails)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Promotion Choice Details'), t('Saved succesfull'))
                    }
                },
                (error) => {
                    props.showAlert(t('Promotion Choice Details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PromotionChoiceDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="choice"
                            required
                            disabled
                            value={choiceDetails.choice}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-9">
                        <TextField
                            label={t('Description')}
                            name="description"
                            required
                            type="text"
                            value={choiceDetails.description}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={save}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(PromotionChoiceDetails);
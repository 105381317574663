import React from 'react';

import utils from '../../utils';

function isMoreThanAge(birthDayValue, ageValue) {

    const birthDay = utils.getDateServerToTZObj(birthDayValue)

    // Obtener la fecha actual
    const currentDate = utils.getDateTZTodayObj();

    // Calcular la edad en años
    let age = currentDate.getFullYear() - birthDay.getFullYear();

    // Ajustar la edad si la fecha de nacimiento aún no ha ocurrido este año
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const birthdayMonth = birthDay.getMonth();
    const birthdayDay = birthDay.getDate();

    if (currentMonth < birthdayMonth || (currentMonth === birthdayMonth && currentDay < birthdayDay)) {
        age--;
    }

    // Verificar si la edad es mayor o igual a 18 años
    return age >= ageValue;
}

let parsleyValidationSPAIN = {
    playerNationalIdType: {
        requirementType: ["String", "String"],
        validateString: function (value, oldNationalId, newNationalId) {

            if (oldNationalId === '' || oldNationalId === undefined || oldNationalId === null) return true;
            let validate = true
            if (oldNationalId !== 'DNI' && oldNationalId !== 'NIE' && (newNationalId === 'DNI' || newNationalId === 'NIE')) validate = false
            return validate
        }
    },
    playerNationality: {
        requirementType: ["String", "String"],
        validateString: function (value, nationalIdType, nationality) {
            if (nationalIdType === '' || nationalIdType === undefined || nationalIdType === null) return true;
            let validate = false
            if (nationalIdType === 'DNI' && nationality === 'ES')
                validate = true
            else if (nationalIdType !== 'DNI' && nationality !== 'ES')
                validate = true

            return validate
        }
    },
    playerCountry: {
        requirementType: ["String", "String", "String"],
        validateString: function (value, nationalIdType, nationality, country) {
            if (nationalIdType === '' || nationalIdType === undefined || nationalIdType === null) return true;
            let validate = true
            if (nationalIdType === 'NIE' && nationality !== 'ES' && country !== 'ES')
                validate = false
            else if (nationalIdType !== 'NIE' && nationalIdType !== 'DNI' && nationality !== 'ES' && country === 'ES')
                validate = false
            return validate
        }
    },
    playerVerificationType: {
        requirementType: ["String", "String"],
        validateString: function (value, verified, verificationType) {
            if (verified === 1 || verified === true) {
                return verificationType !== '' && verificationType !== undefined && verificationType !== null
            } else return true
        }
    },
    playerBirthDay: {
        requirementType: ["String"],
        validateString: function (value, serverValue) {

            return isMoreThanAge(serverValue, 18)
        }
    }
}

let parsleyValidationPERU = {
    playerNationalIdType: {
        requirementType: ["String", "String"],
        validateString: function (value, oldNationalId, newNationalId) {
            if (oldNationalId === '' || oldNationalId === undefined || oldNationalId === null) return true;
            let validate = true
            if (oldNationalId !== 'DNI' && oldNationalId !== 'CARNET_EXTRANJERIA' && oldNationalId !== 'EXTRANJERIA' && (newNationalId === 'DNI' || newNationalId === 'CARNET_EXTRANJERIA' || newNationalId === 'EXTRANJERIA')) validate = false
            return validate
        }
    },
    playerNationality: {
        requirementType: ["String", "String"],
        validateString: function (value, nationalIdType, nationality) {
            if (nationalIdType === '' || nationalIdType === undefined || nationalIdType === null) return true;
            let validate = false
            if (nationalIdType === 'DNI' && nationality === 'PE') validate = true
            else if (nationalIdType !== 'DNI' && nationality !== 'PE') validate = true
            return validate
        }
    },
    playerCountry: {
        requirementType: ["String", "String", "String"],
        validateString: function (value, nationalIdType, nationality, country) {
            if (nationalIdType === '' || nationalIdType === undefined || nationalIdType === null) return true;
            let validate = true
            // if (country === 'PE') validate = true
            // else validate = false
            // return validate

            // if ((nationalIdType === 'EXTRANJERIA' || nationalIdType === 'CARNET_EXTRANJERIA') && nationality !== 'PE' && country !== 'PE') validate = false
            // else
            //     if ((nationalIdType !== 'EXTRANJERIA' && nationalIdType !== 'CARNET_EXTRANJERIA') && nationalIdType !== 'DNI' && nationality !== 'PE' && country === 'PE')
            //         validate = false
            // return validate

            if ((nationalIdType === 'EXTRANJERIA' || nationalIdType === 'CARNET_EXTRANJERIA' || nationalIdType === 'DNI') && country !== 'PE') validate = false
            return validate


        }
    },
    playerBirthDay: {
        requirementType: ["String"],
        validateString: function (value, serverValue) {



            return isMoreThanAge(serverValue, 18)
        }
    }
}
export { parsleyValidationSPAIN, parsleyValidationPERU };
import { InputAdornment, Stack, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import DateAndTimePicker from '../DateAndTimePicker';
import BasicSelect from './BasicSelect';
import utils from "../../utils";
import momentTimeZone from 'moment-timezone';
import moment from 'moment'

import useFormValidation from "../../hooks/parsleyValidation";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function DateTimeRangeFilter(props) {


  const firstDayOfWeek = useMemo(() => {

    let locale = utils.getSessionItem('calimaco-lang')
    return locale == "en-US" ? 0 : 1

  })

  const getCurrentWeek = (date) => {

    //por defecto la semana comienza en Domingo    
    let currentDate = new Date(date);
    let dayOfWeek = currentDate.getDay();
    let difference = dayOfWeek - firstDayOfWeek;
    difference = (difference + 7) % 7;
    let first = new Date(currentDate);
    first.setDate(currentDate.getDate() - difference);
    let last = new Date(currentDate);
    last.setDate(currentDate.getDate() + (6 - difference));
    if (props.showTime) {
      last.setHours(23)
      last.setMinutes(59)
      last.setSeconds(59)
    }
    return [first, last]

  }
  const getCurrentMonth = (date) => {
    let currentDate = new Date(date);
    let first = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let last = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    if (props.showTime) {
      last.setHours(23)
      last.setMinutes(59)
      last.setSeconds(59)
    }
    return [first, last]

  }

  const getValues = (values) => {
    if (values[0] === null) values[0] = ''
    if (values[1] === null) values[1] = ''
    return values
  }
  const [selectedRange, setSelectedRange] = useState(getValues(props.value))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPeriodsConf = () => {


    let dateInit = new Date()
    let dateEnd = new Date()
    if (props.showTime) {
      dateInit.setHours(0)
      dateInit.setMinutes(0)
      dateInit.setSeconds(0)
      dateEnd.setHours(23)
      dateEnd.setMinutes(59)
      dateEnd.setSeconds(59)
    }

    return (
      [
        {
          name: props.t("Today"), func: () => {
            setSelectedRange([dateInit, dateEnd])
            props.callBack([dateInit, dateEnd], props.filterConf.field)
            props.handleNotifiyError()
          }, period: 1
        },
        {
          name: props.t("Yesterday"), func: () => {

            let dInit = moment(dateInit).subtract(1, 'days').toDate()
            let dEnd = moment(dateEnd).subtract(1, 'days').toDate()
            setSelectedRange([dInit, dEnd])
            props.callBack([dInit, dEnd], props.filterConf.field)
            props.handleNotifiyError()
          }, period: 1
        },
        {
          name: `7 ${props.t("Days")}`, func: () => {
            let dInit = moment(dateInit).subtract(7, 'days').toDate()
            setSelectedRange([dInit, dateEnd])
            props.callBack([dInit, dateEnd], props.filterConf.field)
            props.handleNotifiyError()
          }, period: 7
        },
        {
          name: `14 ${props.t("Days")}`, func: () => {
            let dInit = moment(dateInit).subtract(14, 'days').toDate()
            setSelectedRange([dInit, dateEnd])
            props.callBack([dInit, dateEnd], props.filterConf.field)
            props.handleNotifiyError()
          }, period: 14
        },
        {
          name: `30 ${props.t("Days")}`, func: () => {
            let dInit = moment(dateInit).subtract(30, 'days').toDate()
            setSelectedRange([dInit, dateEnd])
            props.callBack([dInit, dateEnd], props.filterConf.field)
            props.handleNotifiyError()
          }, period: 30
        },
        {
          name: props.t("This week"), func: () => {
            let dates = getCurrentWeek(dateInit)
            setSelectedRange(dates)
            props.callBack(dates, props.filterConf.field)
            props.handleNotifiyError()
          }, period: 7
        },
        {
          name: props.t("1 week ago"), func: () => {
            let dates = getCurrentWeek(moment(dateInit).subtract(7, 'days').toDate())
            setSelectedRange(dates)
            props.callBack(dates, props.filterConf.field)
            props.handleNotifiyError()
          }, period: 7
        },
        {
          name: props.t("This month"), func: () => {
            let dates = getCurrentMonth(dateInit)
            setSelectedRange(dates)
            props.callBack(dates, props.filterConf.field)
            props.handleNotifiyError()
          }, period: 31
        },
        {
          name: props.t("1 month ago"), func: () => {
            let dates = getCurrentMonth(moment(dateInit).subtract(1, "months").toDate())
            setSelectedRange(dates)
            props.callBack(dates, props.filterConf.field)
            props.handleNotifiyError()
          }, period: 31
        }
      ])

  }

  const periodsConf = getPeriodsConf()


  const onChangeInitDate = (value, fieldName) => {

    if (value === null) value = ""
    let values = [...selectedRange]
    values[0] = value
    setSelectedRange(values)

    props.callBack(values, props.filterConf.field)

  }
  const onChangeEndDate = (value, fieldName) => {

    if (value === null) value = ""
    let values = [...selectedRange]
    values[1] = value
    setSelectedRange(values)

    props.callBack(values, props.filterConf.field)

  }

  useEffect(() => {
    if (props.validateForm) {
      props.validateForm.reset()
      props.validateForm.refresh()
      props.validateForm.validate()

    }
  }, [props.value, selectedRange])

  return (<>
    <div className="row">
      <div className='col-md-5'>
        <DateAndTimePicker
          handleNotifyError={props.handleNotifiyError}
          required={props.required}
          validations={props.validations}
          disabled={props.disabled}
          key={"date_init_" + props.filterConf.field}
          label={props.t(props.filterConf.label)}
          value={selectedRange[0]}
          fieldName={props.filterConf.field}
          showTime={props.showTime}
          callBackFunction={onChangeInitDate}
        />
      </div>
      <div className="col-md-5">
        <DateAndTimePicker
          handleNotifyError={props.handleNotifiyError}
          required={props.required}
          validations={props.validations}
          key={"date_end_" + props.filterConf.field}
          label={props.t("To")}
          disabled={props.disabled}
          value={selectedRange[1]}
          fieldName={props.filterConf.field}
          showTime={props.showTime}
          callBackFunction={onChangeEndDate}
        />
      </div>
      <div className='col-md-2'>
        <IconButton style={{ 'margin-top': 6 }}
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon="cog" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {periodsConf.map((element) => {
            let allowMaxPeriod = true
            if ((props.filterConf.validation !== undefined && props.filterConf.validation["max-period"] !== undefined)) {
              allowMaxPeriod = (parseInt(props.filterConf.validation["max-period"]) >= element.period)
            }
            return (allowMaxPeriod && <MenuItem onClick={() => {
              handleClose()
              element.func()
              if (props.validateForm) {
                props.validateForm.reset()
                props.validateForm.refresh()
                props.validateForm.validate()
              }
            }}>{element.name}</MenuItem>)

          })}
        </Menu>
      </div>
    </div>
  </>
  )
}

const FilterReport = (props) => {

  const { filterConfig, filterData: filterDataInit, viewFilter, reportId, handleFilterData, executeReport } = props
  const { t } = props

  const selectOptions = useMemo(() => { return [{ id: ">", name: ">" }, { id: ">=", name: ">=" }, { id: "=", name: "=" }, { id: "<=", name: "<=" }, { id: "<", name: "<" }, { id: "in", name: "In" }, { id: "notin", name: "Not in" }] })

  const getFilterData = (filters) => {

    let filterList = {}
    filters.forEach(element => {

      filterList[element.field] = element

    });
    return filterList;
  }

  const [filterData, setFilterData] = useState(getFilterData(filterDataInit))

  const filterView = useMemo(() => {
    let filterVisibility = {}
    if (viewFilter !== undefined && viewFilter.length > 0) {
      for (let i = 0; i < viewFilter.length; i++) {
        const filter = viewFilter[i];
        filterVisibility[filter.field] = filter.visibility
      }
    }
    return filterVisibility;
  }, [viewFilter]);

  //definimos nuestras funciones de validación personales
  const validationFunctions = useMemo(() => {
    return {
      'maxPeriod': {
        requirementType: ["String", "String"],
        validateString: function (value, vPeriod, fname) {

          if (filterData[fname] !== undefined) {
            console.log(fname + JSON.stringify(filterData[fname]['value']))

            if (filterData[fname]['value'][0] !== '' && filterData[fname]['value'][0] !== null) {
              let init = new Date(filterData[fname]['value'][0]).getTime()
              let end = new Date().getTime()
              if (filterData[fname]['value'][1] !== '' && filterData[fname]['value'][1] !== null) {
                end = new Date(filterData[fname]['value'][1]).getTime()
              }
              let diff = (end - init)
              if (diff > (vPeriod * 24 * 60 * 60 * 1000)) return false
              else return true
            }
            else return true
          } else return true
        },
        messages: {
          'en': t(`MaxPeriod`),
          'es': t(`MaxPeriod`),
          'pt': t(`MaxPeriod`)
        }
      }
    }
  }, [filterData])

  useEffect(() => {
    if (formValidation != undefined) {

      props.setValidationFilter(formValidation.validate())

    }
  }, [filterData])


  const { formValidation } = useFormValidation('Form_' + reportId, validationFunctions)

  const onChangeFilter = (value, fieldName) => {

    let currentFilter = { ...filterData }
    currentFilter[fieldName] = value
    setFilterData(currentFilter);



    let filter = []
    Object.keys(currentFilter).forEach((fieldName) => {
      if (typeof currentFilter[fieldName]['value'] === "number" && currentFilter[fieldName]['value'] !== 0) {
        filter.push(currentFilter[fieldName])
      } else {
        if (!currentFilter[fieldName]['value'].includes('undefined') && currentFilter[fieldName]['value'] !== '' && currentFilter[fieldName]['value'] !== 'in ' && currentFilter[fieldName]['value'] !== 'notin ' && !Array.isArray(currentFilter[fieldName]['value'])) {
          filter.push(currentFilter[fieldName])
        }
        else {
          if (Array.isArray(currentFilter[fieldName]['value']) && currentFilter[fieldName]['value'][0] !== '' && currentFilter[fieldName]['value'][1] !== '') {
            filter.push(currentFilter[fieldName])
          }
        }
      }
    })
    handleFilterData(filter)

  }

  const splitValues = (value, operadores) => {

    let returnValues = ['', '']
    let values = value.split(" ")
    if (values.length >= 2 && operadores.includes(values[0])) {
      returnValues[0] = values[0]
      values.shift()
      returnValues[1] = values.join(" ")
    } else if (values.length === 1 && operadores.includes(values[0]))
      returnValues[0] = values[0]
    else returnValues[1] = values[0]


    return returnValues
  }


  const executeFilterData = (event) => {
    event.preventDefault()
    if (formValidation.validate()) {

      let filter = []

      Object.keys(filterData).forEach((fieldName) => {
        if (filterData[fieldName]['useLikeFilter'] === undefined || filterData[fieldName]['useLikeFilter'] === true) {
          if (typeof filterData[fieldName]['value'] === "number" && filterData[fieldName]['value'] !== 0) {
            filter.push(filterData[fieldName])
          } else {
            if (!filterData[fieldName]['value'].includes('undefined') && filterData[fieldName]['value'] !== '' && filterData[fieldName]['value'] !== 'in ' && filterData[fieldName]['value'] !== 'notin ' && !Array.isArray(filterData[fieldName]['value'])) {
              filter.push(filterData[fieldName])
            }
            else {
              if (Array.isArray(filterData[fieldName]['value']) && filterData[fieldName]['value'][0] !== '' && filterData[fieldName]['value'][1] !== '') {
                filter.push(filterData[fieldName])
              }
            }
          }
        }
      })
      formValidation.reset()
      executeReport(filter)
    }
  }

  const readDateRangeValues = (valuesDate, format) => {
    let valuesDateRange = [null, null]
    let formatDate = (format === 'time') ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"

    if (valuesDate !== undefined) {
      if (valuesDate.value[0] !== "") {
        if (format === 'time') {
          valuesDateRange[0] = utils.formatDate(valuesDate.value[0], formatDate);
        } else valuesDateRange[0] = valuesDate.value[0]
      } else {
        valuesDateRange[0] = null
      }
      if (valuesDate.value[1] !== "") {


        if (format === "date") { //quitamos un día pq cuando enviamos al server lo ponemos
          valuesDateRange[1] = momentTimeZone(new Date(valuesDate.value[1]).getTime() - (24 * 60 * 60 * 1000)).tz("UTC").format("YYYY-MM-DD")
        } else {
          valuesDateRange[1] = utils.formatDate(valuesDate.value[1], formatDate);
        }

      } else { valuesDateRange[1] = null }
    }
    return valuesDateRange;
  }
  const readDateValue = (valueDate, format) => {
    let date = null
    let formatDate = (format === 'time') ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"

    if (valueDate !== undefined) {
      if (valueDate.value !== "") {
        if (format === "time") {
          date = utils.formatDate(valueDate.value, formatDate)
        } else date = valueDate.value
      } else {
        date = null
      }
    }
    return date;
  }

  const getTypeFilterField = (filterField) => {
    let field = filterConfig.find((f) => { if (f.field === filterField) return f })
    return field.type
  }

  const getFilterOperatorsOptions = (operators) => {
    let options = []
    options = selectOptions.filter((element) => {
      if (operators.includes(element.id)) return element
    })
    if (operators.includes("not in")) {
      options.push({ id: "notin", name: "Not In" })
    }
    return options
  }

  const notifyError = (fieldValidation, value) => {
    let error = false
    if (formValidation !== undefined) {
      error = formValidation.validate()
      if (value !== undefined) value = (error) ? true : false
      props.setValidationFilter(value)
    }
  }

  return (
    <>
      <form id={"Form_" + reportId} data-parsley-focus="none">
        <div className="row" id={'filterPanel_' + reportId}>
          {filterConfig.map(function (filter, index, array) {
            let currentFilterVisibility = filterView[filter.field] || ''
            let validations = {}
            let isRequired = false
            let opSelectMultiple = ['in', 'notin', 'undefined']
            let useLikeFilter = filter.useLikeFilter === undefined ? true : filter.useLikeFilter;
            if (filter.validation !== undefined) {
              Object.keys(filter.validation).forEach((nameFunction) => {
                let funcName = "data-parsley-" + nameFunction
                let dataFunc = [filter.validation[nameFunction], filter.field]
                if (nameFunction === "required") isRequired = (filter.validation[nameFunction] === "true") ? true : false
                else if (Array.isArray(dataFunc))
                  validations[funcName] = JSON.stringify(dataFunc)
                else validations[funcName] = dataFunc
              })
            }
            switch (filter.type) {

              case 'date_range':
                let valuesDateRange = readDateRangeValues(filterData[filter.field], 'date')
                return <>
                  <div id={"filter-init-" + index} key={"filter-init-" + index} className={"col-12 col-md-6 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                    <DateTimeRangeFilter
                      handleNotifiyError={notifyError}
                      required={isRequired}
                      validations={validations}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      filterConf={filter}
                      filterData={filterData[filter.field]}
                      value={valuesDateRange}
                      showTime={false}
                      t={t}
                      validateForm={formValidation}
                      callBack={(range) => {
                        let prevVal = { field: filter.field, value: [2], type: filter.type }
                        prevVal.value[0] = (range[0] !== '') ? moment(range[0]).format("YYYY-MM-DD") : ''
                        prevVal.value[1] = (range[1] !== '') ? moment(range[1]).add(1, "days").format("YYYY-MM-DD") : ''
                        onChangeFilter(prevVal, filter.field)
                      }}

                    />
                  </div>
                </>
              case 'time_range':
                let valuesTimeRange = readDateRangeValues(filterData[filter.field], 'time')
                return <>
                  <div id={"filter-init-" + index} key={"filter-init-" + index} className={"col-12 col-md-6 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                    <DateTimeRangeFilter
                      handleNotifiyError={notifyError}
                      required={isRequired}
                      validations={validations}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      filterConf={filter}
                      filterData={filterData[filter.field]}
                      value={valuesTimeRange}
                      showTime={true}
                      validateForm={formValidation}
                      t={t}
                      callBack={(range) => {
                        //let prevVal = filterData[filter.field]

                        let prevVal = { field: filter.field, value: [2], type: filter.type }
                        prevVal.value[0] = (range[0] !== '') ? utils.unformatDate(range[0]) : ''
                        prevVal.value[1] = (range[1] !== '') ? utils.unformatDate(range[1]) : ''
                        // if (value === null) value = ""
                        // if (value !== "") value = momentTimeZone(new Date(value).getTime()).tz(utils.getCurrentCompany().config.timezone).format("YYYY-MM-DD")
                        // prevVal = { field: filter.field, value: value, type: filter.type, operator: filter.operator || ">=" }
                        onChangeFilter(prevVal, filter.field)
                      }}

                    />
                  </div>
                </>
              case 'date':
                let valueDate = readDateValue(filterData[filter.field], "date")
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <DateAndTimePicker
                    handleNotifyError={notifyError}
                    required={isRequired}
                    validations={validations}
                    key={"date_" + filter.field}
                    label={t(filter.label)}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    value={valueDate}
                    fieldName={filter.field}
                    showTime={false}
                    callBackFunction={(value, fieldName) => {
                      let prevVal = filterData[filter.field]
                      if (value === null) value = ""
                      if (value !== "") value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                      prevVal = { field: filter.field, value: value, type: filter.type, operator: filter.operator || ">=" }
                      onChangeFilter(prevVal, filter.field)
                    }}
                  />
                </div>
              case 'dateTime':
                let valueDateTime = readDateValue(filterData[filter.field], "time")
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <DateAndTimePicker
                    handleNotifyError={notifyError}
                    required={isRequired}
                    validations={validations}
                    key={"dateTime_" + filter.field}
                    label={t(filter.label)}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    value={valueDateTime}
                    fieldName={filter.field}
                    showTime={true}
                    callBackFunction={(value, fieldName) => {

                      if (value != null) value = utils.unformatDate(value)
                      else value = ""
                      let prevVal = { field: filter.field, value: value, type: filter.type, operator: filter.operator || ">=" }
                      onChangeFilter(prevVal, filter.field)
                    }}
                  />
                </div>
              case 'number':
                let valuesNumber = ['', '']
                let operatorsDefaultNumber = filter.operators || [">", ">=", "=", "<=", "<"]
                if (filterData[filter.field] !== undefined) {
                  valuesNumber = splitValues(filterData[filter.field].value, [...operatorsDefaultNumber, undefined])
                }
                let inputProps = {}

                if (filter.typeValue === "currency") {
                  inputProps = {
                    "startAdornment": <FontAwesomeIcon icon="coins" />
                  }

                }
                // let valuesNumber = (filterData[filter.field] !== undefined) ? filterData[filter.field].value.split(" ") : ['', '']                
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <BasicSelect
                      // label= {t(filter.label)}
                      width={'25%'}
                      disableClearable
                      noEmptyValue
                      name={filter.label + "_criteria"}
                      value={valuesNumber[0] || operatorsDefaultNumber[0]}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      addOpts={getFilterOperatorsOptions(operatorsDefaultNumber)}
                      nameProperty='id'
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsDefaultNumber, undefined])
                          val[0] = (evt.value === "") ? 'undefined' : evt.value
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                          prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, typeValue: filter.typeValue, value: evt.value + " " + undefined, type: filter.type }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }
                      }
                    />
                    <TextField style={{ width: '75%' }}
                      InputProps={inputProps}
                      required={isRequired}
                      inputProps={{ ...validations }}
                      id={"filter_" + filter.field}
                      // label={t("Value")} 
                      label={t(filter.label)}
                      type="text"
                      defaultValue={valuesNumber[1]}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsDefaultNumber, undefined])
                          val[1] = evt.target.value
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, typeValue: filter.typeValue, value: operatorsDefaultNumber[0] + " " + evt.target.value, type: filter.type }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }}
                    />
                  </Stack>

                </div>
              case 'number_set':
                let valuesNumberSet = ['', '']
                let operatorsDefaultNumberSet = getFilterOperatorsOptions(filter.operators || ["in", "notin"])
                let operatorsIds = operatorsDefaultNumberSet.map(objeto => objeto.id)
                if (filterData[filter.field] !== undefined) {
                  valuesNumberSet = splitValues(filterData[filter.field].value, [...operatorsIds, undefined])
                }

                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6" + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <BasicSelect
                      disableClearable
                      // label= {t(filter.label)}
                      width={'25%'}
                      value={valuesNumberSet[0] || operatorsDefaultNumberSet[0].id}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      addOpts={operatorsDefaultNumberSet}
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsIds, undefined])
                          val[0] = (evt.value === "") ? 'undefined' : evt.value
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, value: evt.value + " " + undefined, type: filter.type }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }}
                    />
                    <TextField multiline style={{ width: '75%' }}
                      required={isRequired}
                      inputProps={{ ...validations }}
                      id={"filter_" + filter.field}
                      // label={t("Value")} 
                      label={t(filter.label)}
                      type="text"
                      value={valuesNumberSet[1] === 'undefined' ? '' : valuesNumberSet[1]}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsIds, undefined])
                          val[1] = evt.target.value
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, value: operatorsDefaultNumberSet[0].id + " " + evt.target.value, type: filter.type }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }}
                    />
                  </Stack>

                </div>
              case 'SelectDepositMethods': //OK
                let valuesSelectDepositMethods = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="method"
                    name="depositMethods"
                    label={t(filter.label)}
                    basicInfoName="DepositMethods"
                    value={valuesSelectDepositMethods}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption || true}
                  />
                </div>
              case 'SelectAgents': //OK
                let valuesSelectAgents = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="user"
                    name="agent"
                    label={t(filter.label)}
                    basicInfoName="Agents"
                    value={valuesSelectAgents}
                    addOpts={[{ user: "0", name: t("-") }]}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectPayoutMethods':
                let valuesSelectPayoutMethods = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="method"
                    name="method"
                    label={t(filter.label)}
                    basicInfoName="PayoutMethods"
                    value={valuesSelectPayoutMethods}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>

              case 'SelectProviders': //OK
                let valuesSelectProviders = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="provider"
                    name="providers"
                    label={t(filter.label)}
                    basicInfoName="Providers"
                    value={valuesSelectProviders}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectAccounts': //OK
                let valuesSelectAccounts = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="account"
                    name="Accounts"
                    data="accounts"
                    label={t(filter.label)}
                    basicInfoName="Accounts"
                    value={valuesSelectAccounts}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectFileStatus': //OK
                let valuesSelectFileStatus = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="status"
                    name="FileStatus"
                    label={t(filter.label)}
                    basicInfoName="FileStatus"
                    value={valuesSelectFileStatus}
                    translate={{ property: 'status', prefix: "FILE_STATUS_" }}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectPayoutStatus': //OK
                let valuesSelectPayoutStatus = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="status"
                    name="payoutStatus"
                    label={t(filter.label)}
                    basicInfoName="PayoutStatus"
                    value={valuesSelectPayoutStatus}
                    translate={{ property: 'name', prefix: '' }}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectDepositStatus': //OK
                let valuesSelectDepositStatus = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="status"
                    nameProperty="status"
                    name="depositStatus"
                    data="status"
                    label={t(filter.label)}
                    basicInfoName="DepositsStatus"
                    value={valuesSelectDepositStatus}
                    translate={{ property: 'status', prefix: 'DEPOSIT_STATUS_' }}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }
                    }
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectPromotionsStatus': //OK
                let valuesSelectPromotionsStatus = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    idProperty="status"
                    name="promotionsStatus"
                    label={t(filter.label)}
                    basicInfoName="PromotionsStatus"
                    value={valuesSelectPromotionsStatus}
                    translate={{ property: 'status', prefix: 'PROMOTION_STATUS_' }}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: 'text' }
                      onChangeFilter(prevVal, filter.field)
                    }}
                    hideOnlyOneOption={filter.hideOnlyOneOption === "false" ? false : true}
                  />
                </div>
              case 'SelectMultiple':
                let valuesSelectMultiple = ['', '']
                let operatorsDefaultMultiple = filter.operators || ["in", "notin"]
                if (filterData[filter.field] !== undefined) {
                  valuesSelectMultiple = splitValues(filterData[filter.field].value, [...operatorsDefaultMultiple, undefined])
                  let intsValues = valuesSelectMultiple[1].split(',').map((e) => {
                    if (filter.typeValue === "Integer") return parseInt(e)
                    else return e
                  }
                  )
                  valuesSelectMultiple[1] = intsValues

                }
                let filterSelectMultiple = undefined;
                if (filter.filters !== undefined) {
                  filterSelectMultiple = filter.filters.map((f) => {
                    let newFilter = f
                    if (f.field !== undefined) {
                      newFilter.property = f.property
                      let typeFilter = getTypeFilterField(f.field)
                      if (typeFilter === 'SelectMultiple') {
                        let valuesSelectMultipleFilter = ['', '']
                        if (filterData[f.field] !== undefined) {
                          valuesSelectMultipleFilter = splitValues(filterData[f.field].value, opSelectMultiple)
                        }
                        newFilter.value = valuesSelectMultipleFilter[1].split(",")
                      } else newFilter.value = (filterData[f.field] !== undefined) ? filterData[f.field].value : ''

                    }
                    return newFilter
                  })
                }
                let paramsSelectMultiple = undefined;
                if (filter.params !== undefined) {
                  paramsSelectMultiple = filter.params.map((p) => {
                    let newParam = p
                    if (p.field !== undefined) {
                      newParam.property = p.property
                      let typeFilter = getTypeFilterField(p.field)
                      if (typeFilter === 'SelectMultiple') {
                        let valuesSelectMultipleFilter = ['', '']
                        if (filterData[p.field] !== undefined) {
                          valuesSelectMultipleFilter = splitValues(filterData[p.field].value, opSelectMultiple)
                        }
                        newParam.value = valuesSelectMultipleFilter[1].split(",")
                      } else newParam.value = (filterData[p.field] !== undefined) ? filterData[p.field].value : ''

                    }
                    return newParam
                  })
                }
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <BasicSelect
                      disableClearable
                      name={filter.field + "_criteria"}
                      width={'25%'}
                      value={valuesSelectMultiple[0] || operatorsDefaultMultiple[0]}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      addOpts={getFilterOperatorsOptions(operatorsDefaultMultiple)}
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsDefaultMultiple, undefined])
                          val[0] = (evt.value === "") ? 'undefined' : evt.value
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, value: evt.value + " " + undefined, typeValue: filter.typeValue, type: filter.type }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }}
                    />
                    <BasicSelect
                      width={'75%'}
                      required={isRequired}
                      label={t(filter.label)}
                      validations={{ ...validations }}
                      disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                      value={valuesSelectMultiple[1]}
                      onChange={(evt) => {

                        let prevVal = filterData[filter.field];
                        if (prevVal !== undefined) {
                          let val = splitValues(filterData[filter.field].value, [...operatorsDefaultMultiple, undefined])
                          val[1] = evt.value.join(",")
                          if (val[1] === "") prevVal.value = val[0] + " "
                          else prevVal.value = val.join(" ")
                        }
                        else {
                          prevVal = { field: filter.field, value: operatorsDefaultMultiple[0] + " " + evt.value, typeValue: filter.typeValue, type: filter.type, useLikeFilter: useLikeFilter }
                        }
                        onChangeFilter(prevVal, filter.field)
                      }}
                      idProperty={filter.idProperty}
                      nameProperty={filter.nameProperty || undefined}
                      basicInfoName={filter.basicInfoName}
                      filters={filterSelectMultiple || filter.filters || []}
                      params={paramsSelectMultiple || filter.params || []}
                      data={filter.data || undefined}
                      translate={filter.translate || undefined}
                      multiple
                    //hideOnlyOneOption={filter.hideOnlyOneOption==="false"?false:true}              
                    />
                  </Stack>
                </div>
              case 'Select':
                let valuesSelect = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                let filterSelect = undefined;
                if (filter.filters !== undefined) {
                  filterSelect = filter.filters.map((f) => {
                    let newFilter = f
                    if (f.field !== undefined) {
                      newFilter.property = f.property
                      let typeFilter = getTypeFilterField(f.field)
                      if (typeFilter === 'SelectMultiple') {
                        let valuesSelectMultipleFilter = ['', '']
                        if (filterData[f.field] !== undefined) {
                          valuesSelectMultipleFilter = splitValues(filterData[f.field].value, opSelectMultiple)
                        }
                        newFilter.value = valuesSelectMultipleFilter[1].split(",")
                      } else newFilter.value = (filterData[f.field] !== undefined) ? filterData[f.field].value : ''

                    }
                    return newFilter
                  })
                }
                let paramsSelect = undefined;
                if (filter.params !== undefined) {
                  paramsSelect = filter.params.map((p) => {
                    let newParam = p
                    if (p.field !== undefined) {
                      newParam.property = p.property
                      let typeFilter = getTypeFilterField(p.field)
                      if (typeFilter === 'SelectMultiple') {
                        let valuesSelectMultipleFilter = ['', '']
                        if (filterData[p.field] !== undefined) {
                          valuesSelectMultipleFilter = splitValues(filterData[p.field].value, opSelectMultiple)
                        }
                        newParam.value = valuesSelectMultipleFilter[1].split(",")
                      } else newParam.value = (filterData[p.field] !== undefined) ? filterData[p.field].value : ''

                    }
                    return newParam
                  })
                }
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 m-0 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <BasicSelect label={t(filter.label)}
                    required={isRequired}
                    validations={{ ...validations }}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    value={valuesSelect}
                    addOpts={filter.addOpts || undefined}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.value
                      else prevVal = { field: filter.field, value: evt.value, type: '=', useLikeFilter: useLikeFilter }
                      onChangeFilter(prevVal, filter.field)
                    }}
                    idProperty={filter.idProperty}
                    nameProperty={filter.nameProperty || undefined}
                    basicInfoName={filter.basicInfoName}
                    filters={filterSelect || filter.filters || []}
                    params={paramsSelect || filter.params || []}
                    data={filter.data || undefined}
                    translate={filter.translate || undefined}
                    disableClearable={filter.disableClearable || undefined}
                    noEmptyValue={filter.noEmptyValue || undefined}
                  //hideOnlyOneOption={filter.hideOnlyOneOption==="false"?false:true}
                  />
                </div>
              default:
                let value = (filterData[filter.field] !== undefined) ? filterData[filter.field].value : ''
                return <div id={"filter-" + index} key={"filter-" + index} className={"col-12 col-md-6 " + (currentFilterVisibility === 'hidden' ? "hide" : "")}>
                  <TextField id={"filter_" + filter.field} label={t(filter.label)} type="text" name={"filter_" + filter.field} required={isRequired}
                    inputProps={{ ...validations }}
                    defaultValue={value}
                    disabled={(currentFilterVisibility === 'disabled' ? true : false)}
                    onChange={(evt) => {
                      let prevVal = filterData[filter.field]
                      if (prevVal !== undefined) prevVal.value = evt.target.value
                      else prevVal = { field: filter.field, value: evt.target.value, type: filter.type, useLikeFilter: useLikeFilter }
                      onChangeFilter(prevVal, filter.field)
                    }}
                  />
                </div>

            }
          })}
          <div className="col-12" id="filter-button">
            <button id={"report_filter_btn_" + reportId} className="btn btn-secondary" onClick={executeFilterData}>{t("Filter")}</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default withTranslation()(FilterReport)
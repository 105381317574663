// import React from "react";
// import { withTranslation } from "react-i18next";

// import AdminCLient from '../AdminClient';
// import { Chart } from "chart.js";
// import { Link } from "react-router-dom";
// import utils from "../utils";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// class AppChart extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = { visible: true, title: ".", details: <></>, report: { def: { config: {} } } };

//     if (this.props.windowRef !== undefined) {
//       console.log("Setting window ref = " + this.props.windowRef)
//       window[this.props.windowRef] = this;
//     }

//   }

//   findLineByLeastSquares(values_x, values_y) {
//     var x_sum = 0;
//     var y_sum = 0;
//     var xy_sum = 0;
//     var xx_sum = 0;
//     var count = 0;

//     /*
//      * The above is just for quick access, makes the program faster
//      */
//     var x = 0;
//     var y = 0;
//     var values_length = values_x.length;

//     if (values_length !== values_y.length) {
//       throw new Error('The parameters values_x and values_y need to have same size!');
//     }

//     /*
//      * Above and below cover edge cases
//      */
//     if (values_length === 0) {
//       return [[], []];
//     }

//     /*
//      * Calculate the sum for each of the parts necessary.
//      */
//     for (let i = 0; i < values_length; i++) {
//       x = values_x[i];
//       y = values_y[i];
//       x_sum += x;
//       y_sum += y;
//       xx_sum += x * x;
//       xy_sum += x * y;
//       count++;
//     }

//     /*
//      * Calculate m and b for the line equation:
//      * y = x * m + b
//      */
//     var m = (count * xy_sum - x_sum * y_sum) / (count * xx_sum - x_sum * x_sum);
//     var b = (y_sum / count) - (m * x_sum) / count;

//     /*
//      * We then return the x and y data points according to our fit
//      */
//     var result_values_x = [];
//     var result_values_y = [];

//     for (let i = 0; i < values_length; i++) {
//       x = values_x[i];
//       y = Math.round(x * m + b);
//       result_values_x.push(x);
//       result_values_y.push(y);
//     }

//     return [result_values_x, result_values_y];
//   }


//   findLineByAverage(values_x, values_y) {

//     var y_sum = 0;

//     /*
//      * The above is just for quick access, makes the program faster
//      */
//     var x = 0;
//     var y = 0;
//     var values_length = values_x.length;

//     if (values_length !== values_y.length) {
//       throw new Error('The parameters values_x and values_y need to have same size!');
//     }

//     /*
//      * Above and below cover edge cases
//      */
//     if (values_length === 0) {
//       return [[], []];
//     }

//     /*
//      * Calculate the sum for each of the parts necessary.
//      */
//     for (let i = 0; i < values_length; i++) {
//       y = values_y[i];
//       y_sum += y;

//     }

//     var avg = Math.round(y_sum / values_length);
//     var result_values_x = [];
//     var result_values_y = [];

//     for (let i = 0; i < values_length; i++) {
//       x = values_x[i];
//       result_values_x.push(x);
//       result_values_y.push(avg);
//     }

//     return [result_values_x, result_values_y];
//   }

//   executeReport(){
//     this.componentDidMount() ;  
//   }

//   componentDidMount() {

//     const { t } = this.props;
//     this.chartRef = React.createRef();

//     var clientAPI = new AdminCLient();
//     var thisAux = this;

//     var reportPromise = clientAPI.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.props.report, this.props.filter);
//     reportPromise.then(
//       function (msg) {
//         var report = msg.data;
//         var graph = report.def.config.graph;

//         if (report.def.config.details !== undefined) {

//           thisAux.setState({ details: <Link to={report.def.config.details + "/graph"}>{t("Details Button")}</Link> })

//         }

//         var labels = [];
//         var reportData = [];
//         var minValue = 0;

//         thisAux.setState({ report: report, title: t(graph.title) });

//         // Para cada fila de datos...
//         report.data.forEach(element => {
//           if (graph.labels.endsWith('_date')) {
//             labels.push(element[graph.labels].substring(0, 10));
//           } else {
//             labels.push(element[graph.labels]);
//           }


//           graph.datasets.forEach(dsDef => {
//             dsDef.label = t(dsDef.label);
//             if (reportData[dsDef.data] === undefined) {
//               reportData[dsDef.data] = [];
//             }
//             reportData[dsDef.data].push(element[dsDef.data]);
//             // console.log(element[dsDef.data]);
//             if (element[dsDef.data] < minValue) {
//               minValue = element[dsDef.data];
//             }
//           });
//         });


//         graph.datasets.forEach(dsDef => {
//           dsDef.data = reportData[dsDef.data];
//         });


//         if (graph.interpolation!==undefined){
//           try{
//           var p = Array.from(Array(labels.length).keys());
//           console.log("interpolation = "+JSON.stringify(p));

//           var inter=this.findLineByLeastSquares(p, reportData[graph.interpolation.data]);
//           console.log("interpolation = "+JSON.stringify(inter)) ;
//           graph.interpolation.data= inter[1];
//           graph.datasets.push(graph.interpolation);
//           } catch (err){

//           }
//         }

//         if (graph.average!==undefined){
//           try{
//           var p2 = Array.from(Array(labels.length).keys());
//           console.log("Average = "+JSON.stringify(p2));

//           var inter2=this.findLineByAverage(p2, reportData[graph.average.data]);
//           console.log("Average = "+JSON.stringify(inter2)) ;
//           graph.average.data= inter2[1];
//           graph.datasets.push(graph.average);
//           } catch (err){

//           }
//         }


//         var config = {
//           type: graph.type,
//           scaleStartValue: minValue,
//           data: {
//             labels: labels,
//             datasets: graph.datasets
//           }
//         };

//         console.log(JSON.stringify(config));
//         try{
//           this.myChart.destroy();
//         } catch (err){
//            console.error("Error destroying chart "+err);
//         }
//         this.myChart = new Chart(this.chartRef.current, config);

//       }.bind(this), function (err) {
//         console.log("reportPromise error " + err.code);
//         this.setState({ visible: false });
//       }.bind(this)
//     );

//   }

//   render() {
//     var help_link = <></>;
//     console.log("Help url = " + JSON.stringify(this.state.report.def.config.help_url));
//     if (this.state.report.def.config.help_url !== undefined) {
//       help_link = <a className="help_link" onClick={e => { window.dispatchEvent(new CustomEvent("showHelp", { detail: { url: this.state.report.def.config.help_url } })) }}><span><FontAwesomeIcon icon="info-circle" /></span></a>
//     }

//     return (
//       <>
//       {this.state.visible &&
//         <div className={ "col-12 col-md-6  float-left pt-3 "}>
//           <div className="card">
//             <div className="card-header">{this.state.title} {help_link} {this.state.details}</div>
//             <div className="card-body p-0 p-md-3" ><canvas ref={this.chartRef} /></div>
//           </div>
//         </div>
//       }
//       </>
//     );
//   }
// }
//export default withTranslation()(AppChart);


import AppChart from "./basic/AppChart";
export { AppChart }
export default AppChart





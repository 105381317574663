import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
function LockedIpsDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [lockedIpDetails, setlockedIpDetails] = useState({ ip: "", country: "", created_date: utils.getDateTZTodayObj().getTime() });
    const ip = utils.getSessionItem('current-ip')
    //HEADER
    const goBack = () => {
        props.history.push('/reports/report/locked_ips/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Locked ips'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //Carga de datos
    useEffect(function () {
        if (ip !== undefined && ip !== '-1') {
            adminClient.getLockedIpsDetails(session, company, ip).then(
                async function (result) {
                    setlockedIpDetails(result.data);
                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);

    const unlock = (evt) => {
        adminClient.deleteLockedIpsDetails(session, company, ip).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Locked ips'), t('Unlocked succesfull'));
                    goBack();

                } else {
                    props.showAlert(t('Locked ips'), t('Unlocking error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('Locked ips'), t('Deleting error ') + error.description)
            }
        )
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="row">
                <div className="col-12 col-md-4">
                    <TextField
                        label={t('ID')}
                        name="id"
                        disabled={true}
                        type="text"
                        value={lockedIpDetails.ip}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <TextField
                        label={t('Country')}
                        disabled={true}
                        type="text"
                        value={lockedIpDetails.country}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <TextField
                        label={t('Created date')}
                        disabled={true}
                        type="text"
                        value={lockedIpDetails.created_date}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={unlock}
                    >
                        {t('Unlock')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(LockedIpsDetails);

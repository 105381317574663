import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import utils from '../../utils';
import { TextField } from '@mui/material';
import BasicSelect from '../basic/BasicSelect';
import useFormValidation from '../../hooks/parsleyValidation';


const NotePopup = (props) => {
    const { formValidation } = useFormValidation("Form_commentPersonaDetails"); // Mover el hook aquí
    const { t, company, adminClient } = props
    const user = JSON.parse(utils.getSessionItem("userDetails")).user;

    const [note, setNote] = useState({
        db: user.db,
        note: -1,
        company: company,
        user: user.user,
        title: "",
        content: "",
        department: null
    })

    const onChangeHandler = (evt) => {
        let evtInfo;
        if (evt.target !== undefined) {
            evtInfo = evt.target;
        }
        else {
            evtInfo = evt;
        }
        const { name, value } = evtInfo;

        setNote((prevState) => ({ ...prevState, [name]: value }));

    }
    return <>
        <form id="Form_commentPersonaDetails">
            <div style={{ textAlign: "center", width: "400px" }}>
                <p>{t('Add comment to change')}</p>
                <div className="col-12">
                    <TextField type="text"
                        required
                        name="title"
                        label={t("Note title")}
                        onChange={onChangeHandler}
                        value={note.title}
                    > </TextField>
                </div>
                <div className="col-12">
                    <BasicSelect
                        label={t("Departments")}
                        idProperty="department"
                        name="department"
                        data="departments"
                        basicInfoName="Departments"
                        onChange={onChangeHandler}
                        value={note.department}
                        required

                    />
                </div>
                <div className="col-12">
                    <TextField multiline
                        label={t("Comments")}
                        required
                        name="content"
                        onChange={onChangeHandler}
                        value={note.content}
                    >
                    </TextField>
                </div>
            </div>
        </form>
        <button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
        <button type='button' className='btn btn-primary'
            onClick={() => {
                if (formValidation.validate()) {
                    var promise = adminClient.saveNote(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(note));
                    promise.then(
                        function (msg) {
                            console.log("Update result " + JSON.stringify(msg));
                            props.handleCloseAlert()
                            if (msg.result === 'OK') {
                                props.save();
                            } else {
                                console.error(msg)
                                props.showAlert(t("Saving note"), t('Saving error '))
                            }
                        }, function (err) {
                            props.handleCloseAlert()
                            props.showAlert(t("Saving note"), t('Saving error ' + err))
                        });
                }
            }}
        >
            {t('Ok')}
        </button>
    </>
}

export default withTranslation()(NotePopup);
import React, { useCallback } from 'react';
import { getConnectedEdges, Handle, useNodeId, useStore } from 'reactflow';

const selector =
    (nodeId, handleId) =>
        (s) => {
            // If the user props say this handle is not connectable, we don't need to
            // bother checking anything else.

            const node = s.nodeInternals.get(nodeId);
            const connectedEdges = getConnectedEdges([node], s.edges);
            //Buscamos si esa conexion existe   
            let connExist = connectedEdges.find((conn) => {
                if (conn.sourceHandle === handleId) return conn
            })
            return connExist === undefined;
        };

const FlowConnectionHandle = (props) => {
    const nodeId = useNodeId();
    const isConnectable = useStore(
        useCallback(selector(nodeId, props.id), [
            props.id,
            nodeId
        ])
    );

    // The `isConnectable` prop is a part of React Flow, all we need to do is give
    // it the bool we calculated above and React Flow can handle the logic to disable
    // it for us.
    return <Handle {...props} isConnectable={isConnectable} />;
};

export default FlowConnectionHandle;
import $ from "jquery";
import React, { useEffect, useState } from "react";


function useFormValidation(FormId, validationFunctions) {

    const [formValidation, setFormValidation] = useState()


    useEffect(() => {
        let parsleyInstance = $('#' + FormId).parsley()
        if (parsleyInstance !== undefined) {
            parsleyInstance.options.successClass = 'validationField';
            parsleyInstance.options.errorsContainer = function (ParsleyField) {
                var $topParent = ParsleyField.$element.parent().parent();
                let $helperText = $topParent.find('div.MuiTextValidationHelper')
                if ($helperText.length === 0) {
                    $topParent.append('<div class="MuiTextValidationHelper"></div>');
                    $helperText = $topParent.find('div.MuiTextValidationHelper')
                }
                return $helperText;
            };

            parsleyInstance.options.errorsWrapper = '<div class="validationField"></div>';
            parsleyInstance.options.errorTemplate = '<span></span>';

            if (validationFunctions) {
                Object.keys(validationFunctions).forEach((nameFunction) => {
                    var configFunction = validationFunctions[nameFunction];
                    window.Parsley.addValidator(nameFunction, configFunction);
                })
            }
            setFormValidation(parsleyInstance)
        }

    }, [FormId, $('#' + FormId).length === 1, (validationFunctions !== undefined && validationFunctions)])


    return { formValidation };
}
export function useFormFieldValidation(ref, validationFunctions) {

    const [formValidation, setFormValidation] = useState()


    useEffect(() => {
        let parsleyInstance = $(ref).parsley()
        if (parsleyInstance !== undefined) {
            parsleyInstance.options.successClass = 'validationField';
            parsleyInstance.options.errorsContainer = function (ParsleyField) {
                var $topParent = ParsleyField.$element.parent().parent();
                let $helperText = $topParent.find('div.MuiTextValidationHelper')
                if ($helperText.length === 0) {
                    $topParent.append('<div class="MuiTextValidationHelper"></div>');
                    $helperText = $topParent.find('div.MuiTextValidationHelper')
                }
                return $helperText;
            };

            parsleyInstance.options.errorsWrapper = '<div class="validationField"></div>';
            parsleyInstance.options.errorTemplate = '<span></span>';

            if (validationFunctions) {
                Object.keys(validationFunctions).forEach((nameFunction) => {
                    var configFunction = validationFunctions[nameFunction];
                    window.Parsley.addValidator(nameFunction, configFunction);
                })
            }
            setFormValidation(parsleyInstance)
        }

    }, [ref, $(ref).length === 1, (validationFunctions !== undefined && validationFunctions)])


    return formValidation;
}

export default useFormValidation;
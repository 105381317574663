import React from "react";
import { withTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function HoursCheckbox(props) {
    const { t } = props;


    let hoursArray = [];

    for (let i = 0; i < 24; i++) {
        hoursArray.push(i);
    }

    const hoursOptions = hoursArray.map((i) => {
        return (<FormControlLabel
            style={{ marginRight: "-6px", marginLeft: "-6px" }}
            labelPlacement="top"
            control={
                <Checkbox
                    onChange={(e) => props.handleChange(i, e)}
                    checked={
                        props.hours === undefined
                            ? false
                            : props.hours.charAt(i) === "1"
                    }
                />
            }
            label={i}
        />)
    });


    return (
        <>
            <InputLabel id="HOURS-select-label" label={t("Hours")}>
                {" "}
            </InputLabel>
            <FormControl
                variant="outlined"
                labelid="HOURS-select-label"
                label={t("Hours")}
                margin={"none"}
                style={{ width: "100%", marginBottom: 32 }}
            >
                <FormGroup variant="outlined" row>
                    {hoursOptions}
                    <FormControlLabel
                        labelPlacement="top"
                        control={
                            <Checkbox
                                onChange={props.handleChangeAll}
                                checked={props.hours === "111111111111111111111111"}
                            />
                        }
                        label={t("All")}
                    />
                </FormGroup>
            </FormControl>
        </>
    );
}


export default withTranslation()(HoursCheckbox);

import React from "react";
import ReactModal from "react-modal";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Collapse from "../basic/Collapse";

const AlertMessage = (props) => {
  const { t } = props;
  let content = props.content;
  const customStyles = {
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      position: "fixed",
      margin: "5% auto",
      inset: "auto",
      right: "auto",
      bottom: "auto"
    },
  };

  if (props.title == 'Error' && props.error == undefined) {
    //Comprobamos si el content lleva las palabras clave de los errores de base de datos para los casos en los que no se esta enviando error
    if (props.content !== undefined && (typeof props.content === 'string' && props.content.includes("Duplicate entry"))) {
      content = t("Entrada duplicada")
    }
  } else if (props.error != undefined) {
    //Comprobamos si el content lleva las palabras clave de los errores de base de datos para los casos en los que si se esta mandando el error
    if (props.error.description != undefined && props.error.description.includes("Duplicate entry")) {
      content = t("Entrada duplicada")
    }
  }

  return (
    <ReactModal
      isOpen={props.isActive}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={"card-alert " + "alertSize-" + props.size}>
        <div className="card-header-alert">
          <h4 className="card-title-alert">{props.loading && props.title === undefined ? t("Loading...") : props.title}</h4>
          {(!props.loading && props.title === "Documentación") && <button className="close-button-alert" onClick={props.handleCloseAlert}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          }
        </div>
        <div className="card-body-alert m-4">
          {content}
          <div className="col-12">
            {props.error !== undefined && (
              <Collapse tittle={t("More details")} errorDetail={props.error.description} />
            )}

            <div className="text-right">
              {props.buttons === undefined &&

                <button className="btn btn-primary right" onClick={props.handleCloseAlert}>
                  {t("Close")}
                </button>
              }
              {props.buttons !== undefined && props.buttons.length > 0 && props.buttons.map((button) => button)}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default withTranslation()(AlertMessage);

import React, { useState } from 'react'
import Header from '../PrivateHeader';
import { withTranslation } from "react-i18next";
import VerticalMenu from '../VerticalMenu';
import utils from "../../utils";
import { useAlert } from '../../hooks/Alert';
import AlertMessage from './AlertMessage'

const PrivateLayout = (props) => {

    if (window.menus === undefined) {
        window.menus = JSON.parse(utils.getSessionItem("menus"));
    }
    const { t } = props;
    const mainMenu = utils.toMenuArray(t, window.menus.MAIN)
    const [selected, alertOpen, showAlert, closeAlert] = useAlert();


    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div id="menuContainer" className="col-12 col-md-2 px-md-2 px-0 bg-dark d-none d-md-block vertical-menu">
                    <VerticalMenu items={mainMenu}></VerticalMenu>
                </div>
                <div className="col-12 col-md-10 p-0 ">
                    <Header help_url={props.help_url} showAlert={showAlert} handleCloseAlert={closeAlert} messageAlert={props.messageAlert} />
                    <div className="container mt-0 p-0 ">
                        <main className="row p-0 m-0" >
                            {React.Children.map(props.children, (child) =>
                                React.cloneElement(child, {
                                    showAlert: showAlert,
                                    handleCloseAlert: closeAlert
                                })
                            )}
                        </main>
                    </div>
                </div>
            </div>
            <AlertMessage
                key="PrivateLayout_alert"
                isActive={alertOpen}
                size={selected.size}
                content={selected.content}
                title={selected.title}
                buttons={selected.buttons}
                loading={selected.loading}
                handleCloseAlert={closeAlert}
                error={selected.error}
            />
            <link rel="stylesheet" href="/customized/css/customized.css"></link>
            <link rel="stylesheet" href={`/customized/css/customized_${utils.getCurrentCompany().company}.css`}></link>
        </div>

    )
}

export default withTranslation()(PrivateLayout)
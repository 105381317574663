
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import BasicSelect from '../../components/basic/BasicSelect'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from '../../utils'


const ComponentTestPage = (props) => {

  const [selects, setSelects] = useState({
    account: '', provider: ['betconstruct'], subProvider: ['Fugaso'],
    accountSet: '', adminUser: '', affiliate: '', agent: '-1', payoutStatus: '', banners: '', companyLobbie: '',
    currency: 'PEN', depositStatus: '', regulatoryStatus: '', departments: '', country: '', state: '', province: '',
    city: '', eventType: '', fileStatus: '', gender: '', globalGroup: '', promotionsStatus: '', payoutMethods: '', groups: '',
    eventTypes: '', groupsByRange: '', language: '', languageI18N: '', currencyCompany: '', initPages: '', depositMethods: '', machineType: 'SLOT',
    machineTag: '', paymentAccountsTypes: '', paymentAccountsStatus: '', promotions: '', providerStatus: '', ticketStatus: '', weekDay: '',
    fileType: '', fileSubType: '', betStatus: '', lobbyTag: '', promotionsNew: ''
  })
  const dataHeader = {
    backLink: "",
    title: "Uso de Selects",
    urlHelp: "",
    idDoc: props.idDoc
  }
  const handleSelectChange = (data) => {
    const { value, name } = data
    setSelects((prevState) => ({ ...prevState, [name]: value }));
  }
  const { t } = props

  /**Revisarlos bien Contries, Cities, states, provinces,
   * Memoria companies SelectDepositMethods */
  /** */
  return (
    <>
      <LayoutSection dataHeader={dataHeader}>
        <div className="row" style={{ marginBottom: 20 }}>
          <label id="Seleccion">{JSON.stringify(selects, null, " ")}</label>
        </div>
        <div className="row">
          <div className="col-4">
            <BasicSelect
              idProperty="type"
              name="objectiveType"
              label="ObjectivesTypes"
              basicInfoName="ObjectivesTypes"
              onChange={handleSelectChange}
              value={selects.objectiveType}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="type"
              name="GroupType"
              label="GroupsTypes"
              basicInfoName="GroupsTypes"
              onChange={handleSelectChange}
              value={selects.GroupType}
              translate={{ property: "type", prefix: "GROUP_TYPE_" }}

            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="category"
              name="GroupsCategories"
              label="GroupsCategories"
              basicInfoName="GroupsCategories"
              onChange={handleSelectChange}
              value={selects.GroupsCategories}
              translate={{ property: "category", prefix: "GROUP_CATEGORY_" }}

            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="type"
              name="fileType"
              label={t("Type")}
              basicInfoName="FilesTypes"
              onChange={handleSelectChange}
              value={selects.fileType}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="subtype"
              name="fileSubType"
              label={t("Subtype")}
              basicInfoName="FilesSubTypes"
              filters={[{ property: "type", value: selects.fileType, initVal: true }]}
              onChange={handleSelectChange}
              value={selects.fileSubType}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="provider"
              name="provider"
              label="Providers"
              basicInfoName="Providers"
              filters={[{ property: "type", value: "PROVIDER" }]}
              onChange={handleSelectChange}
              value={selects.provider}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="provider"
              name="subProvider"
              label="SubProviders"
              basicInfoName="Providers"
              filters={[{ property: "type", value: "SUBPROVIDER" },
              { property: "super_provider", value: selects.provider, initVal: true }]}
              onChange={handleSelectChange}
              value={selects.subProvider}
              multiple
              required
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="account"
              name="account"
              label="Accounts"
              data="accounts"
              basicInfoName="Accounts"
              onChange={handleSelectChange}
              value={selects.account}
              disableClearable
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="set"
              name="accountSet"
              label="AccountSets"
              basicInfoName="AccountSets"
              onChange={handleSelectChange}
              value={selects.accountSet}
              disableClearable
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="user"
              nameProperty="alias"
              data="users"
              name="adminUser"
              label="AdminUsers"
              basicInfoName="AdminUsers"
              onChange={handleSelectChange}
              value={selects.adminUser}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="affiliate"
              name="affiliate"
              label="Affiliates"
              data="affiliates"
              basicInfoName="Affiliates"
              onChange={handleSelectChange}
              value={selects.affiliate}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="user"
              name="agent"
              label="Agents"
              addOpts={[{ user: "-1", name: t("No change") }, { user: "0", name: t("-") }]}
              basicInfoName="Agents"
              onChange={handleSelectChange}
              value={selects.agent}
            //multiple
            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="Banners"
              idProperty="banner"
              name="banners"
              data="banners"
              basicInfoName="Banners"
              onChange={handleSelectChange}
              value={selects.banners}
            //multiple
            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="BetStatus"
              idProperty="status"
              name="betStatus"
              nameProperty="status"
              basicInfoName="BetStatus"
              onChange={handleSelectChange}
              value={selects.betStatus}
              translate={{ property: "status", prefix: "BET_STATUS_" }}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Companies"
              idProperty="value"
              name="company"
              nameProperty="label"
              onChange={handleSelectChange}
              addOpts={JSON.parse(utils.getSessionItem("companiesOptions"))}
              value={selects.company}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Company Lobbies"
              idProperty="lobbie"
              name="lobbies"
              data="lobbies"
              basicInfoName="CompanyLobbies"
              onChange={handleSelectChange}
              value={selects.companyLobbie}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Currencies"
              idProperty="currency"
              nameProperty="currency"
              name="currencies"
              data="currencies"
              basicInfoName="Currencies"
              onChange={handleSelectChange}
              value={selects.currency}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Deposit Status"
              idProperty="status"
              nameProperty="status"
              name="depositStatus"
              data="status"
              basicInfoName="DepositsStatus"
              onChange={handleSelectChange}
              value={selects.depositStatus}
              translate={{ property: "status", prefix: "DEPOSIT_STATUS_" }}

            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="Deposit Methods"
              idProperty="method"
              name="depositMethods"
              basicInfoName="DepositMethods"
              onChange={handleSelectChange}
              value={selects.depositMethods}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Events types Webhooks"
              nameProperty="event"
              idProperty="event"
              name="eventType"
              basicInfoName="EventTypes"
              onChange={handleSelectChange}
              filters={[{ property: "queue", value: "webhooks" }]}
              translate={{ property: "event", prefix: "" }}
              value={selects.eventType}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="File status"
              idProperty="status"
              name="fileStatus"
              basicInfoName="FileStatus"
              onChange={handleSelectChange}
              value={selects.fileStatus}
              translate={{ property: "name", prefix: "" }}

            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Gender"
              idProperty="gender"
              name="gender"
              data="gender"
              basicInfoName="Gender"
              onChange={handleSelectChange}
              value={selects.gender}
              translate={{ property: "name", prefix: "" }}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Global group"
              idProperty="type"
              nameProperty="type"
              name="globalGroup"
              data="globalGroup"
              basicInfoName="GlobalGroup"
              onChange={handleSelectChange}
              value={selects.globalGroup}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="group"
              name="group"
              label="Groups"
              basicInfoName="Groups"
              onChange={handleSelectChange}
              value={selects.groups}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="group"
              name="groupsByRange"
              label="Groups by rage 3000-3008"
              basicInfoName="GroupsByRange"
              onChange={handleSelectChange}
              params={[
                { property: "init", value: 3000 },
                { property: "end", value: 3008 }
              ]}
              value={selects.groupsByRange}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="lang"
              name="language"
              label="Languages"
              basicInfoName="Languages"
              onChange={handleSelectChange}
              value={selects.language}
              required
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="langPref"
              name="languageI18N"
              label="Languages I18N"
              basicInfoName="Languages"
              onChange={handleSelectChange}
              value={selects.languageI18N}
              required
            />
          </div>

          <div className='col-4'>
            <BasicSelect
              idProperty="type"
              name="machineType"
              label="Machine types"
              basicInfoName="MachinesTypes"
              onChange={handleSelectChange}
              value={selects.machineType}
              disableClearable
            />
          </div>
          <div className='col-4'>
            <BasicSelect
              idProperty="tag"
              name="lobbyTag"
              label="Lobby tags"
              basicInfoName="LobbiesTags"
              onChange={handleSelectChange}
              value={selects.lobbyTag}
              disableClearable
            />
          </div>
          <div className='col-4'>
            <BasicSelect
              idProperty="tag"
              name="machineTag"
              label="Machine tags"
              basicInfoName="AllMachineTags"
              onChange={handleSelectChange}
              value={selects.machineTag}
              disableClearable
            />
          </div>
          <div className='col-4'>
            <BasicSelect
              idProperty="status"
              nameProperty="status"
              name="paymentAccountsStatus"
              label="PaymentAccountsStatus"
              basicInfoName="PaymentAccountsStatus"
              onChange={handleSelectChange}
              value={selects.paymentAccountsStatus}
              translate={{ property: "status", prefix: "PAYMENT_ACCOUNTS_STATUS_" }}
            />
          </div>
          <div className='col-4'>
            <BasicSelect
              idProperty="type"
              nameProperty="type"
              name="paymentAccountsTypes"
              label="PaymentAccountsTypes"
              basicInfoName="PaymentAccountsTypes"
              onChange={handleSelectChange}
              value={selects.paymentAccountsTypes}
              translate={{ property: "type", prefix: "PAYMENT_ACCOUNTS_TYPE_" }}
            />
          </div>


          <div className="col-4">
            <BasicSelect
              label="Payout Methods"
              idProperty="method"
              name="payoutMethods"
              basicInfoName="PayoutMethods"
              onChange={handleSelectChange}
              value={selects.payoutMethods}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="Payouts status"
              idProperty="status"
              name="payoutStatus"
              basicInfoName="PayoutStatus"
              onChange={handleSelectChange}
              value={selects.payoutStatus}
              translate={{ property: "name", prefix: "" }}
            //multiple
            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="Promotions"
              idProperty="promotion"
              name="promotions"
              nameProperty="promotion"
              data="promotions"
              basicInfoName="ManualPromotions"
              onChange={handleSelectChange}
              value={selects.promotions}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Promotions types"
              idProperty="type"
              name="promotionsTypes"
              nameProperty="type"
              basicInfoName="PromotionsTypes"
              onChange={handleSelectChange}
              value={selects.promotionsTypes}
              translate={{ property: "type", prefix: "PROMOTION_TYPE_" }}

            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="Promotions status"
              nameProperty="description"
              idProperty="status"
              name="promotionsStatus"
              basicInfoName="PromotionsStatus"
              onChange={handleSelectChange}
              value={selects.promotionsStatus}
              translate={{ property: "description", prefix: "" }}
              disableClearable
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="status"
              name="providerStatus"
              label="Status with disabled and enabled"
              basicInfoName="EnabledDisabled"
              onChange={handleSelectChange}
              value={selects.providerStatus}
              translate={{ property: "name", prefix: "" }}
              disableClearable
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="name"
              name="providerStatus"
              label="Status with 0 and 1"
              basicInfoName="EnabledDisabled"
              onChange={handleSelectChange}
              value={selects.providerStatus}
              translate={{ property: "name", prefix: "" }}
              disableClearable
            />
          </div>
          <div className="col-4">

            <BasicSelect
              label="RegulatoryStatus"
              idProperty="status"
              name="regulatoryStatus"
              data="status"
              basicInfoName="RegulatoryStatus"
              onChange={handleSelectChange}
              value={selects.regulatoryStatus}
            />
          </div>
          <div className="col-4">

            <BasicSelect
              label="NumberFormats"
              idProperty="numberFormat"
              nameProperty="label"
              name="numberFormat"
              basicInfoName="NumberFormats"
              onChange={handleSelectChange}
              value={selects.numberFormat}
              translate={{ property: "label", prefix: "" }}
            />
          </div>

          <div className="col-4">

            <BasicSelect
              label="Week day"
              idProperty="day"
              name="weekDay"
              basicInfoName="WeekDays"
              onChange={handleSelectChange}
              value={selects.weekDay}
              multiple
              noEmptyValue
              translate={{ property: "day", prefix: "WEEK_DAY_" }}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Ticket Status"
              idProperty="status"
              name="ticketStatus"
              basicInfoName="TicketStatus"
              onChange={handleSelectChange}
              value={selects.ticketStatus}
              translate={{ property: "status", prefix: "TICKET_STATUS_" }}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="calculator"
              data="calculators"
              name="calculatorPromotion"
              label="Promotion calculator"
              addOpts={[{ calculator: "NO_CALCULATOR", name: t("No calculator") }]}
              basicInfoName="PromotionCalculators"
              onChange={handleSelectChange}
              value={selects.calculatorPromotion}
              disableClearable
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="choice"
              data="choices"
              name="choicePromotion"
              nameProperty="choice"
              label="Promotion choice"
              addOpts={[{ choice: "NO_CHOICE" }]}
              basicInfoName="PromotionChoices"
              onChange={handleSelectChange}
              value={selects.choicePromotion}
              translate={{ property: "choice", prefix: "" }}
              disableClearable
            />
          </div>

          <div className="col-4">
            <BasicSelect
              label="virtualGoods"
              idProperty="virtual_good"
              name="virtualGoods"
              data="virtual_goods"
              basicInfoName="VirtualGoods"
              onChange={handleSelectChange}
              value={selects.virtualGoods}

            />
          </div>

          <hr></hr>
          <label>NUEVOS</label>
          <div className="col-4">
            <BasicSelect
              idProperty="type"
              name="typeAddresses"
              label="Addresses type"
              data="types"
              basicInfoName="AddressTypes"
              onChange={handleSelectChange}
              value={selects.typeAddresses}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Departments"
              idProperty="department"
              name="departments"
              data="departments"
              basicInfoName="Departments"
              onChange={handleSelectChange}
              value={selects.departments}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="country"
              name="country"
              label="Countries"
              data="countries"
              basicInfoName="Countries"
              onChange={handleSelectChange}
              value={selects.country}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="state"
              name="state"
              label="states"
              basicInfoName="States"
              params={[{ property: "country", value: selects.country || "" }]}
              onChange={handleSelectChange}
              value={selects.state}
              required
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="province"
              name="province"
              label="provinces"
              basicInfoName="Provinces"
              params={[
                { property: "country", value: selects.country || "" },
                { property: "state", value: selects.state || "" }
              ]}
              onChange={handleSelectChange}
              value={selects.province}
              required
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="city"
              name="city"
              label="cities"
              basicInfoName="Cities"
              params={[
                { property: "country", value: selects.country || "" },
                { property: "state", value: selects.state || "" },
                { property: "province", value: selects.province || "" }
              ]}
              onChange={handleSelectChange}
              value={selects.city}
              required
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Currencies Company"
              idProperty="currency"
              nameProperty="currency"
              name="currencyCompany"
              data="currencies"
              basicInfoName="CurrenciesCompany"
              onChange={handleSelectChange}
              value={selects.currencyCompany}
            //multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Init pages"
              idProperty="url"
              nameProperty="page"
              name="initPages"
              basicInfoName="InitPages"
              onChange={handleSelectChange}
              value={selects.initPages}
              translate={{ property: "page", prefix: "INIT_PAGES_" }}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="UserVirtualGoodStatus"
              idProperty="status"
              name="userGoodStatus"
              basicInfoName="UserVirtualGoodStatus"
              onChange={handleSelectChange}
              value={selects.userGoodStatus}
              translate={{ property: "status", prefix: "USER_VIRTUAL_GOOD_STATUS_" }}
            />
          </div>
          <div className="col-4">
            <BasicSelect
              label="Promotions"
              idProperty="promotion"
              name="promotionsNew"
              nameProperty="promotion"
              basicInfoName="Promotions"
              onChange={handleSelectChange}
              value={selects.promotionsNew}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="operation"
              name="operationType"
              nameProperty="operation"
              label={t("Type")}
              basicInfoName="OperationsTypes"
              onChange={handleSelectChange}
              value={selects.operationType}
              translate={{ property: "operation", prefix: "OPERATION_TYPE_" }}
              multiple
            />
          </div>
          <div className="col-4">
            <BasicSelect
              idProperty="national_id"
              name="nationalIdType"
              nameProperty="national_id"
              label={t("Type")}
              basicInfoName="NationalIdTypes"
              onChange={handleSelectChange}
              value={selects.nationalIdType}
              translate={{ property: "national_id", prefix: "DOCUMENTO_TYPE_" }}
              multiple
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="react"
              name="flowReact"
              label={t("Flow react")}
              basicInfoName="FlowsReacts"
              onChange={handleSelectChange}
              value={selects.flowReact}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="action"
              name="flowAction"
              label={t("Flow action")}
              basicInfoName="FlowsActions"
              onChange={handleSelectChange}
              value={selects.flowAction}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="template"
              name="emailTemplate"
              label={t("Email templates")}
              basicInfoName="EmailTemplates"
              onChange={handleSelectChange}
              value={selects.emailTemplate}
            />
          </div>

          <div className="col-4">
            <BasicSelect
              idProperty="status"
              name="flowStatus"
              label={t("Flows Status")}
              basicInfoName="FlowsStatus"
              onChange={handleSelectChange}
              value={selects.flowStatus}
            />
          </div>

        </div>
      </LayoutSection>
    </>
  )
}

export default withTranslation()(ComponentTestPage)
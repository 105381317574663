import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import '../../confluenceStyles.css';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocChat from './DocChat';
import { useAlert } from '../../hooks/Alert';
import AlertMessage from '../NavigationAndLayouts/AlertMessage';

const Documentation = (props) => {
  const { t } = props;
  const [responseDataPage, setResponseDataPage] = useState(null);
  const adminClient = new AdminClient();
  const company = utils.getSessionItem('Company');
  const session = JSON.parse(utils.getSessionItem('user')).session;
  let url = 'space/';

  const [idDoc, setIdDoc] = useState(props.idDoc)
  const [prevPage, setPrevPage] = useState(props.idDoc)
  const context = props.context;
  const recommendations = props.recommendations;

  const [selected, alertOpen, showAlert, closeAlert] = useAlert()

  function closePopup() {
    props.handleCloseAlert();
  }

  function back() {
    getPageHTML(prevPage);
  }

  async function getPageHTML(page) {
    url = 'https://calimaco.atlassian.net/wiki/rest/api/content/' + page + '?expand=body.export_view';
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, url);
      setResponseDataPage(documentationResponse.data);
      console.log(documentationResponse.data.body.export_view.value)
    } catch (error) {
      console.error("Error in getPageHTML " + error);
    }
  }

  async function getImage(url) {
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, url);
      return documentationResponse.data
    } catch (error) {
      console.error("Error in getPageHTML " + error);
    }
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    if (buffer !== undefined && buffer.data !== undefined) {
      const bytes = new Uint8Array(buffer.data);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
    }
    return btoa(binary);
  }

  useEffect(() => {
    async function fetchData() {
      await getPageHTML(idDoc);
    }
    fetchData();
  }, [idDoc]); // Lista de dependencias vacía

  useEffect(() => {
    const elements = document.querySelectorAll('[data-linked-resource-type]');

    // Itera sobre los elementos y realiza alguna acción con ellos
    elements.forEach(element => {

      console.log(element); // O cualquier otra acción que desees realizar
      element.onclick = function (event) {
        event.preventDefault();

        console.log('Elemento clicado:', element);

        const resourceId = element.getAttribute('data-linked-resource-id');
        setIdDoc(resourceId)

        // Aquí puedes añadir el código que deseas ejecutar al hacer clic en el elemento
      };
    });

    const imagenes = document.getElementsByClassName('confluence-embedded-image');

    if (imagenes.length > 0) {
      // Convierte la colección HTML a un array
      Array.from(imagenes).forEach(async imagen => {
        console.log(imagen); // O cualquier otra acción que desees realizar
        let binario = await getImage(imagen.src);
        const base64String = arrayBufferToBase64(binario);
        imagen.src = 'data:image/png;base64,' + base64String;
        imagen.style = 'max-width: 100%; margin-top: 10px; cursor: pointer;'
        // Añade un evento onclick para abrir la imagen en una nueva pestaña
        imagen.onclick = function () {
          const newTab = window.open();
          newTab.document.body.innerHTML = `<img class="confluence-embedded-image image-center" src="data:image/png;base64,` + base64String + `" style="max-width: 100%; margin-top: 10px;">`;
        };
      });
    }
  }, [responseDataPage])

  return (
    <div style={{ marginLeft: '5%' }}>
      {/* <button className="btn btn-primary right" onClick={closePopup}>{t("Close")}</button> */}
      {responseDataPage ? (
        <div>
          <h1 style={{ marginBottom: '20px', marginTop: '40px' }}>{responseDataPage.title}</h1>
          <div id="DocuAux" dangerouslySetInnerHTML={{ __html: responseDataPage.body.export_view.value }} />
          {utils.isGranted("VIEW_CALIA_CHAT_DOC") &&
            <div className="row">
              <div className="col-12 ">
                <DocChat document_key={idDoc} context={context} recommendations={recommendations} showAlert={showAlert} closeAlert={closeAlert}></DocChat>
              </div>
            </div>
          }
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <AlertMessage key={'FlowStatusReact_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} loading={selected.loading} />
    </div>
  );
};

const DocButton = (props) => {
  const { t } = props;

  const getDoc = (idDoc) => {
    try {
      return JSON.parse(documentationPages[idDoc])
    } catch (err) {
      return {}
    }
  }

  const documentationPages = JSON.parse(utils.getSessionItem('documentationPages'))
  const documentation = getDoc(props.idDoc);

  const idDoc = documentation.confluenceId || '';
  const context = documentation.context || '';
  const recommendations = documentation.recommendations || "[]";
  const showButton = idDoc !== undefined && idDoc !== null && idDoc !== '' ? true : false;

  function showDocumentation() {
    props.showAlert(t("Documentation"), <Documentation handleCloseAlert={props.handleCloseAlert} idDoc={idDoc} context={context} recommendations={recommendations} t={t} />, true, 'xl')
  }

  useEffect(() => {

  }, []);

  return (
    <>
      {showButton && <IconButton onClick={showDocumentation}>
        <span><FontAwesomeIcon icon="info-circle" /></span>
      </IconButton>}
    </>
  );
}

export default withTranslation()(DocButton);
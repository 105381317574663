import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { CKEditor } from 'ckeditor4-react';
import utils from '../../utils';

function Editor(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);

    const [ckEditor, setCkEditor] = useState(undefined);
    const [oneTime, setOneTime] = useState(false);
    const oneTimeEnabled = props.oneTime !== undefined ? props.oneTime : false;

    useEffect(() => {
        if (ckEditor !== undefined && !oneTime && props.info !== "") {
            ckEditor.setData(props.info);
            if (oneTimeEnabled) {
                setOneTime(true);
            }
        }
    }, [props.info, ckEditor, oneTime]);

    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        props.onChangeHandler({ name: "content", value: data });
    };

    const handleInstanceReady = (evt) => {

        if (props.info) {
            evt.editor.setData(props.info);
        }
        setCkEditor(evt.editor);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="tab-content" id="cmsBodyContent">
                    <div className="tab-pane fade show active" id="cms-body" role="tabpanel" aria-labelledby="cms-body-tab"></div>
                    <CKEditor
                        config={{ height: props.height || 200, allowedContent: true, versionCheck: false }}
                        onChange={handleEditorChange}
                        onInstanceReady={handleInstanceReady}
                    />
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Editor);

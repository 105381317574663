import { TextField, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


function EvaluateGroups(props) {
    const { t } = props;
    const [evaluate, setEvaluate] = useState({
        include: "",
        notInclude: ""
    })

    const [resultEvaluate, setResultEvaluate] = useState({
        include: "",
        notInclude: ""
    })
    const { formValidation } = useFormValidation('Form_EvaluateGroup')


    const [correctGroup, setCorrectGroup] = useState(false)

    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();

    const onChangeHandlerEvaluate = (e) => {
        const { name, value } = e.target || e;
        setEvaluate((prevState) => ({ ...prevState, [name]: value }));
    };

    const writeResults = (msg) => {
        let result = {};
        const notIncludeMsg =
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{t("Incorrect evaluation")}</Typography>
                        {/* <em>{t("Incorrect evaluation help")}</em> */}
                    </React.Fragment>
                }
            >
                <span className="cross" style={{ fontSize: "35px" }}>☒</span>
            </HtmlTooltip>


        const includeMsg =
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{t("Correct evaluation")}</Typography>
                        {/* <em>{t("Correct evaluation help")}</em> */}
                    </React.Fragment>
                }
            >
                <span className="tick" style={{ fontSize: "35px" }}>☑</span></HtmlTooltip>

        if (msg.include === 1) {
            result.include = includeMsg;
        }
        else if (msg.include === 0) {
            result.include = notIncludeMsg;
        }
        else {
            result.include = "";
        }

        if (msg.notInclude === 0) {
            result.notInclude = includeMsg;
        }
        else if (msg.notInclude === 1) {
            result.notInclude = notIncludeMsg;
        }
        else {
            result.notInclude = "";
        }
        return result;
    }

    const evaluateGroupFunction = () => {
        if (formValidation.validate()) {
            adminClient.CALIA_evaluateGroup(session, company, JSON.stringify(evaluate), props.sql).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        let result = writeResults(msg.response);
                        setCorrectGroup(msg.response.include === 1 && msg.response.notInclude === 0);
                        setResultEvaluate(result)
                    } else {
                        props.showAlert(t('CALIA Groups'), t('Evaluate error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('CALIA Groups'), t('Evaluate error ') + error.description)
                }
            )
        }
    }

    const saveEvaluationFunction = () => {
        adminClient.CALIA_saveEvaluationGroup(session, company, JSON.stringify({ reference: props.reference })).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('CALIA Groups'), t('Saving evaluate correct'))

                } else {
                    props.showAlert(t('CALIA Groups'), t('Evaluate error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('CALIA Groups'), t('Evaluate error ') + error.description)
            }
        )
    }

    return (
        <>
            <form id="Form_EvaluateGroup">
                <div className='row'>
                    <div className='col-10'>
                        <TextField
                            label={t('Player included in the group')}
                            name='include'
                            type='text'
                            required
                            value={evaluate.include}
                            onChange={onChangeHandlerEvaluate}
                        />
                    </div>
                    <div className='col-2' style={{ textAlign: "center" }}>
                        {resultEvaluate.include}
                    </div>
                    <div className='col-10'>
                        <TextField
                            label={t('Player NOT included in the group')}
                            name='notInclude'
                            type='text'
                            required
                            value={evaluate.notInclude}
                            onChange={onChangeHandlerEvaluate}
                        />
                    </div>
                    <div className='col-2' style={{ textAlign: "center" }}>
                        {resultEvaluate.notInclude}
                    </div>
                </div>
            </form>
            <button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>
                {t('Cancel')}
            </button>
            <button type='button' className='btn btn-primary'
                style={{ float: "left" }}
                onClick={evaluateGroupFunction}>
                {t('Evaluate')}
            </button>
            {correctGroup &&
                <button type='button' className='btn btn-primary'
                    style={{ float: "left" }}
                    onClick={saveEvaluationFunction}>
                    {t('Save evaluation')}
                </button>
            }
        </>
    );
}

function GroupsCalia(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_CaliaGroups')
    const adminClient = new AdminClient();
    const { t } = props;
    const [groupsDetails, setGroupsDetails] = useState({
        request: "",
        response: ""
    })

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setGroupsDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const requestGroup = (evt) => {
        let jsonRequest = {
            prompt: groupsDetails.request,
            author: "dlledo"
        }
        props.showAlert("LOADING", t("Generating group ..."));

        if (formValidation.validate()) {
            adminClient.CALIA_requestGroup(session, company, JSON.stringify(jsonRequest)).then(
                (msg) => {
                    props.handleCloseAlert();

                    if (msg.data !== undefined) {
                        setGroupsDetails((prevState) => ({ ...prevState, response: msg }));
                    } else {
                        props.showAlert(t('CALIA Groups'), t('Request error ') + msg.msg)
                    }
                },
                (error) => {
                    props.handleCloseAlert();

                    props.showAlert(t('CALIA Groups'), t('Request error ') + error.description);

                }
            )
        }
    }



    const evaluateGroup = (evt) => {
        props.showAlert(t('CALIA Groups'), <EvaluateGroups reference={groupsDetails.response.reference} sql={groupsDetails.response.data.group_sql} showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert} t={props.t} />, [], 's2')

    }

    const createGroup = (evt) => {

    }

    const discardGroup = (evt) => {

    }

    const dataHeader = {
        // backLink: goBack,
        title: t('CALIA Groups evaluate'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_CaliaGroups">
                <div className="row">
                    <div className="col-12">
                        <TextField
                            label={t('Request group')}
                            multiline
                            name="request"
                            required
                            type="text"
                            value={groupsDetails.request}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={requestGroup}
                    >
                        {t('Request group')}
                    </button>

                </div>
            </div>
            {groupsDetails.response !== "" &&
                <div className="row">
                    <div className="col-6">
                        <TextField
                            label={t('reference')}
                            name="response"
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.reference}
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            label={t('date')}
                            name="response"
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.date}
                        />
                    </div>
                    <div className="col-12">
                        <TextField
                            label={t('Response')}
                            name="response"
                            multiline
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.data.group_sql}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label={t('tokens')}
                            name="response"
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.data.tokens}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label={t('totalTime')}
                            name="response"
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.data.totalTime}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label={t('estimatedSQLDelay')}
                            name="response"
                            inputProps={{ readOnly: true }}
                            type="text"
                            value={groupsDetails.response.data.estimatedSQLDelay}
                        />
                    </div>
                    <div className='col-12'>
                        <button
                            style={{ width: "150px", float: "left" }}
                            type="button"
                            className="btn btn-primary"
                            onClick={evaluateGroup}
                        >
                            {t('Evaluate group')}
                        </button>
                        <button
                            style={{ width: "150px" }}

                            type="button"
                            className="btn btn-primary"
                            onClick={createGroup}
                        >
                            {t('Create group')}
                        </button>

                        <button
                            style={{ width: "150px" }}
                            type="button"
                            className="btn btn-secondary"
                            onClick={discardGroup}
                        >
                            {t('Discard group')}
                        </button>

                    </div>
                </div>
            }
        </LayoutSection>
    )
}
export default withTranslation()(GroupsCalia);
import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlowReactActions from './FlowReactActions';
import { useAlert } from '../../hooks/Alert';
import AlertMessage from '../NavigationAndLayouts/AlertMessage';
import FlowReactDetails from './FlowReactDetails';


const FlowStatusReacts = (props) => {

    const { t } = props
    const [statusReacts, setStatusReacts] = useState({})
    const [expanded, setExpanded] = useState([]);
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()

    const handleExpand = (index) => {
        setExpanded((prevExpanded) => {
            prevExpanded[index] = !prevExpanded[index]
            return [...prevExpanded]
        });
    };


    const onDeleteReact = (reactData) => {
        let reacts = { ...statusReacts }
        delete reacts[reactData.react]
        setStatusReacts(reacts)
        props.onUpdateStatusReacts({ name: 'data.config.reacts', value: reacts })

    }

    const editReact = (reactData) => {
        showAlert(t('Edit') + " " + t('React'), <FlowReactDetails status={props.status} flow={props.flow} react={reactData} handleCloseAlert={closeAlert} onUpdateReacts={onUpdateReacts} onUpdateReactActions={onUpdateReactActions} />, [], 'l')
    }
    const deteteReact = (reactData) => {
        showAlert(t('Delete') + " " + t('React'), <p>{t('Delete') + " " + t('React') + " "}<strong>{reactData.name}</strong></p>, [
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    onDeleteReact(reactData)
                    closeAlert()
                }}>
                {t('Ok')}</button>,
            <button type='button' className='btn btn-secondary' onClick={closeAlert}>{t('Cancel')}</button>])
    }
    const newReact = () => {
        showAlert(t('Add') + " " + t('React'), <FlowReactDetails flow={props.flow} status={props.status} handleCloseAlert={closeAlert} onUpdateReacts={onUpdateReacts} onUpdateReactActions={onUpdateReactActions} />, [], 'l')
    }

    const onUpdateReacts = (reactData) => {
        let react = reactData.react
        delete reactData['react']
        let reacts = { ...statusReacts }
        reacts[react] = reactData
        setStatusReacts(reacts)
        props.onUpdateStatusReacts({ name: 'data.config.reacts', value: reacts })
    }

    const onUpdateReactActions = (react, actions) => {
        let reacts = { ...statusReacts }
        reacts[react]['actions'] = actions
        setStatusReacts(reacts)
        props.onUpdateStatusReacts({ name: 'data.config.reacts', value: reacts })
    }

    const getReactDataRender = (reactData) => {
        let message = t(reactData.type + '_React_Description')
        Object.keys(reactData).forEach((key) => {
            message = message.replace("<" + key + ">", reactData[key])
        })
        return message

    }

    useEffect(() => {
        if (props.reacts !== undefined) {
            setStatusReacts(props.reacts)
            setExpanded(Array(Object.keys(props.reacts).length).fill(true))
        }
    }, [props.reacts])


    return (<>
        <div className='reactAction_addItem' ><FontAwesomeIcon title={t('Add') + " " + t('React')} className="float-end" onClick={() => { newReact() }} icon="plus"></FontAwesomeIcon></div>

        {statusReacts !== undefined && Object.keys(statusReacts).map((r, index) => {

            let reactData = statusReacts[r]
            reactData['react'] = r
            return (<>
                <Accordion expanded={expanded[index]}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon="arrow-down" onClick={() => { handleExpand(index) }} />} aria-controls={r + "bh-content"} id={r + "bh-header"}>
                        <Typography sx={{ width: '98%', color: 'text.secondary', alignContent: 'right' }}>
                            <FontAwesomeIcon icon="edit" title={t('Edit') + " " + t('React')} onClick={() => { editReact(reactData) }} /><strong>    {reactData.name}: </strong>
                            {getReactDataRender(reactData)} , {t('will do_ReactFlow')}
                            <FontAwesomeIcon className="float-end" title={t('Delete') + " " + t('React')} onClick={() => { deteteReact(reactData) }} icon="trash" />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FlowReactActions flow={props.flow} showAlert={showAlert} closeAlert={closeAlert} status={props.status} react={reactData.react} actions={reactData.actions} onUpdateReactActions={onUpdateReactActions} />
                    </AccordionDetails>
                </Accordion>

            </>)
        })}
        <AlertMessage key={'FlowStatusReact_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
    </>
    );
}

export default withTranslation()(FlowStatusReacts)
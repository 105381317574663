import React from 'react';
import './App.css';
import Routes from './components/Routes';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import utils from './utils';
import moment from 'moment';
import 'moment/min/locales';

import 'parsleyjs/dist/parsley'

import 'parsleyjs/dist/i18n/es'
import 'parsleyjs/dist/i18n/pt-pt'
import 'parsleyjs/dist/i18n/pt-br'
import parsleyValidationGral from "./context/parsleyValidationGral.js"




function App() {
  moment.tz.setDefault("America/Limna");


  //library.add( faHome,faCaretSquareDown,faChartBar, faUserCog)
  library.add(fas)

  // console.log("App : utils.getSessionItem(i18n)  = " + utils.getSessionItem("i18n"));


  if (utils.getSessionItem("i18n") !== undefined && utils.getSessionItem("i18n") !== null) {
    var _res = JSON.parse(utils.getSessionItem("i18n"));

    var lang = 'es';
    if (utils.getSessionItem("calimaco-lang") !== undefined && utils.getSessionItem("calimaco-lang") !== null) {
      lang = utils.getSessionItem("calimaco-lang").substring(0, 2);
      moment.globalLocale = lang
      moment.locale(lang)
    }

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: _res,
        lng: lang,
        fallbackLng: "en",

        interpolation: {
          escapeValue: false
        }

      });
    //i18n.changeLanguage(lang);

    try {
      let langParsley = lang
      if (lang === 'pt') langParsley = 'pt-br'
      window.Parsley.setLocale(langParsley)
      Object.keys(parsleyValidationGral).forEach((nameFunction) => {
        let configFunction = parsleyValidationGral[nameFunction];
        let message = _res[lang].translation['validation_' + nameFunction];
        if (message !== undefined) {
          configFunction.messages = {}
          configFunction.messages[langParsley] = message
        } else {
          configFunction.messages = {}
          configFunction.messages[langParsley] = ' '
        }
        window.Parsley.addValidator(nameFunction, configFunction);
      })
    }
    catch (err) {
      console.log("error cargando funciones de validación en app")
      console.log(err)

    }

  }
  return (

    <Routes></Routes>

  );







}

export default App;



import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const GameRender = (props) => {



    const [gameData, setGameData] = useState({
        game: props.row.game,
        provider: props.row.provider,
        sub_provider: props.row.sub_provider,
        replay_url: ''
    })

    const showGameDetail = () => {
        const { t } = props
        props.showAlert(t('Game Details'), <iframe src={gameData.replay_url + "?data=" + JSON.stringify(props.row)}></iframe>, undefined, 'xl')

    }

    useEffect(() => {

        try {
            const provider = props.providers.find(objeto => objeto.provider.toUpperCase() === props.row.provider.toUpperCase());
            const providerConfig = JSON.parse(provider.config)
            const replay_url = providerConfig.replay_url !== undefined && providerConfig.replay_url !== null && providerConfig.replay_url !== '' ? providerConfig.replay_url : ''
            setGameData((prevData) => { return { ...prevData, ['replay_url']: replay_url } })
        } catch (err) {
            console.log("Error obteniendo la configuración del proveedor: " + " " + props.row.game + " " + props.row.provider)
        }
    })


    return (
        <>
            {gameData.replay_url !== '' &&
                <div>
                    {props.value} <FontAwesomeIcon onClick={() => showGameDetail()} icon="eye" className='icon' />
                </div>
            }

            {gameData.replay_url === '' && props.value}
        </>
    )
}

export default GameRender
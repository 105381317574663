import React from 'react'
import AdminProfile from './AdminProfile'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

function LoginFirst(props) {
  return (
    <AdminProfile {...props} changePass={true}/>
  )
}

export default withTranslation()(withRouter(LoginFirst))
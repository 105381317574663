import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function BannersContainersDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_BannersContainersDetails')

    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const containerId = utils.getSessionItem('current-container')

    const [containerDetail, setContainerDetail] = useState({
        init_page: 0,
        container: {
            container: containerId,
            name: ""
        },
        banners: [],
        availables: [],
        base_availables: [],
        avaliablesVisible: false,
        filter: ""
    });

    useEffect(function () {
        if (containerId !== undefined && containerId !== '-1') {
            adminClient.getBannerContainerDetails(session, company, containerId).then(
                function (result) {
                    let containerAux = { ...containerDetail }
                    containerAux.container = result.data.container;
                    containerAux.banners = result.data.banners;
                    containerAux.availables = result.data.availables;
                    containerAux.base_availables = result.data.availables;
                    setContainerDetail(containerAux);
                    setAddOrUpdate('update');

                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            adminClient.getBannerContainerDetails(session, company, containerId).then(
                function (result) {
                    setContainerDetail((prevState) => ({ ...prevState, availables: result.data.availables, base_availables: result.data.availables }));
                },
                function (err) {
                    console.error(err);
                }
            );
            setAddOrUpdate('add');
        }
    }, []);

    const removeBanner = (banner) => {
        let oldState = { ...containerDetail }
        oldState.banners = oldState.banners.filter(item => item.banner !== banner.banner);
        oldState.availables = [banner, ...oldState.availables];
        // No hacemos unshift en base_availables si ya existe el banner
        if (!oldState.base_availables.find(item => item.banner === banner.banner)) {
            oldState.base_availables = [banner, ...oldState.base_availables];
        }
        setContainerDetail(oldState);
    }


    const addBanner = (banner) => {
        let oldState = { ...containerDetail }
        oldState.availables = oldState.availables.filter(item => item.banner !== banner.banner);
        //quitar el baner de la lista de base_available
        oldState.base_availables = oldState.base_availables.filter(item => item.banner !== banner.banner);
        banner.view_order = oldState.banners.length;
        oldState.banners.push(banner);
        setContainerDetail(oldState);
    }

    const handleShowAvailables = () => {
        setContainerDetail((prevState) => {
            if (prevState.avaliablesVisible) {
                document.getElementById("availablesDIV").classList.add("d-none");
            } else {
                document.getElementById("availablesDIV").classList.remove("d-none");
            }
            return { ...prevState, avaliablesVisible: !prevState.avaliablesVisible }
        });

    }

    const sortBanners = (a, b) => {
        return (a.view_order > b.view_order) ? 1 : ((b.view_order > a.view_order) ? -1 : 0);
    }

    const handleUpdateOrder = (bannerId, up, evt) => {
        console.log("handleUpdateOrder");
        let nuevo_orden = 0;
        let antiguo_orden = 0;
        let prevState = { ...containerDetail };
        let banners = prevState.banners;
        banners.forEach(banner => {
            if (banner.banner === bannerId) {
                antiguo_orden = Number(banner.view_order);
                nuevo_orden = antiguo_orden + up;
                banner.selected_css = "calimaco-selected";
            } else {
                banner.selected_css = "";
            }
        });
        console.log("banner = " + bannerId + " order = " + nuevo_orden + " antiguo_orden = " + antiguo_orden);

        banners.forEach(banner => {
            if (banner.banner === bannerId) {
                banner.view_order = nuevo_orden;
            } else {
                if (nuevo_orden < antiguo_orden) {
                    if (banner.view_order >= nuevo_orden && banner.view_order < antiguo_orden) {
                        banner.view_order++;
                    }
                } else {
                    if (banner.view_order <= nuevo_orden && banner.view_order > antiguo_orden) {
                        banner.view_order--;
                    }
                }
            }
        });
        prevState.banners = banners.sort(sortBanners);
        setContainerDetail(prevState)
    }


    const handleFilter = (evt) => {
        let oldState = { ...containerDetail };
        oldState.filter = evt.target.value;
        let availables = [];
        oldState.base_availables.forEach(element => {
            if (
                ((element.name !== undefined && element.name !== null && oldState.filter.length > 0 &&
                    (element.name.toUpperCase().indexOf(oldState.filter.toUpperCase()) >= 0)) ||
                    (element.banner !== undefined && oldState.filter.length > 0 &&
                        !isNaN(oldState.filter) && element.banner === parseInt(oldState.filter))) ||
                (oldState.filter.length === 0)
            ) {
                availables.push(element);
            }

        });
        oldState.availables = availables;
        setContainerDetail(oldState)

    }

    const onChangeHandlerContainer = (e) => {
        const { name, value } = e.target || e;
        setContainerDetail(prevState => ({
            ...prevState,
            container: {
                ...prevState.container,
                [name]: value,
            }
        }));
    };

    const setPage = (page) => {
        setContainerDetail((prevState) => ({ ...prevState, init_page: page }));
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            let bannersIds = [];
            containerDetail.banners.forEach(element => {
                bannersIds.push(element.banner);
            });
            let promise;
            if (addOrUpdate === 'add') {
                promise = adminClient.addBannerContainerDetails(session, company, JSON.stringify(containerDetail.container), JSON.stringify(bannersIds));
            }
            else {
                promise = adminClient.updateBannerContainerDetails(session, company, JSON.stringify(containerDetail.container), JSON.stringify(bannersIds));
            }
            promise.then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Banners Containers Details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Error'), t('Saving error ') + t(msg.description))
                    }
                },
                (error) => {
                    props.showAlert(t('Error'), t('Saving error ') + t(error.description))
                }
            )
        }
    }

    const goBack = () => {
        props.history.push('/reports/report/banners_containers/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Container details'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_BannersContainersDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="container"
                            required
                            disabled={addOrUpdate === 'update'}
                            inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            type="text"
                            value={containerDetail.container.container}
                            onChange={onChangeHandlerContainer}
                        />
                    </div>
                    <div className="col-12 col-md-3">

                        <TextField onChange={onChangeHandlerContainer} name="name" label={t("Name")} type="text" value={containerDetail.container.name} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 text-left">
                        {containerDetail.init_page > 0 &&
                            <button className="btn btn-secondary float-start" onClick={(evt) => { setPage(containerDetail.init_page - 1) }}>{t("Previous")}</button>
                        }
                    </div>
                    <div className="col-4 text-center" >
                        {containerDetail.init_page * 100}-{Math.min((containerDetail.init_page + 1) * 100, containerDetail.banners.length)} / {containerDetail.banners.length}
                    </div>
                    <div className="col-4">
                        {(containerDetail.init_page + 1) * 100 < containerDetail.banners.length &&
                            <button className="btn  btn-secondary" href="#" onClick={(evt) => { setPage(containerDetail.init_page + 1) }}>{t("Next")}</button>
                        }
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12 ">
                    {utils.isGranted("SAVE_CMS_BANNERS_CONTAINERS") &&
                        <button type="button" className="btn btn-secondary float-start" onClick={handleShowAvailables}>{t("Add banners")}</button>
                    }
                    {utils.isGranted("SAVE_CMS_BANNERS_CONTAINERS") &&
                        <button type="button" className="btn btn-primary" onClick={save}>{t("Save")}</button>
                    }
                </div>
            </div>
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-striped table-hover table_layout_fixed"><thead>
                        <tr><td><td></td></td><td>{t("ID")}</td><td>{t("Name")}</td><td  > {t("Order")}</td><td>{t("Delete")}</td></tr>
                    </thead>
                        <tbody>
                            {containerDetail.banners.slice(containerDetail.init_page, 100).map((item, idx) => (
                                <tr className={item.selected_css} key={item.machine} id={"grid-machine-" + item.machine} >
                                    <td>{containerDetail.init_page * 100 + idx}</td>
                                    <td>{item.banner}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        {idx === 0 && <span className="cursor-pointer  calimaco-empty-arrow "></span>}
                                        {idx > 0 && <span className="cursor-pointer  calimaco-border-arrow " onClick={(evt) => handleUpdateOrder(item.banner, -1, evt)} ><FontAwesomeIcon size="lg" icon="arrow-up" /></span>}
                                        {idx < containerDetail.banners.length - 1 && <span className="cursor-pointer calimaco-border-arrow" onClick={(evt) => handleUpdateOrder(item.banner, +1, evt)}> <FontAwesomeIcon size="lg" icon="arrow-down" /> </span>}
                                    </td>
                                    <td > <span className="cursor-pointer" onClick={() => removeBanner(item)}><FontAwesomeIcon icon="trash-alt" /></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row d-none lobbyMachinesContainer pb-4" id="availablesDIV">
                <TextField onChange={handleFilter} value={containerDetail.filter} label={t("Filter")} type="text" />

                <div className="table-responsive">
                    <table className="table table-striped table-hover table_layout_fixed"><thead>
                        <tr><td>{t("ID")}</td><td>{t("Name")}</td></tr>
                    </thead>
                        <tbody>
                            {containerDetail.availables.slice(0, 100).map(item => (
                                <tr key={item.banner} id={"grid-machine-" + item.banner} onClick={() => addBanner(item)}>
                                    <td>{item.banner}</td>
                                    <td>{item.name}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(BannersContainersDetails);
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from '../../utils';
import AdminClient from '../../AdminClient';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFormValidation from '../../hooks/parsleyValidation';



function PopupsLobbyDetails(props) {

    const company = utils.getCurrentCompany().company;

    const [lobbyDetails, setLobbyDetails] = useState({ lobby: "", name: "", base_availables: [], availables: [], popups: [] })

    const [newOrUpdate, setNewOrUpdate] = useState("new");

    const [filter, setFilter] = useState("");

    let adminClient = new AdminClient();

    let lobbyId = utils.getSessionItem("current-lobby");

    const { t } = props;

    const { formValidation } = useFormValidation("form-popupDetails")


    useEffect(function () {
        adminClient.getPopupsLobbyDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), lobbyId).then(
            function (result) {
                if (lobbyId !== undefined && lobbyId !== "-1") {
                    let lobby = { ...lobbyDetails };
                    lobby.lobby = result.data.lobby.lobby;
                    lobby.name = result.data.lobby.name;
                    lobby.availables = result.data.availables;
                    lobby.base_availables = result.data.availables;
                    lobby.popups = result.data.popups;
                    setLobbyDetails(lobby);
                    setNewOrUpdate("update");
                }
                else {
                    let lobby = { ...lobbyDetails };
                    lobby.availables = result.data.availables;
                    lobby.base_availables = result.data.availables;
                    setLobbyDetails(lobby);
                    setNewOrUpdate("new");
                }
            },
            function (err) {
                console.error(err);
            }
        )


    }, []);

    const handleUpdate = (evt) => {
        let lobby = { ...lobbyDetails };
        lobby[evt.target.name] = evt.target.value;
        setLobbyDetails(lobby);
    }

    const handleShowAvailables = () => {
        let divAvailables = document.getElementById("availablesDIV");
        let classList = divAvailables.classList;
        if (classList.contains("d-none")) {
            classList.remove("d-none");
        }
        else {
            classList.add("d-none");
        }
    }

    const handleSubmit = () => {
        if (formValidation.validate()) {

            let lobby = { ...lobbyDetails };
            var popupsIds = [];
            lobby.popups.forEach(element => {
                popupsIds.push(element.popup);
            });
            let promise;
            if (newOrUpdate === 'new') {
                promise = adminClient.addPopupsLobbyDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(lobby), JSON.stringify(popupsIds));
            }
            else {
                promise = adminClient.updatePopupsLobbyDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(lobby), JSON.stringify(popupsIds));
            }
            promise.then(
                function (msg) {
                    if (msg.result === "OK") {
                        props.showAlert(t("Correct"), <p>{t("Popups Lobby saved")}</p>);
                        setNewOrUpdate("update");
                    }
                    else {
                        console.error(msg.err);
                    }
                },
                function (err) {
                    props.showAlert(t("Error"), <p>{t("Error in save Popups Lobby")}</p>, undefined, undefined, err)
                    console.error(err);
                });
        }
    }

    const handleFilter = (evt) => {
        let filter = evt.currentTarget.value
        setFilter(filter);

        let availables = [];
        lobbyDetails.base_availables.forEach(element => {
            const filterString = String(filter); // Convertir filter a cadena de texto en caso de ser la ID
            if ((filter.length > 0 && (element.name.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || element.popup.toUpperCase().indexOf(filterString.toUpperCase()) >= 0)) ||
                (filter.length === 0)) {
                availables.push(element);
            }
        })
        setLobbyDetails((prevState) => ({
            ...prevState, availables: availables
        }))
    }

    const addPopup = (popup) => {
        let popups = [...lobbyDetails.popups]
        let availables = lobbyDetails.availables.filter(item => item.popup !== popup.popup);
        let base_availables = lobbyDetails.base_availables.filter(item => item.popup !== popup.popup)
        popup.view_order = popups.length;
        popups.push(popup);
        setLobbyDetails((prevState) => ({
            ...prevState,
            popups: popups,
            availables: availables,
            base_availables: base_availables
        }));
    }

    const removePopup = (popup) => {

        let availables = [...lobbyDetails.availables];
        let base_availables = [...lobbyDetails.base_availables];
        let popups = lobbyDetails.popups.filter(item => item.popup !== popup.popup);

        availables.unshift(popup);
        base_availables.unshift(popup)
        setLobbyDetails((prevState) => ({
            ...prevState,
            popups: popups,
            availables: availables,
            base_availables: base_availables
        }));
    }

    const handleUpdateOrder = (popupId, up, evt) => {
        let nuevo_orden = 0;
        let antiguo_orden = 0;
        let lobby = { ...lobbyDetails };

        lobby.popups.forEach(popup => {
            if (popup.popup === popupId) {
                antiguo_orden = Number(popup.view_order);
                nuevo_orden = antiguo_orden + up;
                popup.selected_css = "calimaco-selected";
            } else {
                popup.selected_css = "";
            }
        });

        lobby.popups.forEach(popup => {
            if (popup.popup === popupId) {
                popup.view_order = nuevo_orden;
            } else {
                if (nuevo_orden < antiguo_orden) {
                    if (popup.view_order >= nuevo_orden && popup.view_order < antiguo_orden) {
                        popup.view_order++;
                    }
                } else {
                    if (popup.view_order <= nuevo_orden && popup.view_order > antiguo_orden) {
                        popup.view_order--;
                    }

                }
            }
        });

        const sortPopups = (a, b) => {
            return (a.view_order > b.view_order) ? 1 : ((b.view_order > a.view_order) ? -1 : 0);
        }

        lobby.popups = lobby.popups.sort(sortPopups);

        setLobbyDetails(lobby);
    }

    const goBack = () => {
        props.history.push('/reports/report/popups_lobbies/table')
    }

    const dataHeader = {
        backLink: goBack,
        title: t("Popups Lobby config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="form-popupDetails">

                <div className="col-12   float-left pt-3">
                    <div className="row">
                        <div className="col-12 col-md-4 col-xxl-2">
                            <TextField required onChange={handleUpdate} name={"lobby"} disabled={newOrUpdate === "update"}
                                label={t("Identifier")} type="text" value={lobbyDetails.lobby} />
                        </div>

                        <div className="col-12 col-md-4 col-xxl-2">
                            <TextField required onChange={handleUpdate} name={"name"}
                                label={t("Name")} type="text" value={lobbyDetails.name} />
                        </div>
                        <div className="row">
                            <div className="col-12 ">
                                <button type="button" className="btn btn-secondary float-start" onClick={handleShowAvailables}>{t("Add Popups")}</button>

                                <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table_layout_fixed"><thead>
                                <tr><td><td></td></td><td>{t("ID")}</td><td>{t("Name")}</td><td  > {t("Order")}</td><td>{t("Delete")}</td></tr>
                            </thead>
                                <tbody>
                                    {lobbyDetails.popups.map((item, idx) => (
                                        <tr className={item.selected_css} key={item.popup} id={"grid-popup-" + item.popup} >
                                            <td>{idx}</td>
                                            <td>{item.popup}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {idx === 0 && <span className="cursor-pointer  calimaco-empty-arrow "></span>}
                                                {idx > 0 && <span className="cursor-pointer  calimaco-border-arrow " onClick={(evt) => handleUpdateOrder(item.popup, -1, evt)} ><FontAwesomeIcon size="lg" icon="arrow-up" /></span>}
                                                {idx < lobbyDetails.popups.length - 1 && <span className="cursor-pointer calimaco-border-arrow" onClick={(evt) => handleUpdateOrder(item.popup, +1, evt)}> <FontAwesomeIcon size="lg" icon="arrow-down" /> </span>}
                                            </td>
                                            <td > <span className="cursor-pointer" onClick={() => removePopup(item)}><FontAwesomeIcon icon="trash-alt" /></span></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row d-none lobbyPopupsContainer pb-4" id="availablesDIV">
                        <TextField onChange={handleFilter} value={filter} label={t("Filter")} type="text" />
                        <div className="table-responsive">
                            <table className="table table-striped table-hover table_layout_fixed"><thead>
                                <tr><td>{t("ID")}</td><td>{t("Name")}</td></tr>
                            </thead>
                                <tbody>
                                    {lobbyDetails.availables.slice(0, 100).map(item => (
                                        <tr key={item.popup} id={"grid-machine-" + item.popup} onClick={() => addPopup(item)}>
                                            <td>{item.popup}</td>
                                            <td>{item.name}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>
        </LayoutSection >
    )
}

export default withTranslation()(PopupsLobbyDetails)

import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AdminClient from '../../AdminClient';
import utils from '../../utils';

const Loading = (props) => {
    useEffect(() => {
        let path = props.location.pathname.split("/");


        console.log("Loading..." + path);
        let clientAPI = new AdminClient();

        let i18nPromise = clientAPI.getI18n();


        Promise.all([i18nPromise]).then(values => {
            //  console.log("i18n ok " + JSON.stringify(values[0]));
            let _res = {};
            values[0].data.forEach(translate => {
                if (_res[translate.lang] === undefined) {
                    _res[translate.lang] = { "translation": {} };

                }
                _res[translate.lang].translation[translate.key] = translate.value;
            });

            console.log("i18n res = " + JSON.stringify(_res));
            utils.setSessionItem("i18n", JSON.stringify(_res));


            i18n
                .use(initReactI18next) // passes i18n down to react-i18next
                .init({
                    resources: _res,
                    lng: "es",
                    fallbackLng: "en",

                    interpolation: {
                        escapeValue: false
                    }

                });
            i18n.changeLanguage("es");

            console.log("Redirection to login page...");
            props.history.push('/login');


        }, reason => {
            console.log("Error " + reason);
        }
        );


    }, []);

    if (utils.getSessionItem("user") !== undefined) {
        return (
            <>
                <p>Loading...</p>
            </>
        );
    } else {
        return (<></>);
    }
}

export default withRouter(Loading);

import React, { useState, useContext, useEffect } from 'react'
import AdminClient from '../../AdminClient';
import utils from "../../utils";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';



function Logout(props) {
    const adminClient = new AdminClient();

    const { t } = props;



    useEffect(function () {

        if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
            this.props.history.push('/');
        }

    }, [])


    const handleCancel = () => {
        props.history.goBack();
    }

    const handleLogout = () => {
        adminClient.logout(
            JSON.parse(utils.getSessionItem("user")).session
        ).then(
            function (data) {
                utils.removeSession();
                props.history.push("/");
            },
            function (err) {
                console.error("calimaco: Error in logout: " + err);
                utils.removeSession();
                props.history.push("/");
            }
        );
    }
    return (
        <div className="col-12   float-left pt-3">
            <div className="card">
                <div className={"card-header "}>{t("Logout")}</div>
                <div className="card-body p-0 p-md-3 text-center" >

                    <p>{t("Do you want to exit?")}</p>
                    <div className="row d-flex justify-content-center ">
                        <button className="btn btn-secondary" onClick={handleCancel}>{t("Cancel")}</button>
                        <button className="btn btn-primary" onClick={handleLogout}>{t("Yes, exit")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withTranslation()(withRouter(Logout))
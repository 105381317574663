import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import { TextField } from '@mui/material';
import BasicSelect from '../../components/basic/BasicSelect';
import useFormValidation from '../../hooks/parsleyValidation';

function CreateRetail(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_CreateRetail');

    const [voucher, setVoucher] = useState({
        amount: 1,
        currency: "EUR"
    });

    const goBack = () => {
        props.history.push('/home');
    };

    const dataHeader = {
        backLink: goBack,
        title: t('Retail'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name !== undefined && value !== undefined) {
            setVoucher((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const printVoucher = (data) => {
        utils.setSessionItem("voucherTicket_data", JSON.stringify(data))
        window.open("/retail/voucherPrint", "_blank");
    }

    const save = (evt) => {
        evt.preventDefault();
        showLoading()
        if (formValidation.validate()) {
            var promise = adminClient.createVoucher(session, company, voucher.amount * 100, voucher.currency);
            promise.then(
                function (msg) {
                    if (msg.result === 'OK') {
                        closeLoading()
                        props.showAlert(t("Create Voucher"), <p>{t("Creation successful")}</p>);
                        printVoucher(msg);
                    } else {
                        console.error(msg);
                        closeLoading()
                        props.showAlert(t("Saving note"), t('Saving error'));
                    }
                }, function (err) {
                    console.error(err);
                    closeLoading()
                    props.showAlert(t("Create Voucher error"), <p>{t("Error: ") + t("ERROR_" + err.description)}</p>);
                });
        }
    };

    const showLoading = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Loading"), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_CreateRetail">
                <div className="row">
                    {utils.isGranted('SAVE_CONFIGURATION_RETAIL') && (
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary" onClick={save}>{t("Create")}</button>
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-md-4">
                        <TextField label={t("Amount")} name="amount" required type="number" value={voucher.amount} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            name='currency'
                            label={t("Currency")}
                            idProperty="currency"
                            nameProperty="currency"
                            data="currencies"
                            basicInfoName="CurrenciesCompany"
                            onChange={onChangeHandler}
                            value={voucher.currency}
                            required
                        />
                    </div>
                </div>
            </form>
        </LayoutSection>
    );
}

export default withTranslation()(CreateRetail);

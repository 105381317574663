import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom'
import TableAndDetails from "../../components/basic/TableAndDetails";
import AdminClient from '../../AdminClient';
import utils from "../../utils";
import { generateColumnsDef } from '../../libs/utilsReports';
import { TextField } from '@mui/material';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import BasicSelect from '../../components/basic/BasicSelect';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import moment from "moment";
import useFormValidation from '../../hooks/parsleyValidation';
import AlertMessage from '../../components/NavigationAndLayouts/AlertMessage';
import { useAlert } from '../../hooks/Alert';

const ReportBasicPage = (props) => {

  let path = props.location.pathname.split("/")
  let reportId = path[path.length - 2]
  const { t } = props;
  const adminClient = new AdminClient();
  const company = utils.getSessionItem("Company");
  const session = JSON.parse(utils.getSessionItem("user")).session;
  const [userDetails, setUserDetails] = useState({ db: null, userId: null });
  const { formValidation } = useFormValidation("Form_reportBasic")
  const [selected, alertOpen, showAlert, closeAlert] = useAlert();

  const [report, setReport] = useState({
    data: [],
    columns: []
  });
  const [detail, setDetail] = useState({});
  const [addOrUpdate, setAddOrUpdate] = useState({});


  useEffect(() => {
    let properties = {}

    if (utils.getSessionItem("current-user") !== undefined && utils.getSessionItem("current-user") !== null) {
      properties.db = utils.getSessionItem("current-user").split(".")[0];
      properties.userId = utils.getSessionItem("current-user").split(".")[1];
    }
    setUserDetails(properties)
    reloadReport(properties);

  }, []);

  const reloadReport = (properties) => {
    let reportPromise = adminClient.getReport(session, company, reportId, [], properties.userId, properties.db);
    reportPromise.then(
      function (msg) {
        let columnsDef = generateColumnsDef(msg.data.def, t);
        setReport((prevState) => ({
          ...prevState,
          data: msg.data.data,
          columns: columnsDef,
          key: msg.data.def.config.table.identifier || null,
          save: msg.data.def.config.save || undefined,
          showNew: msg.data.def.config.showNew || true,
          saveButtonName: msg.data.def.config.saveButtonName || "Save",
          reloadSave: msg.data.def.config.reloadSave || null,
          delete: msg.data.def.config.delete || undefined,
          colTableSize: msg.data.def.config.colTableSize || undefined,
          sizePageTable: msg.data.def.config.sizePageTable || undefined,
          noDeleteConfirmation: msg.data.def.config.noDeleteConfirmation || false,
          saveConfirmation: msg.data.def.config.saveConfirmation || undefined,
          title: msg.data.def.config.table.title,
          showFilterColumn: msg.data.def.config.showFilterColumn || false
        }));
      }, function (err) {

      }
    );
  }

  const handleSaveDetails = () => {
    if (report.saveConfirmation !== undefined) {
      showAlert(report.saveConfirmation.title || t("Save"), report.saveConfirmation.content || t("Are you sure you want to save?"), [
        <button type='button' className='btn btn-secondary' onClick={closeAlert}>{t('Cancel')}</button>,
        <button type='button' className='btn btn-primary' onClick={(evt) => { saveDetails(); closeAlert(); }}>{t('Ok')}</button>
      ])
    } else {
      saveDetails();
    }
  }

  const handleDeleteDetails = (id) => {
    if (report.noDeleteConfirmation) {
      deleteDetails();
    } else {
      showAlert(
        t('Delete'),
        <p>{t('Are you sure you want to delete?')}</p>,
        [<button type='button' className='btn btn-secondary' onClick={closeAlert}>{t('Cancel')}</button>,
        <button type='button' className='btn btn-primary' onClick={(evt) => { deleteDetails(); closeAlert(); }}>{t('Ok')}</button>]
      )
    }
  }

  const addOrUpdateObjective = (detailReport) => {
    formValidation?.reset();

    if (detailReport === -1) {
      setDetail({})
      setAddOrUpdate("add");
    } else {
      setDetail(detailReport);
      setAddOrUpdate("update");

    }
  };

  const deleteDetails = () => {
    let nameReport = "";
    let split = reportId.split("_");

    for (let i of split) {
      nameReport += i.charAt(0).toUpperCase() + i.slice(1);
    } let nameFunction;

    if (report.delete !== undefined) {
      nameFunction = report.delete;
    }
    else {
      nameFunction = "delete" + nameReport + "Details";
    }

    let detailsDelete;
    if (report.key.length === 0) {
      console.log("Error en report")
    }
    if (report.key.length === 1) {
      detailsDelete = detail[report.key[0]]
    }
    else {
      let detailsAux = {}
      for (let x of report.key) {
        detailsAux[x] = detail[x]
      }
      detailsDelete = JSON.stringify(detailsAux);
    }

    adminClient[nameFunction](
      session,
      company,
      detailsDelete
    ).then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK' && msg.err == null) {
          props.showAlert(t("Ok"), t("Deleted succesfull"))
        } else {
          props.showAlert(t("Error"), t("Deleting error"))
        }
        props.history.push(props.history.location.pathname);
      }, function (err) {
        props.showAlert(t("Error"), t("Deleting error"))
        props.history.push(props.history.location.pathname);
      });

  }

  const saveDetails = (event) => {
    if (formValidation.validate()) {
      let split = reportId.split("_");
      let nameReport = "";
      for (let i of split) {
        nameReport += i.charAt(0).toUpperCase() + i.slice(1);
      }

      let nameFunction;
      if (report.save !== undefined) {
        nameFunction = report.save;
      }
      else if (typeof adminClient["save" + nameReport + "Details"] === "function") {
        nameFunction = "save" + nameReport + "Details";
      }
      else {
        nameFunction = addOrUpdate + nameReport + "Details";
      }
      try {
        adminClient[nameFunction](
          session,
          company,
          JSON.stringify(detail),
          userDetails.db,
          userDetails.userId
        ).then(
          function (msg) {
            console.log("Update result " + JSON.stringify(msg));
            if (msg.result === 'OK' && msg.err == null) {
              props.showAlert(t("Ok"), t("Saved succesfull"))
              if (report.reloadSave === "true") props.history.push(props.history.location.pathname);
            } else {
              props.showAlert(t("Error"), t("Saving error") + " " + t(msg.description))
            }
            if (addOrUpdate === "add")
              setAddOrUpdate("update")
            reloadReport(userDetails);
          }, function (err) {
            props.showAlert(t("Error"), t("Saving error") + " " + t(err.description))
            props.history.push(props.history.location.pathname);
          });
      } catch (err) {
        console.log("Unknow save function")
      }

    }
  }
  const onChangeHandler = (evt) => {
    const { name, value } = evt.target || evt;
    setDetail(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const render = useCallback((props) => {

    return (
      <>

        {report.columns.map(objeto => {
          let details = objeto.details;
          //let label = t(objeto.accessor.charAt(0).toUpperCase() + objeto.accessor.slice(1));
          let label = objeto.Header
          switch (details.type) {

            case "text":
              return (
                <div className='col-12'>
                  <TextField
                    label={label}
                    value={detail[objeto.accessor] || ""}
                    name={objeto.accessor}
                    onChange={onChangeHandler}
                    required={details.required}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}
                  ></TextField>
                </div >
              )

            case "text_translated":
              return (
                <div className='col-12'>
                  <TextField
                    label={label}
                    value={detail[objeto.accessor] || ""}
                    name={objeto.accessor}
                    onChange={onChangeHandler}
                    required={details.required}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}
                  ></TextField>
                </div >
              )

            case "number":
              return (
                <div className='col-12'>
                  <TextField
                    label={label}
                    value={detail[objeto.accessor] || ""}
                    name={objeto.accessor}
                    inputProps={{ type: "number" }}
                    onChange={onChangeHandler}
                    required={details.required}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}

                  ></TextField>
                </div >
              )

            case "select":
              return (
                <div className='col-12'>
                  <BasicSelect
                    idProperty={details.idProperty}
                    nameProperty={details.nameProperty || "name"}
                    data={details.data || "data"}
                    basicInfoName={details.basicInfoName}
                    value={detail[objeto.accessor] || ""}
                    name={objeto.accessor}
                    label={label}
                    required={details.required}
                    addOpts={details.addOpts}
                    onChange={onChangeHandler}
                    filters={details.filters || []}
                    translate={details.translate_property ? { property: details.translate_property, prefix: details.translate_prefix || "" } : false}
                    multiple={details.multiple || false}
                    noEmptyValue={details.noEmptyValue || false}
                    disableClearable={details.disableClearable === "true"}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}
                  />
                </div >
              )
            case "date":
              return (
                <div className='col-12'>
                  <DateAndTimePicker
                    label={label}
                    required={details.required}
                    value={utils.formatDate(detail[objeto.accessor]) || null}
                    fieldName={objeto.accessor}
                    showTime={false}
                    callBackFunction={(value, fieldName) => {
                      if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                      onChangeHandler({ value: value, name: fieldName })
                    }}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}
                  />
                </div>
              )
            case "time":
              return (
                <div className='col-12'>
                  <DateAndTimePicker
                    label={label}
                    required={details.required}
                    value={utils.formatDate(detail[objeto.accessor]) || null}
                    fieldName={objeto.accessor}
                    showTime={true}
                    callBackFunction={(value, fieldName) => {
                      if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                      onChangeHandler({ value: value, name: fieldName })
                    }}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}
                  />
                </div>
              )

            case "number100":
              return (
                <div className='col-12'>
                  <TextField
                    label={label}
                    value={detail[objeto.accessor] / 100 || ""}
                    name={objeto.accessor}
                    inputProps={{ type: "number" }}
                    onChange={(evt) => { onChangeHandler({ name: objeto.accessor, value: evt.target.value * 100 }) }}
                    required={details.required}
                    disabled={addOrUpdate === "update" && report.key.includes(objeto.accessor)}

                  ></TextField>
                </div >
              )
            case "none":
              return (
                <div className='col-12'>
                </div>
              )
            default:
              return <></>;
          }

        })}
        <div className="col-12">
          <button type="button" className="btn btn-primary" onClick={handleSaveDetails}>{t(report.saveButtonName || "Save")}</button>
          {(report.delete === undefined && report.delete !== false && addOrUpdate == 'update') &&
            <button type="button" className="btn btn-secondary" onClick={handleDeleteDetails}>{t("Delete")}</button>}
        </div>

      </>
    );
  }, [JSON.stringify(report), JSON.stringify(detail)]);

  const dataHeader = {
    // title: t(report.reportId.charAt(0).toUpperCase() + reportId.slice(1) + " config"),
    title: t(report.title),
    urlHelp: "",
    idDoc: props.idDoc
  }

  return (
    <LayoutSection {...props} reportId={reportId} dataHeader={dataHeader} reportDoc={true}>

      <form id={"Form_reportBasic"}>
        {report.columns.length > 0 && (
          <>

            <TableAndDetails
              colTableSize={report.colTableSize || 8}
              sizePageTable={report.sizePageTable || 20}
              ShowNew={report.showNew === "false" ? false : true}
              columns={report.columns}
              data={report.data}
              renderDetails={render}
              setDetail={addOrUpdateObjective}
              showAlert={props.showAlert}
              handleCloseAlert={props.handleCloseAlert}
              desactiveForm={true}
              showFilterColumn={report.showFilterColumn}
            />
          </>)}
      </form>
      <AlertMessage
        key="PrivateLayout_alert"
        isActive={alertOpen}
        size={selected.size}
        content={selected.content}
        title={selected.title}
        buttons={selected.buttons}
        loading={selected.loading}
        handleCloseAlert={closeAlert}
      />

    </LayoutSection>

  );
}

export default withTranslation()(withRouter(ReportBasicPage))
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils, { isGranted } from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import TreeSelector from '../../components/basic/TreeSelector';
function TagDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_TagDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const tag = utils.getSessionItem('current-tag')

    const [tagDetail, setTagDetail] = useState({
        tag: {
            tag: tag,
            name: "",
            company: company,
            description: ""
        },
        machines: [],
        checked: [],
        nodes: []
    });
    const goBack = () => {
        props.history.push('/reports/report/tags/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Tag details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        let promises = []
        promises.push(adminClient.getMachines(session, company))

        if (tag !== undefined && tag !== '-1') {
            promises.push(adminClient.getTagDetails(session, company, tag));
            setAddOrUpdate('update');
        }
        else {
            setAddOrUpdate('add');
        }

        Promise.all(promises).then(
            async function (result) {
                let tagDetailAux = { ...tagDetail };
                if (result[1] !== undefined) {
                    tagDetailAux.tag = result[1].data;
                    tagDetailAux.checked = result[1].machines;
                }
                tagDetailAux.machines = result[0].machines;
                setTagDetail(tagDetailAux);
            },
            function (err) {
                console.error(err);
            }
        );

    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setTagDetail(prevState => ({
            ...prevState,
            tag: {
                ...prevState.tag,
                [name]: value,
            }
        }));
    };

    const onCheck = (checked) => {
        setTagDetail((prevState) => ({ ...prevState, checked: checked }));
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            let tagAux = JSON.parse(JSON.stringify(tagDetail.tag));
            tagAux.machines = tagDetail.checked;

            adminClient[addOrUpdate + "Tag"](
                session,
                company,
                JSON.stringify(tagAux)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Tag details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Tag details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Tag details'), t('Saving error ') + error.description)
                }
            )
        }
    }
    const remove = (evt) => {
        let tagAux = JSON.parse(JSON.stringify(tagDetail.tag));
        props.showAlert(
            t('Tag details'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeAux(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

        const removeAux = (evt) => {
            adminClient.deleteTag(
                session,
                company,
                JSON.stringify(tagAux)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Tag details'), t('Deleted succesfull'))
                        goBack();
                    } else {
                        props.showAlert(t('Tag details'), t('Deleting error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Tag details'), t('Deleting error ') + error.description)
                }
            )
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_TagDetails">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('Identifier')}
                            name="tag"
                            required
                            disabled={addOrUpdate === 'update'}
                            type="text"
                            value={tagDetail.tag.tag}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-8">
                        <TextField
                            label={t('Name')}
                            name="name"
                            required
                            type="text"
                            value={tagDetail.tag.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-8">
                        <TextField
                            label={t('Description')}
                            name="description"
                            required
                            type="text"
                            value={tagDetail.tag.description}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                {utils.isGranted("SAVE_CONFIGURATION_TAGS") &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={remove}
                        >
                            {t('Delete')}
                        </button>
                    </div>
                }
            </div>
            <div className="row mt-4">
                {addOrUpdate === "update" &&
                    <div className="col-12">
                        <TreeSelector
                            label={t("Machine")}
                            nodes={tagDetail.machines}
                            checked={tagDetail.checked}
                            onCheck={onCheck}
                            typeTree="machine"
                        ></TreeSelector>
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(TagDetails);
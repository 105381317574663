import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';
import EditorJSON from "../../components/basic/EditorJSON";
import Tab from '../../components/basic/BasicTabs';
import Report from "../../components/basic/Report";


function UserPaymentAccountDetails(props) {

    const { t } = props;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    let accountId = utils.getSessionItem("current-payment_account")
    const player = JSON.parse(utils.getSessionItem("userDetails")).user;

    const [accountDetails, setAccountDetails] = useState({
        name: "",
        payment_account: accountId,
        type: 'BANK',
        status: 'NEW',
        details: {}
    });

    const [addOrUpdate, setAddOrUpdate] = useState("");
    const [JSONErrors, setJSONErrors] = useState(false);


    const adminClient = new AdminClient();

    var filter_logs = [
        { "field": "payment_account", "operator": "=", "value": accountId }
    ];
    utils.setSessionItem("ReportFilter-user_payment_accounts_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-user_payment_accounts_logs", JSON.stringify([{ field: "payment_account", visibility: "hidden" }]));



    useEffect(function () {
        adminClient.getUserPaymentAccountDetails(session, company, player.db, player.user, accountId).then(
            (res) => {
                setAccountDetails(res.data);
                if (accountId !== "-1") {
                    setAddOrUpdate("update");
                }
            }, (err) => {
                console.log(err);
            }
        );

    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setAccountDetails((prevState) => ({ ...prevState, [name]: value }));
    };



    const handleSubmit = () => {
        if (formValidation.validate()) {
            if (JSONErrors) {
                props.showAlert(t('Payment account details'), t('JSON format incorrect'))
            }
            else {
                let promise = adminClient.saveUserPaymentAccountDetails(
                    session,
                    company,
                    player.db,
                    player.user,
                    JSON.stringify(accountDetails)
                );

                promise.then(
                    function (msg) {
                        console.log("Update result " + JSON.stringify(msg));
                        if (msg.result === 'OK') {
                            if (accountDetails.account === -1) {
                                onChangeHandler({ name: "account", value: msg.account })
                            }
                            props.showAlert(t("Correct"), <p>{t("Payment account saved")}</p>);
                            window['windowRefLogs'].executeReport(0);
                            goBack();
                        }

                    }, function (err) {
                        console.log("Payment account error" + err);
                        props.showAlert(t("Error"), <p>{t("Error in save payment account")}</p>)
                    }
                );
            }
        }

    };


    const goBack = () => {
        props.history.push("/support/report/user_payment_accounts/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Payment account details"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("Form_PaymentAccountDetails");


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PaymentAccountDetails">


                <div className="row">

                    <div className="col-12 col-md-4  ">
                        <TextField
                            disabled={!utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS')}
                            label={t("Name")}
                            type="text"
                            name="name"
                            required
                            value={accountDetails.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-4 ">

                        <BasicSelect
                            idProperty="type"
                            nameProperty="type"
                            label={t("Type")}
                            required
                            basicInfoName="PaymentAccountsTypes"
                            onChange={onChangeHandler}
                            name="type"
                            value={accountDetails.type}
                            translate={{ property: "type", prefix: "PAYMENT_ACCOUNTS_TYPE_" }}
                            disabled={!utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS')}
                        />
                    </div>
                    <div className="col-12 col-md-4 ">
                        <BasicSelect
                            idProperty="status"
                            nameProperty="status"
                            label={t("Status")}
                            basicInfoName="PaymentAccountsStatus"
                            onChange={onChangeHandler}
                            name="status"
                            value={accountDetails.status}
                            translate={{ property: "status", prefix: "PAYMENT_ACCOUNTS_STATUS_" }}
                            disabled={!utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS')}
                            noEmptyValue
                        />
                    </div>

                    <div className="col-12  ">
                        <Tab key="tabContentGroupsTags" active={0}>
                            <Tab.TabPane key={`TabContentView`} tab={t("Details")}>
                                <EditorJSON
                                    setError={(status) => {
                                        setJSONErrors(status);
                                    }}
                                    //name={t("Details")}
                                    value={JSON.stringify(accountDetails.details)}
                                    onChange={(json) => { onChangeHandler({ name: "details", value: json }) }}
                                />
                            </Tab.TabPane>
                            <Tab.TabPane key={`TabContentPlay`} tab={t("Logs")}>
                                <Report windowRef="windowRefLogs" reportId="user_payment_accounts_logs" viewFilter="user_payment_accounts_logs" limit={10} ></Report>
                            </Tab.TabPane>
                        </Tab>
                    </div>



                </div>
                {utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS') &&
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                        </div>
                    </div>
                }
            </form>
        </LayoutSection>
    );
}

export default withTranslation()(UserPaymentAccountDetails);

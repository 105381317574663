import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import { QrReader } from 'react-qr-reader';
import { TextField } from '@mui/material';
import DateAndTimePicker from '../../components/DateAndTimePicker';

function PayRetail(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [css, setCss] = useState("");

    const [ticket, setTicket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [voucher, setVoucher] = useState(null);
    const textFieldRef = useRef(null);

    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Retail'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const handleResult = (result, error) => {
        if (result) {
            setLoading(true)
            setTicket(result.text);
        }
        if (error) {
            //console.error(error);
        }
    };

    const handlePayment = (evt) => {
        evt.preventDefault();
        props.showAlert(
            t('Payment'),
            <p>{t('Are you sure you want to pay?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    showLoading()
                    var promise = adminClient.payVoucher(session, company, voucher.voucher);
                    promise.then(
                        function (response) {
                            setVoucher(response.voucher)
                            closeLoading()
                        },
                        function (err) {
                            closeLoading()
                            console.error(err);
                            props.showAlert(t("Pay Voucher error"), <p>{t("Error: ") + t("ERROR_" + err.description)}</p>)
                        }
                    )
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    };

    const get = (evt) => {
        showLoading()
        var promise = adminClient.getVoucher(session, company, ticket);
        promise.then(
            function (response) {
                setVoucher(response.voucher)
                closeLoading()
            },
            function (err) {
                console.error(err);
                props.showAlert(t("Get Voucher error"), <p>{t("Error: ") + t("ERROR_" + err.description)}</p>)
                closeLoading()
            }
        )
    }

    const showLoading = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Loading"), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
            const { name, value } = e.target || e;
            if (name !== undefined && value !== undefined) {
                setTicket(value);
            }
        }
    };

    useEffect(() => {
        const cssUrl = utils.getCurrentCompany().config?.backoffice?.voucher_ticket_css;
        setCss(cssUrl);
        //tener el campo de texto preseleccionado al cargar la pagina:
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (ticket) {
            get();
        }
    }, [ticket]);

    useEffect(() => {
        if (voucher) {
            console.log('voucher: ' + JSON.stringify(voucher))
            setLoading(false)
        }
    }, [voucher]);

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PayRetail">
                <div>
                    {loading && <p>Loading...</p>}
                    {voucher && (
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <TextField
                                    label={t('ID')}
                                    name="voucher"
                                    required
                                    disabled={true}
                                    type="text"
                                    value={voucher.voucher}
                                    key="idVoucher"
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <DateAndTimePicker
                                    label={t("Created date")}
                                    value={utils.formatDate(voucher.created_date)}
                                    showTime={true}
                                    name={"created_date"}
                                    id={"created_date"}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                {voucher.status != 'PENDING' ? (
                                    <DateAndTimePicker
                                        label={t("Redeemed date")}
                                        value={utils.formatDate(voucher.redeemed_date)}
                                        showTime={true}
                                        name={"redeemed_date"}
                                        id={"redeemed_date"}
                                        disabled={true}
                                    />
                                ) : (
                                    <p>{t("Voucher not paid")}</p>
                                )}

                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <div className="flex-grow-1 me-2">
                                    <TextField
                                        label={t('Status')}
                                        name="status"
                                        required
                                        disabled={true}
                                        type="text"
                                        value={voucher.status === 'PENDING' ? (voucher.status) : voucher.status === 'PAID' ? (t("This ticket is already paid.")) : (t("Unknown status."))}
                                        key="statusVoucher"
                                    />
                                </div>
                                <div>
                                    {voucher.status === 'PENDING' ? (
                                        <button className="btn btn-primary" disabled={voucher.status !== 'PENDING'} onClick={handlePayment}>{t("Pay Ticket")}</button>
                                    ) : voucher.status === 'PAID' ? (
                                        <p>{t("This ticket is already paid.")}</p>
                                    ) : (
                                        <p>{t("Unknown status.")}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField
                                    label={t('Amount')}
                                    name="amount"
                                    required
                                    disabled={true}
                                    type="text"
                                    value={utils.formatCurrency(voucher.amount / 100, voucher.currency)}
                                    key="amount"
                                />
                            </div>
                        </div>
                    )}
                    <div id="voucher-ticket" className="container calimaco_voucher_ticket_print_container_general_pay">
                        <link rel="stylesheet" href={css} />
                        <div className="calimaco_voucher_ticket_print_container">
                            <div className="row calimaco_voucher_ticket_print_container_qr">
                                <span>{t("Scan your code")}:</span>
                                <TextField label={""} name="qrReader" required type="text" value={ticket} onChange={onKeyPressHandler} inputRef={textFieldRef} onKeyPress={onKeyPressHandler} />
                                <QrReader
                                    onResult={handleResult}
                                    style={{ width: '10%' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </LayoutSection>
    )
}
export default withTranslation()(PayRetail);
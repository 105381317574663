import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import ProgressBar from '../../components/basic/ProgressBar';
import BasicSelect from '../../components/basic/BasicSelect';
function PayoutsBatch(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PayoutsBatch')
    const [progressBar, setProgressBar] = useState({
        show: false, percentage: '0%', textProcess: t('Processing')
    });
    const [operations, setOperations] = useState({
        operationsList: "",
        to_status: "",
        message: {
            rejectReason: "",
            rejectClientMessage: ""
        }
    });
    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Close payouts'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const getResultOK = (data) => {
        return (<label>{t("Processed OK")}: {data.length} </label>)
    }

    const getResultNOK = (data) => {
        let resultNok = {}
        if (data.length > 0) {
            resultNok = data.map((element) => element.db + "." + element.operation + ":" + element.error)
            return (<label>{t("Processed NOK")}: {resultNok.join(' ')} </label>)
        } else {
            return (<label>{t("Without Error")}</label>)
        }
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let nameAux = name.split(".");
        let operationsAux = { ...operations };
        if (nameAux.length > 1) {
            operationsAux[nameAux[0]][nameAux[1]] = value;
        }
        else {
            operationsAux[name] = value;
        }
        setOperations(operationsAux);
    };

    const update = (evt) => {
        if (formValidation.validate()) {

            props.showAlert(
                t('Payouts batch'),
                <p>{t('Are you sure you want to change the status to ') + " " + t(operations.to_status) + "?"}</p>,
                [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
                <button type='button' className='btn btn-primary'
                    onClick={(evt) => {
                        updateAux(evt)
                        props.handleCloseAlert()
                    }}>
                    {t('Ok')}</button>]
            )
        }
        const updateAux = (evt) => {
            if (formValidation.validate()) {
                let operationsAux = operations.operationsList.split(/\r?\n/);
                operationsAux = operationsAux.map(line => line.trim());
                let proggresBarAux = { show: true, percentage: '0%', textProcess: t('Processing') }
                setProgressBar(proggresBarAux);

                adminClient.batchUpdatePayouts(
                    session,
                    company,
                    operationsAux,
                    operations.to_status,
                    operations.message.rejectReason,
                    operations.message.rejectClientMessage
                ).then(
                    (msg) => {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Close payouts'), t('Saved succesfull'))
                            proggresBarAux = { show: true, textOK: getResultOK(msg.data), textNOK: getResultNOK(msg.operationsErrors) };
                            setProgressBar(proggresBarAux);
                            let operationsAux = { ...operations };
                            operationsAux.message.rejectReason = "";
                            operationsAux.message.rejectClientMessage = "";
                            setOperations(operationsAux)
                        } else {
                            props.showAlert(t('Close payouts'), t('Saving error ') + msg.description)
                        }
                    },
                    (error) => {
                        props.showAlert(t('Close payouts'), t('Saving error ') + error.description)
                    }
                )
            }
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PayoutsBatch">
                <div className="row" >
                    <div className="col-12 ">
                        <TextField multiline required
                            placeholder="db.player1&#10;db.player2&#10;db.player3"
                            label={t("Operations")} value={operations.operationsList} name="operationsList" onChange={onChangeHandler}></TextField>
                    </div>

                    <div className="col-12 col-md-6">
                        <BasicSelect
                            label={t("Change to status")}
                            idProperty="status"
                            name="to_status"
                            basicInfoName="PayoutStatus"
                            onChange={onChangeHandler}
                            value={operations.to_status}
                            translate={{ property: "name", prefix: "" }}
                            required
                        />
                    </div>
                    {operations.to_status === 'DENIED' &&
                        <div className="row" >
                            <div className="col-12 col-md-6">
                                <TextField multiline label={t("Reason")} required={companyDetails.config?.backoffice?.payouts?.deniedMessage}

                                    value={operations.message.rejectReason} name="message.rejectReason" onChange={onChangeHandler}></TextField>
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField multiline label={t("Message to client")} required={companyDetails.config?.backoffice?.payouts?.deniedMessage}
                                    value={operations.message.rejectClientMessage} name="message.rejectClientMessage" onChange={onChangeHandler}></TextField>
                            </div>
                        </div>
                    }
                    <div className="col-12">
                        {progressBar.show &&
                            <ProgressBar percentage={progressBar.percentage} text={progressBar.textProcess} textOK={progressBar.textOK} textNOK={progressBar.textNOK} />
                        }
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={update}
                    >
                        {t('Update')}
                    </button>

                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(PayoutsBatch);
// import React, { Component } from "react";
// import { Stack, TextField } from "@mui/material";
// import { es, enUS } from "date-fns/locale";
// import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { withTranslation } from "react-i18next";
// import utils from "../utils";

// export class DateAndTimePicker extends Component {
//   constructor(props) {
//     super(props);


//     this.viewsDateTime = ["year", "month", "day"];
//     this.inputFormat = "P";
//     let selectedValue = props.value;

//     this.adapterLocaleConf =
//       utils.getSessionItem("calimaco-lang") === "es-ES" ? es : enUS;
//     if (this.props.showTime === true) {
//       if (this.adapterLocaleConf === enUS)
//         this.inputFormat = this.inputFormat + " hh:mm:ss a";
//       else this.inputFormat = this.inputFormat + " HH:mm:ss";

//       this.viewsDateTime.push("hours");
//       this.viewsDateTime.push("minutes");
//       this.viewsDateTime.push("seconds");
//     } else {
//       if (this.props.value !== undefined && this.props.value !== null) {
//         selectedValue = this.props.value + " 00:00:00"
//       }

//     }
//     this.state = {
//       selectedDate: selectedValue,
//       disabled: this.props.disabled
//     };
//   }
//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.value !== this.props.value) {
//       if (this.props.showTime !== true && this.props.value !== null) this.setState({ selectedDate: this.props.value + " 00:00:00" });
//       else this.setState({ selectedDate: this.props.value });
//     }
//     if (prevProps.disabled !== this.props.disabled) {
//       this.setState({ disabled: this.props.disabled });
//     }
//   }


//   render() {
//     return (
//       <Stack>
//         <LocalizationProvider
//           key={"loc_" + this.props.fieldName}
//           adapterLocale={this.adapterLocaleConf}
//           dateAdapter={AdapterDateFns}
//         >
//           <DateTimePicker
//             disabled={this.props.disabled}
//             label={this.props.label}
//             inputFormat={this.inputFormat}
//             renderInput={((params) => {
//               if (this.props.error) {
//                 params = { ...params, error: true, helperText: this.props.helperText }
//               }
//               if (this.props.validations !== undefined) {
//                 let inputP = { ...params.inputProps, ...this.props.validations }
//                 params.inputProps = inputP
//               }
//               return <TextField {...params} required={this.props.required} />
//             })}
//             value={this.state.selectedDate}
//             views={this.viewsDateTime}
//             onChange={(newValue) => {
//               this.setState({ selectedDate: newValue });
//               if (this.props.callBackFunction !== undefined) {
//                 this.props.callBackFunction(newValue, this.props.fieldName);
//               }
//             }}
//           />
//         </LocalizationProvider>
//       </Stack>
//     );
//   }
// }

// export default withTranslation()(DateAndTimePicker);
import DateAndTimePicker from "./basic/DateAndTimePicker";
export { DateAndTimePicker }
export default DateAndTimePicker
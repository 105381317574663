import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import Report from '../../components/basic/Report';
import Tab from '../../components/basic/BasicTabs';
import useFormValidation from '../../hooks/parsleyValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateColumnsDef } from '../../libs/utilsReports';
import BasicTable from '../../components/basic/BasicTable';
import jsPDF from 'jspdf';
import OperationTicket from '../../components/OperationTicket';
import ReactDOMServer from 'react-dom/server';


function UserAccounts(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { formValidation: formPassValidation } = useFormValidation("FormPassword");
    const { formValidation: formDepositValidation } = useFormValidation("FormDepositValidation");
    const { formValidation: formPayoutValidation } = useFormValidation("FormPayoutValidation");

    const [tabsContent, setTabsContent] = useState([]);

    const { t } = props;
    const [userAccount, setUserAccount] = useState({
        player: { created_date: null },
        content: <div></div>,
        risk_weighting: 0,
        password: "",
        password2: "",
        depositRequest: {
            amount: 0,
            status: 'NEW',
            method: 'AGENTS',
            comments: ""
        },
        idempotence: ""
    });
    const [idempotenceDefault, setIdempotenceDefault] = useState(utils.generateUID())
    const user = utils.getSessionItem('current-user');

    const goBack = () => {
        props.history.goBack();
    };
    const [showPassword, setShowPassword] = useState({ password: false, password2: false })

    const dataHeader = {
        backLink: goBack,
        title: userAccount.player.alias + ": " + userAccount.player.first_name + " " + userAccount.player.last_name + " (" + userAccount.player.user + ", " + userAccount.player.national_id + ", " + userAccount.player.currency + ")",
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        adminClient.userDetails(session, user).then(
            function (result) {
                utils.setSessionItem("userDetails", JSON.stringify(result.user));
                let risk_weighting = 0;
                result.groups.forEach(element => {
                    risk_weighting += element.risk_weighting;
                });
                let riskLevel = "risk_low";
                if (risk_weighting > 20 && risk_weighting <= 50) {
                    riskLevel = "risk_medium";
                }
                if (risk_weighting > 50) {
                    riskLevel = "risk_high";
                }

                let userAccountOld = { ...userAccount };
                userAccountOld.riskLevel = riskLevel;
                userAccountOld.player = result.user;
                userAccountOld.disabled = true;
                userAccountOld.detailed = false;
                userAccountOld.risk_weighting = risk_weighting;
                userAccountOld.detailsBtnLavel = t("More details");

                setUserAccount(userAccountOld);
            },
            function (err) {
                console.error(err);
            }
        );

    }, []);

    const userAccountsRender = () => {
        return (<>
            <Report windowRef="windowRefUserAccounts" title=" " reportId="user_accounts_agents" location={props.location}></Report>
        </>)
    }
    const GGRRender = () => {
        return (<>
            <Report windowRef="windowRefSummary" reportId="agents_user_summary" location={props.location}></Report>
        </>)
    }

    const [errorDeposit, setErrorDeposit] = useState(false);
    const [isFieldRequired, setIsFieldRequired] = useState(false); // Estado para controlar si el campo es requerido
    const depositRender = () => {
        return (<><form id="FormDepositValidation">

            <div className="row">

                <div className="col-12 col-md-3 col-xl-3">

                    <TextField id="loyalty_weighting" label={t("Amount")} type="number" name="depositRequest.amount"
                        value={userAccount.depositRequest.amount} required onChange={onChangeHandler}
                        error={errorDeposit}
                        className={errorDeposit ? 'warning-textfield' : ''}
                    />
                </div>
                <div className="col-12 col-md-3 col-xl-3">
                    <TextField disabled value={userAccount.player.currency}></TextField>
                </div>
                {companyDetails.config?.backoffice?.agents?.deposit?.idempotence &&
                    <>
                        <div className="col-12 col-md-3 col-xl-3">
                            <TextField label={t("AGENTS_DEPOSIT_IDEMPOTENCE")} required={!isFieldRequired} value={userAccount.idempotence} name="idempotence" onChange={onChangeHandler}></TextField>
                        </div>
                        <div className="col-12 col-md-3 col-xl-3">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isFieldRequired}
                                        onChange={() => setIsFieldRequired(!isFieldRequired)}
                                    />
                                }
                                label={t("Cash")}
                            />
                        </div>

                    </>
                }
                <div className="col-12 col-md-12">
                    <TextField multiline id="comment" label={t("Comment")} type="text" name="depositRequest.comments"
                        value={userAccount.depositRequest.comments} onChange={onChangeHandler} />
                </div>
            </div >

        </form>
            <button className="btn btn-primary" id="btn_deposit" onClick={deposit}>{t("BTN_deposit")}</button>
        </>
        )
    };
    const payoutRender = () => {
        return (<>
            <Report windowRef="windowRefProcessPayoutsAgents" handleClickRowComplete={payoutPopup} rowId="operation"
                reportId="process_payouts_agents" location={props.location}></Report>
        </>)
    }

    const [operationsTable, setOperationsTable] = useState({ columns: [], data: [] })

    const generateDepositPDF = (info) => {
        let url = new URL(companyDetails.config.cdn_url).hostname;
        const pdfContent =
            "COMPROBANTE DE DEPÓSITO\n" +
            "==========================\n\n" +
            "Fecha: " + utils.formatDate(info.operation_date) + "\n" +
            "ID: " + info.operation + "\n" +
            "Depositado a: " + JSON.parse(utils.getSessionItem("userDetails")).alias + " (ID: " + utils.getSessionItem("current-user") + ")\n" +
            "La cantidad de: " + info.amount / 100 + " " + info.currency + "\n" +
            "Para depositar en su cuenta de: \n" + url + "\n" +
            "Agente: " + JSON.parse(utils.getSessionItem("user")).alias + " (ID: " + JSON.parse(utils.getSessionItem("user")).user + ")\n\n" +
            "Conforme: \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n";

        return pdfContent;
    };

    const generatePayoutPDF = (info) => {
        let url = new URL(companyDetails.config.cdn_url).hostname;
        const pdfContent =
            "COMPROBANTE DE RETIRADA\n" +
            "=========================\n\n" +
            "Fecha: " + utils.formatDate(info.operation_date) + "\n" +
            "ID: " + info.operation + "\n" +
            "Entregado a: " + JSON.parse(utils.getSessionItem("userDetails")).alias + " (ID: " + utils.getSessionItem("current-user") + ")\n" +
            "La cantidad de: " + info.amount / 100 + " " + info.currency + "\n" +
            "Desde su cuenta de: " + url + "\n" +
            "Agente: " + JSON.parse(utils.getSessionItem("user")).alias + " (ID: " + JSON.parse(utils.getSessionItem("user")).user + ")\n\n" +
            "Conforme:\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n";

        return pdfContent;
    };

    const printOperation = (info, type) => {
        let content;
        if (type === "OPERATION_TYPE_DEPOSIT") {
            info.type = "OPERATION_TYPE_DEPOSIT";
            utils.setSessionItem("operationTicket_data", JSON.stringify(info))
        } else if (type === "OPERATION_TYPE_PAYOUT") {
            info.type = "OPERATION_TYPE_PAYOUT";
            utils.setSessionItem("operationTicket_data", JSON.stringify(info))
        }
        window.open("/cashiers/operationPrint", "_blank");
    }

    const downloadOperation = (info, type) => {
        let content;
        if (type === "OPERATION_TYPE_DEPOSIT") {
            content = generateDepositPDF(info);
        } else if (type === "OPERATION_TYPE_PAYOUT") {
            content = generatePayoutPDF(info);
        }

        let options = {
            orientation: "p",
            unit: "mm",
            format: [40, 80],
            compressPdf: true
        }
        const pdf = new jsPDF(options);
        pdf.setFontSize(6)
        pdf.text(content, 2, 10, { maxWidth: (40 - 2) }); // Ajusta la posición según sea necesario
        pdf.save('comprobante_' + t(type) + "_" + info.operation + ".pdf");
        // const pdf = new jsPDF();
        // pdf.text(content, 10, 10); // Ajusta la posición según sea necesario
        // pdf.save('comprobante_' + t(type) + "_" + info.operation + ".pdf");

    }

    const sendEmailOperation = (info, type) => {
        let body
        let subject
        if (type === "OPERATION_TYPE_DEPOSIT") {
            body = <OperationTicket data={info} />

            subject = "RECIBO DE DEPOSITO"

        }
        else if (type === "OPERATION_TYPE_PAYOUT") {
            body = <OperationTicket data={info} />
            subject = "RECIBO DE RETIRADA"
        }
        const bodyHTML = ReactDOMServer.renderToString(body);

        adminClient.sendEmailToUser(session, company, user, subject, bodyHTML).then(
            (msg) => {
                props.showAlert(t('Send email'), t("Email sent successfully"))

            },
            (error) => {
                props.showAlert(t('Send email'), t('Error ') + error.description);
            }
        )
    }

    const getDataOperations = () => {
        adminClient.getReport(session, company, "agents_user_last_operations", [], user.split(".")[1], user.split(".")[0], "limit 0 ,1000 ", "created_date desc").then(
            function (msg) {

                let columns = []
                columns = generateColumnsDef(msg.data.def, t);
                columns.push(
                    {
                        Header: t("Actions"),
                        accessor: "button",
                        typeCell: "text",
                        Cell: (props) => {
                            return <div style={{ width: "150px" }}>
                                <IconButton color="default" key={`Column_button_sendDownload`}
                                    className="float-end" aria-label={"download"} onClick={() => downloadOperation(props.row.original, props.row.original.type)}>
                                    <FontAwesomeIcon className="float-end" icon="download" />
                                </IconButton>
                                {companyDetails.config?.backoffice?.agents?.printTicket === "true" &&
                                    <IconButton color="default" key={`Column_button_printDownload`}
                                        className="float-end" aria-label={"sendEmail"} onClick={() => printOperation(props.row.original, props.row.original.type)}>
                                        <FontAwesomeIcon className="float-end" icon="print" />
                                    </IconButton>
                                }
                                <IconButton color="default" key={`Column_button_sendEmail`}
                                    className="float-end" aria-label={"sendEmail"} onClick={() => sendEmailOperation(props.row.original, props.row.original.type)}>
                                    <FontAwesomeIcon className="float-end" icon="envelope" />
                                </IconButton>

                            </div>

                        },
                        Filter: true
                    }
                )
                setOperationsTable({ columns: columns, data: msg.data.data })
            }
            , function (err) {
                console.log(err)
            });

    }

    useEffect(() => {
        getDataOperations()
    }, [])


    const operationsRender = () => {
        return (
            <>
                {operationsTable.columns !== undefined &&
                    <BasicTable data={operationsTable.data} columns={operationsTable.columns} showFilterColumn={true} />
                }
            </>)
    }
    const betsRender = () => {
        return (<>
            <Report windowRef="windowReflastBets" reportId="agents_user_last_bets" location={props.location}></Report>
        </>)
    }
    const casinoRender = () => {
        return (<>
            <Report windowRef="windowReflastCasino" reportId="agents_user_last_casino" location={props.location}></Report>
        </>)
    }
    const passwordRender = () => {
        const minLength = utils.getCurrentCompany().config.backoffice?.minPassUser || 11

        return (<>

            <form id="FormPassword">
                <div className="row">
                    <div className="col-12  col-md-6">
                        <TextField
                            label={t("New password")}
                            type={showPassword.password ? 'text' : 'password'}
                            name="password"
                            onChange={onChangeHandler}
                            required
                            inputProps={{
                                "data-parsley-check-text-fields-equal-message":
                                    t("New password different than re password"),
                                "data-parsley-validate-password": JSON.stringify([minLength, ""]),
                                id: "password"
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password"
                                        >
                                            {showPassword.password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField
                            label={t("Re password")}
                            required
                            type={showPassword.password2 ? 'text' : 'password'}
                            inputProps={{
                                "data-parsley-check-text-fields-equal":
                                    "[isEqual,password]",
                                "data-parsley-check-text-fields-equal-message":
                                    t("New password different than re password")
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password2"
                                        >
                                            {showPassword.password2 ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                            name="password2"
                            onChange={onChangeHandler}

                        />
                    </div>
                </div>
            </form>
            <button className="btn btn-primary" onClick={updatePassword}>{t("Update")}</button>

        </>)
    }

    const updateTables = () => {
        let tabAux = [
            ...(utils.isGranted('AGENTS_VIEW_USER_ACCOUNTS') ? [{ title: t("User Accounts"), content: userAccountsRender }] : []),
            ...(utils.isGranted('AGENTS_VIEW_USER_GGR') ? [{ title: t("GGR"), content: GGRRender }] : []),
            ...(utils.isGranted('AGENTS_USER_DEPOSIT') ? [{ title: t("Get Deposit"), content: depositRender }] : []),
            ...(utils.isGranted('PROCESS_PAYOUT_AGENTS') ? [{ title: t("Pay Payout"), content: payoutRender }] : []),
            ...(utils.isGranted('AGENTS_USER_OPERATIONS') ? [{ title: t("Operations"), content: operationsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Bets"), content: betsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Casino"), content: casinoRender }] : []),
            ...(utils.isGranted('AGENTS_UPDATE_PASSWORD') ? [{ title: t("Password update"), content: passwordRender }] : [])
        ]
        setTabsContent(tabAux)
    }

    useEffect(() => {
        let tabAux = [
            ...(utils.isGranted('AGENTS_VIEW_USER_ACCOUNTS') ? [{ title: t("User Accounts"), content: userAccountsRender }] : []),
            ...(utils.isGranted('AGENTS_VIEW_USER_GGR') ? [{ title: t("GGR"), content: GGRRender }] : []),
            ...(utils.isGranted('AGENTS_USER_DEPOSIT') ? [{ title: t("Get Deposit"), content: depositRender }] : []),
            ...(utils.isGranted('PROCESS_PAYOUT_AGENTS') ? [{ title: t("Pay Payout"), content: payoutRender }] : []),
            ...(utils.isGranted('AGENTS_USER_OPERATIONS') ? [{ title: t("Operations"), content: operationsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Bets"), content: betsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Casino"), content: casinoRender }] : []),
            ...(utils.isGranted('AGENTS_UPDATE_PASSWORD') ? [{ title: t("Password update"), content: passwordRender }] : [])
        ]
        setTabsContent(tabAux)
    }, [userAccount, showPassword, errorDeposit, isFieldRequired, operationsTable])

    const refreshReports = () => {
        window["windowRefAgentAccounts"].executeReport(0);
        window['windowRefUserAccounts'].executeReport(0);
        window['windowRefProcessPayoutsAgents'].executeReport(0);
        window['windowReflastBets'].executeReport(0);
        window['windowReflastCasino'].executeReport(0);
        window['windowRefSummary'].executeReport(0);
    }
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name.split(".").length > 1) {
            let name1 = name.split(".")[0];
            let name2 = name.split(".")[1];
            setUserAccount(prevState => ({
                ...prevState,
                [name1]: {
                    ...prevState[name1],
                    [name2]: value,
                }
            }));
        }
        else {
            setUserAccount((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const payoutPopup = (row) => {
        let operationId = row['operation']
        let amount = row['amount'] / 100
        let otp = companyDetails.config.agents !== undefined && companyDetails.config.agents.otp !== undefined && companyDetails.config.agents.otp;
        let idempotence = "";
        props.showAlert(
            t('Payout process'),
            <div style={{ textAlign: "center", width: "400px" }}>
                <p>{t('Payout process confirmation')}</p>
                <p>{t("ID")} <b>{operationId}</b> {t("Amount")} <b>{amount} </b>  {t("Currency")} <b>{userAccount.player.currency} </b>  </p>
                {otp &&
                    <TextField label={t("Payout Key")}
                        onChange={evt => {
                            setUserAccount((prevState) => ({ ...prevState, otp_key: evt.target.value }))
                        }}>
                    </TextField>
                }
                {(companyDetails.config?.backoffice?.agents?.payout?.idempotence === "true") &&
                    <>
                        <div className="col-12">
                            <TextField label={t("AGENTS_PAYOUT_IDEMPOTENCE")}
                                onChange={evt => {
                                    idempotence = evt.target.value
                                }}>

                            </TextField>
                        </div>
                    </>

                }
                <TextField multiline label={t("Comments")}
                    onChange={evt => {
                        setUserAccount((prevState) => ({ ...prevState, payoutComments: evt.target.value }))
                    }}>
                </TextField>
            </div>
            ,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={() => {
                    payout(operationId, idempotence)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }
    const payout = (operationId, idempotence) => {
        if (companyDetails.config?.backoffice?.agents?.payout?.idempotence === "true" && idempotence !== "") {
            let idemp = idempotence.trim();
            adminClient.validateIdempotenceManualPayout(
                session,
                company,
                idemp
            ).then(
                (msg) => {
                    let result = msg.data;
                    if (result?.length > 0) {
                        props.showAlert(t('Nº transacction in use'), showResults(result[0]), undefined, "s3")
                    } else {
                        processPayout(operationId, idemp)
                    }
                },
                (error) => {
                    props.showAlert(t('User accounts'), t('Check transaction number error ') + error.description);
                }
            )
        }
        else {
            processPayout(operationId)
        }

    }
    const processPayout = (operationId, idemp) => {
        let idempotence = companyDetails.config?.backoffice?.agents?.payout?.idempotence ? idemp : undefined;
        adminClient.manualProcessPayout(
            session,
            company,
            userAccount.player.user,
            operationId.split('.')[0],
            operationId.split('.')[1],
            userAccount.payoutComments,
            idempotence,
            userAccount.otp_key).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('User accounts'), t('Saved succesfull'));
                        refreshReports();
                        updateTables();

                    } else {
                        props.showAlert(t('User accounts'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('User accounts'), t('Saving error ') + error.description);
                    refreshReports();
                    updateTables();
                }
            )

    }

    const doDeposit = (defaultIdempotence) => {
        document.getElementById("btn_deposit").classList.add("hide");
        adminClient.manualDeposit(
            session,
            company,
            userAccount.player.db,
            userAccount.player.user,
            userAccount.depositRequest.method,
            userAccount.depositRequest.amount * 100,
            userAccount.depositRequest.status,
            userAccount.depositRequest.comments,
            defaultIdempotence ? idempotenceDefault : userAccount.idempotence.trim()

        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('User accounts'), t('Deposit OK'));
                    document.getElementById("btn_deposit").classList.remove("hide");
                    refreshReports();
                    if (defaultIdempotence) setIdempotenceDefault(utils.generateUID())
                    getDataOperations()
                } else {
                    console.log("Error en msg de deposito", msg)
                    props.showAlert(t('User accounts'), t('Deposit error ') + msg.description);
                    document.getElementById("btn_deposit").classList.remove("hide");

                }
            },
            (error) => {
                console.log("Error en deposito", error)
                if (error.code !== -34) props.showAlert(t('User accounts'), t('Deposit error ') + error.description);
                document.getElementById("btn_deposit").classList.remove("hide");

                refreshReports();
            }
        )
    }

    const showResults = (result) => {
        const partes = result.idempotence.split("_");
        const idempotence = partes[partes.length - 1];
        const extra = JSON.parse(result.extra);
        return <>
            <div className='col-12'>
                <h3 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }} > {t("Nº transacction")}: </h3>
                <h4 style={{ display: 'inline-block', width: '50%' }}>{idempotence}</h4>
            </div>
            <hr></hr>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Alias")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.alias}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Amount")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.amount / 100}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Date")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{utils.formatDate(result.updated_date)}</span>
            </div>
            {extra !== undefined && extra !== null &&
                <div className='col-12'>
                    <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Cajero")}:</h5>
                    <span style={{ display: 'inline-block', width: '50%' }}>{extra.operator}</span>
                </div>
            }
        </>
    }
    const deposit = (evt) => {
        if (userAccount.depositRequest.amount === 0) {
            setErrorDeposit(true)
        }
        else {
            setErrorDeposit(false)
            if (formDepositValidation.validate()) {
                if (companyDetails.config?.backoffice?.agents?.deposit?.idempotence && userAccount.idempotence !== "") {
                    adminClient.validateIdempotenceManualDeposit(
                        session,
                        company,
                        "manual_deposit_" + user + "_" + userAccount.idempotence.trim()

                    ).then(
                        (msg) => {
                            let result = msg.data;
                            if (result?.length > 0) {
                                props.showAlert(t('Nº transacction in use'), showResults(result[0]), undefined, "s3")
                            } else {
                                doDeposit(false)
                            }
                        },
                        (error) => {
                            props.showAlert(t('User accounts'), t('Check transaction number error ') + error.description);
                        }
                    )



                }
                else {
                    doDeposit(true)
                }
            }
        }
    }
    const updatePassword = () => {
        if (formPassValidation.validate()) {
            adminClient
                .updatePasswordUser(
                    session,
                    company,
                    userAccount.player.db,
                    userAccount.player.user,
                    userAccount.password
                )
                .then(
                    function (data) {
                        if (data.result === "OK") {
                            props.showAlert(
                                t("User accounts"),
                                t("Password updated succesfully")
                            );
                        }
                        else props.showAlert(t('User accounts'), t('Password updated error') + data.description)
                    },
                    function (err) {
                        props.showAlert(t('User accounts'), t('Password updated error') + err.description)
                    }
                );
        }
    };

    const handleClickShowPassword = (evt) => {
        const { name } = evt.currentTarget
        setShowPassword((prevState) => ({ ...prevState, [name]: !prevState[name] }))
    }

    const getTabsRender = () => {
        return <Tab active={0}>
            {
                tabsContent.map((tab, idx) =>
                    <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                        {tab.content()}
                    </Tab.TabPane>
                )
            }
        </Tab>
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="row">
                <div className="col-12">
                    <Report windowRef="windowRefAgentAccounts" className="no-min-height" reportId="agent_accounts" session={session}  ></Report>
                </div>
            </div>
            <div className="row">

                <div className="col-12">
                    {getTabsRender()}


                </div>
            </div>
        </LayoutSection >
    )
}
export default withTranslation()(UserAccounts);

import React, { useEffect, useState } from 'react'
import AdminClient from '../../AdminClient';
import TextField from '@mui/material/TextField';
import useFormValidation from '../../hooks/parsleyValidation';
import utils from "../../utils";
import BasicSelect from '../../components/basic/BasicSelect';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { withTranslation } from 'react-i18next';

const AlertBO = (props) => {

    const company = utils.getCurrentCompany().company;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const adminClient = new AdminClient();
    const [message, setMessage] = useState({ type: "alert", content: "" })
    const { t } = props
    const { formValidation } = useFormValidation('Form-AlertBO')


    //Configuarción Cabecera Sección
    const dataHeader = {
        backLink: "",
        title: t("Backoffice Alert"),
        urlHelp: "",
        idDoc: props.idDoc,
    }
    useEffect(() => {
        adminClient.getOneGlobalParam(session, company, "BACKOFFICE_NOTIFICATION_ALL_BO").then((data) => {
            if (data.param !== "" && data.param !== undefined && data.param !== null) {
                let result = JSON.parse(data.param)
                setMessage(result)
            }
        })
    }, [])
    const sendMessage = () => {

        if (formValidation.validate()) {
            let msgData = { ...message }
            if (message.content === "" || message.content === undefined) msgData = {}
            adminClient.saveAlertHeader(session, company, JSON.stringify(msgData)).then(() => {
                props.showAlert(t("Backoffice Alert"), t("Save succesfully"), [
                    <button type="button" className="btn btn-primary"
                        onClick={(evt) => {
                            //if (thisAux.state.newPromotion) {
                            window.location.reload()
                            //}
                            props.handleCloseAlert();
                        }}>{t("Ok")}</button>

                ])
            })
        }

    }
    const onChangeHandler = (evt) => {
        const { name, value } = evt.target || evt
        setMessage((prevMessage) => ({ ...prevMessage, [name]: value }))
    }
    return (
        <LayoutSection dataHeader={dataHeader}>
            <form id="Form-AlertBO">
                <div className="row">
                    <div className='col-7'>
                        <BasicSelect
                            idProperty="id"
                            value={message.type || 'alert'}
                            name='type'
                            label={t('Type')}
                            onChange={onChangeHandler}
                            addOpts={[{ id: "alert", name: t("Alert") }, { id: "info", name: t("Information") }]}
                            disableClearable
                        />
                    </div>
                    <div className="col-12">
                        <TextField multiline name="content" label={t('Message')} value={message.content} onChange={onChangeHandler}></TextField>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={sendMessage}>{t("Send")}</button>
                    </div>
                </div>
            </form>
        </LayoutSection>
    )
}

export default withTranslation()(AlertBO)
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import DoubleList from '../../components/basic/DoubleList';
function PromotionLobbyDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PromotionLobbyDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const lobbyID = utils.getSessionItem('current-lobby')
    const [lobbyDetails, setLobbyDetails] = useState({
        lobby: {
            lobby: "",
            name: ""
        },
        promotions: [],
        availables: [],
    });
    const goBack = () => {
        props.history.push('/reports/report/promotions_lobbies/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Lobby details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (lobbyID !== undefined && lobbyID !== '-1') {
            adminClient.getPromotionLobbyDetails(session, company, lobbyID).then(
                async function (result) {
                    let lobbyAux = {
                        lobby: result.data.lobby,
                        promotions: result.data.promotions,
                        availables: result.data.availables
                    };
                    setLobbyDetails(lobbyAux);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            adminClient.getPromotions(session, company).then(
                function (result) {
                    setAddOrUpdate('add');
                    let lobbyAux = {
                        lobby: "",
                        promotions: [],
                        availables: result.data
                    };
                    setLobbyDetails(lobbyAux);
                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);

    const onChangeHandlerLobby = (e) => {
        const { name, value } = e.target || e;
        setLobbyDetails(prevState => ({
            ...prevState,
            lobby: {
                ...prevState.lobby,
                [name]: value,
            }
        }));
    };

    const onChangeHandlerLobbyPromotions = (promotions) => {
        setLobbyDetails((prevState) => ({ ...prevState, promotions: promotions }));
    }

    const save = (evt) => {
        let promotionsIds = [];
        lobbyDetails.promotions.forEach((element) => {
            promotionsIds.push(element.promotion);
        });
        if (formValidation.validate()) {
            adminClient.savePromotionLobbyDetails(
                session,
                company,
                JSON.stringify(lobbyDetails.lobby),
                JSON.stringify(promotionsIds)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Lobby details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Lobby details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Lobby details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PromotionLobbyDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="lobby"
                            required
                            disabled={addOrUpdate === 'update'}
                            InputProps={addOrUpdate === 'add' ? {
                                inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                            } : {}}
                            type="text"
                            value={lobbyDetails.lobby.lobby}
                            onChange={onChangeHandlerLobby}
                        />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField
                            label={t("Name")}
                            name="name"
                            value={lobbyDetails.lobby.name}
                            onChange={onChangeHandlerLobby}
                        />
                    </div>
                    <div className="row">
                        <DoubleList
                            draggable
                            availables={lobbyDetails.availables}
                            configured={lobbyDetails.promotions}
                            idProperty={"promotion"}
                            nameProperty={"promotion"}
                            configuredListName={t("Already in lobby")}
                            handleUpdateList={onChangeHandlerLobbyPromotions}
                            pageSize={10}
                        ></DoubleList>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={save}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(PromotionLobbyDetails);
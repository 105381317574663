import { InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
//import Report from "../../components/Report";
import Report from "../../components/basic/Report";
import Tab from "../../components/basic/BasicTabs";
import TreeSelector from "../../components/basic/TreeSelector";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';

function JackpotCategoryDetails(props) {
  const company = utils.getCurrentCompany().company;

  const { formValidation } = useFormValidation("Form_categoryJackpotDetails")

  const [category, setCategory] = useState({
    category: "",
    name: "",
    company: company,
    machinesCategory: {},
    config: {
      machinesTags: [],
      machinesTypes: []
    },
    winnings: [],
    machinesAvailables: [],
  });

  const [addOrUpdate, setAddOrUpdate] = useState("add");

  const session = JSON.parse(utils.getSessionItem("user")).session;

  let adminClient = new AdminClient();

  const { t } = props;

  const categoryId = utils.getSessionItem("current-category");



  //HEADER
  const goBack = () => {
    props.history.push("/reports/report/jackpot_categories/table");
  };

  const dataHeader = {
    backLink: goBack,
    title: t("Categories jackpot"),
    urlHelp: "",
    idDoc: props.idDoc
  };
  //FIN HEADER

  const onChangeHandler = (e) => {
    const { name, value } = e.target || e;
    setCategory((prevCategory) => ({ ...prevCategory, [name]: value }));
  };

  const onChangeConfigHandler = (e) => {
    const { name, value } = e.target || e;
    setCategory((prevCategory) => ({ ...prevCategory, ['config']: { ...prevCategory.config, [name]: value } }));
  }

  //Manejadores modificacion
  const saveCategory = (evt) => {

    let categoryToSave = {}
    if (formValidation.validate()) {

      categoryToSave.category = category.category
      categoryToSave.name = category.name
      categoryToSave.config = category.config
      if (addOrUpdate === 'update') {
        categoryToSave.machines = category.machinesCategory
      }
      adminClient[addOrUpdate + "JackpotCategory"](session, company, JSON.stringify(categoryToSave)).then(
        (msg) => {
          if (msg.result === 'OK') {
            props.showAlert(t("Categories jackpot"), t("Saved succesfull"))
            if (addOrUpdate === 'add') {
              utils.getSessionItem("current-category", categoryToSave.category)
              setAddOrUpdate('update')
            }
          } else {
            props.showAlert(t("Categories jackpot"), t("Saving error") + msg.description)
          }
        },
        (error) => {
          props.showAlert(t("Categories jackpot"), t("Saving error") + error.description)
        }
      )
    }

  };
  const handleChangeDataTreeNode = (evt) => {
    const { name, value } = evt.target;

    let [, machine] = name.split('_');
    machine = parseInt(machine)
    let contributions = category.machinesCategory
    if (value === '') contributions[machine] = 0
    else {
      if (parseInt(value) >= 0)
        contributions[machine] = parseInt(value);
      else if (contributions[machine] !== undefined) delete contributions[machine]
    }
    setCategory((prevCategory) => ({ ...prevCategory, ['machinesCategory']: contributions }))
  };

  const getTplRender = useCallback(
    (element, index) => {

      let hide = ''
      if (category.machinesCategory[element.machine] === undefined) hide = 'hide'

      return (
        <>
          <span id={element.id}>{element.name}</span>
          <span className={hide}>
            <TextField
              label={t("Contribution")}
              name={"machine_" + element.machine + "_" + element.sub_provider}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              inputProps={{ type: "number", min: 0, max: 100, className: "tree-visible" }}
              value={category.machinesCategory[element.machine]}
              onChange={handleChangeDataTreeNode}
            />
          </span>
        </>)


    },
    [JSON.stringify(category.machinesCategory)]
  )
  const onTreeMachinesChecked = (data) => {
    let contributions = category.machinesCategory
    let contibutionsMachines = Object.keys(contributions).map(key => parseInt(key))

    contibutionsMachines.forEach((machineId) => {
      if (!data.includes(machineId))
        delete contributions[machineId]
    })
    data.forEach((machineId) => {
      if (!contibutionsMachines.includes(machineId))
        contributions[machineId] = 100
    })

    setCategory((prevCategory) => ({ ...prevCategory, ['machinesCategory']: contributions }))
  };

  const getMachinesContributions = (machines) => {
    let contributions = {};
    machines.forEach((machineData) => {
      let { machine, contribution } = machineData;
      contributions[machine] = contribution
    });
    return { contributions }
  };

  const getAvailablesMachines = async (tags, types) => {
    let filter = []
    if (tags.length > 0 || types.length > 0) {
      if (tags.length > 0) {
        filter.push({ "field": "mt.tag", "type": "SelectMultiple", "typeValue": "String", "value": "in " + tags.join(',') })
      }
      if (types.length > 0) {
        filter.push({ "field": "type", "type": "SelectMultiple", "typeValue": "String", "value": "in " + types.join(',') })
      }
    }
    let resultAvailables = await adminClient.getReport(session, company, 'machines_types_tags', filter, null, null, '', '')
    return resultAvailables.data.data;

  }

  const jackpotsRender = useCallback(() => {
    let filterJackpot = [{ field: "t.category", operator: "=", value: category.category }];
    utils.setSessionItem("ReportFilter-jackpots_filter", JSON.stringify(filterJackpot));
    utils.setSessionItem("ReportFilterView-jackpots", JSON.stringify([]));
    return (
      <Report
        windowRef="windowRefJackpotsCategory"
        reportId="jackpots"
        filterId="jackpots_filter"
        viewFilter="jackpots"
      ></Report>
    );
  }, [JSON.stringify(category.category)])


  const machinesRender = useCallback(() => {

    return (
      <TreeSelector
        label={t("Machine")}
        name="maquinas"
        nodes={category.machinesAvailables}
        checked={Object.keys(category.machinesCategory).map(key => parseInt(key))}
        expanded={Object.keys(category.machinesCategory)}
        typeTree="machine"
        onCheck={onTreeMachinesChecked}
        itemTpl={getTplRender}
      ></TreeSelector>)

  }, [JSON.stringify(category.machinesAvailables), JSON.stringify(category.machinesCategory)])

  const winningsRender = () => {
    return "contenido Winnings";
  };


  //TABS

  useEffect(() => {
    let tabAux = [
      { title: t("Jackpots"), content: jackpotsRender },
      { title: t("Machines"), content: machinesRender },
      //{ title: t("Winnings"), content: winningsRender },
    ];
    setTabsContent(tabAux);
  }, [jackpotsRender, machinesRender]);

  useEffect(async () => {
    let availables = await getAvailablesMachines(category.config.machinesTags, category.config.machinesTypes)

    setCategory((prevCategory) => {
      return { ...prevCategory, 'machinesAvailables': availables }
    })

  }, [JSON.stringify(category.config)])



  //Carga de datos
  useEffect(function () {

    if (categoryId !== undefined && categoryId !== "-1") {
      adminClient.getCategoryJackpotDetails(session, company, categoryId).then(
        async function (result) {
          let categoryData = { ...category };
          categoryData.name = result.category.name;
          categoryData.category = result.category.category;
          try {
            categoryData.config = JSON.parse(result.category.config)
          } catch (err) {
            console.log("configuración nula")
          }

          const { contributions, contributionsExp } = getMachinesContributions(result.machines);
          categoryData.machinesCategory = contributions;
          categoryData.machinesExpand = contributionsExp
          categoryData.machinesAvailables = await getAvailablesMachines(categoryData.config.machinesTags, categoryData.config.machinesTypes)

          setCategory(categoryData);
          setAddOrUpdate("update");
        },
        function (err) {
          console.error(err);
        }
      );
    } else {
      setAddOrUpdate("add");
    }
  }, []);

  const [tabsContent, setTabsContent] = useState([]);




  //FIN TABS

  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <form id="Form_categoryJackpotDetails">
        <div className="row">
          <div className="col-12 col-md-3  ">
            <TextField
              label={t("ID")}
              name="category"
              required
              disabled={addOrUpdate === "update"}
              inputProps={{ "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t("validationID") }}
              type="text"
              value={category.category}
              onChange={onChangeHandler}
            />
          </div>

          <div className="col-12 col-md-9 ">
            <TextField
              label={t("Name")}
              required
              name="name"
              type="text"
              value={category.name}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <BasicSelect
              idProperty="type"
              name="machinesTypes"
              label="Machine types"
              basicInfoName="MachinesTypes"
              onChange={onChangeConfigHandler}
              value={category.config.machinesTypes}
              multiple
            />
          </div>
          <div className="col-12 col-md-6 ">
            <BasicSelect
              idProperty="tag"
              label={t("Add tag")}
              basicInfoName="AllMachineTags"
              name="machinesTags"
              onChange={onChangeConfigHandler}
              value={category.config.machinesTags}
              multiple
            />
          </div>

        </div>
      </form>

      {utils.isGranted("SAVE_MARKETING_JACKPOTS") && (
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveCategory}
            >
              {t("Save")}
            </button>
            {/* <button type="button" className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button> */}
          </div>
        </div>
      )}

      {addOrUpdate === "update" &&
        <div>
          <Tab active={0}>
            {tabsContent.map((tab, idx) => (
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                {tab.content()}
              </Tab.TabPane>
            ))}
          </Tab>
        </div>}
    </LayoutSection>
  );
}

export default withTranslation()(JackpotCategoryDetails);

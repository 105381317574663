import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlowActionDetails from './FlowActionDetails';


const FlowReactActions = (props) => {

    const { t } = props
    const [reactActions, setReactActions] = useState([])
    const [enabledTransition, setEnabledTransition] = useState(true)

    const getActionDataRender = (actionData) => {
        let message = t(actionData.type + '_React_Description')
        Object.keys(actionData).forEach((key) => {
            message = message.replace("<" + key + ">", actionData[key])
        })
        return message

    }

    const onUpdateActions = (action) => {

        let indexAction = reactActions.findIndex((ra, index) => {
            return ra.action === action.action
        })
        let actions = [...reactActions]
        if (indexAction !== -1) {
            actions[indexAction] = action
        } else actions.push(action)
        setEnabledTransition(!hasTransitionAction(actions))
        setReactActions(actions)
        props.onUpdateReactActions(props.react, actions)
    }

    const hasTransitionAction = (actions) => {
        let found = actions.findIndex((a) => a.type === 'TRANSITION')
        return found !== -1
    }

    useEffect(() => {
        if (props.actions !== undefined) {
            setEnabledTransition(!hasTransitionAction(props.actions))
            setReactActions(props.actions)
        }
    }, [props.actions])


    const editAction = (actionData) => {
        props.showAlert(t('Editando Accion'), <FlowActionDetails flow={props.flow} react={props.react} enabledTransition={enabledTransition} action={actionData} handleCloseAlert={props.closeAlert} t={t} onUpdateActions={onUpdateActions} />, [], 'l')
    }

    const onDeleteAction = (actionData) => {
        let indexAction = reactActions.findIndex((ra, index) => {
            return ra.action === actionData.action
        })
        let actions = [...reactActions]

        if (indexAction !== -1) {
            actions.splice(indexAction, 1)
        }
        setReactActions(actions)
        setEnabledTransition(!hasTransitionAction(actions))
        props.onUpdateReactActions(props.react, actions)

    }

    const deteteAction = (actionData) => {

        props.showAlert(t('Delete') + " " + t('Action'), <p>{t('Delete') + " " + t('Action') + " "}<strong>{actionData.name}</strong></p>, [
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    onDeleteAction(actionData)
                    props.closeAlert()
                }}>
                {t('Ok')}</button>,
            <button type='button' className='btn btn-secondary' onClick={props.closeAlert}>{t('Cancel')}</button>])
    }
    const newAction = () => {
        props.showAlert(t('Editando Accion'), <FlowActionDetails flow={props.flow} react={props.react} enabledTransition={enabledTransition} handleCloseAlert={props.closeAlert} t={t} onUpdateActions={onUpdateActions} />, [], 'l')
    }


    return (<>
        <div className='reactAction_addItem' ><FontAwesomeIcon className="float-end" onClick={() => { newAction() }} icon="plus">{t('addReactAction')}</FontAwesomeIcon></div>
        <ul className="machines_list">
            {reactActions !== undefined && reactActions.map((actionData, index) => {
                //let actionData = reactActions[r]
                return (<>
                    <li className="reactAction_item d-table  w-100"
                        key={"configured_" + actionData.action}
                    >
                        <Typography sx={{ width: '98%', color: 'text.secondary', alignContent: 'right' }} >
                            <FontAwesomeIcon icon="edit" title={t('Edit') + " " + t('Action')} onClick={() => {
                                editAction(actionData)
                            }} /> <strong>{actionData.name}: </strong>{getActionDataRender(actionData)}
                            <FontAwesomeIcon className="float-end" onClick={() => { deteteAction(actionData) }} icon="trash" />
                        </Typography>
                    </li>
                </>)
            })}
        </ul>
    </>
    );
}

export default withTranslation()(FlowReactActions)
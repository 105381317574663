import React, { useState, useEffect, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';


const AgentsTree = (props) => {
    /**
     * Estructura minima de un nodo, user,alias,hasChildren, si no viene siempre intentará llamar al cargar nodos. 
     */

    const [flattenedData, setFlattenedData] = useState([]);
    const [isLoadingRoot, setIsLoadingRoot] = useState(false)
    const [hasRootMore, setHasRootMore] = useState(true)
    const [isLoadingChild, setIsLoadingChild] = useState({})
    const [hasChildMore, setHasChildMore] = useState({})




    useEffect(() => {
        setIsLoadingChild(false)
        setIsLoadingRoot(false)
        setHasRootMore(true)
        setHasChildMore({})
        setFlattenedData(flattenOpened(props.data));
    }, [props.data]);

    const flattenOpened = (treeData) => {
        const result = [];
        for (let node of treeData) {
            flattenNode(node, node.depth, result);
        }
        return result;
    };



    const flattenChilds = (parentNode, collapsed, children, depth, result, indexCount) => {

        if (!collapsed && children) {
            let index = indexCount
            for (let child of children) {
                child.parent = parentNode.parent
                child.parentCollapsed = parentNode.parentCollapsed + "___" + child.user
                flattenNode(child, depth + 1, result, index);
                if (index !== undefined) index++;
            }
        }
    }


    const flattenNode = (node, depth, result, index) => {
        const { user, alias, children, hasChildren } = node;
        let collapsed = node.collapsed === undefined ? true : node.collapsed

        let additionalData = {}
        if (props.getAdditionalNodeData !== undefined) additionalData = props.getAdditionalNodeData(node)

        result.push({
            ...additionalData,
            user,
            alias,
            hasChildren: hasChildren === undefined ? true : hasChildren,
            totalChildren: node.totalChildren || 0,
            numChild: index || node.numChild,
            depth,
            collapsed,
            parent: node.parent,
            parentCollapsed: node.parentCollapsed || ""
        });
        flattenChilds(node, collapsed, children, depth, result, 1)
    };

    const onOpen = async node => {

        //Si el nodo esta cerrado, comprobamos si tiene hijos 
        if (node.collapsed) {
            if (node.hasChildren && (node.children === undefined || node.children === null)) {
                let newChildNodes = await props.fetchMoreChildNodes(node.user, 0)
                if (newChildNodes.length === 0) {
                    node.hasChildren = false
                    setHasChildMore((prev) => {
                        return { ...prev, [node.user]: false }
                    })
                }
                else {

                    const updatedTreeData = flattenedData.map(fnode => {
                        if (fnode.user === node.user) {
                            return {
                                ...fnode,
                                children: [...newChildNodes],
                                totalChildren: newChildNodes.length,
                                parent: node.user,
                                collapsed: false,
                                parentCollapsed: node.parentCollapsed || `${node.user}`
                            };
                        }
                        return fnode;
                    });
                    setHasChildMore((prev) => {
                        return { ...prev, [node.user]: true }
                    })

                    let fopened = flattenOpened(updatedTreeData)
                    setFlattenedData(fopened);
                }
            } else {
                const updatedTreeData = flattenedData.map(fnode => {
                    if (fnode.user === node.user) {
                        return {
                            ...fnode,
                            collapsed: false
                        };
                    }
                    return fnode;
                });
                let fopened = flattenOpened(updatedTreeData)
                setFlattenedData(fopened);
            }
        } else {
            const updatedTreeData = flattenedData.filter(fnode => {
                if (fnode.user === node.user) {
                    fnode.collapsed = true
                    fnode.children = null
                    fnode.totalChildren = 0
                    return fnode
                }

                if (!fnode.parentCollapsed.includes(node.user)) {
                    return {
                        ...fnode
                    };
                }
            });
            let fopened = flattenOpened(updatedTreeData)
            setFlattenedData(fopened);
        }
    };

    const onSelect = (e, node) => {
        // Aquí podrías manejar acciones al seleccionar un nodo.

        e.stopPropagation(); // Evita que el clic se propague al padre       

    };

    const updateNode = async (index, node) => {
        console.log('Actualizando Amount del Nodo')

        setFlattenedData((prev) => {
            prev[index] = node
            return prev
        })
    }

    const loadMoreNodes = useCallback(async (dataMore) => {
        const { visibleStopIndex } = dataMore
        const lastNode = flattenedData[visibleStopIndex];
        //Comprobamos si es un nodo hijo o un padre
        if (lastNode.depth === 1 && !isLoadingRoot && visibleStopIndex >= flattenedData.length - 5) {

            setIsLoadingRoot(true)
            //Comprobamos si ya no hay que cargar mas nodos root
            if (hasRootMore) {
                const newRootNodes = await props.fetchMoreRootNodes();
                if (newRootNodes.length > 0) {
                    let fopened = flattenOpened(newRootNodes)
                    setFlattenedData(prev => [...prev, ...fopened]);
                } else setHasRootMore(false)
                setIsLoadingRoot(false)
            }
        } else if (lastNode.depth !== 1 && !isLoadingChild[lastNode.parent]) {

            setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: true } })
            if (hasChildMore[lastNode.parent]) {

                //Buscamos el indice de ese hijo en el padre, 
                //Buscamos el nodo padre.               
                const parentNode = flattenedData.find(objeto => objeto.user === lastNode.parent);
                console.log('Soy el hijo ' + lastNode.numChild + ' de ' + parentNode.totalChildren)

                if (lastNode.numChild >= parentNode.totalChildren) {
                    const indexOfLastNode = flattenedData.findIndex(objeto => objeto.user === lastNode.user)
                    console.log('Buscar mas nodos hijo ...' + lastNode.parent)
                    const newChildNodes = await props.fetchMoreChildNodes(lastNode.parent);
                    console.log('Tiene ' + newChildNodes.length + 'hijos')
                    console.log('Añadir los hijos al final de ' + indexOfLastNode)

                    if (newChildNodes.length > 0) {

                        let newChildNodesFlat = []
                        flattenChilds(parentNode, false, newChildNodes, parentNode.depth, newChildNodesFlat, lastNode.numChild + 1)
                        let init = flattenedData.slice(0, indexOfLastNode + 1)
                        let end = flattenedData.slice(indexOfLastNode + 1)
                        const treeDataWithChildren = [...init, ...newChildNodesFlat, ...end]

                        const updatedTreeData = treeDataWithChildren.map(node => {
                            if (node.user === lastNode.parent) {
                                return {
                                    ...node,
                                    totalChildren: parentNode.totalChildren + newChildNodes.length,
                                };
                            }
                            return node;
                        });
                        let fopened = flattenOpened(updatedTreeData)
                        setFlattenedData(fopened);
                    } else {
                        setHasChildMore((prev) => { return { ...prev, [lastNode.parent]: false } })
                    }

                }
                setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: false } })
            }
        }

    }, [flattenedData, isLoadingRoot, hasRootMore, isLoadingChild, hasChildMore]);

    const itemData = props.getItemData(onOpen, onSelect, flattenedData, updateNode, props.resources);
    // const itemData = getItemData(onOpen, onSelect, flattenedData);

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    className="List"
                    height={height}
                    itemCount={flattenedData.length}
                    itemSize={props.itemSize || 35}
                    width={width}
                    itemKey={index => flattenedData[index].user}
                    itemData={itemData}
                    onItemsRendered={loadMoreNodes}
                >
                    {props.row}
                </List>
            )}
        </AutoSizer>
    );
};

export default AgentsTree
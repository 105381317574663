import React from "react";
import AdminClient from "../AdminClient";
const utils = require('../utils')
const BasicInfoJSON = require('../context/basicInfoDefinition.json');



export function getBasicInfo(basicInfo, data, params) {
    let adminClient = new AdminClient();
    let sessionItemName = "basicInfo-" + basicInfo;
    let info = JSON.parse(utils.getSessionItem(sessionItemName));
    let saveBasicInfo = BasicInfoJSON.BasicInfo.includes(basicInfo);
    if (info !== null) {
        return Promise.resolve(info);
    } else {
        return new Promise(
            function (resolve, reject) {
                adminClient.getDataBasicInfo(
                    JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), basicInfo, params
                ).then((res) => {
                    let dataToSearch = data || "data";
                    let info = res[dataToSearch];
                    if (saveBasicInfo) {
                        loadBasicInfo(basicInfo, params, data, info)
                    }
                    resolve(info);
                }).catch((err) => {
                    reject(err);
                });

            });
    }
}

export async function loadBasicInfo(basicInfo, params, data, info) {
    unloadBasicInfo(basicInfo)
    let sessionItemName = "basicInfo-" + basicInfo;
    let infoLoad = info || await getBasicInfo(basicInfo, params, data);
    utils.setSessionItem(sessionItemName, JSON.stringify(infoLoad));
}

export function unloadBasicInfo(basicInfo) {
    let sessionItemName = "basicInfo-" + basicInfo;
    utils.removeSessionItem(sessionItemName);
}

export function getTranslateBasicInfo(basicInfo, idProperty, value, data, nameProperty, params) {
    try {
        let sessionItemName = "basicInfo-" + basicInfo;
        let info = JSON.parse(utils.getSessionItem(sessionItemName));
        if (info !== undefined && info !== null) { //Devuelvo si la tengo cargada
            const nameToSearch = nameProperty || "name";
            const objetoEncontrado = info.find(objeto => objeto[idProperty] === value);
            return objetoEncontrado[nameToSearch];
        }
        else {
            if (BasicInfoJSON.BasicInfo.includes(basicInfo)) {
                getBasicInfo(basicInfo, data, params);
            }
            return value;
        }
    } catch (err) {
        console.log(err);
    }
}


import React, { useEffect, useState } from 'react'
import utils from '../../utils';
import { searchUserById } from "../../libs/utilsServerData"
import Report from '../../components/basic/Report';
import AppChart from '../../components/AppChart';
import UserPersonalDetails from './PersonalDetails';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

const UserDetails = (props) => {

    const sectionConfig = JSON.parse(utils.getSessionItem('sections')).USER_DETAILS.config
    const { t } = props
    const [userDetails, setUserDetails] = useState({ userId: '', filter: [] })
    const [userFinded, setUserFinded] = useState(false)

    useEffect(async () => {
        let userFindedAux = await searchUserById(props.location.pathname)
        setUserFinded(userFindedAux)
        if (!userFindedAux)
            props.props.showAlert(t('Error'), t('Player does not exist'))
        let userId = utils.getSessionItem('current-user').split(".")[1];
        let filter = [{ "field": "user", "operator": "=", "value": userId }]
        setUserDetails({ userId: userId, filter: filter })
    }, [])


    return (<>
        {userFinded &&
            <>
                <UserPersonalDetails viewMiddle={sectionConfig.middle_name} showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert} />
                <div className="row">
                    {sectionConfig.elements.map((element) => {
                        switch (element.type) {
                            case 'table':
                                return <Report className={element.size !== undefined ? "col-12 col-md-" + element.size + ' float-left pt-3' : "col-12 col-md-6 col-xxl-4  float-left pt-3"} reportId={element.report} user={userDetails.userId}  ></Report>;
                            case 'chart':
                                return <AppChart className={element.size !== undefined ? "col-" + element.size : "col-12 col-md-4  float-left pt-3 "} report={element.report} filter={userDetails.filter}  ></AppChart>;
                            default:
                                return null;
                        }
                    })}
                </div>
            </>
        }
    </>
    )
}

export default withTranslation()(withRouter(UserDetails))
import { MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import AgentsMenu from '../../components/AgentsMenu';
import BasicSelect from '../../components/basic/BasicSelect';




function NewAgent(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UsersDetails')
    const companyConfBO = utils.getCurrentCompany().config.backoffice
    const hiddenFields = companyConfBO.agents?.agentHiddenFields || []
    const companyRolesMultilevel = companyConfBO?.agents?.roles || [{ "id": "Agente" }, { "id": "Vendedor" }];

    const defaultRole = companyConfBO?.agents?.defaultRole || "Agente";
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassAdmin || 11

    const [userDetails, setUserDetails] = useState(
        {
            user: {
                user: -1,
                company: company,
                alias: '',
                status: 'ENABLED',
                name: '',
                email: '',
                currency: utils.getCurrentCompany().base_currency,
                role: defaultRole
            },
            new_user_password: ''

        });

    const [rolesCreate, setRolesCreate] = useState([])

    //HEADER
    const goBack = () => {
        props.history.push('/reports/report/users/table');
    };
    const dataHeader = {
        title: t('New agent'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //DATOS User
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name === "password") formValidation.reset()
        setUserDetails((prevUserDetails) => ({ ...prevUserDetails, [name]: value }));
    };

    const onChangeHandlerUser = (e) => {
        const { name, value } = e.target || e;
        setUserDetails((prevUserDetails) => {
            let uData = prevUserDetails.user
            uData[name] = value
            return { ...prevUserDetails, ['user']: uData }
        });
    }


    // Manejadores envio al Server
    const save = (evt) => {

        if (formValidation.validate()) {
            // (session, company, alias, password, name, email, currency
            let dataUserDetails = { ...userDetails }
            if (hiddenFields.includes('name')) dataUserDetails.name = userDetails.user.alias
            if (hiddenFields.includes('email')) dataUserDetails.name = userDetails.user.alias + '@' + userDetails.user.alias + ".com"


            adminClient.saveNewAgent(
                JSON.parse(utils.getSessionItem("user")).session,
                utils.getSessionItem("Company"),
                dataUserDetails.user.alias,
                dataUserDetails.password,
                dataUserDetails.user.name,
                dataUserDetails.user.email,
                dataUserDetails.user.currency,
                'ENABLED',
                userDetails.user.role
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        // Redirigimos a la seccion de detalles
                        props.showAlert(t('User details'), t('Saved succesfull'));
                        // /agents/subagents/details
                        props.history.push('/agents/subagents/details/' + msg.agent)
                    } else {
                        props.showAlert(t('User details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('User details'), t('Saving error ') + error.description)
                }
            )
        }
    }


    useEffect(() => {

        adminClient.getAdminUserRoles(session, company).then(
            (result) => {
                //Buscamos en los roles si existe uno de los rolesDefinidos
                let companyRolesMultilevelIds = companyRolesMultilevel.map(element => element.id)
                const rolesCreateConf = companyConfBO?.agents?.createRoles || {}
                const resultado = result.data.find(objeto => companyRolesMultilevelIds.includes(objeto.role));
                if (rolesCreateConf[resultado?.role] !== undefined) {

                    setRolesCreate(rolesCreateConf[resultado?.role])

                } else setRolesCreate(companyRolesMultilevel)
            },
            (err) => {

            })

    }, [])

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <form id="Form_UsersDetails" autocomplete="off">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextField
                                label={t("Alias")}
                                name="alias"
                                required
                                type="text"
                                InputProps={{ inputProps: { "data-parsley-required": "true", "minlength": "3" } }}
                                value={userDetails.user.alias}
                                onChange={onChangeHandlerUser}
                            />
                        </div>
                        <div className="col-12  col-md-4  ">
                            <TextField
                                id="new_user_password"
                                key="new_user_password"
                                label={t("Password")} required={true} type="password"
                                inputProps={
                                    {
                                        "data-parsley-pattern": "^[a-zA-Z0-9]+$",
                                        "data-parsley-pattern-message": t('Password letters and numbers'),
                                        "minLength": minLength,
                                        "autocomplete": "new-password"
                                    }
                                }
                                onChange={(evt) => {
                                    evt.target.name = "password"
                                    onChangeHandler(evt)
                                }} value={userDetails.password} />
                        </div>
                        <div className="col-12  col-md-4  ">
                            <BasicSelect
                                value={userDetails.user.role}
                                name="role"
                                label={t('Role')}
                                nameProperty="id"
                                addOpts={rolesCreate}
                                onChange={(evt) => {
                                    onChangeHandlerUser(evt)
                                }}
                                disableClearable
                            />
                        </div>
                    </div>
                    <div className="row">
                        {!hiddenFields.includes('name') &&
                            <div className="col-12  col-md-4  ">
                                <TextField
                                    label={t("Name")}
                                    name="name"
                                    type="text"
                                    onChange={onChangeHandlerUser}
                                    required
                                    value={userDetails.user.name}
                                />
                            </div>}
                        {!hiddenFields.includes('email') &&
                            <div className="col-12  col-md-4  ">
                                <TextField
                                    label={t("Email")}
                                    type="text"
                                    name="email"
                                    onChange={onChangeHandlerUser}
                                    required
                                    value={userDetails.user.email}
                                />
                            </div>}
                        {!hiddenFields.includes('currency') &&
                            <div className="col-12  col-md-4 ">
                                <BasicSelect
                                    idProperty='currency'
                                    nameProperty='currency'
                                    data='currencies'
                                    basicInfoName='CurrenciesCompany'
                                    value={userDetails.user.currency}
                                    name='currency'
                                    label={t('Currency')}
                                    onChange={onChangeHandlerUser}
                                    disableClearable
                                />
                            </div>}

                    </div>
                </form>

                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>

                    </div>
                </div>

            </LayoutSection>
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(NewAgent);
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from "../../utils"
import { generateColumnsDef } from '../../libs/utilsReports'
import BasicTable from '../../components/basic/BasicTable'
import AdminClient from '../../AdminClient'

const PendingBankDocumentation = (props) => {

    const { t } = props
    const [dataTable, setDataTable] = useState({})
    const dataHeader = {
        backLink: "",
        title: t("Pending bank documentation"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    const goToDetails = (user, user_payment_account) => {
        utils.setSessionItem('current_user_bank_files', JSON.stringify({ user: user, userPaymentAccount: user_payment_account }))
        props.history.push("/support/pending_bank_details")
    }

    useEffect(() => {

        let adminClient = new AdminClient();
        let reportPromise = adminClient.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), "users_pending_documentation_bank", [], null, null, "limit 0 ,1000 ", "created_date desc");
        reportPromise.then(
            function (msg) {

                let columns = []
                columns = generateColumnsDef(msg.data.def, t)

                columns[3]['Cell'] = (cell) => {
                    return <div onClick={(evt) => goToDetails(cell.row.original.user, cell.row.original.payment_account)} className="user_files_total">{cell.value}</div>
                }

                columns[2]['prefixTranslate'] = 'PAYMENT_ACCOUNTS_TYPE_'

                setDataTable({ columns: columns, data: msg.data.data })
            }
            , function (err) {

            });
    }, [])

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>

            {dataTable.columns !== undefined &&
                <BasicTable data={dataTable.data} columns={dataTable.columns} showFilterColumn={true} />}

        </LayoutSection>
    )
}

export default withTranslation()(PendingBankDocumentation)
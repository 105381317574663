// Dependencies
import React, { useContext, useEffect, useState } from 'react'
import { Route, withRouter, useHistory } from 'react-router-dom'
import AdminClient from '../AdminClient';
import { AuthContext } from '../context/AuthContext';
import utils from '../utils';
import Alert from './Alert';
import { withTranslation } from 'react-i18next';


const AppRoute = ({ component: Component, layout: Layout, t, idDoc }, { ...rest }) => {

  const { sessionInfo, setSessionDataInfo } = useContext(AuthContext);
  const [showRedirectModal, setShowRedirectModal] = useState({ showModal: false, errorMessage: '' });
  const [isFirstTime, setIsFirstTime] = useState(false)
  const [messageAlert, setMessageAlert] = useState({})

  const history = useHistory();



  const handleAlertRedirect = () => {
    history.push("/");
    setSessionDataInfo(null)
    setShowRedirectModal(false)
  }

  useEffect(() => {

    async function validateSession(session) {
      console.log("comprobar si es valida de la session")
      let clientAPI = new AdminClient()
      try {
        let data = await clientAPI.isValidSession(session)
        console.log("resultado de la comprobación " + JSON.stringify(data))
        if (data.code !== undefined) {
          console.log("Session not valid")
          setSessionDataInfo(null)
          setShowRedirectModal({ showModal: true, errorMessage: "Session is invalid, we redirect to login" });
        }
      }
      catch (err) {
        setSessionDataInfo(null)
        setShowRedirectModal({ showModal: true, errorMessage: "Network Error" });
      }
    }

    async function getOneGlobalParam(name) {
      let adminClient = new AdminClient();
      let msg = await adminClient.getOneGlobalParam(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), name)
      if (msg.param !== "" && msg.param !== undefined && msg.param !== null) setMessageAlert(JSON.parse(msg.param))
      else setMessageAlert({})
    }

    console.log('Comprobando si existe session')
    if (sessionInfo) {
      console.log("userInfo ok")
      validateSession(sessionInfo)
      if (history.location.pathname !== "/loginFirstTime") {
        let user = JSON.parse(utils.getSessionItem("user"))
        if ((user.last_password_update === undefined || user.last_password_update === null)) {
          history.push("/loginFirstTime")
        } else setIsFirstTime(true)
      } else setIsFirstTime(true)
    } else {
      setShowRedirectModal({ showModal: true, errorMessage: "Session is invalid, we redirect to login" });
    }
    getOneGlobalParam('BACKOFFICE_NOTIFICATION_ALL_BO')

    var company = "";

    if (utils.getSessionItem("Company") !== undefined) {
      company = utils.getSessionItem("Company");
    }

    console.log("Company " + company + " Location = " + window.location.pathname);

  }, [history.location, sessionInfo, setSessionDataInfo]);



  return (
    <>
      <Route
        {...rest}
        render={props => (
          sessionInfo && isFirstTime ?
            <Layout {...props} messageAlert={messageAlert}>
              <Component {...props} key={props.location.key} idDoc={idDoc} />
            </Layout>
            : showRedirectModal.showModal ? <Alert isOpen={showRedirectModal.showModal} title={t("Error:session is invalid")} content={t(showRedirectModal.errorMessage)} close={handleAlertRedirect} /> : ''
        )}
      />
    </>

  )
}
export default withTranslation()(withRouter(AppRoute))

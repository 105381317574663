import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from "../../AdminClient"
import utils from "../../utils"

import DoubleList from '../../components/basic/DoubleList';


function UserGroups(props) {
    const user = JSON.parse(utils.getSessionItem("userDetails")).user;
    const adminClient = new AdminClient();
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { t } = props;

    const [infoGroups, setInfoGroups] = useState({
        user: user,
        groups: [],
        risk_weighting: 0,
        availables: []
    })

    useEffect(() => {
        let promise0 = adminClient.getReport(
            session,
            company,
            "user_groups",
            null,
            user.user,
            user.db
        );
        let promise1 = adminClient.getReport(
            session,
            company,
            "manual_groups",
            null,
            user.user,
            user.db
        );

        Promise.all([promise0, promise1]).then(
            function (result) {
                let current = result[0].data.data;
                let currentAux = {};
                current.forEach((element) => {
                    currentAux[element.group] = element;
                });
                let availables = result[1].data.data;
                let availablesAux = [];
                availables.forEach((element) => {
                    if (currentAux[element.group] === undefined) {
                        availablesAux.push(element);
                    }
                });
                setInfoGroups((prevState) => {
                    let oldState = { ...prevState }
                    oldState.groups = current;
                    oldState.availables = availablesAux;
                    return oldState
                });
            },
            function (err) {
                console.error(err);
            }
        );

    }, [])

    const save = () => {
        let groupsInfo = JSON.parse(JSON.stringify(infoGroups));
        let groups = groupsInfo.groups

        let groupsIds = [];
        groups.forEach((element) => {
            groupsIds.push(element.group);
        });
        adminClient.saveManualUserGroups(
            JSON.parse(utils.getSessionItem("user")).session,
            user.db,
            user.company,
            user.user,
            JSON.stringify(groupsIds)
        ).then(
            function (msg) {
                if (msg.result === "OK") {
                    props.showAlert(t("User Groups"), t("Saved succesfull"));
                } else {
                    props.showAlert(
                        t("User Groups"),
                        t("Saving error") + " " + msg.description
                    );
                }
            },
            function (err) {
                props.showAlert(
                    t("User Groups"),
                    t("Saving error") + " " + err.description
                );
            }
        );
    }
    const updateGroups = (configured) => {
        setInfoGroups((prevState) => ({ ...prevState, groups: configured }));
    }

    const goBack = (evt) => {
        props.history.goBack();
    }


    return (
        <>
            <DoubleList
                availables={infoGroups.availables}
                configured={infoGroups.groups}
                idProperty={"group"}
                nameProperty={"name"}
                configuredListName={t("Includedsm")}
                allowDelete={{ property: "type", value: "MANUAL" }}
                handleUpdateList={updateGroups}
                pageSize={10}
            ></DoubleList>

            {utils.isGranted("SAVE_USER_GROUPS") && (
                <div className="row">
                    <div className="col-12 ">
                        <button
                            type="button"
                            class="btn btn-primary"
                            onClick={save}
                        >
                            {t("Save")}
                        </button>
                    </div>
                </div>
            )}
        </>
    )

}

export default withTranslation()(UserGroups)


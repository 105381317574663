import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import BasicSelect from '../../components/basic/BasicSelect'
import useFormValidation from '../../hooks/parsleyValidation';
import Report from '../../components/basic/Report'



function ExternalListsDetails(props) {

    let listId = utils.getSessionItem("current-list");

    const { formValidation } = useFormValidation("Form_externalList")

    const [externalListDetails, setExternalListDetails] = useState({ list: "", name: "", company: utils.getSessionItem('Company'), field: "email" });

    const [items, setItems] = useState("");

    const [addOrUpdate, setAddOrUpdate] = useState("add");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    const company = utils.getCurrentCompany().company;

    let adminClient = new AdminClient();




    useEffect(function () {
        if (listId !== '-1') {

            utils.setSessionItem("ReportFilter-external-list-details", JSON.stringify([{ field: "list", type: "=", value: listId }]));
            utils.setSessionItem("ReportFilterView-external-list-details", JSON.stringify([{ field: "list", visibility: "hidden" }]));
            Promise.all([adminClient.getExternalListDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), listId)]).then(
                function (result) {
                    setExternalListDetails(result[0].data);
                    setAddOrUpdate("update")
                },
                function (err) {
                    console.error(err);
                }
            );
        }
        else {
            setAddOrUpdate("add")
        }
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name === "items") {
            const items = JSON.parse(`["${value.split(',').map(item => item.trim()).join('", "')}"]`);
            setItems(items)
        }
        else {
            let newExternalListInfo = { ...externalListDetails };
            newExternalListInfo[name] = value;
            setExternalListDetails(newExternalListInfo);
        }
    };

    const handleDelete = () => {
        let promise = adminClient.deleteList(
            session,
            company,
            JSON.stringify(externalListDetails)
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t("External list config"), t("Deleted succesfull"))
                    goBack();
                } else {
                    props.showAlert(t("External list config"), t("Deleting error") + msg.description)
                }
            }, function (err) {
                console.log("Save operation error " + err.code);
                props.showAlert(t("External list config"), t("Deleting error") + err.description)
            });
    };

    const handleSubmit = () => {
        if (formValidation.validate()) {
            let promise;
            if (addOrUpdate === 'add') {
                promise = adminClient.addExternalListDetails(session, company, JSON.stringify(externalListDetails))
            }
            else {
                promise = adminClient.updateExternalListDetails(session, company, JSON.stringify(externalListDetails))
            }
            utils.setSessionItem("ReportFilter-external-list-details", JSON.stringify([{ field: "list", type: "=", value: externalListDetails.list }]));
            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("External list config"), t("Saved succesfull"))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        window["windowRefExternalListItems"].executeReport(0);
                    } else {
                        props.showAlert(t("External list config"), t("Saving error") + msg.description)
                    }
                }, function (error) {
                    props.showAlert(t("External list config"), t("Saving error ") + t(error.description))
                });
        }
    };

    const handleAdd = () => {
        var promise = adminClient.addExternalListItems(session, company, externalListDetails.list, JSON.stringify(items));
        promise.then(
            function (msg) {
                if (msg.result === 'OK') {
                    window["windowRefExternalListItems"].executeReport(0);
                    props.showAlert(t("Elements added"), t("Elements added ok"))
                } else {
                    props.showAlert(t("Elements added"), t("Adding error"))
                }
            }, function (err) {
                props.showAlert(t("Elements added"), t("Adding error"))
            });
    }

    const handleRemove = () => {
        props.showAlert(
            t('External lists'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeAux(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
        const removeAux = (evt) => {
            var promise = adminClient.removeExternalListItems(session, company, externalListDetails.list, JSON.stringify(items));
            promise.then(
                function (msg) {
                    if (msg.result === 'OK') {
                        window["windowRefExternalListItems"].executeReport(0);
                        props.showAlert(t("Elements removed"), t("Elements removed ok"));
                    } else {
                        props.showAlert(t("Elements removed"), t("Error elements removed ok"));
                    }
                }, function (err) {
                    const errores = err.result.filter(objeto => objeto.result === 'ERROR');
                    window["windowRefExternalListItems"].executeReport(0);
                    props.showAlert(t("Elements removed"), errores.length + " " + t("elements not found"));
                });
        }

    }

    const handleRemoveAll = () => {

        props.showAlert(
            t('External lists'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeAux(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
        const removeAux = (evt) => {
            var promise = adminClient.removeAllExternalListItems(session, company, externalListDetails.list);
            promise.then(
                function (msg) {
                    if (msg.result === 'OK') {
                        window["windowRefExternalListItems"].executeReport(0);
                        props.showAlert(t("Elements removed"), t("Elements removed ok"));
                    } else {
                        props.showAlert(t("Elements removed"), t("Error elements removed ok"));
                    }
                }, function (err) {
                    console.log("Elements removed error " + err.code);
                    window["windowRefExternalListItems"].executeReport(0);
                    props.showAlert(t("Elements removed"), t("Error elements removed ok"));
                });
        }
    }

    const goBack = () => {
        props.history.push('/reports/report/external_lists/table')
    }
    const { t } = props
    const dataHeader = {
        backLink: goBack,
        title: t("External lists config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_externalList">
                <div className="card-body m-0">
                    <div className="row">
                        <div className="col-12 col-md-3  ">
                            <TextField
                                required
                                disabled={addOrUpdate === 'update'}
                                inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                                id="list" label={t("Identifier")} name="list" type="text" value={externalListDetails.list} onChange={onChangeHandler} />
                        </div>
                        <div className="col-12 col-md-5">
                            <TextField required id="name" label={t("Name")} type="text" name="name" value={externalListDetails.name} onChange={onChangeHandler} />
                        </div>
                        <div className="col-12 col-md-4">
                            <BasicSelect
                                label={t("Field")}
                                idProperty="key"
                                name="field"
                                basicInfoName="ExternalListFields"
                                onChange={onChangeHandler}
                                value={externalListDetails.field}
                                noEmptyValue
                            />
                        </div>
                        {utils.isGranted('SAVE_CONFIGURATION_EXTERNAL_LISTS') &&

                            <div className="col-12">
                                <button hidden={true} className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button>
                                <button type="button" class="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                            </div>
                        }
                        {addOrUpdate === "update" &&
                            <div className="col-12 col-md-12">
                                <TextField multiline id="elements" placeholder="element1, element2, element3"
                                    label={t("Elements")} name="items" type="text" value={items} onChange={onChangeHandler} />
                            </div>
                        }

                    </div>
                    {addOrUpdate === "update" &&
                        <>
                            {utils.isGranted('SAVE_CONFIGURATION_EXTERNAL_LISTS') &&
                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" class="btn btn-primary" disabled={items === ""} onClick={handleAdd}>{t("Add")}</button>
                                        <button type="button" class="btn btn-secondary" disabled={items === ""} onClick={handleRemove}>{t("Remove")}</button>
                                        <button type="button" class="btn btn-secondary" onClick={handleRemoveAll}>{t("Remove all")}</button>
                                    </div>
                                </div>
                            }
                            <div className="col-12 col-md-12">
                                <Report windowRef="windowRefExternalListItems" className="mt-3" reportId="external_lists_items" viewFilter="external-list-details" filterId="external-list-details" view="table" ></Report>
                            </div>
                        </>
                    }
                </div>
            </form>
        </LayoutSection>
    )
}

export default withTranslation()(ExternalListsDetails)

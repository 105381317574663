import React from "react";
import { withTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { alias: '', password: '', otp: '' };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdateAlias = this.handleUpdateAlias.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
        this.enterListener = this.enterListener.bind(this);
        this.handleUpdateOTP = this.handleUpdateOTP.bind(this);
    }


    enterListener(event) {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            this.props.handleSubmit(this.state.alias, this.state.password, this.state.otp);
        }

    }
    componentDidMount() {
        document.title = window.appBOTitle || 'Calimaco';
        window.addEventListener("keydown", this.enterListener);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.enterListener);
    }

    handleUpdateAlias(evt) {
        this.setState({ "alias": evt.target.value });
    }

    handleUpdatePassword(evt) {
        this.setState({ "password": evt.target.value });
    }

    handleUpdateOTP(evt) {
        this.setState({ "otp": evt.target.value });
    }

    handleSubmit(evt) {
        console.log(" otp = " + this.state.otp);

        this.props.handleSubmit(this.state.alias, this.state.password, this.state.otp);
    }

    render() {
        const { t } = this.props;
        return (

            <div className="container mt-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4 col-lg-3 loginPanel">
                        <form className="text-center">
                            <img src={process.env.REACT_APP_LOGO} alt={process.env.REACT_APP_TITLE} className="logo_login"></img>
                            <div className="container loginInputs">
                                <div className="mb-3  text-start">
                                    <TextField tabIndex={1} label={t("Username")} type="text" onChange={this.handleUpdateAlias} />
                                </div>

                                <div className="mb-3 text-start">
                                    <TextField tabIndex={2} label={t("Password")} type="password" onChange={this.handleUpdatePassword} />
                                </div>

                                <div className="mb-3 text-start  clmc-otp-element " id="OTPinput">
                                    <TextField tabIndex={3} label={t("One Time Password")} type="text" onChange={this.handleUpdateOTP} />
                                </div>

                                <div>{t(this.props.errorMessage)}</div>
                                <button tabIndex={4} type="button" className="btn btn-primary" onClick={this.handleSubmit}>{t("Login")}</button>


                            </div>

                        </form>
                        <div className="col-12 "><p className=" text-end">v {process.env.REACT_APP_VERSION}</p></div>
                    </div>
                </div >
            </div >



        );
    }
}
export default withTranslation()(LoginForm);

import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from '../../utils'
import DateAndTimePicker from '../../components/basic/DateAndTimePicker'
import useFormValidation from '../../hooks/parsleyValidation'


const DateAndTimePickerTestPage = (props) => {

    const dataHeader = {
        backLink: "",
        title: "Uso de DateAndTime",
        urlHelp: "",
        idDoc: props.idDoc
    }

    const [date, setDate] = useState('2023-12-22 08:19:58');
    const { formValidation } = useFormValidation('pruebaFecha')

    // <DateAndTimePicker
    //     required={isRequired}
    //     validations={validations}
    //     disabled={(currentFilterVisibility === 'disabled' ? true : false)}
    //     key={"date_init_" + filter.field}
    //     label={t(filter.label)}
    //     value={valuesDateRange[0]}
    //     fieldName={filter.field}
    //     showTime={false}
    //     callBackFunction={(value, fieldName) => {
    //         let prevVal = filterData[filter.field];
    //         if (value === null) value = ""
    //         if (prevVal !== undefined) {
    //             // prevVal.value[0]=value!==''?momentTimeZone(new Date(value).getTime()).tz(utils.getCurrentCompany().config.timezone).format("YYYY-MM-DD")
    //             prevVal.value[0] = value !== '' ? momentTimeZone(new Date(value).getTime()).format("YYYY-MM-DD")
    //                 : ''

    //         }
    //         else {
    //             prevVal = { field: filter.field, value: [2], type: filter.type }
    //             prevVal.value[0] = value !== '' ? momentTimeZone(new Date(value).getTime()).format("YYYY-MM-DD") : ''
    //             prevVal.value[1] = ""
    //         }
    //         onChangeFilter(prevVal, filter.field)
    //     }}
    // />

    const changeDate = (value, fieldName) => {
        setDate(value)
    }

    return (
        <>
            <LayoutSection dataHeader={dataHeader}>
                <div>La Fecha es {date}</div>
                <form id="pruebaFecha">
                    <DateAndTimePicker
                        showTime={true}
                        value={date}
                        callBackFunction={changeDate}
                    />
                </form>
            </LayoutSection>
        </>
    )
}

export default withTranslation()(DateAndTimePickerTestPage)
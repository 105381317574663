import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import Tab from '../../components/basic/BasicTabs';
import Report from "../../components/basic/Report";
import { useEffect } from 'react';

function Banners(props) {
    const { t } = props;

    const [tabsContent, setTabsContent] = useState(
        [
            { title: t("Current banners"), content: "" },
            { title: t("Past banners"), content: "" },
        ]
    );

    let currentBannersRender = (
        <div className="col-12 col-md-12">
            <Report windowRef="windowRefAudit" className="mt-3" reportId="banners" view="table"  ></Report>
        </div>
    )

    let pastBannersRender = (
        <div className="col-12 col-md-12">
            <Report windowRef="windowRefAudit" className="mt-3" reportId="banners_past" view="table"  ></Report>
        </div>
    )



    useEffect(() => {
        let tabAux = [
            { title: t("Current banners"), content: currentBannersRender },
            { title: t("Past banners"), content: pastBannersRender },
        ]
        setTabsContent(tabAux)

    }, [])


    //HEADER
    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Banners'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <Tab active={0}>
                {
                    tabsContent.map((tab, idx) =>
                        <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                            {tab.content}
                        </Tab.TabPane>
                    )
                }
            </Tab>
        </LayoutSection>
    )
}
export default withTranslation()(Banners);
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import File from "../../components/basic/File";
import { DateAndTimePicker } from "../../components/DateAndTimePicker";
import AllowedForbiddenList from "../../components/basic/AllowedForbiddenList";
// import SelectWeekDays from "../../components/SelectWeekDays";
import HoursCheckbox from '../../components/basic/hoursCheckbox'
import BasicSelect from "../../components/basic/BasicSelect"
import Tab from '../../components/basic/BasicTabs';
import useFormValidation from '../../hooks/parsleyValidation';
import WeeksCheckbox from "../../components/basic/WeeksCheckbox";
import { CKEditor } from 'ckeditor4-react'
import { useRef } from 'react'
import Editor from '../../components/basic/Editor'

function PopupsDetails(props) {

    const session = JSON.parse(utils.getSessionItem("user")).session;

    const company = utils.getCurrentCompany().company;

    const { formValidation } = useFormValidation("form-popupDetails")

    const { t } = props

    let companyAux = {};
    JSON.parse(localStorage.getItem("Companies")).forEach(company => {
        if (company.company === localStorage.getItem("Company")) {
            companyAux = company;
        }
    });

    const [newOrUpdate, setNewOrUpdate] = useState("new");

    const [popupDetails, setPopupDetails] = useState({
        popup: "",
        name: "",
        status: 0,
        init_date: utils.getDateTZTodayObj().getTime(),
        end_date: utils.getDateTZTodayObj().getTime(),
        weekdays: "0000000",
        hours: "000000000000000000000000",
        config: {
            url: "",
            title: "",
            subject: "",
            body: "",
            image: "",
            button_text: ""
        },
        groups_allowed: [],
        groups_availables: [],
        groups_forbbiden: [],
    });


    let adminClient = new AdminClient();

    const popupId = utils.getSessionItem("current-popup");
    // const [ckEditor, setCkEditor] = useState(undefined);



    useEffect(function () {
        if (popupId !== undefined && popupId !== '-1') {
            adminClient.getPopupDetails(session, company, popupId).then(
                function (result) {
                    var popup = result.data;
                    popup.groups_allowed = [];
                    popup.groups_forbbiden = [];
                    if (popup.init_date !== undefined) {
                        popup.init_date = utils.formatDate(popup.init_date)
                    }
                    if (popup.end_date !== undefined) {
                        popup.end_date = utils.formatDate(popup.end_date)
                    }
                    result.data.groups.forEach(element => {
                        console.log("element : " + JSON.stringify(element))
                        if (element.relationship === 'ALLOWED') {
                            popup.groups_allowed.push(element);
                        } else {
                            popup.groups_forbbiden.push(element);
                        }

                    });
                    setNewOrUpdate("update");
                    setPopupDetails(popup);

                },
                function (err) {
                    console.error(err);
                }
            );
        }
        else {
            adminClient.getGroups(session, company).then(
                function (result) {
                    let groups = [];
                    Object.keys(result.groups).forEach(function (key, index) {
                        groups.push(result.groups[key]);
                    });
                    let popup = { ...popupDetails }
                    popup.groups_availables = groups;
                    popup.config.body = ' '
                    setPopupDetails(popup);
                    setNewOrUpdate("new");
                },
                function (err) {
                    console.error(err);
                }
            );
        }

    }, [])

    // const isFirstRun = useRef(true);

    // useEffect(() => {
    //     if (ckEditor !== undefined) {
    //         if (isFirstRun.current) {
    //             isFirstRun.current = false;
    //             setCkEditor((prevCK) => {
    //                 let ckEd = prevCK;
    //                 ckEd.setData(popupDetails.config.body)
    //                 return { ...prevCK, ckEd }
    //             });;
    //         }
    //     }
    // }, [ckEditor])

    useEffect(() => {
        let tabAux = [
            { title: t("Calendar"), content: calendarRender },
            { title: t("Groups"), content: groupsRender },
            { title: t("Multimedia"), content: multimediaRender }
        ]
        setTabsContent(tabAux)

    }, [popupDetails, newOrUpdate])


    const saveDataPopup = (urls) => {
        let popup = { ...popupDetails }
        if (urls.length === 0) popup.config.image = "";
        else popup.config.image = urls[0];
        setPopupDetails(popup)
    }

    const handleUpdate = (evt) => {
        let popup = { ...popupDetails }
        if (evt.target === null || evt.target === undefined) {
            popup[evt.name] = evt.value;
        }
        else {
            popup[evt.target.name] = evt.target.value;
        }
        setPopupDetails(popup);
    }

    const handleUpdateConfig = (evt) => {
        let popup = { ...popupDetails }
        if (evt.target === null || evt.target === undefined) {

        }
        else {
            popup.config[evt.target.name] = evt.target.value;
        }
        setPopupDetails(popup);
    }

    const handleUpdateGroups = (allowed, forbidden) => {
        let popup = { ...popupDetails }
        popup.groups_allowed = allowed;
        popup.groups_forbbiden = forbidden;
        setPopupDetails(popup);

    }

    const handleUpdateAllWeekDays = (day, evt) => {
        let popup = { ...popupDetails }
        if (popup.weekdays === '1111111') {
            popup.weekdays = '0000000';
        } else {
            popup.weekdays = '1111111';
        }
        setPopupDetails(popup)
    }

    const handleUpdateAllHours = (day, evt) => {
        let popup = { ...popupDetails }
        if (popup.hours === '111111111111111111111111') {
            popup.hours = '000000000000000000000000';
        } else {
            popup.hours = '111111111111111111111111';
        }
        setPopupDetails(popup)
    }

    const handleEditorChange = (event) => {
        // const data = event.editor.getData();
        const data = event;
        setPopupDetails(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                body: data,
            }
        }));
    };


    const setCharAt = (str, index, chr) => {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    const handleUpdateWeekDays = (day, evt) => {
        let popup = { ...popupDetails }
        popup.weekdays = setCharAt(popup.weekdays, day, + evt.currentTarget.checked);
        setPopupDetails(popup)
    }

    const handleUpdateHours = (hour, evt) => {
        let popup = { ...popupDetails }
        popup.hours = setCharAt(popup.hours, hour, + evt.currentTarget.checked);
        setPopupDetails(popup)
    }

    const handleSubmit = () => {

        if (formValidation.validate()) {
            let popupAux = { ...popupDetails }

            popupAux.init_date = utils.unformatDate(popupAux.init_date);
            popupAux.end_date = utils.unformatDate(popupAux.end_date);

            let adminClient = new AdminClient();

            let allowed_groups = [];
            let forbidden_groups = [];


            popupDetails.groups_allowed.forEach(element => {
                allowed_groups.push(element.group);
            });
            popupDetails.groups_forbbiden.forEach(element => {
                forbidden_groups.push(element.group);
            });

            popupAux.forbidden_groups = forbidden_groups.join();
            popupAux.allowed_groups = allowed_groups.join();


            let promise = adminClient.savePopupDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(popupAux));
            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert("OK", t("Save succesfully"));
                        utils.setSessionItem("current-popup", popupDetails.popup);
                        setNewOrUpdate("update");

                    }
                }, function (err) {
                    console.log("Saving error" + " " + err.code);
                });
        }

    }

    let calendarRender = () => {
        return <><div className="row">
            <div className="col-12 col-md-3 ">
                <DateAndTimePicker
                    label={t("Init Date")}
                    fieldName="init_date"
                    value={popupDetails.init_date}
                    showTime={true}
                    callBackFunction={(value, fieldName) => {
                        handleUpdate({ target: { name: fieldName, value: value } })
                    }}
                    id={"init_date"}
                />
            </div>
            <div className="col-12 col-md-3 ">
                <DateAndTimePicker
                    label={t("End Date")}
                    fieldName="end_date"
                    value={popupDetails.end_date}
                    showTime={true}
                    callBackFunction={(value, fieldName) => {
                        handleUpdate({ target: { name: fieldName, value: value } })
                    }}
                    validations={
                        {
                            "data-parsley-validate-date-range": "init_date",
                            "data-parsley-validate-date-range-message":
                                t("PARSLEY_VALIDATION_DATE_RANGE")
                        }
                    }
                />
            </div>
        </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <label style={{ marginBottom: "10px", marginLeft: "15px" }}>{t("Week days")}</label>
                    {/* <SelectWeekDays handleChange={handleUpdateWeekDays} handleChangeAll={handleUpdateAllWeekDays} week={popupDetails.weekdays}></SelectWeekDays> */}

                    <WeeksCheckbox handleChange={handleUpdateWeekDays} handleChangeAll={handleUpdateAllWeekDays} week={popupDetails.weekdays}></WeeksCheckbox>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <hr></hr>
                    </div>
                </div>
                <div className="row">
                    <div style={{ marginLeft: "15px" }} className="col-12 col-md-6">
                        <label style={{ marginBottom: "10px" }}>{t("Hours")}</label>
                        <HoursCheckbox handleChange={handleUpdateHours} handleChangeAll={handleUpdateAllHours} hours={popupDetails.hours}></HoursCheckbox>
                    </div>
                </div>
            </div>
        </>
    }

    let groupsRender = () => {
        return <>
            <AllowedForbiddenList
                allowed={popupDetails.groups_allowed}
                availables={popupDetails.groups_availables}
                forbidden={popupDetails.groups_forbbiden}
                idProperty={"group"}
                nameProperty={"name"}
                handleUpdateGroups={handleUpdateGroups}
            ></AllowedForbiddenList>
        </>
    }

    let multimediaRender = () => {
        return <>
            <div className="row">
                {newOrUpdate === "new" ?
                    <div className="col-12">
                        <label>{t("INFO_MESSAGE_NO_ID_CREATE")}</label>
                    </div>
                    :
                    <div className="col-12">
                        <File
                            dropEnable={true}
                            showFile={true}
                            showError={true}
                            numFiles={1}
                            viewerEnable={false}
                            deleteEnable={true}
                            saveData={saveDataPopup}
                            fileName={[popupDetails.config.image]}
                            fileUrlPref={companyAux.config.cdn_url + "../"}
                            getFileUrl={(data) => {
                                return "/cms/img/popups/" + popupDetails.popup + "/" + data.fileName;
                            }}
                            type={'POPUP'}
                            showAlert={props.showAlert}
                            owner={JSON.stringify(popupDetails)}
                            typeFiles={['image', 'video']}
                        />
                    </div>
                }
            </div>
        </>
    }

    const [tabsContent, setTabsContent] = useState(
        [
            { title: t("Calendar"), content: calendarRender },
            { title: t("Groups"), content: groupsRender },
            { title: t("Multimedia"), content: multimediaRender }
        ])

    const goBack = () => {
        props.history.push('/reports/report/popups/table')
    }
    const dataHeader = {
        backLink: goBack,
        title: t("Popup config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="col-12   float-left pt-3">
                <form id="form-popupDetails">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <TextField name="popup" required disabled={newOrUpdate === "update"} onChange={handleUpdate} label={t("Identifier")} type="text" value={popupDetails.popup} />
                        </div>

                        <div className="col-12 col-md-6">
                            <TextField name="name" onChange={handleUpdate} label={t("Name")} type="text" value={popupDetails.name} />
                        </div>

                        <div className="col-12 ">
                            <TextField name="url" onChange={handleUpdateConfig} label={t("Url")} type="text" value={popupDetails.config.url} />
                        </div>

                        <div className="col-12 col-md-6">
                            <TextField name="title" onChange={handleUpdateConfig} label={t("Title")} type="text" value={popupDetails.config.title} />
                        </div>

                        <div className="col-12 col-md-6">
                            <BasicSelect
                                idProperty="name"
                                name="status"
                                label={t("Status")}
                                basicInfoName="EnabledDisabled"
                                onChange={handleUpdate}
                                value={popupDetails.status}
                                translate={{ property: "name", prefix: "" }}
                                disableClearable
                            />
                        </div>
                        <div className="col-12 col-md-12">
                            <TextField name="subject" onChange={handleUpdateConfig} label={t("Subject")} type="text" value={popupDetails.config.subject} />
                        </div>
                        <div className="col-12 " style={{ marginBottom: "20px" }}>
                            {/* <CKEditor
                                config={{ height: 200, allowedContent: true, versionCheck: false }}
                                initData=""
                                onChange={handleEditorChange}
                                onInstanceReady={(evt) => {
                                    setCkEditor(evt.editor);
                                }}
                            /> */}
                            <Editor
                                onChangeHandler={(evt) => { handleEditorChange(evt.value) }}
                                info={popupDetails.config.body}
                                oneTime={true}
                            />
                        </div>
                        <div className="col-12 ">
                            <TextField name="button_text" onChange={handleUpdateConfig} label={t("Button text")} type="text" value={popupDetails.config.button_text} />
                        </div>
                    </div>

                    <Tab active={0}>
                        {
                            tabsContent.map((tab, idx) =>
                                <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                    {tab.content()}
                                </Tab.TabPane>
                            )
                        }
                    </Tab>
                </form>
                <div className="row">
                    <div className="row">
                        <div className="col-12 ">
                            <button type="button" class="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                        </div>
                    </div>

                </div>
            </div>
        </LayoutSection >
    )
}

export default withTranslation()(PopupsDetails)

import { withTranslation } from "react-i18next";
import React, { useState, useEffect, useMemo } from 'react'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { TextField } from "@mui/material";
import { getDataTree, isTypeInt, getExpandIds } from '../../libs/utilsTreeSelector'
import { useAsyncDebounce } from "../../hooks/Debounce";
// import { useAsyncDebounce } from "react-table";




function TreeSelector(props) {

    const [nodes, setNodes] = useState([])
    const [checked, setChecked] = useState([])
    const [expanded, setExpanded] = useState([])
    const [filterText, setFilterText] = useState('')
    const [filteredNodes, setFilteredNodes] = useState([])

    let filtro = filterText
    let expandedNodes = []
    const defaultExpanded = useMemo(() => {
        if (props.expanded !== undefined)
            return getExpandIds(props.nodes, props.typeTree, props.expanded || [])
        else return props.expanded || []
    }, [JSON.stringify(props.expanded)])


    const [errorsTree, setErrorsTree] = useState([])

    const TreeSelectorFilter = ({ filter, setFilter, labelFilter, name }) => {

        const [value, setValue] = useState(filter)

        const onChange = useAsyncDebounce((value) => {
            setFilter(value || '')
            let elementSearch = document.getElementById('TreeSelectorSearch_' + name)
            elementSearch.focus()
        }, 500)

        return (
            <TextField
                className="filter-text"
                placeholder={t("Search") + " " + labelFilter}
                type="text"
                inputProps={{ id: 'TreeSelectorSearch_' + name }}
                value={value || ''}
                onChange={(evt) => {
                    setValue(evt.target.value)
                    onChange(evt.target.value)
                }
                }
            />
        )
    }
    const { t } = props

    const getValuesFilteredChecked = (tree, valuesFilteredChecked, checkedValues) => {
        tree.forEach(node => {
            if (node.children !== undefined) {
                getValuesFilteredChecked(node.children, valuesFilteredChecked, checkedValues)
            }
            else {
                if (checkedValues.length > 0 && checkedValues.indexOf(node.value) > -1) {
                    valuesFilteredChecked.push(node.value)
                }
            }
        })
        return valuesFilteredChecked
    }

    const onCheck = (checkedElements) => {

        if (isTypeInt(props.typeTree)) checkedElements = checkedElements.map((value) => { return parseInt(value) });
        if (filterText !== '') {
            let allChecked = [...checked]
            let valuesFilteredChecked = []
            valuesFilteredChecked = getValuesFilteredChecked(filteredNodes, [], checked)
            if (valuesFilteredChecked.length === 0) allChecked = allChecked.concat(checkedElements)
            else {
                valuesFilteredChecked.forEach(val => {
                    let cheleIdx = checkedElements.indexOf(val)
                    if (checkedElements.indexOf(val) == -1) {
                        allChecked.splice(allChecked.indexOf(val), 1)
                    } else {
                        if (allChecked.indexOf(val) == -1) {
                            allChecked.push(val)
                        } else checkedElements.splice(cheleIdx, 1)
                    }
                })
                allChecked = [...new Set([...allChecked, ...checkedElements])]
            }
            checkedElements = allChecked
        }
        setChecked(checkedElements)
        props.onCheck(checkedElements)
    }

    const onExpand = (expandedElements) => {
        setExpanded(expandedElements)
    }



    const filterNodes = (filtered, node) => {
        const children = (node.children || []).reduce(filterNodes, []);

        if (node.labelOriginal.toLocaleLowerCase().indexOf(filtro.toLocaleLowerCase()) > -1 ||
            children.length) {
            if (children.length === 0) {
                filtered.push({ ...node });
            }
            else {
                filtered.push({ ...node, children });
                expandedNodes.push(node.value)
            }
        }
        return filtered;
    }

    const filterTree = (treeNodes) => {
        if (filtro === '' || filtro === null || filtro === undefined) {
            setFilteredNodes(treeNodes)
            setExpanded([...new Set([...defaultExpanded, ...expandedNodes])])
        } else {
            let nodesFil = treeNodes.reduce(filterNodes, [])
            setFilteredNodes(nodesFil)
            setExpanded(expandedNodes)

        }
    }


    const onFilterChange = (filter) => {

        filtro = filter
        filterTree(nodes)
        setFilterText(filter)


    }

    useEffect(async () => {
        const { t } = props
        let treeNodes = []
        if (props.nodes.length === 0) {
            setNodes(treeNodes)
            setFilteredNodes(treeNodes)
            setExpanded([])
        } else {
            treeNodes = await getDataTree(props.nodes, props.typeTree, t, props.itemTpl, props.config, props.extraConfig);
            setNodes(treeNodes)

            if (filterText === '') {
                setFilteredNodes(treeNodes)
                setExpanded([...new Set([...defaultExpanded, ...expanded])])
            }
            else {
                filterTree(treeNodes)
                setExpanded(expanded)
            }

        }
        //Comprobamos que los valores existan en el arbol
        let findChecked = getValuesFilteredChecked(treeNodes, [], props.checked)
        props.onCheck(findChecked)
        setChecked(findChecked)
    }, [JSON.stringify(props.nodes), props.itemTpl]);

    useEffect(() => {
        let errors = [];

        if (props.typeTree === "providerMachine" || props.typeTree === "machine") {
            for (let i in props.checked) {
                const checkedId = props.checked[i];
                let nodeFound;

                if (Array.isArray(props.nodes)) {
                    nodeFound = props.nodes.some(node => node.machine === checkedId);
                } else if (typeof props.nodes === 'object') {
                    const nodesArray = Object.values(props.nodes);
                    nodeFound = nodesArray.some(node => node.machine === checkedId);
                }
                if (!nodeFound) {
                    errors.push({ name: checkedId, description: t("Machine disabled") });
                }
            }
            for (let i in props.nodes) {
                let objeto = props.nodes[i];
                if (props.checked.includes(objeto.machine)) {
                    if (objeto.status === "DISABLED") {
                        errors.push({ name: objeto.machine, description: t("Machine disabled") })
                    }
                    else if (objeto.allow_freespins) {
                        if (objeto.external_id === undefined || objeto.external_id === null || objeto.external_id === '') {
                            errors.push({ name: objeto.machine, description: t("Machine not propertly configurated") })
                        }
                    }

                }
            }
            if (errorsTree.length === 0) {
                setErrorsTree(errors)
            }
        }

        setChecked(props.checked)
    }, [JSON.stringify(props.checked)])


    return (
        <div className="filter-container">
            <TreeSelectorFilter filter={filterText} setFilter={onFilterChange} labelFilter={props.label} name={props.name} />
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                nodes={filteredNodes}
                onCheck={onCheck}
                iconsClass="fa5"
                showNodeIcon={false}
                showExpandAll={true}
                onExpand={onExpand}
            />
            {errorsTree.length > 0 && (
                <div className="error-container">
                    <p className="error-title">{t(props.typeTree + "-error-title")}</p>
                    {errorsTree.map((error, index) => (
                        <div key={index}>
                            <p className="error-message">{"•"}&nbsp;{error.name} {"→"} &nbsp;{error.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default withTranslation()(TreeSelector);
import React, { useEffect, useState } from "react"
import AdminClient from "../../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../../utils";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import { generateColumnsDef } from "../../libs/utilsReports";
import BasicTable from "../../components/basic/BasicTable";
import { Checkbox } from "@mui/material";

function UserConsentsDetails(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany(company);

    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [dataTable, setDataTable] = useState({ columns: [], data: [] })
    const goBack = () => {
        props.history.push("/support/user_details");
    };

    const [consentsDetails, setConsentsDetails] = useState([])
    const [initialConsentsDetails, setInitialConsentsDetails] = useState([])

    const [columns, setColumns] = useState([])
    const user = JSON.parse(utils.getSessionItem("userDetails")).user;

    const dataHeader = {
        backLink: goBack,
        title: t('Consents'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const handleCheck = (evt, consent) => {
        let checked = evt.target.checked;
        setConsentsDetails((prevState) => {
            let oldState = [...prevState]
            oldState[consent].agree = checked ? "CONSENT_1" : "CONSENT_0";
            return oldState
        });
    }
    useEffect(() => {

        let reportPromise = adminClient.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), "user_consents", [], user.user, user.db, "limit 0 ,1000 ", "created_date desc");
        reportPromise.then(
            function (msg) {

                let columns = []
                columns = generateColumnsDef(msg.data.def, t)
                setConsentsDetails(JSON.parse(JSON.stringify(msg.data.data)))
                setInitialConsentsDetails(JSON.parse(JSON.stringify(msg.data.data)))
                columns[1] = {
                    Header: t("Acepto"),
                    accessor: "agree",
                    typeCell: "checkbox",
                    Cell: (props) => {
                        return <Checkbox
                            disabled={props.row.original.type === "TERMS"}
                            checked={props.row.original.agree === "CONSENT_1"}
                            onChange={(evt) => { handleCheck(evt, props.row.id) }}
                            sx={{
                                '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.5)' }
                            }}
                        ></Checkbox>
                    },
                    Filter: true
                }


                setColumns(columns)
            }
            , function (err) {

            });

    }, [])

    function getConsentsFilter() {
        // Crear un objeto para almacenar los objetos del array final basados en "consent"
        const mapaFinal = initialConsentsDetails.reduce((acc, obj) => {
            acc[obj.consent] = obj;
            return acc;
        }, {});

        // Filtrar los objetos del array inicial cuyo "agree" sea diferente en el array final
        const consentimientosDiferentes = consentsDetails.filter(obj => {
            const objFinal = mapaFinal[obj.consent];
            return objFinal && obj.agree !== objFinal.agree;
        });

        return consentimientosDiferentes;
    }
    const save = (evt) => {
        const constFinals = getConsentsFilter();

        adminClient.saveUserConsents(
            session,
            company,
            JSON.stringify(user),
            JSON.stringify(constFinals)
        ).then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t("Ok"), t("Saved succesfull"))
                } else {
                    props.showAlert(t("Error"), t("Saving error"))
                }
            }, function (err) {
                console.log("Save operation error " + err.code);
                props.showAlert(t("Error"), t("Saving error"))
            });
    }


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserConsentsDetails">
                <div className="row">
                    <BasicTable key="table-Consents" data={consentsDetails} columns={columns} hidePagination />
                </div>

            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted('SAVE_USER_AUTOEXCLUSION') &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={goBack}
                    >
                        {t('Back')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(UserConsentsDetails);
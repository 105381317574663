import React, { useEffect, useState } from 'react'
import utils from '../../utils';
import { withTranslation } from 'react-i18next'

const VoucherTicket = (props) => {
    const data = props.data
    const { t } = props;
    const [css, setCss] = useState("");

    useEffect(() => {
        const cssUrl = utils.getCurrentCompany().config?.backoffice?.voucher_ticket_css;
        setCss(cssUrl);
    }, []);

    return (
        <div id="voucher-ticket" className="container calimaco_voucher_ticket_print_container_general">
            <link rel="stylesheet" href={css} />
            <div className="calimaco_voucher_ticket_print_container_logo"></div>
            <div className="calimaco_voucher_ticket_print_container">
                <div className="row calimaco_voucher_ticket_print_title">
                    <span>{t("retail_title")}</span>
                </div>
                <div className="row calimaco_voucher_ticket_print_date">
                    <span>{t("Date")}: </span>
                    <p>{utils.formatDate(data?.voucher?.created_date)}</p>
                </div>
                <div className="row calimaco_voucher_ticket_print_amount">
                    <span>{t("The amount of")}:</span>
                    <p className='value'>{data?.voucher?.amount / 100} {data?.voucher?.currency}</p>
                </div>
                <div className="row calimaco_voucher_ticket_print_container_qr">
                    {data?.img && <img src={data.img} alt="QR Code" />}
                </div>
            </div>
        </div>
    );
}

function VoucherPrint(props) {
    const data = JSON.parse(utils.getSessionItem("voucherTicket_data"));
    const { t } = props;
    console.log('voucher print: ' + JSON.stringify(data))
    if (data) {
        return (
            <VoucherTicket data={data} t={t} />
        )
    } else {
        return (
            <p>No hay datos</p>
        )
    }
}
export default withTranslation()(VoucherPrint)

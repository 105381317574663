import React, { memo, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { useAlert } from '../../hooks/Alert';
import AlertMessage from '../../components/NavigationAndLayouts/AlertMessage';
import { TextField } from '@mui/material';
import AgentsMenu from '../../components/AgentsMenu';
import AgentsTree from '../../components/agents/AgentsTree';
import memoizeOne from 'memoize-one';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { areEqual } from "react-window"
import CustomRenders from '../../context/customRenders';


// Componente Row que representa cada nodo.
const Row = memo(({ data, index, style }) => {
    const { flattenedData, onOpen, onSelect, updateNode, resources } = data;
    const node = flattenedData[index];
    const leftIcon = (node.depth - 1) * 10;
    const left = leftIcon + 22;

    return (
        <>
            <div style={style} className='item-background'>
                <div style={{
                    position: 'absolute',
                    left: `${leftIcon}px`,
                    width: `calc(100% - ${leftIcon}px)`,
                }}>
                    {node.hasChildren && node.collapsed &&
                        <IconButton className='icon-button' onClick={async () => await onOpen(node)}><FontAwesomeIcon icon="angle-right" className='icon' /></IconButton>

                    }
                    {node.hasChildren && !node.collapsed &&
                        <IconButton className='icon-button' onClick={async () => await onOpen(node)}><FontAwesomeIcon icon="angle-down" className='icon' /></IconButton>
                    }
                </div>

                {/* Contenido del nodo */}
                <div
                    className={`${node.hasChildren ? 'tree-branch' : ''} ${node.collapsed ? 'tree-item-closed' : 'tree-item-open'}`}
                    style={{
                        position: 'absolute',
                        left: `${left}px`,
                        width: `calc(100% - ${left}px)`,
                    }}
                >
                    {/* Renderización de contenido */}

                    {resources.renders.renderAgentAlias(node.alias, node, resources.t, {})}
                    {resources.renders.renderAgentsPlayersCurrency(node.amount, node, resources.t, {})}
                    {resources.renders.renderAgentsPlayersOperations("", node, resources.t, {
                        showAlert: resources.showAlert, handleCloseAlert: resources.closeAlert, extraData: {
                            finishOperation: ({ action, type, dataResponse }) => {
                                let updatedNode = { ...node }
                                switch (action + "_" + type) {
                                    case "SEND_agent":
                                    case "RETRIEVE_agent": {
                                        updatedNode.amount = dataResponse[0].amount
                                        updateNode(index, updatedNode)
                                        break;
                                    }
                                    case "SEND_player":
                                    case "RETRIEVE_player": {
                                        updatedNode.amount = updatedNode.amount + 1;
                                        updateNode(index, updatedNode)
                                        break;
                                    }
                                    case "STATUS_agent": {
                                        let updatedNodes = dataResponse.agentsUpdated.split(" ")
                                        if (dataResponse.allAgents) {
                                            //Buscamos a todos los agentes que han sido actualizados su estado 
                                            flattenedData.forEach((n, index) => {
                                                if (updatedNodes.includes(`${n.user}`)) {
                                                    n.status = dataResponse.status
                                                    if (dataResponse.status === 'DISABLED') {
                                                        n.blocked_by = resources.adminUser
                                                    }
                                                    else n.blocked_by = null
                                                    updateNode(index, n)
                                                }
                                            })
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                    })}
                </div>
            </div>



        </>
    );
}, areEqual);

const getItemData = memoizeOne((onOpen, onSelect, flattenedData, updateNode, resources) => (
    {
        onOpen,
        onSelect,
        flattenedData,
        updateNode,
        resources
    }
));


const AgentControlTree = (props) => {
    const t = props.t
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminUser = JSON.parse(utils.getSessionItem('user')).user;
    const [data, setData] = useState([])

    const adminClient = new AdminClient()

    //Variables para almacenar el total de nodos pedidos (root y child nodes).
    const [totalRootNodesFetched, setTotalRootNodesFetched] = useState(0)
    const [totalChildNodesFetched, setTotalChildNodesFetched] = useState({})
    const [executeSearch, setExecuteSearch] = useState(false)
    //PRUEBAS CON MUCHOS ROOT Y CHILDS
    // const MAX_ROOT_NODES = 100; // Máximo de 100 root nodes.
    // const MAX_CHILD_NODES = 50; // Máximo de 50 child nodes por nodo padre.

    const [searchString, setSearchString] = useState("");
    const [search, setSearch] = useState("all");
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()
    const dataHeader = {
        title: t('Agent Control Panel'),
        buttons: [],
        idDoc: props.idDoc
    };

    // const refreshReport = () => {

    //     if (window['ReportResult']) window['ReportResult'].executeReport(0)
    // }

    // const extraData = {
    //     "finishOperation": refreshReport
    // }

    const onChangeSearchString = (evt) => {
        const { value } = evt.target
        setSearchString(value)
    }




    //PRUEBAS CON MUCHOS ROOT Y CHILDS

    // // Función simulada para generar nodos.
    // const generateNodes = (prefix, startIndex, count, parent = 0, depth = 1) => {
    //     return Array.from({ length: count }, (_, i) => ({
    //         user: `${prefix}-${startIndex + i + 1}`,
    //         alias: `${prefix} Node ${startIndex + i + 1}`,
    //         children: null, // Los nodos con profundidad 2 pueden tener hijos.
    //         depth: depth,
    //         parent: parent
    //     }));
    // };




    // Función simulada para fetchMoreRootNodes, limitada a 100 nodos.
    const fetchMoreRootNodes = async () => {
        console.log("Fetching more root nodes from", totalRootNodesFetched);


        //PRUEBAS CON MUCHOS ROOT Y CHILDS

        // // Si ya se alcanzó el máximo de 100 root nodes, no devolvemos más.
        // if (totalRootNodesFetched >= MAX_ROOT_NODES) {
        //     return [];
        // }

        // return new Promise((resolve) => {


        //     //Calculamos cuántos nodos se pueden pedir sin superar el máximo.
        //     const nodesToFetch = Math.min(20, MAX_ROOT_NODES - totalRootNodesFetched);
        //     const newNodes = generateNodes('Root', totalRootNodesFetched, nodesToFetch);


        //     resolve(newNodes);


        // });

        return new Promise((resolve) => {

            const filterReport = [{ "field": "u.alias", "type": "like_end", "value": searchString }]

            if (totalRootNodesFetched != -1) {
                adminClient.getReport(session, company, "agents_control_" + search, filterReport, null, null, `limit ${totalRootNodesFetched},20`).then((result) => {
                    //PRUEBAS CON MUCHOS ROOT Y CHILDS

                    // Calculamos cuántos nodos se pueden pedir sin superar el máximo.
                    //const nodesToFetch = Math.min(20, MAX_ROOT_NODES - totalRootNodesFetched);
                    // const newNodes = generateNodes('Root', totalRootNodesFetched, nodesToFetch);
                    // resolve(newNodes);

                    if (result.data.data.length > 0) {
                        let newNodes = result.data.data.map((d) => {
                            return {
                                ...d,
                                children: null, // Los nodos con profundidad 2 pueden tener hijos.
                                depth: 1,
                                parent: 0,
                                hasChildren: d.hasChildren === 1 ? true : false
                            }
                        })
                        if (newNodes.length === 20) {
                            setTotalRootNodesFetched(totalRootNodesFetched + newNodes.length); // Actualizamos el total de nodos root pedidos.
                        } else setTotalRootNodesFetched(-1);
                        resolve(newNodes)
                    } else {

                        setTotalRootNodesFetched(-1); // Actualizamos el total de nodos root pedidos.
                        resolve([])
                    }

                })
            } else resolve([])
        });

    };

    // Función simulada para fetchMoreChildNodes, limitada a 50 nodos hijos por nodo padre.
    const fetchMoreChildNodes = async (parentId, startIndex) => {

        //PRUEBAS CON MUCHOS ROOT Y CHILDS

        // // Inicializamos el contador para el nodo padre si no existe.
        // if (startIndex === 0 || !totalChildNodesFetched[parentId]) {
        //     totalChildNodesFetched[parentId] = 0;
        // }
        // console.log(`Fetching more child nodes for parent ${parentId} from index ${totalChildNodesFetched[parentId]}`);
        // // Si ya se alcanzó el máximo de 50 child nodes para este nodo, no devolvemos más.
        // if (totalChildNodesFetched[parentId] >= MAX_CHILD_NODES) {
        //     return [];
        // }

        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         // Calculamos cuántos nodos hijos se pueden pedir sin superar el máximo.
        //         const nodesToFetch = Math.min(15, MAX_CHILD_NODES - totalChildNodesFetched[parentId]);
        //         const newNodes = generateNodes(`Child of ${parentId}`, totalChildNodesFetched[parentId], nodesToFetch, parentId);

        //         setTotalChildNodesFetched((prev) => {
        //             return { ...prev, [parentId]: prev[parentId] + newNodes.length }
        //         }); // Actualizamos el total de hijos pedidos para este nodo padre.
        //         resolve(newNodes);
        //     }, 1000); // Simula un retraso de 1 segundo.
        // });


        // Inicializamos el contador para el nodo padre si no existe.
        if (startIndex === 0 || !totalChildNodesFetched[parentId]) {
            totalChildNodesFetched[parentId] = 0;
        }
        console.log(`Fetching more child nodes for parent ${parentId} from index ${totalChildNodesFetched[parentId]}`);


        return new Promise((resolve) => {

            const callFilter = `${parentId};${search};${searchString}`

            if (totalChildNodesFetched[parentId] != -1) {
                adminClient.getReport(session, company, "agents_control_subagents", [], null, null, `limit ${totalChildNodesFetched[parentId]},20`, "", callFilter).then((result) => {

                    if (result.data.data.length > 0) {
                        let newNodes = result.data.data.map((d) => {
                            return {
                                ...d,
                                children: null, // Los nodos con profundidad 2 pueden tener hijos.
                                depth: 0,
                                parent: parentId,
                                hasChildren: d.hasChildren === 1 ? true : false
                            }
                        })
                        if (newNodes.length === 20) {
                            setTotalChildNodesFetched((prev) => {
                                return { ...prev, [parentId]: prev[parentId] + newNodes.length }
                            }); // Actualizamos el total de hijos pedidos para este nodo padre.
                            resolve(newNodes)
                        }
                        else {
                            setTotalChildNodesFetched((prev) => {
                                return { ...prev, [parentId]: -1 }
                            });
                        }
                        resolve(newNodes)
                    } else {

                        setTotalChildNodesFetched((prev) => {
                            return { ...prev, [parentId]: -1 }
                        }); // Actualizamos el total de hijos pedidos para este nodo padre.
                        resolve([])
                    }
                })
            } else resolve([])
        })
    }


    const getAdditionalNodeData = (node) => {

        const { amount, currency, db, type, status, blocked_by } = node

        return { amount, currency, db, type, status, blocked_by }

    }
    useEffect(() => {

        // Datos iniciales para el árbol.

        fetchMoreRootNodes().then((result) => {
            console.log("recibiendo datos")
            setData(result)
        });

    }, [search])

    useEffect(() => {

        if (executeSearch) {
            //if (window['ReportResult']) window['ReportResult'].executeReport([{ "field": "u.alias", "type": "like_end", "value": searchString }])
            fetchMoreRootNodes().then((result) => {
                console.log("recibiendo datos")
                setData(result)
            });
            setExecuteSearch(false)
        }

    }, [executeSearch])


    // utils.setSessionItem("ReportFilterView-agent-control", JSON.stringify([]))
    // utils.setSessionItem("ReportFilter-agent-control", JSON.stringify([{ "field": "u.alias", "type": "like_end", "value": searchString }]))

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>

                <div className="content m-2 row">
                    <div className="col-8 col-md-4">
                        <TextField className='float-start' label={t("Search") + " " + t("Alias")} name="search" type="text" value={searchString} onChange={onChangeSearchString} />
                    </div>
                    <div className="col-4  col-md-2" >
                        <button className={'btn btn-primary float-start'} onClick={() => {
                            setTotalChildNodesFetched({})
                            setTotalRootNodesFetched(0)
                            setExecuteSearch(true)
                        }}>{t('Search')}</button>
                    </div>

                    <div className="col-12 row col-md-6">
                        <div className="col-12 col-md-12" >
                            <button className={(search !== 'users') ? 'btn btn-secondary float-end' : 'btn btn-primary float-end'} onClick={() => {
                                setTotalChildNodesFetched({})
                                setTotalRootNodesFetched(0)
                                setSearch('users')
                            }}>{t("Players")}</button>
                            <button className={(search !== 'agents') ? 'btn btn-secondary float-end' : ' btn btn-primary float-end'} onClick={() => {
                                setTotalChildNodesFetched({})
                                setTotalRootNodesFetched(0)
                                setSearch('agents')
                            }}>{t("AGENTS")}</button>
                            <button className={(search !== 'all') ? 'btn btn-secondary float-end' : 'btn btn-primary float-end'} onClick={() => {
                                setTotalChildNodesFetched({})
                                setTotalRootNodesFetched(0)
                                setSearch('all')
                            }}>{t("All")}</button>

                        </div>
                    </div>
                </div>
                <div style={{ height: "400px" }}>
                    <AgentsTree
                        data={data}
                        fetchMoreChildNodes={fetchMoreChildNodes}
                        fetchMoreRootNodes={fetchMoreRootNodes}
                        row={Row}
                        getItemData={getItemData}
                        getAdditionalNodeData={getAdditionalNodeData}
                        itemSize={35}
                        resources={{
                            renders: new CustomRenders(),
                            showAlert: showAlert,
                            closeAlert: closeAlert,
                            adminUser: adminUser
                        }} />

                </div>


                {/* {search === 'All' &&
                    <Report reportId="agent_control_all" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>}
                {search === 'Agents' &&
                    <Report reportId="agent_control_agents" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>}
                {search === 'Players' &&
                    <Report reportId="agent_control_users" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>} */}
            </LayoutSection >
            <AgentsMenu></AgentsMenu>
            <AlertMessage key={'AgentControl_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
        </>
    )
}

export default withTranslation()(AgentControlTree)
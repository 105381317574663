import React from "react";
import { withTranslation } from "react-i18next";
import AdminClient from "../AdminClient";
import utils from "../utils";
import TextField from '@mui/material/TextField';
// import SelectGroups from "./SelectGroups";
// import SelectPromotions from "./SelectPromotions";
import BasicSelect from "./basic/BasicSelect";

class TournamentPrize extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdateFromPosition = this.handleUpdateFromPosition.bind(this);
        this.handleUpdateToPosition = this.handleUpdateToPosition.bind(this);
        this.handleUpdatPrizeAmount = this.handleUpdatPrizeAmount.bind(this);
        this.handleUpdateGroupMemberShip = this.handleUpdateGroupMemberShip.bind(this);
        this.handleUpdateVirtualGood = this.handleUpdateVirtualGood.bind(this);
        this.handleUpdatePromotion = this.handleUpdatePromotion.bind(this);

        this.handleDelete = this.handleDelete.bind(this);

        this.adminClient = new AdminClient();

        this.state = ({ prize: this.props.prize });

    }

    handleSubmit(evt) {
        const { t } = this.props;

        this.props.handleSave(this.state.prize);

        this.props.showAlert(t("Prize saved"), <p>{t("Prize saved ok")}</p>)
    }

    handleDelete(evt) {
        const { t } = this.props;

        this.props.handleDelete(this.state.prize);

        this.props.showAlert(t("Prize deleted"), <p>{t("Prize deleted ok")}</p>);
    }

    handleUpdateFromPosition(evt) {
        var prize = this.state.prize;
        prize.from_position = Number(evt.currentTarget.value);
        this.setState({ prize: prize });
    }

    handleUpdateToPosition(evt) {
        var prize = this.state.prize;
        prize.to_position = Number(evt.currentTarget.value);
        this.setState({ prize: prize });
    }

    handleUpdatPrizeAmount(evt) {
        var prize = this.state.prize;
        prize.prize_amount = Number(evt.currentTarget.value) * 100;
        this.setState({ prize: prize });
    }

    handleUpdateGroupMemberShip(evt) {
        var prize = this.state.prize;
        if (evt.currentTarget !== undefined && evt.currentTarget !== null) {
            prize.prize_membership = Number(evt.currentTarget.value);
        } else {
            prize.prize_membership = Number(evt.value);

        }
        this.setState({ prize: prize });
    }
    handleUpdatePromotion(evt) {
        var prize = this.state.prize;
        let promotion = evt.value;
        prize.prize_promotion = promotion === "" ? "-1" : promotion;
        // if (evt.currentTarget !== undefined && evt.currentTarget !== null) {
        //     prize.prize_promotion = evt.currentTarget.value;
        // } else {
        //     prize.prize_promotion = evt.target.value;
        // }
        this.setState({ prize: prize });
    }

    handleUpdateVirtualGood(evt) {
        var prize = this.state.prize;

        if (evt.currentTarget !== undefined && evt.currentTarget !== null) {
            prize.prize_virtualgood = evt.currentTarget.value;
        } else {
            prize.prize_virtualgood = evt.target.value;
        }
        this.setState({ prize: prize });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField label={t("From")} type="text" value={this.state.prize.from_position} onChange={this.handleUpdateFromPosition} />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField label={t("To")} type="text" value={this.state.prize.to_position} onChange={this.handleUpdateToPosition} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField label={t("Cash Prize")} type="number" value={this.state.prize.prize_amount !== undefined ? this.state.prize.prize_amount / 100 : 0} onChange={this.handleUpdatPrizeAmount} />
                    </div>
                    <div className="col-12 col-md-6">
                        {/* <SelectPromotions label={t("Promotion")} placeholder={t("Does not apply")} onChange={this.handleUpdatePromotion} value={this.state.prize.prize_promotion || -1} company={utils.getSessionItem('Company')}></SelectPromotions> */}
                        <BasicSelect
                            label={t("Promotion")}
                            idProperty="promotion"
                            name="promotions"
                            nameProperty="promotion"
                            data="promotions"
                            basicInfoName="ManualPromotions"
                            onChange={this.handleUpdatePromotion}
                            value={this.state.prize.prize_promotion}
                        />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        {/* <SelectGroups company={utils.getSessionItem("Company")} placeholder={t("Does not apply")} label={t("Group membership")} value={this.state.prize.prize_membership || -1} onChange={this.handleUpdateGroupMemberShip} ></SelectGroups> */}
                        <BasicSelect
                            idProperty="group"
                            name="group"
                            label="Groups"
                            basicInfoName="Groups"
                            addOpts={[{ group: "-1", name: t("Does not apply") }]}
                            onChange={this.handleUpdateGroupMemberShip}
                            value={this.state.prize.prize_membership}
                            noEmptyValue
                        />
                    </div>

                </div>

                <div className="row">

                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>{t("Add")}</button>
                        <button type="button" className="btn btn-primary btn-delete float-start" onClick={this.handleDelete}>{t("Delete")}</button>
                        <button type="button" className="btn btn-secondary" onClick={this.props.closePopup}>{t("Back")}</button>
                    </div>
                </div>

            </>

        );
    }
}
export default withTranslation()(TournamentPrize);
import React, { useState } from 'react'
import BasicTable from './BasicTable'
import { withTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

const TableAndDetails = (props) => {



  const handleDeleteRow = (id) => {

    props.showAlert(
      t('Delete'),
      <p>{t('Are you sure you want to delete?')}</p>,
      [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
      <button type='button' className='btn btn-primary'
        onClick={(evt) => {
          props.deletehandler(id)
          props.handleCloseAlert()
        }}>
        {t('Ok')}</button>]
    )
    //props.deletehandler(id)
  }
  const columns = props.deletehandler ? [...props.columns, {
    Header: "",
    accessor: "button",
    typeCell: "text",
    Cell: (props) => {
      return <FontAwesomeIcon className="float-end" onClick={(evt) => {
        evt.stopPropagation()
        setShowDetails(false)
        handleDeleteRow(props.row.id)
      }} icon="trash" />
    }

  }] : props.columns
  const renderDetails = props.renderDetails
  const [showDetails, setShowDetails] = useState(false)
  const showSaveButton = props.showSaveButton || false
  const [sizeCol, setSizeCol] = useState(props.colTableSize || 6)
  const showNew = props.ShowNew;
  const { t } = props
  const [data, setData] = useState([])

  const handleDetailsRow = (row) => {
    props.setDetail(row.original)
    setShowDetails(true)
  }
  const handleNewElement = () => {
    props.setDetail(-1)
    setShowDetails(true)
  }
  useEffect(() => {
    setData(props.data || [])

  }, [JSON.stringify(props.data)])


  return (
    <div className="row">
      <div className={showDetails ? "col-" + sizeCol : "col-12"}>
        {(showNew === undefined || showNew) &&
          <div className="col-12">
            <IconButton color="default" key={`TableDetails_button_New`} className="float-end" aria-label={t('New')} onClick={handleNewElement}>
              <FontAwesomeIcon icon="plus-square" />
            </IconButton>
          </div>}
        <div className="col-12">
          <BasicTable key="table-List" data={data} columns={columns} rowHandle={handleDetailsRow} sizePageTable={props.sizePageTable} showFilterColumn={props.showFilterColumn} />
        </div>
      </div>
      <div className={showDetails ? "col-" + (12 - sizeCol) : "hide col-" + (12 - sizeCol)}>
        <div className="row">
          <div className="col-12">
            {showSaveButton && <button className="btn btn-primary" disabled={props.disabledSaveButton} onClick={() => {
              props.handleSaveDetails()
            }}>{t("Save")}</button>}
            <button className="btn btn-secondary" onClick={() => { setShowDetails(false) }}>{t("Close")}</button>
          </div>
          {!props.desactiveForm ?
            <form id={props.formId}>
              {showDetails && renderDetails()}
            </form>
            :
            <>
              {showDetails && renderDetails()}
            </>
          }
        </div>
      </div>
    </div >

  )
}

export default withTranslation()(TableAndDetails)
import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import AdminCLient from '../AdminClient';
import utils from '../utils';

class AppKPI extends React.Component {

  constructor(props) {
    super(props);
    this.state = { title: ".", content: <></>, kpi: { def: { config: {} } } };
    this.chartRef = React.createRef();
    this.showDetails = this.showDetails.bind(this);
    this.visible = false;
    //    console.log("Chart constructor " + props.report+" "+props.filter);
  }

  showDetails() {
    console.log("show details");
    if (this.visible) {

      document.getElementById("kpi-" + this.props.kpi).classList.add("d-none");
    } else {
      document.getElementById("kpi-" + this.props.kpi).classList.remove("d-none");
    }
    this.visible = !this.visible;
  }

  componentDidMount() {
    const { t } = this.props;
    var clientAPI = new AdminCLient();

    var promise = clientAPI.getKPI(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.props.kpi, this.props.filter);
    promise.then(
      function (msg) {
        var values = msg.data.data;
        this.setState({ "title": t(msg.data.def.config.title) });
        this.setState({ kpi: msg.data })
        console.log("KPI values = " + JSON.stringify(values));
        var value0 = values[0].value;
        var value1 = values[1].value;
        var value2 = values[2].value;
        var value3 = values[3].value;
        var value4 = values[4].value;


        if (msg.data.def.config.type === "currency") {
          //value0 = utils.formatCurrency(value0, msg.data.def.config.currency);
          //value1 = utils.formatCurrency(value1, msg.data.def.config.currency);
          //value2 = utils.formatCurrency(value2, msg.data.def.config.currency);

          value0 = utils.formatCurrency(value0, utils.getCurrentCompany().base_currency);
          value1 = utils.formatCurrency(value1, utils.getCurrentCompany().base_currency);
          value2 = utils.formatCurrency(value2, utils.getCurrentCompany().base_currency);
          value3 = utils.formatCurrency(value3, utils.getCurrentCompany().base_currency);
        }

        value0 = t("Today") + ":" + value0;
        // value1 = t("Yesterday") + ":" + value1;
        // value2 = t("Last week") + ":" + value2;
        // value3 = t("Last month") + ":" + value3;
        value4 = utils.formatDate(value4, "HH:mm:ss");


        var help_link = <></>;
        console.log("KPI Help url = " + JSON.stringify(this.state.kpi.def.config));
        // if (this.state.kpi.def.config.help_url !== undefined) {
        //   help_link = <a href className="help_link" onClick={e => { window.dispatchEvent(new CustomEvent("showHelp", { detail: { url: this.state.kpi.def.config.help_url } })) }}><span><FontAwesomeIcon icon="info-circle" /></span></a>
        // }

        var content =
          <div className="card">
            <div className="card-header kpi-header" onClick={this.showDetails}>
              <div className="row">
                <div className="col-12 col-md-9 text-md-start">{this.state.title}  {value0} {help_link}</div>
                <div className="col-12 col-md-3 text-md-end">{value4}</div>
              </div>
            </div>
            <div className="kpi card-body p-0 p-md-3 d-none d-sm-block " id={"kpi-" + this.props.kpi}>
              <div className="row">
                <div className="text-center text-md-end">{t("Yesterday")} {value1}</div>
              </div>
              <div className="row">
                <div className="text-center text-md-end">{t("Last week")} {value2}</div>
              </div>
              <div className="row">
                <div className="text-center text-md-end">{t("Last month")} {value3}</div>
              </div>
            </div>
          </div>
          ;
        this.setState({ "content": content });
      }.bind(this), function (err) {
        console.log("kpi promise error " + err.code);
      }
    );

  }


  render() {

    return (

      <div className="col-12 col-md-6  col-lg-4 col-xl-3  float-left pt-3">
        {this.state.content}
      </div>

    );
  }
}
export default withTranslation()(AppKPI);



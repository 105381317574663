import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import '../../confluenceStyles.css';

const ValleTest = (props) => {
  const { t } = props;
  const [responseDataSpaces, setResponseDataSpaces] = useState(null);
  const [responseDataPages, setResponseDataPages] = useState(null);
  const [responseDataPage, setResponseDataPage] = useState(null);
  const adminClient = new AdminClient();
  const company = utils.getSessionItem('Company');
  const session = JSON.parse(utils.getSessionItem('user')).session;
  const authorization = 'Basic dmFsbGUuY2FycmFzY29AY2FsaW1hY28uY29tOkFUQVRUM3hGZkdGMC1yMjRUVVlnM2VOa1A0b1JSOUhFb1JzcnQyTDVWdkY2ZFB5WEFrMll3dy1NalJvdVRwaV9hODJlQ3N3WWIwQUw2bGs3S04yYlhESl9WWFFfLVNZaGppclg2RURvRkdHdWRITDAtRGdDUER5SFg4TndnckdvVEpqMFBtVkkwdlRqbVpEYmYwQmNNVnZWNGpxWmtMaTYtSnU1QV9lYUlCNldsdDJ2UnZPa3BTOD1BRUM0NUMwQw==';
  let url = 'space/';

  function closePopup() {
    props.handleCloseAlert();
  }

  async function getSpaces() {
    url = 'space/';
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, authorization, url);
      setResponseDataSpaces(documentationResponse.results.data);
    } catch (error) {
      console.error("Error in getSpaces " + error);
    }
  }

  async function getSpacePages(space) {
    url = 'content?spaceKey=' + space;
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, authorization, url);
      setResponseDataPages(documentationResponse.results.data);
    } catch (error) {
      console.error("Error in getSpacePages " + error);
    }
  }

  async function getPageHTML(page) {
    url = 'content/' + page + '?expand=body.export_view';
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, authorization, url);
      setResponseDataPage(documentationResponse.data);
    } catch (error) {
      console.error("Error in getPageHTML " + error);
    }
  }

  function handleClickSpace(spaceKey) {
    getSpacePages(spaceKey);
  }

  function handleClickPage(pageId) {
    getPageHTML(pageId);
  }

  useEffect(() => {
    async function fetchData() {
      await getPageHTML('26181908');
    }
    fetchData();
  }, []); // Lista de dependencias vacía

  return (
    <div style={{ marginLeft: '5%' }}>
      <button className="btn btn-primary right" onClick={closePopup}>{t("Close")}</button>
      {responseDataPage ? (
        <div>
          <h1 style={{ marginBottom: '20px', marginTop: '40px' }}>{responseDataPage.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: responseDataPage.body.export_view.value }} />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default withTranslation()(ValleTest);

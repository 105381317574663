import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import TreeSelector from '../../components/basic/TreeSelector';
import Tab from '../../components/basic/BasicTabs';
import DoubleList from '../../components/basic/DoubleList';
function RoleDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_RoleDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const roleId = utils.getSessionItem('current-role')
    const hiddenFieldsAvailables = useMemo(() => {
        const lista = [
            "address", "affiliate", "affiliate_tag", "agent", "alias", "birthday", "city", "country", "created_date", "deposits_disabled", "email", "facebook_id", "gender", "google_id", "last_device", "last_ip", "last_login_date", "first_name", "last_name", "login_errors", "mobile", "modified_date", "nationality", "national_id", "national_id_type", "otp", "payouts_disabled", "play_disabled", "promotion", "promotions_disabled", "province", "regulatory_status", "register_ip", "state", "type", "verification_type", "verified", "zipcode", "website"

        ];

        const listaAvailables = lista.map(field => {
            return { field };
        });
        return listaAvailables
    }, [])


    const noEditFieldsAvailables = useMemo(() => {

        const lista = [
            "address", "affiliate", "agent", "alias", "birthday", "city", "country", "deposits_disabled", "email", "facebook_id", "first_name", "gender", "google_id", "last_name", "login_errors", "mobile", "nationality", "national_id", "national_id_type", "otp", "payouts_disabled", "play_disabled", "promotions_disabled", "province", "regulatory_status", "state", "type", "verified", "zipcode"
        ];

        const listaAvailables = lista.map(field => {
            return { field };
        });
        return listaAvailables
    }, [])
    const [roleDetails, setRoleDetails] = useState({
        role: {
            role: roleId,
            name: "",
            company: company,
            config: {
                "datosPlayer": {
                    hiddenFields: [],
                    noEditFields: []
                }
            }
        },
        hiddenFieldsAvailables: hiddenFieldsAvailables,
        noEditFieldsAvailables: noEditFieldsAvailables,
        grants: [],
        availables: [],
        nodes: [],
        checked: [],
    });
    const goBack = () => {
        props.history.push('/reports/report/roles/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Role details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (roleDetails.role.role !== undefined && roleDetails.role.role !== '-1') {
            adminClient.getRoleDetails(session, company, roleDetails.role.role).then(
                function (result) {
                    let roleDetailsAux = {}
                    roleDetailsAux.role = result.data;
                    let config;
                    if (result.data.config === null) {
                        config = {
                            "datosPlayer": {
                                hiddenFields: [],
                                noEditFields: []
                            }
                        }
                    }
                    else {
                        config = JSON.parse(result.data.config);
                        if (config.datosPlayer === undefined) {
                            config["datosPlayer"] = {
                                hiddenFields: [],
                                noEditFields: []
                            }
                        } else {
                            let objhidden = config.datosPlayer.hiddenFields.map(string => ({ field: string }));
                            config.datosPlayer.hiddenFields = objhidden
                            let objnoEdit = config.datosPlayer.noEditFields.map(string => ({ field: string }));
                            config.datosPlayer.noEditFields = objnoEdit
                        }


                    }
                    roleDetailsAux.role.config = config;
                    let checked = [];

                    result.grants.forEach(element => {
                        checked.push(element.grant);
                    });

                    let nodesAV = result.availables.concat(result.grants)
                    roleDetailsAux.grants = result.grants
                    roleDetailsAux.availables = result.availables
                    roleDetailsAux.nodes = nodesAV;
                    roleDetailsAux.checked = checked;
                    roleDetailsAux.hiddenFieldsAvailables = hiddenFieldsAvailables.filter(objeto => !config.datosPlayer.hiddenFields.some(elemento => objeto.field === elemento.field));
                    roleDetailsAux.noEditFieldsAvailables = noEditFieldsAvailables.filter(objeto => !config.datosPlayer.noEditFields.some(elemento => objeto.field === elemento.field));

                    setRoleDetails(roleDetailsAux);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, [addOrUpdate]);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setRoleDetails(prevState => ({
            ...prevState,
            role: {
                ...prevState.role,
                [name]: value,
            }
        }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            let roleAux = JSON.parse(JSON.stringify(roleDetails.role))
            if (roleDetails.checked !== undefined) {
                roleAux.grants = roleDetails.checked.join();
            }
            let arrynoEdit = roleAux.config.datosPlayer.noEditFields.map(element => (element.field))
            let arrayhiddenFields = roleAux.config.datosPlayer.hiddenFields.map(element => (element.field))
            roleAux.config.datosPlayer.noEditFields = arrynoEdit
            roleAux.config.datosPlayer.hiddenFields = arrayhiddenFields
            roleAux.config = JSON.stringify(roleAux.config);
            adminClient.saveRole(
                session,
                company,
                JSON.stringify(roleAux)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Role details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Role details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Role details'), t('Saving error ') + error.description)
                }
            )
        };
    }

    const handleRemove = (evt) => {
        props.showAlert(
            t('Role details'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

    }

    const remove = (evt) => {
        adminClient.deleteRole(
            session,
            company,
            JSON.stringify(roleDetails.role)
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Role details'), t('Deleted succesfull'))
                    goBack()
                } else {
                    props.showAlert(t('Role details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('Role details'), t('Deleting error ') + error.description)
            }
        )
    }

    const onCheck = (checked) => {
        setRoleDetails((prevState) => ({ ...prevState, checked: checked }));
    }

    const onChangeList = (name, value) => {

        setRoleDetails((prevState) => {
            let oldState = { ...prevState }
            oldState.role.config.datosPlayer[name] = value;
            return { ...prevState, oldState }
        });

    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_RoleDetails">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField
                            label={t('ID')}
                            name="role"
                            required
                            disabled={addOrUpdate === 'update'}
                            inputProps={addOrUpdate === "add" && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            type="text"
                            value={roleDetails.role.role}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <TextField
                            label={t('Name')}
                            name="name"
                            required
                            type="text"
                            value={roleDetails.role.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                {utils.isGranted('SAVE_CONFIGURATION_ROLES') &&

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        {addOrUpdate === "update" &&
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleRemove}
                            >
                                {t('Delete')}
                            </button>
                        }
                    </div>
                }
            </div>
            {addOrUpdate === "update" &&
                <Tab active={0}>
                    <Tab.TabPane key={`TabContentPlay`} tab={t("Grants")}>
                        <TreeSelector
                            label={t('Grant')}
                            nodes={roleDetails.nodes}
                            checked={roleDetails.checked}
                            onCheck={onCheck}
                            typeTree="grant"
                        ></TreeSelector>
                    </Tab.TabPane>

                    <Tab.TabPane key={`TabContentPlay`} tab={t("Players data")}>

                        <DoubleList
                            availables={roleDetails.hiddenFieldsAvailables}
                            configured={roleDetails.role.config.datosPlayer.hiddenFields}
                            useTranslationsId={true}
                            translatePrefix="PLAYER_FIELD_"
                            idProperty={"field"}
                            configuredListName={t("Hidden fields")}
                            handleUpdateList={(value) => onChangeList("hiddenFields", value)}
                        ></DoubleList>

                        <hr></hr>

                        <DoubleList
                            availables={roleDetails.noEditFieldsAvailables}
                            configured={roleDetails.role.config.datosPlayer.noEditFields}
                            useTranslationsId={true}
                            translatePrefix="PLAYER_FIELD_"
                            idProperty={"field"}
                            configuredListName={t("No edit fields")}
                            handleUpdateList={(value) => onChangeList("noEditFields", value)}
                        ></DoubleList>



                    </Tab.TabPane>

                </Tab>
            }
        </LayoutSection >
    )
}
export default withTranslation()(RoleDetails);
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import AppChart from "../../components/AppChart";
import BasicTable from "../../components/basic/BasicTable";
import Report from '../../components/basic/Report';



function UserSessionDetails(props) {

    const { t } = props;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    const sessionId = utils.getSessionItem("current-session_id")
    const player = JSON.parse(utils.getSessionItem("userDetails")).user;
    const filter = [{ "field": "t.session", "operator": "=", "value": sessionId }];
    if (window.sections === undefined || window.sections === null) {
        window.sections = JSON.parse(utils.getSessionItem("sections"));
    }
    const config = window.sections["USER_SESSION_DETAILS"].config;

    const [sessionDetails, setSessionDetails] = useState(
        {
            init_date: "",
            end_date: "",
            session_ip: "",
            country: ""
        }
    );

    const [dataTable, setDataTable] = useState([])

    const adminClient = new AdminClient();


    useEffect(function () {
        adminClient.userSessionDetails(session, company, player.user, sessionId).then(
            (res) => {
                setSessionDetails(res.data);
            }, (err) => {
                console.log(err);
            }
        );

    }, []);

    useEffect(() => {
        let clavesDeseadas = ["deposits", "payouts", "wagers", "winnings"];
        let arrayResultante = Object.entries(sessionDetails)
            .filter(([key]) => clavesDeseadas.includes(key)) //Filtro las keys
            .map(([name, value]) => ({ name: t(name.charAt(0).toUpperCase() + name.slice(1)), value })); //Traduzco y construyo array
        setDataTable(arrayResultante)
    }, [sessionDetails])

    const goBack = () => {
        props.history.push("/support/report/user_sessions/table");
    };

    const dataHeader = {
        backLink: goBack,
        title: t("Session details"),
        urlHelp: "",
        idDoc: props.idDoc
    };


    const sessionColumns = [
        {
            Header: t("Name"), accessor: 'name', typeCell: 'text_translated', filter: 'currency', Filter: true
        },
        {
            Header: t("Value"), accessor: 'value', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let value = props.row.original.value;
                return utils.formatCurrency(value / 100, player.currency)
            }
        },
    ]
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>

            <div className="summary row">
                <div className="col-12 col-md-6">
                    <TextField disabled label={t("Init Date")} type="text" value={sessionDetails.init_date} />
                </div>
                <div className="col-12 col-md-6">
                    <TextField disabled label={t("End Date")} type="text" value={sessionDetails.end_date} />
                </div>
                <div className="col-12 col-md-6">
                    <TextField disabled label={t("IP")} type="text" value={sessionDetails.session_ip} />
                </div>
                <div className="col-12 col-md-6">
                    <TextField disabled label={t("Country")} type="text" value={sessionDetails.country} />
                </div>
            </div>
            <div class="table-responsive">
                <BasicTable data={dataTable} columns={sessionColumns} showFilterColumn={true} />
            </div>

            <div className="row">
                {config.elements.map((element) => {
                    switch (element.type) {
                        case 'table':
                            utils.setSessionItem("ReportFilter-" + element.report, JSON.stringify(filter));
                            return <Report className="col-12   float-left pt-3" filterId={element.report} reportId={element.report} user={player.user}  ></Report>;
                        case 'chart':
                            return <AppChart report={element.report} filter={filter}></AppChart>;
                        default: return null;
                    }
                })}
            </div>
        </LayoutSection >
    );
}

export default withTranslation()(UserSessionDetails);

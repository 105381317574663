import React, { useState, memo, useEffect, useCallback } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';



// Componente Row que representa cada nodo.
const Row = memo(({ data, index, style }) => {
    const { flattenedData, onOpen, onSelect } = data;
    const node = flattenedData[index];
    const left = node.depth * 20;
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (!dataLoaded) {
            console.log("Renderizando nodo " + node.label + " este es su parentcollapsed " + node.parentCollapsed);
            setDataLoaded(true)
        }
    }, []);

    return (
        <div className="item-background" style={style} onClick={async () => await onOpen(node)}>
            <div
                className={`${node.hasChildren ? 'tree-branch' : ''} ${node.collapsed ? 'tree-item-closed' : 'tree-item-open'}`}
                onClick={e => onSelect(e, node)}
                style={{
                    position: 'absolute',
                    left: `${left}px`,
                    width: `calc(100% - ${left}px)`,
                }}
            >
                {`${node.label}`}
            </div>
        </div>
    );
}, areEqual);

const getItemData = memoizeOne((onOpen, onSelect, flattenedData) => ({
    onOpen,
    onSelect,
    flattenedData,
}));



// Componente principal SpeedTree
const SpeedTree = (props) => {


    const [flattenedData, setFlattenedData] = useState([]);
    const [isLoadingRoot, setIsLoadingRoot] = useState(false)
    const [hasRootMore, setHasRootMore] = useState(true)
    const [isLoadingChild, setIsLoadingChild] = useState({})
    const [hasChildMore, setHasChildMore] = useState({})

    useEffect(() => {
        setFlattenedData(flattenOpened(props.data));
    }, [props.data]);

    const flattenOpened = (treeData) => {
        const result = [];
        for (let node of treeData) {
            flattenNode(node, node.depth, result);
        }
        return result;
    };



    const flattenChilds = (parentNode, collapsed, children, depth, result, indexCount) => {

        if (!collapsed && children) {
            let index = indexCount
            for (let child of children) {
                child.parent = parentNode.parent
                child.parentCollapsed = parentNode.parentCollapsed + "___" + child.id
                flattenNode(child, depth + 1, result, index);
                if (index !== undefined) index++;
            }
        }
    }


    const flattenNode = (node, depth, result, index) => {
        const { id, label, children } = node;
        let collapsed = node.collapsed === undefined ? true : node.collapsed



        result.push({
            id,
            label,
            hasChildren: node.hasChildren === undefined ? true : node.hasChildren,
            totalChildren: node.totalChildren || 0,
            numChild: index || node.numChild,
            depth,
            collapsed,
            parent: node.parent,
            parentCollapsed: node.parentCollapsed || ""
        });
        flattenChilds(node, collapsed, children, depth, result, 1)
    };

    const onOpen = async node => {

        //Si el nodo esta cerrado, comprobamos si tiene hijos 
        if (node.collapsed) {
            if (node.hasChildren && (node.children === undefined || node.children === null)) {
                let newChildNodes = await props.fetchMoreChildNodes(node.id, 0)
                if (newChildNodes.length === 0) {
                    node.hasChildren = false
                    setHasChildMore((prev) => {
                        return { ...prev, [node.id]: false }
                    })
                }
                else {

                    const updatedTreeData = flattenedData.map(fnode => {
                        if (fnode.id === node.id) {
                            return {
                                ...fnode,
                                children: [...newChildNodes],
                                totalChildren: newChildNodes.length,
                                parent: node.id,
                                collapsed: false,
                                parentCollapsed: node.parentCollapsed || node.id
                            };
                        }
                        return fnode;
                    });
                    setHasChildMore((prev) => {
                        return { ...prev, [node.id]: true }
                    })

                    let fopened = flattenOpened(updatedTreeData)
                    setFlattenedData(fopened);
                }
            } else {
                const updatedTreeData = flattenedData.map(fnode => {
                    if (fnode.id === node.id) {
                        return {
                            ...fnode,
                            collapsed: false
                        };
                    }
                    return fnode;
                });
                let fopened = flattenOpened(updatedTreeData)
                setFlattenedData(fopened);
            }
        } else {
            const updatedTreeData = flattenedData.filter(fnode => {
                if (fnode.id === node.id) {
                    fnode.collapsed = true
                    fnode.children = null
                    fnode.totalChildren = 0
                    return fnode
                }

                if (!fnode.parentCollapsed.includes(node.id)) {
                    return {
                        ...fnode
                    };
                }
            });
            let fopened = flattenOpened(updatedTreeData)
            setFlattenedData(fopened);
        }
    };

    const onSelect = (e, node) => {
        // Aquí podrías manejar acciones al seleccionar un nodo.
    };

    const loadMoreNodes = useCallback(async (dataMore) => {
        const { visibleStopIndex } = dataMore
        const lastNode = flattenedData[visibleStopIndex];
        //Comprobamos si es un nodo hijo o un padre
        if (lastNode.depth === 1 && !isLoadingRoot && visibleStopIndex >= flattenedData.length - 5) {

            setIsLoadingRoot(true)
            //Comprobamos si ya no hay que cargar mas nodos root
            if (hasRootMore) {
                const newRootNodes = await props.fetchMoreRootNodes();
                if (newRootNodes.length > 0) {
                    let fopened = flattenOpened(newRootNodes)
                    setFlattenedData(prev => [...prev, ...fopened]);
                } else setHasRootMore(false)
                setIsLoadingRoot(false)
            }
        } else if (lastNode.depth !== 1 && !isLoadingChild[lastNode.parent]) {

            setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: true } })
            if (hasChildMore[lastNode.parent]) {

                //Buscamos el indice de ese hijo en el padre, 
                //Buscamos el nodo padre.               
                const parentNode = flattenedData.find(objeto => objeto.id === lastNode.parent);
                console.log('Soy el hijo ' + lastNode.numChild + ' de ' + parentNode.totalChildren)

                if (lastNode.numChild >= parentNode.totalChildren) {
                    const indexOfLastNode = flattenedData.findIndex(objeto => objeto.id === lastNode.id)
                    console.log('Buscar mas nodos hijo ...' + lastNode.parent)
                    const newChildNodes = await props.fetchMoreChildNodes(lastNode.parent);
                    console.log('Tiene ' + newChildNodes.length + 'hijos')
                    console.log('Añadir los hijos al final de ' + indexOfLastNode)

                    if (newChildNodes.length > 0) {

                        let newChildNodesFlat = []
                        flattenChilds(parentNode, false, newChildNodes, parentNode.depth, newChildNodesFlat, lastNode.numChild + 1)
                        let init = flattenedData.slice(0, indexOfLastNode + 1)
                        let end = flattenedData.slice(indexOfLastNode + 1)
                        const treeDataWithChildren = [...init, ...newChildNodesFlat, ...end]

                        const updatedTreeData = treeDataWithChildren.map(node => {
                            if (node.id === lastNode.parent) {
                                return {
                                    ...node,
                                    totalChildren: parentNode.totalChildren + newChildNodes.length,
                                };
                            }
                            return node;
                        });
                        let fopened = flattenOpened(updatedTreeData)
                        setFlattenedData(fopened);
                    } else {
                        setHasChildMore((prev) => { return { ...prev, [lastNode.parent]: false } })
                    }

                }
                setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: false } })
            }
        }

    }, [flattenedData, isLoadingRoot, hasRootMore, isLoadingChild, hasChildMore]);

    const itemData = getItemData(onOpen, onSelect, flattenedData);

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    className="List"
                    height={height}
                    itemCount={flattenedData.length}
                    itemSize={32}
                    width={width}
                    itemKey={index => flattenedData[index].id}
                    itemData={itemData}
                    onItemsRendered={loadMoreNodes}
                >
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
};

// // Datos iniciales para el árbol.
// const initialData = generateNodes('Root', 0, 20);

// Render del componente SpeedTree
const MartaTest = () => {

    const [data, setData] = useState([])

    //Variables para almacenar el total de nodos pedidos (root y child nodes).
    const [totalRootNodesFetched, setTotalRootNodesFetched] = useState(0)
    const MAX_ROOT_NODES = 100; // Máximo de 100 root nodes.

    const [totalChildNodesFetched, setTotalChildNodesFetched] = useState({})
    const MAX_CHILD_NODES = 50; // Máximo de 50 child nodes por nodo padre.

    // Función simulada para generar nodos.
    const generateNodes = (prefix, startIndex, count, parent = 0, depth = 1) => {
        return Array.from({ length: count }, (_, i) => ({
            id: `${prefix}-${startIndex + i + 1}`,
            label: `${prefix} Node ${startIndex + i + 1}`,
            children: null, // Los nodos con profundidad 2 pueden tener hijos.
            depth: depth,
            parent: parent
        }));
    };



    // Función simulada para fetchMoreRootNodes, limitada a 100 nodos.
    const fetchMoreRootNodes = async () => {
        console.log("Fetching more root nodes from", totalRootNodesFetched);

        // Si ya se alcanzó el máximo de 100 root nodes, no devolvemos más.
        if (totalRootNodesFetched >= MAX_ROOT_NODES) {
            return [];
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                // Calculamos cuántos nodos se pueden pedir sin superar el máximo.
                const nodesToFetch = Math.min(20, MAX_ROOT_NODES - totalRootNodesFetched);
                const newNodes = generateNodes('Root', totalRootNodesFetched, nodesToFetch);

                setTotalRootNodesFetched(totalRootNodesFetched + newNodes.length); // Actualizamos el total de nodos root pedidos.
                resolve(newNodes);
            }, 1000); // Simula un retraso de 1 segundo.
        });
    };

    // Función simulada para fetchMoreChildNodes, limitada a 50 nodos hijos por nodo padre.
    const fetchMoreChildNodes = async (parentId, startIndex) => {
        // Inicializamos el contador para el nodo padre si no existe.
        if (startIndex === 0 || !totalChildNodesFetched[parentId]) {
            totalChildNodesFetched[parentId] = 0;
        }
        console.log(`Fetching more child nodes for parent ${parentId} from index ${totalChildNodesFetched[parentId]}`);

        // Si ya se alcanzó el máximo de 50 child nodes para este nodo, no devolvemos más.
        if (totalChildNodesFetched[parentId] >= MAX_CHILD_NODES) {
            return [];
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                // Calculamos cuántos nodos hijos se pueden pedir sin superar el máximo.
                const nodesToFetch = Math.min(15, MAX_CHILD_NODES - totalChildNodesFetched[parentId]);
                const newNodes = generateNodes(`Child of ${parentId}`, totalChildNodesFetched[parentId], nodesToFetch, parentId);

                setTotalChildNodesFetched((prev) => {
                    return { ...prev, [parentId]: prev[parentId] + newNodes.length }
                }); // Actualizamos el total de hijos pedidos para este nodo padre.
                resolve(newNodes);
            }, 1000); // Simula un retraso de 1 segundo.
        });
    };



    useEffect(() => {

        // Datos iniciales para el árbol.
        fetchMoreRootNodes().then((result) => { setData(result) });

    }, [])


    return (

        <div style={{ height: "400px" }}>
            <SpeedTree data={data} fetchMoreChildNodes={fetchMoreChildNodes} fetchMoreRootNodes={fetchMoreRootNodes} />
        </div>
    )
}

export default MartaTest;

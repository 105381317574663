import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import LayoutSection from '../NavigationAndLayouts/LayoutSection'
import BasicTableServer from './BasicTableServer'
import FilterReport from './FilterReport'
import utils from "../../utils";
import { generateColumnsDef, getRendersInfo } from '../../libs/utilsReports'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const Report = (props) => {

    const initialReportProperties = () => {
        let properties = {}

        //leemos configuración de visualización de filtros
        if (props.viewFilter !== undefined) {
            let sessionViewFilter = utils.getSessionItem("ReportFilterView-" + props.viewFilter);
            if (sessionViewFilter !== undefined && sessionViewFilter != null) {
                properties.viewFilter = JSON.parse(sessionViewFilter);
            }
        }

        if (props.db === undefined) {
            if (utils.getSessionItem("current-user") !== undefined && utils.getSessionItem("current-user") !== null) {
                properties.db = utils.getSessionItem("current-user").split(".")[0];
            }
        }
        if (props.userId === undefined) {
            if (utils.getSessionItem("current-user") !== undefined && utils.getSessionItem("current-user") !== null) {
                properties.userId = utils.getSessionItem("current-user").split(".")[1];
            }
        }
        if (props.callFilter !== undefined && props.callFilter !== '') {

            properties.callFilter = props.callFilter

        }

        properties.limit = props.limit || 200
        properties.view = props.view || 'table'
        if (props.location !== undefined)
            properties.path = props.location.pathname
        properties.sort = ''
        properties.order = ''
        properties.rowsPerPage = properties.limit
        properties.page = 0
        return properties
    }

    const initialReportFilterData = () => {
        let initFilter = ""
        if (props.filterId !== undefined) {
            initFilter = utils.getSessionItem("ReportFilter-" + props.filterId);
        }
        if (utils.getSessionItem("ReportFilter-" + props.reportId) !== undefined && utils.getSessionItem("ReportFilter-" + props.reportId) !== null) {
            initFilter = utils.getSessionItem("ReportFilter-" + props.reportId);
        }
        if (initFilter === undefined || initFilter === 'undefined' || initFilter === null || initFilter === "") {
            initFilter = [];
        } else {
            initFilter = JSON.parse(initFilter);
        }
        return initFilter
    }

    const [configReport, setConfigReport] = useState()
    const [filterData, setFilterData] = useState(initialReportFilterData())
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [reportProperties, setReportProperties] = useState(initialReportProperties())
    const [loading, setLoading] = useState(false)
    const [prevToken, setPrevToken] = useState("")
    const [nextToken, setNextToken] = useState("")
    const [pageCount, setPageCount] = useState(0)
    const [filterHTML, setFilterHTML] = useState(initialReportFilterData())
    const [validationFilter, setValidationFilter] = useState(true)





    var view = props.view || 'table';
    const { t } = props;

    useEffect(() => {
        setReportProperties((prevState) => ({ ...prevState, ['callFilter']: props.callFilter }))
    }, [props.callFilter])

    const onChangeFilter = (filter, validateForm) => {
        if (props.onHandlerCallFilter !== undefined) {
            props.onHandlerCallFilter(filter)
        }
        setFilterData(filter)
    }

    const executeReportHTML = (filter) => {

        if (filter === 0) filter = [...filterData]
        setFilterHTML(filter)
    }

    if (props.windowRef !== undefined) {
        window[props.windowRef] = { executeReport: executeReportHTML };
    }

    const findDatesInFilter = (dateFilterName) => {
        return filterData.find((f) => {
            if (dateFilterName === undefined && (f.type === 'date_range' || f.type === 'time_range')) return f
            else if (dateFilterName !== undefined && (f.name = dateFilterName)) {
                return f
            }
        })
    }
    const handleDetailsRow = (evt, row) => {
        if (configReport.details_grant === undefined || utils.isGranted(configReport.details_grant)) {
            // //Si nos definen en el report
            let rowKey = ""
            let rowData = row.original
            let concatUrlDetails = ""
            utils.setSessionItem("current_row_" + props.reportId, JSON.stringify(rowData))

            //existe un rowId definido por propiedad cuando instaciamos <Report rowId=""
            if (props.rowId !== undefined) {
                rowKey = props.rowId + "_" + rowData[props.rowId]
            }
            else {   //Comprobamos que hay un detalle definido
                if (configReport.details !== undefined && configReport.details.length === undefined) {
                    //Comprobamos que keys hay que pasar al detalle
                    if (configReport.details.key != undefined) {
                        configReport.details.key.split(",").forEach(keyVal => {
                            if (rowKey.length > 0) { rowKey += "_" }
                            rowKey += rowData[keyVal]
                        });
                    }
                    //Comprobamos si hay que pasar el user
                    if (configReport.details.user != undefined) {
                        rowKey += "__" + rowData[configReport.details.user]
                    }
                    if (configReport.details.rest_key !== undefined) {
                        configReport.details.rest_key.split(",").forEach(keyVal => {
                            concatUrlDetails += "/" + rowData[keyVal]
                        });
                    }
                }

            }

            if (props.handleClick !== undefined) {

                props.handleClick({ currentTarget: { id: rowKey } })
                //props.handleClick(rowData)
            } else if (props.handleClickRowComplete !== undefined) {
                props.handleClickRowComplete(rowData)
            } else {
                if (configReport.details !== undefined && configReport.details.length === undefined) {
                    //Comprobamos que necesitamos user
                    let idAux
                    if (configReport.details.user !== undefined) {
                        idAux = rowKey.split("__")
                        let id = idAux[0]
                        utils.setSessionItem("current-" + configReport.details.key, id)
                        let userAux = idAux[1]
                        utils.setSessionItem("current-user", userAux)
                    } else {
                        idAux = rowKey.split("_")
                        let id = idAux.join("_")
                        utils.setSessionItem("current-" + configReport.details.key, id)
                    }
                    //Comprobamos si es filtro para un subreport                
                    if (configReport.details.subreport) {
                        let filter = []
                        let i = 0;
                        idAux = rowKey.split("_")
                        let datesFilter = findDatesInFilter(configReport.details.dateFilter)
                        if (datesFilter !== undefined) filter.push(datesFilter)
                        configReport.details.key.split(",").forEach((element) => {
                            let criteria = {
                                "field": "t." + element,
                                "type": "=",
                                "value": idAux[i++]
                            }
                            filter.push(criteria)
                        })

                        //Preguntamos por el extra-filter
                        if (configReport.details.extra_filter !== undefined) {
                            let criterias = JSON.parse(configReport.details.extra_filter)
                            for (let i in criterias) {
                                let criteria = criterias[i];
                                filter.push(criteria)
                            }
                        }

                        utils.setSessionItem("ReportFilter-" + configReport.details.subreport, JSON.stringify(filter))
                    }

                    if (configReport.details.new_window) {
                        window.open(configReport.details.page + concatUrlDetails, "_blank")
                    } else {
                        props.history.push(configReport.details.page + concatUrlDetails)
                    }
                }
            }
        }

    }

    const handleNew = (evt) => {
        if (configReport.details !== undefined) {
            utils.removeSessionItem("current_row_" + props.reportId)
            let newElementkeyValue = ""
            if (configReport.new_element_key !== undefined) {
                newElementkeyValue = utils.getSessionItem("current-" + configReport.new_element_key)
                newElementkeyValue = newElementkeyValue + "_-1"
                utils.setSessionItem("current-" + configReport.details.key, newElementkeyValue)

            } else {
                utils.setSessionItem("current-" + configReport.details.key, -1);
            }

            props.history.push(configReport.details.page);
        }
    }

    const handlePageSizeChange = (newPageSize) => {
        setReportProperties((prevState) => ({ ...prevState, ['rowsPerPage']: newPageSize }))
    }

    const initPage = useCallback(
        () => {

        },
        [filterHTML],
    )

    const showLoading = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Loading"), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }
    const loadData = useCallback(async ({ customToken, sortBy }) => {

        let order = sortBy.map((orderCriteria) => {
            let ord = 'ASC'
            if (orderCriteria.desc === true) ord = 'DESC'
            ord = `${orderCriteria.id} ${ord}`
            return ord
        })

        setLoading(true);

        const result = await executeReport(customToken, filterHTML, order.join(","))
        if (result.error === undefined) {

            if (result.config !== undefined && result.config !== null) {
                setFilterData(result.config.filterData)
                setColumns(result.config.columns)
                setConfigReport(result.config.configReport)
            } else setFilterData(filterHTML)


            const { data: dataResult, pageCount, prevToken, nextToken } = result

            setPageCount(pageCount)
            setPrevToken(prevToken)
            setNextToken(nextToken)
            setData(dataResult)
            setReportProperties((prevState) => ({
                ...prevState,
                ...result.reportProperties
            }))

        }
        else {
            if (props.showAlert) {
                props.showAlert(t("Report Error"),
                    result.error.description)
            }
        }
        setLoading(false);

    }, [reportProperties.rowsPerPage, filterHTML]);

    async function executeReport(limit, filter, newSort, newRowsPerPage) {

        let sort = newSort || reportProperties.sort
        let rowsPerPage = newRowsPerPage || reportProperties.rowsPerPage
        let limitReport = limit


        let adminClient = new AdminClient()
        setLoading(true)
        showLoading()
        try {
            let msg = await adminClient.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), props.reportId, filter, reportProperties.userId, reportProperties.db, "limit " + limitReport + "," + (rowsPerPage), sort, reportProperties.callFilter);
            closeLoading()
            setLoading(false)
            if (msg.result === "OK") {
                let config;
                if (configReport === undefined) {
                    await getRendersInfo(msg.data.def)
                    config = { columns: generateColumnsDef(msg.data.def, t, props.extraData, props.showAlert, props.handleCloseAlert), filterData: msg.data.filter, configReport: msg.data.def.config }
                }

                let dataResult = msg.data.data
                let pageCount
                if (msg.data.total_rows !== undefined) {
                    pageCount = Math.ceil(msg.data.total_rows / reportProperties.rowsPerPage)
                } else {
                    pageCount = Math.ceil(limitReport / reportProperties.rowsPerPage) + 1
                }
                return {
                    data: dataResult, pageCount: pageCount,
                    prevToken: limitReport - reportProperties.rowsPerPage, nextToken: limitReport + reportProperties.rowsPerPage,
                    config: config, reportProperties: {
                        sort: sort,
                        limit: limitReport,
                        totalRows: msg.data.total_rows
                    }
                }
            }
        } catch (error) {
            closeLoading()
            setLoading(false)
            return { error: error }
        }
    }

    function removeNoUseLikeFilter() {

        let aux = filterData.filter((f) => {
            if (f.useLikeFilter === undefined || f.useLikeFilter === true) {
                return f
            }
        })
        return aux;

    }

    function generateCSVURL() {
        showLoading()
        let clientAPI = new AdminClient();
        let user = JSON.parse(utils.getSessionItem("user"))
        clientAPI.getCSVReport(user.session, utils.getSessionItem("Company"), props.reportId, removeNoUseLikeFilter(filterData), reportProperties.userId, reportProperties.db, "", user.lang.split("-")[0], reportProperties.callFilter).then(


            function (resp) {
                closeLoading()
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'text/csv; charset=UTF-8' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = props.reportId + ".csv";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );
    }

    function generateExcelURL() {
        showLoading()
        let clientAPI = new AdminClient();
        let user = JSON.parse(utils.getSessionItem("user"))


        clientAPI.getExcelReport(user.session, utils.getSessionItem("Company"), props.reportId, removeNoUseLikeFilter(filterData), reportProperties.userId, reportProperties.db, "", user.lang.split("-")[0], reportProperties.callFilter).then(


            function (resp) {
                closeLoading()
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'application/xls' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = props.reportId + ".xls";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );

    }

    function generatePDFURL() {
        showLoading()
        let clientAPI = new AdminClient();
        let user = JSON.parse(utils.getSessionItem("user"))
        clientAPI.getPDFReport(user.session, utils.getSessionItem("Company"), props.reportId, removeNoUseLikeFilter(filterData), reportProperties.userId, reportProperties.db, "", user.lang.split("-")[0], reportProperties.callFilter).then(


            function (resp) {
                closeLoading()
                let a = document.createElement("a");
                var fileURL = URL.createObjectURL(resp);
                a.href = fileURL
                a.download = props.reportId + ".pdf";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );
    }
    function showFilterReport(value) {

        setReportProperties((prevState) => ({ ...prevState, ['showFilterConf']: !value }))
    }

    const dataHeader = useMemo(() => {
        let dataHeader = {}

        if (configReport !== undefined) {
            dataHeader.backLink = props.historyBack ? "goBackHistory" : ''
            dataHeader.title = t(props.title || configReport.table.title)
            let buttons = []

            let allowFilterView = true
            if ((reportProperties.viewFilter !== undefined && reportProperties.viewFilter.length === 0)) {
                allowFilterView = false;
            }


            if (configReport.csv)
                if (validationFilter) buttons.push({ name: "", handle: generateCSVURL, icon: <FontAwesomeIcon icon="file-csv" /> })
                else buttons.push({ name: "", handle: () => { props.showAlert(t(props.title || configReport.table.title), t('Filter has errors')) }, icon: <FontAwesomeIcon icon="file-csv" /> })
            if (configReport.excel)
                if (validationFilter) buttons.push({ name: "", handle: generateExcelURL, icon: <FontAwesomeIcon icon="file-excel" /> })
                else buttons.push({ name: "", handle: () => { props.showAlert(t(props.title || configReport.table.title), t('Filter has errors')) }, icon: <FontAwesomeIcon icon="file-excel" /> })
            if (configReport.pdf)
                if (validationFilter) buttons.push({ name: "", handle: generatePDFURL, icon: <FontAwesomeIcon icon="file-pdf" /> })
                else buttons.push({ name: "", handle: () => { props.showAlert(t(props.title || configReport.table.title), t('Filter has errors')) }, icon: <FontAwesomeIcon icon="file-pdf" /> })

            if (allowFilterView && configReport.filters !== undefined && configReport.filters !== null && configReport.filters.length > 0)
                buttons.push({
                    name: "", handle: () => {
                        showFilterReport(reportProperties.showFilterConf)
                    }, icon: <FontAwesomeIcon icon="cog" />
                })
            if (configReport.details !== undefined && configReport.details !== null && configReport.details.length > 0) {
                if (configReport.details !== props.path) {
                    buttons.push({ name: "", url: configReport.details, icon: <FontAwesomeIcon icon="list" /> })
                }
            }
            if (configReport.new_element !== undefined && (configReport.new_element_grant === undefined || utils.isGranted(configReport.new_element_grant, utils.getSessionItem("Company"))))
                buttons.push({ name: "", handle: handleNew, icon: <FontAwesomeIcon icon="plus-square" /> })

            if (buttons.length > 0)
                dataHeader.buttons = buttons

        }
        return dataHeader
    }, [configReport, filterData, reportProperties.showFilterConf, validationFilter, reportProperties.callFilter])

    //Configuarción Cabecera Sección

    return (<>
        <LayoutSection {...props} className={props.className} report={props.reportDoc} dataHeader={(dataHeader !== null) ? dataHeader : ""}>
            {configReport !== undefined && reportProperties.showFilterConf &&
                <FilterReport filterConfig={configReport.filters} filterData={filterData} viewFilter={reportProperties.viewFilter} executeReport={executeReportHTML} handleFilterData={onChangeFilter} reportId={props.reportId} showAlert={props.showAlert} setValidationFilter={setValidationFilter} />
            }

            <BasicTableServer
                columns={columns}
                data={data}
                loading={loading}
                pageCount={pageCount}
                fetchData={loadData}
                rowHandle={handleDetailsRow}
                columnRowHandle={configReport?.details?.columnDetail}
                nextToken={nextToken}
                prevToken={prevToken}
                totalRows={reportProperties.totalRows}
                pageSize={reportProperties.rowsPerPage}
                handlePageSizeChange={handlePageSizeChange}
                initPage={initPage}
            />
        </LayoutSection>
    </>
    )
}

export default withTranslation()(withRouter(Report))
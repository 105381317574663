import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';



function UserAddressesDetails(props) {

    const { t } = props;


    const company = utils.getCurrentCompany().company;
    let user_id = utils.getSessionItem("current-id");
    let bd = utils.getSessionItem("current-user").split(".")[0];
    let user = utils.getSessionItem("current-user").split(".")[1];


    const [userAddress, setUserAddress] = useState({
        id: user_id !== "-1" ? user_id : null,
        db: bd,
        user: user,
        name: "",
        type: "",
        country: "",
        address: "",
        zipcode: "",
        city: "",
        state: "",
        province: "",
        comments: ""
    });

    const [newOrUpdate, setNewOrUpdate] = useState("");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    let adminClient = new AdminClient();


    useEffect(function () {
        if (user_id === "-1") {
            setNewOrUpdate("new");
        }
        else {
            adminClient.getUserAddressDetails(session, company, user, user_id).then((res) => {
                let info = res.data[0];
                setUserAddress(info);
                setNewOrUpdate("update");

            }, (err) => {
                console.log(err);
            })
        }

    }, []);


    const handleUpdate = (e) => {
        const { name, value } = e.target || e;
        let newInfo = { ...userAddress };
        newInfo[name] = value;
        setUserAddress(newInfo);
    };

    const handleDelete = () => {
        let promise = adminClient.deleteUserAddressesDetails(
            session,
            company,
            userAddress.user,
            userAddress.id,
            userAddress.db
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t("Correct"), <p>{t("Addresses deleted")}</p>);
                    goBack();

                }
            }, function (err) {
                console.log("Delete Dirección error " + err.event);
                props.showAlert(t("Error"), <p>{t("Error in delete Addresses")}</p>)
            }
        );



    };

    const handleSubmit = () => {
        if (formValidation.validate()) {

            let promise = adminClient.saveUserAddressesDetails(
                session,
                company,
                JSON.stringify(userAddress)
            );

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Correct"), <p>{t("Addresses saved")}</p>);
                    }

                }, function (err) {
                    console.log("Save Dirección error" + err);
                    props.showAlert(t("Error"), <p>{t("Error in save Addresses")}</p>)
                }
            );
        }

    };


    const goBack = () => {
        props.history.push("/support/report/user_addresses/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Addreses details"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("form-userAddressesDetails");


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="form-userAddressesDetails">

                <div className="row" >
                    <div className="col-12 col-md-6">
                        <TextField label={t("Name")} required disabled={newOrUpdate === "update"} name="name" type="text" value={userAddress.name} onChange={handleUpdate} />
                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty="address_type"
                            name="type"
                            label="Addresses type"
                            data="types"
                            basicInfoName="AddressTypes"
                            onChange={handleUpdate}
                            value={userAddress.type}
                            noEmptyValue
                        />
                    </div>
                    <div className="col-12 col-md-8">
                        <TextField label={t("Address")} name="address" type="text" value={userAddress.address} onChange={handleUpdate} />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField label={t("Zipcode")} name="zipcode" type="number" value={userAddress.zipcode} onChange={handleUpdate} />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            idProperty="country"
                            name="country"
                            label={t("Country")}
                            data="countries"
                            basicInfoName="Countries"
                            onChange={handleUpdate}
                            value={userAddress.country}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            idProperty="state"
                            name="state"
                            label={t("State")}
                            basicInfoName="States"
                            params={[{ property: "country", value: userAddress.country || "" }]}
                            onChange={handleUpdate}
                            value={userAddress.state}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            idProperty="province"
                            name="province"
                            label={t("Province")}
                            basicInfoName="Provinces"
                            params={[
                                { property: "country", value: userAddress.country || "" },
                                { property: "state", value: userAddress.state || "" }
                            ]}
                            onChange={handleUpdate}
                            value={userAddress.province}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            idProperty="city"
                            name="city"
                            label={t("City")}
                            basicInfoName="Cities"
                            params={[
                                { property: "country", value: userAddress.country || "" },
                                { property: "state", value: userAddress.state || "" },
                                { property: "province", value: userAddress.province || "" }
                            ]}
                            onChange={handleUpdate}
                            value={userAddress.city}
                        />
                    </div>
                    <div className="col-12">
                        <TextField label={t("Comments")} name="comments" type="text" multiline value={userAddress.comments} onChange={handleUpdate} />
                    </div>
                </div>

                {utils.isGranted('SAVE_AFFILIATES') &&
                    <div className="row">

                        <div className="col-12">

                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                            <button type="button" className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button>
                        </div>
                    </div>
                }
            </form>
        </LayoutSection>
    );
}

export default withTranslation()(UserAddressesDetails);

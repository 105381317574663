import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import UserInput from '../../components/UserInput';
import BasicSelect from '../../components/basic/BasicSelect';
import Report from '../../components/basic/Report';
import DateAndTimePicker from '../../components/DateAndTimePicker';
function DepositsDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_DepositsDetails')
    const operation = utils.getSessionItem('current-operation').split(".")
    utils.setSessionItem("ReportFilter-depositLogs", JSON.stringify([{ field: "db", type: "=", value: operation[0] }, { field: "operation", type: "=", value: operation[1] }]));
    const [depositDetails, setdepositDetails] = useState({
        alias: "",
        user: "",
        db: operation[0] || "",
        operation: operation[1] || "",
        operation_date: null,
        amount: 0,
        currency: "USD",
        status: "",
        force_repay: false,


    });
    const goBack = () => {
        props.history.push('/support/report/user_deposits/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Deposit details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (operation !== undefined) {
            adminClient.getOperationDetails(session, operation[0], operation[1]).then(
                async function (result) {
                    result.data.initial_status = result.data.status;
                    setdepositDetails(result.data);
                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setdepositDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerStatus = (e) => {
        let depositDetailsAux = { ...depositDetails };
        depositDetailsAux.status = e.value;
        depositDetailsAux.next_status = e.value;
        setdepositDetails(depositDetailsAux);
    };

    const update = (evt) => {
        if (formValidation.validate()) {
            adminClient.updateOperation(
                session,
                JSON.stringify(depositDetails)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Deposit details'), t('Updated succesfull'));
                        window["reportLogs"].executeReport(0);
                    } else {
                        props.showAlert(t('Deposit details'), t('Updating error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Deposit details'), t('Updating error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_DepositsDetails">
                <div className="row">
                    <div className="col-12 col-md-4 col-xxl-2">
                        <UserInput alias={depositDetails.alias} user={depositDetails.user}></UserInput>
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Identifier")} type="text" value={depositDetails.db + "." + depositDetails.operation} />
                    </div>

                    <div className="col-12 col-md-4 col-xxl-2">
                        <DateAndTimePicker
                            disabled
                            renderInput={(props) => <TextField  {...props} />}
                            label={t("Created date")}
                            value={utils.formatDate(depositDetails.operation_date || '')}
                            showTime={true}
                        />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Amount")} type="text" value={utils.formatCurrency(depositDetails.amount / 100, depositDetails.currency)} />
                    </div>
                    <div className="col-12 col-md-3 col-xxl-2">
                        <BasicSelect
                            disabled={!utils.isGranted('UPDATE_DEPOSIT_STATUS')}
                            label={t("Current status")}
                            idProperty="status"
                            nameProperty="status"
                            data="status"
                            name="status"
                            basicInfoName="DepositsStatus"
                            onChange={onChangeHandlerStatus}
                            value={depositDetails.status}
                            translate={{ property: "status", prefix: "DEPOSIT_STATUS_" }}
                        />
                    </div>
                    {depositDetails.initial_status === 'NEW' && depositDetails.status === 'SUCCESS' && utils.isGranted('CONFIRM_DEPOSIT') &&
                        <div className="col-12 col-md-4 col-lg-2">
                            <FormControlLabel
                                control={<Checkbox
                                    checked={depositDetails.force_repay}
                                    onChange={(evt) => {
                                        onChangeHandler({ name: "force_repay", value: evt.target.checked })
                                    }}
                                />}
                                label={t("Force repay")} />
                        </div>
                    }
                    <div className="col-12 ">
                        <TextField multiline label={t("New comment")} type="text" name="comments" onChange={onChangeHandler} />
                    </div>
                </div>
                <div className="row">
                    <div className="col 12 ">
                        <Report className="max_width_100" windowRef={"reportLogs"} reportId="operation_logs" limit={3} filterId="depositLogs"></Report>
                    </div>
                    <div className="row">
                        <Report className="max_width_100" windowRef={"reportLogs"} reportId="transactions_communication_logs" limit={3} filterId="depositLogs"></Report>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={update}
                    >
                        {t('Update')}
                    </button>

                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(DepositsDetails);
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import BasicSelect from '../../components/basic/BasicSelect';
import File from '../../components/basic/File';
import moment from "moment";
import Tab from '../../components/basic/BasicTabs';
import Report from "../../components/basic/Report";

function UserFileDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const companyDetails = utils.getCompany(company);
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UserFileDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const file = utils.getSessionItem('current-file')
    const userDb = utils.getSessionItem("current-user").split(".")[0]
    const userId = utils.getSessionItem("current-user").split(".")[1]

    const [fileDetails, setFileDetails] = useState({
        db: userDb,
        user: userId,
        company: company,
        status: "PENDING",
        side: "",
        type: "",
        expiration_date: null,
        path: "",
        comments: null,
        extra: null,
        reviewer: null,
        review_date: null,
        payment_account: null
    });

    const [paymentAccounts, setPaymentAccounts] = useState([])
    const goBack = () => {
        props.history.goBack();
    };
    const dataHeader = {
        backLink: goBack,
        title: t('File details'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    var filter_logs = [
        { "field": "file", "operator": "=", "value": utils.getSessionItem("current-file").split(".")[2] }
    ];
    utils.setSessionItem("ReportFilter-user_files_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-user_files_logs", JSON.stringify([{ field: "file", visibility: "hidden" }]));

    useEffect(async function () {
        await adminClient.getReport(
            session, company, "user_payment_accounts", [], userId, userDb,
            "limit 0 ,1000 ", "created_date desc"
        ).then(
            function (result) {
                let data = result.data.data;
                data.forEach(element => {
                    if (element.name === null) {
                        element.name = JSON.stringify(element.payment_account)
                    }
                });
                setPaymentAccounts(data)
            },
            function (err) {
                console.error(err);
            }
        )
        if (file !== undefined && file !== '-1') {
            let fileId = utils.getSessionItem("current-file").split(".");
            adminClient.getFileDetails(
                session,
                fileId[0],
                userId,
                fileId[2]
            ).then(
                function (result) {
                    if (result.data.comments === undefined || result.data.comments === null) {
                        result.data.comments = "";
                    }
                    setFileDetails(result.data);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');

        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setFileDetails((prevState) => ({ ...prevState, [name]: value }));
    };
    const saveFile = (urls) => {
        if (formValidation.validate()) {

            let fileData = { ...fileDetails };
            fileData.path = urls[0];
            let adminClient = new AdminClient();
            adminClient
                .newFile(
                    session,
                    JSON.stringify(fileData),
                    company
                )
                .then(
                    (result) => {
                        let fileAux = result.data;
                        utils.setSessionItem(
                            "current-file",
                            fileAux.db + "." + fileAux.user + "." + fileAux.file
                        );
                        fileAux.log_user = JSON.parse(utils.getSessionItem("user")).user
                        setFileDetails(fileAux);
                        setAddOrUpdate('update');
                        window['windowRefLogs'].executeReport(0);
                    },
                    (err) => {
                        console.log("Error salvando fichero");
                    }
                );
        }
    }


    const save = (evt) => {
        if (formValidation.validate()) {

            adminClient.saveFile(
                session,
                JSON.stringify(fileDetails),
                company
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('File details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        window['windowRefLogs'].executeReport(0);
                    } else {
                        props.showAlert(t('File details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('File details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    const handlePreDelete = () => {
        props.showAlert(
            t('Delete'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove()
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }
    const remove = (evt) => {
        adminClient.deleteFile(
            session,
            JSON.stringify(fileDetails),
            company
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('File details'), t('Deleted succesfull'))
                    goBack()
                } else {
                    props.showAlert(t('File details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('File details'), t('Deleting error ') + error.description)
            }
        )
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserFileDetails">
                <div className="row">
                    <div className="col-12 col-md-6 col-xxl-4">
                        <TextField
                            InputLabelProps={{
                                shrink:
                                    fileDetails !== null &&
                                    fileDetails.db + "." + fileDetails.file !==
                                    undefined,
                            }}
                            label={t("ID")}
                            type="text"
                            disabled
                            value={
                                addOrUpdate === "add"
                                    ? ""
                                    : fileDetails.db + "." + fileDetails.file
                            }
                        />
                    </div>

                    <div
                        className={
                            addOrUpdate === "add" ? "hidden" : "col-12 col-md-6 col-xxl-4"
                        }
                    >
                        <DateAndTimePicker
                            label={t("Created date")}
                            disabled
                            value={utils.formatDate(fileDetails.created_date)}
                            showTime={true}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <DateAndTimePicker
                            label={t("Expiration date")}
                            disabled={fileDetails.type !== "IDENTITY" || !utils.isGranted("SAVE_USER_FILES")}
                            value={fileDetails.expiration_date}
                            required={fileDetails.type === "IDENTITY" && companyDetails.config.documentation !== undefined && companyDetails.config.documentation.identity.required_expiration_date && fileDetails.expiration_date === null}
                            showTime={false}
                            callBackFunction={(value, fieldName) => {
                                if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                onChangeHandler({ name: "expiration_date", value: value })
                            }}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-xxl-4">
                        <BasicSelect
                            idProperty="type"
                            name="type"
                            label={t("Type")}
                            nameProperty="type"
                            basicInfoName="FilesTypes"
                            onChange={onChangeHandler}
                            value={fileDetails.type}
                            translate={{ property: "type", prefix: "FILE_TYPE_" }}
                        />
                    </div>
                    {fileDetails.type === "BANK" &&
                        <div className="col-12 col-md-6 col-xxl-4">
                            <BasicSelect
                                idProperty="payment_account"
                                label={t("Payment_account")}
                                name="payment_account"
                                addOpts={paymentAccounts}
                                onChange={onChangeHandler}
                                value={fileDetails.payment_account}
                            />
                        </div>

                    }
                    <div className="col-12 col-md-6 col-xxl-4">
                        <BasicSelect
                            idProperty="subtype"
                            name="sub_type"
                            nameProperty="subtype"
                            label={t("Subtype")}
                            basicInfoName="FilesSubTypes"
                            filters={[
                                {
                                    property: "type",
                                    value: fileDetails.type,
                                    initVal: true,
                                },
                            ]}
                            onChange={onChangeHandler}
                            value={fileDetails.sub_type}
                            translate={{
                                property: "subtype",
                                prefix: "FILE_SUB_TYPE_",
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <BasicSelect
                            idProperty="side"
                            name="side"
                            nameProperty="side"
                            label={t("Side")}
                            basicInfoName="FilesSide"
                            onChange={onChangeHandler}
                            value={fileDetails.side}
                            translate={{ property: "side", prefix: "FILE_SIDE_" }}
                        />
                    </div>

                    <div className="col-12  col-md-12 col-xxl-4">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            name="status"
                            basicInfoName="FileStatus"
                            onChange={onChangeHandler}
                            value={fileDetails.status}
                            translate={{ property: "status", prefix: "FILE_STATUS_" }}
                            disabled={!utils.isGranted("SAVE_USER_FILES")}
                        />
                    </div>

                    <div
                        className={
                            addOrUpdate === "add" ? "hidden" : "col-12 col-md-6 col-xxl-4"
                        }
                    >
                        <DateAndTimePicker
                            label={t("Review date")}
                            disabled
                            value={fileDetails.review_date === null ? null : utils.formatDate(fileDetails.review_date)}
                            showTime={true}
                        />
                    </div>
                    <div
                        className={
                            addOrUpdate === "add" ? "hidden" : "col-12 col-md-6 col-xxl-4"
                        }
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: fileDetails.reviewer_name !== undefined,
                            }}
                            label={t("Reviewer")}
                            type="text"
                            disabled
                            value={fileDetails.reviewer_name}
                        />
                    </div>
                    <div className={addOrUpdate === "add" ? "hidden" : "col-12"}>
                        <TextField
                            InputLabelProps={{
                                shrink: fileDetails.path !== undefined,
                            }}
                            label={t("Path")}
                            type="text"
                            disabled
                            value={fileDetails.path}
                        />
                    </div>
                    <div className="col-12  ">
                        <TextField
                            disabled={!utils.isGranted("SAVE_USER_FILES")}
                            multiline
                            InputLabelProps={{
                                shrink: fileDetails.comments !== undefined,
                            }}
                            label={t("Comments")}
                            type="text"
                            name="comments"
                            onChange={onChangeHandler}
                            value={fileDetails.comments}
                        />
                    </div>
                </div>

            </form>
            <div className="row">
                <div className={addOrUpdate === "add" ? "hidden" : "col-12 nb-3"}>
                    {utils.isGranted("SAVE_USER_FILES") && (<>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t("Save")}
                        </button>
                        {fileDetails.log_user !== undefined && fileDetails.log_user !== 0 && fileDetails.log_user === JSON.parse(utils.getSessionItem("user")).user &&
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handlePreDelete}
                            >
                                {t("Delete")}
                            </button>
                        }
                    </>)}
                </div>

                <div className="col-md-12">
                    <Tab key="tabContentGroupsTags" active={0}>
                        <Tab.TabPane key={`TabContentView`} tab={t("Details")}>
                            {addOrUpdate === "add" && utils.isGranted("SAVE_USER_FILES") ? (
                                <div className="col-md-12">
                                    <File
                                        dropEnable={true}
                                        showFile={true}
                                        showError={true}
                                        numFiles={1}
                                        viewerEnable={true}
                                        deleteEnable={true}
                                        disabledUpdatedPopup={true}
                                        saveData={(urls) => {
                                            saveFile(urls);
                                        }}
                                        getFileUrl={(data) => {
                                            return (
                                                company +
                                                "/" +
                                                Math.floor(fileDetails.user / 1000) +
                                                "/" +
                                                fileDetails.user +
                                                "/" +
                                                data.fileName
                                            );
                                        }}
                                        type={"USER_FILE"}
                                        owner={JSON.stringify({ user: fileDetails.user })}
                                        showAlert={props.showAlert}
                                        onlyUpload={true}
                                        typeFiles={["image", "file"]}
                                    />
                                </div>
                            ) : (
                                <File
                                    onlyViewer={true}
                                    inputFile={fileDetails.file && adminClient.getFile(
                                        session,
                                        fileDetails
                                    )}
                                    inputFileExtension={fileDetails.path.substring(fileDetails.path.lastIndexOf('.'))}
                                />
                            )}
                        </Tab.TabPane>
                        <Tab.TabPane key={`TabContentPlay`} tab={t("Logs")}>
                            <Report windowRef="windowRefLogs" reportId="user_files_logs" viewFilter="user_files_logs" limit={10}></Report>
                        </Tab.TabPane>
                    </Tab>


                </div>
            </div >

        </LayoutSection >
    )
}
export default withTranslation()(UserFileDetails);
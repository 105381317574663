import { Checkbox, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import momentTimeZone from 'moment-timezone';

import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import BasicTable from '../../components/basic/BasicTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../components/basic/ProgressBar';
function Payouts(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const lang = utils.getSessionItem('calimaco-lang').split("-")[0]
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_Payouts')
    const { formValidation: formValidationMessage } = useFormValidation('Form_Payouts_Message')
    let from = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    let to = new Date();
    if (from !== null) from = momentTimeZone(from).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    if (to != null) to = momentTimeZone(to).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));

    const cleanFilter = {
        status: "",
        method: "",
        created_date_from: from, //Para que filtre desde ayer
        created_date_to: to,
        playerID: "",
        payoutID: "",
        amountFrom: "",
        amountTo: "",
        currency: companyDetails.currencies.length === 1 ? companyDetails.currencies[0] : "",
        currencyReport: companyDetails.currencies.length === 1 ? companyDetails.currencies[0] : "",
        bankName: ""
    }
    const [filter, setFilter] = useState(cleanFilter);
    const [excelFilter, setExcelFilter] = useState(null)
    const initialCheckboxFilter = {
        verified: 2,
        low_odds: 2,
        promotions_redeemed: 2,
        magic_number: 2,
        cashouts: 2,
        depositante: 2,
    }
    const [valueFilter, setValueFilter] = useState(initialCheckboxFilter)

    const [operations, setOperations] = useState([]);
    const [nextStatus, setNextStatus] = useState([]);
    const [selectedOperations, setSelectedOperations] = useState([]);
    const [progressBar, setProgressBar] = useState({
        show: false,
        percentage: '0%',
        textProcess: t('Processing')
    })
    const [changeStatus, setChangeStatus] = useState({
        to_status: "",
        rejectReason: "",
        rejectClientMessage: ""
    });
    const [groups, setGroups] = useState([]);
    const [generalColumnsDef, setGeneralColumnsDef] = useState([]);

    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Manage payouts'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        adminClient.getGroups(session, company).then(
            function (msg) {
                setGroups(msg.groups);
            }, function (err) {
                console.log("Payout error " + err.code);
            }
        );

    }, []);

    const handleCheck = (evt, operation) => {
        let checked = evt.target.checked;
        if (checked) {
            setSelectedOperations((prevOps) => [...prevOps, operation]);

        } else {
            setSelectedOperations((prevOps) => prevOps.filter((prevOp) => prevOp !== operation));
        }
    }

    const handleCheckAll = (evt, operations) => {
        let checked = evt.target.checked;
        const operationsIds = operations.map((objeto) => objeto.operation);
        if (checked) {
            setSelectedOperations(operationsIds);

        } else {
            setSelectedOperations([]);
        }
    }

    const CheckFilter = (props) => {
        const { filterValue, setFilter, Header, id } = props.column

        const handleCheckboxChange = () => {
            let value = valueFilter[id];
            let nextState = (value - 1) // Alternar entre los tres estados
            if (nextState === -1) nextState = 2;
            setValueFilter((prevState) => ({ ...prevState, [id]: nextState }));
            setFilter(nextState)
        };

        return (
            <div className="checkbox-container" onClick={() => {
                if (selectedOperations.length > 0) {
                    setSelectedOperations([])
                }
                handleCheckboxChange();
            }}>
                {(valueFilter[id]) === 2 && <span className="empty">☐</span>}
                {(valueFilter[id]) === 1 && <span className="tick">☑</span>}
                {(valueFilter[id]) === 0 && <span className="cross">☒</span>}
            </div>
        )
    }

    const customFilter = (rows, id, filterValue) => {
        console.log("FILTRANDO..." + id)
        let caseId = id[0];
        if (filterValue === 2) return rows;
        else {
            switch (caseId) {
                case 'verified':
                case "depositante":
                    return rows.filter((row) => {
                        return row.values[id] === filterValue;
                    })
                case "currency":
                    return rows.filter((row) => {
                        return row.values[id] === filterValue;
                    })

                case "low_odds":
                case "promotions_redeemed":
                case "magic_number":
                case "cashouts":

                    return rows.filter((row) => {
                        return row.values[id] !== filterValue;
                    })
                default:
                    return rows.filter((row) => {
                        return row.values[id] === filterValue;
                    })
            }

        }
    }

    const handleClickDetails = (db, user, operation) => {
        utils.setSessionItem("current-operation", operation);
        utils.setSessionItem("current-user", operation.split('.')[0] + "." + user);

        window.open('/cashflow/payouts/details/' + user, '_blank');

        // window.open("/cashflow/payouts/details", "calimaco_payout_details");
    }

    const handleClickLowOdds = (user, operation) => {
        adminClient.getPayoutExtraLowOdds(
            session,
            company,
            operation.split(".")[0],
            user,
            operation.split(".")[1]
        ).then(
            function (response) {
                let data = response.data;
                let columns = [
                    { Header: t("Operation"), accessor: 'operation', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Bet type"), accessor: 'bet_type', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Operation date"), accessor: 'operation_date', typeCell: 'date', filter: 'text', Filter: true },
                    {
                        Header: t("Currency"), accessor: 'currency', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            let amount = props.row.original.amount;
                            let currency = props.row.original.currency;
                            return utils.formatCurrency(amount / 100, currency)
                        }
                    },
                    { Header: t("Odds"), accessor: 'odds', typeCell: 'text', filter: 'text', Filter: true },
                ]
                props.showAlert(t('Low odds'), <BasicTable key="table-LowOdds" data={data} columns={columns} showFilterColumn={true} hidePagination />, undefined, 'm');
            }, function (err) {
                alert(err.description);
            }
        )
    }

    const handleClickPromotionsRedeemed = (user, operation) => {
        adminClient.getPayoutExtraPromotionsRedeemed(
            session,
            company,
            operation.split(".")[0],
            user,
            operation.split(".")[1]
        ).then(
            function (response) {
                let data = response.data;
                let columns = [
                    { Header: t("Promotion"), accessor: 'promotion', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Operation"), accessor: 'operation', typeCell: 'text', filter: 'text', Filter: true },
                    { Header: t("Operation date"), accessor: 'operation_date', typeCell: 'date', filter: 'text', Filter: true },
                    {
                        Header: t("Currency"), accessor: 'currency', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            let amount = props.row.original.amount;
                            let currency = props.row.original.currency;
                            return utils.formatCurrency(amount / 100, currency)
                        }
                    }
                ]
                props.showAlert(t('Redeemed promotions'), <BasicTable key="table-PromotionsRedeemed" data={data} columns={columns} showFilterColumn={true} hidePagination />, undefined, 'm');


            }, function (err) {
                alert(err.description);
            }
        )
    }

    const generateColumns = () => {
        return [
            nextStatus?.length > 0 ?
                {
                    Header: t("Select"),
                    accessor: "register",
                    typeCell: "checkbox",
                    Cell: (props) => {
                        return <Checkbox checked={selectedOperations.includes(props.row.original.operation)} onChange={(evt) => { handleCheck(evt, props.row.original.operation) }}></Checkbox>
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <Checkbox checked={props.page.length === selectedOperations.length} name="register" onChange={(evt) => { handleCheckAll(evt, props.page.map((objeto) => objeto.original)) }}></Checkbox>
                        }
                        else return true;
                    }
                }
                : null,
            {
                Header: t("ID"), accessor: 'operation', typeCell: 'text', filter: 'text', Filter: true,
                Cell: (props) => {
                    let operation = props.row.original.operation;
                    return <div className={" cursor-pointer "} style={{ display: "flex" }}
                        onClick={(evt) => handleClickDetails(props.row.original.db, props.row.original.user, props.row.original.operation, evt)}>
                        <FontAwesomeIcon icon="eye" style={{ marginRight: '5px' }} /> {" " + operation}
                    </div>
                }
            },
            { Header: t("Status"), accessor: 'status', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
            { Header: t("Operation date"), accessor: 'operation_date', typeCell: 'time', filter: 'time', Filter: true },
            { Header: t("Player"), accessor: 'user', typeCell: 'text', filter: 'text', Filter: true },
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("alias") ?
                { Header: t("Alias"), accessor: 'alias', typeCell: 'text', filter: 'text', Filter: true } : null,
            { Header: t("Created date"), accessor: 'created_date', typeCell: 'time', filter: 'text', Filter: true },
            { Header: t("FirstName"), accessor: 'first_name', typeCell: 'text', filter: 'text', Filter: true },
            { Header: t("LastName"), accessor: 'last_name', typeCell: 'text', filter: 'text', Filter: true },
            { Header: t("Email"), accessor: 'email', typeCell: 'text', filter: 'text', Filter: true },
            { Header: t("Type"), accessor: 'national_id_type', typeCell: 'text', filter: 'text', Filter: true },
            { Header: t("National ID"), accessor: 'national_id', typeCell: 'text', filter: 'text', Filter: true },
            {
                Header: t("Amount"), accessor: 'amount', typeCell: 'currency', filter: 'text', Filter: true,
                Cell: (props) => {
                    let amount = props.row.original.amount;
                    let currency = props.row.original.currency;
                    return utils.formatCurrency(amount / 100, currency)
                }
            },

            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_verified") ?
                {
                    Header: t("Verified"), typeCell: 'text', filter: 'custom', accessor: "verified",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let verified = props.row.original.verified;
                        let htmlValue = t("PAYOUTS_EXTRA_VERIFIED_" + verified);
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_low_odds") ?
                {
                    Header: t("Low odds"), typeCell: 'text', filter: 'custom', accessor: "low_odds",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let low_odds = parseInt(props.row.original.low_odds);
                        let htmlValue = t("PAYOUTS_EXTRA_ODDS_" + low_odds)
                        if (low_odds === 1) {
                            return <div style={{ cursor: "pointer" }} onClick={() => { handleClickLowOdds(props.row.original.user, props.row.original.operation) }} dangerouslySetInnerHTML={{ __html: htmlValue }} />
                        }
                        else {
                            return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                        }
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_redeemed") ?
                {
                    Header: t("Redeemed promotions"), typeCell: 'text', filter: 'custom', accessor: "promotions_redeemed",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let promotions_redeemed = parseInt(props.row.original.promotions_redeemed);
                        let htmlValue = t("PAYOUTS_EXTRA_PROMOTIONS_" + promotions_redeemed)
                        if (promotions_redeemed === 1) {
                            return <div style={{ cursor: "pointer" }} onClick={() => { handleClickPromotionsRedeemed(props.row.original.user, props.row.original.operation) }} dangerouslySetInnerHTML={{ __html: htmlValue }} />
                        }
                        else {
                            return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                        }
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_magic_number") ?
                {
                    Header: t("Magic Number"), typeCell: 'text', filter: 'custom', accessor: "magic_number",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let magic_number = parseInt(props.row.original.magic_number);
                        let htmlValue = t("PAYOUTS_EXTRA_MAGIC_NUMBER_" + magic_number)
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("cashouts") ?
                {
                    Header: t("Cashouts"), typeCell: 'text', filter: 'custom', accessor: "cashouts",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let cashouts = parseInt(props.row.original.cashouts);
                        let htmlValue = t("PAYOUTS_EXTRA_CASHOUTS_" + cashouts)
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            {
                Header: t("Deposits"), accessor: 'deposits', typeCell: 'currency', filter: 'text', Filter: true,
                Cell: (props) => {
                    let deposits = props.row.original.deposits;
                    let currency = props.row.original.currency;
                    return utils.formatCurrency(deposits / 100, currency)
                }
            },
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("current_cash") ?
                {

                    Header: t("Current cash"), accessor: 'current_cash', typeCell: 'text', filter: 'text', Filter: true,
                    Cell: (props) => {
                        let currentCash = props.row.original.current_cash;
                        let currency = props.row.original.currency;
                        let format = utils.formatCurrency(currentCash / 100, currency);
                        let htmlValue = currentCash < 0 ? <div style={{ color: 'red' }}>{format}</div> : <div>{format}</div>;
                        return htmlValue
                    }

                }
                : null,

            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_depositante") ?
                {
                    Header: t("Depositante"), typeCell: 'text', filter: 'custom', accessor: "depositante",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let depositante = parseInt(props.row.original.depositante);
                        let htmlValue = t("PAYOUTS_EXTRA_DEPOSITOR_" + depositante)
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("AT") ?
                {
                    Header: t("AT"), typeCell: 'text', filter: 'text', accessor: "at_category", Filter: true,
                    Cell: (props) => {
                        let at_category = parseInt(props.row.original.at_category);
                        let htmlValue
                        if (at_category !== undefined && at_category !== null && at_category !== 0) {
                            htmlValue = "<span class='calimaco_table_red'>" + groups[at_category].name.replace("AT - ", "") + "</span>"
                        }
                        else {
                            htmlValue = '<span class="calimaco_table_green">---</span>'
                        }
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />

                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("Altenar") ?
                {
                    Header: t("Altenar"), typeCell: 'text', filter: 'text', accessor: "altenar_category", Filter: true,
                    Cell: (props) => {
                        let altenar_category = parseInt(props.row.original.altenar_category);
                        return altenar_category !== undefined && altenar_category !== null && altenar_category !== 0 ? groups[altenar_category].name.replace("ALTENAR - ", "") : '-'
                    }
                }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("BC") ?
                {
                    Header: t("BC"), typeCell: 'text', filter: 'text', accessor: "bc_category", Filter: true,

                    Cell: (props) => {
                        let bc_category = parseInt(props.row.original.bc_category);
                        return bc_category !== undefined && bc_category !== null && bc_category !== 0 ? groups[bc_category].name.replace("BETCONSTRUCT - ", "") : '-'
                    }
                }
                : null,
            { Header: t("Method"), accessor: 'method', typeCell: 'text', filter: 'text', Filter: true },

            companyDetails.config?.backoffice?.payouts?.infoTable.includes("Shop") ?
                { Header: t("Shop"), accessor: 'shop_name', typeCell: 'text', filter: 'text', Filter: true }
                : null,
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("operation_verified") ?
                {
                    Header: t("IP"), typeCell: 'text', filter: 'custom', accessor: "ip",
                    filterCustom: customFilter,
                    Cell: (props) => {
                        let ip = props.row.original.ip;
                        let htmlValue = t(ip);
                        return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                    },
                    Filter: (props) => {
                        if (props.data.length > 0) {
                            return <CheckFilter {...props} />
                        }
                        else return true;
                    }
                }
                : null,
            { Header: t("Bank"), accessor: 'bank', typeCell: 'text', filter: 'text', Filter: true },
            { Header: t("Approved date"), accessor: 'approved_date', typeCell: 'time', filter: 'time', Filter: true },
            { Header: t("Approved by"), accessor: 'approved_by_alias', typeCell: 'text', filter: 'text', Filter: true },
            companyDetails.config?.backoffice?.payouts?.infoTable.includes("register_days") ?
                {
                    Header: t("register_days"), typeCell: 'text', filter: 'text', accessor: "register_days", Filter: true,
                    Cell: (props) => {
                        let register_days = parseInt(props.row.original.register_days);
                        return register_days !== undefined && register_days !== null && register_days !== 0 ? register_days : '-'
                    }
                }
                : null,

        ].filter(Boolean);
    };

    useEffect(function () {
        let generalColumnsDefAux = generateColumns();
        setGeneralColumnsDef(generalColumnsDefAux);
    }, [groups, nextStatus, selectedOperations, valueFilter]);

    const onChangeHandlerFilter = (e) => {
        const { name, value } = e.target || e;
        setFilter((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerStatus = (e) => {
        const { name, value } = e.target || e;
        setChangeStatus((prevState) => ({ ...prevState, [name]: value }));
    };

    const loadNextStatus = (from_status) => {
        adminClient.getGrantedNextPayoutStatus(
            session,
            company,
            from_status).then(
                function (response) {
                    let valores = response.status.map(objeto => objeto.status);
                    setNextStatus(valores);
                },
                function (err) {
                    console.error("ERROR in load next payout status")
                }
            );
    }

    const showLoadingSearch = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Searching..."), true);
    }
    const showLoadingDownload = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Downloading..."), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }

    const search = (e) => {
        if (e !== "reload") {
            setProgressBar({ show: false })
        }
        if (formValidation.validate()) {
            showLoadingSearch();
            loadNextStatus(filter.status);
            let searchFilter = [];


            if (filter.payoutID !== "") {
                searchFilter.push({ "field": "t.db", "type": "text", "value": filter.payoutID.split(".")[0] });
                searchFilter.push({ "field": "t.operation", "type": "text", "value": filter.payoutID.split(".")[1] });
            }
            else {
                searchFilter.push({ "field": "t.operation_date", "type": "time_range", "value": [utils.unformatDate(filter.created_date_from), utils.unformatDate(filter.created_date_to)] });
                searchFilter.push({ "field": "o.status", "type": "string", "value": filter.status });
                if (filter.playerID !== "") {
                    searchFilter.push({ "field": "t.user", "type": "text", "value": filter.playerID });
                }
                if (filter.amountFrom !== "") {
                    searchFilter.push({ "field": "t.amount", "type": "number", "value": ">= " + filter.amountFrom * 100 });
                }
                if (filter.amountTo !== "") {
                    searchFilter.push({ "field": "t.amount", "type": "number", "value": "<= " + filter.amountTo * 100 });
                }
                if (filter.method !== "") {
                    searchFilter.push({ "field": "t.method", "type": "string", "value": filter.method });
                }
                if (filter.bankName !== "") {
                    searchFilter.push({ "field": 'pa.details', "type": "like", "value": filter.bankName });
                }
                if (filter.currency !== "") {
                    searchFilter.push({ "field": "o.currency", "type": "=", "value": filter.currency })
                }
                if (filter.atGroupsAllowed !== undefined && filter.atGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.atGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.at_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.atGroupsForbidden !== undefined && filter.atGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.atGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.at_category", "type": "number_set", "value": "notin " + elements });
                }

                if (filter.altenarGroupsAllowed !== undefined && filter.altenarGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.altenarGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.altenar_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.altenarGroupsForbidden !== undefined && filter.altenarGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.altenarGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.altenar_category", "type": "number_set", "value": "notin " + elements });
                }

                if (filter.betConstructGroupsAllowed !== undefined && filter.betConstructGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.betConstructGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.bc_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.betConstructGroupsForbidden !== undefined && filter.betConstructGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.betConstructGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.bc_category", "type": "number_set", "value": "notin " + elements });
                }
            }
            setExcelFilter(searchFilter);
            adminClient.getReport(
                session, company, "payouts", searchFilter, null, null,
                "limit 0 ," + 1000 + " ", "created_date desc").then(
                    function (msg) {
                        closeLoading();
                        let operationsResult = msg.data.data;
                        setValueFilter(initialCheckboxFilter);
                        setOperations(operationsResult);
                        setSelectedOperations([])
                        setFilter((prevState) => ({ ...prevState, currencyReport: filter.currency }));

                    }
                    , function (err) {
                        console.log("ERROR, getReport Payouts " + err)
                    }
                );
        }
    }

    const clean = (e) => {
        setFilter(cleanFilter);
        setExcelFilter([]);
        setValueFilter(initialCheckboxFilter)
    }

    const excel = (e) => {
        showLoadingDownload();
        adminClient.getExcelReport(session, company, "payouts", excelFilter, null, null, "", lang).then(
            function (resp) {
                closeLoading();
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'application/xls' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = "payouts.xls";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );
    }

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total
        setProgressBar({ show: true, percentage: percentage + '%', textProcess: t('Processing') + " " + data.partial + "/" + data.total })
    }

    const getResultOK = (data) => {
        return (<label>{t("Processed OK")}: {data.length} </label>)
    }

    const getResultNOK = (data) => {
        let resultNok = {}
        if (data.length > 0) {
            resultNok = data.map((element) => element.db + "." + element.operation + ":" + element.error)
            return (<label>{t("Processed NOK")}: {resultNok.join(' ')} </label>)
        } else {
            return (<label>{t("Without Error")}</label>)
        }
    }

    const update = (e) => {
        if (formValidationMessage.validate()) {
            props.showAlert(
                t('Payouts batch'),
                <p>{t('Are you sure you want to change the status to ') + " " + t(changeStatus.to_status) + "?"}</p>,
                [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
                <button type='button' className='btn btn-primary'
                    onClick={(evt) => {
                        updateAux(evt)
                        props.handleCloseAlert()
                    }}>
                    {t('Ok')}</button>]
            )
        }
        const updateAux = (evt) => {
            if (formValidationMessage.validate()) {
                setProgressBar({ show: true, percentage: '0%', textProcess: t('Processing') })
                adminClient.batchUpdatePayouts(
                    session,
                    company,
                    selectedOperations,
                    changeStatus.to_status,
                    changeStatus.rejectReason,
                    changeStatus.rejectClientMessage,
                    handleResponse).then(
                        function (msg) {
                            setProgressBar({ show: true, textProcess: t('Processed'), textOK: getResultOK(msg.data), textNOK: getResultNOK(msg.operationsErrors) })
                            setValueFilter(initialCheckboxFilter);
                            search("reload");
                            props.showAlert(t('Manage payouts'), t('Updated succesfull'));
                            setChangeStatus({ to_status: "", rejectReason: "", rejectClientMessage: "" })
                            setSelectedOperations([])
                        }
                        , function (err) {
                            setProgressBar({ show: false })
                            props.showAlert(t('Manage payouts'), t('Updating error'));

                        }
                    );
            }
        }
    }


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_Payouts">
                <div className="row" >
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            basicInfoName="PayoutStatus"
                            onChange={onChangeHandlerFilter}
                            name="status"
                            value={filter.status}
                            translate={{ property: "name", prefix: "" }}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            label={t("Payment method")}
                            idProperty="method"
                            basicInfoName="PayoutMethods"
                            name="method"
                            onChange={onChangeHandlerFilter}
                            value={filter.method}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            required
                            label={t("Requested from")}
                            value={filter.created_date_from}
                            showTime={true}
                            callBackFunction={(value) => onChangeHandlerFilter({ name: "created_date_from", value: value })}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            required
                            label={t("To")}
                            value={filter.created_date_to}
                            showTime={true}
                            callBackFunction={(value) => onChangeHandlerFilter({ name: "created_date_to", value: value })}
                        />
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Player ID")}
                            value={filter.playerID}
                            name="playerID"
                            onChange={onChangeHandlerFilter}
                            placeholder="db.playerId"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Payout ID")}
                            value={filter.payoutID}
                            name="payoutID"
                            onChange={onChangeHandlerFilter}
                            placeholder="db.payoutId"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Amount from")}
                            value={filter.amountFrom}
                            name="amountFrom"
                            onChange={onChangeHandlerFilter}
                            type="number"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("To")}
                            value={filter.amountTo}
                            name="amountTo"
                            onChange={onChangeHandlerFilter}
                            type="number"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <BasicSelect
                            label="Currencies"
                            idProperty="currency"
                            nameProperty="currency"
                            name="currency"
                            data="currencies"
                            basicInfoName="CurrenciesCompany"
                            onChange={onChangeHandlerFilter}
                            value={filter.currency}
                        />

                    </div>
                    <div className="col-12 col-md-2">
                        <TextField label={t("Bank")} value={filter.bankName} name="bankName" onChange={onChangeHandlerFilter}></TextField>
                    </div>
                </div>
                {/* PARTE OPCIONAL */}
                <div className='row'>
                    {companyDetails.config?.backoffice?.payouts?.searchGroups?.includes("Betconstruct") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Betconstruct Allowed")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="betConstructGroupsAllowed"
                                    params={[
                                        { property: "init", value: 400 },
                                        { property: "end", value: 499 }
                                    ]}
                                    value={filter.betConstructGroupsAllowed}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Betconstruct Forbbiden")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="betConstructGroupsForbidden"
                                    params={[
                                        { property: "init", value: 400 },
                                        { property: "end", value: 499 }
                                    ]}
                                    value={filter.betConstructGroupsForbidden}
                                    multiple
                                />
                            </div>
                        </>
                    }
                    {companyDetails.config?.backoffice?.payouts?.searchGroups?.includes("Altenar") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Altenar Allowed")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="altenarGroupsAllowed"
                                    params={[
                                        { property: "init", value: 100 },
                                        { property: "end", value: 199 }
                                    ]}
                                    value={filter.altenarGroupsAllowed}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Altenar Forbbiden")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="altenarGroupsForbidden"
                                    params={[
                                        { property: "init", value: 100 },
                                        { property: "end", value: 199 }
                                    ]}
                                    value={filter.altenarGroupsForbidden}
                                    multiple
                                />
                            </div>
                        </>
                    }
                    {companyDetails.config?.backoffice?.payouts?.searchGroups?.includes("AT") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("AT Allowed")}
                                    basicInfoName="Groups"
                                    onChange={onChangeHandlerFilter}
                                    name="atGroupsAllowed"
                                    value={filter.atGroupsAllowed}
                                    filters={[{ property: "category", value: "AT" }]}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("AT Forbbiden")}
                                    basicInfoName="Groups"
                                    onChange={onChangeHandlerFilter}
                                    name="atGroupsForbbiden"
                                    value={filter.atGroupsForbbiden}
                                    filters={[{ property: "category", value: "AT" }]}
                                    multiple
                                />
                            </div>
                        </>
                    }
                </div>
            </form>
            <div className="row">
                <div className="col-12">

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={search}
                    >
                        {t('Search')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={clean}
                    >
                        {t('Clean')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={excel}
                        disabled={operations.length === 0}
                    >
                        {t('Excel')}
                    </button>
                </div>
            </div>
            <form id="Form_Payouts_Message">

                <div className='row'>
                    {operations.length > 0 &&
                        <div style={{ textAlign: "center" }}>
                            <label style={{ fontWeight: "bold" }}>
                                Resultados: {operations.length}
                                {filter.currencyReport !== "" && (
                                    <>
                                        &nbsp;Cantidad total: {utils.formatCurrency(operations.reduce((total, objeto) => total + objeto.amount, 0) / 100, filter.currencyReport)}
                                    </>
                                )}
                                &nbsp;&nbsp;&nbsp;Seleccionados: {selectedOperations.length}
                                {filter.currencyReport !== "" && (
                                    <>
                                        &nbsp;Cantidad seleccionada: {utils.formatCurrency(selectedOperations.reduce((total, id) => {
                                            const objetoEncontrado = operations.find(objeto => objeto.operation === id);
                                            if (objetoEncontrado) {
                                                return total + objetoEncontrado.amount;
                                            }
                                            return total;
                                        }, 0) / 100, filter.currencyReport)}
                                    </>
                                )}
                            </label>
                        </div>
                    }
                    {selectedOperations.length > 0 &&
                        nextStatus.length > 0 &&
                        <div className="row mt-4" >
                            <div className="col-12 col-md-4">
                                <BasicSelect
                                    required
                                    label={t("Change to status")}
                                    idProperty="status"
                                    nameProperty="status"
                                    name="to_status"
                                    basicInfoName="PayoutStatus"
                                    filters={[{ property: "status", value: nextStatus }]}
                                    onChange={onChangeHandlerStatus}
                                    value={changeStatus.to_status}
                                    translate={{ property: "status", prefix: "" }}
                                />
                            </div>

                            {changeStatus.to_status === 'DENIED' &&
                                <div className="row" >
                                    <div className="col-12 col-md-6">
                                        <TextField multiline label={t("Reason")} name="rejectReason" value={changeStatus.rejectReason} onChange={onChangeHandlerStatus}
                                            required={companyDetails.config?.backoffice?.payouts?.deniedMessage}
                                        ></TextField>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <TextField multiline label={t("Message to client")} name="rejectClientMessage" value={changeStatus.rejectClientMessage} onChange={onChangeHandlerStatus}
                                            required={companyDetails.config?.backoffice?.payouts?.deniedMessage}
                                        ></TextField>
                                    </div>
                                </div>
                            }

                        </div>

                    }
                    <div className="col-12  ">
                        {progressBar.show &&
                            <ProgressBar percentage={progressBar.percentage} text={progressBar.textProcess} textOK={progressBar.textOK} textNOK={progressBar.textNOK} />
                        }
                    </div>
                </div>
            </form>
            {selectedOperations.length > 0 &&
                nextStatus.length > 0 &&
                <div className="row">

                    <div className="col-12 col-md-4">
                        <button type="button" className="btn btn-primary" onClick={update}>{t("Change status")}</button>
                    </div>
                </div>
            }
            <div className='row'>
                {generalColumnsDef.length > 0 &&
                    <BasicTable key="table-payouts" data={operations} columns={generalColumnsDef} showFilterColumn={true} sizePageOptions={[10, 50, 100, 400, 1000]} />
                }
            </div>
        </LayoutSection >
    )
}
export default withTranslation()(Payouts);
import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils, { isGranted } from "../utils";
import { TextField } from "@mui/material";

class AltenarPrint extends React.Component {

  constructor(props) {

    super(props);
    this.altenarSession = {};
    this.config = { server: {} };

    this.state = { barcode: "", bet: {} };

    this.adminClient = new AdminClient();
    console.log("AltenarPrint: : constructor " + new Date());

    this.handleUpdateBarcode = this.handleUpdateBarcode.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handlePay = this.handlePay.bind(this);

  }


  handleUpdateBarcode(evt) {
    console.log("calimaco: barcode: " + evt.target.value);
    this.setState({ barcode: evt.target.value });
  }




  handlePay() {
    console.log("calimaco: we are going to check : " + this.state.barcode);
    document.getElementById("loading").classList.remove("hidden");
    document.getElementById("ticket_details").classList.add("hidden");
    document.getElementById("ticket_details").classList.add("hidden");
    var thisAux = this;
    var validatePromise = this.adminClient.payBet(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.bet.db, this.state.bet.operation);
    validatePromise.then(function (data) {
      thisAux.handleCheck()
    },
      function (err) {
        console.log("calimaco: barcode result: " + JSON.stringify(err));
        document.getElementById("loading").classList.add("hidden");
        document.getElementById("ticket_details").classList.add("hidden");
        document.getElementById("no_data_found").classList.remove("hidden");
      });

  }


  handleCheck() {
    console.log("calimaco: we are going to check : " + this.state.barcode);
    document.getElementById("loading").classList.remove("hidden");
    document.getElementById("ticket_details").classList.add("hidden");
    document.getElementById("ticket_details").classList.add("hidden");
    var thisAux = this;
    var validatePromise = this.adminClient.validateBetBarcode(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.barcode);
    validatePromise.then(function (data) {
      console.log("calimaco: barcode result: " + JSON.stringify(data));
      thisAux.setState({ bet: data.bet });
      document.getElementById("loading").classList.add("hidden");
      document.getElementById("ticket_details").classList.remove("hidden");
      document.getElementById("no_data_found").classList.add("hidden");
    },
      function (err) {
        console.log("calimaco: barcode result: " + JSON.stringify(err));
        document.getElementById("loading").classList.add("hidden");
        document.getElementById("ticket_details").classList.add("hidden");
        document.getElementById("no_data_found").classList.remove("hidden");
      });

  }

  render() {
    const { t } = this.props;

    return (
      <div className="container `m-2">
        <div className="row m-1">
          <div className="card ">
            <div className="card-body m-0">

              <div className="row">

                <div className="col-12 col-md-8">
                  <TextField id="barcode" label={t("Barcode")} type="text" value={this.state.barcode} onChange={this.handleUpdateBarcode} />
                </div>
                <div className="col-12 col-md-4 float-left" >
                  <button type="button" className="btn btn-primary" onClick={this.handleCheck}>{t("Check")}</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row hidden m-1" id="loading">
          {t("Loading")}
        </div>
        <div className="row hidden m-1" id="no_data_found">
          {t("No data found")}
        </div>
        <div className="row hidden m-1" id="ticket_details">
          <div className="card ">
            <div className="card-title m-2">

            </div>
            <div className="card-body m-0 p-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.game }} disabled id="bet_game" label={t("Ticket id")} type="text" value={this.state.bet.game} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.code }} disabled id="bet_code" label={t("Bet code")} type="text" value={this.state.bet.code} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.status }} disabled id="betstatus" label={t("Bet status")} type="text" value={this.state.bet.status} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField InputLabelProps={{ shrink: this.state.bet.ticket_status }} disabled id="ticketstatus" label={t("Ticket status")} type="text" value={this.state.bet.ticket_status} />
                </div>
                <div className="col-12 col-md-4">
                  <TextField InputLabelProps={{ shrink: this.state.bet.currency }} disabled id="ticket_currency" label={t("Currency")} type="text" value={this.state.bet.currency} />
                </div>
                <div className="col-12 col-md-4">
                  <TextField InputLabelProps={{ shrink: this.state.bet.wager }} disabled id="ticket_wager" label={t("Wager")} type="text" value={utils.formatCurrency(this.state.bet.wager / 100, this.state.bet.currency)} />
                </div>
                {this.state.bet.status === 'WON' &&
                  <div className="col-12 col-md-4">
                    <TextField InputLabelProps={{ shrink: this.state.bet.winning }} disabled id="ticket_winning" label={t("Winning")} type="text" value={utils.formatCurrency(this.state.bet.winning / 100, this.state.bet.currency)} />
                  </div>
                }
                {this.state.bet.ticket_status === 'PAID' &&
                  <>
                    <div className="col-12 col-md-6" >
                      <TextField InputLabelProps={{ shrink: this.state.bet.paid_date }} disabled id="ticket_paid_datee" label={t("Paid at")} type="text" value={this.state.bet.paid_date} />
                    </div>
                    <div className="col-12 col-md-6" >
                      <TextField InputLabelProps={{ shrink: this.state.bet.payer_name }} disabled id="ticket_payer_name" label={t("Paid by")} type="text" value={this.state.bet.payer_name} />
                    </div>
                  </>
                }
                {this.state.bet.ticket_status === 'PAYMENT_PENDING' &&
                  <>
                    <div className="col-12 col-md-4 float-left" >
                    <button type="button" className="btn btn-primary" onClick={this.handlePay}>{t("Pay") + " " + utils.formatCurrency((this.state.bet.status==='WON'?this.state.bet.winning:this.state.bet.wager) / 100, this.state.bet.currency)}</button>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}



export default withTranslation()(withRouter(AltenarPrint))

import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import AppChart from "../../components/AppChart";
import AppKPI from "../../components/AppKPI";
import Report from "../../components/basic/Report";
import utils from '../../utils';

const Global_Monitor = (props) => {
    const [content, setContent] = useState(null);

    useEffect(() => {

        window.setTimeout(function () {
            window.location.reload()
        }, 5 * 60 * 1000);

        if (window.sections === undefined || window.sections === null) {
            window.sections = JSON.parse(utils.getSessionItem("sections"));
            console.log("localStorage.getItem(sections = " + utils.getSessionItem("sections"));
        }

        if (window.sections === undefined || window.sections === null) {
            props.props.history.push('/admin/login');
        } else {
            const config = window.sections["GLOBAL_MONITOR"].config;

            if (config !== undefined && config.help_url !== undefined) {
                setContent({ help: true, help_url: config.help });
                console.log("help : " + config.help_url);
            }

            const contentElements = config.elements.map((element, index) => {
                switch (element.type) {
                    case 'table':
                        return <Report key={index} className="col-12 float-left pt-3 max_width_100" reportId={element.report} filter={element.filter} />;
                    case 'chart':
                        return <AppChart key={index} className="col-12 col-md-4" report={element.report} filter={element.filter} />;
                    case 'kpi':
                        return <AppKPI key={index} kpi={element.kpi} filter={element.filter} />;
                    case 'newline':
                        return <div key={index} className="newline"></div>;
                    default:
                        return null;
                }
            });

            setContent(contentElements);
        }
    }, [props.history]);

    return (
        <>
            {utils.getSessionItem("user") !== undefined ? content : <></>}
        </>
    );
}

export default withRouter(Global_Monitor);
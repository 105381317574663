import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import BasicSelect from '../../components/basic/BasicSelect'
import useFormValidation from '../../hooks/parsleyValidation';



function GlobalGroupDetails(props) {

    let globalGroupId = utils.getSessionItem("current-global_group").split(".");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    const company = utils.getCurrentCompany().company;

    const { formValidation } = useFormValidation("Form_globalGroups")

    const [globalGroupDetails, setGlobalGroupDetails] = useState({ group: globalGroupId[0], company: company, type: "", relationship: "FORBIDDEN" });

    const [addOrUpdate, setAddOrUpdate] = useState("add");



    let adminClient = new AdminClient();

    useEffect(function () {
        if (globalGroupId !== -1) {
            let groupId = globalGroupId[0];
            let type = globalGroupId[1];
            let relationship = globalGroupId[2];
            Promise.all([adminClient.getGlobalGroupDetails(session, company, groupId, type, relationship)]).then(
                function (result) {
                    setGlobalGroupDetails(result[0].data);
                    setAddOrUpdate("update")
                },
                function (err) {
                    console.error(err);
                }
            );
        }
        else {
            setAddOrUpdate("add")
        }
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;

        let newGroupInfo = { ...globalGroupDetails };
        newGroupInfo[name] = value;
        setGlobalGroupDetails(newGroupInfo);

    };

    const handleDelete = () => {
        let promise = adminClient.deleteGlobalGroup(session, company, JSON.stringify(globalGroupDetails));
        promise.then(
            function (msg) {
                console.log("Delete result " + JSON.stringify(msg));
                if (msg.result === 'OK') {

                    props.showAlert(t("Group deleted"), t("Group deleted ok"));
                    goBack();

                } else {
                    props.showAlert(t("Group deleted"), t("Deleting error"));
                }
            }, function (err) {
                console.log("Save operation error " + err.code);
                props.showAlert(t("Group deleted"), t("Deleting error"));
            });

    };

    const handleSubmit = () => {
        if (formValidation.validate()) {


            var promise = adminClient.saveGlobalGroup(session, company, JSON.stringify(globalGroupDetails));
            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        setAddOrUpdate("update");
                        props.showAlert(t("Group saved"), t("Group saved ok"));

                    } else {
                        props.showAlert(t("Group saved"), t("Saving error"));
                    }
                }, function (err) {
                    console.log("Save operation error " + err.code);
                    props.showAlert(t("Group saved"), t("Saving error"));
                });
        }
    };

    const goBack = () => {
        props.history.push('/reports/report/global_groups/table')
    }
    const { t } = props
    const dataHeader = {
        backLink: goBack,
        title: t("Global groups config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_globalGroups">
                <div className="card-body m-0">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <BasicSelect
                                idProperty="group"
                                name="group"
                                label={t("Group")}
                                basicInfoName="Groups"
                                onChange={onChangeHandler}
                                value={globalGroupDetails.group}
                                disabled={addOrUpdate === "update"}
                                noEmptyValue
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <BasicSelect
                                label={t("Type")}
                                idProperty="type"
                                name="type"
                                basicInfoName="GlobalGroupTypes"
                                onChange={onChangeHandler}
                                translate={{ property: "type", prefix: "GLOBAL_GROUP_TYPE_" }}
                                value={globalGroupDetails.type}
                                disabled={addOrUpdate === "update"}
                                disableClereable
                                noEmptyValue
                            />
                        </div>
                        <div className="col-12 col-md-6">

                            <BasicSelect
                                label={t("Relationship")}
                                name="relationship"
                                idProperty="relationship"
                                onChange={onChangeHandler}
                                addOpts={[{ "relationship": "FORBIDDEN", "name": "Forbidden" }]}
                                translate={{ property: "relationship", prefix: "GLOBAL_GROUP_RELATIONSHIP_" }}
                                value={globalGroupDetails.relationship}
                                disabled={addOrUpdate === "update"}
                                disableClereable
                                noEmptyValue
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                            <button hidden={addOrUpdate === "add"} type="button" className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button>
                        </div>
                    </div>
                </div>
            </form>
        </LayoutSection>
    )
}

export default withTranslation()(GlobalGroupDetails)

import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils, { isGranted } from "../utils";
import Barcode from 'react-jsbarcode';
import DocButton from "../components/basic/DocButton";

class AltenarPage extends React.Component {

  constructor(props) {

    super(props);
    this.altenarSession = {};
    this.config = { server: {} };

    this.state = { step: "FIRST", users: [], currentPlayer: {}, providerConfig: {} };

    this.setPlayer = this.setPlayer.bind(this);
    this.stepOne = this.stepOne.bind(this);
    this.print = this.print.bind(this);

  }

  componentDidMount() {
    var adminClient = new AdminClient();
    var thisAux = this;

    var providerPromise = adminClient.getProviderDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), "altenar");
    providerPromise.then(function (response) {
      console.log("providerPromise " + response.data.config);
      thisAux.setState({ providerConfig: JSON.parse(response.data.config) });
    }, function (err) {
      console.error("providerPromise " + err.description);
    }
    );

    var usersPromise = adminClient.getUserDataUsers(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"));
    usersPromise.then(function (response) {
      console.log("getDataUsers " + JSON.stringify(response));
      thisAux.setState({ users: response.users });
    }, function (err) {
      console.error("getDataUsers " + err.description);
    }
    );


  }

  async setPlayer(index) {
    var adminClient = new AdminClient();
    var sessionPromise = await adminClient.getUserGameSession(JSON.parse(utils.getSessionItem("user")).session,
      JSON.stringify(utils.getSessionItem("Company")), JSON.stringify(this.state.users[index]), 'altenar');
    this.state.users[index].session = sessionPromise.user.provider_session;
    this.setState({ currentPlayer: this.state.users[index], step: "SECOND" });
  }

  stepOne() {
    this.setState({ currentPlayer: {}, step: "FIRST" });
  }

  print() {

    window.open("/agents/altenar_print", "_blank");
  }

  handleSubmit() {
    var bet = this.state.bet;
    console.log("Bet to be save: " + JSON.stringify(bet));
    var adminClient = new AdminClient();
    this.setState({ errorMessage: "" });


    var thisAux = this;

    var promise = adminClient.updateBet(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(bet));
    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          try {
            console.log("Updating report... " + thisAux.windowRef);
            window[this.windowRef].executeReport(0);
          } catch (Err) {
            console.log(Err);
          }
          alert("OK");
          thisAux.setState({ bet: msg.data });
        } else {
          thisAux.setState({ errorMessage: "" + msg.result });
        }
      }.bind(this), function (err) {
        console.log("Save bet error " + err.code);
        thisAux.setState({ errorMessage: "" + err.description });
      });

  }



  handleUpdateStatus(evt) {
    var bet = this.state.bet;
    bet.status = evt.target.value;

    this.setState({ "bet": bet });
  }




  goBack(evt) {
    this.props.history.goBack();
  }


  render() {
    const { t } = this.props;

    console.log("Altenar js = " + this.state.providerConfig.js);
    let config = {

      endpoint: this.state.providerConfig.js,
      walletcode: this.state.providerConfig.walletcode,
      skinId: this.state.providerConfig.skinId,
      session: this.state.currentPlayer.session
    }

    return (
      <>
        {this.state.step === 'FIRST' &&

          <div className="card calimaco_tickets_choose_profile">
            <div className="card-title">
              {t("Choose one profile to do the bet")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="card-body m-0">
              <div className="container">
                <div className="row">
                  {this.state.users.map((user, index) => {
                    return (
                      <div className="col-6 p-3" ><button className="btn btn-primary w-100 translate-middle " onClick={() => { this.setPlayer(index) }} >  {t("ticket_profile_" + user.currency)}</button>
                      </div>

                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.step === 'SECOND' &&
          <>
            <div className="col-12 calimaco_tickets_current_profile">
              {t("Current ticket profile")} : {t("ticket_profile_" + this.state.currentPlayer.currency)}
              <button className="btn btn-secondary w-100  " onClick={this.print} >{t("Print ticket")}</button>
              <button className="btn btn-secondary w-100  " onClick={this.stepOne} >{t("Change profile")}</button>
            </div>
            <div className="col-12   float-left pt-3">
              <iframe src={this.state.providerConfig.bo_new_page + "?config=" + JSON.stringify(config)}></iframe>
            </div>
          </>
        }
      </>
    )
  }
}



export default withTranslation()(withRouter(AltenarPage))

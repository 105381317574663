import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../basic/BasicSelect';
import { IconButton, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import AlertMessage from '../NavigationAndLayouts/AlertMessage';
import { useAlert } from '../../hooks/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Operations = (props) => {

    const agentInfo = props.agentInfo
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const companyConfBO = utils.getCurrentCompany().config.backoffice
    const showDescription = (companyConfBO?.operationDescription !== undefined && !companyConfBO?.operationDescription) ? true : false
    const { t } = props;


    const [selected, alertOpen, showAlert, closeAlert] = useAlert()

    const { formValidation: formValidationActivity } = useFormValidation('Form_AgentsActivity')

    const userCanRetrieve = (actionType) => {

        if (companyConfBO.agents?.useParentBlocked && actionType === 'RETRIEVE' && props.agentInfo.status === 'DISABLED' && props.agentInfo.blocked_by != undefined && props.agentInfo.blocked_by !== null && JSON.parse(utils.getSessionItem('user')).user !== props.agentInfo.blocked_by) {

            adminClient.isParentFromUser(session, company, props.agentInfo.blocked_by).then((result) => {
                setCanRetrieve(true)
            }, (err) => {
                setCanRetrieve(false)
            })
        } else setCanRetrieve(true)
    }

    const optionsOperationsTokens = useMemo(() => {

        let options = []
        if (utils.isGranted('SEND_TOKENS')) options.push({ type: "SEND", name: t('ACTION_SEND') + " " + t("To value") + " " + " (" + agentInfo.alias + ")" })
        if (utils.isGranted('RETRIEVE_TOKENS')) options.push({ type: "RETRIEVE", name: t("ACTION_RETRIEVE") + " " + t("From value") + " " + " (" + agentInfo.alias + ")" })
        return options
    }, [agentInfo])

    const [creditInfo, setCreditInfo] = useState({
        actionType: props.actionType || "SEND",
        amount: 10.00,
        description: ''
    });

    const [canRetrieve, setCanRetrieve] = useState(false)


    useEffect(() => {
        userCanRetrieve(props.actionType || 'SEND')
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name !== undefined && value !== undefined) {
            setCreditInfo((prevState) => ({ ...prevState, [name]: value }));
            if (name === 'actionType') {
                userCanRetrieve(value)
            }

        }
    };

    const save = (evt) => {

        if (formValidationActivity.validate()) {

            document.getElementById('operationAgent_btn').classList.add('hide')
            if (creditInfo.actionType === 'SEND') {
                var promise = adminClient.sendTokens(session, agentInfo.user, company, creditInfo.amount * 100, agentInfo.currency, creditInfo.description);
                promise.then(
                    function (response) {

                        const channel = new BroadcastChannel('updateBalance');
                        channel.postMessage("updateBalance")
                        document.getElementById('operationAgent_btn').classList.remove('hide')
                        showAlert(t("Send tokens"), <p>{t("Tokens sent succesfully")}</p>, [<button type='button' className='btn btn-primary'
                            onClick={(evt) => {
                                if (props.finishOperation) props.finishOperation({ action: creditInfo.actionType, type: "agent", dataResponse: response.accounts });
                                if (props.showClose) props.showClose()
                                else closeAlert()
                            }}>
                            {t('Ok')}</button>])
                    },
                    function (err) {
                        console.error(err);
                        document.getElementById('operationAgent_btn').classList.remove('hide')
                        showAlert(t("Send tokens"), <p>{t("Send tokens error") + ": " + t("ERROR_" + err.description)}</p>)
                    }
                )

            } else {

                var promise = adminClient.retrieveTokens(session, agentInfo.user, company, creditInfo.amount * 100, agentInfo.currency, creditInfo.description);
                promise.then(
                    function (response) {
                        if (response.result === "OK") {

                            const channel = new BroadcastChannel('updateBalance');
                            channel.postMessage("updateBalance")
                            document.getElementById('operationAgent_btn').classList.remove('hide')
                            showAlert(t("Retrieve tokens"), <p>{t("Tokens retrieved succesfully")}</p>, [<button type='button' className='btn btn-primary'
                                onClick={(evt) => {
                                    if (props.finishOperation) props.finishOperation({ action: creditInfo.actionType, type: "agent", dataResponse: response.accounts });
                                    if (props.showClose) props.showClose()
                                    else closeAlert()
                                }}>
                                {t('Ok')}</button>])

                        } else {
                            document.getElementById('operationAgent_btn').classList.remove('hide')
                            showAlert(t("Retrieve tokens"), <p>{t("Send tokens error") + ": " + t("ERROR_" + response.code)}</p>)
                        }
                    },
                    function (err) {
                        console.error(err);
                        document.getElementById('operationAgent_btn').classList.remove('hide')
                        showAlert(t("Retrieve tokens"), <p>{t("Send tokens error") + " " + t("ERROR_" + err.description)}</p>)
                    }
                )
            }
        }
    }

    return (<>
        {!canRetrieve && <p>El usuario esta bloqueado y no es por este usuario</p>}
        <form id="Form_AgentsActivity">
            <div className="row">
                {props.showActionType &&
                    <div className={props.showActionType ? 'col-12 col-md-3' : 'col-12'}>
                        <BasicSelect
                            idProperty='type'
                            value={creditInfo.actionType}
                            name='actionType'
                            label={"(" + JSON.parse(utils.getSessionItem("user")).alias + ") " + t('I want to')}
                            addOpts={optionsOperationsTokens}
                            onChange={onChangeHandler}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={true}
                        />
                    </div>}
                {canRetrieve && <div className={props.showActionType ? 'col-12 col-md-3' : 'col-12'}>
                    <TextField label={t("the amount")} name="amount" type="number" InputProps={{ inputProps: { step: "0.01", 'data-parsley-amount-greater': 0 } }} value={creditInfo.amount} required onChange={onChangeHandler} />
                </div>}
                {canRetrieve && showDescription &&
                    <div className={props.showActionType ? 'col-12 col-md-6' : 'col-12'}>
                        <TextField label={t("Description")} name="description" type="text" value={creditInfo.description} onChange={onChangeHandler} />
                    </div>
                }
            </div>
        </form>
        <div className="row">
            <div className="col-12 ">
                {utils.isGranted('SEND_TOKENS') && canRetrieve &&


                    <button id="operationAgent_btn" className="btn btn-primary" onClick={save}>{t("ACTION_" + creditInfo.actionType)}</button>


                }
                {props.showClose &&

                    <button className="btn btn-secondary" onClick={props.showClose}>{t("Close")}</button>

                }
            </div>
        </div>
        <AlertMessage key={'SendAndRetrieve_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
    </>

    )
}

const ChangePassword = (props) => {

    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;

    const { formValidation: formPassValidation } = useFormValidation("FormPassword");
    const [showPassword, setShowPassword] = useState({ password: false, password2: false })
    const [password, setPassword] = useState("")
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassAdmin || 11

    const handleClickShowPassword = (evt) => {
        const { name } = evt.currentTarget
        setShowPassword((prevState) => ({ ...prevState, [name]: !prevState[name] }))
    }

    const onChangePasswordHandler = (e) => {
        const { value } = e.target || e;
        setPassword(value);
    }

    const updatePassword = () => {
        if (formPassValidation.validate()) {
            adminClient
                .updateAgentPassword(
                    session,
                    company,
                    props.agentUser,
                    password
                )
                .then(
                    function (data) {
                        if (data.result === "OK") {
                            showAlert(
                                t("Agents"),
                                t("Password updated succesfully"), [<button type='button' className='btn btn-primary'
                                    onClick={(evt) => {
                                        if (props.showClose) props.showClose()
                                        else closeAlert()
                                    }}>
                                    {t('Ok')}</button>]
                            );
                        }
                        else showAlert(t("Agents"), t('Password updated error') + data.description)
                    },
                    function (err) {
                        showAlert(t("Agents"), t('Password updated error') + err.description)
                    }
                );
        }
    };

    return (
        <>

            <form id="FormPassword">
                <div className="row">
                    <div className="col-12  col-md-6">
                        <TextField
                            label={t("New password")}
                            type={showPassword.password ? 'text' : 'password'}
                            name="password"
                            onChange={onChangePasswordHandler}
                            required

                            inputProps={
                                {
                                    "data-parsley-pattern": "^[a-zA-Z0-9]+$",
                                    "data-parsley-pattern-message": t('Password letters and numbers'),
                                    "minLength": minLength,
                                    id: "password"
                                }

                            }
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password"
                                        >
                                            {showPassword.password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField
                            label={t("Re password")}
                            required
                            type={showPassword.password2 ? 'text' : 'password'}
                            inputProps={{
                                "data-parsley-check-text-fields-equal":
                                    "[isEqual,password]",
                                "data-parsley-check-text-fields-equal-message":
                                    t("New password different than re password")
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password2"
                                        >
                                            {showPassword.password2 ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                            name="pasword2"
                            onChange={onChangePasswordHandler}

                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12 ">
                    {utils.isGranted("AGENTS_SAVE_AGENT") &&
                        <button className="btn btn-primary" onClick={updatePassword}>{t("Update")}</button>
                    }
                    {props.showClose &&

                        <button className="btn btn-secondary" onClick={props.showClose}>{t("Close")}</button>

                    }
                </div>
            </div>
            <AlertMessage key={'Password_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />

        </>
    )

}

const ToolBar = (props) => {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const companyConfBO = utils.getCurrentCompany().config.backoffice

    const { t } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goOperationTokens = (rowUser, actionType, title) => {
        props.showAlert(title, <SendAndRetrieve agentInfo={rowUser} finishOperation={props.finishOperation} actionType={actionType} showClose={props.handleCloseAlert} />, true)
    }

    const goDetails = (user) => {
        window.open('/agents/subagents/details/' + user, "_blank")
    }

    const goPassword = (rowUser) => {
        props.showAlert(t("Change Password"), <Password agentUser={rowUser} showClose={props.handleCloseAlert} />, true)
    }

    const showConfirmStatus = (rowUser, status) => {

        props.showAlert(t('Status'), <p>{t('Are you sure you want to change the status to ') + " " + t(status) + "?"}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    props.handleCloseAlert()
                    doChangeStatus(rowUser, status, true)
                }}>
                {t('All agents')}</button>,
            <button type='button' className={'btn btn-primary'}
                onClick={(evt) => {
                    props.handleCloseAlert()
                    doChangeStatus(rowUser, status, false)
                }}>
                {t('Only this agent')}</button>])
    }


    const goStatus = async (row, status) => {

        if (companyConfBO.agents?.useParentBlocked && row.blocked_by !== undefined && row.blocked_by !== null && JSON.parse(utils.getSessionItem('user')).user !== row.blocked_by) {
            //Vemos el identificador de quien lo bloqueo, siempre que este habilitado en la company el control de bloqueo                      
            adminClient.isParentFromUser(session, company, row.blocked_by).then((data) => {
                showConfirmStatus(row.user, status)
            }, (err) => {
                props.showAlert(t('Status'), <p>{t('Agent blocked by other user')}</p>)
            })
        } else showConfirmStatus(row.user, status)



    }
    const doChangeStatus = (rowUser, status, allAgents) => {

        adminClient.updateAgentStatus(session, company, rowUser, status, allAgents).then(function (data) {
            if (data.result === "OK") {
                props.showAlert(t('Status'), t("Updating successful"), [<button type='button' className='btn btn-primary'
                    onClick={(evt) => {
                        props.finishOperation({
                            action: "STATUS",
                            type: "agent",
                            dataResponse:
                                { status: status, allAgents: allAgents, useParentBlocked: companyConfBO.agents?.useParentBlocked, agentsUpdated: data.data.trim() }
                        });
                        props.handleCloseAlert()
                    }}>
                    {t('Ok')}</button>]);


            }
            else props.showAlert(t('Status'), t('Updating error') + data.description)
        },
            function (err) {
                if (err.code === 'blockedBy') {
                    props.showAlert(t('Status'), t('Agent blocked by other user') + err.description, [<button type='button' className='btn btn-primary'
                        onClick={(evt) => {
                            props.finishOperation({
                                action: "STATUS",
                                type: "agent",
                                dataResponse: { status: status, allAgents: allAgents, useParentBlocked: companyConfBO.agents?.useParentBlocked, agentsUpdated: err.data }
                            });
                            props.handleCloseAlert()
                        }}>
                        {t('Close')}</button>])

                }
                else props.showAlert(t('Status'), t('Updating error') + err.description)
            })
    }

    return (<>
        <div className="desktop-icons">
            {utils.isGranted('SEND_TOKENS') &&
                <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'SEND', t("ACTION_SEND"))}><FontAwesomeIcon icon="plus-circle" className='icon' /></IconButton>}
            {utils.isGranted('RETRIEVE_TOKENS') &&
                <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'RETRIEVE', t("ACTION_RETRIEVE"))}><FontAwesomeIcon icon="minus-circle" className='icon' /></IconButton>}
            {utils.isGranted("AGENTS_SAVE_AGENT") && <><IconButton className='icon-button' onClick={() => {
                goStatus(props.row, props.row.status === 'DISABLED' ? 'ENABLED' : 'DISABLED')
                handleClose()
            }}><FontAwesomeIcon className='icon' icon={props.row.status === 'DISABLED' ? 'unlock' : 'lock'} /></IconButton>

                <IconButton className='icon-button' onClick={() => goPassword(props.row.user)}><FontAwesomeIcon className='icon' icon="key" /></IconButton></>}
            <IconButton className='icon-button' onClick={() => goDetails(props.row.user)}><FontAwesomeIcon className='icon' icon="eye" /></IconButton>
        </div>
        <div className="mobile-menu">
            {utils.isGranted('SEND_TOKENS') && <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'SEND', t("ACTION_SEND"))}><FontAwesomeIcon icon="plus-circle" className='icon' /></IconButton>}
            {utils.isGranted('RETRIEVE_TOKENS') &&
                <IconButton className='icon-button' onClick={() => goOperationTokens(props.row, 'RETRIEVE', t("ACTION_RETRIEVE"))}><FontAwesomeIcon icon="minus-circle" className='icon' /></IconButton>}
            <IconButton onClick={handleClick} className="icon-button"><FontAwesomeIcon icon='bars' className="icon" /></IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {utils.isGranted("AGENTS_SAVE_AGENT") &&
                    <MenuItem onClick={() => {
                        goStatus(props.row, props.row.status === 'DISABLED' ? 'ENABLED' : 'DISABLED')
                        handleClose()
                    }}>
                        <FontAwesomeIcon icon={props.row.status === 'DISABLED' ? 'unlock' : 'lock'} />
                    </MenuItem>}
                {utils.isGranted("AGENTS_SAVE_AGENT") &&
                    <MenuItem onClick={() => {
                        goPassword(props.row.user)
                        handleClose()
                    }
                    }><FontAwesomeIcon icon='key' /></MenuItem>
                }
                <MenuItem onClick={() => {
                    goDetails(props.row.user)
                    handleClose()
                }}><FontAwesomeIcon icon='eye' /></MenuItem>
            </Menu>
        </div >
    </>)

}
const SendAndRetrieve = withTranslation()(Operations)
const Password = withTranslation()(ChangePassword)
const AgentToolBar = withTranslation()(ToolBar)
export { SendAndRetrieve, Password, AgentToolBar }


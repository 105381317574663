import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next";
import moment from 'moment';
import { generateColumnsDef } from '../../libs/utilsReports'

import utils from '../../utils';
import AdminClient from "../../AdminClient";
import { TextField } from "@mui/material";
import File from "../../components/basic/File";
import BasicSelect from "../../components/basic/BasicSelect";
import DateAndTimePicker from "../../components/DateAndTimePicker";
import BasicTable from "../../components/basic/BasicTable";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import MessagesList from "../../components/basic/MessagesList";
import useFormValidation from "../../hooks/parsleyValidation";
import { initDataPlayerRegulation } from '../../libs/regulations/regulations';


const PendingDocumentation = (props) => {

    const [documentationDetails, setDocumentationDetails] = useState({
        user_details: { national_id_type: "DNI", verified: 0, state: 0, province: 0, city: 0 },
        operations: [],
        user_files: [],
        expiration_date: null,
        created_date: [null, null],
        rejectClientMessage: '',
        rejectReason: ''
    })

    const [documentationOpen, setDocumentationOpen] = useState(false);

    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getSessionItem("Company");
    const { formValidation } = useFormValidation("Form_pendingDoc");

    const adminClient = new AdminClient();

    const { t } = props

    const dataHeader = {
        title: t("Pending documentation"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    const notifyError = (value) => {
        let error = false
        if (formValidation !== undefined) {
            error = formValidation.validate()
        }
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setDocumentationDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerUserDetails = (e) => {
        const { name, value } = e.target || e;
        setDocumentationDetails((prevState) => {
            let oldState = { ...prevState }
            oldState.user_details[name] = value
            return oldState
        });
    }
    const onChangeHandlerReject = ({ code, description }) => {

        setDocumentationDetails((prevState) => {
            let oldState = { ...prevState }
            oldState.rejectReason = code
            oldState.rejectClientMessage = description
            return oldState
        });
    }

    useEffect(() => {
        search();
        if (window.sections === undefined || window.sections === null) {
            window.sections = JSON.parse(utils.getSessionItem("sections"));
        }
    }, [])


    const handleClickDetails = async (user, type) => {
        props.showAlert("LOADING", t("Loading"), true);
        setDocumentationOpen(false)
        let getUserDetails = await adminClient.userDetails(session, user);
        let getUserFilesResponse = await adminClient.getUserFiles(
            session, company, user.split(".")[0], user.split(".")[1], 'PENDING', type.substring(10)
        );



        setDocumentationDetails((prevState) => {
            let oldState = { ...prevState }
            if (getUserFilesResponse.files.length > 0) {
                oldState.expiration_date = getUserFilesResponse.files[0].expiration_date || null;
            }
            //let RegulationPlayer = initDataPlayerRegulation(getUserDetails, t);
            oldState.user_details = { ...getUserDetails.user }
            oldState.olduser = { ...getUserDetails.user }
            oldState.user_file_type = type.substring(10);
            oldState.user_files = getUserFilesResponse.files;
            oldState.rejectClientMessage = ""
            oldState.rejectReason = ""
            oldState.hiddenFields = getUserDetails.hiddenFields
            oldState.noEditFields = getUserDetails.noEditFields
            oldState.requiredFields = getUserDetails.requiredFields
            return oldState
        });
        setDocumentationOpen(true)
        props.handleCloseAlert()
    }
    useEffect(() => {

        if (documentationOpen === true) {
            let newDocumentationDetails = { ...documentationDetails }
            let playerData = {
                olduser: { ...documentationDetails.olduser },
                user: { ...documentationDetails.user_details },
                hiddenFields: [...documentationDetails.hiddenFields],
                noEditFields: [...documentationDetails.noEditFields],
                requiredFields: [...documentationDetails.requiredFields]
            }
            let RegulationPlayer = initDataPlayerRegulation(playerData, t);
            newDocumentationDetails.user_details = RegulationPlayer.user
            newDocumentationDetails.requiredFields = RegulationPlayer.requiredFields
            newDocumentationDetails.noEditFields = RegulationPlayer.noEditFields
            newDocumentationDetails.hiddenFields = RegulationPlayer.hiddenFields

            setDocumentationDetails(newDocumentationDetails)
        } else if (formValidation !== undefined) formValidation.reset()
    }, [documentationOpen])

    const search = () => {

        let from = documentationDetails.created_date[0];
        let to = documentationDetails.created_date[1];
        if (from === null) {
            from = new Date();
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
        }
        if (to === null) {
            to = new Date();
        }

        let myTimeZone = moment.tz.guess();
        let myoffset = moment.tz(myTimeZone).utcOffset();


        let r = moment.utc(to);
        console.log("to = " + to + "  r = " + r);
        r = r - (-myoffset) * 60 * 1000;

        to = new Date(r);

        r = moment.utc(from);
        r = r - (-myoffset) * 60 * 1000;
        from = new Date(r);

        let filter = [];

        filter.push({ "field": "t.status", "type": "string", "value": "PENDING" });
        filter.push({ "field": "t.type", "type": "string", "value": "IDENTITY" })
        adminClient.getConfiPlayerData(session, company).then((response) => {

            adminClient.getReport(
                session,
                company,
                "users_pending_documentation", filter, null, null, "limit 0 ,100 ", "created_date desc"
            ).then(
                function (msg) {
                    let columns = []
                    columns = generateColumnsDef(msg.data.def, t)
                    columns[3]['Cell'] = (cell) => {
                        return <div onClick={(evt) => handleClickDetails(cell.row.original.user, cell.row.original.type)} className="user_files_total">{cell.row.original.total}</div>
                    }
                    columns[3]['Filter'] = true;
                    columns[5]['Filter'] = true;

                    if (response.data.hiddenFields.includes('alias')) delete columns[4]
                    if (response.data.hiddenFields.includes('national_id')) delete columns[2]
                    setDocumentationDetails((prevState) => {
                        let oldState = { ...prevState }
                        oldState.operations = msg.data.data;
                        oldState.selected_operations = [];
                        oldState.columns = columns;
                        return oldState
                    });
                    setDocumentationOpen(false);
                }
                , function (err) {
                    console.error("Error in report")
                }
            );
        })
    }

    const updateDataPlayer = () => {
        let newDocumentationDetails = { ...documentationDetails }
        newDocumentationDetails.olduser = { ...documentationDetails.user_details }
        setDocumentationDetails(newDocumentationDetails)
        setDocumentationOpen(false)
        setDocumentationOpen(true)
    }

    const handleSavePlayer = () => {
        if (formValidation.validate()) {

            adminClient.saveUser(
                session, JSON.stringify(documentationDetails.user_details)
            ).then(
                function (msg) {
                    props.showAlert(t('Pending documentation details'), t('Saved succesfull'));
                    updateDataPlayer()
                }, function (err) {
                    props.showAlert(t('Pending documentation details'), t('Saving error'));

                }
            );
            //}
        }
    }

    const handleApprove = async () => {
        let currCompany = utils.getCurrentCompany();
        if (currCompany.config.documentation !== undefined && currCompany.config.documentation.required_expiration_date && documentationDetails.expiration_date === null) {
            props.showAlert(t("Pending documentation details"), <p>{t("Expiration date required")}</p>)
        }
        else {
            try {
                let approveUserFilesResponse = await adminClient.reviewUserFiles(
                    session, company,
                    documentationDetails.user_details.db, documentationDetails.user_details.user,
                    'PENDING', 'APPROVED', documentationDetails.user_file_type, documentationDetails.user_details.national_id_type,
                    documentationDetails.expiration_date, '', ''
                );
                if (approveUserFilesResponse.result === 'OK') {
                    props.showAlert(t("Pending documentation details"), <p>{t("Documents approved")}</p>)
                    search();
                } else {
                    props.showAlert(t("Pending documentation details"), <p>{approveUserFilesResponse.description}</p>)
                }
            } catch (err) {
                props.showAlert(t("Pending documentation details"), <p>{err.description}</p>)
            }
        }
    }

    const handleVerify = async () => {
        try {
            setDocumentationDetails((prevState) => {
                let oldState = { ...prevState }
                oldState.user_details.verified = 1;
                return oldState
            });

            let verifyUserResponse = await adminClient.verifyUser(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"),
                documentationDetails.user_details.db, documentationDetails.user_details.user);
            if (verifyUserResponse.result === 'OK') {
                props.showAlert(t("Pending documentation details"), <p>{t("Player verified")}</p>)

            } else {
                props.showAlert(t("Pending documentation details"), <p>{t("Player verify error")}</p>)
            }
        } catch (err) {
            props.showAlert(t("Pending documentation details"), <p>{t("Player verify error")}</p>)

        }

    }

    const handleApproveAll = () => {
        if (formValidation.validate()) {
            // let dataBirth = new Date(documentationDetails.user_details.birthday)
            // let now = new Date()
            // if ((now.getTime() - dataBirth.getTime()) < (18 * 365 * 24 * 60 * 60 * 1000))
            //     props.showAlert(t("Pending documentation details"), t("Less than 18 years"))
            // else {
            let currCompany = utils.getCurrentCompany();
            if (currCompany.config.documentation !== undefined && currCompany.config.documentation.identity.required_expiration_date && documentationDetails.expiration_date === null) {
                props.showAlert(t("Pending documentation details"), <p>{t("Expiration date required")}</p>)
            }
            else {
                adminClient.reviewUserFiles(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), documentationDetails.user_details.db, documentationDetails.user_details.user, 'PENDING', 'APPROVED', documentationDetails.user_file_type, documentationDetails.user_details.national_id_type, documentationDetails.expiration_date, '', '').then((mensaje) => {

                    setDocumentationDetails((prevState) => {
                        let oldState = { ...prevState }
                        oldState.user_details.verified = 1;
                        return oldState
                    });
                    adminClient.saveUser(session, JSON.stringify(documentationDetails.user_details)).then((msg) => {
                        props.showAlert(t("Pending documentation details"), <p>{t("Documents approved") + " " + t("Player verified") + " " + t("User saved ok")}</p>)
                        updateDataPlayer()
                        search();
                    }, (err) => {
                        props.showAlert(t("Pending documentation details"), <p>{err}</p>)
                    });

                }, (err) => {
                    props.showAlert(t("Pending documentation details"), <p>{err}</p>)
                })
            }
            //}
        }
    }

    const handleDeny = async () => {
        try {
            let approveUserFilesResponse = await adminClient.reviewUserFiles(session, company,
                documentationDetails.user_details.db, documentationDetails.user_details.user, 'PENDING', 'DENIED', documentationDetails.user_file_type, documentationDetails.user_details.national_id_type, documentationDetails.expiration_date, documentationDetails.rejectReason, documentationDetails.rejectClientMessage)
            if (approveUserFilesResponse.result === 'OK') {
                props.showAlert(t("Pending documentation details"), <p>{t("Documents denied")}</p>)
                search();
            } else {
                props.showAlert(t("Pending documentation details"), <p>{approveUserFilesResponse.description}</p>)
            }
        } catch (err) {
            props.showAlert(t("Pending documentation details"), <p>{err.description}</p>)
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            {documentationOpen &&
                <>
                    <div className="row">
                        <div>
                            <button className="btn btn-primary float-start" onClick={handleSavePlayer}>{t("Save personal data")}</button>
                            <button className="btn btn-primary float-start" onClick={handleApprove}>{t("Approve Documentation")}</button>
                            {documentationDetails.user_details.verified === 0 &&
                                <button className="btn btn-primary float-start" onClick={handleVerify}>{t("Verify User")}</button>
                            }

                            <button className="btn btn-secondary" onClick={handleApproveAll}>{t("Approve")}</button>
                            <button className="btn btn-secondary" onClick={handleDeny}>{t("Deny")}</button>
                            <button className="btn btn-secondary" onClick={(evt) => { search(); }}>{t("Close")}</button>
                        </div>
                    </div>
                    <form id="Form_pendingDoc" data-parsley-focus="none" data-parsley-ui-enabled="true">
                        <div className="row">

                            <div className="col-12 col-md-3">
                                <TextField disabled label={t("ID")} value={documentationDetails.user_details.db + "." + documentationDetails.user_details.user}></TextField>
                            </div>
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('created_date'))) &&
                                <div className="col-12 col-md-3">
                                    <DateAndTimePicker
                                        id="Player_created_date"
                                        disabled
                                        required
                                        renderInput={(props) => <TextField  {...props} />}
                                        label={t("Created date")}
                                        value={utils.formatDate(documentationDetails.user_details.created_date || '')}
                                        showTime={true}
                                    />
                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('alias'))) &&
                                <div className="col-12 col-md-3">
                                    <TextField id="Player_alias" disabled required label={t("Alias")} name="alias" value={documentationDetails.user_details.alias}></TextField>
                                </div>}
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('email'))) &&
                                <div className="col-12 col-md-3">
                                    <TextField id="Player_email" disabled required label={t("Email")} name="email" value={documentationDetails.user_details.email}></TextField>
                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('first_name'))) &&
                                <div className="col-12 col-md-4">
                                    <TextField required id="Player_first_name" disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('first_name')))} label={t("FirstName")} name="first_name" value={documentationDetails.user_details.first_name} onChange={onChangeHandlerUserDetails}></TextField>
                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('last_name'))) && <>
                                <div className="col-12 col-md-4">
                                    <TextField id="Player_last_name" required disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('last_name')))} label={t("LastName")} name="last_name" value={documentationDetails.user_details.last_name} onChange={onChangeHandlerUserDetails}></TextField>
                                </div>


                                {window.sections !== undefined &&
                                    window?.sections["USER_DETAILS"]?.config['middle_name'] &&
                                    <div className="col-12 col-md-4">

                                        <TextField id="Player_middle_name" disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('last_name')))} required={documentationDetails.requiredFields.includes("middle_name")} label={t("MiddleName")} name="middle_name" value={documentationDetails.user_details.middle_name !== null ? documentationDetails.user_details.middle_name : ''} onChange={onChangeHandlerUserDetails}></TextField>
                                    </div>
                                }
                            </>}
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('birthday'))) &&
                                <div className="col-12 col-md-4">
                                    <DateAndTimePicker
                                        id="Player_birthday"
                                        key="userBirthDate"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('birthday')))}
                                        required={documentationDetails.requiredFields.includes("birthday")}
                                        renderInput={(props) => <TextField  {...props} />}
                                        label={t("Birthday")}
                                        name="birthday"
                                        handleNotifyError={notifyError}
                                        validations={{ 'data-parsley-player-birth-day': JSON.stringify([documentationDetails.user_details.birthday]) }}
                                        value={(documentationDetails.user_details.birthday || '')}
                                        showTime={false}
                                        callBackFunction={(value) => {
                                            if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                            onChangeHandlerUserDetails({ target: { name: "birthday", value: value } });
                                        }}
                                    />
                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('national_id_type'))) &&
                                <div className="col-12 col-md-4">
                                    <BasicSelect
                                        id="Player_national_id_type"
                                        name="national_id_type"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('national_id_type')))}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-national-id-type': JSON.stringify([documentationDetails.olduser.national_id_type, documentationDetails.user_details.national_id_type]) }}
                                        required={documentationDetails.requiredFields.includes("national_id_type")}
                                        idProperty='national_id'
                                        nameProperty='national_id'
                                        handleNotifiyError={notifyError}
                                        basicInfoName='NationalIdTypes'
                                        value={documentationDetails.user_details.national_id_type}
                                        label={t('National ID type')}
                                        onChange={onChangeHandlerUserDetails}
                                        translate={{ property: 'national_id', prefix: 'DOCUMENTO_TYPE_' }}
                                    />


                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('national_id'))) &&
                                <div className="col-12 col-md-4">
                                    <TextField id="Player_national_id" name="national_id" disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('national_id')))} label={t("National ID")}
                                        required={documentationDetails.requiredFields.includes("national_id")}
                                        value={documentationDetails.user_details.national_id} onChange={onChangeHandlerUserDetails}></TextField>
                                </div>
                            }

                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('nationality'))) &&
                                <div className="col-12 col-md-3">
                                    <BasicSelect
                                        id="Player_nationality"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('nationality')))}
                                        required={documentationDetails.requiredFields.includes('nationality')}
                                        idProperty='country'
                                        data='countries'
                                        basicInfoName='Countries'
                                        handleNotifiyError={notifyError}
                                        value={documentationDetails.user_details.nationality}
                                        label={t("Nationality")}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-nationality': JSON.stringify([documentationDetails.user_details.national_id_type, documentationDetails.user_details.nationality]) }}
                                        name='nationality'
                                        onChange={onChangeHandlerUserDetails}
                                    />
                                </div>
                            }

                            <div className="col-12 col-md-4">
                                <DateAndTimePicker
                                    id="Player_expiration_date"
                                    key={"expiration_date"}
                                    label={t("Expiration date")}
                                    renderInput={(props) => <TextField  {...props} />}
                                    value={documentationDetails.expiration_date}
                                    showTime={false}
                                    handleNotifyError={notifyError}
                                    callBackFunction={(value) => {
                                        if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                        onChangeHandler({ target: { name: "expiration_date", value: value } });
                                    }}

                                />
                            </div>
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('country'))) &&
                                <div className="col-12 col-md-3">
                                    <BasicSelect
                                        required
                                        id="Player_country"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('country')))}

                                        idProperty='country'
                                        data='countries'
                                        basicInfoName='Countries'
                                        value={documentationDetails.user_details.country}
                                        label={t('Country')}
                                        handleNotifiyError={notifyError}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-country': JSON.stringify([documentationDetails.user_details.national_id_type, documentationDetails.user_details.nationality, documentationDetails.user_details.country]) }}
                                        name='country'
                                        onChange={onChangeHandlerUserDetails}
                                    />
                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('state'))) &&
                                <div className="col-12 col-md-3">
                                    <BasicSelect
                                        id="Player_state"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('state')))}
                                        required={documentationDetails.requiredFields.includes("state")}
                                        idProperty='state'
                                        basicInfoName='States'
                                        handleNotifiyError={notifyError}
                                        value={documentationDetails.user_details.state}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-validate-if-empty': true }}
                                        name='state'
                                        label={t('State')}
                                        onChange={onChangeHandlerUserDetails}
                                        params={[
                                            { property: 'country', value: documentationDetails.user_details.country || '' }
                                        ]}
                                    />

                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('province'))) &&
                                <div className="col-12 col-md-3">
                                    <BasicSelect
                                        id="Player_province"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('province')))}
                                        idProperty='province'
                                        basicInfoName='Provinces'
                                        required={documentationDetails.requiredFields.includes("province")}
                                        value={documentationDetails.user_details.province}
                                        name='province'
                                        label={t('Province')}
                                        handleNotifiyError={notifyError}
                                        onChange={onChangeHandlerUserDetails}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-validate-if-empty': true }}
                                        params={[
                                            { property: 'country', value: documentationDetails.user_details.country || '' },
                                            { property: 'state', value: documentationDetails.user_details.state || '' }
                                        ]}
                                        multiple={false}
                                        noEmptyValue={false}
                                        disableClearable={false}
                                    />

                                </div>
                            }
                            {(documentationDetails.hiddenFields.length === 0 || !(documentationDetails.hiddenFields.length > 0 && documentationDetails.hiddenFields.includes('city'))) &&
                                <div className="col-12 col-md-3">
                                    <BasicSelect
                                        id="Player_city"
                                        disabled={!(documentationDetails.noEditFields.length === 0 || !(documentationDetails.noEditFields.length > 0 && documentationDetails.noEditFields.includes('city')))}
                                        idProperty='city'
                                        basicInfoName='Cities'
                                        required={documentationDetails.requiredFields.includes("city")}
                                        value={documentationDetails.user_details.city}
                                        name='city'
                                        label={t('City')}
                                        validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-validate-if-empty': true }}
                                        handleNotifiyError={notifyError}
                                        onChange={onChangeHandlerUserDetails}
                                        params={[
                                            { property: 'country', value: documentationDetails.user_details.country || '' },
                                            { property: 'state', value: documentationDetails.user_details.state || '' },
                                            { property: 'province', value: documentationDetails.user_details.province || '' }
                                        ]}
                                        multiple={false}
                                        noEmptyValue={false}
                                        disableClearable={false}
                                    />

                                </div>}
                        </div>

                        <div className="row" >
                            <MessagesList label={{ "code": t("Reason"), "description": t("Message to client") }} type="DOCUMENTATION_USER_IDENTITY" onChangeHandler={onChangeHandlerReject} />
                        </div>
                        <div className="row">
                            {documentationDetails.user_files !== undefined &&
                                documentationDetails.user_files.map((file) => {
                                    let adminClient = new AdminClient();
                                    file.user = documentationDetails.user_details.user;
                                    return <div className="col-md-6">
                                        <File
                                            onlyViewer={true}
                                            inputFile={adminClient.getFile(session, file)}
                                            inputFileExtension={file.path.substring(file.path.lastIndexOf('.'))}
                                        />
                                    </div>
                                })
                            }
                        </div>
                    </form>
                </>
            }

            {
                documentationDetails.columns !== undefined &&
                <div className="row">
                    <BasicTable data={documentationDetails.operations} key="table-pending-documentation" columns={documentationDetails.columns || []} showFilterColumn={true} />
                </div>
            }
        </LayoutSection >
    )
}

export default withTranslation()(PendingDocumentation)
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";

function I18nCompaniesDetails(props) {

  const { t } = props;

  const [i18nCompaniesDetail, setI18nCompaniesDetail] = useState({ key: "", langs: {} });

  const [newOrUpdate, setNewOrUpdate] = useState("");

  const session = JSON.parse(utils.getSessionItem("user")).session;

  const company = utils.getCurrentCompany().company;

  let adminClient = new AdminClient();

  const langs = JSON.parse(utils.getSessionItem("basicInfo-languages"));

  useEffect(function () {
    let keyId = utils.getSessionItem("current-key");
    adminClient.getI18nCompaniesByKey(session, company, keyId).then((res) => {
      let i18nInfo = res;
      let nameObject = {};

      for (const e of i18nInfo.data) {
        let langPref = e.lang.split("-")[0];
        let newValue = {
          "value": e.value,
          "description": e.description
        }
        nameObject[langPref] = newValue;
      }

      let i18nNew = {
        key: keyId,
        langs: nameObject,
      };

      if (keyId !== "-1") {
        setNewOrUpdate("update");
      } else {
        setNewOrUpdate("new");
      }

      setI18nCompaniesDetail(i18nNew);
    });

    window.addEventListener("keydown", enterListener);
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInfo = { ...i18nCompaniesDetail };

    if (name === "key") {
      newInfo[name] = value;
    }
    else {
      let parseName = name.split(".");
      let newValue = newInfo["langs"][parseName[1]];
      if (newValue === undefined) {
        newValue = {
          "value": "",
          "description": ""
        }
      }
      if (parseName[0] === "value") newValue.value = value;
      else if (parseName[0] === "description") newValue.description = value;
      newInfo["langs"][parseName[1]] = newValue;
    }
    setI18nCompaniesDetail(newInfo);
  };

  const handleDelete = () => {

    let promise = adminClient.deleteI18NCompanies(
      session,
      company,
      i18nCompaniesDetail.key
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          props.showAlert(t("Ok"), t("Deleted succesfull"))
          goBack();
        }
        else {
          props.showAlert(t("Error"), t("Deleting error"))
        }
      }, function (err) {
        console.log("Delete I18N error " + err.code);
        props.showAlert(t("Error"), t("Deleting error"))
      }
    );
  };

  const enterListener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      handleSubmit();
    }
  }

  const handleSubmit = () => {

    let promise = adminClient.saveI18NCompanies(
      session,
      company,
      i18nCompaniesDetail.key,
      JSON.stringify(i18nCompaniesDetail.langs)
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          if (newOrUpdate === "new") {
            props.showAlert(t("Ok"), t("Saved succesfull"));
            goBack();
          }
          else {
            setTimeout(() => {
              window.close();
            }, 200);
          }
        } else {
          props.showAlert(t("Error"), t("Saving error"))
        }
      }, function (err) {
        console.log("Save I18NCompanies error " + err.code);
        props.showAlert(t("Error"), t("Saving error"))
      }
    );

  };


  const langsInput = langs.map((value) => {
    let langPref = value.lang.split("-")[0];
    let valueInfo = i18nCompaniesDetail["langs"][langPref];
    if (valueInfo === undefined) {
      let newValue = {
        "value": "",
        "description": ""
      }
      valueInfo = newValue;
    }
    return (<>
      <div className="col-6 ">
        <TextField
          name={"value." + langPref} id="id" label={t(value.name)} type="text" value={valueInfo["value"]} onChange={onChangeHandler}
        />
      </div>

      <div className="col-6 ">
        <TextField
          name={"description." + langPref} id="id" label={t("Description")} type="text" value={valueInfo["description"]} onChange={onChangeHandler}
        />
      </div>
    </>)


  });

  const goBack = () => {
    props.history.push("/reports/report/i18nCompanies/table");
  };


  const dataHeader = {
    backLink: goBack,
    title: t("i18Companies config"),
    urlHelp: "",
    idDoc: props.idDoc
  };


  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <div className="row">
        <div className="card-body m-0">
          <div className="row">
            <div className="col-12 ">
              <TextField
                disabled={newOrUpdate === "new" ? false : true} name="key" id="id" label={t("Key")} type="text" value={i18nCompaniesDetail.key} onChange={onChangeHandler}
              />
            </div>
            {langsInput}
          </div>

          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                {t("Save")}
              </button>
              <button
                hidden={newOrUpdate === "new" ? true : false}
                className="btn btn-secondary"
                onClick={handleDelete}
              >
                {t("Delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  );
}

export default withTranslation()(I18nCompaniesDetails);

import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";
import JSONEditor from "jsoneditor";


function EditorJSON(props) {
    const jsonEditorRef = useRef(null);
    const mode = props.onlyViewer ? "view" : "code";
    const name = props.name || ""
    const height = props.height || "400px"
    useEffect(() => {
        const container = document.getElementById('jsoneditor' + name);
        const options = {
            mode: mode,
            onChange: (json) => {
                onChangeHandlerJSON()
            },
        };
        const editor = new JSONEditor(container, options);
        jsonEditorRef.current = editor;
    }, []);

    useEffect(() => {
        let json = jsonEditorRef.current.getText()
        let data;
        let parseJson;
        if (json === '{}' || json === "null") {
            try {
                if (typeof props.value === "string") {
                    parseJson = JSON.parse(props.value);
                }
                else {
                    parseJson = props.value;
                }
                data = json === "" ? "" : JSON.parse(json);
                if (JSON.stringify(parseJson) != JSON.stringify(data)) {
                    jsonEditorRef.current.set(parseJson)
                }
            } catch {
                if (JSON.stringify(props.value) !== JSON.stringify(json)) {
                    if (props.value === undefined) {
                        jsonEditorRef.current.set("")
                    }
                    else {
                        if (json === "{}") {
                            jsonEditorRef.current.set(props.value)

                        }
                    }
                }

            }
        }

    }, [props.value])


    const onChangeHandlerJSON = () => {
        let json = jsonEditorRef.current.getText()
        try {
            if (json === "") {
                props.onChange(null)
                props.setError(false)
            }
            else {
                props.onChange(JSON.parse(json))
                props.setError(false)
            }

        }
        catch {
            props.setError(true);
        }
    };

    return (
        <>{name !== "" ?
            <>
                <ul className="nav nav-tabs" id="cmsBodyTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="cms-body-tab" data-bs-toggle="tab" data-bs-target="#cms-body" type="button" role="tab" aria-controls="cms-body" aria-selected="false">{props.name}</button>
                    </li>
                </ul>
                <div className="tab-content" id="cmsBodyContent" style={{ marginBottom: "10px" }}>

                    <div id={"jsoneditor" + name} style={{ height: height }} />
                </div>
            </>
            :
            <div id={"jsoneditor"} style={{ height: height }} />
        }</>
    );
}


export default withTranslation()(EditorJSON);

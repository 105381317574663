import React, { useEffect, useRef } from 'react'

const TextHTMLRender = (props) => {

    const convertURLsToLinks = (text) => {

        // Eliminar las etiquetas HTML
        let noHTML = text.replace(/<\/?[^>]+(>|$)/g, "");
        const urlPattern = /(https?:\/\/[^\s]+)/g; // Expresión regular para detectar URLs con http o https
        return noHTML.replace(urlPattern, '<a href="$1" target="_blank">$1</a>'); // Convierte las URLs en enlaces
    }

    const textRef = useRef(null)

    useEffect(() => {
        if (textRef.current !== null) {
            //Eliminando cualquier etiqueta y solo dejamos urls,
            textRef.current.innerHTML = convertURLsToLinks(props.value);
            //Permitimos que se meta cualquier etiqueta. 
            //textRef.current.innerHTML = props.value
        }

    }, [textRef])

    return (
        <div ref={textRef}></div>
    )
}

export default TextHTMLRender
import React from "react";
import { io } from "socket.io-client";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import utils from "./utils";
import AdminClient from "./AdminClient";


class NotificationClient extends React.Component {

	constructor(props) {
		super(props);

		console.log("NotificationClient ");
		this.showOptions = this.showOptions.bind(this);
		this.updateStatus = this.updateStatus.bind(this);
		this.handleMessage = this.handleMessage.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.closeChat = this.closeChat.bind(this);
		this.userProfile = this.userProfile.bind(this);
		this.minimizeChat = this.minimizeChat.bind(this);
		this.maximizeChat = this.maximizeChat.bind(this);
		this.formatUserMessage = this.formatUserMessage.bind(this);
		this.openChat = this.openChat.bind(this);

		this.state = { status: "DISCONNECTED", connectionOptionsVisible: "d-none", chatWindowVisible: "d-none", chatWindowMaximize: "d-none" };
		window.openChat = this.openChat;


	}

	openChat(user, alias) {
		console.log("Wants to open chat with user " + user);
		var message = {
			type: "openChatFromSupport",
			user: user,
			alias: alias
		};
		this.socket.emit('chat', message);
	}

	handleDragEnd(evt) {

		console.log("Drop : " + evt);

		document.getElementById('chatWindow').style.top = this.offset[1] + evt.pageY + "px";
		document.getElementById('chatWindow').style.left = this.offset[0] + evt.pageX + "px";
	}

	userProfile() {
		utils.setSessionItem("current-user", + this.state.userChatting);
		this.props.history.push('/support/user_details');
	}

	pickup(e) {
		var el = document.getElementById('chatWindow');

		if (e.clientX) {
			this.offset = [el.offsetLeft - e.clientX, el.offsetTop - e.clientY];
		}
		else if (e.touches) {
			// for touch devices, use 1st touch only
			this.offset = [el.offsetLeft - e.touches[0].pageX, el.offsetTop - e.touches[0].pageY];
		}
		//console.log(this.offset);
	}

	handleDragStart(evt) {
		console.log("Dragstart : " + evt);
	}
	async getOneGlobalParam(name) {
		var adminClient = new AdminClient();
		return adminClient.getOneGlobalParam(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), name)
	}

	async componentDidMount() {
		const { t } = this.props;

		console.log("NotificationClient mounted");

		document.getElementById("chatWindow").addEventListener('mousedown', this.pickup);
		document.getElementById("chatWindow").addEventListener('dragend', this.handleDragEnd);
		document.getElementById("chatWindow").addEventListener('dragstart', this.handleDragStart);

		// let notifications = (utils.getCurrentCompany().config.backoffice?.notifications === undefined) ? true : utils.getCurrentCompany().config.backoffice?.notifications
		let notifications = await this.getOneGlobalParam("BACKOFFICE_NOTIFICATIONS")

		if (this.socket !== undefined) this.socket.emit('disconnect');

		if (notifications.param !== "DISABLED") {

			if (process.env.REACT_APP_LOCAL_API === "1") {
				this.socket = io.connect("http://localhost:18104", { transports: ['websocket'] });
			} else {
				this.socket = io.connect(window.host, { path: "/api/notifications", transports: ['websocket'] });
			}

			this.socket.on("connect", (data) => {
				console.log("NotificationClient connect socket.id: " + this.socket.id);
				var message = {
					type: "loginSupport",
					session: JSON.parse(utils.getSessionItem("user")).session
				};
				this.socket.emit('server', message);
			});
			this.socket.on("reconnect", (data) => {
				console.log("NotificationClient reconnect socket.id: " + this.socket.id);
				var message = {
					type: "loginSupport",
					session: JSON.parse(utils.getSessionItem("user")).session
				};
				this.socket.emit('server', message);
			});

			this.socket.on("server", (data) => {
				switch (data.type) {
					case "loginSupport":
						if (data.result === "OK") {
							this.setState({ status: 'CONNECTED' });
						} else {
							this.setState({ status: 'DISCONNECTED' });
						}
						break;
					case "updateStatus":
						if (data.result === "OK") {
							this.setState({ status: data.status });
						}
						document.getElementById("connectionOptions").classList.add("d-none");
						this.setState({ optionsVisible: false })
						break;
					default:
						this.setState({ status: 'DISCONNECTED' });
				}
			});

			this.socket.on("chat", (data) => {
				console.log("Chat message: " + JSON.stringify(data));
				switch (data.type) {
					case 'callingSupport':
					case 'openChatFromSupport':

						document.getElementById("chatMessages").innerHTML = '';

						this.updateStatus('BUSY');
						console.log("Opening window...." + data.user.user);
						if (data.user.user.toString().startsWith('anonymous')) {
							this.setState({ chatWindowVisible: "", chatId: data.chatId, userChatting: data.user.user, userInChat: t("Anonymous user") });
						} else {
							this.setState({ chatWindowVisible: "", chatId: data.chatId, userChatting: data.user.user, userInChat: data.user.alias + " ( " + data.user.user + " ) " + t("CHAT_REASON_" + data.extra) });
						}

						if (data.type === 'callingSupport') {
							var msg = {
								type: "supportMessage",
								user: this.state.userChatting,
								chatId: this.state.chatId,
								msg: "Hola, ¿le puedo ayudar en algo?"
							}
							this.socket.emit("chat", msg);
						}
						break;
					case 'userMessage':
						console.log("user message : " + data.msg);
						document.getElementById("chatMessages").appendChild(this.formatUserMessage(data.msg));
						document.getElementById("chatMessages").scrollTo(0, document.getElementById("chatMessages").scrollHeight);
						break;
					case 'userExitChat':
						console.log("user exit chat : " + data.msg);
						document.getElementById("chatMessages").appendChild(this.formatUserMessage(t("Has left the chat")));
						document.getElementById("chatMessages").scrollTo(0, document.getElementById("chatMessages").scrollHeight);
						break;
					case 'supportMessage':
						console.log("support message : " + data.msg);
						document.getElementById("chatMessages").appendChild(this.formatSupportMessage(data.msg));
						document.getElementById("chatMessages").scrollTo(0, document.getElementById("chatMessages").scrollHeight);
						break;
					default:
						console.log("Unknown message " + data.type);
				}
			});


			this.socket.on('error', (error) => {
				console.log("NotificationClient error = " + error);
			});

			this.socket.on('disconnect', (reason) => {
				console.log("NotificationClient client disconnected");
				if (reason === 'io server disconnect') {
					// the disconnection was initiated by the server, you need to reconnect manually
					console.log("NotificationClient server disconnected the client, trying to reconnect");
					this.socket.connect();
				} else {
					console.log("NotificationClient trying to reconnect again with server");
					this.socket.disconnect()
				}
				// else the socket will automatically try to reconnect
			});
		}

	}

	formatSupportMessage(msg) {
		var d1 = document.createElement('div');
		d1.className = 'supportMessageRow justify-content-end';
		var d2 = document.createElement('div');
		d2.className = 'callout border-callout callout-right supportMessageContent';
		d2.innerHTML = msg + " <b class='border-notch notch notch-right'></b>	<b class='notch notch-right'></b>";;
		var d3 = document.createElement('div');
		d3.className = 'chatMessageDate';
		d3.innerHTML = utils.formatTime();
		d2.appendChild(d3);

		d1.appendChild(d2);
		return d1;
	}

	formatUserMessage(msg) {

		var d1 = document.createElement('div');
		d1.className = 'userMessageRow';
		var d2 = document.createElement('div');
		d2.className = 'callout border-callout callout-left userMessageContent';
		d2.innerHTML = msg + " <b class='border-notch  notch notch-left border-notch-left'></b>	<b class='notch notch-left'></b>";
		var d3 = document.createElement('div');
		d3.className = 'chatMessageDate';
		d3.innerHTML = utils.formatTime();
		d2.appendChild(d3);

		d1.appendChild(d2);
		return d1;
	}

	handleMessage(evt) {
		this.setState({ "message": evt.target.value });
	}


	sendMessage() {
		var msg = {
			type: "supportMessage",
			user: this.state.userChatting,
			chatId: this.state.chatId,
			msg: this.state.message
		}
		document.getElementById("message").value = "";
		this.setState({ "message": "" });
		this.socket.emit("chat", msg);
	}


	showOptions() {
		console.log("connectionOptionsVisible = " + this.state.connectionOptionsVisible);

		if (this.state.connectionOptionsVisible === undefined || this.state.connectionOptionsVisible.length === 0) {
			this.setState({ connectionOptionsVisible: "d-none" });
		} else {
			this.setState({ connectionOptionsVisible: "" });
		}
	}

	updateStatus(status) {
		if (this.state.status === "DISCONNECTED" && status !== "DISCONECTED") {
			var msg = {
				type: "loginSupport",
				session: JSON.parse(utils.getSessionItem("user")).session
			}
			this.socket.emit("server", msg);
		};
		console.log("Updating status to " + status);
		msg = {};
		msg.type = "updateStatus";
		msg.status = status;
		this.socket.emit("server", msg);
	}

	closeChat() {
		console.log("Closing chat...");
		document.getElementById("chatMessages").innerHTML = "";
		this.setState({ chatWindowVisible: "d-none" });
		this.setState({ connectionOptionsVisible: "d-none" });
		var msg = {
			type: "supportExitChat",
			chatId: this.state.chatId
		}
		this.socket.emit("chat", msg);
	}

	minimizeChat() {
		console.log("Minimizing chat...");
		this.setState({ chatWindowVisible: "d-none" });
		this.setState({ chatWindowMaximize: "" });
	}
	maximizeChat() {
		console.log("Maximizing chat...");
		this.setState({ chatWindowVisible: "" });
		this.setState({ chatWindowMaximize: "d-none" });
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div className="notificationWidget float-end">
					<div id="notificationStatus">
						<a href="#" className="float-start" onClick={this.showOptions} title={t("NOTIFICATION_STATUS_" + this.state.status)}>
							<FontAwesomeIcon className={"notification_status_" + this.state.status} icon="circle" />
						</a>
						<div id="chatWindowMaximize" className={this.state.chatWindowMaximize}>
							<button className="btn btn-secondary w-auto toolBarOption" onClick={this.maximizeChat}><FontAwesomeIcon icon="window-maximize" /></button>
						</div>
					</div>
					<div id="connectionOptions" className={this.state.connectionOptionsVisible}>
						<div className="row option" onClick={() => this.updateStatus('DISCONNECTED')} >
							<div className="col-2">{this.state.status === "DISCONNECTED" && <FontAwesomeIcon icon="check" />}</div>
							<div className="col-10">{t("Notification Status Disconnected")}</div>
						</div>
						<div className="row option" onClick={() => this.updateStatus('CONNECTED')} >
							<div className="col-2">{this.state.status === "CONNECTED" && <FontAwesomeIcon icon="check" />}</div>
							<div className="col-10">{t("Notification Status Connected")}</div>
						</div>
						<div className="row option" onClick={() => this.updateStatus('BUSY')} >
							<div className="col-2">{this.state.status === "BUSY" && <FontAwesomeIcon icon="check" />}</div>
							<div className="col-10">{t("Notification Status Busy")}</div>
						</div>
						<div className="row option" onClick={() => this.updateStatus('AVAILABLE')} >
							<div className="col-2">{this.state.status === "AVAILABLE" && <FontAwesomeIcon icon="check" />}</div>
							<div className="col-10">{t("Notification Status Available")}</div>
						</div>
					</div>

				</div>
				<div id="chatWindow" draggable className={"col-12 col-sm-6 vh-50 vw-50 " + this.state.chatWindowVisible}>
					<div id="chatToolbar">
						<button className="btn btn-secondary w-auto toolBarOption" onClick={this.closeChat}><FontAwesomeIcon icon="window-close" /></button>
						<button className="btn btn-secondary w-auto toolBarOption" onClick={this.minimizeChat}><FontAwesomeIcon icon="window-minimize" /></button>
						<button className="btn btn-primary w-auto float-start toolBarUser" onClick={this.userProfile}>{this.state.userInChat}</button>

					</div>
					<div id="chatMessages"></div>
					<div id="chatCommands">
						<input className="w-100" type="text" id="message" onChange={this.handleMessage}></input>
						<button className="btn btn-primary w-25" onClick={this.sendMessage}>{t("Send")}</button>
					</div>
				</div>
			</>

		)

	}

};


export default withTranslation()(withRouter(NotificationClient));
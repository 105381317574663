import React, { useEffect, useRef, useState } from 'react'
import AdminClient from '../../AdminClient'
import { Link } from 'react-router-dom'
import { Chart } from 'chart.js'
import utils from "../../utils";
import { withTranslation } from 'react-i18next';
import useMediaQuery from '../../hooks/MediaQuery';

const AppChart = (props) => {

    const { t } = props
    const [chart, setChart] = useState({
        header: { title: "" },
        isMobile: false,
        graph: {},
        details: <></>,
        minValue: 0,
        labels: [],
        reportData: []
    })

    const [visible, setVisible] = useState(true)

    const chartRef = useRef(null)
    const chartInstanceRef = useRef(null);
    const isMobile = useMediaQuery('(max-width: 1300px)')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (chartInstanceRef.current) {
                chartInstanceRef.current.resize()
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const findLineByLeastSquares = (values_x, values_y) => {
        var x_sum = 0;
        var y_sum = 0;
        var xy_sum = 0;
        var xx_sum = 0;
        var count = 0;

        /*
         * The above is just for quick access, makes the program faster
         */
        var x = 0;
        var y = 0;
        var values_length = values_x.length;

        if (values_length !== values_y.length) {
            throw new Error('The parameters values_x and values_y need to have same size!');
        }

        /*
         * Above and below cover edge cases
         */
        if (values_length === 0) {
            return [[], []];
        }

        /*
         * Calculate the sum for each of the parts necessary.
         */
        for (let i = 0; i < values_length; i++) {
            x = values_x[i];
            y = values_y[i];
            x_sum += x;
            y_sum += y;
            xx_sum += x * x;
            xy_sum += x * y;
            count++;
        }

        /*
         * Calculate m and b for the line equation:
         * y = x * m + b
         */
        var m = (count * xy_sum - x_sum * y_sum) / (count * xx_sum - x_sum * x_sum);
        var b = (y_sum / count) - (m * x_sum) / count;

        /*
         * We then return the x and y data points according to our fit
         */
        var result_values_x = [];
        var result_values_y = [];

        for (let i = 0; i < values_length; i++) {
            x = values_x[i];
            y = Math.round(x * m + b);
            result_values_x.push(x);
            result_values_y.push(y);
        }

        return [result_values_x, result_values_y];
    }
    const findLineByAverage = (values_x, values_y) => {

        var y_sum = 0;

        /*
         * The above is just for quick access, makes the program faster
         */
        var x = 0;
        var y = 0;
        var values_length = values_x.length;

        if (values_length !== values_y.length) {
            throw new Error('The parameters values_x and values_y need to have same size!');
        }

        /*
         * Above and below cover edge cases
         */
        if (values_length === 0) {
            return [[], []];
        }

        /*
         * Calculate the sum for each of the parts necessary.
         */
        for (let i = 0; i < values_length; i++) {
            y = values_y[i];
            y_sum += y;

        }

        var avg = Math.round(y_sum / values_length);
        var result_values_x = [];
        var result_values_y = [];

        for (let i = 0; i < values_length; i++) {
            x = values_x[i];
            result_values_x.push(x);
            result_values_y.push(avg);
        }

        return [result_values_x, result_values_y];
    }


    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        let config = {
            type: chart.graph.type,
            scaleStartValue: chart.minValue,
            data: {
                labels: chart.labels,
                datasets: chart.graph.datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        stacked: true,
                        grid: {
                            display: true,
                            color: "rgba(255,99,132,0.2)"
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                }
            }
        }
        if (isMobile) {
            config.options = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        stacked: true,
                        grid: {
                            display: true,
                            color: "rgba(255,99,132,0.2)"
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                }
            }
        }

        chartInstanceRef.current = new Chart(ctx, config);

    }, [chart])

    useEffect(() => {

        let adminClient = new AdminClient()
        adminClient.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), props.report, props.filter).then((msg) => {
            let report = msg.data
            let graphConfig = report.def.config.graph
            let details = <></>

            if (report.def.config.details !== undefined) {
                details = <Link to={report.def.config.details + "/graph"}>{t("Details Button")}</Link>
            }

            let labels = []
            let reportData = []
            let minValue = 0

            report.data.forEach(row => {
                if (graphConfig.labels.endsWith('_date')) {
                    labels.push(row[graphConfig.labels].substring(0, 10));
                } else {
                    labels.push(row[graphConfig.labels]);
                }

                graphConfig.datasets.forEach(dsDef => {
                    dsDef.label = t(dsDef.label);
                    if (reportData[dsDef.data] === undefined) {
                        reportData[dsDef.data] = [];
                    }
                    reportData[dsDef.data].push(row[dsDef.data]);
                    // console.log(element[dsDef.data]);
                    if (row[dsDef.data] < minValue) {
                        minValue = row[dsDef.data];
                    }
                });
            });

            graphConfig.datasets.forEach(dsDef => {
                dsDef.data = reportData[dsDef.data];
            });
            if (graphConfig.interpolation !== undefined) {
                try {
                    var p = Array.from(Array(labels.length).keys());
                    console.log("interpolation = " + JSON.stringify(p));
                    var inter = findLineByLeastSquares(p, reportData[graphConfig.interpolation.data]);
                    console.log("interpolation = " + JSON.stringify(inter));
                    graphConfig.interpolation.data = inter[1];
                    graphConfig.datasets.push(graphConfig.interpolation);
                } catch (err) {

                }
            }
            if (graphConfig.average !== undefined) {
                try {
                    var p2 = Array.from(Array(labels.length).keys());
                    console.log("Average = " + JSON.stringify(p2));
                    var inter2 = findLineByAverage(p2, reportData[graphConfig.average.data]);
                    console.log("Average = " + JSON.stringify(inter2));
                    graphConfig.average.data = inter2[1];
                    graphConfig.datasets.push(graphConfig.average);
                } catch (err) {

                }
            }

            setChart((prevState) => {
                prevState.header.title = t(graphConfig.title)
                prevState.minValue = minValue
                prevState.labels = labels
                prevState.details = details
                prevState.graph = graphConfig
                return { ...prevState }
            })

        }, (err) => {
            console.log("Error obteniendo el report" + err.code);
            setVisible(false)
        })

    }, [props.report, props.filter, t])


    return (


        <>
            <div className={isMobile ? "col-12 float-left pt-3 " : "col-md-6  float-left pt-3"}>
                <div className="card">
                    <div className="card-header">{chart.header.title} </div>
                    <div className="card-body p-0 p-md-3" >
                        <div style={isMobile ? { position: 'relative', height: '80vh', width: '80vw' } : { position: 'relative', height: '50vh', width: '35vw' }}>
                            <canvas ref={chartRef} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(AppChart)
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from "../../AdminClient"
import utils from "../../utils"
import useFormValidation from '../../hooks/parsleyValidation'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserUpdatePassword(props) {

    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company; const { t } = props
    const [showPassword, setShowPassword] = useState({ password: false, password2: false })
    const [password, setPassword] = useState({ password: false, password2: false })
    const user = JSON.parse(utils.getSessionItem("userDetails")).user;
    const adminClient = new AdminClient();
    const handleClickShowPassword = (evt) => {
        const { name } = evt.currentTarget
        setShowPassword((prevState) => ({ ...prevState, [name]: !prevState[name] }))
    }
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setPassword((prevState) => ({ ...prevState, [name]: value }));
    };
    const { formValidation: formPassValidation } = useFormValidation("FormPassword");
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassUser || 11

    const updatePassword = () => {
        if (formPassValidation.validate()) {
            adminClient.updatePasswordUser(
                session,
                company,
                user.db,
                user.user,
                password.password
            )
                .then(
                    function (data) {
                        if (data.result === "OK") {
                            props.showAlert(t("Ok"), t("Updated succesfull"))
                        }
                        else props.showAlert(t("Error"), t("Updating error"))
                    },
                    function (err) {
                        props.showAlert(t("Error"), t(err.description))
                    }
                );
        }
    };

    return (
        <>
            <form id="FormPassword">
                <div className="row">
                    <div className="col-12  col-md-6">
                        <TextField
                            label={t("New password")}
                            type={showPassword.password ? 'text' : 'password'}
                            name="password"
                            onChange={onChangeHandler}
                            required
                            inputProps={{
                                "data-parsley-check-text-fields-equal-message":
                                    t("New password different than re password"),
                                "data-parsley-validate-password": JSON.stringify([minLength, ""]),
                                id: "password"
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password"
                                        >
                                            {showPassword.password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField
                            label={t("Re password")}
                            required
                            type={showPassword.password2 ? 'text' : 'password'}
                            inputProps={{
                                "data-parsley-check-text-fields-equal":
                                    "[isEqual,password]",
                                "data-parsley-check-text-fields-equal-message":
                                    t("New password different than re password")
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseDown={handleClickShowPassword}
                                            onMouseUp={handleClickShowPassword}
                                            edge="end"
                                            name="password2"
                                        >
                                            {showPassword.password2 ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                            name="password2"
                            onChange={onChangeHandler}

                        />
                    </div>
                </div>
            </form>
            <button className="btn btn-primary" onClick={updatePassword}>{t("Update")}</button>
        </>
    )

}

export default withTranslation()(UserUpdatePassword)


import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from '../../components/DateAndTimePicker'
import BasicSelect from "../../components/basic/BasicSelect";
import MissionDetails from '../Marketing/MissionDetails';

function IncidentDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_Incidents')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const incidentId = utils.getSessionItem('current-incident')
    const [incident, setIncident] = useState({
        incident: incidentId,
        created_date: null,
        user: JSON.parse(utils.getSessionItem('user')).user,
        description: "",
        previous_value: "",
        new_value: "",
        init_date: utils.getDateTZTodayObj().getTime(),
        end_date: utils.getDateTZTodayObj().getTime(),
        type: ""
    });
    const goBack = () => {
        props.history.goBack();
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Incidents'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (incidentId !== undefined && incidentId !== '-1') {
            adminClient.getIncidentDetails(session, company, incidentId).then(
                async function (result) {
                    result.data.init_date = utils.formatDate(result.data.init_date);
                    result.data.end_date = utils.formatDate(result.data.end_date);
                    setIncident(
                        result.data
                    );
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setIncident((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            let incidentDetails = JSON.parse(JSON.stringify(incident))
            incidentDetails.init_date = utils.unformatDate(new Date(incidentDetails.init_date))
            incidentDetails.end_date = utils.unformatDate(new Date(incidentDetails.end_date))
            adminClient[addOrUpdate + "IncidentDetails"](
                session,
                company,
                JSON.stringify(incidentDetails)
            ).then(
                (msg) => {
                    if (msg.result === 'OK' && msg.code === undefined) {
                        props.showAlert(t('Incidents'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {

                            utils.setSessionItem('current-incident', msg.data.incident)
                            setIncident({ ...incident, "incident": msg.data.incident })
                            setAddOrUpdate('update')
                        }

                    } else {
                        props.showAlert(t('Incidents'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Incidents'), t('Saving error ') + error.description)
                }
            )
        }
    }
    const remove = (evt) => {

        props.showAlert(
            t('External lists'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeAux(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
        const removeAux = (evt) => {
            adminClient.deleteIncidentDetails(
                session,
                company,
                incident.incident
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Incidents'), t('Deleted succesfull'))
                        goBack()
                    } else {
                        props.showAlert(t('Incidents'), t('Deleting error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Incidents'), t('Deleting error ') + error.description)
                }
            )
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_Incidents">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('ID')}
                            name="id"
                            disabled
                            type="text"
                            value={incident.incident}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('Created date')}
                            name="id"
                            disabled
                            type="text"
                            value={incident.created_date !== null ? utils.formatDate(incident.created_date) : ""}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('User')}
                            name="user"
                            disabled
                            type="text"
                            value={incident.user}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <TextField
                            label={t('Previous value')}
                            name="previous_value"
                            type="text"
                            value={incident.previous_value}
                            onChange={onChangeHandler}
                            inputProps={{ maxLength: 255 }}

                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <TextField
                            label={t('New value')}
                            name="new_value"
                            type="text"
                            value={incident.new_value}
                            onChange={onChangeHandler}
                            inputProps={{ maxLength: 255 }}

                        />
                    </div>
                    <div className="col-12 col-md-12">

                        <TextField
                            label={t('Description')}
                            name="description"
                            required
                            type="text"
                            value={incident.description}
                            onChange={onChangeHandler}
                            multiline
                        />
                    </div>

                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            label={t("Init Date")}
                            required
                            fieldName="init_date"
                            value={incident.init_date}
                            showTime={true}
                            callBackFunction={(value, fieldName) => {

                                onChangeHandler({ target: { name: fieldName, value: value } });
                            }}
                            id={"init_date"}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            label={t("End Date")}
                            required
                            fieldName="end_date"
                            value={incident.end_date}
                            showTime={true}
                            callBackFunction={(value, fieldName) => {
                                onChangeHandler({ target: { name: fieldName, value: value } });
                            }}
                            id={"end_date"}
                            validations={
                                {
                                    "data-parsley-validate-date-range": "init_date",
                                    "data-parsley-validate-date-range-message":
                                        t("PARSLEY_VALIDATION_DATE_RANGE")
                                }
                            }
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty='type'
                            nameProperty='type'
                            required
                            basicInfoName='IncidentTypes'
                            value={incident.type}
                            label={t('Type')}
                            onChange={(value, idProperty) => {
                                onChangeHandler({ target: { name: 'type', value: value.value } });
                            }}
                            disabled={false}
                            translate={{ property: 'name', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>

                </div>
            </form>
            <div className="row">
                {utils.isGranted("SAVE_INCIDENTS") &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        {addOrUpdate === "update" &&
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={remove}
                            >
                                {t('Delete')}
                            </button>
                        }
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(IncidentDetails);
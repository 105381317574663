import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../utils";
import Report from "../components/basic/Report";
import UserInput from "../components/UserInput";
import DocButton from "../components/basic/DocButton";


class AnalysisOperationDetails extends React.Component {

  constructor(props) {

    super(props);
    this.windowRef = "reportLogs";

    this.goBack = this.goBack.bind(this);

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }

    var operationId = utils.getSessionItem("current-operation").split(".");

    this.state = { operation: { amount: 0, currency: 'USD' }, flow_options: <div></div>, risk_weighting: 0 };
    utils.setSessionItem("ReportFilter-depositLogs", JSON.stringify([{ field: "db", type: "=", value: operationId[0] }, { field: "operation", type: "=", value: operationId[1] }]));

    this.handleUpdateNextState = this.handleUpdateNextState.bind(this);
    this.handleUpdateComments = this.handleUpdateComments.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    if (window.sections === undefined || window.sections === null) {
      this.props.history.push('/login');
    } else {

      var adminClient = new AdminClient();
      var thisAux = this;
      Promise.all([adminClient.getOperationDetails(JSON.parse(utils.getSessionItem("user")).session, operationId[0], operationId[1])]).then(
        function (result) {
          console.log("Operation  = " + JSON.stringify(result));
          thisAux.setState({ operation: result[0].data });
        },
        function (err) {
          console.error(err);
        }

      );
    }
  }

  handleSubmit() {
    var operation = this.state.operation;
    console.log("Operation to be save: " + JSON.stringify(operation));
    var adminClient = new AdminClient();
    this.setState({ errorMessage: "" });

    var thisAux = this;

    var promise = adminClient.updateOperation(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(operation));
    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          document.getElementById("comments").value = "";
          try {
            console.log("Updating report... " + thisAux.windowRef);
            window[this.windowRef].executeReport(0);
          } catch (Err) {
            console.log(Err);
          }

          thisAux.setState({ operation: msg.data });
        } else {
          thisAux.setState({ errorMessage: "" + msg.result });
        }
      }.bind(this), function (err) {
        console.log("Save operation error " + err.code);
        thisAux.setState({ errorMessage: "" + err.description });
      });

  }


  handleUpdateNextState(evt) {
    var operation = this.state.operation;
    operation.next_status = evt.target.value;
    this.setState({ "operation": operation });
  }

  handleUpdateComments(evt) {
    var operation = this.state.operation;
    operation.comments = evt.target.value;
    this.setState({ "operation": operation });
  }

  goBack(evt) {
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;


    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="col-12   float-left pt-3">
          <div className="card">
            <div className="card-header">
              {t("Operation details")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="card-body m-0">


              <div className="row">
                <div className="col-12 col-md-4 col-xxl-3">
                  <label htmlFor="user"><b>{t("Usuario")}</b></label>
                  <UserInput alias={this.state.operation.alias} user={this.state.operation.user}></UserInput>
                </div>
                <div className="col-12 col-md-4 col-xxl-2">
                  <label htmlFor="operation"><b>{t("Identifier")}</b></label>
                  <input type="text" name="operation" disabled value={this.state.operation.db + "." + this.state.operation.operation}></input>
                </div>

                <div className="col-12 col-md-4 col-xxl-2">
                  <label htmlFor="operation_date"><b>{t("Created date")}</b></label>
                  <input type="text" name="operation_date" disabled value={utils.formatDate(this.state.operation.operation_date)}></input>

                </div>

                <div className="col-12 col-md-4 col-xxl-2">
                  <label htmlFor="type"><b>{t("Type")}</b></label>
                  <input type="text" name="type" disabled value={this.state.operation.type}></input>
                </div>

                <div className="col-12 col-md-4 col-xxl-2">
                  <label htmlFor="current_status"><b>{t("Current status")}</b></label>
                  <input type="text" name="current_status" disabled value={t(this.state.operation.status)}></input>
                </div>



                <div className="col-12 col-md-4 col-xxl-2">
                  <label htmlFor="amount"><b>{t("Amount")}</b></label>
                  <input type="text" name="amount" disabled value={utils.formatCurrency(this.state.operation.amount / 100, this.state.operation.currency)}></input>
                </div>
              </div>
              <div className="row">
                <Report windowRef={this.windowRef} reportId="operation_logs" limit={3} filterId="depositLogs"></Report>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <label htmlFor="comments"><b>{t("New comment")}</b></label>
                  <textarea className="comments" rows={3} id="comments" name="comments" onChange={this.handleUpdateComments}></textarea>
                </div>
              </div>
              <div className="col-12">
                <button type="button" class="btn btn-primary" onClick={this.handleSubmit}>{t("Save")}</button>
                <button type="button" class="btn btn-secondary" onClick={this.goBack}>{t("Back")}</button>
              </div>

            </div>
          </div>

          <div className="errorMessage" id="payoutDetailsErrorMsg">{this.state.errorMessage}</div>
        </div>
      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(AnalysisOperationDetails))

import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../utils";
import TextField from '@mui/material/TextField';
// import SelectUserVirtualGoodStatus from "../components/SelectUserVirtualGoodStatus ";
import BasicSelect from "../components/basic/BasicSelect";
import DateAndTimePicker from "../components/DateAndTimePicker";
import DocButton from "../components/basic/DocButton";


class UserVirtualGoodDetails extends React.Component {

  constructor(props) {

    super(props);
    var player = JSON.parse(utils.getSessionItem("userDetails")).user;
    this.state = { isNew: Number(utils.getSessionItem("current-user_virtual_good")) === -1, virtual_good: {}, user_virtual_good: { db: player.db, user: player.user, company: player.company, virtual_good: -1, user_virtual_good: -1, status: "DONE" } };


    this.handleUpdateComments = this.handleUpdateComments.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidMount() {
    if (Number(utils.getSessionItem("current-user_virtual_good")) !== -1) {
      var virtualGoodDetails = JSON.parse(utils.getSessionItem("current_row_user_virtual_goods"));
      var virtualGoodId = virtualGoodDetails.user_virtual_good.split(".");
      var adminClient = new AdminClient();
      var thisAux = this;
      console.log("Calling getVirtualGoodDetails " + virtualGoodId[0] + " " + virtualGoodId[1]);
      var player = JSON.parse(utils.getSessionItem("userDetails")).user;
      Promise.all([adminClient.getUserVirtualGoodDetails(JSON.parse(utils.getSessionItem("user")).session, virtualGoodId[0], player.user, virtualGoodId[1])]).then(
        function (result) {
          console.log("virtual_good  = " + JSON.stringify(result[0].virtual_good));
          thisAux.setState({ user_virtual_good: result[0].user_virtual_good, virtual_good: result[0].virtual_good });
        },
        function (err) {
          console.error(err);
        }

      );
    }
  }


  handleSubmit() {
    var virtual_good = this.state.user_virtual_good;
    var adminClient = new AdminClient();
    this.setState({ errorMessage: "" });

    var thisAux = this;

    var promise = adminClient.saveUserVirtualGoodDetails(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(virtual_good));
    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          alert("OK");
          thisAux.setState({ user_virtual_good: msg.user_virtual_good, virtual_good: msg.virtual_good });
          thisAux.props.history.goBack();
        } else {
          thisAux.setState({ errorMessage: "" + msg.result });
        }
      }, function (err) {
        console.log("Save operation error " + err.code);
        thisAux.setState({ errorMessage: "" + err.description });
      });

  }

  handleUpdateComments(evt) {
    var user_virtual_good = this.state.user_virtual_good;
    user_virtual_good.comments = evt.target.value;
    this.setState({ user_virtual_good: user_virtual_good });
  }


  handleUpdateStatus(evt) {
    var user_virtual_good = this.state.user_virtual_good;
    user_virtual_good.status = evt.value;
    this.setState({ user_virtual_good: user_virtual_good });
  }



  goBack(evt) {
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;


    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="col-12   float-left pt-3">
          <div className="card">
            <div className="card-header">
              {t("Virtual Good details")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="card-body m-0">
              <div className="row">
                {!this.state.isNew &&
                  <>
                    <div className="col-12 col-md-4 ">
                      <TextField disabled label={t("Identifier")} type="text" value={this.state.user_virtual_good.db + "." + this.state.user_virtual_good.user_virtual_good} />
                    </div>

                    <div className="col-12 col-md-4 ">
                      <DateAndTimePicker
                        disabled
                        renderInput={(props) => <TextField  {...props} />}
                        label={t("Created date")}
                        value={utils.formatDate(this.state.user_virtual_good.created_date || '')}
                        showTime={true}
                      />
                    </div>
                  </>
                }


                <div className="col-4">
                  <TextField InputLabelProps={{ shrink: this.state.virtual_good.name !== undefined }} disabled label={t("VirtualGood")} type="text" value={this.state.virtual_good.name} />
                </div>

                <div className="col-4">
                  {/* <SelectUserVirtualGoodStatus label={t("Status")} value={this.state.user_virtual_good.status} onChange={this.handleUpdateStatus} /> */}
                  <BasicSelect
                    label={t("Status")}
                    idProperty="status"
                    name="userGoodStatus"
                    basicInfoName="UserVirtualGoodStatus"
                    onChange={this.handleUpdateStatus}
                    value={this.state.user_virtual_good.status}
                    translate={{ property: "status", prefix: "USER_VIRTUAL_GOOD_STATUS_" }}
                  />
                </div>


                <div className="col-12">
                  <TextField InputLabelProps={{ shrink: this.state.user_virtual_good.comments !== undefined }} multiline label={t("Comments")} type="text" value={this.state.user_virtual_good.comments} onChange={this.handleUpdateComments} />
                </div>

                <div className="col-12">
                  {utils.isGranted('SAVE_VIRTUAL_GOODS') &&

                    <button type="button" class="btn btn-primary" onClick={this.handleSubmit}>{t("Save")}</button>
                  }
                </div>

              </div>

            </div>
          </div>

          <div className="errorMessage" id="payoutDetailsErrorMsg">{this.state.errorMessage}</div>
        </div>
      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(UserVirtualGoodDetails))

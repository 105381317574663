import React from "react"
import { Link, withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import utils from '../utils';
import TextField from '@mui/material/TextField';
import BasicSelect from "../components/basic/BasicSelect";

class UserNotification extends React.Component {

  constructor(props) {
    super(props);

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }

    this.handleUpdateData = this.handleUpdateData.bind(this);
    this.handleUpdateType = this.handleUpdateType.bind(this);
    this.handleChatButton = this.handleChatButton.bind(this);
    this.handleProfileButton = this.handleProfileButton.bind(this);

    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.goBack = this.goBack.bind(this);



    var user = JSON.parse(utils.getSessionItem("userDetails")).user;




    this.state = { notification: { db: user.db, user: user.user, company: user.company, type: "POPUP" } };

    if (window.sections === undefined || window.sections === null) {
      window.sections = JSON.parse(utils.getSessionItem("sections"));
    }
    if (window.sections === undefined || window.sections === null) {
      this.props.history.push('/login');
    }
  }

  handleProfileButton() {

    var user = JSON.parse(utils.getSessionItem("userDetails")).user;
    utils.setSessionItem("current-user", user.user);

    this.props.history.push('/support/user_details/' + user.user);
  }

  handleSaveButton() {

    var clientAPI = new AdminClient();

    var promise = clientAPI.sendNotification(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(this.state.notification));
    promise.then(
      function (msg) {
        console.log("Manual notification = " + JSON.stringify(msg));
        var myModal = document.getElementById('successModal');
        myModal.style.display = "block";
      }, function (err) {
        const { t } = this.props;
        console.log("Manual notification error " + err.code);
        this.setState({ errorDescription: t("ERROR_" + err.code) })
        var myModal = document.getElementById('errorModal');
        myModal.style.display = "block";
      }.bind(this)
    );



  }

  goBack(evt) {
    this.props.history.goBack();
  }

  closeError(evt) {
    var myModal = document.getElementById('errorModal');
    myModal.style.display = "none";
  }


  handleUpdateData(evt) {
    var notification = this.state.notification;
    notification.text = evt.target.value;
    this.setState({ "notification": notification });
  }

  handleUpdateType(evt) {
    var notification = this.state.notification;
    notification.sub_type = evt.value;
    this.setState({ "notification": notification });
  }

  handleChatButton(evt) {
    console.log("Want to chat...");
    var user = JSON.parse(utils.getSessionItem("userDetails")).user;
    window.openChat(user.user, user.alias);
  }



  render() {
    const { t } = this.props;
    var user = JSON.parse(utils.getSessionItem("userDetails")).user;


    if (utils.getSessionItem("user") !== undefined) {
      return (

        <>
          <Link className="nav-link " aria-current="page" onClick={() => window.openChat(user.user, user.alias)} >
            <FontAwesomeIcon icon="comment-dots" /> {t("Chat")}
          </Link>
          <div className="card-body p-0 p-md-3" >
            <legend>{t("User notification")}</legend>
            <div className="row">
              <div className="col-12  col-xl-4">
                {/* <FormControl variant="outlined" margin={"none"} style={{ width: "100%", marginBottom: 32 }}>
                    <InputLabel id="currency-select-label">{t("Notification type")}</InputLabel>
                    < Select labelId="currency-select-label" label={t("Notification type")} onChange={this.handleUpdateType}>
                      <MenuItem key="TEXT" value="TEXT">{t('Text')}</MenuItem>
                    </Select>
                  </FormControl> */}
                <BasicSelect
                  idProperty='type'
                  value={this.state.notification.sub_type}
                  label={t('User notification')}
                  onChange={this.handleUpdateType}
                  addOpts={[{ type: 'TEXT', name: 'Text' }]}
                  translate={{ property: 'name', prefix: '' }}
                  multiple={false}
                  noEmptyValue={true}
                  disableClearable={false}
                />
              </div>
              <div className="col-12 col-md-12">
                <TextField
                  multiline
                  label={t("Data")} type="text"
                  onChange={this.handleUpdateData}
                />
              </div>
            </div>

            <button className="btn btn-primary" onClick={this.handleSaveButton}>{t("Send")}</button>
            <div className="errorMessage" id="loginErrorMsg">{this.props.errorMessage}</div>

            <div class="modal " id="successModal" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="successModalLabel">{t("Notification sent")}</h5>
                  </div>
                  <div class="modal-body">
                    {t("Notification sent ok explanation")}
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={this.goBack}>{t("Close")}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal " id="errorModal" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="errorModalLabel">{t("Notification Error")}</h5>
                  </div>
                  <div class="modal-body">
                    {t("Notification sent error explanation")}
                    <p>{this.state.errorDescription}</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={this.closeError}>{t("Close")}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </>
      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(UserNotification))

import React from 'react';
import { useRef } from 'react';

import { withTranslation } from 'react-i18next';



function UploaderField(props) {

    const acceptedFiles = props.acceptedFiles || ".csv";
    const label = props.label || "";
    const { t } = props;

    const fileInputRef = useRef(null);

    const handleUploadFile = () => {
        fileInputRef.current.click();
    };

    const isValidExtension = (fileName, validExtensions) => {
        const fileExtension = "." + fileName.split('.').pop().toLowerCase();
        return validExtensions.split(',').map(ext => ext.trim().toLowerCase()).includes(fileExtension);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        fileInputRef.current.value = ''; // Restablecer el valor del campo de entrada de archivos
        const partes = file.name.split('.');
        const extension = '.' + partes[partes.length - 1];
        if (props.maxSize !== undefined && file.size > props.maxSize) {
            props.showAlert(t('Error upload file'), t('Error maxSize'));
        }
        else if (!isValidExtension(file.name, acceptedFiles)) {
            props.showAlert(t('Invalid file format'), t('Please select a valid file format'));
        }
        else {
            props.showAlert(t("CSV"), <p>{t("We are going to process") + " " + file.name}</p>,
                [<button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Cancel")}</button>,
                <button type="button" className="btn btn-primary"
                    onClick={(evt) => {
                        props.handleCloseAlert();

                        props.callBackFunction(file);
                    }}>{t("Ok")}</button>]
            );
        }
    };

    return (

        <div>
            <input type="file" id="myFileInput" accept={acceptedFiles} style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
            <button type="button" disabled={props.disabled} className="btn btn-primary" onClick={handleUploadFile}>
                {t(label)}
            </button>

        </div>
    );
}

export default withTranslation()(UploaderField)
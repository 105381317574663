import React, { createContext, useState } from "react";
import utils from '../utils'

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const { children } = props;
  let user={session:''}  
  if(utils.getSessionItem('user')!==null){
    user=JSON.parse(utils.getSessionItem('user'))
  }  
  const {session}=user;
  const [sessionInfo, setSessionInfo] = useState(session);
  const setSessionDataInfo=(session)=>{
    setSessionInfo(session)
  }
 

  return (
    <AuthContext.Provider value={{ sessionInfo, setSessionDataInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
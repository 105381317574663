import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../utils";
import Report from "../components/basic/Report";
import TextField from '@mui/material/TextField';

import { DateAndTimePicker } from "../components/DateAndTimePicker";
import DocButton from "../components/basic/DocButton";




class UserChatDetails extends React.Component {

  constructor(props) {

    super(props);
    this.windowRef = "chat_messages";

    this.goBack = this.goBack.bind(this);
    this.handleUpdateTitle = this.handleUpdateTitle.bind(this);
    this.handleUpdateSummary = this.handleUpdateSummary.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdateCreatedDate = this.handleUpdateCreatedDate.bind(this);

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }

    var chatId = utils.getSessionItem("current-chat");

    this.state = { chatId: chatId, chat: { created_date: new Date(), title: '', summary: '' } };
    utils.setSessionItem("ReportFilter-chat_messages", JSON.stringify([{ field: "chat", type: "=", value: chatId }]));

    if (window.sections === undefined || window.sections === null) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    var adminClient = new AdminClient();
    var thisAux = this;
    var player = JSON.parse(utils.getSessionItem("userDetails")).user;

    console.log("Calling getChatDetails " + this.state.chatId);
    Promise.all([adminClient.getChatDetails(JSON.parse(utils.getSessionItem("user")).session, player.company, this.state.chatId)]).then(
      function (result) {
        console.log("Chat  = " + JSON.stringify(result[0].data));
        thisAux.setState({ chat: result[0].data });
      },
      function (err) {
        console.error(err);
      }

    );
  }

  handleSubmit() {
    const { t } = this.props;
    var chat = this.state.chat;
    console.log("Chat  to be save: " + JSON.stringify(chat));
    var adminClient = new AdminClient();
    this.setState({ errorMessage: "" });

    var thisAux = this;

    var promise = adminClient.saveChatDetails(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(chat));
    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          document.getElementById("errorMessage").value = "";
          alert(t("Chat updated!"));
          try {
            console.log("Updating report... " + thisAux.windowRef);
            window[this.windowRef].executeReport(0);

          } catch (Err) {
            console.log(Err);
          }

        } else {
          thisAux.setState({ errorMessage: "" + msg.result });
        }
      }, function (err) {
        console.log("Save operation error " + err.code);
        thisAux.setState({ errorMessage: "" + err.description });
      });

  }

  handleUpdateTitle(evt) {
    var chat = this.state.chat;
    chat.title = evt.target.value;
    this.setState({ "chat": chat });
  }
  handleUpdateCreatedDate(evt) {
    var chat = this.state.chat;
    chat.created_date = evt.target.value;
    this.setState({ "chat": chat });
  }

  handleUpdateSummary(evt) {
    var chat = this.state.chat;
    chat.summary = evt.target.value;
    this.setState({ "chat": chat });
  }



  goBack(evt) {
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;


    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="col-12   float-left pt-3">
          <div className="card">
            <div className="card-header">
              {t("Chat details")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="card-body m-0">
              <div className="row">


                <div className="col-12">
                  <DateAndTimePicker
                    disabled
                    label={t("Requested from")}
                    value={this.state.chat.created_date}
                    showTime={true}
                    callBackFunction={this.handleUpdateCreatedDate}
                  />
                </div>
              </div> <div className="row">
                <div className="col-12">
                  <TextField
                    label={t("Title")} type="text"
                    value={this.state.chat.title}
                    onChange={this.handleUpdateTitle}
                  />

                </div>
              </div> <div className="row">

                <div className="col-12">
                  <TextField
                    multiline
                    label={t("Summary")} type="text"
                    value={this.state.chat.summary}
                    onChange={this.handleUpdateSummary}
                  />

                </div>

              </div>


              <div className="row ">
                <button type="button" class="btn btn-primary" onClick={this.handleSubmit}>{t("Save")}</button>
                <button type="button" class="btn btn-secondary" onClick={this.goBack}>{t("Back")}</button>
              </div>

              <div className="row">
                <Report windowRef={this.windowRef} reportId="user_chat_messages" limit={10} filterId="chat_messages"></Report>
              </div>

            </div>
          </div>

          <div className="errorMessage" id="errorMessage">{this.state.errorMessage}</div>
        </div>
      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(UserChatDetails))

import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import Report from '../../components/basic/Report';
import Tab from '../../components/basic/BasicTabs';
import { withRouter } from 'react-router';
import AgentsMenu from '../../components/AgentsMenu';

import { Password, SendAndRetrieve } from '../../components/agents/componentsUsers';


function UserAccounts(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();

    const [tabsContent, setTabsContent] = useState([]);

    const { t } = props;
    const [userAccount, setUserAccount] = useState({
        player: { created_date: null },
        content: <div></div>,
        risk_weighting: 0,
        password: "",
        password2: "",
    });

    let user = utils.getSessionItem('current-user');

    const dataHeader = {
        title: userAccount.player.alias + " (" + userAccount.player.db + "." + userAccount.player.user + ", " + userAccount.player.currency + ")",
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        let path = props.location.pathname.split("/");
        let db = path[path.length - 2]
        let userId = path[path.length - 1];
        user = db + "." + userId

        utils.setSessionItem('current-user', user)

        adminClient.userDetails(session, user).then(
            function (result) {
                utils.setSessionItem("userDetails", JSON.stringify(result.user));
                let risk_weighting = 0;
                result.groups.forEach(element => {
                    risk_weighting += element.risk_weighting;
                });
                let riskLevel = "risk_low";
                if (risk_weighting > 20 && risk_weighting <= 50) {
                    riskLevel = "risk_medium";
                }
                if (risk_weighting > 50) {
                    riskLevel = "risk_high";
                }

                let userAccountOld = { ...userAccount };
                userAccountOld.riskLevel = riskLevel;
                userAccountOld.player = result.user;
                userAccountOld.disabled = true;
                userAccountOld.detailed = false;
                userAccountOld.risk_weighting = risk_weighting;
                userAccountOld.detailsBtnLavel = t("More details");

                setUserAccount(userAccountOld);
            },
            function (err) {
                console.error(err);
            }
        );

    }, []);


    const GGRRender = () => {
        return (<>
            <Report windowRef="windowRefSummary" reportId="agents_user_summary" location={props.location}></Report>
        </>)
    }

    const depositRender = () => {
        let idempotence = utils.generateUID()
        return (<>
            <SendAndRetrieve userAccount={userAccount.player} idempotence={idempotence} showActionType={true} updateOperations={refreshReports} />
            <div className="row">
                <Report windowRef="windowRefUserAccounts" title=" " reportId="user_accounts_agents" location={props.location}></Report>
            </div>
        </>)
    };
    const payoutRender = () => {
        return (<>
            <Report windowRef="windowRefProcessPayoutsAgents" handleClickRowComplete={payoutPopup} rowId="operation"
                reportId="process_payouts_agents" location={props.location}></Report>
        </>)
    }


    const operationsRender = () => {

        return (
            <>
                <Report windowRef="windowOperationsUser" reportId="agents_user_last_operations" location={props.location}></Report>
            </>)
    }

    const hierarchyRender = () => {
        return (<>
            <Report windowRef="windowReflastBets" reportId="agents_user_hierarchy" location={props.location} callFilter={userAccount.player.user}></Report>
        </>)
    }

    const betsRender = () => {
        return (<>
            <Report windowRef="windowReflastBets" reportId="agents_user_last_bets" location={props.location}></Report>
        </>)
    }
    const casinoRender = () => {
        return (<>
            <Report windowRef="windowReflastCasino" reportId="agents_user_last_casino" location={props.location}></Report>
        </>)
    }
    const passwordRender = () => {
        return (<><Password userAccount={userAccount.player} /></>)
    }

    const updateTables = () => {
        let tabAux = [
            // ...(utils.isGranted('AGENTS_VIEW_USER_ACCOUNTS') ? [{ title: t("User Accounts"), content: userAccountsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_DEPOSIT') ? [{ title: t("Agent Deposit/Payout"), content: depositRender }] : []),
            ...(utils.isGranted('AGENTS_VIEW_USER_GGR') ? [{ title: t("GGR"), content: GGRRender }] : []),
            ...(utils.isGranted('PROCESS_PAYOUT_AGENTS') ? [{ title: t("Pay Payout"), content: payoutRender }] : []),
            ...(utils.isGranted('AGENTS_USER_OPERATIONS') ? [{ title: t("Operations"), content: operationsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Bets"), content: betsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Hierarchy"), content: hierarchyRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Casino"), content: casinoRender }] : []),
            ...(utils.isGranted('AGENTS_UPDATE_PASSWORD') ? [{ title: t("Password update"), content: passwordRender }] : [])
        ]
        setTabsContent(tabAux)
    }

    useEffect(() => {
        let tabAux = [
            // ...(utils.isGranted('AGENTS_VIEW_USER_ACCOUNTS') ? [{ title: t("User Accounts"), content: userAccountsRender }] : []),            
            ...(utils.isGranted('AGENTS_USER_DEPOSIT') ? [{ title: t("Agent Deposit/Payout"), content: depositRender }] : []),
            ...(utils.isGranted('AGENTS_VIEW_USER_GGR') ? [{ title: t("GGR"), content: GGRRender }] : []),
            ...(utils.isGranted('PROCESS_PAYOUT_AGENTS') ? [{ title: t("Pay Payout"), content: payoutRender }] : []),
            ...(utils.isGranted('AGENTS_USER_OPERATIONS') ? [{ title: t("Operations"), content: operationsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Bets"), content: betsRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Hierarchy"), content: hierarchyRender }] : []),
            ...(utils.isGranted('AGENTS_USER_ACTIVITY') ? [{ title: t("Casino"), content: casinoRender }] : []),
            ...(utils.isGranted('AGENTS_UPDATE_PASSWORD') ? [{ title: t("Password update"), content: passwordRender }] : [])
        ]
        setTabsContent(tabAux)
    }, [userAccount])
    const refreshReports = () => {
        //window["windowRefAgentAccounts"].executeReport(0);
        if (window['windowRefUserAccounts']) window['windowRefUserAccounts'].executeReport(0);
        if (utils.isGranted('PROCESS_PAYOUT_AGENTS') && window['windowRefProcessPayoutsAgents']) window['windowRefProcessPayoutsAgents'].executeReport(0);
        if (window['windowReflastBets']) window['windowReflastBets'].executeReport(0);
        if (window['windowReflastCasino']) window['windowReflastCasino'].executeReport(0);
        if (window['windowRefSummary']) window['windowRefSummary'].executeReport(0);
    }

    const payoutPopup = (row) => {
        let operationId = row['operation']
        let amount = row['amount'] / 100
        let otp = companyDetails.config.agents !== undefined && companyDetails.config.agents.otp !== undefined && companyDetails.config.agents.otp;
        let idempotence = "";
        props.showAlert(
            t('Payout process'),
            <div style={{ textAlign: "center", width: "400px" }}>
                <p>{t('Payout process confirmation')}</p>
                <p>{t("ID")} <b>{operationId}</b> {t("Amount")} <b>{amount} </b>  {t("Currency")} <b>{userAccount.player.currency} </b>  </p>
                {otp &&
                    <TextField label={t("Payout Key")}
                        onChange={evt => {
                            setUserAccount((prevState) => ({ ...prevState, otp_key: evt.target.value }))
                        }}>
                    </TextField>
                }
                {(companyDetails.config?.backoffice?.agents?.payout?.idempotence === "true") &&
                    <>
                        <div className="col-12">
                            <TextField label={t("AGENTS_PAYOUT_IDEMPOTENCE")}
                                onChange={evt => {
                                    idempotence = evt.target.value
                                }}>

                            </TextField>
                        </div>
                    </>

                }
                <TextField multiline label={t("Comments")}
                    onChange={evt => {
                        setUserAccount((prevState) => ({ ...prevState, payoutComments: evt.target.value }))
                    }}>
                </TextField>
            </div>
            ,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={() => {
                    payout(operationId, idempotence)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }
    const payout = (operationId, idempotence) => {
        if (companyDetails.config?.backoffice?.agents?.payout?.idempotence === "true" && idempotence !== "") {

            adminClient.validateIdempotenceManualPayout(
                session,
                company,
                idempotence
            ).then(
                (msg) => {
                    let result = msg.data;
                    if (result?.length > 0) {
                        props.showAlert(t('Nº transacction in use'), showResults(result[0]), undefined, "s3")
                    } else {
                        processPayout(operationId, idempotence)
                    }
                },
                (error) => {
                    props.showAlert(t('User accounts'), t('Check transaction number error ') + error.description);
                }
            )
        }
        else {
            processPayout(operationId)
        }

    }
    const processPayout = (operationId, idemp) => {
        let idempotence = companyDetails.config?.backoffice?.agents?.payout?.idempotence ? idemp : undefined;
        adminClient.manualProcessPayout(
            session,
            company,
            userAccount.player.user,
            operationId.split('.')[0],
            operationId.split('.')[1],
            userAccount.payoutComments,
            idempotence,
            userAccount.otp_key).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('User accounts'), t('Saved succesfull'));
                        refreshReports();
                        updateTables();

                    } else {
                        props.showAlert(t('User accounts'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('User accounts'), t('Saving error ') + error.description);
                    refreshReports();
                    updateTables();
                }
            )

    }

    const showResults = (result) => {
        const partes = result.idempotence.split("_");
        const idempotence = partes[partes.length - 1];
        const extra = JSON.parse(result.extra);
        return <>
            <div className='col-12'>
                <h3 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }} > {t("Nº transacction")}: </h3>
                <h4 style={{ display: 'inline-block', width: '50%' }}>{idempotence}</h4>
            </div>
            <hr></hr>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Alias")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.alias}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Amount")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.amount / 100}</span>
            </div>
            <div className='col-12'>
                <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Date")}:</h5>
                <span style={{ display: 'inline-block', width: '50%' }}>{result.updated_date}</span>
            </div>
            {extra !== undefined && extra !== null &&
                <div className='col-12'>
                    <h5 style={{ textAlign: 'left', display: 'inline-block', width: '50%' }}>{t("Cajero")}:</h5>
                    <span style={{ display: 'inline-block', width: '50%' }}>{extra.operator}</span>
                </div>
            }
        </>
    }

    const getTabsRender = () => {
        return <Tab active={0} renderAll={false}>
            {
                tabsContent.map((tab, idx) =>
                    <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                        {tab.content()}
                    </Tab.TabPane>
                )
            }
        </Tab>
    }

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>

                <div className="row">

                    <div className="col-12">
                        {getTabsRender()}


                    </div>
                </div>

            </LayoutSection >
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(withRouter(UserAccounts));
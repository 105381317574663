import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from "../../components/DateAndTimePicker";
import momentTimeZone from 'moment-timezone';
import Report from "../../components/basic/Report";
import BasicTable from "../../components/basic/BasicTable";



function UserPromotionsDetails(props) {

    const { t } = props;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    const player = JSON.parse(utils.getSessionItem("userDetails")).user;
    const userPromotionId = utils.getSessionItem("current-user-promotion-id").split(".");

    const [promotionDetails, setPromotionDetails] = useState(
        {
            db: userPromotionId[0],
            user_promotion_id: userPromotionId[1],
            status: "ACTIVE"
        }
    );

    const [altenarBonus, setAltenarBonus] = useState([]);
    const adminClient = new AdminClient();

    const from = momentTimeZone(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    const to = momentTimeZone(new Date(new Date().getTime())).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));

    const filter_logs = [
        { "field": "db", "operator": "=", "value": userPromotionId[0] },
        { "field": "user_promotion_id", "operator": "=", "value": userPromotionId[1] },
        { "field": "log_date", "type": "time_range", "value": [utils.unformatDate(from), utils.unformatDate(to)] },
    ];


    utils.setSessionItem("ReportFilter-user_promotions_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-user_promotions_logs", JSON.stringify([{ field: "db", visibility: "hidden" }, { field: "user_promotion_id", visibility: "hidden" }]));



    useEffect(function () {
        adminClient.getUserPromotiontDetails(session, userPromotionId[0], userPromotionId[1]).then(
            (res) => {
                setPromotionDetails(res.data);
            }, (err) => {
                console.log(err);
            }
        );

    }, []);


    const sortBonus = (a, b) => {
        return (a.BonusAccountId < b.BonusAccountId) ? 1 : ((b.BonusAccountId < a.BonusAccountId) ? -1 : 0);
    }

    const altenarStatus = () => {
        const fechaHora = new Date(promotionDetails.created_date);
        const dateFrom = fechaHora.toISOString().split("T")[0];

        adminClient.getAltenarUserPromotionHistory(session, promotionDetails, dateFrom).then(
            function (msg) {

                if (msg.result === 'OK') {
                    setAltenarBonus(msg.data.GetClientBonusHistoryMessageResult.GetClientBonusInfoMessageResult.sort(sortBonus))
                } else {
                    props.showAlert(t("Promotion saved"), <p>{t(msg.result)}</p>);
                }
            }, function (err) {
                props.showAlert(t("Promotion saved"), <p>{t(err.description)}</p>);
            });
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setPromotionDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerAmount = (e) => {
        const { name, value } = e.target || e;
        setPromotionDetails((prevState) => ({ ...prevState, [name]: value * 100 }));
    };



    const handleSubmit = () => {
        if (formValidation.validate()) {
            let promotionsDetailsAux = { ...promotionDetails };
            if (promotionsDetailsAux.provider !== null && promotionsDetailsAux.provider !== 'altenar' && promotionsDetailsAux.provider !== 'kambi') {
                promotionsDetailsAux.amount = promotionsDetailsAux.amount / 100;
                promotionsDetailsAux.initial_amount = promotionsDetailsAux.initial_amount / 100;
            }
            let promise = adminClient.saveUserPromotion(
                session,
                JSON.stringify(promotionDetails)
            );

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Promotion saved"), <p>{t("Promotion saved")}</p>);
                        goBack()
                    }

                }, function (err) {
                    console.log("Promotion saved error" + err);
                    props.showAlert(t("Error"), <p>{t("Promotion saved error")}</p>)
                }
            );

        }

    };


    const goBack = () => {
        props.history.push("/support/user_promotions");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Promotion details"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("Form_PromotionsDetails");
    const altenarColumns = [
        {
            Header: t("Created date"), accessor: 'CreatedDate', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let CreatedDate = props.row.original.CreatedDate;
                return utils.formatDate2(CreatedDate)
            }
        },
        {
            Header: t("External id"), accessor: 'BonusAccountId', typeCell: 'text', filter: 'text', Filter: true
        },
        {
            Header: t("Status"), accessor: 'BonusStatus', typeCell: 'text', filter: 'text', Filter: true
        },
        {
            Header: t("Amount"), accessor: 'Amount', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let Amount = props.row.original.Amount;
                return Amount / 100
            }
        },
        {
            Header: t("Initial Amount"), accessor: 'InitialBonus', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let InitialBonus = props.row.original.InitialBonus;
                return InitialBonus / 100
            }
        },
        {
            Header: t("WR"), accessor: 'RolloverRemain', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let RolloverRemain = props.row.original.RolloverRemain;
                return RolloverRemain / 100
            }
        },
        {
            Header: t("Initial WR"), accessor: 'RolloverRequired', typeCell: 'text', filter: 'text', Filter: true,
            Cell: (props) => {
                let RolloverRequired = props.row.original.RolloverRequired;
                return RolloverRequired / 100
            }
        }
    ]

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PromotionsDetails">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField InputLabelProps={{ shrink: (promotionDetails.db + "." + promotionDetails.user_promotion_id) !== undefined }}
                            className={"text-center"}
                            label={t("Identifier")} type="text"
                            disabled
                            value={promotionDetails.db + "." + promotionDetails.user_promotion_id} />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField InputLabelProps={{ shrink: (promotionDetails.promotion) !== undefined }}
                            className={"text-center"}
                            label={t("Promotioon")} type="text"
                            disabled
                            value={promotionDetails.promotion} />
                    </div>

                    <div className="col-12 col-md-4">
                        <BasicSelect
                            label={t("Status")}
                            nameProperty="description"
                            idProperty="status"
                            name="status"
                            basicInfoName="PromotionsStatus"
                            onChange={onChangeHandler}
                            value={promotionDetails.status}
                            translate={{ property: "status", prefix: "PROMOTION_STATUS_" }}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <DateAndTimePicker
                            disabled
                            renderInput={(props) => <TextField  {...props} />}
                            label={t("Created date")}
                            value={utils.formatDate(promotionDetails.created_date || '')}
                            showTime={true}
                        />

                    </div>
                    {(promotionDetails.status !== "PENDING") &&
                        <div className="col-12 col-md-4">
                            <DateAndTimePicker
                                disabled
                                renderInput={(props) => <TextField  {...props} />}
                                label={t("Activation date")}
                                value={utils.formatDate(promotionDetails.activation_date || '')}
                                showTime={true}
                            />
                        </div>
                    }

                    {promotionDetails.status === "REDEEMED" &&
                        <div className="col-12 col-md-4">
                            <DateAndTimePicker
                                disabled
                                renderInput={(props) => <TextField  {...props} />}
                                label={t("Redeemed date")}
                                value={utils.formatDate(promotionDetails.redeemed_date || '')}
                                showTime={true}
                            />
                        </div>
                    }
                    {promotionDetails.status === "CANCELLED" &&
                        <>
                            <div className="col-12 col-md-4">
                                <DateAndTimePicker
                                    disabled
                                    renderInput={(props) => <TextField  {...props} />}
                                    label={t("Cancelled date")}
                                    value={utils.formatDate(promotionDetails.cancelled_date || '')}
                                    showTime={true}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <TextField
                                    className={"text-center"}
                                    label={t("Cancelled by")} type="text"
                                    disabled
                                    value={promotionDetails.cancelled_admin_alias || t('Player')} />
                            </div>
                        </>
                    }
                    <div className="col-12 col-md-4">
                        <TextField InputLabelProps={{ shrink: promotionDetails.provider !== undefined }}
                            className={"text-center"}
                            label={t("Provider")} type="text"
                            disabled
                            value={promotionDetails.provider} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField InputLabelProps={{ shrink: promotionDetails.amount !== undefined }}
                            className={"text-center"}
                            label={t("Amount")} type="number"
                            disabled={!(utils.isGranted('SAVE_USER_PROMOTION'))}
                            name="amount"
                            onChange={onChangeHandlerAmount}
                            value={promotionDetails.amount / 100} />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField InputLabelProps={{ shrink: promotionDetails.initial_amount !== undefined }}
                            className={"text-center"}
                            label={t("Initial Amount")} type="number"
                            disabled={!(utils.isGranted('SAVE_USER_PROMOTION'))}
                            onChange={onChangeHandlerAmount}
                            name="initial_amount"
                            value={promotionDetails.initial_amount / 100} />

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField InputLabelProps={{ shrink: promotionDetails.wr !== undefined }}
                            type="number"
                            className={"text-center"}
                            label={t("WR")}
                            disabled={!(utils.isGranted('SAVE_USER_PROMOTION'))}
                            onChange={onChangeHandlerAmount}
                            name="wr"

                            value={promotionDetails.wr / 100} />

                    </div>

                    <div className="col-12 col-md-6">
                        <TextField InputLabelProps={{ shrink: promotionDetails.initial_wr !== undefined }}
                            className={"text-center"}
                            label={t("Initial WR")} type="number"
                            disabled={!(utils.isGranted('SAVE_USER_PROMOTION'))}
                            onChange={onChangeHandlerAmount}
                            name="initial_wr"
                            value={promotionDetails.initial_wr / 100} />
                    </div>


                    <div className="col-12 col-md-6">
                        <TextField InputLabelProps={{ shrink: promotionDetails.external_id !== undefined }}
                            className={"text-center"}
                            label={t("External id")} type="text"
                            disabled
                            onChange={onChangeHandler}
                            name="external_id"
                            value={promotionDetails.external_id} />
                    </div>


                    <div className="col-12 ">
                        <TextField multiline InputLabelProps={{ shrink: promotionDetails.comments !== undefined }}
                            className={"text-center"}
                            label={t("Comments")} type="text"
                            disabled={!utils.isGranted('SAVE_USER_PROMOTION')}
                            onChange={onChangeHandler}
                            name="comments"
                            value={promotionDetails.comments} />
                    </div>
                </div>

            </form >

            <div className="row">

                <div className="col-12">
                    {utils.isGranted('SAVE_USER_PROMOTION') &&
                        <button type="button" class="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                    }
                </div>
            </div>
            <div className="row">

                <div className="col-12 ">
                    <Report windowRef="windowRefUserPromotionLogs" reportId="user_promotions_logs" viewFilter="user_promotions_logs" limit="10"></Report>
                </div>

            </div>



            {promotionDetails.provider === 'altenar' &&
                <>
                    <div className="row justify-content-end me-3">
                        <button type="button" class="btn btn-secondary" style={{ width: "auto" }} onClick={altenarStatus}>{t("Altenar status")}</button>
                    </div>
                    <div className="row">
                        <BasicTable data={altenarBonus} columns={altenarColumns} showFilterColumn={true} />
                    </div>
                    {/* <div className="card-body">
                            <table className="table table-striped table-hover table_layout_fixed">
                                <thead><tr id="tr">
                                    <th className="text">{t("Created date")}</th>
                                    <th className="text">{t("External id")}</th>
                                    <th className="text">{t("Status")}</th>
                                    <th className="number">{t("Amount")}</th>
                                    <th className="number">{t("Initial Amount")}</th>
                                    <th className="number">{t("WR")}</th>
                                    <th className="number">{t("Initial WR")}</th>
                                </tr></thead>
                                <tbody>

                                    {altenarBonus.map((bonus, idx) => {
                                        return (
                                            <tr key={bonus.BonusAccountId} id={"bonus_" + idx}  >
                                                <td>{utils.formatDate2(bonus.CreatedDate)}</td>
                                                <td>{bonus.BonusAccountId}</td>
                                                <td>{bonus.BonusStatus}</td>

                                                <td className="number">{bonus.Amount / 100}</td>
                                                <td className="number">{bonus.InitialBonus / 100}</td>
                                                <td className="number">{bonus.RolloverRemain / 100}</td>
                                                <td className="number">{bonus.RolloverRequired / 100}</td>

                                            </tr>)
                                    })
                                    }
                                </tbody>
                            </table>
                        </div> */}

                </>
            }

        </LayoutSection >
    );
}

export default withTranslation()(UserPromotionsDetails);

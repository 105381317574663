import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import BasicSelect from '../../components/basic/BasicSelect'
import useFormValidation from '../../hooks/parsleyValidation';
import { TextField } from '@mui/material';

import { readNodesAndEdges, writeFlowStatusList, getEdgesFromNode } from '../../components/Flows/utilsFlows';

import ReactFlow, {
    Background,
    addEdge,
    Panel,
    useNodesState,
    useEdgesState,
    MarkerType,
    ReactFlowProvider,
    Controls,
    ControlButton,
    useReactFlow
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../../components/Flows/styles.css'
import FlowStatusDetails from '../../components/Flows/FlowStatusDetails';
import FlowStatusNode from '../../components/Flows/FlowStatusNode';
import FlowEdge from '../../components/Flows/FlowEdge';
import { loadBasicInfo } from '../../libs/utilsBasicInfo';
import FlowDownloadImg from '../../components/Flows/FlowDownloadImg';
import FlowConnectionLine from '../../components/Flows/FlowConnectionLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//FLOW PRINCIPAL

const nodeTypes = { statusNode: FlowStatusNode }
const edgeTypes = { edgeNode: FlowEdge }



function FlowsDetails(props) {

    const { t } = props

    let flowId = utils.getSessionItem("current-flow");
    const { formValidation } = useFormValidation("Form_flowDetails")
    const [flowDetail, setFlowDetail] = useState({ flow: '', name: "", status: "ENABLED", config: {} });
    const [addOrUpdate, setAddOrUpdate] = useState("add");
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;




    let adminClient = new AdminClient();

    //NodesType and EdgeTypes

    const initialNodes = []
    const initialEdges = []

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [nodesToDelete, setNodesToDelete] = useState([])
    const [viewPort, setViewPort] = useState({})

    const [rfInstance, setRfInstance] = useState(null);




    const onConnect = useCallback(
        (params) => {
            let edgeNew = {}
            setNodes((nds) => {
                let handle = params.sourceHandle.split('-')
                let indexHandle = handle[2].split('_')
                let nodeFind = nds.findIndex((n, index) => {
                    return n.id === params.source
                })
                if (nodeFind !== -1) {
                    let n = nds[nodeFind]
                    let keyReact = Object.keys(n.data.config.reacts)[indexHandle[1]]
                    let nameReact = n.data.config.reacts[keyReact].name
                    let color = n.data.config.diagram.color
                    if (n.data.config.reacts[keyReact].actions === undefined)
                        n.data.config.reacts[keyReact].actions = []
                    n.data.config.reacts[keyReact].actions.push({
                        action: "TRANSITION",
                        name: `Transition to ${params.target}`,
                        type: "TRANSITION",
                        new_status: params.target
                    })
                    edgeNew = {
                        ...params,
                        id: `edge-${params.source}-${params.target}-${handle[2]}`,
                        data: {
                            label: `${nameReact}`,
                            deleteEdge: onEdgeDelete,
                            type: `TRANSITION`
                        },
                        type: 'edgeNode',
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: color,
                        },
                        style: {
                            strokeWidth: 1,
                            stroke: color,
                        }
                    }
                    nds[nodeFind] = n
                }
                return nds

            })

            setEdges((eds) => addEdge(edgeNew, eds))
        },
        [setNodes]
    )


    const removeTransitionFromNode = useCallback((id) => {
        setNodes((nds) => {
            let handle = id.split('-')
            let indexHandle = handle[3].split('_')
            let nodeFind = nds.findIndex((n, index) => {
                return n.id === handle[1]
            })
            if (nodeFind !== -1) {
                let n = nds[nodeFind]
                let keyReact = Object.keys(n.data.config.reacts)[indexHandle[1]]
                let nodeActions = n.data.config.reacts[keyReact].actions.filter((act) => act.type !== 'TRANSITION')
                n.data.config.reacts[keyReact].actions = nodeActions
                nds[nodeFind] = n
            }
            return nds
        })

    }, [setNodes])

    const onEdgeDelete = useCallback((idTransition) => {
        removeTransitionFromNode(idTransition)
        setEdges((eds) => eds.filter((e) => e.id !== idTransition));
    },
        [setNodes]
    )

    const onUpdateNodesStatus = useCallback(
        (nData, isDelete) => {
            setNodes((nds) => {

                let indexFind = nds.findIndex((n, index) => (n.id === nData.id))
                if (indexFind === -1)
                    return [...nds, nData]
                else {
                    if (isDelete) {
                        nds.splice(indexFind, 1)
                        setNodesToDelete((ndsDelete) => {

                            let indexDelete = ndsDelete.findIndex((nDel, index) => (nDel.status === nData.status))
                            if (indexDelete === -1) return [...ndsDelete, nData.data]
                            else ndsDelete[indexDelete] = nData.data
                            return [...ndsDelete]

                        })
                    }
                    else nds[indexFind].data = nData.data
                    return [...nds]
                }
            })
            setEdges((eds) => {

                //Si queremos borrar tenemos que quitar todos las conexiones donde es source y donde es target
                if (isDelete) {
                    let edsToDelete = eds.filter((e) => (e.target === nData.id || e.source === nData.id))
                    edsToDelete.forEach((e) => { removeTransitionFromNode(e.id) })
                    return eds.filter((e) => (e.target !== nData.id && e.source !== nData.id))
                } else {
                    let edsFromNode = getEdgesFromNode(nData.data, onEdgeDelete)
                    let otherEds = eds.filter((e) => e.source !== nData.id)
                    return [...otherEds, ...edsFromNode]
                }
            })
        }

        , [setNodes])

    //Flow Diagram

    //FlowDetails

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e
        let newFlowDetail = { ...flowDetail }
        newFlowDetail[name] = value
        setFlowDetail(newFlowDetail)
    }

    const saveFlow = () => {


        if (formValidation.validate()) {
            let flowConfig = { ...flowDetail }
            if (rfInstance) {
                let flowDiagram = rfInstance.toObject();
                flowConfig['status_list'] = writeFlowStatusList(flowDetail, flowDiagram)
                flowConfig['config'] = {}
                flowConfig['config']['viewport'] = flowDiagram.viewport
                flowConfig['statusDelete'] = nodesToDelete
            }

            let promise = adminClient[addOrUpdate + "Flow"](session, company, JSON.stringify(flowConfig))

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Flow config"), t("Saved succesfull"))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        if (flowConfig.config.viewport) setViewPort(flowConfig.config.viewport)
                        utils.setSessionItem('current-flow', flowDetail.flow)
                        setNodes((nds) => {
                            let ndsNotNew = nds.map((n) => {
                                if (n.data.isNew) delete n.data.isNew
                                return n
                            })
                            return ndsNotNew
                        })
                    } else {
                        props.showAlert(t("Flow config"), t("Saving error") + " " + msg.description)
                    }
                }, function (error) {
                    if (error.code === -17) error.description = t("Duplicated")
                    props.showAlert(t("Flow config"), t("Saving error") + " " + error.description)
                });
        }

    }

    const deleteFlow = () => {
        props.showAlert(t('Delete') + " " + t('Flow'), <p>{t('Delete') + " " + t('Flow') + " "}<strong>{flowDetail.name}</strong></p>, [
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    onDeleteFlow()
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>,
            <button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>])
    }

    const onDeleteFlow = () => {
        let promise = adminClient.deleteFlow(
            session,
            company,
            flowId
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t("Flow config"), t("Deleted succesfull"))
                    goBack();
                } else {
                    props.showAlert(t("Flow config"), t("Deleting error") + msg.description)
                }
            }, function (err) {
                console.log("Save operation error " + err.code);
                props.showAlert(t("Flow config"), t("Deleting error") + t(""))
            });
    };


    //FLOWDetails

    const addStatus = (evt) => {
        props.showAlert(t('Status Config'), <FlowStatusDetails flow={flowDetail.flow} key={'newNode'}
            showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert}
            onUpdateStatus={onUpdateNodesStatus} />, [], 'xl')
    }

    const editStatus = (event, node) => {

        //Hacemos esto pq si no va una referencia y aunque cancelemos las modificaciones, se hacen
        let nodeCp = JSON.parse(JSON.stringify(node))
        props.showAlert(t('Status Config'), <FlowStatusDetails flow={flowDetail.flow} key={node.id + "_edit"}
            showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert}
            onUpdateStatus={onUpdateNodesStatus} statusData={nodeCp} />, [], 'xl')
    }

    useEffect(() => {
        loadBasicInfo("FlowsActions", undefined, 'data')
        loadBasicInfo("FlowsReacts", undefined, 'data')

        if (flowId !== undefined && flowId !== "-1") {

            adminClient.getFlowDetails(session, company, flowId).then(
                (res) => {
                    setFlowDetail(res.data)
                    //Generamos los nodos y las conexiones
                    const { nodes: flowNodes, edges: flowEdges } = readNodesAndEdges(res.data.status_list, onEdgeDelete);
                    setNodes(flowNodes)
                    setEdges(flowEdges)
                    setViewPort(res.data.config?.viewport)
                    setAddOrUpdate('update')
                },
                (err) => { })

        }
    }, [])

    const goBack = () => {
        props.history.push('/reports/report/flows/table')
    }

    const dataHeader = {
        backLink: goBack,
        title: t("Flow config"),
        urlHelp: "",
        idDoc: props.idDoc
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_flowDetails">
                <div className="row">
                    <div className="col-12 col-md-3  ">
                        <TextField
                            label={t("ID")}
                            required
                            InputProps={addOrUpdate === 'add' ? {
                                inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                            } : {}}
                            name="flow"
                            disabled={addOrUpdate === "update"}
                            type="text"
                            value={flowDetail.flow}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-6 ">
                        <TextField
                            label={t("Name")}
                            name="name"
                            required
                            type="text"
                            value={flowDetail.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-3 ">
                        <BasicSelect
                            idProperty="name"
                            name="status"
                            label={t("Status")}
                            basicInfoName="EnabledDisabled"
                            onChange={onChangeHandler}
                            value={flowDetail.status}
                            translate={{ property: "name", prefix: "" }}
                            disableClearable
                        />
                    </div>

                </div>
            </form>
            {addOrUpdate === "add" &&
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-primary calimaco_float_right" onClick={saveFlow}>{t("Save")}</button>
                        <button type="button" hidden={(addOrUpdate === "add") ? true : false} className="btn btn-secondary calimaco_float_right" onClick={deleteFlow}>{t("Delete")}</button>
                    </div>
                </div>}
            <div className="row">
                <div style={{ height: 600 }}>
                    {addOrUpdate === 'update' &&
                        <ReactFlowProvider>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                fitView
                                onEdgesDelete={onEdgeDelete}
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                onNodeDoubleClick={editStatus}
                                connectionLineComponent={FlowConnectionLine}
                                onInit={(instance) => {
                                    if (viewPort?.x !== undefined) instance.setViewport(viewPort)
                                    instance.fitView()
                                    setRfInstance(instance)
                                }}>
                                <Background />
                                <Controls showInteractive={false} showZoom={false}>
                                    <ControlButton onClick={() => addStatus()} title={t('Add Status')}>
                                        <FontAwesomeIcon icon='plus' />
                                    </ControlButton>
                                    <ControlButton title={t("DownloadImage")}>
                                        <FlowDownloadImg />
                                    </ControlButton>
                                    <ControlButton onClick={() => saveFlow()} title={t("Save")}>
                                        <FontAwesomeIcon icon="save" />
                                    </ControlButton>
                                    <ControlButton onClick={() => deleteFlow()} title={t("Delete")}>
                                        <FontAwesomeIcon icon='trash' />
                                    </ControlButton>
                                </Controls>
                            </ReactFlow>
                        </ReactFlowProvider>}
                </div>
            </div>
        </LayoutSection>
    )
}

export default withTranslation()(FlowsDetails)
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Tab from '../../components/basic/BasicTabs';
import Report from "../../components/basic/Report";
import DoubleList from "../../components/basic/DoubleList";
import ProgressBar from '../../components/basic/ProgressBar';
import UploaderField from '../../components/basic/UploaderField';


function GroupDetails(props) {
    const company = utils.getSessionItem("Company");
    const session = JSON.parse(utils.getSessionItem('user')).session;
    let groupId = utils.getSessionItem('current-group')
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_GroupDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [onProgress, setOnProgress] = useState(false);
    const [barProgress, setBarProgress] = useState({ show: false, text: "", percentage: "0%", textOK: false, textNOK: false })

    const [groupDetails, setGroupDetails] = useState({
        group: { group: groupId, name: "", company: company, risk_weighting: 0, loyalty_weighting: 0, criteria: "", type: "", category: "", css: "" },
        availables: [], triggers: [], items: ""
    });


    //HEADER
    const goBack = () => {
        props.history.push('/reports/report/groups/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Group Details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //Carga de datos
    useEffect(function () {
        if (groupId !== undefined && groupId !== '-1') {
            utils.setSessionItem("ReportFilter-group-members", JSON.stringify([{ field: "t.group", type: "=", value: groupId }]));
            utils.setSessionItem("ReportFilterView-group-members", JSON.stringify([]));
            adminClient.getGroupDetails(session, company, groupId).then(
                async function (result) {
                    let newInfo = { group: result.data, triggers: result.triggers, availables: result.availables };
                    setGroupDetails(newInfo);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {

            setAddOrUpdate('add');
        }
    }, []);

    const onChangeHandlerGroup = (e) => {
        const { name, value } = e.target || e;
        let oldGroupDetails = { ...groupDetails }
        oldGroupDetails.group[name] = value;
        setGroupDetails(oldGroupDetails);
    };

    const onChangeHandlerEvents = (e) => {
        let arrayTriggers = e.target || e;
        let oldGroupDetails = { ...groupDetails }
        oldGroupDetails.triggers = arrayTriggers;
        setGroupDetails(oldGroupDetails);
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let oldGroupDetails = { ...groupDetails }
        if (name === "items") {
            let newValue = value.trim();
            newValue = newValue.replace(/\n/g, ",");
            oldGroupDetails[name] = newValue;
        }
        else {
            oldGroupDetails[name] = value;
        }
        setGroupDetails(oldGroupDetails);
    };
    //Manejadores modificacion
    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveGroup(
                JSON.parse(utils.getSessionItem("user")).session, company, JSON.stringify(groupDetails.group), JSON.stringify(groupDetails.triggers)
            ).then(
                async (msg) => {
                    if (msg.result === 'OK') {
                        if (addOrUpdate === 'add') {
                            await adminClient.getGroupDetails(session, company, msg.data.group).then(
                                function (result) {
                                    let newInfo = { group: result.data, triggers: result.triggers, availables: result.availables };
                                    setGroupDetails(newInfo);
                                    groupId = result.data.group;
                                    utils.setSessionItem('current-group', groupId);
                                    utils.setSessionItem("ReportFilter-group-members", JSON.stringify([{ field: "t.group", type: "=", value: groupId }]));
                                    utils.setSessionItem("ReportFilterView-group-members", JSON.stringify([]));
                                    setAddOrUpdate('update');
                                    window["windowRefGroupMembers"].executeReport(0);

                                },
                                function (err) {
                                    console.error(err);
                                }
                            );
                            setAddOrUpdate('update')
                        }
                        props.showAlert(t('Group Details'), t('Saved succesfull'))

                    } else {
                        props.showAlert(t('Group Details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Group Details'), t('Saving error ') + t(error.description))
                }
            )
        }
    }
    const remove = (evt) => {
        adminClient.deleteGroup(session, company, JSON.stringify(groupDetails.group)).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Group Details'), t('Deleted succesfull'))
                    goBack();
                } else {

                    props.showAlert(t('Group Details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                if (error.code === "ER_ROW_IS_REFERENCED") error.description = t("Delete references")
                props.showAlert(t('Group Details'), t('Deleting error ') + ": " + t(error.description))
            }
        )

    }

    const handleAdd = (evt) => {
        setOnProgress(true);
        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = '0%'
            auxBarProgress.text = t('Loading users to charge in groups')
            auxBarProgress.textOK = false;
            auxBarProgress.textNOK = false;
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });
        let parseItems = "[" + groupDetails.items + "]";
        try {
            let aux = JSON.parse(parseItems)
            console.log(aux)
            adminClient.addManualMembers(
                session,
                company,
                groupDetails.group.group,
                "[" + groupDetails.items + "]"
            ).then(
                function (msg) {
                    console.log('Update result ' + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t('Group config'), t('Added succesfull'))
                        let errorMsg = "";
                        if (msg.incorrectMembers !== undefined && msg.incorrectMembers.length > 0) {
                            errorMsg = t("Members not added") + ":" + msg.incorrectMembers
                        }
                        setBarProgress(prevBarProgress => {
                            let auxBarProgress = { ...prevBarProgress }
                            auxBarProgress.percentage = '100%'
                            auxBarProgress.show = true;
                            auxBarProgress.text = t('Added users in groups')
                            auxBarProgress.textOK = t("Members added") + ":" + msg.correctMembers
                            auxBarProgress.textNOK = errorMsg !== "" && errorMsg
                            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                            return updatedBarProgress;
                        });
                        setOnProgress(false);
                        window["windowRefGroupMembers"].executeReport(0);
                    } else {
                        props.showAlert(t('Group config'), t('Adding error ') + msg.description)
                        setOnProgress(false);
                    }
                }, function (err) {
                    console.log('Adding operation error ' + err.code);
                    props.showAlert(t('Group config'), t('Adding error '))
                    setOnProgress(false);

                }
            );
        }
        catch (err) {
            props.showAlert(t('Group config'), t('Adding error ') + t("Format incorrect"))
            setOnProgress(false);
            setBarProgress((prevState) => ({ ...prevState, ['show']: false }))

        }

    }

    const handleRemove = (evt) => {
        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = false;
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });
        props.showAlert(
            t('Groups'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeMember(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

    }

    const removeMember = (evt) => {
        adminClient.removeManualMembers(
            session,
            company,
            groupDetails.group.group,
            "[" + groupDetails.items + "]"
        ).then(
            function (msg) {
                console.log('Update result ' + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t('Group config'), t('Deleted succesfull'))
                    window["windowRefGroupMembers"].executeReport(0);
                } else {
                    props.showAlert(t('Group config'), t('Deleting error ') + t(msg.description))
                }
            }, function (err) {
                console.log('Deleting operation error ' + err.description);
                props.showAlert(t('Group config'), t('Deleting error') + " " + t(err.description))
            }
        );
    }

    const handleRemoveAll = (evt) => {
        props.showAlert(
            t('Groups'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    removeAllMembers(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }

    const removeAllMembers = (evt) => {
        adminClient.removeAllManualMembers(
            session,
            company,
            groupDetails.group.group
        ).then(
            function (msg) {
                console.log('Update result ' + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(t('Group config'), t('Deleted succesfull') + ": " + t('Deleted remove allMembers'));
                    window["windowRefGroupMembers"].executeReport(0);

                } else {
                    props.showAlert(t('Group config'), t('Deleting error ') + msg.description)
                }
            }, function (err) {
                console.log('Deleting operation error ' + err.code);
                props.showAlert(t('Group config'), t('Deleting error '))
            }
        );
    }
    let criteriaRender = () => {
        return <>
            <div className="col-12 col-md-12">
                <TextField multiline name="criteria" label={t("Criteria")} type="text" value={groupDetails.group.criteria} onChange={onChangeHandlerGroup} />
            </div>
        </>
    }

    let eventsRender = () => {
        return <>
            <DoubleList
                configured={groupDetails.triggers}
                availables={groupDetails.availables}
                useTranslationsId={true}
                translatePrefix="TRIGGER_"
                idProperty="trigger"
                configuredListName={t("Selecteds")}
                handleUpdateList={onChangeHandlerEvents}>
            </DoubleList>
        </>
    }


    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = data.text + " " + data.partial + "/" + data.total


        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = percentage + '%';
            auxBarProgress.text = t(text);
            if (data.textOK !== undefined) auxBarProgress.textOK = t("Users added") + ": " + data.textOK
            if (data.textNOK !== undefined) auxBarProgress.textNOK = data.textNOK.length !== 0 ? t("Users not found") + ": " + JSON.stringify(data.textNOK) : false
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });

    };

    const sendCSVGroups = (file) => {
        setOnProgress(true);
        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = '0%'
            auxBarProgress.text = t('Loading users to charge in groups')
            auxBarProgress.textOK = false;
            auxBarProgress.textNOK = false;
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });

        adminClient.sendCSVGroups(
            session,
            company,
            groupDetails.group.group,
            file,
            handleResponse
        ).then(
            function (msg) {
                console.log('Update result ' + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    setBarProgress(prevBarProgress => {
                        let auxBarProgress = { ...prevBarProgress }
                        auxBarProgress.percentage = '100%'
                        auxBarProgress.show = true;
                        auxBarProgress.text = t('Added users in groups')
                        const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                        return updatedBarProgress;
                    });
                    setOnProgress(false);
                    props.showAlert(t('Group config'), t('Added succesfull'))
                    window["windowRefGroupMembers"].executeReport(0);

                } else {
                    props.showAlert(t('Group config'), t('Adding error ') + t(msg.description))
                }

            }, function (err) {
                console.log('Adding operation error ' + err.code);
                setOnProgress(false);
                props.showAlert(t('Group config'), t('Adding error ') + t(err.description))

            }
        );
    }




    let membersRender = () => {
        return <>
            {groupDetails.group.type === 'MANUAL' &&
                <div className="col-12 col-md-12">
                    <TextField multiline id="members" label={t("Members")} type="text" name="items" value={groupDetails.items} onChange={onChangeHandler}
                        placeholder="player1, player2, player3" />
                </div>
            }


            {groupDetails.group.type === 'MANUAL' && utils.isGranted('SAVE_CONFIGURATION_GROUPS') && <div className="row">

                <div className="col-12">
                    <button type="button" disabled={onProgress || groupDetails.items === "" || groupDetails.items === undefined} className="btn btn-primary" onClick={handleAdd}>{t("Add")}</button>
                    <button type="button" disabled={onProgress || groupDetails.items === "" || groupDetails.items === undefined} className="btn btn-secondary" onClick={handleRemove}>{t("Remove")}</button>
                    <button type="button" disabled={onProgress} className="btn btn-secondary" onClick={handleRemoveAll}>{t("Remove all")}</button>
                    <UploaderField
                        label="CSV"
                        acceptedFiles=".csv"
                        showAlert={props.showAlert}
                        handleCloseAlert={props.handleCloseAlert}
                        callBackFunction={sendCSVGroups}
                        disabled={onProgress}
                    />
                </div>
                {barProgress.show &&
                    <ProgressBar percentage={barProgress.percentage}
                        text={barProgress.text}
                        textOK={barProgress.textOK}
                        textNOK={barProgress.textNOK}
                    />
                }


            </div>
            }
            <Report windowRef="windowRefGroupMembers" className="mt-3" reportId="groups_members" viewFilter="group-members" filterId="group-members" view="table" ></Report>
        </>
    }

    const [tabsContent, setTabsContent] = useState(
        [
            { title: t("Criteria"), content: criteriaRender },
            { title: t("Events"), content: eventsRender },
            { title: t("Members"), content: membersRender }
        ]
    );

    useEffect(() => {
        let tabAux = [
            { title: t("Criteria"), content: criteriaRender },
            { title: t("Events"), content: eventsRender },
            { title: t("Members"), content: membersRender }
        ]
        setTabsContent(tabAux)

    }, [groupDetails, barProgress, onProgress, addOrUpdate])

    const handleRemoveGroup = (evt) => {
        props.showAlert(
            t('Groups'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove()
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_GroupDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="group"
                            required
                            disabled
                            type="text"
                            value={groupDetails.group.group}
                            onChange={onChangeHandlerGroup}
                        />
                    </div>
                    <div className="col-12 col-md-9">
                        <TextField id="name" label={t("Name")} name="name" type="text" required value={groupDetails.group.name} onChange={onChangeHandlerGroup} />
                    </div>

                    <div className="col-12 col-md-3">
                        <TextField id="risk_weighting" name="risk_weighting" label={t("Risk weighting")} type="number" value={groupDetails.group.risk_weighting} onChange={onChangeHandlerGroup} />
                    </div>

                    <div className="col-12 col-md-3">
                        <TextField id="loyalty_weighting" name="loyalty_weighting" label={t("Loyalty weighting")} type="number" value={groupDetails.group.loyalty_weighting} onChange={onChangeHandlerGroup} />
                    </div>

                    <div className="col-12 col-md-3">
                        <BasicSelect
                            disabled={addOrUpdate === 'update'}
                            idProperty="type"
                            label={t("Type")}
                            name="type"
                            basicInfoName="GroupsTypes"
                            onChange={onChangeHandlerGroup}
                            value={groupDetails.group.type}
                            translate={{ property: "type", prefix: "GROUP_TYPE_" }}
                            noEmptyValue
                        />
                    </div>
                    {addOrUpdate !== 'add' &&
                        <div className="col-12 col-md-3">
                            <BasicSelect
                                idProperty="category"
                                label={t("Category")}
                                name="category"
                                basicInfoName="GroupsCategories"
                                onChange={onChangeHandlerGroup}
                                value={groupDetails.group.category}
                                translate={{ property: "category", prefix: "GROUP_CATEGORY_" }}

                            />
                        </div>
                    }

                    <div className="col-12 ">
                        <TextField id="css" label={t("CSS")} type="text" name="css" value={groupDetails.group.css} onChange={onChangeHandlerGroup} />
                    </div>
                </div>
            </form>
            {addOrUpdate === "update" &&
                <Tab active={0}>
                    {
                        tabsContent.map((tab, idx) =>
                            <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                {tab.content()}
                            </Tab.TabPane>
                        )
                    }
                </Tab>
            }
            <div className="row">
                {utils.isGranted("SAVE_CONFIGURATION_GROUPS") &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        {addOrUpdate === "update" && groupDetails.group.type === 'MANUAL' && <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleRemoveGroup}
                        >
                            {t("Delete")}
                        </button>
                        }

                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(GroupDetails);
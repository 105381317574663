import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";


function LockedNationalIdDetails(props) {

    const { t } = props;

    const company = utils.getCurrentCompany().company;

    const [lockedNationalId, setLockedNationalId] = useState({ company: company, national_id: "", description: "" });

    const [newOrUpdate, setNewOrUpdate] = useState("");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    let adminClient = new AdminClient();


    useEffect(function () {
        let national_id = utils.getSessionItem("current-national_id");
        if (national_id === '-1') {
            setNewOrUpdate("new");
        }
        else {
            adminClient.getLockedNationalId(session, company, national_id).then((res) => {
                let info = res.data;
                Object.entries(info).map(obj => {
                    const key = obj[0];
                    const value = obj[1];
                    if (value === null) info[key] = "";
                });
                setLockedNationalId(info);
                setNewOrUpdate("update");

            }, (err) => {
                console.log(err);
            })
        }

    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let newInfo = { ...lockedNationalId };
        newInfo[name] = value;
        setLockedNationalId(newInfo);
    };

    const handleDelete = () => {
        let promise = adminClient.deleteLockedNationalId(
            session,
            company,
            lockedNationalId.national_id,
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert("Ok", "Documento borrado correctamente");
                    goBack();

                } else {
                    props.showAlert("Error", "Error borrando Documento")
                }
            }, function (err) {
                console.log("Save Documento error " + err.event);
                props.showAlert("Error", "Error borrando Documento ")
            }
        );



    };

    const handleSubmit = () => {
        let promise = adminClient.saveLockedNationalId(
            session,
            company,
            JSON.stringify(lockedNationalId)
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert("Ok", "Documento guardado correctamente");
                    goBack();
                } else {
                    props.showAlert("Error", "Error guardando Documento")
                }

            }, function (err) {
                console.log("Delete Documento " + err.description.code);
                props.showAlert("Error", "Error guardando Documento " + t(err.description))
            }
        );


    };


    const goBack = () => {
        props.history.push("/reports/report/locked_national_id/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Document locked details"),
        urlHelp: "",
        idDoc: props.idDoc
    };



    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="row" >
                <div className="card-body m-0">
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                name="national_id" disabled={newOrUpdate === "update"} id="national_id" label={t("Document")} type="text" value={lockedNationalId.national_id} onChange={onChangeHandler}
                            />
                        </div>
                        <div className="col-12">
                            <TextField
                                name="description" disabled={newOrUpdate === "update"} multiline id="description" label={t("Description")} type="text" value={lockedNationalId.description} onChange={onChangeHandler}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">

                            <button
                                hidden={newOrUpdate === "update" ? true : false}
                                className="btn btn-primary" onClick={handleSubmit}>
                                {t("Save")}
                            </button>
                            <button
                                hidden={newOrUpdate === "new" ? true : false}
                                className="btn btn-secondary"
                                onClick={handleDelete}
                            >
                                {t("Delete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutSection>
    );
}

export default withTranslation()(LockedNationalIdDetails);

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import ProgressBar from '../../components/basic/ProgressBar'
import UploaderField from '../../components/basic/UploaderField';
import Tab from '../../components/basic/BasicTabs';

function NegativeBalanceReset(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_NegativeBalanceReset')
    const [barProgress, setBarProgress] = useState({ show: false, text: "", percentage: "0%", textOK: false, textNOK: false })
    const [onProgress, setOnProgress] = useState(false);
    const [sendEmailCheckbox, setSendEmailCheckbox] = useState(false);
    const [manualAdjustment, setManualAdjustment] = useState({
        account: "",
        players: "",
        comment: ""
    });

    const dataHeader = {
        title: t('Negative balance reset'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //Carga de datos

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setManualAdjustment((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)

        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = percentage + '%';
            auxBarProgress.text = text + ": " + data.partial + "/" + data.total;
            if (data.textOK) auxBarProgress.textOK = t("Users correct") + ": " + data.textOK
            if (data.textNOK) auxBarProgress.textNOK = JSON.parse(data.textNOK).length !== 0 ? t("Users errors") + ": " + data.textNOK : false
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });

    };

    const sendBatch = (file) => {
        if (file !== undefined || formValidation.validate()) {
            setOnProgress(true);
            setBarProgress(prevBarProgress => {
                let auxBarProgress = { ...prevBarProgress }
                auxBarProgress.show = true;
                auxBarProgress.percentage = '0%'
                auxBarProgress.text = t('Updating users')
                auxBarProgress.textOK = false;
                auxBarProgress.textNOK = false;
                const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                return updatedBarProgress;
            });
            let players = manualAdjustment.players.split(/\r?\n/);
            adminClient.negativeBalanceReset(
                session,
                company,
                players,
                manualAdjustment.comment,
                handleResponse,
                file,
                sendEmailCheckbox,
                manualAdjustment.subject,
                manualAdjustment.body
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        setBarProgress(prevBarProgress => {
                            let auxBarProgress = { ...prevBarProgress }
                            auxBarProgress.percentage = '100%'
                            auxBarProgress.show = true;
                            auxBarProgress.text = t('All players updated')
                            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                            return updatedBarProgress;
                        });
                        setOnProgress(false);
                        props.showAlert(t('Manual adjunstment batch'), t('Sent succesfull'))

                    } else {
                        setOnProgress(false);
                        props.showAlert(t('Manual adjunstment batch'), t('Sending error ') + msg.description)
                    }
                },
                (error) => {
                    setOnProgress(false);
                    props.showAlert(t('Manual adjunstment batch'), t('Sending error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_NegativeBalanceReset">
                <div className="row">

                    <div className="col-12 ">
                        <TextField multiline label={t("Players")} name="players" value={manualAdjustment.players} onChange={onChangeHandler}
                            placeholder="db.player1 &#10;db.player2 &#10;db.player3" required
                        ></TextField>
                    </div>
                    <div className="col-12 ">
                        <TextField multiline label={t("Comment")} name="comment" value={manualAdjustment.comment} onChange={onChangeHandler}></TextField>
                    </div>


                    <div className='col-12'>
                        <FormControlLabel control={
                            <Checkbox checked={sendEmailCheckbox} onChange={(evt) => { setSendEmailCheckbox(evt.target.checked) }}>                           </Checkbox>
                        } label={t("Send email notification")} />
                    </div>
                    {sendEmailCheckbox &&
                        <div className='col-12'>

                            <Tab key="tabEmailContent" active={0}>
                                <Tab.TabPane key="tabKeyEmailContent" tab={t("Email content")}>
                                    <div className="col-12 ">
                                        <TextField required label={t("Subject")} name="subject" value={manualAdjustment.subject} onChange={onChangeHandler}></TextField>
                                    </div>
                                    <div className="col-12 ">
                                        <TextField required multiline label={t("Body")} name="body" value={manualAdjustment.body} onChange={onChangeHandler}></TextField>
                                    </div>
                                </Tab.TabPane>
                            </Tab>
                        </div>}

                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <UploaderField
                        label="CSV"
                        acceptedFiles=".csv"
                        showAlert={props.showAlert}
                        handleCloseAlert={props.handleCloseAlert}
                        callBackFunction={sendBatch}
                        disabled={onProgress}
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => sendBatch()}
                        disabled={onProgress}
                    >
                        {t('Send')}
                    </button>
                </div>
                {barProgress.show &&
                    <ProgressBar percentage={barProgress.percentage}
                        text={barProgress.text}
                        textOK={barProgress.textOK}
                        textNOK={barProgress.textNOK}
                    />
                }
            </div>
        </LayoutSection >
    )
}
export default withTranslation()(NegativeBalanceReset);
import React, { useEffect, useRef, useState } from 'react'
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateField, DateTimeField } from '@mui/x-date-pickers';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import utils from "../../utils";
import { useFormFieldValidation } from '../../hooks/parsleyValidation'
import { TextField } from '@mui/material';
//import moment from 'moment';




const DateAndTimePicker = (props) => {


    const localeLang = utils.getSessionItem("calimaco-lang").substring(0, 2)


    const [selectedDate, setSelectedDate] = useState(null)
    const [hiddenError, setHiddenError] = useState(null)

    const t = props
    const dateRef = useRef()
    const fieldValidation = useFormFieldValidation(dateRef?.current)



    const handleErrorField = (error, value) => {


        let notifyError = true
        if (error !== null) {
            notifyError = false
            setHiddenError(error)
        } else {
            setHiddenError(null)
        }

    }
    const getSlotsProps = () => {

        let inputProps = { 'data-parsley-validate-date-field-error': hiddenError }
        if (props.id) inputProps['id'] = props.id;
        if (props.required) inputProps['data-parsley-required'] = true
        if (props.validations) inputProps = { ...inputProps, ...props.validations }
        let slotsProps = {
            'textField': {
                clearable: true,
                required: props.required,
                inputProps: { ...inputProps },
                inputRef: dateRef,
                onError: handleErrorField

            }, // The actions will be different between desktop and mobile

            actionBar: ({ wrapperVariant }) => {
                return { actions: wrapperVariant !== 'mobile' ? [] : ['clear', 'cancel', 'accept'] }
            },
        }
        return slotsProps
    }

    const changeDate = (value) => {
        setSelectedDate(value)
        if (props.callBackFunction !== undefined && (value !== null && value.isValid() || value === null)) {

            props.callBackFunction(value === null ? null : value.toDate(), props.fieldName)
        }
    }
    useEffect(() => {

        setSelectedDate(props.value === '' || props.value === null || props.value === undefined ? null : dayjs(props.value))

    }, [props.value])

    useEffect(() => {

        if (dateRef.current !== null && fieldValidation !== undefined) {
            if (props.handleNotifyError !== undefined) {

                if (hiddenError !== null && hiddenError !== 'null') {

                    props.handleNotifyError(fieldValidation, true)
                } else {

                    props.handleNotifyError(fieldValidation, false)
                }
            }

        }
    })


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeLang}>
            {props.showTime &&
                <DateTimePicker
                    required={props.required}
                    disabled={props.disabled}
                    label={props.label}
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    defaultValue={props.value === '' || props.value === null || props.value === undefined ? dayjs(props.value) : null}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    value={selectedDate}
                    slots={{ field: DateTimeField }}
                    slotProps={getSlotsProps()}
                    onChange={changeDate}
                />}
            {!props.showTime && <DateTimePicker
                label={props.label}
                required={props.required}
                disabled={props.disabled}
                defaultValue={props.value === '' || props.value === null || props.value === undefined ? dayjs(props.value) : null}
                views={['year', 'month', 'day']}
                value={selectedDate}
                slots={{ field: DateField }}
                slotProps={getSlotsProps()}
                onChange={changeDate}
            />}

        </LocalizationProvider>
    )
}

export default DateAndTimePicker
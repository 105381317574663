import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

function I18nDetails(props) {

  const { t } = props;

  const [i18nDetail, setI18nDetail] = useState({ key: "", langs: {} });

  const [newOrUpdate, setNewOrUpdate] = useState("");

  const session = JSON.parse(utils.getSessionItem("user")).session;

  const company = utils.getCurrentCompany().company;

  const actualLang = JSON.parse(utils.getSessionItem("user")).lang;

  let adminClient = new AdminClient();

  const langs = JSON.parse(utils.getSessionItem("basicInfo-languages"));

  useEffect(function () {
    let keyId = utils.getSessionItem("current-key");
    //let keyId = "Agent accounts";

    adminClient.getLanguageByKey(session, company, keyId).then((res) => {
      let i18nInfo = res;
      let nameObject = {};

      for (const e of i18nInfo.data) {
        let langPref = e.lang.split("-")[0];
        nameObject[langPref] = e.value;
      }

      let i18nNew = {
        key: keyId,
        langs: nameObject,
      };

      if (keyId !== "-1") {
        setNewOrUpdate("update");
      } else {
        setNewOrUpdate("new");
      }

      setI18nDetail(i18nNew);
    });


  }, []);

  const enterListener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      handleSubmit();
    }
  }
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInfo = { ...i18nDetail };

    if (name === "key") {
      newInfo[name] = value;
    }
    else {
      newInfo["langs"][name] = value;
    }
    setI18nDetail(newInfo);
  };

  const handleDelete = () => {
    let promise = adminClient.deleteI18N(
      session,
      company,
      i18nDetail.key
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          props.showAlert(t("Ok"), t("Deleted succesfull"));
          setTimeout(async () => {
            try {
              await reloadI18n();
              window.close();
            } catch (err) {
              console.log(err);
              window.close();
            }
          }, 500);
        }
        else {
          props.showAlert(t("Error"), t("Deleting error"))
        }
      }, function (err) {
        console.log("Save I18N error " + err.description.code);
        props.showAlert(t("Error"), t("Deleting error") + t(err.description))
      }
    );

  };

  const handleSubmit = () => {

    let promise = adminClient.saveI18N(
      session,
      company,
      i18nDetail.key,
      JSON.stringify(i18nDetail.langs)
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          if (newOrUpdate === "new") {
            props.showAlert(t("Ok"), t("Saved succesfull"));
            goBack();
          }
          else {
            setTimeout(async () => {
              try {
                await reloadI18n();
                window.close();
              } catch (err) {
                console.log(err);
                window.close();
              }
            }, 500);
          }

        } else {
          props.showAlert(t("Error"), t("Saving error"))
        }

      }, function (err) {
        console.log("Delete I18N error " + err.description.code);
        props.showAlert(t("Error"), t("Saving error") + t(err.description))
      }
    );
  };

  const reloadI18n = () => {
    return new Promise(
      function (resolve, reject) {
        adminClient.getI18n(company).then((values) => {
          var _res = {};
          values.data.forEach(translate => {
            if (_res[translate.lang] === undefined) {
              _res[translate.lang] = { "translation": {} };
            }
            _res[translate.lang].translation[translate.key] = translate.value;
          });

          //console.log("i18n res = " + JSON.stringify(_res));
          utils.setSessionItem("i18n", JSON.stringify(_res));
          i18next
            .use(initReactI18next) // passes i18n down to react-i18next
            .init({
              resources: _res,
              lng: "es",
              fallbackLng: "en",

              interpolation: {
                escapeValue: false
              }

            });
          i18next.changeLanguage(actualLang.substring(0, 2));
          resolve();
        }, (err) => {
          console.log("Error " + err);
          reject();
        })
      });

  }


  const langsInput = langs.map((value) => {
    let langPref = value.lang.split("-")[0];
    let valueInfo = i18nDetail["langs"][langPref];
    if (valueInfo === undefined) valueInfo = "";

    return (<div className="col-12 ">
      <TextField onKeyPress={enterListener}
        name={langPref} id="id" label={t(value.name)} type="text" value={valueInfo} onChange={onChangeHandler}
      />
    </div>)


  });

  const goBack = () => {
    props.history.push("/reports/report/i18n/table");
  };


  const dataHeader = {
    backLink: goBack,
    title: t("i18c config"),
    urlHelp: "",
    idDoc: props.idDoc
  };


  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <div className="row" >
        <div className="card-body m-0">
          <div className="row">
            <div className="col-12 ">
              <TextField
                disabled={newOrUpdate === "new" ? false : true} name="key" id="id" label={t("Key")} type="text" value={i18nDetail.key} onChange={onChangeHandler}
              />
            </div>
            {langsInput}
          </div>

          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                {t("Save")}
              </button>
              <button
                hidden={newOrUpdate === "new" ? true : false}
                className="btn btn-secondary"
                onClick={handleDelete}
              >
                {t("Delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  );
}

export default withTranslation()(I18nDetails);

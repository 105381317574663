import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import UserInput from '../../components/UserInput';
import BasicSelect from '../../components/basic/BasicSelect';
import Report from '../../components/basic/Report';
import DateAndTimePicker from '../../components/DateAndTimePicker';
function PayoutsDetials(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PayoutsDetials')
    const operation = utils.getSessionItem('current-operation').split(".");
    utils.setSessionItem("ReportFilter-payoutLogs", JSON.stringify([{ field: "db", type: "=", value: operation[0] }, { field: "operation", type: "=", value: operation[1] }]));

    const [payoutDetails, setPayoutDetails] = useState({
        operation: {
            amount: 0,
            currency: 'USD',
            method: ""
        },
        flow_options: <div></div>,
        risk_weighting: 0,
        status: [],
        paymentAccount: {}
    });
    const goBack = () => {
        props.history.push('/support/report/user_payouts/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Payout details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (operation !== undefined) {
            Promise.all([adminClient.getOperationDetails(session, operation[0], operation[1]),
            adminClient.getPayoutFlowOptions(session, operation[0], operation[1])]).then(
                function (result) {
                    let payoutDetailsAux = { ...payoutDetails }
                    if (result[1].data !== undefined && result[1].data.length > 0) {
                        let status = [];
                        result[1].data.forEach(element => {
                            status.push(element.status);
                        });
                        payoutDetailsAux.status = status;
                    } else {
                        payoutDetailsAux.status = [];
                    }

                    let paymentAccount = result[0].paymentAccount;
                    if (paymentAccount === undefined) {
                        paymentAccount = {};
                    }
                    payoutDetailsAux.operation = result[0].data;
                    payoutDetailsAux.paymentAccount = paymentAccount;
                    setPayoutDetails(payoutDetailsAux);
                },
                function (err) {
                    console.error(err);
                }

            );
        }
    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setPayoutDetails(prevState => ({
            ...prevState,
            operation: {
                ...prevState.operation,
                [name]: value,
            }
        }));
    };

    const update = (evt) => {
        if (formValidation.validate()) {
            adminClient.updateOperation(
                session,
                JSON.stringify(payoutDetails.operation)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Payout details'), t('Updated succesfull'));
                        window["reportLogs"].executeReport(0);
                        adminClient.getPayoutFlowOptions(session, msg.data.db, msg.data.operation).then(
                            function (result) {

                                if (result.data !== undefined && result.data.length > 0) {
                                    let status = [];
                                    result.data.forEach(element => {
                                        status.push(element.status);
                                    });
                                    setPayoutDetails((prevState) => ({ ...prevState, status: status }));
                                } else {
                                    setPayoutDetails((prevState) => ({ ...prevState, status: [] }));
                                }
                            },
                            function (err) {
                                console.error(err);
                            }

                        );
                    } else {
                        props.showAlert(t('Payout details'), t('Updating error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Payout details'), t('Updating error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_PayoutsDetials">
                <div className="row">

                    <div className="col-12 col-md-4 col-xxl-3">
                        <UserInput alias={payoutDetails.operation.alias} user={payoutDetails.operation.user}></UserInput>
                    </div>

                    <div className="col-12 col-md-4 col-xxl-3">
                        <TextField disabled label={t("Identifier")} type="text" value={payoutDetails.operation.db + "." + payoutDetails.operation.operation} />
                    </div>



                    <div className="col-12 col-md-4 col-xxl-3">
                        <DateAndTimePicker
                            disabled
                            renderInput={(props) => <TextField  {...props} />}
                            label={t("Created date")}
                            value={utils.formatDate(payoutDetails.operation.operation_date || '')}
                            showTime={true}
                        />
                    </div>

                    <div className="col-12 col-md-4 col-xxl-3">
                        <TextField disabled label={t("Current status")} type="text" value={t(payoutDetails.operation.status)} />
                    </div>


                    <div className="col-12 col-md-4 col-xxl-3">
                        <TextField disabled label={t("Amount")} type="text" value={utils.formatCurrency(payoutDetails.operation.amount / 100, payoutDetails.operation.currency)} />
                    </div>

                    <div className="col-12 col-md-4 col-xxl-3">
                        <TextField disabled label={t("Method")} type="text" value={payoutDetails.operation.method} />
                    </div>

                    {payoutDetails.paymentAccount !== undefined && payoutDetails.paymentAccount.details !== undefined &&
                        <div className="col-12 ">
                            <TextField multiline disabled label={t("Account details")} type="text" value={payoutDetails.paymentAccount.details} />
                        </div>
                    }
                </div>
                <div className="row border m-1">
                    <div className="col-12 mt-3">
                        <BasicSelect
                            label={t("Change to status")}
                            idProperty="status"
                            basicInfoName="PayoutStatus"
                            name="next_status"
                            filters={[{ property: "status", value: payoutDetails.status }]}
                            onChange={onChangeHandler}
                            value={payoutDetails.operation.next_status}
                            translate={{ property: "status", prefix: "" }}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <TextField multiline value={payoutDetails.operation.comments} label={t("New comment")} name="comments" type="text" onChange={onChangeHandler} />
                    </div>
                </div>

                <div className="row">
                    <Report className="max_width_100" windowRef={"reportLogs"} reportId="operation_logs" limit={3} filterId="payoutLogs"></Report>
                </div>
                <div className="row">
                    <Report className="max_width_100" windowRef={"reportLogs"} reportId="transactions_communication_logs" limit={3} filterId="payoutLogs"></Report>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={update}
                    >
                        {t('Update')}
                    </button>

                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(PayoutsDetials);
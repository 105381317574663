import React, { useEffect, useState } from 'react'
import Header from '../PrivateHeader';
import { withTranslation } from "react-i18next";
import VerticalMenu from '../VerticalMenu';
import utils from "../../utils";
import { useAlert } from '../../hooks/Alert';
import AlertMessage from './AlertMessage'
import ProfileHeader from '../ProfileHeader'
import AdminClient from '../../AdminClient';


const PrivateLayout = (props) => {


    if (window.menus === undefined) {
        window.menus = JSON.parse(utils.getSessionItem("menus"));
    }
    const { t } = props;
    const mainMenu = utils.toMenuArray(t, window.menus.MAIN)
    const [selected, alertOpen, showAlert, closeAlert] = useAlert();
    useEffect(async () => {
        let adminClient = new AdminClient();
        let user = await adminClient.userDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("current-user"));

        const handleTabFocus = () => {
            if (utils.getCurrentCompany().company !== user.user.company) {
                showAlert(
                    t('Error'),
                    <p>{t('CHANGE_COMPANY_USER_DETAILS')}</p>,
                    [<button type='button' className='btn btn-primary'
                        onClick={(evt) => {
                            window.close();
                        }}>
                        {t('Ok')}</button>
                    ]
                )
            }
        };

        window.addEventListener('focus', handleTabFocus);

        return () => {
            window.removeEventListener('focus', handleTabFocus);
        };
    }, []);

    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                {/* <div id="menuContainer" className="col-12 col-md-2 px-md-2 px-0 bg-dark d-none d-md-block vertical-menu">
                    <VerticalMenu  items={mainMenu}></VerticalMenu>
                </div> 
                */}
                <div className="col-12 p-0 ">
                    {/* <Header help_url={props.help_url} showAlert={showAlert} handleCloseAlert={closeAlert}/> */}
                    <div className="container mt-0 p-0 ">
                        <main className="row p-0 m-0" >
                            <div class="col-12   float-left pt-3">
                                <div className="card p-0 mt-3">
                                    <div className="card-header profileHeader">
                                        <ProfileHeader messageAlert={props.messageAlert}></ProfileHeader>
                                    </div>
                                    <div className="card-body m-0 min-height-50">
                                        {React.Children.map(props.children, (child) =>
                                            React.cloneElement(child, {
                                                showAlert: showAlert,
                                                handleCloseAlert: closeAlert
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            <AlertMessage
                isActive={alertOpen}
                content={selected.content}
                title={selected.title}
                buttons={selected.buttons}
                handleCloseAlert={closeAlert}
                size={selected.size}
                loading={selected.loading}
            />
            <link rel="stylesheet" href="/customized/css/customized.css"></link>
            <link rel="stylesheet" href={`/customized/css/customized_${utils.getCurrentCompany().company}.css`}></link>
        </div>
    )
}

export default withTranslation()(PrivateLayout)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from "../utils";
import ProfileMenu from "./ProfileMenu";
import AdminClient from "../AdminClient";
import { searchUserById } from "../libs/utilsServerData"

class ProfileHeader extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        if (window.menus === undefined) {
            window.menus = JSON.parse(utils.getSessionItem("menus"));
        }
        var profileMenu = window.menus.PROFILE;
        //var userId = utils.getSessionItem("current-user").split(".")[1];


        this.userStatus = this.userStatus.bind(this);

        // var filter = [{ "field": "user", "operator": "=", "value": userId }];
        this.goBack = this.goBack.bind(this);

        this.user = { regulatory_status: 'PENDING', groups: [] };

        console.log("profileMenu = " + JSON.stringify(profileMenu));


        console.log("ProfileHeader user recovered " + JSON.stringify(this.user));

        this.state = {
            messageAlert: {},
            user: this.user,
            profileMenu: utils.toMenuArray(t, profileMenu, "MENU_PROFILE"),
            filter: [],
            VIEW_USERS_NFTS: utils.isGranted('VIEW_USERS_NFTS') !== undefined,
            section_config: { elements: [] }
        };
        console.log("ProfileHeader");


    }

    componentWillUnmount() {

        console.log("UserPersonalDetails: componentWillUnmount: statusInterval = " + this.statusInterval);
        clearInterval(this.statusInterval);
    }

    userStatus() {
        if (utils.getSessionItem("user") !== undefined && utils.getSessionItem("user") !== null) {
            var adminClient = new AdminClient();
            var user = utils.getSessionItem("current-user").split(".");
            var promise = adminClient.userStatus(JSON.parse(utils.getSessionItem("user")).session, user[0], user[1]);
            var thisAux = this;
            promise.then(
                function (result) {
                    thisAux.setState({ connected: result.status === "CONNECTED" });
                },
                function (err) {
                    console.error(err);
                }

            );
        } else {
            clearInterval(this.statusInterval);
        }
    }

    async componentDidUpdate(prevProps, prevState) {

        if (prevProps.messageAlert !== this.props.messageAlert)
            this.setState({ messageAlert: this.props.messageAlert })
    }

    async componentDidMount() {
        await searchUserById(this.props.location.pathname)
        var userId = utils.getSessionItem("current-user").split(".")[1];
        if (
            utils.getSessionItem("userDetails") === undefined || utils.getSessionItem("userDetails") === null ||
            "" + JSON.parse(utils.getSessionItem("userDetails")).user.user !== userId
        ) {
            var adminClient = new AdminClient();
            var user = await adminClient.userDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("current-user"));

            utils.setSessionItem("userDetails", JSON.stringify(user));
        }
        await this.recoverUserDetails(userId);
        this.setState({ user: this.user });

        var thisAux = this;


        this.userStatus();
        clearInterval(this.statusInterval);
        this.statusInterval = setInterval(function () { thisAux.userStatus(); }, 10000);
        console.log("UserPersonalDetails: statusInterval = " + this.statusInterval);

    }

    async recoverUserDetails(userId) {
        console.log("ProfileHeader recoverUserDetails " + userId);
        while (utils.getSessionItem("userDetails") === undefined || utils.getSessionItem("userDetails") === null) {
            console.log("ProfileHeader recoverUserDetails " + userId);
            await new Promise(r => setTimeout(r, 100));
        }
        console.log("ProfileHeader recoverUserDetails: recovering ");

        var user = JSON.parse(utils.getSessionItem("userDetails")).user;
        console.log("ProfileHeader recoverUserDetails " + JSON.stringify(user));

        console.log("ProfileHeader recoverUserDetails " + user.user + " " + userId + " " + (Number(user.user) !== Number(userId)));
        while (Number(user.user) !== Number(userId)) {
            await new Promise(r => setTimeout(r, 100));
            user = JSON.parse(utils.getSessionItem("userDetails")).user;
        }
        console.log("ProfileHeader recoverUserDetails obtained " + JSON.stringify(user));
        this.currenciesCompany = utils.getCompany(user.company).currencies
        this.user = user;
        this.companyDetails = utils.getCompany(utils.getSessionItem('Company'))

    }

    goBack(evt) {
        this.props.history.goBack();
    }



    render() {
        const { t } = this.props;
        return (
            <>

                <div className="row profileTitleRow">
                    {this.state.messageAlert.content && <div className={"marquee " + "marquee_" + this.state.messageAlert.type}><p>{this.state.messageAlert.content}</p></div>}
                    {/* <div className="col-2 text-center">
                        <a href className="float-start" title={t("Back")} onClick={this.goBack}><span><FontAwesomeIcon icon="arrow-left" /></span></a>
                    </div> */}
                    <div className="col-12  profileTitle">
                        <FontAwesomeIcon className={"connected_icon_" + this.state.connected} icon="circle" /> {this.state.user.alias}  -  {this.state.user.db}.{this.state.user.user}{utils.getCompany(utils.getSessionItem('Company')).config?.backoffice?.showCompanyPlayerHeader ? '.' + utils.getSessionItem('Company') : ""} - {this.state.user.national_id}{this.currenciesCompany !== undefined && this.currenciesCompany.length > 1 ? '- ' + this.state.user.currency : ''}
                    </div>
                </div>
                <div className="row" >
                    <div className="col-12">
                        <ProfileMenu label={t("Profile Menu")} items={this.state.profileMenu}></ProfileMenu>
                    </div>

                </div>
            </>
        );
    }
}
export default withTranslation()(withRouter(ProfileHeader));
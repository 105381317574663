import { InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import Tab from '../../components/basic/BasicTabs';
import BasicSelect from '../../components/basic/BasicSelect'
import Report from "../../components/basic/Report"
import useFormValidation from '../../hooks/parsleyValidation'


function AffiliateDetails(props) {

  const company = utils.getCurrentCompany().company;
  const [affiliate, setAffiliate] = useState({ affiliate: "", name: "", status: "", company: company, config: { commission: 0 } })
  const [addOrUpdate, setAddOrUpdate] = useState('add');
  const session = JSON.parse(utils.getSessionItem("user")).session;
  let adminClient = new AdminClient();
  const affiliateId = utils.getSessionItem("current-affiliate");
  let filter = [{ "field": "affiliate", "operator": "=", "value": affiliateId }];
  const { t } = props;
  const { formValidation } = useFormValidation('Form_Affiliates')
  utils.setSessionItem("ReportFilter-affiliates_members", JSON.stringify(filter));

  useEffect(function () {

    if (affiliateId !== undefined && affiliateId !== "-1") {
      adminClient.getAffiliateDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), affiliateId).then(
        function (result) {
          var affiliate = result.data;
          if (affiliate.config === null || affiliate.config === "") affiliate.config = { commission: 0 }
          else affiliate.config = JSON.parse(affiliate.config)
          setAffiliate(affiliate);
          setAddOrUpdate('update');
        },
        function (err) {
          console.error(err);
        })
    }
    else {
      setAddOrUpdate('add');
    }

  }, [])

  const onChangeHandler = (e) => {
    const { name, value } = e.currentTarget || e;
    let newAffiliate = { ...affiliate };
    newAffiliate[name] = value;
    setAffiliate(newAffiliate);
  };
  const onChangeCommissionHandler = (e) => {
    const { value } = e.currentTarget || e;
    let newAffiliate = { ...affiliate };
    newAffiliate['config']['commission'] = value;
    setAffiliate(newAffiliate);
  };

  const save = (evt) => {
    if (formValidation.validate()) {
      adminClient[addOrUpdate + "Affiliate"](
        session,
        affiliate.company,
        JSON.stringify(affiliate)
      ).then(
        (msg) => {
          if (msg.result === 'OK') {
            props.showAlert(t('Affiliate details'), t('Saved succesfull'))
            if (addOrUpdate === 'add') {
              setAddOrUpdate('update')
            }
          } else {
            props.showAlert(t("Error"), <p>{t("Error in save affiliate")}</p>)
          }
        },
        (error) => {
          props.showAlert(t("Error"), <p>{t("Error in save affiliate")}</p>)
        }
      )
    }
  }

  const remove = (evt) => {
    props.showAlert(
      t('Affiliate details'),
      <p>{t('Are you sure you want to delete?')}</p>,
      [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
      <button type='button' className='btn btn-primary'
        onClick={(evt) => {
          removeAux(evt)
          props.handleCloseAlert()
        }}>
        {t('Ok')}</button>]
    )
  }

  const removeAux = (evt) => {
    adminClient.deleteAffiliate(
      session,
      affiliate.company,
      JSON.stringify(affiliate)
    ).then(
      (msg) => {
        if (msg.result === 'OK') {
          props.showAlert(t('Affiliate details'), t('Deleted succesfull'))
          goBack();
        } else {
          props.showAlert(t('Affiliate details'), t('Deleting error ') + msg.description)
        }
      },
      (error) => {
        props.showAlert(t('Affiliate details'), t('Deleting error ') + error.description)
      }
    )
  }

  // const sumaryRender = () => {
  //   return <>
  //     Summary
  //   </>
  // }
  const membersRender = () => {
    return <>
      <Report windowRef="windowRefAffiliatesMembers" reportId="affiliates_members" showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert}  ></Report>
    </>
  }
  const [tabsContent, setTabsContent] = useState(
    [
      // { title: t("Summary"), content: sumaryRender },
      { title: t("Members"), content: membersRender }
    ])

  const goBack = () => {
    props.history.push('/reports/report/affiliates/table')
  }
  const dataHeader = {
    backLink: goBack,
    title: t("Affiliate config"),
    urlHelp: "",
    idDoc: props.idDoc
  }

  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <form id="Form_Affiliates">
        <div className="row">

          <div className="col-12 col-md-3  ">
            <TextField label={t("ID")} name="affiliate" required disabled={addOrUpdate === "update"} type="text" value={affiliate.affiliate} onChange={onChangeHandler} />
          </div>

          <div className="col-12 col-md-9 ">
            <TextField label={t("Name")} name="name" required type="text" value={affiliate.name} onChange={onChangeHandler} />
          </div>

          <div className="col-12  col-sm-6 ">
            <BasicSelect
              idProperty="name"
              label={t("Status")}
              name="status"
              required
              basicInfoName="EnabledDisabled"
              onChange={onChangeHandler}
              value={affiliate.status}
              translate={{ property: "name", prefix: "" }}
              disableClearable
            />
          </div>
          <div className="col-12 col-md-6 ">

            <TextField
              label={t("Commission")}
              name="commission"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { "data-parsley-min": 0 }
              }}
              value={affiliate.config.commission || 0}
              onChange={onChangeCommissionHandler}
            />
          </div>

        </div>
      </form>
      {utils.isGranted('SAVE_AFFILIATES') &&
        <div className="row">

          <div className="col-12">

            <button className="btn btn-primary" onClick={save}>{t("Save")}</button>
            <button className="btn btn-secondary" onClick={remove}>{t("Delete")}</button>
          </div>
        </div>
      }

      {addOrUpdate === 'update' &&
        <div className="row">
          <Tab active={0}>
            {
              tabsContent.map((tab, idx) =>
                <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                  {tab.content()}
                </Tab.TabPane>
              )
            }
          </Tab>

        </div>}
    </LayoutSection >
  )
}

export default withTranslation()(AffiliateDetails)

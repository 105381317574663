import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import BasicSelect from "../basic/BasicSelect";
import { Checkbox, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import useFormValidation from "../../hooks/parsleyValidation";
import * as utilsBasicInfo from '../../libs/utilsBasicInfo'
import utils from '../../utils';

const FlowActionDetails = (props) => {
    const { t } = props
    const { formValidation } = useFormValidation('Form_ActionDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [actionDetails, setActionDetails] = useState({ action: '', name: '', type: 'EMAIL' })
    const [configActionTypes, setConfigActionTypes] = useState()
    const [actionsWithOutTransition, setActionsWithOutTransition] = useState([])



    const cleanGenerateFieldsValues = (action) => {
        return {
            action: action.action,
            name: action.name,
            type: action.type
        }

    }

    const handleAction = (evt) => {
        const { name, value } = evt.target || evt;
        const deep = name.split(".");
        let newState = { ...actionDetails };
        let oldType = newState['type']
        let currentObj = JSON.parse(JSON.stringify(newState));

        for (let i = 0; i < deep.length - 1; i++) {
            if (!currentObj.hasOwnProperty(deep[i])) {
                currentObj[deep[i]] = {};
            }
            currentObj = currentObj[deep[i]];
        }
        currentObj[deep[deep.length - 1]] = value;
        if (oldType !== newState['type']) {
            newState = cleanGenerateFieldsValues(newState)
        }
        setActionDetails(newState);
    };

    const handleCheck = (evt) => {
        const { name, checked } = evt.target || evt;
        setActionDetails((prevState) => {
            prevState[name] = (checked) ? "true" : "false";
            return { ...prevState };
        });
    }

    const saveAction = () => {
        if (formValidation.validate()) {
            props.onUpdateActions(actionDetails)
            setAddOrUpdate('update')
            props.handleCloseAlert()
        }
    }

    const getFilters = (filtersData) => {
        return filtersData.map((f) => {
            if (f.props !== undefined) f.value = props[f.props]
            if (f.field !== undefined) f.value = actionDetails[f.field]
            return f
        })
    }

    const generateActionFields = () => {
        let configAction = configActionTypes[actionDetails.type]
        console.log(JSON.stringify(configAction))
        return Object.keys(configAction).map((fieldName) => {
            let field = configAction[fieldName]


            switch (field.type) {
                case "currency":
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>
                                }}
                                name={fieldName}
                                value={actionDetails[fieldName] === undefined ? 0 : actionDetails[fieldName] / 100}
                                onChange={(evt) => { handleAction({ name: fieldName, value: evt.target.value * 100 }) }}
                            />
                        </div>
                    );
                case "integer":
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                type="number"
                                name={fieldName}
                                value={actionDetails[fieldName]}
                                onChange={handleAction}
                            />
                        </div>
                    );
                case "boolean":
                    return (
                        <div className="col-12 col-sm-2">
                            <FormControlLabel control={<Checkbox
                                onChange={handleCheck}
                                name={fieldName}
                                checked={(actionDetails[fieldName] === "false") ? false : true} />}
                                label={t(field.label)} />
                        </div>
                    );
                case "Select": return (<>
                    <div className="col-12 col-sm-6">
                        <BasicSelect key={actionDetails.type + "_" + fieldName} label={t(field.label)}
                            value={actionDetails[fieldName]}
                            name={fieldName}
                            onChange={handleAction}
                            idProperty={field.idProperty}
                            nameProperty={field.nameProperty || undefined}
                            basicInfoName={field.basicInfoName}
                            filters={getFilters(field.filters || [])}
                            params={field.params || []}
                            data={field.data || undefined}
                            translate={field.translate || undefined}
                            disableClearable={field.disableClearable || undefined}
                        />
                    </div>
                </>)
                default:
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                name={fieldName}
                                type="text"
                                value={actionDetails[fieldName]}
                                onChange={handleAction}
                            />
                        </div>
                    );
            }
        })
    }

    useEffect(() => {
        if (props.action !== undefined) {
            setActionDetails(props.action)
            setAddOrUpdate('update')
        }
    }, [props.action])

    useEffect(async () => {
        let configRT = await utilsBasicInfo.getBasicInfo("FlowsActions")
        let configTypes = {}
        let allowedActions = []
        configRT.forEach((element) => {
            if (element.action !== 'TRANSITION') allowedActions.push(element.action)
            configTypes[element.action] = JSON.parse(element.config)
        })
        setConfigActionTypes(configTypes)
        setActionsWithOutTransition(allowedActions)
    }, []);

    return (<>
        <form id="Form_ActionDetails">
            <div className="row">
                <div className="col-12 col-md-2">
                    <TextField
                        label={t('ID')}
                        name="action"
                        required
                        disabled={addOrUpdate === 'update'}
                        InputProps={addOrUpdate === 'add' ? {
                            inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                        } : {}}
                        type="text"
                        value={actionDetails.action}
                        onChange={handleAction}
                    />
                </div>
                <div className="col-12  col-md-8 ">
                    <TextField
                        label={t("Name")}
                        type="text"
                        name="name"
                        required
                        value={actionDetails.name}
                        onChange={handleAction} />
                </div>
                <div className="col-12 col-sm-2">
                    <BasicSelect
                        idProperty="action"
                        name="type"
                        label={t("Type")}
                        basicInfoName="FlowsActions"
                        onChange={handleAction}
                        value={actionDetails.type}
                        disableClearable={true}
                        filters={actionsWithOutTransition.length > 0 ? [{ property: "action", value: actionsWithOutTransition }] : []}
                    />
                </div>
            </div>
        </form>
        <div className="row">
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={saveAction}>{t("Save")}</button>
                <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
            </div>
        </div>
        <div className="row">
            {configActionTypes && generateActionFields()}
        </div>
        <div className="row">
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={saveAction}>{t("Save")}</button>
                <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
            </div>
        </div>


    </>)

}
export default withTranslation()(FlowActionDetails)
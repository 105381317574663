import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BasicSelect from '../../components/basic/BasicSelect';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { TextField } from '@mui/material';
import ProgressBar from '../../components/basic/ProgressBar';
import useFormValidation from '../../hooks/parsleyValidation';
import AdminClient from '../../AdminClient';
import utils from "../../utils";

const PromotionsBatch = (props) => {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;

    const { formValidation } = useFormValidation('Form_batch_promotion')

    const [batchDetails, setBatchDetails] = useState({
        promotion: "",
        base_amount: "",
        comments: "",
        player_list: "",
        group: ""
    })
    const [progressBar, setProgressBar] = useState({
        show: false,
        percentage: '0%',
        textProcess: t('Processing')
    })

    const goBack = () => {
        props.history.goBack();
    }
    const dataHeader = {
        backLink: goBack,
        title: t('Batch promotions'),
        urlHelp: '',
        idDoc: props.idDoc
    }
    const onChangeHandler = (evt) => {
        const { name, value } = evt.target || evt;
        setBatchDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        setProgressBar({
            show: true,
            percentage: percentage + '%',
            textProcess: t('Processing') + " " + data.partial + "/" + data.total
        })
    }
    const send = async () => {
        if (formValidation.validate()) {
            let response = await adminClient.getPromotionDetails(session, company, batchDetails.promotion);
            let promotion = response.data.promotion;

            let type = "fs";
            let amount = batchDetails.base_amount;
            if (promotion.type === 'INTERNAL' || promotion.config.externalConfig.provider === 'altenar' || promotion.config.externalConfig.provider === 'kambi') {
                amount = amount * 100;
                type = "currency";
            }
            setProgressBar({
                show: true,
                percentage: '0%',
                textProcess: t('Processing Promotions')
            })

            adminClient.batchPromotion(
                JSON.parse(utils.getSessionItem("user")).session,
                utils.getSessionItem("Company"),
                batchDetails.promotion,
                amount,
                batchDetails.player_list,
                batchDetails.comments,
                type,
                "" + new Date().getTime(),
                batchDetails.group || '',
                handleResponse
            ).then(
                function (msg) {
                    setProgressBar((prevState) => ({ ...prevState, showProgress: false }));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Batch promotion"), t("Process Executed"))
                    } else {
                        props.showAlert(t("Batch promotion"), t("Processing Error") + msg.description)
                    }
                },
                function (err) {
                    setProgressBar((prevState) => ({ ...prevState, showProgress: false }));
                    props.showAlert(t("Batch promotion"), t("Processing Error") + err.description)
                });
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_batch_promotion">

                <div className="row">
                    <div className="col-12">
                        <BasicSelect
                            label={t("Promotion")}
                            idProperty="promotion"
                            name="promotion"
                            nameProperty="promotion"
                            data="promotions"
                            required
                            basicInfoName="ManualPromotions"
                            onChange={onChangeHandler}
                            value={batchDetails.promotion}
                        />
                    </div>

                    <div className="col-12">
                        <TextField label={t("Base amount")} name="base_amount" onChange={onChangeHandler} value={batchDetails.base_amount} />

                    </div>
                    <div className="col-12">
                        <TextField multiline placeholder={t("Comments")} name="comments" label={t("Comments")} type="text" onChange={onChangeHandler} value={batchDetails.comments} />
                    </div>
                    <div className="col-12">
                        <BasicSelect
                            idProperty='group'
                            basicInfoName='Groups'
                            value={batchDetails.group}
                            name='group'
                            label={t('Group') + " " + t("Players list")}
                            onChange={onChangeHandler}
                            multiple={false}
                            disabled={batchDetails.player_list !== ""}
                            required={batchDetails.player_list === ""}
                            formValidation={formValidation}
                            validations={{ "data-parsley-required-message": t("Requerido seleccionar un grupo o un listado de players") }}
                        />
                    </div>
                    <div className="col-12">
                        <TextField multiline placeholder="db.player1 , amount&#10;db.player2 , amount&#10;db.player3 , amount"
                            disabled={batchDetails.group !== ""} label={t("Players list")} name="player_list" type="text"
                            onChange={onChangeHandler} value={batchDetails.player_list} required={batchDetails.group === ""}
                            inputProps={{ "data-parsley-required-message": t("Requerido seleccionar un grupo o un listado de players") }}
                        />
                    </div>
                </div>
            </form>

            <div className="row">
                {utils.isGranted('GIVE_MANUAL_PROMOTION') &&

                    <div className="col-12">
                        <button className="btn btn-primary" disabled={progressBar.show} onClick={send}>{t("Execute")}</button>
                        {progressBar.show &&
                            <ProgressBar percentage={progressBar.percentage} text={progressBar.textProcess} />
                        }

                    </div>
                }
            </div>
        </LayoutSection>
    );
};
export default withTranslation()(PromotionsBatch)

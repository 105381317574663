import React, { useEffect, useState } from "react";
import useFormValidation from "../../hooks/parsleyValidation";
import AdminClient from "../../AdminClient";
import utils, { setSessionItem } from "../../utils";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom'
import i18next from "i18next";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import BasicSelect from "../../components/basic/BasicSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';


function AdminProfile(props) {
  const { t } = props;
  const adminClient = new AdminClient();
  const userConfig =
    utils.getSessionItem("user") === undefined
      ? {}
      : JSON.parse(utils.getSessionItem("user"));
  const [config, setConfig] = useState({
    current_password: "",
    new_password: "",
    re_password: "",
    qr: "",
    otpEnabled: userConfig.otpEnabled || false,
    lang: userConfig.lang || '',
    numberFormat: userConfig.number_format || '',
  });

  const [showPassword, setShowPassword] = useState({ current_password: false, new_password: false, re_password: false })

  const { formValidation: formPassValidation } = useFormValidation("FormPassword");
  const { formValidation: formSettingsValidation } = useFormValidation("FormSettings")

  const onhandleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setConfig((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleClickShowPassword = (evt) => {
    const { name } = evt.currentTarget
    setShowPassword((prevState) => ({ ...prevState, [name]: !prevState[name] }))
  }
  const savePassword = () => {
    if (formPassValidation.validate()) {
      adminClient
        .updatePassword(
          JSON.parse(utils.getSessionItem("user")).session,
          config.current_password,
          config.new_password
        )
        .then(
          function (data) {
            if (data.result === "OK") {
              let user = JSON.parse(utils.getSessionItem("user"))
              user.last_password_update = utils.formatDate(new Date())
              utils.setSessionItem("user", JSON.stringify(user))
              props.showAlert(
                t("Update password"),
                t("Password updated succesfully")
              );
              if (props.changePass) {
                let sectionsAux = window.sections;
                let objetoEncontrado = null;
                let menusAux = window.menus;
                for (let i = 0; i < menusAux.length; i++) {
                  if (menusAux[i].url === user.init_page) {
                    objetoEncontrado = menusAux[i];
                    break; // Terminamos el bucle una vez que encontramos el objeto
                  }
                }
                let init_user_page = undefined;
                if (objetoEncontrado) {
                  init_user_page = objetoEncontrado.url;
                }
                const exits = "DASHBOARD" in sectionsAux;


                if (exits) {
                  props.history.push(init_user_page || '/home');
                }
                else {
                  props.history.push(init_user_page || '/defaultHome');
                }
              }
            }
            else props.showAlert(t("Error"), data.description);
          },
          function (err) {
            props.showAlert(t("Error"), err.description);
          }
        );
    }
  };
  const saveLang = () => {
    if (formSettingsValidation.validate()) {
      adminClient
        .updateLang(
          JSON.parse(utils.getSessionItem("user")).session,
          config.lang,
          config.numberFormat
        )
        .then(
          function (data) {
            if (data.result === "OK") {
              i18next.changeLanguage(config.lang.substring(0, 2));
              utils.setSessionItem("calimaco-lang", config.lang);
              let user = JSON.parse(utils.getSessionItem("user"))
              user.lang = config.lang
              user.number_format = config.numberFormat
              utils.setSessionItem("user", JSON.stringify(user))
              var lang = 'es';
              if (config.lang !== undefined && config.lang !== null) {
                lang = config.lang.substring(0, 2);
                moment.globalLocale = lang
                moment.locale(lang)
              }
              utils.setNumberFormat(config.numberFormat);
              props.showAlert(t("Update lang"), t("Lang updated succesfully"));
            } else {
              props.showAlert(t("Error"), data.description);
            }
          },
          function (err) {
            props.showAlert(t("Error"), err.description);
          }
        );
    }
  };
  const saveEnableOTP = () => {
    adminClient
      .enableOTP(JSON.parse(utils.getSessionItem("user")).session)
      .then(
        function (data) {
          if (data.result === "OK") {
            setConfig((prevState) => ({
              ...prevState,
              otpEnabled: true,
              qr: data.img,
            }));
            let user = JSON.parse(utils.getSessionItem("user"))
            user['otpEnabled'] = true
            utils.setSessionItem("user", JSON.stringify(user))
            props.showAlert(t("Enable OTP"), t("OTP activated"));
          } else {
            props.showAlert(t("Error"), data.description);
          }
        },
        function (err) {
          props.showAlert(t("Error"), err.description);
        }
      );
  };

  useEffect(() => {
    var adminClient = new AdminClient();
    if (JSON.parse(utils.getSessionItem("user")).otpEnabled) {
      var promise = adminClient.getQR(JSON.parse(utils.getSessionItem("user")).session);
      promise.then(
        function (response) {
          console.log("qr image  " + response.img);
          setConfig((prevConfig) => ({ ...prevConfig, ['qr']: response.img }))
        }.bind(this),
        function (err) {
          console.error("qr error: " + JSON.stringify(err));
        }.bind(this));
    }
  }, [])


  const renderFormPassword = () => {
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassAdmin || 11
    return <form id="FormPassword">
      <div className="row">
        <div className="col-12  ">
          <TextField
            label={t("Current password")}
            type={showPassword.current_password ? 'text' : 'password'}
            name="current_password"
            inputProps={{ id: "currentPassword" }}
            InputProps={{
              id: "currentPassword",
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleClickShowPassword}
                    onMouseUp={handleClickShowPassword}
                    edge="end"
                    name="current_password"
                  >
                    {showPassword.current_password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                  </IconButton>
                </InputAdornment>
            }}
            onChange={onhandleChange}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12  ">
          <TextField
            label={t("New password")}
            type={showPassword.new_password ? 'text' : 'password'}
            name="new_password"
            onChange={onhandleChange}
            required
            inputProps={{
              "data-parsley-check-text-fields-equal":
                "[notEqual,currentPassword]",
              "data-parsley-check-text-fields-equal-message":
                t("New password different than re password"),
              "data-parsley-validate-password": JSON.stringify([minLength, ""]),
              id: "newPassword"
            }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleClickShowPassword}
                    onMouseUp={handleClickShowPassword}
                    edge="end"
                    name="new_password"
                  >
                    {showPassword.new_password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                  </IconButton>
                </InputAdornment>

            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12  ">
          <TextField
            label={t("Re password")}
            required
            type={showPassword.re_password ? 'text' : 'password'}
            inputProps={{
              "data-parsley-check-text-fields-equal":
                "[isEqual,newPassword]",
              "data-parsley-check-text-fields-equal-message":
                t("New password different than re password")
            }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleClickShowPassword}
                    onMouseUp={handleClickShowPassword}
                    edge="end"
                    name="re_password"
                  >
                    {showPassword.re_password ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                  </IconButton>
                </InputAdornment>

            }}
            name="re_password"
            onChange={onhandleChange}

          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-primary"
            onClick={savePassword}
          >
            {t("Update password")}
          </button>
        </div>
      </div>
    </form>
  }

  return (<>
    {(props.changePass) && <>
      {renderFormPassword()}
    </>}
    {!(props.changePass) && <>
      <div className="col-12 justify-content-center  pt-3">
        <div className="card col-12 col-md-6">
          <div className="card-header">{t("AdminProfile")}</div>
          <div className="card-body m-0">
            {config.otpEnabled && (
              <div className="row mb-4 clmc-otp-element">
                <div className="col-12  ">
                  <label htmlFor="banner">
                    <b>{t("One Time Password")}</b>
                  </label>
                  <img className="qr-image" src={config.qr}></img>
                </div>
              </div>
            )}

            {!config.otpEnabled && (
              <div className="row mb-4  clmc-otp-element ">
                <div className="col-12  ">
                  <label htmlFor="banner">
                    <b>{t("One Time Password")}</b>
                  </label>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveEnableOTP}
                  >
                    {t("Enable OTP")}
                  </button>
                </div>
              </div>
            )}

            {renderFormPassword()}

            <form id="FormSettings">
              <div className="row">
                <div className="col-12 mt-2 ">
                  <BasicSelect
                    idProperty="lang"
                    label={t("Lang")}
                    basicInfoName="Languages"
                    name="lang"
                    onChange={onhandleChange}
                    value={config.lang}
                    disableClearable
                  />
                </div>
                <div className="col-12  mt-2">
                  <BasicSelect
                    label={t("Number format")}
                    idProperty="numberFormat"
                    nameProperty="label"
                    basicInfoName="NumberFormats"
                    name="numberFormat"
                    onChange={onhandleChange
                    }
                    value={config.numberFormat}
                    translate={{ property: "label", prefix: "" }}
                    required
                    disableClearable
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveLang}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>}
  </>
  );
}

export default withTranslation()(withRouter(AdminProfile))

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { withTranslation } from "react-i18next";

const Collapse = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;
    const errorDetail = props.errorDetail;

    const toggleDetails = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className="col-12" style={{ marginBottom: '10px' }}>
                <IconButton color="default" className="float-left" aria-label={props.tittle} onClick={toggleDetails}>
                    {isOpen ? <FontAwesomeIcon icon="minus-square" /> : <FontAwesomeIcon icon="plus-square" />} <span className="headerButton">{props.tittle}</span>
                </IconButton>
            </div>
            {
                isOpen && (
                    <div className="col-12">
                        <p>{errorDetail}</p>
                    </div>
                )
            }
        </div >
    );
}

export default withTranslation()(Collapse);
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
function AllowedPlayersDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_AllowedPlayersDetails')
    const [player, setPlayer] = useState();
    const [addOrUpdate, setAddOrUpdate] = useState("add");
    const userId = utils.getSessionItem('current-user')


    useEffect(function () {
        if (userId !== undefined && userId !== '-1') {
            setPlayer(userId);
            setAddOrUpdate('update');
        }
    }, []);
    const goBack = () => {
        props.history.push('/reports/report/allowed_players/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Allowed players'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setPlayer(value);
    };
    //Manejadores modificacion
    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveAllowedPlayer(session, company, player).then(
                (msg) => {
                    props.showAlert(t('Allowed players'), t('Added succesfull'))
                    goBack();

                },
                (error) => {
                    props.showAlert(t('Allowed players'), t('Adding error ') + error.result)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_AllowedPlayersDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="id"
                            required
                            disabled={addOrUpdate === "update"}
                            inputProps={{ "data-parsley-pattern": "^[0-9]+$", "data-parsley-pattern-message": t('validationIDNumbers') }}
                            type="text"
                            value={player}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={addOrUpdate === "update"}
                        onClick={save}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(AllowedPlayersDetails);

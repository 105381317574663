import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
function AccountsSetDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_AccountsSetDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const set = utils.getSessionItem('current-set')

    const [accountSet, setAcountSet] = useState({
        company: company,
        set: set,
        in_accounts: ""
    });
    const goBack = () => {
        props.history.push('/reports/report/accounts_sets/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Accounts set details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (set !== undefined && set !== '-1') {
            adminClient.getAccountsSetDetails(
                session,
                company,
                set
            ).then(
                async function (result) {
                    setAcountSet(result.data);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setAcountSet((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveAccountsSet(
                session,
                company,
                JSON.stringify(accountSet)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Accounts set details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Accounts set details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Accounts set details'), t('Saving error ') + t(error.description))
                }
            )
        }
    }
    const handleRemove = (evt) => {
        props.showAlert(
            t('Role details'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

    }
    const remove = (evt) => {
        adminClient.deleteAccountsSet(
            session,
            company,
            JSON.stringify(accountSet)
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Accounts set details'), t('Deleted succesfull'))
                    goBack();
                } else {
                    props.showAlert(t('Accounts set details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('Accounts set details'), t('Deleting error ') + t(error.description))
            }
        )
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_AccountsSetDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('Accounts Set')}
                            name="set"
                            required
                            disabled={addOrUpdate === 'update'}
                            inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            type="text"
                            value={accountSet.set}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12   ">
                        <TextField multiline label={t("In Accounts")} type="text" name="in_accounts" value={accountSet.in_accounts} onChange={onChangeHandler} />
                    </div>
                </div>

            </form>
            {utils.isGranted('SAVE_CONFIGURATION_ACCOUNTS_SETS') &&
                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleRemove}
                        >
                            {t('Delete')}
                        </button>
                    </div>
                </div>
            }
        </LayoutSection>
    )
}
export default withTranslation()(AccountsSetDetails);
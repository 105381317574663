import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import utils from '../../utils';



function DepartmentsDetails(props) {

  const [departmentInfo, setDepartmentInfo] = useState({ "id": "", "name": "" });

  const [newOrUpdate, setNewOrUpdate] = useState("");

  const session = JSON.parse(utils.getSessionItem("user")).session;

  const company = utils.getCurrentCompany().company;

  let adminClient = new AdminClient();

  const { t } = props;

  useEffect(function () {

    let departmentId = utils.getSessionItem("current-department");
    if (departmentId !== undefined && departmentId !== "-1") {
      adminClient.getDepartmentDetails(session, company, departmentId).then((res) => {
        let department = res;
        let info = {
          "id": departmentId,
          "name": department.departments.name
        }
        setNewOrUpdate("update");
        setDepartmentInfo(info);
      })

    }
    else {
      setNewOrUpdate("new");
    }

  }, [])

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newDepartmentInfo = { ...departmentInfo };
    newDepartmentInfo[name] = value;
    setDepartmentInfo(newDepartmentInfo);
  };


  const handleDelete = () => {
    let promise = adminClient.deleteDepartment(
      session,
      company,
      departmentInfo.id
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          props.showAlert(t("Ok"), t("Deleted succesfull"))
          goBack();
        } else {
          props.showAlert(t("Error"), t("Deleting error"))
        }
      }, function (err) {
        console.log("Save operation error " + err.code);
        props.showAlert(t("Error"), t("Deleting error"))
      });
  };

  const handleSubmit = () => {

    let promise = adminClient.saveDepartmentsDetails(
      session,
      company,
      JSON.stringify({ department: departmentInfo.id, name: departmentInfo.name })
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          props.showAlert(t("Ok"), t("Saved succesfull"))
        } else {
          props.showAlert(t("Error"), t("Saving error"))
        }
      }, function (err) {
        console.log("Save operation error " + err.code);
        props.showAlert(t("Error"), t("Saving error"))
      });

  };

  const goBack = () => {
    props.history.push('/reports/report/departments/table')
  }
  const dataHeader = {
    backLink: goBack,
    title: t("Departments config"),
    urlHelp: "",
    idDoc: props.idDoc
  }

  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <div className="row">
        <div className="card-body m-0">
          <div className="row">

            <div className="col-12 ">
              <TextField disabled={(newOrUpdate === "new") ? false : true} name="id" id="id" label={t("Identifier")} type="text" value={departmentInfo.id} onChange={onChangeHandler} />

            </div>

            <div className="col-12   ">
              <TextField name="name" id="name" label={t("Name")} type="text" value={departmentInfo.name} onChange={onChangeHandler} />
            </div>
          </div>

          <div className="row">

            <div className="col-12">

              <button className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
              <button hidden={(newOrUpdate === "new") ? true : false} className="btn btn-secondary" onClick={handleDelete}>{t("Delete")}</button>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  )
}

export default withTranslation()(DepartmentsDetails)

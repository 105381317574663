import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import { Box, TextField } from '@mui/material'
import BasicSelect from './BasicSelect'
import utils from '../../utils';

const MessagesList = (props) => {
    const t = props.t
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const [messageDetails, setMessageDetails] = useState({
        "internalCode": t('Other'),
        "code": '',
        "description": ''
    })
    const [messagesList, setMessagesList] = useState([])
    const onChangeHandler = (event) => {
        const { objSelection } = event
        setMessageDetails({ code: objSelection.code, internalCode: objSelection.code, description: objSelection.description })
        props.onChangeHandler({ code: objSelection.code, description: objSelection.description })
    }
    useEffect(() => {

        const adminClient = new AdminClient()
        adminClient.getMessagesList(session, company, props.type).then((data) => {
            if (data.data.length > 0) {
                data.data.push({ code: t('Other'), description: '', type: 'DOCUMENTATION_USER' })
            }
            setMessagesList(data.data)
        }, (err) => {
            console.log("Error obteniendo los mensajes predefinidos" + err.description)
        })

    }, [props.type])


    return (

        <div className='row'>
            {messagesList.length > 0 &&
                <div className="col-12 col-md-6">
                    <BasicSelect
                        value={messageDetails.code}
                        name='code'
                        idProperty="code"
                        nameProperty="code"
                        label={t(props.label['code'])}
                        addOpts={messagesList}
                        onChange={onChangeHandler}
                    />
                </div>}
            {messagesList.length === 0 &&
                <div className="col-12 col-md-6">
                    <TextField multiline name="rejectReason" label={t(props.label['code'])} value={messageDetails.code} onChange={(evt) => {
                        setMessageDetails({ internalCode: t('Other'), code: evt.target.value, description: messageDetails.description })
                        props.onChangeHandler({ code: evt.target.value, description: messageDetails.description })
                    }}></TextField>
                </div>
            }
            <div className="col-12">
                <TextField disabled={messageDetails.internalCode !== t('Other') ? true : false} label={t(props.label['description'])} name="description" multiline type="text" value={messageDetails.description} onChange={messageDetails.internalCode !== t('Other') ? '' : (evt) => {
                    setMessageDetails({ internalCode: t('Other'), code: messageDetails.code, description: evt.target.value })
                    props.onChangeHandler({ code: messageDetails.code, description: evt.target.value })
                }} />
            </div>
        </div>
    )
}

export default withTranslation()(MessagesList)
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import useFormValidation from '../../hooks/parsleyValidation'
import utils from "../../utils";
import { TextField } from '@mui/material'

const UserLimits = (props) => {

    const company = utils.getCurrentCompany().company;
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UserLimits')
    const [limits, setLimits] = useState({
        'WEEKLY_DEPOSIT': { 'value': 0 },
        'MONTHLY_DEPOSIT': { 'value': 0 },
        'DAILY_DEPOSIT': { 'value': 0 }
    })
    const user = utils.getSessionItem('current-user').split(".")
    const adminClient = new AdminClient()
    const dataHeader = {

        title: t('Deposit Limits'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(() => {
        adminClient.getLimitDetails(session, user[0], user[1], company).then((result) => {

            let limitsData = { ...limits }
            if (result.data.length > 0) {
                result.data.forEach((element) => {
                    limitsData[element.limit]['value'] = element.value
                    limitsData[element.limit]['current'] = element.current
                })
            }
            setLimits(limitsData)
        }, (err) => {
            console.log("Error obteniendoo limites")
        })

    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setLimits((prevLimits) => {
            prevLimits[name]['value'] = value * 100
            return { ...prevLimits }
        });
    };

    const saveLimits = () => {

        if (formValidation.validate()) {
            let limitData = Object.entries(limits).map(([limit, { value }]) => ({
                limit,
                value
            }));

            adminClient.saveLimitsDetails(session, JSON.stringify(limitData), user[0], user[1]).then((msg) => {

                if (msg.result === 'OK') {
                    props.showAlert(t("Deposit Limits"), <p>{t("Saved succesfull")}</p>);
                } else {
                    props.showAlert(t("Deposit Limits"), <p>{t("Saving error ") + " " + msg.error?.description}</p>)
                }
            }, (err) => {

                props.showAlert(t("Deposit Limits"), <p>{t("Saving error ") + " " + err.description}</p>)
            })
        }
    }

    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserLimits">
                <div className="row" >
                    <div className="col-12 col-md-4">
                        <TextField label={t("DAILY_DEPOSIT")} required helperText={t("Of your established limit you have paid in the amount of") + " " + limits.DAILY_DEPOSIT.current / 100} name="DAILY_DEPOSIT" type="number" value={limits.DAILY_DEPOSIT.value / 100} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField label={t("WEEKLY_DEPOSIT")} required helperText={t("Of your established limit you have paid in the amount of") + " " + limits.WEEKLY_DEPOSIT.current / 100} name="WEEKLY_DEPOSIT" type="number" InputProps={{ inputProps: { "data-parsley-min": limits.DAILY_DEPOSIT.value / 100, "data-parsley-min-message": t("No puede ser menor que ") + " " + t("DAILY_DEPOSIT") } }} value={limits.WEEKLY_DEPOSIT.value / 100} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField label={t("MONTHLY_DEPOSIT")} required helperText={t("Of your established limit you have paid in the amount of") + " " + limits.MONTHLY_DEPOSIT.current / 100} name="MONTHLY_DEPOSIT" type="number" InputProps={{ inputProps: { "data-parsley-min": limits.WEEKLY_DEPOSIT.value / 100, "data-parsley-min-message": t("No puede ser menor que ") + " " + t("WEEKLY_DEPOSIT") } }} value={limits.MONTHLY_DEPOSIT.value / 100} onChange={onChangeHandler} />
                    </div>
                </div>
            </form>
            {utils.isGranted('SAVE_USER_LIMITS') && <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={saveLimits}>
                        {t('Save')}
                    </button>

                </div>
            </div>}

        </LayoutSection>
    </>
    )
}

export default withTranslation()(UserLimits)
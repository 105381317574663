import React from 'react'

const PublicLayout = ({children}) => {
  return (

      <main>{children}</main>
 
  )
}

export default PublicLayout
import React from 'react';
import momentTimeZone from 'moment-timezone';
const utils = require('../utils')
const { getBasicInfo } = require('../libs/utilsBasicInfo')



class RenderReports {

    constructor() {
        this.BasicInfoRenders = {}
        // this.customRenders = new CustomRenders()
    }

    getBasicInfoData(basicInfoName) {
        return this.BasicInfoRenders[basicInfoName]
    }

    //Cargamos info previa necesaria para un tipo de render
    async loadPreviousInfoRender(renderInfoList) {
        if (renderInfoList !== undefined) {

            let infoList = Object.keys(renderInfoList)
            for (let i = 0; i < infoList.length; i++) {
                const render = renderInfoList[infoList[i]]
                await this.loadInfoRender(render)
            }

        }
        return true;
    }

    async loadInfoRender(renderInfo) {
        const renderData = renderInfo.renderData
        switch (renderInfo.typeRender) {
            case 'basicInfo':
                this.BasicInfoRenders[renderData.basicInfoName] = []
                try {
                    let result = await getBasicInfo(renderData.basicInfoName, renderData.data, renderData.params)
                    this.BasicInfoRenders[renderData.basicInfoName] = result
                }
                catch (err) {
                    console.log("Error cargando datos para RenderBasicInfo" + renderData.basicInfoName)
                }
                break
            case 'custom':
                //Si tiene Basic info dependientes los cargamos
                if (renderData !== undefined) {
                    renderData.forEach(async binfo => {
                        try {
                            let result = await getBasicInfo(binfo.basicInfoName, binfo.data, binfo.params)
                            this.BasicInfoRenders[binfo.basicInfoName] = result
                        }
                        catch (err) {
                            console.log("Error cargando datos para RenderBasicInfo" + binfo.basicInfoName)
                        }
                    });
                }
                break;
        }

    }

    getTimeRender(cellValue, rowOriginal, t, cellProps) {
        let hourFormat = utils.getSessionItem("calimaco-lang") === "en-US" ? 'L hh:mm:ss A' : 'L HH:mm:ss';
        return <div className={cellProps.className}>{utils.formatDate(cellValue, hourFormat)}</div>;
    }
    getDateRender(cellValue, rowOriginal, t, cellProps) {
        return momentTimeZone(new Date(cellValue).getTime()).tz("UTC").format("L")
    }

    getBasicInfoRender(cellValue, rowOriginal, t, cellProps) {
        let { basicInfoName, idProperty, nameProperty, translate } = cellProps.renderData.renderData
        let renderValue = '';
        if (cellValue === '0') return ''

        if (this.BasicInfoRenders[basicInfoName] === undefined || this.BasicInfoRenders[basicInfoName].length === 0) return cellValue

        for (let i = 0; i < this.BasicInfoRenders[basicInfoName].length; i++) {
            const element = this.BasicInfoRenders[basicInfoName][i];
            if (element[idProperty] === cellValue) {
                if (translate != undefined) {
                    return <div className={cellProps.className}>{t(translate.prefix + element[translate.property])}</div>
                }
                else {
                    return <div className={cellProps.className}>{element[nameProperty]}</div>
                }
            }
        }
        return renderValue
    }
    getCurrencyRender(cellValue, rowOriginal, t, cellProps, currency2 = false) {
        let classNameCell = cellProps.className
        let currencyField = "currency"
        if (currency2) currencyField = "currencySecondary"
        let currencyRow = rowOriginal[currencyField] || 'USD';
        let className = " positive";
        if (cellValue < 0) {
            className = " negative";
        }
        classNameCell = classNameCell + " " + className
        return <span className={classNameCell}>{utils.formatCurrency(cellValue / 100, currencyRow)}</span>
    }
    getNumberRender(cellValue, rowOriginal, t, cellProps, symbol) {
        return <div className={cellProps.className}>{utils.formatNumber(cellValue) + (symbol === undefined ? '' : symbol)}</div>
    }
    getIntegerRender(cellValue, rowOriginal, t, cellProps) {
        return <div className={cellProps.className}>{cellValue}</div>
    }

    getPercentageRender(cellValue, rowOriginal, t, cellProps) {
        return this.getNumberRender(cellValue, rowOriginal, t, cellProps, "%")
    }
    getPerMilRender(cellValue, rowOriginal, t, cellProps) {
        return <div className={cellProps.className}>{utils.formatNumber(cellValue)} &permil;</div>
    }
    getPerTenMilRender(cellValue, rowOriginal, t, cellProps) {
        return <div className={cellProps.className}>{utils.formatNumber(cellValue)} ‱</div>
    }
    getTranslateRender(cellValue, rowOriginal, t, cellProps) {
        let prefixTranslate = ""
        if (cellProps.prefixTranslate !== undefined) prefixTranslate = cellProps.prefixTranslate
        return t(prefixTranslate + cellValue)
    }
    getTextTranslateRender(cellValue, rowOriginal, t, cellProps) {
        let prefixTranslate = ""
        if (cellProps.prefixTranslate !== undefined) prefixTranslate = cellProps.prefixTranslate

        return <span dangerouslySetInnerHTML={{ __html: t(prefixTranslate + cellValue) }}></span>
    }
    getImageCMSRender(cellValue, rowOriginal, t, cellProps) {
        let valueShow = cellValue.replace("<cms>", utils.getCurrentCompany().config.cdn_url + "../")
        return <img className="calimaco_logo" src={valueShow} />
    }
    getCustomRender(cellValue, rowOriginal, t, cellProps) {
        return this[cellProps.renderData.nameFunction](cellValue, rowOriginal, t, cellProps)
    }
    getTextRender(cellValue, rowOriginal, t, cellProps) {
        return <span className={cellProps.className}>{cellValue}</span>
    }
}

export default RenderReports;
import React, { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import BasicSelect from "../../components/basic/BasicSelect";
import { TextField } from "@mui/material";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import useFormValidation from "../../hooks/parsleyValidation";

const UserManualPayout = (props) => {

    const { t } = props
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_UserPayouts')

    const user = JSON.parse(utils.getSessionItem("userDetails")).user;
    const company = utils.getCurrentCompany().company;
    const companyDetails = utils.getCurrentCompany(company);
    const [payout, setPayout] = useState({
        amount: utils.formatNumber(10, 2),
        method: "",
        comments: "",
        bankInfo: false

    });
    const [blockPayout, setBlockPayout] = useState(false)

    const [methodsList, setMethodsList] = useState([])

    const adminClient = new AdminClient()

    useEffect(() => {
        adminClient.payoutMethods(session, company, user.db, user.user).then(
            function (msg) {
                let methodsListAux = [];
                msg.data.forEach(element => {
                    methodsListAux.push({ label: element.name, value: element.method, bankInfo: element.bankInfo });
                });
                setMethodsList(methodsListAux)
            }, function (err) {
                console.log("Payout payout error " + err.code);
            }
        );

    }, [])

    const onChangeHandler = (e) => {
        const { name, value, objSelection } = e.target || e;
        let bankInfo = payout.bankInfo;
        if (name === 'method') {
            if (objSelection.bankInfo !== undefined && objSelection.bankInfo) {
                bankInfo = true
            } else bankInfo = false
        }
        setPayout((prevState) => ({ ...prevState, [name]: value, ['bankInfo']: bankInfo }));
    };

    const save = () => {
        if (formValidation.validate()) {
            setBlockPayout(true)
            adminClient.manualPayout(
                session, company, user.db, user.user,
                payout.method, payout.amount * 100, 'NEW',
                payout.comments, payout.bank).then(
                    function (msg) {
                        props.showAlert(t("Manual payout title"), t("Manual payout ok explanation"));
                        goBack();

                    }, function (err) {
                        setBlockPayout(false)
                        props.showAlert(
                            t("Manual payout title"),
                            t("Saving error") + " " + t("ERROR_" + err.code)
                        );
                    }.bind(this)
                );
        }
    }
    const goBack = () => {
        props.history.push("/support/user_accounts");
    };
    const dataHeader = {
        backLink: goBack,
        title: t("Manual payout title"),
        urlHelp: "",
        idDoc: props.idDoc
    };
    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserPayouts">

                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField id="amount" label={t("Amount")} type="number" name="amount" required
                            value={payout.amount} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            idProperty='value'
                            nameProperty='label'
                            name="method"
                            value={payout.method}
                            label={t('Method')}
                            onChange={onChangeHandler}
                            addOpts={methodsList}
                            translate={{ property: 'label', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                            required
                        />
                    </div>
                    {payout.bankInfo && <div className="col-12 col-md-4 col-xl-4">
                        <BasicSelect
                            idProperty='bank'
                            nameProperty='popular_name'
                            value={payout.bank}
                            name="bank"
                            label={t('Bank')}
                            onChange={onChangeHandler}
                            basicInfoName='Banks'
                            filters={[{ property: "payout", value: 1 }]}
                        />

                    </div>}

                    <div className="col-12 col-md-12">
                        <TextField multiline label={t("Comment")} type="text" name="comments" value={payout.comments} onChange={onChangeHandler}

                        />
                    </div>

                </div >
            </form>
            <div className="row">
                <button className="btn btn-primary float-end" disabled={blockPayout} onClick={save}>{t("Do payout")}</button>

            </div>
        </LayoutSection>
    </>
    )
}

export default withTranslation()(withRouter(UserManualPayout))
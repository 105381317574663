import React from 'react';
import moment from 'moment';


var parsleyValidationGral = {
  amountGreater: {
    requirementType: "number",
    validateString: function (value, param) {
      return parseFloat(value) > param;
    },
  },
  validatePassword: {
    requirementType: ["number", "string"],
    validateString: function (new_pass, minLength, notWordAllowed) {
      var validacion = false;
      var notWordAllowedSet = notWordAllowed.split(/\s+/);
      var containsNotWordAllowed = false;
      var normalizedPassword = new_pass.toLowerCase();

      notWordAllowedSet.forEach(function (notWordAllowedWord) {
        var start;
        var mincs = 3;
        if (
          notWordAllowedWord.length >= mincs &&
          normalizedPassword.length >= mincs
        ) {
          for (start = 0; start <= notWordAllowedWord.length - mincs; start++) {
            if (
              normalizedPassword.indexOf(
                notWordAllowedWord.substring(start, start + mincs).toLowerCase()
              ) != -1
            ) {
              containsNotWordAllowed = true;
            }
          }
        }
      });

      if (containsNotWordAllowed) {
        validacion = false
      } else if (new_pass.length > minLength && new_pass.match(/[a-z]/) && new_pass.match(/[A-Z]/) && new_pass.match(/\d/) && new_pass.match(/[^a-zA-Z\d]/)) {
        validacion = true
      } else {
        validacion = false
      }

      return validacion;
    }
  },
  validatePasswordLight: {
    requirementType: "string",
    validateString: function (new_pass, notWordAllowed) {
      var validacion = false;


      var notWordAllowedSet = notWordAllowed.split(/\s+/);
      var containsNotWordAllowed = false;
      var normalizedPassword = new_pass.toLowerCase();

      notWordAllowedSet.forEach(function (notWordAllowedWord) {
        var start;
        var mincs = 3;
        if (
          notWordAllowedWord.length >= mincs &&
          normalizedPassword.length >= mincs
        ) {
          for (start = 0; start <= notWordAllowedWord.length - mincs; start++) {
            if (
              normalizedPassword.indexOf(
                notWordAllowedWord.substring(start, start + mincs).toLowerCase()
              ) != -1
            ) {
              containsNotWordAllowed = true;
            }
          }
        }
      });

      if (containsNotWordAllowed) {
        validacion = false
      } else if (new_pass.length > 7) {
        validacion = true
      } else {
        validacion = false
      }

      return validacion;
    }
  },
  checkTextFieldsEqual: {
    requirementType: ["string", "string"],
    validateString: function (value, comparission, passElement) {
      if (comparission === "notEqual") return value !== window.document.getElementById(passElement).value;
      else if (comparission === "isEqual") return value === window.document.getElementById(passElement).value;
      else return false
    }
  },
  validateDateFieldError: {
    requirementType: "string",
    validateString: function (value, error) {
      if (error !== "null" && error !== null) return false
      else return true
      /*let formatMoment = "MM/DD/YYYY hh:mm:ss aa"
      if (showTime)
        formatMoment = langLocale == "en" ? "MM/DD/YYYY hh:mm:ss aa" : "DD/MM/YYYY hh:mm:ss"
      else formatMoment = langLocale == "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"

      if (value === formatMoment) return true
      let d = moment(new Date(value), formatMoment).toDate()
      if (d) {
        return true
      } else return false*/

    }
  },

  validateDateRange: {
    requirementType: "string",
    validateString: function (value, passElement) {

      let initD = moment(window.document.getElementById(passElement).defaultValue, window.document.getElementById(passElement).placeholder).toDate()
      let endD = moment(value, window.document.getElementById(passElement).placeholder).toDate()
      return initD < endD



    }
  }
};

export default parsleyValidationGral;

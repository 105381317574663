import React, { useState } from "react"

export const useAlert = (initialMode = false, initialSelected = { title: "", content: "", buttons: [], loading: false, error: "" }) => {
    const [alertOpen, setAlertOpen] = useState(initialMode)
    const [selected, setSelected] = useState(initialSelected)


    const showAlert = (title, content = undefined, buttons = undefined, size = "none", error = undefined) => {
        setAlertOpen(!alertOpen)
        if (title === "LOADING") {
            setSelected({ title: content, content: <div className="loading-container" ><div className="spinner"></div></div>, buttons: (buttons === true) ? undefined : [], size: size, loading: true, error: error })
        }
        else {
            setSelected({ title: title, content: content, buttons: buttons, size: size, loading: false, error: error })
        }
    }
    const closeAlert = () => {
        setAlertOpen(false)
        setSelected({ title: "", content: "", buttons: undefined, size: undefined, loading: false, error: "" })
    }
    return [selected, alertOpen, showAlert, closeAlert]
}
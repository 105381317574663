import React from 'react'
import { withTranslation } from 'react-i18next'
import utils from "../../utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Report from '../../components/basic/Report';


function UserPromotions(props) {
    const user = JSON.parse(utils.getSessionItem("userDetails")).user;

    const { t } = props;


    const handleClick = (evt) => {
        let id = evt.currentTarget.id.split("_")[1];
        console.log("Promotion  id = " + id);
        utils.setSessionItem("current-user-promotion-id", id);
        props.history.push('/support/user_promotion_details');
    }

    return (
        <>
            {utils.isGranted('GIVE_MANUAL_PROMOTION') &&
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                    <li className="nav-item" id="showAccountsDetailsButton">
                        <Link className="nav-link text-end me-3" aria-current="page" to={"/support/user_manual_promotion"} >
                            <FontAwesomeIcon icon="asterisk" /> {t("New manual promotion")}  </Link>
                    </li>
                </ul>

            }
            <Report handleClick={handleClick} rowId="id" reportId="user_promotions" user={user.user} db={user.db} location={props.location}></Report>
        </>
    )

}

export default withTranslation()(UserPromotions)


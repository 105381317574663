import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import EditorJSON from '../../components/basic/EditorJSON';
function CompanyConfig(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [JSONErrors, setJSONErrors] = useState(false);
    const { formValidation } = useFormValidation('Form_CompanyConfig')
    const [companyDetails, setCompanyDetails] = useState({});
    const goBack = () => {
        props.history.goBack();
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Company config'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {

        adminClient.getCompanyConfig(session, company).then(
            async function (result) {
                setCompanyDetails(JSON.parse(result.config));
            },
            function (err) {
                console.error(err);
            }
        );

    }, []);
    const onChangeHandlerJSON = (e) => {
        setCompanyDetails(e);
    };

    const save = (evt) => {
        if (JSONErrors) {
            props.showAlert(t('Provider details'), t('JSON format incorrect'))
        }
        else {
            if (formValidation.validate()) {

                adminClient.updateCompanyConfig(
                    session,
                    company,
                    JSON.stringify(companyDetails, null, 2)
                ).then(
                    (msg) => {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Company config'), t('Saved succesfull'))
                        } else {
                            props.showAlert(t('Company config'), t('Saving error ') + msg.description)
                        }
                    },
                    (error) => {
                        props.showAlert(t('Company config'), t('Saving error ') + error.description)
                    }
                )
            }
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_CompanyConfig">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <EditorJSON
                            onChange={onChangeHandlerJSON}
                            setError={(status) => { setJSONErrors(status) }}
                            value={companyDetails}
                            name={t("Configuration")}
                            height={"700px"}

                        />
                    </div>
                </div>
            </form>
            <div className="row">
                {utils.isGranted("SAVE_COMPANY_CONFIG") &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(CompanyConfig);
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Tab from "../../components/basic/BasicTabs";
import DoubleList from '../../components/basic/DoubleList';
import { useMemo } from 'react';
import BasicTable from '../../components/basic/BasicTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Report from '../../components/basic/Report';



function AdminUsersDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UsersDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [activeTab, setActiveTab] = useState()
    const [userDetails, setUserDetails] = useState(
        {
            user: {
                user: -1,
                company: company,
                alias: '',
                status: 'ENABLED',
                name: '',
                email: '',
                init_page: '',
                otp: null
            },
            new_user_password: '',
            availables: [],
            childs: [],
            parents: [],
            roles: [],
            userCompanies: [],
            accounts: [],
            adminUsers: []
        });
    const [createInCompany, setCreateInCompany] = useState('')
    let isDescriptionDisabled = utils.getCurrentCompany().config.backoffice?.operationDescription === undefined ? true : utils.getCurrentCompany().config.backoffice?.operationDescription
    const initToken = { addRemove: '', amount: 0, currency: '', description: '', isDisabled: isDescriptionDisabled }
    const [token, setToken] = useState(initToken)
    const [parent, setParent] = useState()

    const userId = utils.getSessionItem('current-user')

    const companiesOptionsRoles = useMemo(() => {

        //Comprobamos para que compañias disponibles tiene grant de modificar los roles
        //Comprobamos si el usuario esta ya en esas compañias 

        let comOptAux = JSON.parse(utils.getSessionItem("companiesOptions"))
        let companiesOptions = []
        comOptAux.forEach(element => {
            let userExists = userDetails.userCompanies.find((ucomp) => {
                if (ucomp.company === element.value) return true
            })
            if (element.value != utils.getSessionItem('Company') && utils.isGranted('SAVE_CONFIGURATION_USERS_ROLES', element.value) && !userExists)
                companiesOptions.push(element)
        })

        return companiesOptions

    }, [JSON.stringify(userDetails.userCompanies)])


    const filter = [{ "field": "t.user", "type": "text", "value": userId }];
    utils.setSessionItem("ReportFilter-admin_operations", JSON.stringify(filter));
    utils.setSessionItem("ReportFilter-admin_audit", JSON.stringify(filter))
    utils.setSessionItem("ReportFilterView-admin_operations", JSON.stringify([{ field: "t.user", visibility: "hidden" }]));
    //HEADER
    const goBack = () => {
        props.history.push('/reports/report/users/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('User details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //DATOS User
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        if (name === "password") formValidation.reset()
        setUserDetails((prevUserDetails) => ({ ...prevUserDetails, [name]: value }));
    };

    const onChangeHandlerUser = (e) => {
        const { name, value } = e.target || e;
        setUserDetails((prevUserDetails) => {
            let uData = prevUserDetails.user
            uData[name] = value
            return { ...prevUserDetails, ['user']: uData }
        });
    }
    const onChangeRoles = (configured) => {

        setUserDetails((prevUserDetails) => ({ ...prevUserDetails, ['roles']: configured }));
    }

    //DATOS User
    const refreshReports = () => {
        window['windowRefSummary'].executeReport(0);
        window['windowRefActivity'].executeReport(0);
    }
    //Datos Tokens
    const onChangeHandlerToken = (e) => {
        const { name, value } = e.target || e;
        setToken((prevToken) => ({ ...prevToken, [name]: value }));
    }

    const goToUserDetails = (userId) => {
        console.log("userId  = " + userId);

        utils.setSessionItem("current-user", userId);
        props.history.push('/configuration/users/details2');
    }

    const sendToken = () => {

        adminClient.updateTokens
            (
                session,
                userDetails.user.user,
                company,
                token.addRemove === 'add' ? token.amount * 100 : -token.amount * 100,
                token.currency,
                token.description
            ).then(
                function (response) {

                    setUserDetails((prevUserDetails) => ({ ...prevUserDetails, ['accounts']: response.accounts }));


                    props.showAlert(t("Tokens"), token.addRemove === 'add' ? t("Tokens added ok") : t("Tokens removed ok"))

                    refreshReports();


                },
                function (err) {
                    props.showAlert(t("Tokens"), token.addRemove === 'add' ? t("Tokens added error") : t("Tokens removed error") + " " + err.description)
                }
            );
    }
    //Datos Tokens

    //UnLocked
    const unlockAdmin = () => {

        adminClient.unlockAdmin(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem("Company"),
            JSON.stringify(userDetails.user)
        ).then(
            function (msg) {
                props.showAlert(t("User unlocked"), t("User unlocked  ok"))
                goBack()

            },
            function (err) {
                props.showAlert(t("User unlocked"), t("User unlocked  nok") + " " + err.description)
            })
    }

    //Manejadores envio al Server
    const save = (evt) => {
        let userData = userDetails
        let roles = []
        userData.roles.forEach(element => {
            roles.push(element.role);
        });
        userData.user.roles = roles.join(",");

        if (formValidation.validate()) {
            adminClient.saveAdminUser(
                JSON.parse(utils.getSessionItem("user")).session,
                utils.getSessionItem("Company"),
                JSON.stringify(userData.user),
                userDetails.password || ''
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        if (addOrUpdate === 'add') {
                            utils.setSessionItem('current-user', msg.data.user)
                            setAddOrUpdate('update')
                        }
                        setParent(userDetails.user.parent)
                        props.showAlert(t('User details'), t('Saved succesfull'))
                    } else {
                        props.showAlert(t('User details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('User details'), t('Saving error ') + error.description)
                }
            )
        }
    }
    const remove = (evt) => {
        // adminClient[deleteUsersDetails]().then(
        // (msg) => { 
        //         if(msg.result==='OK'){
        //             props.showAlert(t('User details'),t('Deleted succesfull'))
        //             if(addOrUpdate==='add'){
        //                 setAddOrUpdate('update')              
        //             }
        //             }else {
        //                 props.showAlert(t('User details'),t('Deleting error ')+ msg.description)
        //             }
        //         },
        //     (error) => { 
        //         props.showAlert(t('User details'),t('Deleting error ')+ error.description)
        //     }
        // )
    }

    const create = () => {

        let companyName = JSON.parse(utils.getSessionItem('companiesOptions')).find((comp) => {
            if (comp.value == createInCompany)
                return comp
        }).label
        adminClient.createAdminUserInCompany(
            session,
            createInCompany,
            JSON.stringify(userDetails.user)
        ).then((msg) => {
            if (msg.result == 'OK') {
                setUserDetails((prevUserDetails) => {
                    let userCompanies = prevUserDetails.userCompanies
                    userCompanies.push({ company: createInCompany, name: companyName })
                    return { ...prevUserDetails, ['userCompanies']: userCompanies }
                });
                props.showAlert(t('User details'), <>
                    <p>{t('User created in') + " " + companyName}</p>
                    <p>{t('If you want to modify the user in') + " " + companyName + " " + t('you must change to') + " " + companyName + " " + t('edit user')}</p>
                </>)
            }
            else props.showAlert(t('User details'), t('Creating error'))
        }, (err) => {
            props.showAlert(t('User details'), t('Creating error'))
        })
    }
    const rolesRender = () => {

        return (<>
            <DoubleList
                availables={userDetails.availables}
                configured={userDetails.roles}
                idProperty={"role"}
                configuredListName={t("Granted roles")}
                handleUpdateList={onChangeRoles}
            ></DoubleList>
        </>)



    }
    const tokensRender = () => {

        const tokensBalanceColumnsDef = [
            { Header: t("Account"), accessor: 'account', typeCell: 'text', filter: 'text' },
            { Header: t("Amount"), accessor: 'amount', typeCell: 'currency' }
        ]
        const headerBalance = {
            title: t('Accounts'),
            buttons: [
                {
                    handle: () => { onChangeHandlerToken({ target: { name: 'addRemove', value: 'remove' } }) },
                    icon: <FontAwesomeIcon icon="minus-square" />
                },
                {
                    handle: () => { onChangeHandlerToken({ target: { name: 'addRemove', value: 'add' } }) },
                    icon: <FontAwesomeIcon icon="plus-square" />
                }
            ]
        }

        return <>
            <LayoutSection {...props} dataHeader={headerBalance}>
                <div className="row">
                    <div className={token.addRemove == '' ? 'col-md-12' : "col-md-6"}>
                        <BasicTable key="table-past-Promotions" data={userDetails.accounts} columns={tokensBalanceColumnsDef} hidePagination={true} />
                    </div>
                    {token.addRemove !== '' &&
                        <div className="col-md-6">
                            <div className="col-12 col-md-6">
                                <TextField label={t("Amount")} type="text" name="amount" value={token.amount} onChange={onChangeHandlerToken} />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    label={t("Currency")}
                                    idProperty="currency"
                                    nameProperty="currency"
                                    name="currency"
                                    data="currencies"
                                    basicInfoName="CurrenciesCompany"
                                    onChange={onChangeHandlerToken}
                                    value={token.currency}
                                    noEmptyValue
                                />
                            </div>
                            <div className="col-12 col-md-12">
                                <TextField label={t("Description")} name='description' type="text" value={token.description} onChange={onChangeHandlerToken} disabled={token.isDisabled} />
                            </div>
                            {utils.isGranted('CREATE_TOKENS') &&
                                <div className="col-12">
                                    <button className="btn btn-secondary" onClick={() => { setToken(initToken) }}>{t("Cancel")}</button>
                                    <button className="btn btn-primary" onClick={sendToken}>{token.addRemove == 'add' ? t('Add') : t('Remove')}</button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </LayoutSection>
            <div className="row">
                <Report
                    windowRef="windowRefSummary"
                    reportId="admin_tokens_summary"
                    filterId="admin_operations"
                    viewFilter="admin_operations" />

            </div></>

    }
    const operationsRender = () => {

        return <Report
            windowRef="windowRefActivity"
            reportId="admin_operations"
            filterId="admin_operations"
            viewFilter="admin_operations" />

    }
    const hierarchyRender = () => {

        return <><BasicSelect
            idProperty="user"
            nameProperty="alias"
            label={t("Parent")}
            name="parent"
            addOpts={userDetails.adminUsers}
            onChange={onChangeHandlerUser}
            value={userDetails.user.parent}
        />
            <div className="col-12  ">
                <label htmlFor="parent"><b>{t("Parents")}</b></label>
                <div className="row">
                    {
                        userDetails.parents.map(function (parent, index, array) {
                            return (
                                <div className="float-start w-auto">
                                    <a
                                        href="#"
                                        className="float-start"
                                        onClick={() => goToUserDetails(parent.user)}
                                    >{parent.alias}</a>
                                </div>)
                        })}
                </div>

                <label htmlFor="childs"><b>{t("Childs")}</b></label>
                <div className="row">
                    {
                        userDetails.childs.map(function (child, index, array) {
                            return (
                                <div className="float-start w-auto">
                                    <a href="#"
                                        onClick={() => goToUserDetails(child.user)}
                                    >{child.alias}</a>
                                </div>)
                        })
                    }
                </div>
            </div>
        </>


    }
    const auditRender = () => {
        return <Report
            windowRef="windowRefAudit"
            reportId="admin_audit"
            filterId="admin_audit" />
    }
    const disableOTP = () => {

        adminClient.disableOTP(
            session,
            company,
            JSON.stringify(userDetails.user.user)
        ).then(
            function (msg) {
                let user = JSON.parse(utils.getSessionItem("user"))
                user['otpEnabled'] = false
                utils.setSessionItem("user", JSON.stringify(user))
                props.showAlert(t("User OTP"), t("User disabled otp"))

            },
            function (err) {
                props.showAlert(t("User OTP"), t("User error in disabled otp") + " " + err.description)
            })
    }

    const OTPRender = () => {

        return (<>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-secondary" onClick={disableOTP}>{t("Disable")}</button>
                </div>
            </div>
        </>
        )

    }
    const lockedRender = () => {

        return (<>
            <div className="col-12">
                <TextField disabled label={t("Invalid logins")} value={userDetails.logins}></TextField>
            </div>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-secondary" onClick={unlockAdmin}>{t("Unlock")}</button>
                </div>
            </div>
        </>
        )

    }


    const getTabsByPrivileges = () => {
        let tabsConf = []
        if (utils.isGranted('VIEW_CONFIGURATION_USERS_ROLES'))
            tabsConf.push({ title: t("Roles"), content: rolesRender })
        if (utils.isGranted('VIEW_CONFIGURATION_USERS_TOKENS'))
            tabsConf.push({ title: t("Tokens"), content: tokensRender })
        if (utils.isGranted('VIEW_CONFIGURATION_USERS_OPERATIONS'))
            tabsConf.push({ title: t("Operations"), content: operationsRender })
        if (utils.isGranted('VIEW_CONFIGURATION_USERS_HIERARCHY'))
            tabsConf.push({ title: t("Hierarchy"), content: hierarchyRender })
        if (utils.isGranted('VIEW_CONFIGURATION_USERS_AUDIT'))
            tabsConf.push({ title: t("Audit"), content: auditRender })
        if (userDetails.logins !== undefined && userDetails.logins > 0)
            tabsConf.push({ title: t("Locked"), content: lockedRender })
        if (userDetails.user.otp !== null && userDetails.user.otp !== undefined)
            tabsConf.push({ title: t("OTP"), content: OTPRender })

        return tabsConf
    }

    const [tabsContent, setTabsContent] = useState(getTabsByPrivileges());
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassAdmin || 11

    //Carga de datos
    useEffect(function () {
        if (userId !== undefined && userId !== '-1') {
            let promises = [adminClient.getAdminUserDetails(session, company, userId), adminClient.getAdminUserAccounts(JSON.parse(utils.getSessionItem("user")).session, userId, utils.getSessionItem("Company")), adminClient.getAdminUsers(
                JSON.parse(utils.getSessionItem("user")).session,
                utils.getSessionItem("Company")
            )]

            Promise.all(promises).then(
                function (result) {
                    let userData = {}
                    userData.user = result[0].data
                    userData.userCompanies = result[0].userCompanies
                    userData.childs = result[0].childs
                    userData.parents = result[0].parents
                    userData.roles = result[0].roles
                    userData.availables = result[0].availables
                    userData.logins = result[0].logins
                    userData.accounts = result[1].accounts
                    result[2].users.push({ user: -1, alias: t('No parent') });
                    userData.adminUsers = result[2].users.filter((item) => {
                        return Number(item.user) !== Number(userId)
                    })
                    setUserDetails(userData)
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, [addOrUpdate, parent]);

    useEffect(() => {
        setTabsContent(getTabsByPrivileges());
    }, [JSON.stringify(userDetails), addOrUpdate, token]);

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UsersDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('Identifier')}
                            name="user"
                            required
                            disabled
                            type="text"
                            value={userDetails.user.user}
                            onChange={onChangeHandlerUser}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t("Alias")}
                            name="alias"
                            required
                            inputProps={{ minLength: 3 }}
                            type="text"
                            value={userDetails.user.alias}
                            onChange={onChangeHandlerUser}
                        />
                    </div>
                    <div className="col-12  col-md-3  ">
                        <TextField
                            id="new_user_password"
                            key="new_user_password"
                            label={t("Password")} required={addOrUpdate === 'add' ? true : false} type="password"
                            inputProps={{
                                "data-parsley-validate-password": JSON.stringify([minLength, ""]),
                                id: "newPassword",
                                autoComplete: 'new-password'
                            }}
                            onChange={(evt) => {
                                evt.target.name = "password"
                                onChangeHandler(evt)
                            }} value={userDetails.password} />
                    </div>
                    <div className="col-12  col-md-3  ">
                        <BasicSelect
                            idProperty='name'
                            basicInfoName='EnabledDisabled'
                            value={userDetails.user.status}
                            name='status'
                            label={t('Status')}
                            onChange={onChangeHandlerUser}
                            translate={{ property: 'name', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12  col-md-4  ">
                        <TextField
                            label={t("Name")}
                            name="name"
                            type="text"
                            onChange={onChangeHandlerUser}
                            required
                            value={userDetails.user.name}
                        />
                    </div>
                    <div className="col-12  col-md-4  ">
                        <TextField
                            label={t("Email")}
                            type="text"
                            name="email"
                            onChange={onChangeHandlerUser}
                            required
                            value={userDetails.user.email}
                        />
                    </div>
                    <div className="col-12  col-md-4 ">
                        <BasicSelect
                            label={t("Init page")}
                            idProperty="url"
                            nameProperty="page"
                            name="init_page"
                            basicInfoName="InitPages"
                            onChange={onChangeHandlerUser}
                            value={userDetails.user.init_page}
                            translate={{ property: "page", prefix: "INIT_PAGES_" }}
                        />
                    </div>
                    <div className="col-12  col-md-4 ">
                        <BasicSelect
                            label={t("Default Company")}
                            idProperty="company"
                            name="company"
                            addOpts={userDetails.userCompanies}
                            value={userDetails.user.company}
                            onChange={onChangeHandlerUser}
                            disableClearable
                        />
                    </div>
                    <div className="col-12  col-md-4 ">
                        <BasicSelect
                            idProperty='currency'
                            nameProperty='currency'
                            data='currencies'
                            basicInfoName='CurrenciesCompany'
                            value={userDetails.user.currency}
                            name='currency'
                            label={t('Currency')}
                            onChange={onChangeHandlerUser}
                            disableClearable
                        />
                    </div>

                </div>
            </form>

            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={save}
                    >
                        {t('Save')}
                    </button>
                    {false && addOrUpdate == 'update' && <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={remove}
                    >
                        {t('Delete')}
                    </button>}
                </div>
            </div>
            {/* Comprobamos si el usuarioAdministrador tiene permisos para crear usuarios en otras compañias Si solo es en una y es en la que estoy no saco posibilidad de crear usuario Si hay más de una saco el desplegable y el boton*/}

            {addOrUpdate == 'update' && companiesOptionsRoles.length > 0 && <>
                <div className="row mt-4">
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            label={t('Create in')}
                            idProperty="value"
                            name="company"
                            nameProperty="label"
                            addOpts={companiesOptionsRoles}
                            value={createInCompany}
                            onChange={(evt) => {
                                setCreateInCompany(evt.value)
                            }}
                            noEmptyValue
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={create}
                            style={{ float: 'left' }}
                        >
                            {t('Create')}
                        </button>
                    </div>
                </div>
            </>}
            {/* TABS */}
            {addOrUpdate == 'update' &&
                <div className="row">
                    <Tab>
                        {tabsContent.map((tab, idx) => (
                            <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                {tab.content()}
                            </Tab.TabPane>
                        ))}
                    </Tab>
                </div>}
        </LayoutSection>
    )
}
export default withTranslation()(AdminUsersDetails);
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from "../../components/DateAndTimePicker";
import UserInput from "../../components/UserInput";
import Report from "../../components/basic/Report";



function UserTicketDetails(props) {

    const { t } = props;
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;
    const ticket = JSON.parse(utils.getSessionItem("current_row_user_tickets"));
    const ticketId = ticket.id.split(".");
    utils.setSessionItem("ReportFilter-ticket_messages", JSON.stringify([{ field: "ticket", type: "=", value: ticketId[1] }]));
    const [ticketDetails, setTicketDetails] = useState(
        {
            status: "OPEN",
            title: "",
            alias: "",
            user: "",
            db: "",
            ticket: "",
            created_date: null
        }
    );

    const adminClient = new AdminClient();


    useEffect(function () {
        adminClient.getTicketDetails(session, company, ticketId[0], ticketId[1]).then(
            (res) => {
                setTicketDetails(res.data);
                window["ticket_messages"].executeReport(0);
            }, (err) => {
                console.log(err);
            }
        );


    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setTicketDetails((prevState) => ({ ...prevState, [name]: value }));
    };



    const handleSubmit = () => {
        if (formValidation.validate()) {

            let promise = adminClient.saveTicketMessage(
                session,
                JSON.stringify(ticketDetails)
            );

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t("Correct"), <p>{t("Note saved")}</p>);
                        goBack()
                    }

                }, function (err) {
                    console.log("Note error" + err);
                    props.showAlert(t("Error"), <p>{t("Error in save Note")}</p>)
                }
            );

        }

    };


    const goBack = () => {
        props.history.push("/support/report/user_tickets/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Ticket details"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("Form_TicketDetails");


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_TicketDetails">
                <div className="row">
                    <div className="col-12 col-md-4 col-xxl-3">
                        <UserInput alias={ticketDetails.alias} user={ticketDetails.user}></UserInput>
                    </div>

                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Identifier")} type="text" value={ticketDetails.db + "." + ticketDetails.ticket} />

                    </div>

                    <div className="col-12 col-md-4 col-xxl-2">
                        <DateAndTimePicker
                            disabled
                            renderInput={(props) => <TextField  {...props} />}
                            label={t("Created date")}
                            value={utils.formatDate(ticketDetails.created_date || '')}
                            showTime={true}
                        />
                    </div>

                    <div className="col-12 col-md-4 col-xxl-2">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            basicInfoName="TicketStatus"
                            name="status"
                            onChange={onChangeHandler}
                            value={ticketDetails.status}
                            translate={{ property: "status", prefix: "TICKET_STATUS_" }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField disabled label={t("Title")} type="text" value={ticketDetails.title} />
                    </div>
                    <div className="col-12 pt-3">
                        <TextField multiline label={t("New message")} type="text" name="message" value={ticketDetails.message} onChange={onChangeHandler} />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Save")}</button>
                </div>
            </div>
            <div className="row">
                <Report windowRef="ticket_messages" reportId="ticket_messages" limit={5} filterId="ticket_messages"></Report>

            </div>
        </LayoutSection>
    );
}

export default withTranslation()(UserTicketDetails);

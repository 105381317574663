import React from 'react'
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom'
import Report from '../../components/basic/Report';


const ReportPage = (props) => {

  let path = props.location.pathname.split("/")
  let reportId = path[path.length - 2]
  let view = path[path.length - 1]
  return (
    <Report reportId={reportId} view={view} reportDoc={true} historyBack={true} showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert} history={props.history} />
  )
}

export default withTranslation()(withRouter(ReportPage))
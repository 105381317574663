import React from "react";

function PageNotFound() {
    return (
        <>
            {/* Mensaje cuando llegue a página incorrecta */}
            <p>Uy, llegaste a un mundo desconocido. Mejor regresa al inicio.</p>
        </>
    );
}

export default PageNotFound;
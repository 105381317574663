import SSE from './libs/sse'
//const host = process.env.REACT_APP_CALIMACO_API_BASE;
const axios = require('axios');
const qs = require("qs")


class AdminClient {

	constructor() {

		switch (process.env.REACT_APP_LOCAL_API) {
			case "1":
				this.adminApiUrl = "http://localhost:18097";
				this.adminReportsURL = "http://localhost:18098";
				break;
			case "2":
				this.adminApiUrl = "https://api-staging.calimaco.com/api/admin";
				this.adminReportsURL = "https://api-staging.calimaco.com/api/admin_reports";
				break;
			case "3":
				this.adminApiUrl = "https://wallet.casinoenvivo.club/api//admin";
				this.adminReportsURL = "https://wallet.casinoenvivo.club/api/admin_reports";
				break;
			case "4":
				this.adminApiUrl = "https://wallet.f0rbet.com/api/admin/";
				this.adminReportsURL = "https://wallet.f0rbet.com/api/admin_reports";
				break;
			default:

				this.adminApiUrl = window.host + "/api/admin";
				this.adminReportsURL = window.host + "/api/admin_reports";

		}
	}

	post(url, data) {
		return new Promise(
			function (resolve, reject) {
				axios.post(url, data)
					.then((res) => {
						if (res.data.result === "OK") {
							resolve(res.data);
						} else {
							reject(res.data);
						}
					}).catch((err) => {
						console.error("AdminClient:post:error: " + JSON.stringify(err));
						var error = { description: err.message };
						reject(error);
					});

			});
	}

	post2(url, data) {
		return new Promise(
			function (resolve, reject) {
				axios.post(url, null, { params: data })
					.then((res) => {
						if (res.data.result === "OK") {
							resolve(res.data);
						} else {
							reject(res.data);
						}
					}).catch((err) => {
						console.error("AdminClient:post:error: " + JSON.stringify(err));
						var error = { description: err.message };
						reject(error);
					});

			});
	}

	post3(url, data) {
		return new Promise(
			function (resolve, reject) {
				let options = {
					method: "POST",
					headers: { "content-type": "application/x-www-form-urlencoded" },
					data: qs.stringify(data),
					url
				};
				axios(options).then((res) => {
					if (res.data.result === "OK") {
						resolve(res);
					} else {
						reject(res.data);
					}
				}).catch((err) => {
					console.error("AdminClient:post:error: " + JSON.stringify(err));
					var error = { description: err.message };
					reject(error);
				});

			});
	}

	postFile(url, data) {
		return new Promise(
			function (resolve, reject) {
				axios.post(url, data)
					.then((res) => {
						resolve(res);
					}).catch((err) => {
						console.error("AdminClient:post:error: " + JSON.stringify(err));
						var error = { description: err.message };
						reject(error);
					});

			});
	}

	batchPost(url, data, callBackFunction) {
		return new Promise(
			function (resolve, reject) {
				let source = new SSE(url, {
					payload: data,
					method: 'POST'
				});


				source.addEventListener('message', function (event) {
					// Assuming we receive JSON-encoded data payloads:					
					if (callBackFunction !== undefined) {
						console.log(JSON.parse(event.data))
						callBackFunction(JSON.parse(event.data))
					}
				})
				source.addEventListener('close', function (event) {
					source.close()
					if (event.data !== '') {
						let msg = JSON.parse(event.data)
						if (msg.result === 'OK')
							resolve(msg)
						else reject(msg)
					} else resolve(event.data)
				})
				source.stream();
			});

	}

	postJSON2(url, data) {
		return new Promise(
			function (resolve, reject) {
				const axiosConfig = {
					headers: {
						"Access-Control-Allow-Origin": "*",
						'Content-Type': 'application/json'
					}

				};
				axios.post(url, data, axiosConfig)
					.then((res) => {
						if (res.data !== undefined) {
							resolve(res.data);
						} else {
							resolve(res);
						}
					}).catch((err) => {
						reject(err);
					});
			});
	}

	getLastNonPrintedBet(session, company) {
		console.log("AdminClient: getLastNonPrintedBet: session " + session);
		return this.post(this.adminApiUrl + '/getLastNonPrintedBet', "session=" + session + "&company=" + company);
	}


	getLastBet(session, company, game) {
		console.log("AdminClient: getLastBet: session " + session);
		return this.post(this.adminApiUrl + '/getLastBet', "session=" + session + "&company=" + company + "&game=" + game);
	}

	validateBetBarcode(session, company, barcode) {
		console.log("AdminClient: validateBetBarcode: session " + session);
		return this.post(this.adminApiUrl + '/validateBetBarcode', "session=" + session + "&company=" + company + "&barcode=" + barcode);
	}

	getAgentBetDetails(session, company, game) {
		console.log("AdminClient: getAgentBetDetails: session " + session);
		return this.post(this.adminApiUrl + '/getAgentBetDetails', "session=" + session + "&company=" + company + "&game=" + game);
	}

	payBet(session, company, db, operation) {
		console.log("AdminClient: payBet: session " + session);
		return this.post(this.adminApiUrl + '/payBet', "session=" + session + "&company=" + company + "&db=" + db + "&operation=" + operation);
	}

	machinesUpdatetBatch(session, company, machines, add_lobbies, remove_lobbies, machine_type, add_tags, remove_tags, demo, status, set, view_granted_groups, view_forbidden_groups, play_granted_groups, play_forbidden_groups, allow_freespins) {
		return this.post(this.adminApiUrl + '/machinesUpdatetBatch', "session=" + session + "&machines=" + machines + "&company=" + company +
			"&add_lobbies=" + add_lobbies + "&remove_lobbies=" + remove_lobbies + "&machine_type=" + machine_type + "&add_tags=" + add_tags +
			"&remove_tags=" + remove_tags + "&demo=" + demo + "&status=" + status + "&set=" + set +
			"&view_granted_groups=" + view_granted_groups + "&view_forbidden_groups=" + view_forbidden_groups + "&play_granted_groups=" + play_granted_groups + "&play_forbidden_groups=" + play_forbidden_groups + "&allow_freespins=" + allow_freespins);
	}

	bcWinningsBatch(session, company, operations, comment) {
		return this.post(this.adminApiUrl + '/bcWinningsBatch', "session=" + session + "&operations=" + operations + "&company=" + company + "&comment=" + comment);
	}

	ezugiWinningsBatch(session, company, operations, comment) {
		return this.post(this.adminApiUrl + '/ezugiWinningsBatch', "session=" + session + "&operations=" + operations + "&company=" + company + "&comment=" + comment);
	}

	saveRedirectionDetails(session, company, redirection) {
		return this.post(this.adminApiUrl + '/saveRedirectionDetails', "session=" + session + "&redirection=" + redirection + "&company=" + company);
	}

	getBanners(session, company) {
		return this.post(this.adminApiUrl + '/getBanners', "session=" + session + "&company=" + company);
	}

	getLangs(session, company) {
		return this.post(this.adminApiUrl + '/getLangs', "session=" + session + "&company=" + company);
	}

	getDepartments(session, company) {
		return this.post(this.adminApiUrl + '/getDepartments', "session=" + session + "&company=" + company);
	}

	getEmailContent(session, company, website, event) {
		return this.post(this.adminApiUrl + '/getEmailContent', "session=" + session + "&company=" + company + "&website=" + website + "&event=" + event);
	}

	getDepartmentDetails(session, company, id) {
		return this.post(this.adminApiUrl + '/getDepartmentDetails', "session=" + session + "&company=" + company + "&id=" + id);
	}

	getUserAddressDetails(session, company, user, user_id) {
		return this.post(this.adminApiUrl + '/getUserAddressDetails', "session=" + session + "&company=" + company + "&user=" + user + "&user_id=" + user_id);

	}

	getRedirectionDetails(session, company, url) {
		return this.post(this.adminApiUrl + '/getRedirectionDetails', "session=" + session + "&url=" + url + "&company=" + company);
	}

	executeInternalTask(session, company, task) {
		return this.post(this.adminApiUrl + '/executeInternalTask', "session=" + session + "&task=" + task + "&company=" + company);
	}

	getAutoexclusionDetails(session, company, db, user, init_date) {
		return this.post(this.adminApiUrl + '/getAutoexclusionDetails', "session=" + session + "&db=" + db + "&user=" + user + "&company=" + company + "&init_date=" + init_date);

	}
	saveAutoexclusionDetails(session, company, autoexclusion) {
		return this.post(this.adminApiUrl + '/saveAutoexclusionDetails', "session=" + session + "&company=" + company + "&autoexclusion=" + autoexclusion);

	}

	mobileCreateVerificationCode(session, company, mobile) {
		return this.post(this.adminApiUrl + '/mobileCreateVerificationCode', "session=" + session + "&mobile=" + mobile + "&company=" + company);

	}
	mobileVerifyVerificationCode(session, company, mobile, code) {
		return this.post(this.adminApiUrl + '/mobileVerifyVerificationCode', "session=" + session + "&mobile=" + mobile + "&company=" + company + "&code=" + code);

	}
	register(session, company, user) {
		return this.post(this.adminApiUrl + '/register', "session=" + session + "&user=" + user + "&company=" + company);
	}

	getServiceDetails(session, company, service) {
		return this.post(this.adminApiUrl + '/getServiceDetails', "session=" + session + "&service=" + service + "&company=" + company);
	}

	getGrantedPayoutStatus(session, company) {
		return this.post(this.adminApiUrl + '/getGrantedPayoutStatus', "session=" + session + "&company=" + company);
	}
	getGrantedNextPayoutStatus(session, company, from_status) {
		return this.post(this.adminApiUrl + '/getGrantedNextPayoutStatus', "session=" + session + "&company=" + company + "&from_status=" + from_status);
	}
	reloadService(session, company, service) {
		return this.post(this.adminApiUrl + '/reloadService', "session=" + session + "&service=" + service + "&company=" + company);
	}


	updatePasswordAgent(session, company, db, user, password) {
		return this.post(this.adminApiUrl + '/updatePasswordAgent', "session=" + session + "&company=" + company + "&user=" + user + "&db=" + db + "&password=" + password);
	}


	updatePasswordUser(session, company, db, user, password) {
		return this.post(this.adminApiUrl + '/updatePasswordUser', "session=" + session + "&company=" + company + "&user=" + user + "&db=" + db + "&password=" + password);
	}

	manualDeposit(session, company, db, user, method, amount, status, comment, idempotence, bank) {
		var data = "session=" + session + "&company=" + company + "&user=" + user + "&method=" + method + "&amount=" + amount + "&status=" + status + "&comment=" + comment + "&db=" + db;
		if (idempotence !== undefined && idempotence !== null && idempotence !== 'undefined') {
			data += "&idempotence=" + idempotence;
		}
		if (bank !== undefined && bank !== null && bank !== 'undefined') {
			data += "&bank=" + bank;
		}

		return this.post(this.adminApiUrl + '/manualDeposit', data);
	}

	validateIdempotenceManualDeposit(session, company, idempotence) {
		return this.post(this.adminApiUrl + '/validateIdempotenceManualDeposit', "session=" + session + "&company=" + company + "&idempotence=" + idempotence);

	}

	validateIdempotenceManualPayout(session, company, idempotence) {
		return this.post(this.adminApiUrl + '/validateIdempotenceManualPayout', "session=" + session + "&company=" + company + "&idempotence=" + idempotence);

	}


	manualAdjustment(session, company, db, user, account, amount, comment) {
		return this.post(this.adminApiUrl + '/manualAdjustment', "session=" + session + "&company=" + company + "&user=" + user + "&account=" + account + "&amount=" + amount + "&comment=" + comment + "&db=" + db);
	}

	manualPayout(session, company, db, user, method, amount, status, comment, bank) {

		var data = "session=" + session + "&company=" + company + "&db=" + db + "&user=" + user + "&method=" + method + "&amount=" + amount + "&status=" + status + "&comment=" + comment;
		if (bank !== undefined && bank !== null && bank !== 'undefined') {
			data += "&bank=" + bank;
		}
		return this.post(this.adminApiUrl + '/manualPayout', data);
	}

	manualProcessPayout(session, company, user, db, operation, comment, idempotence, otp_key) {
		return this.post(this.adminApiUrl + '/manualProcessPayout', "session=" + session + "&company=" + company + "&user=" + user + "&db=" + db + "&operation=" + operation + "&comment=" + comment + "&otp_key=" + otp_key + "&idempotence=" + idempotence);
	}

	login(alias, password, otp) {
		return this.post(this.adminApiUrl + '/login', "alias=" + alias + "&password=" + password + "&otp=" + otp);
	}

	isValidSession(session) {
		return this.post(this.adminApiUrl + '/isValidSession', "session=" + session);
	}

	logout(session) {
		return this.post(this.adminApiUrl + '/logout', "session=" + session);
	}

	getExternalListDetails(session, company, list) {
		return this.post(this.adminApiUrl + '/getExternalListDetails', "session=" + session + "&company=" + company + "&list=" + list);
	}
	saveExternalListDetails(session, company, list) {
		return this.post(this.adminApiUrl + '/saveExternalListDetails', "session=" + session + "&company=" + company + "&list=" + list);
	}
	addExternalListItems(session, company, list, items) {
		return this.post(this.adminApiUrl + '/addExternalListItems', "session=" + session + "&company=" + company + "&list=" + list + "&items=" + items);
	}
	removeExternalListItems(session, company, list, items) {
		return this.post(this.adminApiUrl + '/removeExternalListItems', "session=" + session + "&company=" + company + "&list=" + list + "&items=" + items);
	}
	removeAllExternalListItems(session, company, list) {
		return this.post(this.adminApiUrl + '/removeAllExternalListItems', "session=" + session + "&company=" + company + "&list=" + list);
	}

	getHelp(session, company, location) {
		return this.post(this.adminApiUrl + '/getHelp', "session=" + session + "&company=" + company + "&location=" + location);
	}

	getServerTime(session, company) {
		return this.post(this.adminApiUrl + '/getServerTime', "session=" + session + "&company=" + company);
	}
	addManualMembers(session, company, group, items, callBackFunction) {

		var formData = new FormData();
		formData.append("session", session);
		formData.append("company", company);
		formData.append("group", group);
		formData.append("items", items);
		return this.batchPost(this.adminApiUrl + '/addManualMembers', formData, callBackFunction)
	}
	removeManualMembers(session, company, group, items) {
		return this.post(this.adminApiUrl + '/removeManualMembers', "session=" + session + "&company=" + company + "&group=" + group + "&items=" + items);
	}
	sendCSVGroups(session, company, group, file, callBackFunction) {
		var formData = new FormData();
		formData.append("session", session);
		formData.append("company", company);
		formData.append("group", group);
		formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + '/sendCSVGroups', formData, callBackFunction)
	}

	removeAllManualMembers(session, company, group) {
		return this.post(this.adminApiUrl + '/removeAllManualMembers', "session=" + session + "&company=" + company + "&group=" + group);
	}

	getExcelReport(session, company, report, filter, user, db, limit, lang, callFilter = '') {
		let data = {
			'session': session,
			'company': company,
			'report': report,
			'filter': JSON.stringify(filter),
			'user': user,
			'db': db,
			'limit': limit,
			'lang': lang,
			'excel': true,
			'callFilter': callFilter
		}
		let url = this.adminReportsURL + "/getReport"

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.onload = function () {
				resolve(xhr.response)
			}
			xhr.onerror = function () {

				reject({
					status: "error",
					code: this.status,
					statusText: xhr.statusText
				});
			}
			xhr.open("POST", url);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.responseType = "blob";
			xhr.send(qs.stringify(data));

		});
	}

	getCSVReport(session, company, report, filter, user, db, limit, lang, callFilter = '') {

		let data = {
			'session': session,
			'company': company,
			'report': report,
			'filter': JSON.stringify(filter),
			'user': user,
			'db': db,
			'limit': limit,
			'lang': lang,
			'csv': true,
			'callFilter': callFilter
		}
		let url = this.adminReportsURL + "/getReport"

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.onload = function () {
				resolve(xhr.response)
			}
			xhr.onerror = function () {

				reject({
					status: "error",
					code: this.status,
					statusText: xhr.statusText
				});
			}
			xhr.open("POST", url);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.responseType = "blob";
			xhr.send(qs.stringify(data));

		});
	}

	getPDFReport(session, company, report, filter, user, db, limit, lang, callFilter = '') {

		let data = {
			'session': session,
			'company': company,
			'report': report,
			'filter': JSON.stringify(filter),
			'user': user,
			'db': db,
			'limit': limit,
			'lang': lang,
			'pdf': true,
			'callFilter': callFilter
		}
		let url = this.adminReportsURL + "/getReport"

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.onload = function () {
				resolve(xhr.response)
			}
			xhr.onerror = function () {

				reject({
					status: "error",
					code: this.status,
					statusText: xhr.statusText
				});
			}
			xhr.open("POST", url);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.responseType = "blob";
			xhr.send(qs.stringify(data));

		});

	}

	getReport(session, company, report, filter, user, db, limit = '', sort = '', callFilter = '') {
		return this.post(this.adminReportsURL + '/getReport', "session=" + session + "&company=" + company + "&report=" + report + "&filter=" + encodeURIComponent(JSON.stringify(filter)) + "&user=" + user + "&db=" + db + "&limit=" + limit + "&sort=" + sort + "&callFilter=" + callFilter);
	}

	manualDepositRevisionBatch(session, company, operations, method, status, callBackFunction, file) {
		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('operations', JSON.stringify(operations))
		formData.append('method', method)
		formData.append('status', status)
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + "/manualDepositRevisionBatch", formData, callBackFunction)
	}

	batchPromotionTS(session, company, freebet, base_amount, players, comments, idempotence) {
		return this.post(this.adminApiUrl + '/batchPromotionTS', "session=" + session + "&company=" + company + "&freebet=" + freebet + "&base_amount=" + base_amount + "&players=" + players + "&comments=" + comments + "&idempotence=" + idempotence);
	}

	batchPromotion(session, company, promotion, base_amount, players, comments, type, idempotence, group, callBackFunction) {

		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('promotion', promotion)
		if (base_amount !== undefined && !Number.isNaN(base_amount))
			formData.append('base_amount', Number(base_amount))
		formData.append('players', players)
		formData.append('type', type)
		formData.append('comments', comments)
		formData.append('idempotence', idempotence)
		formData.append('group', group || '')

		return this.batchPost(this.adminApiUrl + '/batchPromotion', formData, callBackFunction);

	}
	manualPromotion(session, company, promotion, base_amount, player) {
		return this.post(this.adminApiUrl + '/batchPromotion', "session=" + session + "&company=" + company + "&promotion=" + promotion + "&base_amount=" + base_amount + "&player=" + player);
	}

	getKPI(session, company, kpi, filter) {
		return this.post(this.adminReportsURL + '/getKPI', "session=" + session + "&company=" + company + "&kpi=" + kpi + "&filter=" + JSON.stringify(filter));
	}

	getI18n(company) {
		return this.post(this.adminApiUrl + '/getI18n', "company=" + company);
	}

	getMenus(session, company) {
		return this.post(this.adminApiUrl + '/getMenus', "session=" + session + "&company=" + company);
	}

	getUserCompanies(session) {
		return this.post(this.adminApiUrl + '/getUserCompanies', "session=" + session);
	}

	getHelpUrls(session) {
		return this.post(this.adminApiUrl + '/getHelpUrls', "session=" + session);

	}
	getSections(session, company) {
		return this.post(this.adminApiUrl + '/getSections', "session=" + session + "&company=" + company);
	}

	getAccountsSets(session) {
		return this.post(this.adminApiUrl + '/getAccountsSets', "session=" + session);
	}

	getManualPromotions(session, company) {
		return this.post(this.adminApiUrl + '/getManualPromotions', "session=" + session + "&company=" + company);
	}

	getAccounts(session, company) {
		return this.post(this.adminApiUrl + '/getAccounts', "session=" + session + "&company=" + company);
	}
	getRegulatoryStatus(session, company) {
		return this.post(this.adminApiUrl + '/getRegulatoryStatus', "session=" + session + "&company=" + company);
	}

	getCurrencies(session) {
		return this.post(this.adminApiUrl + '/getCurrencies', "session=" + session);
	}

	getCurrenciesCompany(session, company) {
		return this.post(this.adminApiUrl + '/getCurrenciesCompany', "session=" + session + "&company=" + company);
	}
	getAgents(session, company) {
		return this.post(this.adminApiUrl + '/getAgents', "session=" + session + "&company=" + company);
	}

	getInitPages(session, company) {
		return this.post(this.adminApiUrl + '/getInitPages', "session=" + session + "&company=" + company);
	}

	getCompanyLobbies(session, company) {
		return this.post(this.adminApiUrl + '/getCompanyLobbies', "session=" + session + "&company=" + company);
	}

	getMachinesTags(session, company) {
		return this.post(this.adminApiUrl + '/getMachinesTags', "session=" + session + "&company=" + company);
	}

	getPromotionsTags(session, company) {
		return this.post(this.adminApiUrl + '/getPromotionsTags', "session=" + session + "&company=" + company);
	}
	getInternalTaskDetails(session, company, task) {
		return this.post(this.adminApiUrl + '/getInternalTaskDetails', "session=" + session + "&task=" + task + "&company=" + company);
	}

	getAccountsSetDetails(session, company, accountSet) {
		return this.post(this.adminApiUrl + '/getAccountsSetDetails', "session=" + session + "&set=" + accountSet + "&company=" + company);
	}

	getAffiliateDetails(session, company, affiliate) {
		return this.post(this.adminApiUrl + '/getAffiliateDetails', "session=" + session + "&affiliate=" + affiliate + "&company=" + company);
	}

	getAffiliates(session, company) {
		return this.post(this.adminApiUrl + '/getAffiliates', "session=" + session + "&company=" + company);
	}
	saveAccountsSet(session, company, accountSet) {
		return this.post(this.adminApiUrl + '/saveAccountsSet', "session=" + session + "&accountSet=" + accountSet + "&company=" + company);
	}

	addAffiliate(session, company, affiliate) {
		return this.post(this.adminApiUrl + '/addAffiliate', "session=" + session + "&affiliate=" + affiliate + "&company=" + company);
	}

	updateAffiliate(session, company, affiliate) {
		return this.post(this.adminApiUrl + '/updateAffiliate', "session=" + session + "&affiliate=" + affiliate + "&company=" + company);
	}

	deleteAffiliate(session, company, affiliate) {
		return this.post(this.adminApiUrl + '/deleteAffiliate', "session=" + session + "&affiliate=" + affiliate + "&company=" + company);
	}

	saveService(session, company, service) {
		return this.post(this.adminApiUrl + '/saveService', "session=" + session + "&service=" + service + "&company=" + company);
	}


	deleteAccountsSet(session, company, accountSet) {
		return this.post(this.adminApiUrl + '/deleteAccountsSet', "session=" + session + "&accountSet=" + accountSet + "&company=" + company);
	}
	getMessageAlert(session, company, alert, alert_time) {
		return this.post(this.adminApiUrl + '/getMessageAlert', "session=" + session + "&alert=" + alert + "&company=" + company + "&alert_time=" + alert_time);
	}
	saveMessageAlert(session, company, alert, alert_time, comments) {
		return this.post(this.adminApiUrl + '/saveMessageAlert', "session=" + session + "&alert=" + alert + "&company=" + company + "&alert_time=" + alert_time + "&comments=" + comments);
	}

	getMonitorAlertDetails(session, company, alert) {
		return this.post(this.adminApiUrl + '/getMonitorAlertDetails', "session=" + session + "&alert=" + alert + "&company=" + company);
	}
	saveMonitorAlert(session, company, alert) {
		return this.post(this.adminApiUrl + '/saveMonitorAlert', "session=" + session + "&alert=" + alert + "&company=" + company);
	}


	getProviderDetails(session, company, provider) {
		return this.post(this.adminApiUrl + '/getProviderDetails', "session=" + session + "&provider=" + provider + "&company=" + company);
	}

	saveProvider(session, company, provider) {
		return this.post(this.adminApiUrl + '/saveProvider', "session=" + session + "&provider=" + encodeURIComponent(provider) + "&company=" + company);
	}
	deleteProvider(session, company, provider) {
		return this.post(this.adminApiUrl + '/deleteProvider', "session=" + session + "&provider=" + provider + "&company=" + company);
	}


	sendNotification(session, company, notification) {
		return this.post(this.adminApiUrl + '/sendNotification', "session=" + session + "&notification=" + encodeURIComponent(notification) + "&company=" + company);
	}



	saveUser(session, user) {
		return this.post(this.adminApiUrl + '/saveUser', "session=" + session + "&user=" + user);
	}

	getTournaments(session, company) {
		return this.post(this.adminApiUrl + '/getTournaments', "session=" + session + "&company=" + company);
	}

	getPromotions(session, company) {

		return this.post(this.adminApiUrl + '/getPromotions', "session=" + session + "&company=" + company);
	}

	getAltenarUserPromotionHistory(session, promotion, dateFrom) {
		return this.post(this.adminApiUrl + '/getAltenarUserPromotionHistory', "session=" + session + "&company=" + promotion.company + "&db=" + promotion.db + "&user=" + promotion.user + "&from=" + dateFrom);
	}

	saveUserPromotion(session, promotion) {
		return this.post(this.adminApiUrl + '/saveUserPromotion', "session=" + session + "&promotion=" + promotion);
	}
	promotionExists(session, company, promotion) {
		return this.post(this.adminApiUrl + '/promotionExists', "session=" + session + "&company=" + company + "&promotion=" + promotion);
	}

	tournamentExists(session, company, tournament) {
		return this.post(this.adminApiUrl + '/tournamentExists', "session=" + session + "&company=" + company + "&tournament=" + tournament);
	}
	getPromotionDetails(session, company, promotion) {
		return this.post(this.adminApiUrl + '/getPromotionDetails', "session=" + session + "&company=" + company + "&promotion=" + promotion);
	}

	getProviders(session, company) {
		return this.post(this.adminApiUrl + '/getProviders', "session=" + session + "&company=" + company);
	}

	getProviderMachines(session, company, provider) {
		return this.post(this.adminApiUrl + '/getProviderMachines', "session=" + session + "&company=" + company + "&provider=" + provider);
	}
	getMachines(session, company) {
		return this.post(this.adminApiUrl + '/getMachines', "session=" + session + "&company=" + company);
	}
	getNewPromotionDetails(session, company, promotion) {
		return this.post(this.adminApiUrl + '/getNewPromotionDetails', "session=" + session + "&company=" + company + "&promotion=" + promotion);
	}

	getPayoutFlowDetails(session, company, rule) {
		return this.post(this.adminApiUrl + '/getPayoutFlowDetails', "session=" + session + "&company=" + company + "&rule=" + rule);
	}

	getMethodDetails(session, company, method) {
		return this.post(this.adminApiUrl + '/getMethodDetails', "session=" + session + "&company=" + company + "&method=" + method);
	}
	savePromotion(session, promotion) {
		return this.post(this.adminApiUrl + '/savePromotion', "session=" + session + "&promotion=" + encodeURIComponent(promotion));
	}

	addMachineToPromotion(session, promotion) {
		return this.post(this.adminApiUrl + '/addMachineToPromotion', "session=" + session + "&promotion=" + encodeURIComponent(promotion));
	}

	deletePromotion(session, promotion, company) {
		return this.post(this.adminApiUrl + '/deletePromotion', "session=" + session + "&promotion=" + promotion + "&company=" + company);
	}
	saveCompanyMethod(session, method) {
		return this.post(this.adminApiUrl + '/saveCompanyMethod', "session=" + session + "&method=" + encodeURIComponent(method));
	}

	agentsUsersSearch(session, company, freeText) {
		var data = "session=" + session + "&freeText=" + freeText + "&company=" + company;


		return this.post(this.adminApiUrl + '/agentsUsersSearch', data);
	}


	agentsAgentsSearch(session, company, freeText) {
		var data = "session=" + session + "&freeText=" + freeText + "&company=" + company;


		return this.post(this.adminApiUrl + '/agentsAgentsSearch', data);
	}

	userSearch(session, company, freeText, alias, user, email, firstname, lastname, memberOf, notMemberOf, verified, registerFrom, registerTo, mobile, national_id, agent) {
		var data = "session=" + session + "&freeText=" + freeText + "&email=" + email + "&company=" + company + "&alias=" + alias + "&user=" + user + "&firstname=" + firstname + "&lastname=" + lastname + "&memberOf=" + memberOf + "&notMemberOf=" + notMemberOf
		if (verified !== undefined) {
			data += "&verified=" + verified;
		}
		data += "&register_from=" + registerFrom;
		data += "&register_to=" + registerTo;
		data += "&mobile=" + mobile;
		data += "&national_id=" + national_id;
		data += "&agent=" + agent;
		return this.post(this.adminApiUrl + '/search', data);
	}
	userSearchExcel(session, company, freeText, alias, user, email, firstname, lastname, memberOf, notMemberOf, verified, registerFrom, registerTo, mobile, national_id, agent) {
		var data = "session=" + session + "&freeText=" + freeText + "&email=" + email + "&company=" + company + "&alias=" + alias + "&user=" + user + "&firstname=" + firstname + "&lastname=" + lastname + "&memberOf=" + memberOf + "&notMemberOf=" + notMemberOf
		if (verified !== undefined) {
			data += "&verified=" + verified;
		}
		data += "&register_from=" + registerFrom;
		data += "&register_to=" + registerTo;
		data += "&mobile=" + mobile;
		data += "&national_id=" + national_id;
		data += "&agent=" + agent;
		data += "&excel=true";
		return this.adminApiUrl + '/search?' + data;
	}
	userSearchCSV(session, company, freeText, alias, user, email, firstname, lastname, memberOf, notMemberOf, verified, registerFrom, registerTo, mobile, national_id, agent) {
		var data = "session=" + session + "&freeText=" + freeText + "&email=" + email + "&company=" + company + "&alias=" + alias + "&user=" + user + "&firstname=" + firstname + "&lastname=" + lastname + "&memberOf=" + memberOf + "&notMemberOf=" + notMemberOf
		if (verified !== undefined) {
			data += "&verified=" + verified;
		}
		data += "&register_from=" + registerFrom;
		data += "&register_to=" + registerTo;
		data += "&mobile=" + mobile;
		data += "&national_id=" + national_id;
		data += "&agent=" + agent;
		data += "&csv=true";
		return this.adminApiUrl + '/search?' + data;
	}

	getUserFiles(session, company, db, user, status, type) {
		return this.post(this.adminApiUrl + '/getUserFiles', "session=" + session + "&company=" + company + "&db=" + db + "&type=" + type + "&status=" + status + "&user=" + user);
	}

	getUserFilesBank(session, company, db, user, payment_user_account) {
		return this.post(this.adminApiUrl + '/getUserFilesBank', "session=" + session + "&company=" + company + "&db=" + db + "&payment_user_account=" + payment_user_account + "&user=" + user)
	}
	reviewFileBank(session, company, db, user, fileId, to_status, reject_reason, reject_client_message) {
		return this.post(this.adminApiUrl + '/reviewFileBank', "session=" + session + "&company=" + company + "&db=" + db + "&fileId=" + fileId + "&to_status=" + to_status + "&user=" + user + "&reject_reason=" + reject_reason + "&reject_client_message=" + reject_client_message);
	}

	reviewUserFiles(session, company, db, user, from_status, to_status, type, sub_type, expiration_date, reject_reason, reject_client_message) {
		return this.post(this.adminApiUrl + '/reviewUserFiles', "session=" + session + "&company=" + company + "&db=" + db + "&type=" + type + "&from_status=" + from_status + "&to_status=" + to_status + "&user=" + user + "&reject_reason=" + reject_reason + "&reject_client_message=" + reject_client_message + "&expiration_date=	" + JSON.stringify(expiration_date) + "&subtype=" + sub_type);
	}
	verifyUser(session, company, db, user) {
		return this.post(this.adminApiUrl + '/verifyUser', "session=" + session + "&company=" + company + "&db=" + db + "&user=" + user);
	}

	getPayoutExtraPromotionsRedeemed(session, company, db, user, operation) {
		return this.post(this.adminApiUrl + '/getPayoutExtraPromotionsRedeemed', "session=" + session + "&company=" + company + "&db=" + db + "&operation=" + operation + "&user=" + user);
	}

	getPayoutExtraLowOdds(session, company, db, user, operation) {
		return this.post(this.adminApiUrl + '/getPayoutExtraLowOdds', "session=" + session + "&company=" + company + "&db=" + db + "&operation=" + operation + "&user=" + user);
	}

	updatePassword(session, current_password, new_password) {
		return this.post(this.adminApiUrl + '/updatePassword', "session=" + session + "&current_password=" + current_password + "&new_password=" + new_password);
	}

	updateLang(session, lang, numberFormat) {
		return this.post(this.adminApiUrl + '/updateLang', "session=" + session + "&lang=" + lang + "&numberFormat=" + numberFormat);
	}

	saveLockedNationalId(session, company, locked_national_id) {
		return this.post(this.adminApiUrl + '/saveLockedNationalId', "session=" + session + "&company=" + company + "&locked_national_id=" + locked_national_id);
	}

	deleteLockedNationalId(session, company, locked_national_id) {
		return this.post(this.adminApiUrl + '/deleteLockedNationalId', "session=" + session + "&company=" + company + "&locked_national_id=" + locked_national_id);
	}

	getLockedNationalId(session, company, locked_national_id) {
		return this.post(this.adminApiUrl + '/getLockedNationalId', "session=" + session + "&company=" + company + "&locked_national_id=" + locked_national_id);
	}

	enableOTP(session) {
		return this.post(this.adminApiUrl + '/enableOTP', "session=" + session);
	}
	userDetails(session, userId) {
		return this.post(this.adminApiUrl + '/userDetails', "session=" + session + "&user=" + userId);
	}
	userSessionDetails(session, company, userId, userSession) {
		return this.post(this.adminApiUrl + '/getSessionDetails', "session=" + session + "&user=" + userId + "&company=" + company + "&userSession=" + userSession);
	}
	userStatus(session, dbId, userId) {
		return this.post(this.adminApiUrl + '/userStatus', "session=" + session + "&user=" + userId + "&db=" + dbId);
	}

	depositMethods(session, company, db, userId) {
		return this.post(this.adminApiUrl + '/depositMethods', "session=" + session + "&user=" + userId + "&company=" + company + "&db=" + db);
	}

	payoutMethods(session, company, db, userId) {
		return this.post(this.adminApiUrl + '/payoutMethods', "session=" + session + "&user=" + userId + "&company=" + company + "&db=" + db);
	}
	getAvailablePayoutMethods(session, company) {
		return this.post(this.adminApiUrl + '/getAvailablePayoutMethods', "session=" + session + "&company=" + company);
	}

	getOperationDetails(session, db, operation) {
		return this.post(this.adminApiUrl + '/getOperationDetails', "session=" + session + "&db=" + db + "&operation=" + operation);
	}

	getBetDetails(session, db, operation) {
		return this.post(this.adminApiUrl + '/getBetDetails', "session=" + session + "&db=" + db + "&operation=" + operation);
	}

	getFileDetails(session, db, user, file) {
		return this.post(this.adminApiUrl + '/getFileDetails', "session=" + session + "&db=" + db + "&user=" + user + "&file=" + file);
	}
	saveFile(session, file, company) {
		return this.post(this.adminApiUrl + '/saveFile', "session=" + session + "&file=" + file + "&company=" + company);
	}
	newFile(session, file, company) {
		return this.post(this.adminApiUrl + '/newFile', "session=" + session + "&file=" + file + "&company=" + company);
	}
	getLobbyDetails(session, company, lobby) {
		return this.post(this.adminApiUrl + '/getLobbyDetails', "session=" + session + "&lobby=" + lobby + "&company=" + company);
	}
	getPromotionLobbyDetails(session, company, lobby) {
		return this.post(this.adminApiUrl + '/getPromotionLobbyDetails', "session=" + session + "&lobby=" + lobby + "&company=" + company);
	}

	getLanguageByKey(session, company, key) {
		return this.post(this.adminApiUrl + '/getLanguageByKey', "session=" + session + "&key=" + key + "&company=" + company);
	}

	getI18nCompaniesByKey(session, company, key) {
		return this.post(this.adminApiUrl + '/getI18nCompaniesByKey', "session=" + session + "&key=" + key + "&company=" + company);
	}

	getTournamentLobbyDetails(session, company, lobby) {
		return this.post(this.adminApiUrl + '/getTournamentLobbyDetails', "session=" + session + "&lobby=" + lobby + "&company=" + company);
	}
	getBannerContainerDetails(session, company, container) {
		return this.post(this.adminApiUrl + '/getBannerContainerDetails', "session=" + session + "&container=" + container + "&company=" + company);
	}

	getPopupsLobbyDetails(session, company, lobby) {
		return this.post(this.adminApiUrl + '/getPopupsLobbyDetails', "session=" + session + "&lobby=" + lobby + "&company=" + company);
	}

	getChatDetails(session, company, chat) {
		return this.post(this.adminApiUrl + '/getChatDetails', "session=" + session + "&chat=" + chat + "&company=" + company);
	}

	saveChatDetails(session, chat) {
		return this.post(this.adminApiUrl + '/saveChatDetails', "session=" + session + "&chat=" + chat);
	}
	savePromotionLobbyDetails(session, company, lobby, promotions) {
		return this.post(this.adminApiUrl + '/savePromotionLobbyDetails', "session=" + session + "&promotions=" + encodeURIComponent(promotions) + "&lobby=" + lobby + "&company=" + company);
	}

	saveTournamentLobbyDetails(session, company, lobby, tournaments) {
		return this.post(this.adminApiUrl + '/saveTournamentLobbyDetails', "session=" + session + "&tournaments=" + encodeURIComponent(tournaments) + "&lobby=" + lobby + "&company=" + company);
	}


	saveTicketMessage(session, ticket) {
		return this.post(this.adminApiUrl + '/saveTicketMessage', "session=" + session + "&ticket=" + ticket);
	}

	getTicketDetails(session, company, db, ticket) {
		return this.post(this.adminApiUrl + '/getTicketDetails', "session=" + session + "&db=" + db + "&ticket=" + ticket + "&company=" + company);
	}

	getUserVirtualGoodDetails(session, db, user, virtual_good) {
		return this.post(this.adminApiUrl + '/getUserVirtualGoodDetails', "session=" + session + "&db=" + db + "&virtual_good=" + virtual_good + "&user=" + user);
	}
	saveUserVirtualGoodDetails(session, virtual_good) {
		return this.post(this.adminApiUrl + '/saveUserVirtualGoodDetails', "session=" + session + "&virtual_good=" + virtual_good);
	}
	getNoteDetails(session, db, note) {
		return this.post(this.adminApiUrl + '/getNoteDetails', "session=" + session + "&db=" + db + "&note=" + note);
	}

	saveNote(session, note) {
		return this.post(this.adminApiUrl + '/saveNote', "session=" + session + "&note=" + note);
	}

	getUserPromotiontDetails(session, db, promotion) {
		return this.post(this.adminApiUrl + '/getUserPromotionDetails', "session=" + session + "&db=" + db + "&promotion=" + promotion);
	}

	getLimitDetails(session, db, user, company) {
		return this.post(this.adminApiUrl + '/getLimitDetails', "session=" + session + "&db=" + db + "&user=" + user + "&company=" + company);
	}


	saveManualUserGroups(session, db, company, user, groupsIds) {
		return this.post(this.adminApiUrl + '/saveManualUserGroups', "session=" + session + "&company=" + company + "&db=" + db + "&user=" + user + "&groups=" + groupsIds);
	}


	saveLimitsDetails(session, limits, db, user) {
		return this.post(this.adminApiUrl + '/saveLimitDetails', "session=" + session + "&db=" + db + "&user=" + user + "&limits=" + limits);
	}
	getPayoutFlowOptions(session, db, operation) {
		return this.post(this.adminApiUrl + '/getPayoutFlowOptions', "session=" + session + "&db=" + db + "&operation=" + operation);
	}

	updateOperation(session, operation) {
		return this.post(this.adminApiUrl + '/updateOperation', "session=" + session + "&operation=" + operation);
	}

	updateBet(session, company, bet) {
		return this.post(this.adminApiUrl + '/updateBet', "session=" + session + "&company=" + company + "&bet=" + bet);
	}

	getUserDataUsers(session, company) {
		return this.post(this.adminApiUrl + '/getUserDataUsers', "session=" + session + "&company=" + company);
	}

	getUserGameSession(session, company, user, provider) {
		return this.post(this.adminApiUrl + '/getUserGameSession', "session=" + session + "&company=" + company + "&user=" + user + "&provider=" + provider);
	}

	getFile(session, file) {
		return this.adminApiUrl + '/getFile?session=' + session + '&db=' + file.db + '&file=' + file.file + '&user=' + file.user;
	}

	saveTournament(session, tournament) {
		return this.post(this.adminApiUrl + '/saveTournament', "session=" + session + "&tournament=" + encodeURIComponent(tournament));
	}

	duplicateTournament(session, company, tournament, new_tournament, new_name) {
		return this.post(this.adminApiUrl + '/duplicateTournament', "session=" + session + "&company=" + company + "&tournament=" + tournament + "&new_tournament=" + new_tournament + "&new_name=" + new_name);
	}


	duplicatePromotion(session, company, promotion, new_promotion, new_name) {
		return this.post(this.adminApiUrl + '/duplicatePromotion', "session=" + session + "&company=" + company + "&promotion=" + promotion + "&new_promotion=" + new_promotion + "&new_name=" + new_name);
	}

	getTournamentType(session, company, tournament) {
		return this.post(this.adminApiUrl + '/getTournamentType', "session=" + session + "&company=" + company);
	}

	getTournamentDetails(session, company, tournament) {
		return this.post(this.adminApiUrl + '/getTournamentDetails', "session=" + session + "&company=" + company + "&tournament=" + encodeURIComponent(tournament));
	}
	getNewTournamentDetails(session, company, tournament) {
		return this.post(this.adminApiUrl + '/getNewTournamentDetails', "session=" + session + "&company=" + company + "&tournament=" + tournament);
	}
	getTournamentPrizeDetails(session, company, tournament, from_position) {
		return this.post(this.adminApiUrl + '/getTournamentPrizeDetails', "session=" + session + "&company=" + company + "&tournament=" + tournament + "&from_position=" + from_position);
	}

	saveTournamentPrize(session, prize) {
		return this.post(this.adminApiUrl + '/saveTournamentPrize', "session=" + session + "&prize=" + prize);
	}

	deleteTournamentPrize(session, prize) {
		return this.post(this.adminApiUrl + '/deleteTournamentPrize', "session=" + session + "&prize=" + prize);
	}
	getMachineDetails(session, company, machine) {
		return this.post(this.adminApiUrl + '/getMachineDetails', "session=" + session + "&company=" + company + "&machine=" + machine);
	}

	saveMachine(session, company, machine, tags, granted_groups, forbidden_groups, play_granted_groups, play_forbidden_groups) {
		return this.post(this.adminApiUrl + '/saveMachine', "session=" + session + "&company=" + company + "&machine=" + machine + "&tags=" + encodeURIComponent(tags) + "&granted_groups=" + granted_groups + "&forbidden_groups=" + forbidden_groups + "&play_granted_groups=" + play_granted_groups + "&play_forbidden_groups=" + play_forbidden_groups);
	}

	getWebhookDetails(session, company, webhook) {
		return this.post(this.adminApiUrl + '/getWebhookDetails', "session=" + session + "&company=" + company + "&webhook=" + webhook);
	}

	saveWebhook(session, company, webhook) {
		return this.post(this.adminApiUrl + '/saveWebhook', "session=" + session + "&company=" + company + "&webhook=" + webhook);
	}
	deleteWebhook(session, company, webhook) {
		return this.post(this.adminApiUrl + '/deleteWebhook', "session=" + session + "&company=" + company + "&webhook=" + webhook);
	}

	getGroups(session, company) {
		return this.post(this.adminApiUrl + '/getGroups', "session=" + session + "&company=" + company);
	}

	getGroupsByRange(session, company, init, end) {
		return this.post(this.adminApiUrl + '/getGroupsByRange', "session=" + session + "&company=" + company + "&init=" + init + "&end=" + end);
	}

	getGroupDetails(session, company, group) {
		return this.post(this.adminApiUrl + '/getGroupDetails', "session=" + session + "&company=" + company + "&group=" + group);
	}
	getGlobalGroupDetails(session, company, group, type, relationship) {
		return this.post(this.adminApiUrl + '/getGlobalGroupDetails', "session=" + session + "&company=" + company + "&group=" + group + "&type=" + type + "&relationship=" + relationship);
	}

	getVirtualGoods(session, company) {
		return this.post(this.adminApiUrl + '/getVirtualGoods', "session=" + session + "&company=" + company);
	}

	BatchDepositUpdate(session, company, operations, comments, next_status, callBackFunction) {

		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('operations', operations)
		formData.append('comments', comments)
		formData.append('next_status', next_status)

		return this.batchPost(this.adminApiUrl + "/BatchDepositUpdate", formData, callBackFunction)


	}


	batchUpdatePayouts(session, company, operations, to_status, rejectReason, rejectClientMessage, callBackFunction) {

		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('operations', JSON.stringify(operations))
		formData.append('to_status', to_status)
		formData.append('reject_reason', rejectReason)
		formData.append('reject_client_message', rejectClientMessage)

		return this.batchPost(this.adminApiUrl + "/batchUpdatePayouts", formData, callBackFunction)

	}


	negativeBalanceReset(session, company, players, comment, callBackFunction, file, sendEmail, subject, body) {
		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('players', JSON.stringify(players))
		formData.append('comment', comment)
		formData.append('sendEmail', sendEmail)
		formData.append('subject', subject)
		formData.append('body', body)
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + "/negativeBalanceReset", formData, callBackFunction)
	}

	manualAdjustmentBatch(session, company, players, account, comment, callBackFunction, file, sendEmail, subject, body) {
		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('players', JSON.stringify(players))
		formData.append('account', account)
		formData.append('comment', comment)
		formData.append('sendEmail', sendEmail)
		formData.append('subject', subject)
		formData.append('body', body)
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + "/manualAdjustmentBatch", formData, callBackFunction)
	}


	usersUpdatetBatch(session, company, players, verified, status, can_deposit, can_payout, can_play, can_promotions, agent, note, note_title, department, callBackFunction, file) {

		var formData = new FormData();
		formData.append("session", session);
		formData.append("company", company);
		formData.append("players", JSON.stringify(players));
		formData.append("verified", verified);
		formData.append("status", status);
		formData.append("can_deposit", can_deposit);
		formData.append("can_payout", can_payout);
		formData.append("can_play", can_play);
		formData.append("can_promotions", can_promotions);
		formData.append("agent", agent);
		formData.append("note", note);
		formData.append("note_title", note_title);
		formData.append("department", department);
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + '/usersUpdatetBatch', formData, callBackFunction)

	}

	manualDepositBatch(session, company, players, method, comment, idempotence, callBackFunction, file) {
		var formData = new FormData();
		formData.append("session", session);
		formData.append("company", company);
		formData.append("players", players);
		formData.append("method", method);
		formData.append("comment", comment);
		formData.append("idempotence", idempotence);
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + '/manualDepositBatch', formData, callBackFunction)
	}


	saveGroup(session, company, group, triggers) {
		return this.post2(this.adminApiUrl + '/saveGroup',
			{
				session: session,
				company: company,
				group: group,
				triggers: triggers
			});
	}
	deleteGroup(session, company, group) {
		return this.post(this.adminApiUrl + '/deleteGroup', "session=" + session + "&company=" + company + "&group=" + group);
	}
	saveGlobalGroup(session, company, group, triggers) {
		return this.post2(this.adminApiUrl + '/saveGlobalGroup',
			{
				session: session,
				company: company,
				group: group
			});
	}
	deleteGlobalGroup(session, company, group) {
		return this.post(this.adminApiUrl + '/deleteGlobalGroup', "session=" + session + "&company=" + company + "&group=" + group);
	}
	unlockAdmin(session, company, user) {
		return this.post(this.adminApiUrl + '/unlockAdmin', "session=" + session + "&company=" + company + "&user=" + user);
	}

	getAdminUserDetails(session, company, user) {
		return this.post(this.adminApiUrl + '/getAdminUserDetails', "session=" + session + "&company=" + company + "&user=" + user);
	}
	getAdminUserHierarchy(session, user, company) {
		return this.post(this.adminApiUrl + '/getAdminUserHierarchy', "session=" + session + "&company=" + company + "&user=" + user);
	}

	sendTokens(session, user, company, amount, currency, description) {
		return this.post(this.adminApiUrl + '/sendTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&description=" + encodeURIComponent(description));
	}
	retrieveTokens(session, user, company, amount, currency, description) {
		return this.post(this.adminApiUrl + '/retrieveTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&description=" + encodeURIComponent(description));
	}

	getAdminUsers(session, company) {
		return this.post(this.adminApiUrl + '/getAdminUsers', "session=" + session + "&company=" + company);
	}

	getAdminUserAccounts(session, user, company) {
		return this.post(this.adminApiUrl + '/getAdminUserAccounts', "session=" + session + "&company=" + company + "&user=" + user);
	}
	updateTokens(session, user, company, amount, currency, description) {
		return this.post(this.adminApiUrl + '/updateTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&description=" + description);
	}
	purchaseTokens(session, user, company, amount, currency, comission, cash_amount, cash_currency) {
		return this.post(this.adminApiUrl + '/purchaseTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&comission=" + comission + "&cash_amount=" + cash_amount + "&cash_currency=" + cash_currency);
	}

	sellTokens(session, user, company, amount, currency, comission, cash_amount, cash_currency) {
		return this.post(this.adminApiUrl + '/sellTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&comission=" + comission + "&cash_amount=" + cash_amount + "&cash_currency=" + cash_currency);
	}

	lendTokens(session, user, company, amount, currency, comission, cash_amount, cash_currency) {
		return this.post(this.adminApiUrl + '/lendTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&comission=" + comission + "&cash_amount=" + cash_amount + "&cash_currency=" + cash_currency);
	}
	RepayLoanTokens(session, user, company, amount, comission, currency) {
		return this.post(this.adminApiUrl + '/repayLoanTokens', "session=" + session + "&company=" + company + "&user=" + user + "&amount=" + amount + "&currency=" + currency + "&comission=" + comission);
	}

	saveNewAgent(session, company, alias, password, name, email, currency, status, role) {
		let data = "session=" + session + "&company=" + company + "&alias=" + alias + "&password=" + password + "&name=" + name + "&email=" + email + "&currency=" + currency + "&status=" + status + "&role=" + role;
		return this.post(this.adminApiUrl + '/saveNewAgent', data);
	}

	saveAdminUser(session, company, user, password) {
		return this.post(this.adminApiUrl + '/saveAdminUser', "session=" + session + "&company=" + company + "&user=" + user + "&password=" + password);
	}
	deleteAdminUser(session, company, user) {
		return this.post(this.adminApiUrl + '/deleteAdminUser', "session=" + session + "&company=" + company + "&user=" + user);
	}

	getUserPaymentAccountDetails(session, company, db, user, account) {
		return this.post(this.adminApiUrl + '/getUserPaymentAccountDetails', "session=" + session + "&db=" + db + "&user=" + user + "&account=" + account + "&company=" + company);
	}

	saveUserPaymentAccountDetails(session, company, db, user, account) {
		return this.post(this.adminApiUrl + '/saveUserPaymentAccountDetails', "session=" + session + "&db=" + db + "&user=" + user + "&account=" + account + "&company=" + company);
	}

	saveVirtualGoodDetails(session, virtual_good) {
		return this.post(this.adminApiUrl + '/saveVirtualGoodDetails', "session=" + session + "&virtual_good=" + virtual_good);
	}

	getVirtualGoodDetails(session, company, virtual_good) {
		return this.post(this.adminApiUrl + '/getVirtualGoodDetails', "session=" + session + "&company=" + company + "&virtual_good=" + virtual_good);
	}

	getRoleDetails(session, company, role) {
		return this.post(this.adminApiUrl + '/getRoleDetails', "session=" + session + "&company=" + company + "&role=" + role);
	}

	getTagDetails(session, company, tag) {
		return this.post(this.adminApiUrl + '/getTagDetails', "session=" + session + "&company=" + company + "&tag=" + tag);
	}


	saveTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/saveTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}
	deleteTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/deleteTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}

	getPromotionsTagDetails(session, company, tag) {
		return this.post(this.adminApiUrl + '/getPromotionsTagDetails', "session=" + session + "&company=" + company + "&tag=" + tag);
	}
	savePromotionTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/savePromotionTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}
	deletePromotionTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/deletePromotionTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}

	saveRole(session, company, role) {
		return this.post(this.adminApiUrl + '/saveRole', "session=" + session + "&company=" + company + "&role=" + role);
	}
	deleteRole(session, company, role) {
		return this.post(this.adminApiUrl + '/deleteRole', "session=" + session + "&company=" + company + "&role=" + role);
	}

	saveDepartmentsDetails(session, company, department) {
		return this.post(this.adminApiUrl + '/saveDepartment', "session=" + session + "&company=" + company + "&department=" + department);
	}
	deleteDepartmentsDetails(session, company, id) {
		return this.post(this.adminApiUrl + '/deleteDepartment', "session=" + session + "&company=" + company + "&id=" + id);
	}
	deleteUserAddressesDetails(session, company, user, user_id, db) {
		return this.post(this.adminApiUrl + '/deleteUserAddressesDetails', "session=" + session + "&company=" + company + "&user=" + user + "&user_id=" + user_id + "&db=" + db);
	}
	saveUserAddressesDetails(session, company, address) {
		return this.post(this.adminApiUrl + '/saveUserAddressesDetails', "session=" + session + "&company=" + company + "&address=" + address);
	}
	saveEmailContent(session, company, emailContent) {
		return this.post(this.adminApiUrl + '/saveEmailContent', "session=" + session + "&company=" + company + "&emailContent=" + encodeURIComponent(emailContent));
	}
	deleteEmailContent(session, company, website, event) {
		return this.post(this.adminApiUrl + '/deleteEmailContent', "session=" + session + "&company=" + company + "&website=" + website + "&event=" + event);
	}
	saveI18N(session, company, key, data) {
		return this.post(this.adminApiUrl + '/saveI18N', "session=" + session + "&company=" + company + "&key=" + key + "&data=" + data);
	}
	deleteI18NCompanies(session, company, key) {
		return this.post(this.adminApiUrl + '/deleteI18NCompanies', "session=" + session + "&company=" + company + "&key=" + key);
	}

	saveI18NCompanies(session, company, key, data) {
		return this.post(this.adminApiUrl + '/saveI18NCompanies', "session=" + session + "&company=" + company + "&key=" + key + "&data=" + data);
	}
	deleteI18N(session, company, key) {
		return this.post(this.adminApiUrl + '/deleteI18N', "session=" + session + "&company=" + company + "&key=" + key);
	}
	getPromotionChoiceDetails(session, company, choice) {
		return this.post(this.adminApiUrl + '/getPromotionChoiceDetails', "session=" + session + "&company=" + company + "&choice=" + choice);
	}

	deleteExchangesDetails(session, company, exchange) {
		return this.post(this.adminApiUrl + '/deleteExchange', "session=" + session + "&company=" + company + "&exchange=" + exchange);
	}

	saveExchangesDetails(session, company, exchange) {
		return this.post(this.adminApiUrl + '/saveExchange', "session=" + session + "&company=" + company + "&exchange=" + exchange);
	}
	getExchangeDetails(session, company, exchange) {
		return this.post(this.adminApiUrl + '/getExchange', "session=" + session + "&company=" + company + "&exchange=" + exchange);
	}
	getPromotionCalculators(session, company) {
		return this.post(this.adminApiUrl + '/getPromotionCalculators', "session=" + session + "&company=" + company);
	}
	getPromotionChoices(session, company) {
		return this.post(this.adminApiUrl + '/getPromotionChoices', "session=" + session + "&company=" + company);
	}

	savePromotionChoiceDetails(session, company, choice) {
		return this.post(this.adminApiUrl + '/savePromotionChoiceDetails', "session=" + session + "&company=" + company + "&choice=" + choice);
	}

	getBasicInfo(session) {
		return this.post(this.adminApiUrl + '/getBasicInfo', "session=" + session);
	}

	getEmailTemplateDetails(session, company, template) {
		return this.post(this.adminApiUrl + '/getEmailTemplateDetails', "session=" + session + "&company=" + company + "&template=" + encodeURIComponent(template));
	}
	saveEmailTemplateDetails(session, company, template) {
		return this.post(this.adminApiUrl + '/saveEmailTemplateDetails', "session=" + session + "&template=" + encodeURIComponent(template) + "&company=" + company);
	}

	getFragmentDetails(session, company, fragment) {
		return this.post(this.adminApiUrl + '/getFragmentDetails', "session=" + session + "&company=" + company + "&fragment=" + fragment);
	}


	getBannerDetails(session, company, banner) {
		return this.post(this.adminApiUrl + '/getBannerDetails', "session=" + session + "&company=" + company + "&banner=" + banner);
	}
	saveBannerDetails(session, company, banner) {
		return this.post(this.adminApiUrl + '/saveBannerDetails', "session=" + session + "&banner=" + encodeURIComponent(banner) + "&company=" + company);
	}

	getPopupDetails(session, company, popup) {
		return this.post(this.adminApiUrl + '/getPopupDetails', "session=" + session + "&company=" + company + "&popup=" + popup);
	}

	savePopupDetails(session, company, popup) {
		return this.post(this.adminApiUrl + '/savePopupDetails', "session=" + session + "&popup=" + encodeURIComponent(popup) + "&company=" + company);
	}

	uploadFile(session, type, company, owner, file, name) {
		var formData = new FormData();
		formData.append("session", session);
		formData.append("type", type);
		formData.append("owner", owner);
		formData.append("company", company);

		formData.append("myFile", file, name);

		return this.post(this.adminApiUrl + '/uploadFile', formData)
	}

	getQR(session) {
		return this.post(this.adminApiUrl + '/getQR', "session=" + session);
	}

	getStates(session, country) {
		return this.post(this.adminApiUrl + '/getStates', "session=" + session + "&country=" + country);
	}
	getProvinces(session, country, state) {
		return this.post(this.adminApiUrl + '/getProvinces', "session=" + session + "&country=" + country + "&state=" + state);
	}
	getCities(session, country, state, province) {
		return this.post(this.adminApiUrl + '/getCities', "session=" + session + "&country=" + country + "&state=" + state + "&province=" + province);
	}
	getCountries(session) {
		return this.post(this.adminApiUrl + '/getCountries', "session=" + session);
	}
	getDataBasicInfo(session, company, basicInfoName, params, saveServer) {
		return this.post(this.adminApiUrl + '/getDataBasicInfo', "session=" + session + "&company=" + company + "&basicInfoName=" + basicInfoName + "&params=" + params + "&saveServer" + saveServer);
	}

	getCategoryJackpotDetails(session, company, category) {
		return this.post(this.adminApiUrl + '/getCategoryJackpotDetails', "session=" + session + "&company=" + company + "&category=" + category);
	}

	addJackpotCategory(session, company, category) {
		return this.post(this.adminApiUrl + '/addCategoryJackpotDetails', "session=" + session + "&company=" + company + "&category=" + category);
	}
	updateJackpotCategory(session, company, category) {
		return this.post(this.adminApiUrl + '/updateCategoryJackpotDetails', "session=" + session + "&company=" + company + "&category=" + category);
	}
	deleteJackpotCategory(session, company, category) {
		return this.post(this.adminApiUrl + '/deleteCategoryJackpotDetails', "session=" + session + "&company=" + company + "&category=" + category);
	}

	getJackpotDetails(session, company, jackpot) {
		return this.post(this.adminApiUrl + '/getJackpotDetails', "session=" + session + "&company=" + company + "&jackpot=" + jackpot);
	}
	getJackpotCurrencies(session, company) {
		return this.post(this.adminApiUrl + '/getJackpotCurrencies', "session=" + session + "&company=" + company);
	}

	addJackpot(session, company, jackpot) {
		return this.post(this.adminApiUrl + '/addJackpot', "session=" + session + "&company=" + company + "&jackpot=" + jackpot);
	}
	updateJackpot(session, company, jackpot) {
		return this.post(this.adminApiUrl + '/updateJackpot', "session=" + session + "&company=" + company + "&jackpot=" + jackpot);
	}
	deleteJackpot(session, company, jackpot) {
		return this.post(this.adminApiUrl + '/deleteJackpot', "session=" + session + "&company=" + company + "&jackpot=" + jackpot);
	}
	deleteFile(session, file, company) {
		return this.post(this.adminApiUrl + '/deleteFile', "session=" + session + "&file=" + file + "&company=" + company);
	}


	getFlowDetails(session, company, flow) {
		return this.post(this.adminApiUrl + '/getFlowDetails', "session=" + session + "&company=" + company + "&flow=" + flow);
	}

	updateFlow(session, company, flow) {
		return this.post(this.adminApiUrl + '/updateFlow', "session=" + session + "&company=" + company + "&flow=" + flow);
	}

	addFlow(session, company, flow) {
		return this.post(this.adminApiUrl + '/addFlow', "session=" + session + "&company=" + company + "&flow=" + flow);
	}

	deleteFlow(session, company, flow) {
		return this.post(this.adminApiUrl + '/deleteFlow', "session=" + session + "&company=" + company + "&flow=" + flow);
	}


	getMissionDetails(session, company, mission) {
		return this.post(this.adminApiUrl + '/getMissionDetails', "session=" + session + "&company=" + company + "&mission=" + mission);
	}

	deleteMissionDetails(session, company, mission) {
		return this.post(this.adminApiUrl + '/deleteMissionDetails', "session=" + session + "&company=" + company + "&mission=" + mission);
	}

	addMissionDetails(session, company, mission) {
		return this.post(this.adminApiUrl + '/addMissionDetails', "session=" + session + "&company=" + company + "&mission=" + encodeURIComponent(mission));
	}

	updateMissionDetails(session, company, mission) {
		return this.post(this.adminApiUrl + '/updateMissionDetails', "session=" + session + "&company=" + company + "&mission=" + encodeURIComponent(mission));
	}

	getLockedIpsDetails(session, company, locked_ip) {
		return this.post(this.adminApiUrl + '/getLockedIpsDetails', "session=" + session + "&company=" + company + "&locked_ip=" + locked_ip);
	}
	deleteLockedIpsDetails(session, company, locked_ip) {
		return this.post(this.adminApiUrl + '/deleteLockedIpsDetails', "session=" + session + "&company=" + company + "&locked_ip=" + locked_ip);
	}

	saveAllowedPlayer(session, company, user) {
		return this.post(this.adminApiUrl + '/saveAllowedPlayer', "session=" + session + "&company=" + company + "&user=" + user);
	}
	createAdminUserInCompany(session, company, userAdmin) {
		return this.post(this.adminApiUrl + '/createAdminUserInCompany', "session=" + session + "&company=" + company + "&userAdmin=" + userAdmin);
	}

	getAllowedCountries(session, company) {
		return this.post(this.adminApiUrl + '/getAllowedCountries', "session=" + session + "&company=" + company);
	}
	updateAllowedCountries(session, company, country) {
		return this.post(this.adminApiUrl + '/updateAllowedCountries', "session=" + session + "&company=" + company + "&country=" + country);
	}
	deleteAllowedCountries(session, company, country) {
		return this.post(this.adminApiUrl + '/deleteAllowedCountries', "session=" + session + "&company=" + company + "&country=" + country);
	}


	addAllowedIpDetails(session, company, ipDetail) {
		return this.post(this.adminApiUrl + '/addAllowedIpDetails', "session=" + session + "&company=" + company + "&ipDetail=" + ipDetail);
	}

	updateAllowedIpDetails(session, company, ipDetail) {
		return this.post(this.adminApiUrl + '/updateAllowedIpDetails', "session=" + session + "&company=" + company + "&ipDetail=" + ipDetail);
	}
	deleteAllowedIpDetails(session, company, ipDetail) {
		return this.post(this.adminApiUrl + '/deleteAllowedIpDetails', "session=" + session + "&company=" + company + "&ipDetail=" + ipDetail);
	}

	getPromotionsMachine(session, company, provider) {
		return this.post(this.adminApiUrl + '/getPromotionsMachine', "session=" + session + "&company=" + company + "&provider=" + provider);
	}

	deleteLobbyDetails(session, company, lobby) {
		return this.post(this.adminApiUrl + '/deleteLobbyDetails', "session=" + session + "&company=" + company + "&lobby=" + lobby);
	}

	CALIA_evaluateGroup(session, company, evaluate, sql) {
		return this.post(this.adminApiUrl + '/CALIA_evaluateGroup', "session=" + session + "&company=" + company + "&evaluate=" + evaluate + "&sql=" + encodeURIComponent(sql));
	}

	CALIA_requestGroup2(session, company, request) {
		return this.post(this.adminApiUrl + '/CALIA_requestGroup', "session=" + session + "&company=" + company + "&request=" + request);
	}

	CALIA_requestGroup(session, company, request) {
		return this.postJSON2(this.caliaUrl + '/endpoint/chat', request);
	}

	CALIA_requestExploreData(session, company, prompt) {
		return this.post(this.adminApiUrl + '/CALIA_requestExploreData', "session=" + session + "&company=" + company + "&prompt=" + prompt);
	}

	CALIA_saveEvaluationGroup(session, company, reference) {
		return this.postJSON2(this.caliaUrl + '/endpoint/validate_group', reference);
	}

	saveFlowPayoutStatus(session, company, details) {
		return this.post(this.adminApiUrl + '/saveFlowPayoutStatus', "session=" + session + "&company=" + company + "&details=" + details);
	}

	sendEmailToUser(session, company, player, subject, body) {
		return this.post(this.adminApiUrl + '/sendEmailToUser', "session=" + session + "&company=" + company + "&player=" + player + "&subject=" + subject + "&body=" + body);

	}

	disableOTP(session, company, user) {
		return this.post(this.adminApiUrl + '/disableOTP', "session=" + session + "&company=" + company + "&user=" + user);

	}

	newTempUserSession(session, company, db, user, provider) {
		return this.post(this.adminApiUrl + '/newTempUserSession', "session=" + session + "&company=" + company + "&db=" + db + "&user=" + user + "&provider=" + provider);

	}

	getTermsConditionsDetails(session, company, term) {
		return this.post(this.adminApiUrl + '/getTermsConditionsDetails', "session=" + session + "&company=" + company + "&term=" + term);
	}

	addTermsConditionsDetails(session, company, term) {
		return this.post(this.adminApiUrl + '/addTermsConditionsDetails', "session=" + session + "&company=" + company + "&term=" + encodeURIComponent(term));
	}

	updateTermsConditionsDetails(session, company, term) {
		return this.post(this.adminApiUrl + '/updateTermsConditionsDetails', "session=" + session + "&company=" + company + "&term=" + encodeURIComponent(term));
	}

	addTermsConditionsVersionDetails(session, company, term, version) {
		return this.post(this.adminApiUrl + '/addTermsConditionsVersionDetails', "session=" + session + "&company=" + company + "&term=" + term + "&version=" + encodeURIComponent(version));
	}

	updateTermsConditionsVersionDetails(session, company, term, version) {
		return this.post(this.adminApiUrl + '/updateTermsConditionsVersionDetails', "session=" + session + "&company=" + company + "&term=" + term + "&version=" + encodeURIComponent(version));
	}
	deleteTermsConditionsVersionDetails(session, company, term, version) {
		return this.post(this.adminApiUrl + '/deleteTermsConditionsVersionDetails', "session=" + session + "&company=" + company + "&term=" + term + "&version=" + version);
	}

	getTermsConditionsVersionAccepted(session, company, term, version) {
		return this.post(this.adminApiUrl + '/getTermsConditionsVersionAccepted', "session=" + session + "&company=" + company + "&term=" + term + "&version=" + version);
	}

	saveMimetypesDetails(session, company, mimetype) {
		return this.post(this.adminApiUrl + '/saveMimetypesDetails', "session=" + session + "&company=" + company + "&mimetype=" + mimetype);
	}

	deleteMimetypesDetails(session, company, mimetype) {
		return this.post(this.adminApiUrl + '/deleteMimetypesDetails', "session=" + session + "&company=" + company + "&mimetype=" + mimetype);
	}

	addExternalListDetails(session, company, list) {
		return this.post(this.adminApiUrl + '/addExternalListDetails', "session=" + session + "&company=" + company + "&list=" + list);
	}
	updateExternalListDetails(session, company, list) {
		return this.post(this.adminApiUrl + '/updateExternalListDetails', "session=" + session + "&company=" + company + "&list=" + list);
	}

	addTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/addTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}

	updateTag(session, company, tag) {
		return this.post(this.adminApiUrl + '/updateTag', "session=" + session + "&company=" + company + "&tag=" + tag);
	}

	cancelPromotion(session, company, promotion, players, file, callBackFunction) {
		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('promotion', promotion)
		formData.append('players', players)
		if (file !== undefined) formData.append("myFile", file, file.name);
		return this.batchPost(this.adminApiUrl + '/cancelPromotion', formData, callBackFunction);
	}

	addIncidentDetails(session, company, incident) {
		return this.post(this.adminApiUrl + '/addIncidentDetails', "session=" + session + "&company=" + company + "&incident=" + encodeURIComponent(incident));
	}

	updateIncidentDetails(session, company, incident) {
		return this.post(this.adminApiUrl + '/updateIncidentDetails', "session=" + session + "&company=" + company + "&incident=" + encodeURIComponent(incident));
	}
	deleteIncidentDetails(session, company, incident) {
		return this.post(this.adminApiUrl + '/deleteIncidentDetails', "session=" + session + "&company=" + company + "&incident=" + incident);
	}

	getIncidentDetails(session, company, incident) {
		return this.post(this.adminApiUrl + '/getIncidentDetails', "session=" + session + "&company=" + company + "&incident=" + incident);
	}

	getCompanyConfig(session, company) {
		return this.post(this.adminApiUrl + '/getCompanyConfig', "session=" + session + "&company=" + company);
	}
	updateCompanyConfig(session, company, companyDetails) {
		return this.post(this.adminApiUrl + '/updateCompanyConfig', "session=" + session + "&company=" + company + "&companyDetails=" + encodeURIComponent(companyDetails));
	}
	isParentFromUser(session, company, user) {
		return this.post(this.adminApiUrl + '/isParentFromUser', "session=" + session + "&company=" + company + "&userAdmin=" + user);
	}
	updateAgentUser(session, company, user) {
		return this.post(this.adminApiUrl + '/updateAgentUser', "session=" + session + "&company=" + company + "&user=" + user);
	}
	getAgentUserCommissions(session, company, user) {
		return this.post(this.adminApiUrl + '/getAgentUserCommissions', "session=" + session + "&company=" + company + "&user=" + user);
	}
	saveAgentUserCommissions(session, company, user, commissions) {
		return this.post(this.adminApiUrl + '/saveAgentUserCommissions', "session=" + session + "&company=" + company + "&user=" + user + "&commissions=" + commissions);
	}
	getBalanceUser(session, company) {
		return this.post(this.adminApiUrl + '/getBalanceUser', "session=" + session + "&company=" + company);
	}

	getProvidersErrorCodeI18n(session, company, provider, error_code) {
		return this.post(this.adminApiUrl + '/getProvidersErrorCodeI18n', "session=" + session + "&company=" + company + "&provider=" + provider + "&error_code=" + error_code);
	}
	addProvidersErrorCode(session, company, provider, codeDetails) {
		return this.post(this.adminApiUrl + '/addProvidersErrorCode', "session=" + session + "&company=" + company + "&provider=" + provider + "&codeDetails=" + codeDetails);
	}
	updateProvidersErrorCode(session, company, provider, codeDetails) {
		return this.post(this.adminApiUrl + '/updateProvidersErrorCode', "session=" + session + "&company=" + company + "&provider=" + provider + "&codeDetails=" + codeDetails);
	}
	deleteProvidersErrorCode(session, company, provider, error_code) {
		return this.post(this.adminApiUrl + '/deleteProvidersErrorCode', "session=" + session + "&company=" + company + "&provider=" + provider + "&error_code=" + error_code);
	}

	getOneGlobalParam(session, company, name) {
		return this.post(this.adminApiUrl + '/getOneGlobalParam', "session=" + session + "&company=" + company + "&name=" + name);

	}
	updateAgentPassword(session, company, user, password) {
		return this.post(this.adminApiUrl + '/updateAgentPassword', "session=" + session + "&company=" + company + "&user=" + user + "&password=" + password);
	}
	updateAgentStatus(session, company, user, status, allAgents) {
		return this.post(this.adminApiUrl + '/updateAgentStatus', "session=" + session + "&company=" + company + "&user=" + user + "&status=" + status + "&allAgents=" + allAgents);
	}
	getCurrentUserBalance(session, company, db, user, balance) {
		return this.post(this.adminApiUrl + '/getCurrentUserBalance', "session=" + session + "&company=" + company + "&user=" + user + "&db=" + db + "&balance=" + balance);
	}
	getMessagesList(session, company, type) {
		return this.post(this.adminApiUrl + '/getMessageList', "session=" + session + "&company=" + company + "&type=" + type);
	}

	getPaymentMethodsErrorCodeI18n(session, company, method, error_code) {
		return this.post(this.adminApiUrl + '/getPaymentMethodsErrorCodeI18n', "session=" + session + "&company=" + company + "&method=" + method + "&error_code=" + error_code);
	}
	addPaymentMethodsErrorCode(session, company, method, codeDetails) {
		return this.post(this.adminApiUrl + '/addPaymentMethodsErrorCode', "session=" + session + "&company=" + company + "&method=" + method + "&codeDetails=" + codeDetails);
	}
	updatePaymentMethodsErrorCode(session, company, method, codeDetails) {
		return this.post(this.adminApiUrl + '/updatePaymentMethodsErrorCode', "session=" + session + "&company=" + company + "&method=" + method + "&codeDetails=" + codeDetails);
	}
	deletePaymentMethodsErrorCode(session, company, method, error_code) {
		return this.post(this.adminApiUrl + '/deletePaymentMethodsErrorCode', "session=" + session + "&company=" + company + "&method=" + method + "&error_code=" + error_code);
	}
	saveUserConsents(session, company, user, consents) {
		return this.post(this.adminApiUrl + '/saveUserConsents', "session=" + session + "&company=" + company + "&user=" + user + "&consents=" + consents);
	}

	addMessagesDetails(session, company, messageDetails) {
		return this.post(this.adminApiUrl + '/addMessagesDetails', "session=" + session + "&company=" + company + "&messageDetails=" + messageDetails);
	}
	updateMessagesDetails(session, company, messageDetails) {
		return this.post(this.adminApiUrl + '/updateMessagesDetails', "session=" + session + "&company=" + company + "&messageDetails=" + messageDetails);
	}
	deleteMessagesDetails(session, company, messageDetails) {
		return this.post(this.adminApiUrl + '/deleteMessagesDetails', "session=" + session + "&company=" + company + "&messageDetails=" + messageDetails);
	}

	unlockEmailTokenBlocked(session, company, emailToken) {
		return this.post(this.adminApiUrl + '/unlockEmailTokenBlocked', "session=" + session + "&company=" + company + "&emailToken=" + emailToken);
	}

	resetLogingIpsErrors(session, company, loginIpsErrors) {
		return this.post(this.adminApiUrl + '/resetLogingIpsErrors', "session=" + session + "&company=" + company + "&loginIpsErrors=" + loginIpsErrors);
	}
	getConfiPlayerData(session, company) {
		return this.post(this.adminApiUrl + '/configPlayerData', "session=" + session + "&company=" + company);
	}
	getAutoexclusionVerticalDetails(session, company, db, user, init_date) {
		return this.post(this.adminApiUrl + '/getAutoexclusionVerticalDetails', "session=" + session + "&db=" + db + "&user=" + user + "&company=" + company + "&init_date=" + init_date);

	}
	saveAutoexclusionVerticalDetails(session, company, autoexclusion) {
		return this.post(this.adminApiUrl + '/saveAutoexclusionVerticalDetails', "session=" + session + "&company=" + company + "&autoexclusion=" + autoexclusion);

	}
	getPromotionCodes(session, company, promotion) {
		return this.post(this.adminApiUrl + '/getPromotionCodes', "session=" + session + "&company=" + company + "&promotion=" + promotion);
	}
	createVoucher(session, company, amount, currency) {
		return this.post(this.adminApiUrl + '/retail/createVoucher', "session=" + session + "&company=" + company + "&amount=" + amount + "&currency=" + currency);
	}
	getVoucher(session, company, voucher) {
		return this.post(this.adminApiUrl + '/retail/getVoucher', "session=" + session + "&company=" + company + "&voucher=" + voucher);
	}
	payVoucher(session, company, voucher) {
		return this.post(this.adminApiUrl + '/retail/payVoucher', "session=" + session + "&company=" + company + "&voucher=" + voucher);
	}
	getExcelUserSearch(session, company, freeText, alias, user, email, firstname, lastname, memberOf, notMemberOf, verified, registerFrom, registerTo, mobile, national_id, agent) {
		let data = {
			'session': session,
			'freeText': freeText,
			'email': email,
			'company': company,
			'alias': alias,
			'user': user,
			'firstname': firstname,
			'lastname': lastname,
			'memberOf': memberOf,
			'notMemberOf': notMemberOf,
			'verified': verified,
			'register_from': registerFrom,
			'register_to': registerTo,
			'mobile': mobile,
			'national_id': national_id,
			'agent': agent,
			'excel': true
		}
		let url = this.adminApiUrl + "/search"

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.onload = function () {
				resolve(xhr.response)
			}
			xhr.onerror = function () {

				reject({
					status: "error",
					code: this.status,
					statusText: xhr.statusText
				});
			}
			xhr.open("POST", url);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.responseType = "blob";
			xhr.send(qs.stringify(data));

		});
	}
	getCSVUserSearch(session, company, freeText, alias, user, email, firstname, lastname, memberOf, notMemberOf, verified, registerFrom, registerTo, mobile, national_id, agent) {

		let data = {
			'session': session,
			'freeText': freeText,
			'email': email,
			'company': company,
			'alias': alias,
			'user': user,
			'firstname': firstname,
			'lastname': lastname,
			'memberOf': memberOf,
			'notMemberOf': notMemberOf,
			'verified': verified,
			'register_from': registerFrom,
			'register_to': registerTo,
			'mobile': mobile,
			'national_id': national_id,
			'agent': agent,
			'csv': true
		}
		let url = this.adminApiUrl + "/search"

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.onload = function () {
				resolve(xhr.response)
			}
			xhr.onerror = function () {

				reject({
					status: "error",
					code: this.status,
					statusText: xhr.statusText
				});
			}
			xhr.open("POST", url);
			xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.responseType = "blob";
			xhr.send(qs.stringify(data));

		});
	}
	getDocumentation(session, company, url) {
		return this.post(this.adminApiUrl + '/getDocumentation', "session=" + session + "&company=" + company + "&url=" + url);
	}
	getDocumentationPages(session, company) {
		return this.post(this.adminApiUrl + '/getDocumentationPages', "session=" + session + "&company=" + company);
	}
	batchUpdateChat(session, company, question, author, chat_id, document_key, callBackFunction) {

		let formData = new FormData()
		formData.append('session', session)
		formData.append('company', company)
		formData.append('question', question)
		formData.append('author', author)
		formData.append('chat_id', chat_id)
		formData.append('document_key', document_key)

		return this.batchPost(this.adminApiUrl + "/batchUpdateChat", formData, callBackFunction)

	}
	addAIGroup(session, company, group_id, name, description, query_criteria = "", procedure_criteria = "", procedure_name = "", slave = 1) {
		return this.post(this.adminApiUrl + '/addAIGroup',
			"session=" + session + "&company=" + company + "&group_id=" + group_id + "&name=" + name +
			"&description=" + description + "&query_criteria=" + query_criteria + "&procedure_criteria=" + procedure_criteria +
			"&procedure_name=" + procedure_name + "&slave=" + slave);
	}
	getAdminNotifications(session, company) {
		return this.post(this.adminApiUrl + '/getAdminNotifications', "session=" + session + "&company=" + company)
	}

	updateStatusAdminNotification(session, company, notification) {
		return this.post(this.adminApiUrl + '/updateStatusAdminNotification', "session=" + session + "&company=" + company + "&notification=" + notification)
	}

	addLobbyDetails(session, company, lobby, machines) {
		return this.post(this.adminApiUrl + '/addLobbyDetails', "session=" + session + "&machines=" + machines + "&lobby=" + encodeURIComponent(lobby) + "&company=" + company);
	}

	updateLobbyDetails(session, company, lobby, machines) {
		return this.post(this.adminApiUrl + '/updateLobbyDetails', "session=" + session + "&machines=" + machines + "&lobby=" + encodeURIComponent(lobby) + "&company=" + company);
	}

	addPopupsLobbyDetails(session, company, lobby, popups) {
		return this.post(this.adminApiUrl + '/addPopupsLobbyDetails', "session=" + session + "&popups=" + popups + "&lobby=" + lobby + "&company=" + company);
	}

	updatePopupsLobbyDetails(session, company, lobby, popups) {
		return this.post(this.adminApiUrl + '/updatePopupsLobbyDetails', "session=" + session + "&popups=" + popups + "&lobby=" + lobby + "&company=" + company);
	}

	addBannerContainerDetails(session, company, container, banners) {
		return this.post(this.adminApiUrl + '/addBannerContainerDetails', "session=" + session + "&banners=" + banners + "&container=" + container + "&company=" + company);
	}

	updateBannerContainerDetails(session, company, container, banners) {
		return this.post(this.adminApiUrl + '/updateBannerContainerDetails', "session=" + session + "&banners=" + banners + "&container=" + container + "&company=" + company);
	}

	addFragmentDetails(session, company, fragment) {
		return this.post(this.adminApiUrl + '/addFragmentDetails', "session=" + session + "&fragment=" + encodeURIComponent(fragment) + "&company=" + company);
	}

	updateFragmentDetails(session, company, fragment) {
		return this.post(this.adminApiUrl + '/updateFragmentDetails', "session=" + session + "&fragment=" + encodeURIComponent(fragment) + "&company=" + company);
	}

	deleteFragmentDetails(session, company, fragment) {
		return this.post(this.adminApiUrl + '/deleteFragmentDetails', "session=" + session + "&fragment=" + encodeURIComponent(fragment) + "&company=" + company);
	}
	getLimitWagersDetails(session, db, user, company) {
		return this.post(this.adminApiUrl + '/getLimitWagersDetails', "session=" + session + "&db=" + db + "&user=" + user + "&company=" + company);
	}
	saveLimitWagersDetails(session, limits, db, user) {
		return this.post(this.adminApiUrl + '/saveLimitWagersDetails', "session=" + session + "&db=" + db + "&user=" + user + "&limits=" + limits);
	}
	getAdminUserRoles(session, company) {
		return this.post(this.adminApiUrl + '/getAdminUserRoles', "session=" + session + "&company=" + company);
	}
	saveAlertHeader(session, company, message) {
		return this.post(this.adminApiUrl + '/saveAlertHeader', "session=" + session + "&company=" + company + "&message=" + message);
	}

};

export default AdminClient;
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Tab from '../../components/basic/BasicTabs';
import AllowedForbiddenList from '../../components/basic/AllowedForbiddenList';
function MachinesUpdateBatch(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [updateDetails, setUpdateDetails] = useState({
        machine_type: -1,
        agent: 0,
        note: "",
        note_title: "",
        can_play: 0,
        can_deposit: 0,
        can_payout: 0,
        can_promotions: 0,
        players: [],
        agent: -1,
        remove_tags: [],
        add_tags: [],
        machineRelations: {
            play_groups_availables: [],
            play_groups_allowed: [],
            play_groups_forbidden: [],
            view_groups_availables: [],
            view_groups_allowed: [],
            view_groups_forbidden: []
        }
    });
    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Machines Update Batch'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(async function () {
        let groupsObj = await adminClient.getGroups(session, company);
        let groups = Object.values(groupsObj.groups);
        let machineRelationsNew = updateDetails.machineRelations;
        machineRelationsNew.play_groups_availables = groups;
        machineRelationsNew.view_groups_availables = groups;
        setUpdateDetails((prevState) => ({ ...prevState, machineRelations: machineRelationsNew }));
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setUpdateDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerGroup = (allowed, forbidden, type) => {
        let machineRelationsNew = { ...updateDetails };
        if (type === "view") {
            machineRelationsNew.machineRelations.view_groups_allowed = allowed;
            machineRelationsNew.machineRelations.view_groups_forbidden = forbidden;
        }
        else if (type === "play") {
            machineRelationsNew.machineRelations.play_groups_allowed = allowed;
            machineRelationsNew.machineRelations.play_groups_forbidden = forbidden;
        }
        setUpdateDetails(machineRelationsNew);
    };

    const send = (evt) => {
        if (updateDetails.machines === undefined) {
            props.showAlert(t("Machines update"), <p>{t("Need to add machines")}</p>);

        }
        else {
            let machines = updateDetails.machines.split(',');

            props.showAlert(t("Machines update"), <p>{t("We are going to process") + " " + machines.length + " " + t("machines")}</p>,
                [<button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Cancel")}</button>,
                <button type="button" className="btn btn-primary"
                    onClick={(evt) => {
                        sendBatch();
                        props.handleCloseAlert()
                    }}>{t("Ok")}</button>]
            );
        }
    }

    const sendBatch = () => {

        var machines = updateDetails.machines.split(/\r?\n/);
        var promise = adminClient.machinesUpdatetBatch(
            session, company,
            machines,
            JSON.stringify(updateDetails.add_lobbies), JSON.stringify(updateDetails.remove_lobbies),
            updateDetails.machine_type,
            JSON.stringify(updateDetails.add_tags), JSON.stringify(updateDetails.remove_tags),
            updateDetails.demoAllowed,
            updateDetails.status,
            updateDetails.set,
            JSON.stringify(updateDetails.machineRelations['view_groups_allowed']), JSON.stringify(updateDetails.machineRelations['view_groups_forbidden']),
            JSON.stringify(updateDetails.machineRelations['play_groups_allowed']), JSON.stringify(updateDetails.machineRelations['play_groups_forbidden']),
            updateDetails.allow_freespins
        );

        promise.then(
            function (msg) {

                if (msg.errors !== undefined && msg.errors.length > 0) {
                    var errorMessage = <><p>{msg.errors.length + " " + t("Machines were not updated")}</p>
                        {msg.errors.map(item => (
                            <p>{item.user + "  " + t("Reason") + " " + item.description} </p>
                        ))}
                    </>
                    props.showAlert(t("Machines update"), errorMessage);
                } else {
                    props.showAlert(t("Machines update"), <p>{t("All machines updated")}</p>);
                }
            }
            , function (err) {
                props.showAlert(t("Machines update"), <p>{t("Error: ") + err.message}</p>);

            });
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <BasicSelect
                        label={t("Add lobby")}
                        idProperty="lobby"
                        name="add_lobbies"
                        data="lobbies"
                        basicInfoName="CompanyLobbies"
                        onChange={onChangeHandler}
                        value={updateDetails.add_lobbies}
                    />
                </div>

                <div className="col-12 col-sm-6">
                    <BasicSelect
                        label={t("Remove lobby")}
                        idProperty="lobby"
                        name="remove_lobbies"
                        data="lobbies"
                        basicInfoName="CompanyLobbies"
                        onChange={onChangeHandler}
                        value={updateDetails.remove_lobbies}
                    />
                </div>


                <div className="col-12 ">
                    <BasicSelect
                        idProperty="type"
                        label={t("Type")}
                        name="machine_type"
                        basicInfoName="MachinesTypes"
                        onChange={onChangeHandler}
                        value={updateDetails.machine_type}
                        disableClearable
                    />
                </div>

                <div className="col-12 col-sm-6">
                    <BasicSelect
                        idProperty="tag"
                        label={t("Add tag")}
                        name="add_tags"
                        basicInfoName="AllMachineTags"
                        onChange={onChangeHandler}
                        value={updateDetails.add_tags}
                        multiple
                    />
                </div>


                <div className="col-12 col-sm-6">
                    <BasicSelect
                        idProperty="tag"
                        label={t("Remove tag")}
                        basicInfoName="AllMachineTags"
                        name="remove_tags"
                        onChange={onChangeHandler}
                        value={updateDetails.remove_tags}
                        multiple
                    />
                </div>

                <div className="col-12 mt-4">
                    <TextField type="text" multiline label={t("Machines")} name="machines" onChange={onChangeHandler} value={updateDetails.machines}  ></TextField>
                </div>
                <div className="col-12 col-md-3">
                    <BasicSelect
                        idProperty="set"
                        name="set"
                        label={t("Set")}
                        basicInfoName="AccountSets"
                        onChange={onChangeHandler}
                        value={updateDetails.set}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <BasicSelect
                        idProperty='status'
                        basicInfoName='EnabledDisabled'
                        value={updateDetails.demoAllowed}
                        name='demoAllowed'
                        label={t('Demo Allowed')}
                        onChange={(evt) => { onChangeHandler({ name: "demoAllowed", value: evt.value === "" ? undefined : evt.value }) }}
                        translate={{ property: 'name', prefix: '' }}
                        multiple={false}
                        noEmptyValue={false}
                        disableClearable={false}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <BasicSelect
                        idProperty='name'
                        basicInfoName='EnabledDisabled'
                        value={updateDetails.status}
                        name='status'
                        label={t('Status')}
                        onChange={(evt) => { onChangeHandler({ name: "status", value: evt.value === "" ? undefined : evt.value }) }}
                        translate={{ property: 'name', prefix: 'STATUS_' }}
                        multiple={false}
                        noEmptyValue={false}
                        disableClearable={false}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <BasicSelect
                        idProperty='status'
                        basicInfoName='EnabledDisabled'
                        value={updateDetails.status}
                        name='allow_freespins'
                        label={t('Allow FresSpins')}
                        onChange={(evt) => { onChangeHandler({ name: "allow_freespins", value: evt.value === "" ? undefined : evt.value }) }}
                        translate={{ property: 'name', prefix: '' }}
                        multiple={false}
                        noEmptyValue={false}
                        disableClearable={false}
                    />
                </div>
                <Tab key="tabContentGroupsTags" active={0}>
                    <Tab.TabPane key={`TabContentView`} tab={t("View Groups")}>
                        <AllowedForbiddenList
                            allowed={updateDetails.machineRelations['view_groups_allowed']}
                            availables={updateDetails.machineRelations['view_groups_availables']}
                            forbidden={updateDetails.machineRelations['view_groups_forbidden']}
                            idProperty={"group"}
                            nameProperty={"name"}
                            handleUpdateGroups={(allowed, forbidden) => onChangeHandlerGroup(allowed, forbidden, "view")}
                        />
                    </Tab.TabPane>
                    <Tab.TabPane key={`TabContentPlay`} tab={t("Play Groups")}>
                        <AllowedForbiddenList
                            allowed={updateDetails.machineRelations['play_groups_allowed']}
                            availables={updateDetails.machineRelations['play_groups_availables']}
                            forbidden={updateDetails.machineRelations['play_groups_forbidden']}
                            idProperty={"group"}
                            nameProperty={"name"}
                            handleUpdateGroups={(allowed, forbidden) => onChangeHandlerGroup(allowed, forbidden, "play")}
                        />
                    </Tab.TabPane>
                </Tab>
            </div>
            <div className="row">
                {utils.isGranted("SAVE_CONFIGURATION_MACHINES") &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={send}
                        >
                            {t('Send')}
                        </button>
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(MachinesUpdateBatch);
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import utils from '../utils';
function OperationTicket(props) {

    const data = props.data
    const type = data.type;
    const companyDetails = utils.getCurrentCompany();
    const url = new URL(companyDetails.config.cdn_url).hostname;
    const formattedURL = `<a href="${"https://" + url}" target="_blank" style="text-decoration: underline; color: #0000EE;">${url}</a>`;
    const logoURL = "/img/logo-header.png";
    const { t } = props;
    const [css, setCss] = useState("")
    useEffect(() => {
        const cssUrl = utils.getCurrentCompany().config?.backoffice?.operation_ticket_css;
        console.log(cssUrl)
        setCss(cssUrl)
    }, [])

    console.log(css)
    return (<>
        <div className={"container calimaco_operation_ticket_print_container_general"} >
            <link
                rel="stylesheet"
                href={css}
            />
            <div className="calimaco_operation_ticket_print_container_logo"></div>
            {type === "OPERATION_TYPE_DEPOSIT" &&
                <div className='calimaco_operation_ticket_print_container'>
                    <div className="row calimaco_operation_ticket_print_title">
                        <span>  {t("operation_ticket_print_title_deposit")}  </span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_date">
                        <span>{t("Date")}: {utils.formatDate(data.operation_date)}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_id">
                        <span>{t("ID")}: {data.operation}</span>

                    </div>

                    <div className="row calimaco_operation_ticket_print_player">
                        <span>{t("Deposit to")}:</span>
                        <span className='value'>{JSON.parse(utils.getSessionItem("userDetails")).alias} ({utils.getSessionItem("current-user")})</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_amount">
                        <span>{t("The amount of")}:</span>
                        <span className='value'>{data.amount / 100} {data.currency}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_url">
                        <span>{t("To deposit into your account")}:</span>
                        <span className='value'>{formattedURL && <p dangerouslySetInnerHTML={{ __html: formattedURL }}></p>}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_agent">
                        <span>{t("Agent")}:</span>
                        <span className='value'>{JSON.parse(utils.getSessionItem("user")).alias} ({JSON.parse(utils.getSessionItem("user")).user})</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_signature">
                        <span>{t("Confirmation")}:</span>

                    </div>

                </div>
            }
            {type === "OPERATION_TYPE_PAYOUT" &&
                <div className='calimaco_operation_ticket_print_container'>
                    <div className="row calimaco_operation_ticket_print_title">
                        <span>  {t("operation_ticket_print_title_payout")}  </span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_date">
                        <span>{t("Date")}: {utils.formatDate(data.operation_date)}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_id">
                        <span>{t("ID")}: {data.operation}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_player">
                        <span>{t("Payout to")}:</span>
                        <span className='value'>{JSON.parse(utils.getSessionItem("userDetails")).alias} ({utils.getSessionItem("current-user")})</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_amount">
                        <span>{t("The amount of")}:</span>
                        <span className='value'>{data.amount / 100} {data.currency}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_url">
                        <span>{t("From their account at")}:</span>
                        <span className='value'>{formattedURL && <p dangerouslySetInnerHTML={{ __html: formattedURL }}></p>}</span>
                    </div>

                    <div className="row calimaco_operation_ticket_print_agent">
                        <span>{t("Agent")}:</span>
                        <span className='value'>{JSON.parse(utils.getSessionItem("user")).alias} ({JSON.parse(utils.getSessionItem("user")).user})</span>
                    </div>
                    <div className="row calimaco_operation_ticket_print_signature">
                        <span>{t("Confirmation")}:</span>
                    </div>
                </div>
            }
        </div>
    </>)
}

export default withTranslation()(OperationTicket)
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function LobbyGames(props) {
  const company = utils.getSessionItem('Company');
  const session = JSON.parse(utils.getSessionItem('user')).session;
  const adminClient = new AdminClient();
  const { t } = props;
  const { formValidation } = useFormValidation('Form_LobbyGames')
  const lobby = utils.getSessionItem('current-lobby');

  const [addOrUpdate, setAddOrUpdate] = useState('add');
  const [onlyEnabled, setOnlyEnabled] = useState(false)

  const [lobbyGames, setLobbyGames] = useState({
    init_page: 0,
    lobby: {
      lobby: lobby,
      name: "",
      type: "",
      company: company,
      config: ""
    },
    machines: [],
    availables: [],
    filter: '',
    selectedMachines: [],
    filterSelected: "",
    checked: {},
    base_availables: [],
    avaliablesVisible: false
  });

  const pageSize = 50;
  utils.setSessionItem("ReportFilter-lobby_machines", JSON.stringify([{ field: "lobby", type: "=", value: lobby }]));


  const goBack = () => {
    props.history.push('/reports/report/lobbies/table');
  };

  const dataHeader = {
    backLink: goBack,
    title: t('Lobby games'),
    urlHelp: '',
    idDoc: props.idDoc
  };

  useEffect(function () {
    adminClient.getLobbyDetails(session, company, lobby).then(
      async function (result) {
        if (lobby !== undefined && lobby !== '-1') {
          let auxLobby = { ...lobbyGames };
          auxLobby.lobby = result.data.lobby;
          auxLobby.machines = result.data.machines;
          auxLobby.selectedMachines = result.data.machines.slice(0, pageSize);
          auxLobby.availables = result.data.availables;
          auxLobby.base_availables = result.data.availables;
          setLobbyGames(auxLobby);
          setAddOrUpdate('update');
        } else {
          let auxLobby = { ...lobbyGames };
          auxLobby.availables = result.data.availables;
          auxLobby.base_availables = result.data.availables;
          setLobbyGames(auxLobby);
          setAddOrUpdate('add');
        }
      },
      function (err) {
        console.error(err);
      }
    );

  }, []);

  const onChangeHandlerLobby = (e) => {
    const { name, value } = e.target || e;
    setLobbyGames((prevState) => ({
      ...prevState,
      lobby: {
        ...prevState.lobby,
        [name]: value
      },
    }));

  }

  const handleShowAvailables = () => {
    if (lobbyGames.avaliablesVisible) {
      document.getElementById("availablesDIV").classList.add("d-none");
    } else {
      document.getElementById("availablesDIV").classList.remove("d-none");
    }
    setLobbyGames((prevState) => ({
      ...prevState,
      avaliablesVisible: !prevState.avaliablesVisible
    }));
  }

  const filterAvailables = () => {

    let availables = []

    lobbyGames.base_availables.forEach(element => {
      const filterString = String(lobbyGames.filter); // Convertir filter a cadena de texto en caso de ser la ID        
      if ((lobbyGames.filter.length > 0 && (element.name?.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || element.sub_provider?.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || String(element.machine).toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || String(element.external_id).toUpperCase().indexOf(filterString.toUpperCase()) >= 0)) || (lobbyGames.filter.length === 0)) {
        if (!onlyEnabled) availables.push(element)
        else if (element.status === 'ENABLED') availables.push(element)
      }
    });

    setLobbyGames((prevState) => ({
      ...prevState,
      availables: availables,
    }));
  }

  useEffect(() => {
    filterAvailables()
  }, [onlyEnabled, JSON.stringify(lobbyGames.filter)])

  const handleFilter = (evt) => {
    let filter = evt.target.value;
    let name = evt.target.name;

    setLobbyGames((prevState) => ({ ...prevState, [name]: filter }));

    let availables = [];

    if (evt.target.name === "filterSelected") {
      lobbyGames.machines.forEach(element => {
        const filterString = String(filter); // Convertir filter a cadena de texto en caso de ser la ID
        if ((filter.length > 0 && (element.name.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || element.sub_provider.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || String(element.machine).toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || String(element.external_id).toUpperCase().indexOf(filterString.toUpperCase()) >= 0)) ||
          (filter.length === 0)) {
          availables.push(element);
        }
      });

      setLobbyGames((prevState) => ({
        ...prevState,
        selectedMachines: availables.slice(0, pageSize),
        init_page: 0
      }));
    }
  }

  const handleTag = (machineId, evt) => {
    let machines = [...lobbyGames.machines];
    machines.forEach(machine => {
      if (machine.machine === machineId) {
        machine.tag = evt.value;
      }
    });
    setLobbyGames((prevState) => ({ ...prevState, machines: machines }));
  }

  const handleUpdateOrder = (machineId, evt, up) => {

    const sortMachines = (a, b) => {
      return (a.view_order > b.view_order) ? 1 : ((b.view_order > a.view_order) ? -1 : 0);
    }

    let antiguo_orden;
    let nuevo_orden;
    let machines = [...lobbyGames.machines];
    machines.forEach(machine => {
      if (machine.machine === machineId) {
        antiguo_orden = Number(machine.view_order);
        nuevo_orden = up ? antiguo_orden + up : Number(evt.target.value);
        machine.selected_css = "calimaco-selected";
      } else {
        machine.selected_css = "";
      }
    });

    machines.forEach(machine => {
      if (machine.machine === machineId) {
        machine.view_order = nuevo_orden;
      } else {
        if (nuevo_orden < antiguo_orden) {
          if (machine.view_order >= nuevo_orden && machine.view_order < antiguo_orden) {
            machine.view_order++;
          }
        } else {
          if (machine.view_order <= nuevo_orden && machine.view_order > antiguo_orden) {
            machine.view_order--;
          }

        }
      }
    });
    machines = machines.sort(sortMachines);

    let availables = [];

    machines.forEach(element => {
      if ((lobbyGames.filterSelected.length > 0 &&
        (element.name.toUpperCase().indexOf(lobbyGames.filterSelected.toUpperCase()) >= 0 || element.sub_provider.toUpperCase().indexOf(lobbyGames.filterSelected.toUpperCase()) >= 0)) ||
        (lobbyGames.filterSelected.length === 0)) {
        availables.push(element);
      }
    });

    setLobbyGames((prevState) => ({
      ...prevState,
      selectedMachines: availables.slice(prevState.init_page * pageSize, (prevState.init_page + 1) * pageSize),
      machines: machines
    }));
  }

  const save = (evt) => {
    if (formValidation.validate()) {
      let lobby = { ...lobbyGames.lobby };
      let machines = [...lobbyGames.machines]

      document.getElementById("saveLobbyBtn").disabled = true;

      var machinesIds = [];
      machines.forEach(element => {
        machinesIds.push({ machine: element.machine, tag: element.tag, view_order: element.view_order });
      });
      let promise;
      if (addOrUpdate === 'add') {
        promise = adminClient.addLobbyDetails(session, company, JSON.stringify(lobby), JSON.stringify(machinesIds))
      }
      else {
        promise = adminClient.updateLobbyDetails(session, company, JSON.stringify(lobby), JSON.stringify(machinesIds))
      }
      promise.then(
        (msg) => {
          if (msg.result === 'OK') {
            props.showAlert(t('Lobby games'), t('Saved succesfull'))
            if (addOrUpdate === 'add') {
              setAddOrUpdate('update')
            }
            document.getElementById("saveLobbyBtn").disabled = false;
          } else {
            props.showAlert(t('Lobby games'), t('Saving error ') + msg.description)
          }
        },
        (error) => {
          props.showAlert(t('Error'), t('Saving error ') + error.description)
        }
      )
    }
  }

  const remove = (evt) => {
    let lobby = { ...lobbyGames.lobby };

    props.showAlert(
      t('Lobby games'),
      <p>{t('DELETE_LOBBY_CONFIRMATION')}</p>,
      [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
      <button type='button' className='btn btn-primary'
        onClick={(evt) => {
          adminClient.deleteLobbyDetails(session, company, JSON.stringify(lobby)).then(
            (msg) => {
              if (msg.result === 'OK') {
                props.showAlert(t('Lobby games'), t('Deleted succesfull'))
                if (addOrUpdate === 'add') {
                  setAddOrUpdate('update')
                }
                goBack();
              } else {
                props.showAlert(t('Lobby games'), t('Deleting error ') + msg.description)
              }
            },
            (error) => {
              props.showAlert(t('Lobby games'), t('Deleting error ') + error.description)
            }
          )
          props.handleCloseAlert()
        }}>
        {t('Ok')}</button>]
    )

  }


  const setPage = (page) => {
    let visible_machines;
    if (lobbyGames.filterSelected !== undefined && lobbyGames.filterSelected.length > 0) {
      let filter = { ...lobbyGames.filterSelected };
      let availables = [];
      lobbyGames.machines.forEach(element => {
        const filterString = String(filter); // Convertir filter a cadena de texto en caso de ser la ID
        if ((filter.length > 0 && (element.name.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || element.sub_provider.toUpperCase().indexOf(filterString.toUpperCase()) >= 0 || String(element.machine).toUpperCase().indexOf(filterString.toUpperCase()) >= 0)) ||
          (filter.length === 0)) {
          availables.push(element);
        }
      });

      visible_machines = availables.slice(page * pageSize, (page + 1) * pageSize);

      setLobbyGames((prevState) => {
        let oldState = { ...prevState };
        oldState.init_page = page;
        oldState.selectedMachines = visible_machines;
        return { ...prevState, oldState }
      });

    } else {
      visible_machines = lobbyGames.machines.slice(page * pageSize, (page + 1) * pageSize);
    }
    setLobbyGames((prevState) => ({
      ...prevState,
      init_page: page,
      selectedMachines: visible_machines
    }));
  }

  const removeMachine = (machine) => {
    //let machines = [...lobbyGames.machines];
    let availables = [...lobbyGames.availables];
    let base_availables = [...lobbyGames.base_availables];

    // La quitamos de la máquinas
    let machines = lobbyGames.machines.filter(item => item.machine !== machine.machine);
    // La añádimos al principio de las disponibles
    availables.unshift(machine);
    base_availables.push(machine)

    // Actualizamos las visibles
    let visible_machines = machines.slice(0, pageSize);
    setLobbyGames((prevState) => ({
      ...prevState,
      machines: machines,
      availables: availables,
      base_availables: base_availables,
      selectedMachines: visible_machines
    }));
  }

  const removeAllMachines = () => {
    let machines = [...lobbyGames.machines];
    props.showAlert(
      t('Lobby games'),
      <p>{t('CLEAN_GAMES_LOBBY_CONFIRMATION')}</p>,
      [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
      <button type='button' className='btn btn-primary'
        onClick={(evt) => {
          setLobbyGames((prevState) => ({
            ...prevState,
            machines: [],
            availables: [...prevState.availables, ...machines],
            base_availables: [...prevState.base_availables, ...machines],
            selectedMachines: []
          }));
          props.handleCloseAlert()
        }}>
        {t('Ok')}</button>]
    )

  }

  const addMachine = (machine) => {
    let machines = [...lobbyGames.machines];
    //let availables = [...lobbyGames.availables];
    // Quitamos la máquina de las disponibles
    let availables = lobbyGames.availables.filter(item => item.machine !== machine.machine);
    let base_availables = lobbyGames.base_availables.filter(item => item.machine !== machine.machine)
    // Asignamos el orden al final
    machine.view_order = machines.length + 1;
    // Añadimos la máquina al final
    machines.push(machine);

    let visible_machines = machines.slice(0, pageSize);

    setLobbyGames((prevState) => ({
      ...prevState,
      machines: machines,
      availables: availables,
      base_availables: base_availables,
      selectedMachines: visible_machines,
      init_page: 0
    }));
  }

  const handleAddSelected = () => {
    let checked = lobbyGames.checked;
    let machines = lobbyGames.machines;
    let availables = lobbyGames.availables;
    let base_availables = lobbyGames.base_availables;
    let visible_machines;
    for (let i in checked) {
      let machine = availables.filter(item => Number(item.machine) === Number(i))[0];
      // Quitamos la máquina de las disponibles
      availables = availables.filter(item => item.machine !== machine.machine);
      base_availables = base_availables.filter(item => item.machine !== machine.machine);
      // Asignamos el orden al final
      machine.view_order = machines.length + 1;
      // Añadimos la máquina al final
      machines.push(machine);
      visible_machines = machines.slice(0, pageSize);
    }
    setLobbyGames((prevState) => ({
      ...prevState,
      machines: machines,
      availables: availables,
      base_availables: base_availables,
      selectedMachines: visible_machines,
      init_page: 0,
      checked: {},
      checked_all: undefined
    }));
  }

  const handleUpdateCheckAll = () => {
    let checked = lobbyGames.checked;
    if (lobbyGames.checked_all !== undefined) {
      for (let i = 0; i < lobbyGames.availables.slice(0, pageSize).length; i++) {
        setLobbyGames((prevState) => ({
          ...prevState,
          checked: {},
          checked_all: undefined
        }));
      }
    } else {
      for (let i = 0; i < lobbyGames.availables.slice(0, pageSize).length; i++) {
        let machine = lobbyGames.availables[i];
        checked[machine.machine] = true;
        setLobbyGames((prevState) => ({
          ...prevState,
          checked: checked,
          checked_all: true
        }));
      }

    }
  }
  const handleUpdateCheckAvailable = (machine, evt) => {
    let checked = lobbyGames.checked;
    if (evt.target.checked) {
      checked[machine] = true;
    } else {
      delete checked[machine];
    }
    setLobbyGames((prevState) => ({
      ...prevState,
      checked: checked
    }));
  }



  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <form id="Form_LobbyGames">
        <div className="row">
          <div className="col-12 col-md-6">
            <TextField
              label={t('ID')}
              name="lobby"
              required
              disabled={addOrUpdate === 'update'}
              inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
              type="text"
              value={lobbyGames.lobby.lobby}
              onChange={onChangeHandlerLobby}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label={t('Name')}
              name="name"
              required
              type="text"
              value={lobbyGames.lobby.name}
              onChange={onChangeHandlerLobby}
            />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-12">
          {utils.isGranted("SAVE_CMS_MACHINES_LOBBIES") &&
            <button
              type="button"
              className="btn btn-primary"
              onClick={save}
              id="saveLobbyBtn"
            >
              {t('Save')}
            </button>
          }

          {lobbyGames.machines.length > 0 && utils.isGranted("SAVE_CMS_MACHINES_LOBBIES") &&
            <button
              type="button"
              className="btn btn-secondary"
              onClick={removeAllMachines}
              id="removeBtn"
            >
              {t('Clean lobby')}
            </button>
          }
          {utils.isGranted("SAVE_CMS_MACHINES_LOBBIES") &&
            <button
              type="button"
              className="btn btn-secondary"
              onClick={remove}
              id="removeBtn"
            >
              {t('Delete')}
            </button>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-4 text-left">
          {lobbyGames.init_page > 0 &&
            <button className="btn btn-secondary float-start" onClick={(evt) => { setPage(lobbyGames.init_page - 1) }}>{t("Previous")}</button>
          }
        </div>
        <div className="col-4 text-center" >
          {lobbyGames.init_page * pageSize}-{Math.min((lobbyGames.init_page + 1) * pageSize, lobbyGames.machines.length)} / {lobbyGames.machines.length}
        </div>
        <div className="col-4">
          {(lobbyGames.init_page + 1) * pageSize < lobbyGames.machines.length &&
            <button className="btn  btn-secondary" href="#" onClick={(evt) => { setPage(lobbyGames.init_page + 1) }}>{t("Next")}</button>
          }
        </div>
      </div>
      <div className="row calimaco_already_in_lobby">
        <TextField onChange={handleFilter} name="filterSelected" value={lobbyGames.filterSelected} label={t("Filter")} type="text" />
        <div className="table-responsive">
          <table className="table table-striped table-hover table_layout_fixed"><thead>
            <tr><td></td><td>{t("Name")}</td><td>{t("ID")}</td><td>{t("External Id")}</td><td>{t("Type")}</td><td>{t("Provider")}</td><td>{t("Tag")}</td><td>{t("Order")}</td>
              <td></td><td></td><td>{t("Delete")}</td></tr>
          </thead>
            <tbody>
              {lobbyGames.selectedMachines.map((item, idx) => (
                <tr className={item.selected_css} key={item.machine} id={"grid-machine-" + item.machine} >
                  <td>{lobbyGames.init_page * pageSize + idx}</td>
                  <td>{item.name}</td>
                  <td>{item.machine}</td>
                  <td>{item.external_id}</td>
                  <td>{item.type}</td>
                  <td className="provider">{item.sub_provider}</td>
                  <td>
                    <BasicSelect
                      idProperty="tag"
                      name="lobbyTag"
                      label={t("Tag")}
                      basicInfoName="LobbiesTags"
                      onChange={(evt) => handleTag(item.machine, evt)}
                      value={item.tag}
                    />
                  </td>
                  <td>
                    {lobbyGames.init_page === 0 && idx === 0 && <span className="cursor-pointer  calimaco-empty-arrow "></span>}
                    {(lobbyGames.init_page > 0 || idx > 0) && <span className="cursor-pointer  calimaco-border-arrow " onClick={(evt) => handleUpdateOrder(item.machine, evt, -1)} ><FontAwesomeIcon size="lg" icon="arrow-up" /></span>}
                    {(idx < lobbyGames.selectedMachines.length - 1 || (lobbyGames.init_page + 1) * pageSize < lobbyGames.machines.length) && <span className="cursor-pointer calimaco-border-arrow" onClick={(evt) => handleUpdateOrder(item.machine, evt, +1)}> <FontAwesomeIcon size="lg" icon="arrow-down" /> </span>}
                  </td>
                  <td><TextField onChange={(evt) => handleUpdateOrder(item.machine, evt)} value={(lobbyGames.init_page + item.view_order)}></TextField></td>
                  <td><img className="img-thumbnail" src={"" + utils.getCurrentCompany().config.cdn_url + "../" + item.logo}></img></td>
                  <td><span className="cursor-pointer" onClick={() => removeMachine(item)}><FontAwesomeIcon icon="trash-alt" /></span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 ">
          <button type="button" className="btn btn-secondary float-start" onClick={handleShowAvailables}>{t("Add machines")}</button>
        </div>
      </div>
      <div className="row d-none lobbyMachinesContainer" id="availablesDIV">
        <div className="col-12 col-md-8">
          <TextField onChange={handleFilter} name="filter" value={lobbyGames.filter} label={t("Filter")} type="text" />
        </div>
        <div className="col-12 col-md-4">
          <FormControlLabel
            control={<Checkbox
              checked={onlyEnabled}
              name="filter_enabled"
              onChange={() => { setOnlyEnabled(!onlyEnabled) }}
            />}
            label={t("Only Enabled")} />
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table_layout_fixed"><thead>
            <tr><td>{t("Name")}</td><td>{t("ID")}</td><td>{t("Type")}</td><td>{t("Provider")}</td><td>{t("External Id")}</td><td>{t("Status")}</td><td>
              <td className="p-0">
                <button disabled={Object.keys(lobbyGames.checked).length === 0} type="button" className="btn btn-secondary " onClick={handleAddSelected}>{t("Add selected")}</button>
                <Checkbox checked={lobbyGames.checked_all !== undefined} onChange={handleUpdateCheckAll}></Checkbox>
              </td>
            </td></tr>
          </thead>
            <tbody>
              {lobbyGames.availables.slice(0, pageSize).map(item => (
                <tr key={item.machine} id={"grid-machine-" + item.machine}>
                  <td onClick={() => addMachine(item)}>{item.name}</td>
                  <td onClick={() => addMachine(item)}>{item.machine}</td>
                  <td onClick={() => addMachine(item)}>{item.type}</td>
                  <td onClick={() => addMachine(item)} className="provider">{item.sub_provider}</td>
                  <td onClick={() => addMachine(item)}>{item.external_id}</td>
                  <td onClick={() => addMachine(item)}>{t(item.status)}</td>
                  <td><Checkbox checked={lobbyGames.checked[item.machine] !== undefined} id={"checked_" + item.machine} onChange={(evt => handleUpdateCheckAvailable(item.machine, evt))}></Checkbox></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


      </div>
    </LayoutSection>
  )
}
export default withTranslation()(LobbyGames);
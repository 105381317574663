import React, { useState,useEffect } from "react";

  function usePagination(initData, itemsPerPage) {
    const [pageIndex, setPageIndex] = useState(0);
    const [data,setData]=useState(initData)
    itemsPerPage=itemsPerPage||5
    const pageCount = Math.ceil(data.length / itemsPerPage);    
    const canPreviousPage=pageIndex==0?false:true
    const canNextPage=pageIndex==pageCount-1?false:true
    const total=data.length;
    useEffect(() => {

      if(pageIndex==pageCount) if(canPreviousPage) previousPage()
      
    }, [pageCount])      


 function currentData() {
      const begin = (pageIndex-1) * itemsPerPage;
      const end = begin + itemsPerPage;
      return data.slice(begin, end);
 }

  function begin(){
    if(pageIndex==0) return 0
    else return (pageIndex) * itemsPerPage;    
  }  

  function nextPage() {
    setPageIndex(Math.min(pageIndex + 1, pageCount));
  }

  function previousPage() {
    setPageIndex(Math.max(pageIndex - 1, 0));
  }

  function gotoPage(page) {
    let min=0;    
    let pageToGo = Math.max(Number(min), Math.min(Number(pageCount-1), Number(page)));
    setPageIndex(pageToGo);
  }
 
  return { nextPage, previousPage, gotoPage, pageIndex, pageCount,begin,setPageIndex,setData,canNextPage,canPreviousPage,total};
 }

 export default usePagination;
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import BasicSelect from '../../components/basic/BasicSelect';
function UserVirtualGoodDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const player = JSON.parse(utils.getSessionItem("userDetails")).user;

    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UserVirtualGoodDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const virtualGoodRow = JSON.parse(utils.getSessionItem('current_row_user_virtual_goods'))
    const [virtualGoodDetails, setVirtualGoodDetails] = useState({
        virtual_good: {
            name: ""
        },
        user_virtual_good: {
            db: player.db,
            user: player.user,
            company: player.company,
            virtual_good: -1,
            user_virtual_good: -1,
            status: "DONE",
            comments: ""
        }
    });
    const goBack = () => {
        props.history.push('/support/report/user_virtual_goods/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Virtual good details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        let virtualGoodId = virtualGoodRow.user_virtual_good.split(".");

        if (virtualGoodRow !== undefined && virtualGoodRow !== '-1') {
            adminClient.getUserVirtualGoodDetails(session, virtualGoodId[0], player.user, virtualGoodId[1]).then(
                async function (result) {
                    setVirtualGoodDetails((prevState) => ({
                        ...prevState,
                        user_virtual_good: result.user_virtual_good,
                        virtual_good: result.virtual_good
                    }));
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setVirtualGoodDetails(prevState => ({
            ...prevState,
            user_virtual_good: {
                ...prevState.user_virtual_good,
                [name]: value,
            }
        }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveUserVirtualGoodDetails(
                session,
                JSON.stringify(virtualGoodDetails.user_virtual_good)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Virtual good details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        setVirtualGoodDetails((prevState) => ({
                            ...prevState,
                            user_virtual_good: msg.user_virtual_good,
                            virtual_good: msg.virtual_good
                        }));
                    } else {
                        props.showAlert(t('Virtual good details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Virtual good details'), t('Saving error ') + error.description)
                }
            )
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserVirtualGoodDetails">
                <div className="row">
                    {addOrUpdate === 'update' &&
                        <>
                            <div className="col-12 col-md-4">
                                <TextField
                                    label={t('ID')}
                                    name="id"
                                    disabled={addOrUpdate === 'update'}
                                    type="text"
                                    value={virtualGoodDetails.user_virtual_good.db + "." + virtualGoodDetails.user_virtual_good.user_virtual_good}
                                    onChange={onChangeHandler}
                                />
                            </div>

                            <div className="col-12 col-md-4 ">
                                <DateAndTimePicker
                                    disabled
                                    renderInput={(props) => <TextField  {...props} />}
                                    label={t("Created date")}
                                    value={utils.formatDate(virtualGoodDetails.user_virtual_good.created_date)}
                                    showTime={true}
                                />
                            </div>
                        </>
                    }

                    <div className="col-4">
                        <TextField disabled label={t("VirtualGood")} type="text" value={virtualGoodDetails.virtual_good.name} />
                    </div>

                    <div className="col-4">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            name="status"
                            basicInfoName="UserVirtualGoodStatus"
                            onChange={onChangeHandler}
                            value={virtualGoodDetails.user_virtual_good.status}
                            translate={{ property: "status", prefix: "USER_VIRTUAL_GOOD_STATUS_" }}
                            disableClearable
                        />
                    </div>


                    <div className="col-12">
                        <TextField multiline label={t("Comments")} type="text" name="comments" value={virtualGoodDetails.user_virtual_good.comments} onChange={onChangeHandler} />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted('SAVE_VIRTUAL_GOODS') &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }

                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(UserVirtualGoodDetails);
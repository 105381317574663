import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


class ProfileMenu extends React.Component {

  hideMenu() {
    document.getElementById("profileMenu").classList.remove("show");

  }

  render() {
    if (this.props.items === undefined || this.props.items.length === 0) {
      return <>No items</>
    } else {

      return (


        <nav className="navbar navbar-expand-xxl navbar-light bg-light">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">


              <ul id="profileMenu" className="navbar-nav me-auto mb-2 mb-lg-0"   >
                {this.props.items.map((el, index) => {
                  return (
                    <>
                      {el.childs.length > 0 &&
                        <li className="dropdown" id={el.item.item + "-" + index} key={el.item.item + "-" + index}>
                          <a key={"a_" + el.item.item + "-" + index}
                            className="dropdown-item dropdown-toggle"
                            id={"profileMenu-navbarDropdown-" + el.item.item}
                            data-bs-toggle="dropdown" aria-expanded="false"

                          >
                            {el.item.icon !== null && <span className="menu-icon"><FontAwesomeIcon icon={el.item.icon} /></span>} {el.item.label}
                          </a>
                          <ul
                            key={"profileMenu-ul-" + el.item.item + "-" + index}
                            id={"profileMenu-ul-" + el.item.item + "-" + index}
                            className="dropdown-menu dropdown-submenu"

                            aria-labelledby={"profileMenu-navbarDropdown-" + el.item.item}>
                            {el.childs.map((el2, index) => {
                              return (
                                <li key={"sub" + el2.item.item} id={el2.item.item} >
                                  <Link onClick={this.hideMenu} key={el2.item.item} className="dropdown-item" aria-current="page" to={el2.item.url}  >
                                    {el2.item.icon !== null && <FontAwesomeIcon icon={el2.item.icon} />} {el2.item.label}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      }
                      {el.childs.length === 0 &&
                        <li className="nav-item" key={el.item.item + "-" + index} id={el.item.item + "-" + index}>
                          <Link onClick={this.hideMenu} key={el.item.item} className="dropdown-item " aria-current="page" to={el.item.url}  >
                            {el.item.icon !== null && <span className="menu-icon"><FontAwesomeIcon icon={el.item.icon} /></span>} {el.item.label}
                          </Link>
                        </li>


                      }

                    </>

                  )
                })
                }
              </ul>



            </div>
          </div>

        </nav>

      )
    }
  }
}
export default ProfileMenu


import { TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import DateAndTimePicker from "../../components/DateAndTimePicker";
import moment from "moment";
import useFormValidation from "../../hooks/parsleyValidation";

function ExchangesDetails(props) {

    const { t } = props;

    const { formValidation } = useFormValidation('Form_ExchangesDetails')

    const [exchangeDetail, setExchangeDetail] = useState({ currency: "", exchange_currency: "", init_date: moment(new Date().getTime()).format("YYYY-MM-DD"), end_date: null, value: 1 });

    const [addOrUpdate, setAddOrUpdate] = useState("");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    const company = utils.getCurrentCompany().company;

    let adminClient = new AdminClient();

    useEffect(function () {

        let newInfo = { ...exchangeDetail };

        let exchangeInfo = utils.getSessionItem("current-currency,exchange_currency,init_date,end_date,value").split("_");

        if (exchangeInfo[0] !== "-1") {
            setAddOrUpdate("update");
            newInfo.currency = exchangeInfo[0];
            newInfo.exchange_currency = exchangeInfo[1];
            newInfo.init_date = exchangeInfo[2];
            newInfo.end_date = exchangeInfo[3] === "null" ? null : exchangeInfo[3];
            newInfo.value = exchangeInfo[4];
            setExchangeDetail(newInfo);
        } else {
            setAddOrUpdate("add");
        }

    }, []);

    const goBack = () => {
        props.history.push("/reports/report/exchanges/table");
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let newInfo = { ...exchangeDetail };
        newInfo[name] = value;

        setExchangeDetail(newInfo);
    };

    const handlePreSubmit = async () => {
        try {
            if (formValidation.validate()) {

                if (exchangeDetail.currency === exchangeDetail.exchange_currency && (parseInt(exchangeDetail.value) !== 1)) {
                    props.showAlert(
                        t('Save'),
                        <p>{t('EXCHANGES_SAME_CURRENCY_NO_1')}</p>,
                        [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
                        <button type='button' className='btn btn-primary'
                            onClick={(evt) => {
                                handleSubmit()
                                props.handleCloseAlert()
                            }}>
                            {t('Ok')}</button>]
                    )
                }
                else if (addOrUpdate === "add") {
                    let exchangeInUse = await adminClient.getExchangeDetails(session, company, JSON.stringify(exchangeDetail))
                    if (exchangeInUse.data.result === 'OK') {
                        props.showAlert(
                            t('Save'),
                            <p>{t('EXCHANGES_SAME_INTERVAL')}</p>,
                            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
                            <button type='button' className='btn btn-primary'
                                onClick={(evt) => {
                                    handleSubmit()
                                    props.handleCloseAlert()
                                }}>
                                {t('Ok')}</button>]
                        )
                    }
                    else {
                        handleSubmit()
                    }
                }
                else {
                    handleSubmit()
                }
            }
        }
        catch (err) {
            console.log(err)
        }

    }

    const handleSubmit = () => {
        let exchangeDetailToSave = { ...exchangeDetail };

        if (exchangeDetailToSave.end_date === "-1") exchangeDetailToSave.end_date = null;
        let promise = adminClient.saveExchangesDetails(
            session,
            company,
            JSON.stringify(exchangeDetailToSave)
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert(
                        t('Ok'),
                        <p>{t("Saved succesfull")}</p>,
                        [<button type='button' className='btn btn-primary'
                            onClick={(evt) => {
                                goBack()
                            }}>
                            {t('Ok')}</button>]
                    )
                } else {
                    props.showAlert(t("Error"), t("Saving error"))
                }

            }, function (err) {
                console.log("Delete Exchange error " + err);
                props.showAlert("Error", t("Saving error") + t(err.description))
            }
        );


    };

    const handlePreDelete = () => {
        props.showAlert(
            t('Delete'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    handleDelete()
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }

    const handleDelete = () => {
        let promise = adminClient.deleteExchangesDetails(
            session,
            company,
            JSON.stringify(exchangeDetail)
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert("Ok", t("Deleted succesfull"))
                    goBack();
                } else {
                    props.showAlert("Error", t("Deleting error"))
                }
            }, function (err) {
                console.log("Save Exchange error " + err.description.code);
                props.showAlert("Error", t("Deleting error") + t(err.description))
            }
        );

    };



    const dataHeader = {
        backLink: goBack,
        title: t("Exchange config"),
        urlHelp: "",
        idDoc: props.idDoc
    };


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>

            <div className="row">
                <div className="card-body m-0">
                    <form id="Form_ExchangesDetails">

                        <div className="row">
                            <div className="col-12 col-md-6">

                                <BasicSelect
                                    required
                                    disabled={addOrUpdate === "add" ? false : true}
                                    label={t("Currency")}
                                    idProperty="currency"
                                    nameProperty="currency"
                                    name="currency"
                                    data="currencies"
                                    basicInfoName="Currencies"
                                    onChange={onChangeHandler}
                                    value={exchangeDetail.currency}
                                    translate={{ property: "currency", prefix: "" }}
                                />
                            </div>
                            <div className="col-12 col-md-6">

                                <BasicSelect
                                    required
                                    disabled={addOrUpdate === "add" ? false : true}
                                    label={t("Exchange currency")}
                                    idProperty="currency"
                                    nameProperty="currency"
                                    name="exchange_currency"
                                    data="currencies"
                                    basicInfoName="Currencies"
                                    onChange={onChangeHandler}
                                    value={exchangeDetail.exchange_currency}
                                    translate={{ property: "currency", prefix: "" }}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <DateAndTimePicker
                                    required
                                    disabled={addOrUpdate === "add" ? false : true}
                                    key={"init_date"}
                                    label={t("Init date")}
                                    value={exchangeDetail.init_date}
                                    fieldName={"init_date"}
                                    showTime={false}
                                    callBackFunction={(value, fieldName) => {
                                        if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                        onChangeHandler({ value: value, name: fieldName })
                                    }}
                                    id={"init_date"}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <DateAndTimePicker
                                    key={"end_date"}
                                    label={t("End date")}
                                    value={exchangeDetail.end_date}
                                    fieldName={"end_date"}
                                    showTime={false}
                                    callBackFunction={(value, fieldName) => {
                                        if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                        onChangeHandler({ value: value, name: fieldName })
                                    }}
                                    validations={
                                        {
                                            "data-parsley-validate-date-range": "init_date",
                                            "data-parsley-validate-date-range-message":
                                                t("PARSLEY_VALIDATION_DATE_RANGE")
                                        }
                                    }
                                />
                            </div>
                            <div className="col-12 ">
                                <TextField
                                    required
                                    name="value" id="data" label={t("Value")} type="text"
                                    InputProps={{
                                        inputProps: {
                                            min: 0.0001
                                        }
                                    }}
                                    value={exchangeDetail.value} onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary" onClick={handlePreSubmit}>
                                {t("Save")}
                            </button>
                            <button hidden={addOrUpdate === "add" ? true : false}
                                className="btn btn-secondary" onClick={handlePreDelete}>
                                {t("Delete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutSection>
    );
}

export default withTranslation()(ExchangesDetails);

import React, { useState, useEffect } from 'react'

const Tab = ({ renderAll = true, children, active = 0 }) => {
  const [activeTab, setActiveTab] = useState(active)
  const [tabsData, setTabsData] = useState([])




  useEffect(() => {
    let data = []
    React.Children.forEach(children, element => {
      if (!React.isValidElement(element)) return;
      const { props: { tab, children } } = element;
      data.push({ tab, children })
    })
    setTabsData(data)
  }, [children])


  return (
    <div style={{ marginTop: 10 }}>

      <ul className="nav nav-tabs">
        {tabsData.map(({ tab }, idx) => (
          <li className="nav-item" key={`tab-${idx}`}>
            <button className={`nav-link ${idx === activeTab ? "active" : ""}`} type="button" role="tab" aria-controls="tags" aria-selected={idx === activeTab ? "true" : "false"} onClick={() => { setActiveTab(idx) }}>{tab}</button>
          </li>
        ))
        }
      </ul>

      {tabsData.map((tab, idx) => {

        if (renderAll || idx === activeTab) {
          return (<div key={`tab-content-${idx}`} className="tab-content p-3" hidden={idx === activeTab ? false : true}>
            {tab.children}
          </div>)
        }
      })}

    </div>
  )
}
const TabPane = ({ children }) => { return { children } }

Tab.TabPane = TabPane;
export default Tab
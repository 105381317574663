import React from 'react'
import OperationTicket from '../../components/OperationTicket'
import utils from '../../utils';

function OperationPrint(props) {
    const data = JSON.parse(utils.getSessionItem("operationTicket_data"));
    return (
        <OperationTicket data={data} />
    )
}
export default OperationPrint

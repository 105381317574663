import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import utils from '../../utils';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import Report from '../../components/basic/Report';
import { useAlert } from '../../hooks/Alert';
import AlertMessage from '../../components/NavigationAndLayouts/AlertMessage';
import { TextField } from '@mui/material';
import AgentsMenu from '../../components/AgentsMenu';

const AgentControl = (props) => {
    const t = props.t
    const [searchString, setSearchString] = useState("");
    const [search, setSearch] = useState("All");
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()
    const dataHeader = {
        title: t('Agent Control Panel'),
        buttons: [],
        idDoc: props.idDoc
    };

    const refreshReport = () => {

        if (window['ReportResult']) window['ReportResult'].executeReport(0)
    }

    const extraData = {
        "finishOperation": refreshReport
    }

    const onChangeSearchString = (evt) => {
        const { value } = evt.target
        setSearchString(value)
    }

    const onSearch = (evt) => {
        if (window['ReportResult']) window['ReportResult'].executeReport([{ "field": "u.alias", "type": "like_end", "value": searchString }])

    }

    utils.setSessionItem("ReportFilterView-agent-control", JSON.stringify([]))
    utils.setSessionItem("ReportFilter-agent-control", JSON.stringify([{ "field": "u.alias", "type": "like_end", "value": searchString }]))

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>

                <div className="content m-2 row">
                    <div className="col-8 col-md-4">
                        <TextField className='float-start' label={t("Search") + " " + t("Alias")} name="search" type="text" value={searchString} onChange={onChangeSearchString} />
                    </div>
                    <div className="col-4  col-md-2" >
                        <button className={'btn btn-primary float-start'} onClick={onSearch}>{t('Search')}</button>
                    </div>

                    <div className="col-12 row col-md-6">
                        <div className="col-12 col-md-12" >
                            <button className={(search !== 'Players') ? 'btn btn-secondary float-end' : 'btn btn-primary float-end'} onClick={() => { setSearch('Players') }}>{t("Players")}</button>
                            <button className={(search !== 'Agents') ? 'btn btn-secondary float-end' : ' btn btn-primary float-end'} onClick={() => { setSearch('Agents') }}>{t("AGENTS")}</button>
                            <button className={(search !== 'All') ? 'btn btn-secondary float-end' : 'btn btn-primary float-end'} onClick={() => { setSearch('All') }}>{t("All")}</button>

                        </div>
                    </div>
                </div>
                {search === 'All' &&
                    <Report reportId="agent_control_all" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>}
                {search === 'Agents' &&
                    <Report reportId="agent_control_agents" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>}
                {search === 'Players' &&
                    <Report reportId="agent_control_users" windowRef="ReportResult" filterId={"agent-control"} viewFilter={'agent-control'} showAlert={showAlert} handleCloseAlert={closeAlert} limit={10} extraData={extraData}></Report>}
            </LayoutSection >
            <AgentsMenu></AgentsMenu>
            <AlertMessage key={'AgentControl_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} />
        </>
    )
}

export default withTranslation()(AgentControl)
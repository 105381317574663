import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminClient from '../../AdminClient'
import { withTranslation } from 'react-i18next';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import utils from '../../utils';

function File(props) {


    const dropEnable = props.dropEnable || false;
    const showFile = props.showFile || false;
    const showError = props.showError || false;
    const numFiles = props.numFiles || -1;
    const fileName = props.fileName || [];
    const viewerEnable = props.viewerEnable || false;
    const deleteEnable = props.deleteEnable || false;
    const showAlert = props.showAlert || alert;
    const onlyViewer = props.onlyViewer || false;
    const inputFile = props.image || props.inputFile || "";
    let inputFileExtension = props.inputFileExtension !== undefined ? props.inputFileExtension.toLowerCase() : "";
    const enableCopyUrl = props.enableCopyUrl || false;
    const onlyUpload = props.onlyUpload || false;
    const typeFiles = props.typeFiles || ["image"];
    const videoExt = props.videoExt || ['.mp4', '.MP4', '.webm']
    const imageExt = props.imageExt || ['.jpeg', '.jpg', '.png', '.webp', '.gif']
    const fileExt = props.fileExt || ['.pdf', '.txt']

    const maxSize = 20 * 1024 * 1024;  // Establecer tamaño máximo en 20 MB

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        width: '100%'
    };

    const parentImgStyle = {
        position: "relative",
        overflow: "hidden",
        width: "100%"
    }

    const fileCardsStyle = {
        textAlign: 'center',
        padding: '20px',
    }

    const { t } = props

    const style = useMemo(() => ({
        ...baseStyle
    }), []);

    const [myFiles, setMyFiles] = useState([]);

    const [fromServer, setFromServer] = useState(false);

    const videoType = { 'video/*': videoExt }
    const imageType = { 'image/*': imageExt }
    const fileType = { 'image/*': fileExt }
    let accept = {};
    if (typeFiles.length === 1) {
        switch (typeFiles[0]) {
            case "image":
                accept = imageType;
                break;
            case "video":
                accept = videoType;
                break;
            case "file":
                accept = fileType;
                break;
            default:
                console.error("No correct type files in File Component");
        }
    }
    else if (typeFiles.length < 1) {
        console.error("No type files in File Component");
    }
    else {
        for (let type of typeFiles) {
            switch (type) {
                case "image":
                    accept = { ...accept, ...imageType };
                    break;
                case "video":
                    accept = { ...accept, ...videoType };
                    break;
                case "file":
                    accept = { ...accept, ...fileType };
                    break;
                default:
                    console.error("No correct type files in File Component");
            }
        }
    }

    let dropOptions = {
        accept: accept,
        maxSize: maxSize,
        multiple: false
    }

    if (numFiles > 0) {
        dropOptions.maxFile = numFiles - myFiles.length;
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone(dropOptions);

    const removeFile = file => () => {
        const newFiles = [...myFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
        setMyFiles(newFiles);
        let urls = newFiles.map((f) => f.url)
        props.saveData(urls)

    }

    const createMyFile = () => {
        let newFiles = [];
        for (let i = 0; i < fileName.length; i++) {
            newFiles.push({
                name: fileName[i],
                url: fileName[i],
            })
        }
        setFromServer(true)

        setMyFiles([...myFiles, ...newFiles]);

    }

    const acceptedFileItems = myFiles.map((file) => (
        <div key={file.name} className="col-12 col-md-6" style={fileCardsStyle}>
            {file.name !== '' &&
                <div>
                    {imageExt.includes("." + file.url.split(".").pop().toLowerCase()) &&
                        <div id="parentImage" style={parentImgStyle}>
                            {viewerEnable ?
                                <>
                                    {imageExt.includes(file.url.substring(file.url.lastIndexOf('.')).toLowerCase()) ?
                                        <div className="viewerFile">
                                            <ReactPanZoom
                                                image={props.fileUrlPref + file.url}
                                                alt="View image"
                                            />
                                        </div>
                                        :
                                        <>
                                            {fileExt.includes(inputFileExtension) || videoExt.includes(inputFileExtension) ?
                                                <div className="viewerFile">
                                                    <iframe
                                                        src={props.fileUrlPref + file.url}
                                                        width="100%"
                                                        height="100%"
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <div className="viewerFileDownload">
                                                        <label style={{ paddingLeft: "15px" }}>{t("Preview not available") + " " + inputFileExtension}</label>
                                                        <br></br>
                                                        <a className="btn btn-secondary" id="descargarBoton" style={{ float: "left" }} href={props.fileUrlPref + file.url}>{t("Download file")}</a>
                                                    </div>
                                                </>

                                            }
                                        </>

                                    }
                                </>
                                :
                                <>
                                    <img style={{ maxWidth: "100%", minWidth: "50%", position: "block" }} hidden={showFile ? false : true} id={file.name} key={file.name} src={props.fileUrlPref + file.url}
                                        onClick={() => {
                                            if (enableCopyUrl) {
                                                navigator.clipboard.writeText(props.fileUrlPref + file.url);
                                                showAlert(t("URL"), t("URL copied"));
                                            }

                                        }}
                                    /><br></br>
                                </>
                            }
                        </div>
                    }

                    {videoExt.includes("." + file.url.split(".").pop().toLowerCase()) &&
                        <div id="parentImage" style={parentImgStyle}>

                            <video style={{ maxWidth: "100%", minWidth: "50%", position: "block" }} hidden={showFile ? false : true} id={file.name} key={file.name}
                                onClick={() => {
                                    if (enableCopyUrl) {
                                        navigator.clipboard.writeText(props.fileUrlPref + file.url);
                                        showAlert(t("URL"), t("URL copied"));
                                    }

                                }}
                                controls>
                                <source src={props.fileUrlPref + file.url} />
                            </video>
                            <br></br>

                        </div>
                    }


                    <div hidden={showFile ? true : false}>
                        Nombre: {file.name}
                    </div>
                </div>
            }
            <div>
                <button hidden={(deleteEnable) ? false : true} style={{ marginTop: '10px', marginRight: '10px' }} onClick={removeFile(file)}><FontAwesomeIcon icon="trash" /></button>
            </div>
        </div>
    ));

    useEffect(() => {
        handleUploadImageDropZone(acceptedFiles, "add");
    }, [acceptedFiles]);


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));


    const handleUploadImageDropZone = (files, type) => {
        if (files !== null) {
            let adminClient = new AdminClient();

            const session = JSON.parse(utils.getSessionItem("user")).session;
            const company = utils.getSessionItem("Company");

            for (let file of files) {
                let promise = adminClient.uploadFile(session, props.type, company, props.owner, file, file.name);

                promise.then(function (data) {
                    if (data.result === "OK") {

                        let urls = myFiles.map((f) => f.url)
                        let urlFile = props.getFileUrl(data);
                        urls.push(urlFile)
                        props.saveData(urls)

                        if (!onlyUpload) {
                            let myFile = [{
                                name: file.path,
                                url: urlFile
                            }];
                            setMyFiles([...myFiles, ...myFile]);
                        }

                        if (!props.disabledUpdatedPopup) showAlert("OK", t("Uploaded succesfull"));
                    }
                }, function (err) {
                    showAlert("Error", t("Uploaded error") + " : " + err.description);
                })

            }

        } else {
            showAlert("Error", t("Uploaded error") + t("No file"));
        }
    }


    return (

        <div>
            {onlyViewer && inputFileExtension !== undefined ?
                <>
                    {imageExt.includes(inputFileExtension) ?
                        <div className="viewerFile">
                            <ReactPanZoom
                                image={inputFile}
                                alt="View image"
                            />
                        </div>
                        :
                        <>
                            {fileExt.includes(inputFileExtension) || videoExt.includes(inputFileExtension) ?
                                <div className="viewerFile">
                                    <iframe
                                        src={inputFile}
                                        width="100%"
                                        height="100%"
                                        style={{ width: "100%" }}
                                    />
                                </div>

                                :
                                <>
                                    <div className="viewerFileDownload">
                                        <label style={{ paddingLeft: "15px" }}>{t("Preview not available") + " ( " + inputFileExtension + " )"}</label>
                                        <br></br>
                                        <a className="btn btn-secondary" id="descargarBoton" style={{ float: "left" }} href={inputFile}>{t("Download file")}</a>
                                    </div>
                                </>

                            }
                        </>

                    }
                </>
                :
                <>
                    <div className="row">
                        {(!fromServer && fileName[0] !== undefined && myFiles.length === 0) &&
                            <>
                                {fileName[0] !== '' &&
                                    createMyFile()
                                }
                            </>

                        }
                        {acceptedFileItems}
                    </div>
                    {(numFiles === -1 || numFiles > myFiles.length) &&
                        <div>
                            {dropEnable &&
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <p>{t("Drop explanation")}</p>
                                </div>

                            }
                        </div>
                    }

                    {showError &&
                        <div>
                            {fileRejectionItems.length > 0 &&
                                <div>
                                    <h4>{t("Files with error")}</h4>
                                    {fileRejectionItems}
                                </div>
                            }
                        </div>
                    }
                </>
            }

        </div >
    );
}

export default withTranslation()(File)

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { TextField } from '@mui/material';
import ProgressBar from '../../components/basic/ProgressBar';
import useFormValidation from '../../hooks/parsleyValidation';
import AdminClient from '../../AdminClient';
import utils from "../../utils";
import BasicSelect from '../../components/basic/BasicSelect';

const BatchDepositUpdate = (props) => {
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_batch_deposit_confirmation')

    const [batchDetails, setBatchDetails] = useState({
        comments: "",
        operations: "",
        next_status: 'SUCCESS'
    })
    const [progressBar, setProgressBar] = useState({
        show: false,
        percentage: '0%',
        textProcess: t('Processing'),
        inProgress: false
    })

    const goBack = () => {
        props.history.goBack();
    }
    const dataHeader = {
        backLink: goBack,
        title: t('Batch deposit update'),
        urlHelp: '',
        idDoc: props.idDoc
    }
    const onChangeHandler = (evt) => {
        const { name, value } = evt.target || evt;
        setBatchDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        setProgressBar({
            show: true,
            percentage: percentage + '%',
            textProcess: t('Processing') + " " + data.partial + "/" + data.total
        })
    }
    const send = async () => {
        if (formValidation.validate()) {
            setProgressBar({
                show: true,
                percentage: '0%',
                textProcess: t('Processing Deposits'),
                inProgress: true
            })

            adminClient.BatchDepositUpdate(
                JSON.parse(utils.getSessionItem("user")).session,
                utils.getSessionItem("Company"),
                JSON.stringify(batchDetails.operations.split("\n")),
                batchDetails.comments,
                batchDetails.next_status,
                handleResponse
            ).then(
                function (msg) {
                    setProgressBar({
                        show: true,
                        percentage: '100%',
                        textProcess: t('Process Executed'),
                        textOK: t("Operations correct") + ":" + msg.operationsCorrect,
                        textNOK: t("Operations errors") + ":" + msg.operationsErrors,
                        inProgress: false
                    })
                    if (msg.result === 'OK') {
                        props.showAlert(t("Batch deposit update"), t("Process Executed"))
                    } else {
                        props.showAlert(t("Batch deposit update"), t("Processing Error") + " " + msg.description)
                    }
                },
                function (err) {
                    setProgressBar((prevState) => ({
                        ...prevState,
                        inProgress: false
                    }));
                    props.showAlert(t("Batch deposit update"), t("Processing Error") + err.description)
                });
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_batch_deposit_confirmation">

                <div className="row">
                    <div className="col-12">
                        <BasicSelect
                            idProperty='status'
                            nameProperty='status'
                            data='status'
                            basicInfoName='DepositsStatus'
                            value={batchDetails.next_status}
                            name='next_status'
                            label={t('New Status')}
                            onChange={onChangeHandler}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={true}
                            translate={{ property: "status", prefix: "DEPOSIT_STATUS_" }}
                        />
                    </div>

                    <div className="col-12">
                        <TextField multiline placeholder={t("Comments")} name="comments" label={t("Comments")} type="text" onChange={onChangeHandler} value={batchDetails.comments} />
                    </div>

                    <div className="col-12">
                        <TextField multiline placeholder="db.operation1&#10;db.operation2&#10;db.operation3" label={t("Operations list")}
                            name="operations" type="text" onChange={onChangeHandler} value={batchDetails.operations} required />
                    </div>

                </div>
            </form>

            <div className="row">
                <div className="col-12">
                    <button className="btn btn-primary" disabled={progressBar.inProgress} onClick={send}>{t("Execute")}</button>
                    {progressBar.show &&
                        <ProgressBar percentage={progressBar.percentage} text={progressBar.textProcess} textOK={progressBar.textOK} textNOK={progressBar.textNOK} />
                    }

                </div>
            </div>
        </LayoutSection>
    );
};
export default withTranslation()(BatchDepositUpdate)

import React from "react"
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { withTranslation } from "react-i18next";

class UserInput extends React.Component {

  render() {
    const { t } = this.props;

    return(<Link to={"/support/user_details"} >  
      <TextField disabled label={t("User")} type="text" value={this.props.alias + "(" + this.props.user + ")"} />
      </Link>
     )}

}
export default withTranslation()(UserInput)


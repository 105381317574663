import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import useFormValidation from "../../hooks/parsleyValidation";
import * as utilsBasicInfo from '../../libs/utilsBasicInfo'
import BasicSelect from "../basic/BasicSelect";
import FlowReactActions from "./FlowReactActions";
import AlertMessage from "../NavigationAndLayouts/AlertMessage";
import { useAlert } from "../../hooks/Alert";
import TreeSelector from "../basic/TreeSelector";
import AdminClient from "../../AdminClient";
import utils from '../../utils';



const FlowReactDetails = (props) => {
    const { t } = props
    const { formValidation } = useFormValidation('Form_ReactDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [reactDetails, setReactDetails] = useState({ react: '', name: '', type: 'BASIC_TIMER', actions: [] })
    const [configReactTypes, setConfigReactTypes] = useState()
    const [machinesTree, setMachinesTree] = useState([])

    const session = JSON.parse(utils.getSessionItem("user")).session;
    const company = utils.getCurrentCompany().company;

    let adminClient = new AdminClient();


    const [selected, alertOpen, showAlert, closeAlert] = useAlert()


    const cleanGenerateFieldsValues = (react) => {
        return {
            react: react.react,
            name: react.name,
            type: react.type
        }

    }
    const handleReact = (evt) => {
        const { name, value } = evt.target || evt;
        const deep = name.split(".");
        let newState = { ...reactDetails };
        let oldType = newState['type']
        let currentObj = newState;

        for (let i = 0; i < deep.length - 1; i++) {
            if (!currentObj.hasOwnProperty(deep[i])) {
                currentObj[deep[i]] = {};
            }
            currentObj = currentObj[deep[i]];
        }
        currentObj[deep[deep.length - 1]] = value;

        if (oldType !== newState['type']) {
            newState = cleanGenerateFieldsValues(newState)
        }
        setReactDetails(newState);
    };

    const handleCheck = (evt) => {
        const { name, checked } = evt.target || evt;
        setReactDetails((prevState) => {
            prevState[name] = (checked) ? "true" : "false";
            return { ...prevState };
        });
    }

    const onUpdateReactActions = (react, actions) => {
        let reacts = { ...reactDetails }
        reacts['actions'] = actions
        setReactDetails(reacts)
    }

    const saveReact = () => {
        if (formValidation.validate()) {
            props.onUpdateReacts(reactDetails)
            if (addOrUpdate === 'add') {
                setAddOrUpdate('update')
            }
            else props.handleCloseAlert()
        }
    }

    const getFilters = (filtersData) => {
        return filtersData.map((f) => {
            if (f.props !== undefined) f.value = props[f.props]
            if (f.field !== undefined) f.value = reactDetails[f.field]
            return f
        })
    }

    const generateReactFields = () => {
        let configReact = configReactTypes[reactDetails.type]
        return Object.keys(configReact).map((fieldName) => {
            let field = configReact[fieldName]
            switch (field.type) {
                case "currency":
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>
                                }}
                                name={fieldName}
                                value={reactDetails[fieldName] === undefined ? 0 : reactDetails[fieldName] / 100}
                                onChange={(evt) => { handleReact({ name: fieldName, value: evt.target.value * 100 }) }}
                            />
                        </div>
                    );
                case "integer":
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                type="number"
                                name={fieldName}
                                value={reactDetails[fieldName] === undefined ? '' : reactDetails[fieldName]}
                                onChange={handleReact}
                            />
                        </div>
                    );
                case "boolean":
                    return (
                        <div className="col-12 col-sm-2">
                            <FormControlLabel control={<Checkbox
                                onChange={handleCheck}
                                name={fieldName}
                                checked={(reactDetails[fieldName] === "false") ? false : true} />}
                                label={t(field.label)} />
                        </div>
                    );
                case "machinesTree":
                    return (<>{machinesTree.length > 0 &&
                        <div className="col-12 col-sm-12">
                            <TreeSelector
                                label={t('Machine')}
                                nodes={machinesTree}
                                checked={reactDetails[fieldName] || []}
                                typeTree="machine"
                                onCheck={(machines) => {
                                    handleReact({ name: fieldName, value: machines })
                                }}
                            ></TreeSelector>
                        </div>
                    }
                    </>)
                case "Select": return (<>
                    <div className="col-12 col-sm-6">
                        <BasicSelect key={reactDetails.type + "_" + fieldName} label={t(field.label)}
                            value={reactDetails[fieldName]}
                            name={fieldName}
                            onChange={handleReact}
                            idProperty={field.idProperty}
                            nameProperty={field.nameProperty || undefined}
                            basicInfoName={field.basicInfoName}
                            filters={getFilters(field.filters || [])}
                            params={field.params || []}
                            data={field.data || undefined}
                            translate={field.translate || undefined}
                            disableClearable={field.disableClearable || undefined}
                        />
                    </div>
                </>)
                default:
                    return (
                        <div className="col-12 col-sm-4">
                            <TextField
                                label={t(field.label)}
                                name={fieldName}
                                type="text"
                                value={reactDetails[fieldName]}
                                onChange={handleReact}
                            />
                        </div>
                    );
            }
        })
    }

    useEffect(() => {
        if (props.react !== undefined) {
            setReactDetails(props.react)
            setAddOrUpdate('update')
        }
    }, [props.react])

    useEffect(async () => {
        let configRT = await utilsBasicInfo.getBasicInfo("FlowsReacts")
        let machines = await adminClient.getMachines(session, company)
        let machinesTree = []
        machines.machines.forEach(element => {
            machinesTree[element.machine] = element;
        });
        let configTypes = {}
        configRT.forEach((element) => {
            configTypes[element.react] = JSON.parse(element.config)
        })
        setConfigReactTypes(configTypes)
        setMachinesTree(machinesTree)
    }, []);

    return (<>
        <form id="Form_ReactDetails">
            <div className="row">
                <div className="col-12 col-md-2">
                    <TextField
                        label={t('ID')}
                        name="react"
                        required
                        disabled={addOrUpdate === 'update'}
                        InputProps={addOrUpdate === 'add' ? {
                            inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                        } : {}}
                        type="text"
                        value={reactDetails.react}
                        onChange={handleReact}
                    />
                </div>
                <div className="col-12  col-md-8 ">
                    <TextField
                        label={t("Name")}
                        type="text"
                        name="name"
                        required
                        value={reactDetails.name}
                        onChange={handleReact} />
                </div>
                <div className="col-12 col-sm-2">
                    <BasicSelect
                        idProperty="react"
                        name="type"
                        label={t("Type")}
                        basicInfoName="FlowsReacts"
                        onChange={handleReact}
                        value={reactDetails.type}
                        disableClearable={true}

                    />
                </div>
            </div>
            <div className="row">
                {configReactTypes && generateReactFields()}
            </div>
        </form>
        <div className="row">
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={saveReact}>{t("Save")}</button>
                <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
            </div>
        </div>
        {addOrUpdate === 'update' && <>
            <label><strong>{t('Actions')}</strong></label>
            <FlowReactActions showAlert={showAlert} flow={props.flow} closeAlert={closeAlert} status={props.status} react={reactDetails.react} actions={reactDetails.actions} onUpdateReactActions={onUpdateReactActions} />
            <AlertMessage key={'FlowStatusReact_alert'} isActive={alertOpen} size={selected.size} content={selected.content} title={selected.title} buttons={selected.buttons} handleCloseAlert={closeAlert} /></>}
        <div className="row">
            <div className="col-12">
                <button type="button" className="btn btn-primary" onClick={saveReact}>{t("Save")}</button>
                <button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Close")}</button>
            </div>
        </div>

    </>)




}
export default withTranslation()(FlowReactDetails)
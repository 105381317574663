import React, { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import BasicSelect from "../../components/basic/BasicSelect";
import { TextField } from "@mui/material";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import useFormValidation from "../../hooks/parsleyValidation";

const UserManualDeposit = (props) => {

    const { t } = props
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_UserDeposits')
    const [idempotenceDefault, setIdempotenceDefault] = useState(utils.generateUID())

    const user = JSON.parse(utils.getSessionItem("userDetails")).user;
    const company = utils.getCurrentCompany().company;
    const companyDetails = utils.getCurrentCompany(company);
    const [blockDeposit, setBlockDeposit] = useState(false)
    const [deposit, setDeposit] = useState({
        amount: utils.formatNumber(10, 2),
        method: "",
        comments: "",
        status: 'SUCCESS',
        idempotence: '',
        required_idempotence: false,
        bankInfo: false
    });
    const options = [
        { label: t('SUCCESS'), value: 'SUCCESS' }
    ];
    const [methodsList, setMethodsList] = useState([])

    const adminClient = new AdminClient()

    useEffect(() => {
        adminClient.depositMethods(session, company, user.db, user.user).then(
            function (msg) {
                let methodsListAux = [];
                msg.data.forEach(element => {
                    methodsListAux.push({ label: element.name, value: element.method, required_idempotence: element.required_idempotence, bankInfo: element.bankInfo });
                });
                setMethodsList(methodsListAux)
            }, function (err) {
                console.log("Deposit deposit error " + err.code);
            }
        );

    }, [])

    const onChangeHandler = (e) => {
        const { name, value, objSelection } = e.target || e;
        let requiredIdempotence = deposit.required_idempotence;
        let bankInfo = deposit.bankInfo;
        if (name === 'method') {
            if (objSelection.required_idempotence !== undefined && objSelection.required_idempotence) {
                requiredIdempotence = true
            } else requiredIdempotence = false
            if (objSelection.bankInfo !== undefined && objSelection.bankInfo) {
                bankInfo = true
            } else bankInfo = false
        }
        setDeposit((prevState) => ({ ...prevState, [name]: value, ['required_idempotence']: requiredIdempotence, ['bankInfo']: bankInfo }));
    };

    const save = () => {
        if (formValidation.validate()) {
            setBlockDeposit(true)
            let idempotenceData = idempotenceDefault
            if (deposit.required_idempotence) idempotenceData = deposit.idempotence

            adminClient.manualDeposit(
                session, company, user.db, user.user,
                deposit.method, deposit.amount * 100,
                deposit.status, deposit.comments, idempotenceData, deposit.bank).then(
                    function (msg) {
                        props.showAlert(t("Manual deposit title"), t("Manual deposit ok explanation"));
                        setIdempotenceDefault(utils.generateUID())
                        goBack()

                    }, function (err) {
                        setBlockDeposit(false)
                        if (err.code !== -34) {
                            props.showAlert(
                                t("Manual deposit title"),
                                t("Saving error") + " " + t("ERROR_" + err.code)
                            )
                        };
                    }.bind(this)
                );
        }
    }
    const goBack = () => {
        props.history.push("/support/user_accounts");
    };
    const dataHeader = {
        backLink: goBack,
        title: t("Manual deposit title"),
        urlHelp: "",
        idDoc: props.idDoc
    };
    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserDeposits">

                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField id="amount" label={t("Amount")} type="number" name="amount" required value={deposit.amount} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            idProperty='value'
                            nameProperty='label'
                            name="method"
                            value={deposit.method}
                            label={t('Method')}
                            onChange={onChangeHandler}
                            addOpts={methodsList}
                            translate={{ property: 'label', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                        <BasicSelect
                            idProperty='value'
                            nameProperty='label'
                            value={deposit.status}
                            label={t('Status')}
                            onChange={onChangeHandler}
                            addOpts={options}
                            translate={{ property: 'label', prefix: '' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={true}
                        />

                    </div>
                    {deposit.required_idempotence &&
                        <div className="col-12 col-md-6">
                            <TextField required={deposit.required_idempotence} label={t("Idempotence")} type="text" name="idempotence" value={deposit.idempotence} onChange={onChangeHandler} />
                        </div>}
                    {deposit.bankInfo && <div className="col-12 col-md-6 col-xl-4">
                        <BasicSelect
                            idProperty='bank'
                            nameProperty='popular_name'
                            value={deposit.bank}
                            name="bank"
                            label={t('Bank')}
                            onChange={onChangeHandler}
                            basicInfoName='Banks'
                            filters={[{ property: "deposit", value: 1 }]}
                        />

                    </div>}
                    <div className="col-12 col-md-12">
                        <TextField multiline label={t("Comment")} type="text" name="comments" value={deposit.comments} onChange={onChangeHandler} />
                    </div>

                </div >
            </form>
            <div className="row">
                <button className="btn btn-primary float-end" disabled={blockDeposit} onClick={save}>{t("Do deposit")}</button>

            </div>
        </LayoutSection>
    </>
    )
}

export default withTranslation()(withRouter(UserManualDeposit))
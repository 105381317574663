import React, { useEffect, useMemo, useState } from 'react'
import AdminClient from '../AdminClient'
import BasicTable from '../components/basic/BasicTable'
import utils from '../utils';
import { FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import TextHTMLRender from './renders/TextHTMLRender';


const AdminNotifications = (props) => {

    const adminClient = new AdminClient()
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const [notifications, setNotifications] = useState([])
    const isMobile = useMediaQuery('(max-width: 1300px)')
    utils.setSessionItem('showAdminNotifications', false)

    const { t } = props


    const columnsDef =
        (isMobile) ? [

            {
                Header: t("Content"), accessor: 'content', typeCell: 'text'
            }

        ] : [

            {
                Header: t("Created date"), accessor: 'created_date', typeCell: 'time'
            },
            {
                Header: t("Title"), accessor: 'title', typeCell: 'text'
            },

            {
                Header: t("Content"), accessor: 'content', typeCell: 'text', Cell: (props) => {
                    return <TextHTMLRender value={props.row.original.content} />
                }
            }
            , {
                Header: t("Mark as read"), accesor: "", typeCell: 'text', Cell: (props) => {

                    return <Switch size="small" onClick={(evt) => {
                        adminClient.updateStatusAdminNotification(session, company, props.row.original.notification).then((result) => {
                            setNotifications(result.data)
                        })
                    }} />
                }
            }

        ]

    useEffect(() => {


        adminClient.getAdminNotifications(session).then((result) => {

            setNotifications(result.data)
        }, (err) => {

        })
    }, [])


    return (<>
        {notifications.length > 0 && <>


            <FormControlLabel control={<Switch onClick={(evt) => {
                adminClient.updateStatusAdminNotification(session, company, '').then((result) => {
                    setNotifications(result.data)
                })
            }} />} label={t("Mark all as read")} />
            <BasicTable data={notifications} columns={columnsDef} hidePagination={isMobile ? true : undefined} hideHeader={isMobile ? true : undefined} />
        </>
        }
        {notifications.length === 0 &&
            <span>{t("No existen Notificaciones")}</span>
        }
    </>
    )
}

export default AdminNotifications
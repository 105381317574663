import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import EditorJSON from '../../components/basic/EditorJSON';



function ServicesDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_ServicesDetails')
    const serviceId = utils.getSessionItem('current-service')
    const [JSONErrors, setJSONErrors] = useState(false);

    const [serviceDetail, setServiceDetail] = useState({
        service: serviceId,
        config: null
    });
    const goBack = () => {
        props.history.push('/reports/report/services/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Service details'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    useEffect(function () {


        if (serviceId !== undefined && serviceId !== '-1') {
            adminClient.getServiceDetails(session, company, serviceId).then(
                async function (result) {
                    let service = result.data;
                    service.config = JSON.parse(service.config);
                    setServiceDetail(service);
                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);

    const onChangeHandlerJSON = (e) => {
        setServiceDetail((prevState) => ({ ...prevState, config: e }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            if (JSONErrors) {
                props.showAlert(t('Provider details'), t('JSON format incorrect'))
            }
            else {
                adminClient.saveService(
                    session,
                    company,
                    JSON.stringify(serviceDetail)
                ).then(
                    (msg) => {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Service details'), t('Saved succesfull'))
                        } else {
                            props.showAlert(t('Service details'), t('Saving error ') + msg.description)
                        }
                    },
                    (error) => {
                        props.showAlert(t('Service details'), t('Saving error ') + error.description)
                    }
                )
            }
        }
    }

    const reload = (evt) => {
        if (formValidation.validate()) {
            adminClient.reloadService(
                session,
                company,
                serviceDetail.service
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Service details'), t('Reloaded succesfull'))
                    } else {
                        props.showAlert(t('Service details'), t('Reloading error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Service details'), t('Reloading error ') + error.description)
                }
            )
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_ServicesDetails">
                <div className="row">
                    <div className="col-12">
                        <TextField
                            label={t('Service')}
                            disabled
                            type="text"
                            value={serviceDetail.service}
                        />
                    </div>
                    <div className="col-12">
                        <EditorJSON
                            setError={(status) => { setJSONErrors(status) }}
                            onChange={onChangeHandlerJSON}
                            value={serviceDetail.config}
                            name={t("Configuration")}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted('SAVE_SERVICES') &&

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={reload}
                    >
                        {t('Reload')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(ServicesDetails);
import React from 'react';
import { withTranslation } from 'react-i18next';
import DocChat from '../../components/basic/DocChat';
import { useAlert } from '../../hooks/Alert';


function ChatCalia(props) {
    const { t } = props;
    const [showAlert, closeAlert] = useAlert()

    return (

        <div className="row">
            <div className="col-12">
                <DocChat document_key='HCD' context='' recommendations='[]' showAlert={showAlert} closeAlert={closeAlert}></DocChat>
            </div>
        </div>

    )
}
export default withTranslation()(ChatCalia);
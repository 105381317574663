import React, { useEffect, useState } from 'react'
import utils from '../../utils';
import { withTranslation } from 'react-i18next'
import AdminClient from '../../AdminClient'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import { Checkbox, FormControlLabel, InputAdornment, InputLabel, TextField } from '@mui/material';
import Tab from '../../components/basic/BasicTabs';
// import SelectMachinesTags from '../../components/SelectMachinesTags';
import AllowedForbiddenList from '../../components/basic/AllowedForbiddenList';
import File from '../../components/basic/File';
import BasicSelect from '../../components/basic/BasicSelect'
import { getBasicInfo } from '../../libs/utilsBasicInfo';

function MachineDetailsI18n(props) {

  const { i18n } = props.machine

  const [machineDetailsI18n, setMachineDetailsI18n] = useState({
    logo: '',
    description: '',
    name: '',
    webName: '',
    background: ''
  })
  const { t, errorMessage } = props
  const session = JSON.parse(utils.getSessionItem("user")).session
  const company = utils.getCurrentCompany();
  useEffect(() => {
    let data = {
      logo: (i18n[props.lang] && i18n[props.lang].logo) ? i18n[props.lang].logo : '',
      description: (i18n[props.lang] && i18n[props.lang].description) ? i18n[props.lang].description : '',
      name: (i18n[props.lang] && i18n[props.lang].name) ? i18n[props.lang].name : '',
      webName: (i18n[props.lang] && i18n[props.lang].webName) ? i18n[props.lang].webName : '',
      background: (i18n[props.lang] && i18n[props.lang].background) ? i18n[props.lang].background : ''
    }
    setMachineDetailsI18n(data);

  }, [])

  const onChangeHandler = (evt) => {
    const { name, value } = evt.target
    let field = name.split("_")
    setMachineDetailsI18n((prevState) => ({ ...prevState, [field[0]]: value }));
    props.onChange(field[1], field[0], value)
  }

  const saveDataMachine = (urls, type) => {
    let machineDetails = { ...machineDetailsI18n }
    if (urls.length === 0) machineDetails[type] = "";
    else machineDetails[type] = urls[0];
    setMachineDetailsI18n(machineDetails);
    props.onChange(props.lang, type, machineDetails[type])
  }

  return (
    <>
      <div id={`Details_${props.lang}`} className="row">
        <div className="col-12 col-md-4">
          <TextField id={`name_${props.lang}`} label={t("Name")} name={`name_${props.lang}`} type="text" value={machineDetailsI18n.name} onChange={onChangeHandler}
            error={errorMessage && machineDetailsI18n.name === ""}
            helperText={(errorMessage && machineDetailsI18n.name) === "" ? t('Required') : ' '}
          />
        </div>
        <div className="col-12 col-md-4">
          <TextField id={`webName_${props.lang}`} label={t("Web Name")} name={`webName_${props.lang}`} type="text" value={machineDetailsI18n.webName} onChange={onChangeHandler}
            error={errorMessage && machineDetailsI18n.webName === ""}
            helperText={(errorMessage && machineDetailsI18n.webName) === "" ? t('Required') : ' '}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6  ">
          <InputLabel className={`label-border-label_logo_${props.lang}`} id={`cms_summary_image-label_logo_${props.lang}`}>{t("Logo")}</InputLabel>
          <div className="mb-3">
            <File
              dropEnable={true}
              showFile={true}
              showError={true}
              numFiles={1}
              viewerEnable={false}
              deleteEnable={true}
              saveData={(urls) => { saveDataMachine(urls, 'logo') }}
              fileName={[machineDetailsI18n.logo]}
              fileUrlPref={company.config.cdn_url + "../"}
              getFileUrl={(data) => {
                return "/cms/img/logos/" + props.machine.provider + "/" + props.machine.machine + "/" + data.fileName;
              }}
              type={'MACHINE'}
              owner={JSON.stringify(props.machine)}
              showAlert={props.showAlert}
            />
          </div>
        </div>
        <div className="col-12 col-md-6  ">
          <InputLabel className={`label-border-label_background_${props.lang}`} id={`cms_summary_image-label_background_${props.lang}`}>{t("Background")}</InputLabel>
          <div className="mb-3">
            <File
              dropEnable={true}
              showFile={true}
              showError={true}
              numFiles={1}
              editEnable={false}
              deleteEnable={true}
              saveData={(urls) => { saveDataMachine(urls, "background") }}
              fileName={[machineDetailsI18n.background]}
              fileUrlPref={company.config.cdn_url + "../"}
              getFileUrl={(data) => {
                return "/cms/img/logos/" + props.machine.provider + "/" + props.machine.machine + "/" + data.fileName;
              }}
              type={'MACHINE'}
              owner={JSON.stringify(props.machine)}
              showAlert={props.showAlert}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField id={`description_${props.lang}`} label={t("Description")} name={`description_${props.lang}`} multiline value={machineDetailsI18n.description} onChange={onChangeHandler} />
        </div>
      </div>
    </>
  )
}

function MachineDetails(props) {


  const company = utils.getCurrentCompany();
  const getInitI18n = () => {
    let langs = company.langs.split(',')
    let indexDefLang = langs.indexOf(company.default_lang);
    langs.splice(indexDefLang, 1);
    langs.splice(0, 0, company.default_lang);
    let initMachineI18Values = { logo: '', description: '', name: '', webName: '', background: '' }
    let i18n = {}
    for (let i = 0; i < langs.length; i++) {
      i18n[langs[i]] = initMachineI18Values;

    }
    return i18n;
  }
  //Inicializacion
  const initMachine = { machine: -1, provider: '', sub_provider: '', type: 'SLOT', external_id: '', external_launcher_id: "", name: '', rtp: null, demoAllowed: 0, allow_freespins: 0, status: 'ENABLED', set: '', regulation_code: '', i18n: getInitI18n() }
  const initMachineRelations = { play_groups_availables: [], play_groups_allowed: [], play_groups_forbidden: [], view_groups_availables: [], view_groups_allowed: [], view_groups_forbidden: [], tagsChecked: [], groupAutoExclusionVertical: '' }

  const [promotions, setPromotions] = useState([]);
  const [promotionsEnabled, setPromotionsEnabled] = useState([])
  const [grantsLoad, setGrantsLoad] = useState(false)

  const machineId = Number(utils.getSessionItem("current-machine"));
  const session = JSON.parse(utils.getSessionItem("user")).session
  //Estado Pantalla
  const [machine, setMachine] = useState(initMachine)
  const [machineRelations, setMachineRelations] = useState(initMachineRelations)
  const [oldGroupAutoExclusionVertical, setOldGroupAutoExclusionVertical] = useState(initMachine.type);

  //traducciones
  const { t } = props

  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageLang, setErrorMessageLang] = useState({});

  const validate = () => {

    let isCorrect = true;
    if (machine.name === undefined || machine.name === '') {
      isCorrect = false;
    }
    if (machine.external_id === undefined || machine.external_id === '') {
      isCorrect = false;
    }
    if (machine.machine !== -1) {
      let errorValue = {};
      let machineI18n = machine.i18n;
      for (const [key, value] of Object.entries(machineI18n)) {
        errorValue[key] = false;
        if (value.name === undefined || value.name === '') {
          errorValue[key] = true;
          isCorrect = false;
        }
        if (value.webName === undefined || value.webName === '') {
          errorValue[key] = true;
          isCorrect = false;
        }
      }
      setErrorMessageLang(errorValue);
    }
    setErrorMessage(!isCorrect);
    return isCorrect;
  }

  //Manejadores de cambio
  const onChangeHandler = (e) => {
    if (e.target === undefined) {
      let { name, value } = e;
      if (value !== undefined && value !== null) value = value.trim()
      setMachine((prevState) => ({ ...prevState, [name]: value }));
    }
    else {
      let { name, value } = e.target;
      if (value !== undefined && value !== null) value = value.trim()
      setMachine((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const onChangeMachineTypeHandler = (e) => {
    const { name, value, objSelection } = e || e.target;
    setMachine((prevState) => ({ ...prevState, [name]: value, ['groupAutoExclusionVertical']: objSelection.autoexclusion_group }));
  };


  const handleTagUpdate = (evt) => {
    setMachineRelations((prevState) => ({ ...prevState, tagsChecked: evt.value }))
  }
  const updateGroupsPlay = (allowed, forbidden, availables) => {
    setMachineRelations((prevState) => ({ ...prevState, play_groups_allowed: allowed, play_groups_forbidden: forbidden, play_groups_availables: availables }))

  }
  const updateGroupsView = (allowed, forbidden, availables) => {
    setMachineRelations((prevState) => ({ ...prevState, view_groups_allowed: allowed, view_groups_forbidden: forbidden, view_groups_availables: availables }))
  }
  const updateI18n = (lang, name, value) => {

    setMachine((prevState) => {
      let machine = { ...prevState }
      if (machine['i18n'][lang] === undefined) machine['i18n'][lang] = {}
      machine['i18n'][lang][name] = value
      return machine;
    })
  }

  const onChangeHandlerRTP = (evt) => {
    setMachine((prevState) => ({ ...prevState, rtp: (evt.target.value === '') ? null : evt.target.value }))
  }

  const handleAllowedDemoEnabled = (evt) => {
    setMachine((prevState) => ({ ...prevState, demoAllowed: (evt.target.checked) ? 1 : 0 }))
  }

  const handleStatus = (evt) => {
    setMachine((prevState) => ({ ...prevState, status: (evt.target.checked) ? 'ENABLED' : 'DISABLED' }))
  }

  const handleGamingSession = (evt) => {
    setMachine((prevState) => ({ ...prevState, gaming_session_required: (evt.target.checked) ? 1 : 0 }))
  }

  const handleIsLive = (evt) => {
    setMachine((prevState) => ({ ...prevState, is_live: (evt.target.checked) ? 1 : 0 }))
  }

  const handleAllowFreespins = (evt) => {
    setMachine((prevState) => ({ ...prevState, allow_freespins: (evt.target.checked) ? 1 : 0 }))
  }
  //Carga de datos
  useEffect(() => {

    const machineId = Number(utils.getSessionItem("current-machine"));
    const company = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem("user")).session

    if (machineId !== 0 && machineId !== -1) {

      let adminClient = new AdminClient()
      Promise.all([
        adminClient.getMachineDetails(session, company.company, machineId)
      ]).then(
        async (result) => {

          let machineEdit = result[0].data;
          let machineEditRelations = {}

          let view_groups_allowed = []
          let view_groups_forbidden = []

          result[0].groups.forEach(element => {
            if (element.relationship === 'ALLOWED') {
              view_groups_allowed.push(element);
            } else {
              view_groups_forbidden.push(element);
            }
          });
          machineEditRelations['view_groups_availables'] = result[0].groups_availables
          machineEditRelations['view_groups_allowed'] = view_groups_allowed
          machineEditRelations['view_groups_forbidden'] = view_groups_forbidden

          let play_groups_allowed = []
          let play_groups_forbidden = []
          result[0].play_groups.forEach(element => {
            if (element.relationship === 'ALLOWED') {
              play_groups_allowed.push(element);
            } else {
              play_groups_forbidden.push(element);
            }
          });
          machineEditRelations['play_groups_availables'] = result[0].play_groups_availables
          machineEditRelations['play_groups_allowed'] = play_groups_allowed
          machineEditRelations['play_groups_forbidden'] = play_groups_forbidden

          machineEditRelations['tagsChecked'] = result[0].tags.map((x) => x.tag)

          let machinesTypes = await getBasicInfo('MachinesTypes')
          machinesTypes.filter((element) => {
            if (element.type === machineEdit.type) {
              machineEdit['groupAutoExclusionVertical'] = element.autoexclusion_group
              return true
            }
          })


          setGrantsLoad(machineEditRelations['view_groups_allowed'].length > 0 || machineEditRelations['play_groups_allowed'].length > 0);

          setMachine(machineEdit)
          setMachineRelations(machineEditRelations)
          setOldGroupAutoExclusionVertical(machineEdit['groupAutoExclusionVertical'])
        },
        (err) => {
          console.error(err)
        }
      )
    }
  }, [machineId]);

  useEffect(() => {
    if (machine !== initMachine) {
      let adminClient = new AdminClient()
      adminClient.getPromotionsMachine(session, company.company, machine.provider).then(
        (result) => {

          setPromotionsEnabled(result.data)
        },
        (err) => {
          console.error(err)
        }
      )
    }
  }, [machine])

  const goBack = () => {
    props.history.push('/reports/report/machines/table')
  }

  const addAllowedVerticalGroup = (type, groupAutoexclusionVertical, relations) => {

    //Buscamos si esta en los disponibles y removemos
    let foundedGroup = null;
    relations[type + '_groups_availables'] = relations[type + '_groups_availables'].filter(group => {
      if (group.group === groupAutoexclusionVertical) {
        foundedGroup = group;
        return false;
      }
      return true;
    });
    if (foundedGroup === null) {
      //Buscamos en los allowed y removemos
      relations[type + '_groups_allowed'] = relations[type + '_groups_allowed'].filter(group => {
        if (group.group === groupAutoexclusionVertical) {
          foundedGroup = group;
          return false;
        }
        return true;
      });
    }
    // si es correcto el grupo configurado añadimos a los forbidden
    if (foundedGroup !== null) relations[type + '_groups_forbidden'].push(foundedGroup)
    return relations

  }
  function removeAllowedVerticalGroup(type, groupAutoexclusionVertical, relations) {
    let foundedGroup = null;

    relations[type + '_groups_forbidden'] = relations[type + '_groups_forbidden'].filter(group => {
      if (group.group === groupAutoexclusionVertical) {
        foundedGroup = group;
        return false;
      }
      return true;
    });
    if (foundedGroup !== null) relations[type + '_groups_availables'].push(foundedGroup)
    return relations
  }

  const checkAllowedVerticalGroup = () => {
    if (validate()) {
      let messageRemove = false
      let messageAdd = false
      //Comprobamos si ha cambiado el tipo para retirar el grupo de autoexclusion del vertical
      let relations = JSON.parse(JSON.stringify(machineRelations))
      if (machine.groupAutoExclusionVertical != oldGroupAutoExclusionVertical && oldGroupAutoExclusionVertical !== null && oldGroupAutoExclusionVertical !== '') {
        const foundedPlayRemove = machineRelations['play_groups_forbidden'].some(objeto => objeto.group === oldGroupAutoExclusionVertical);
        const foundedViewRemove = machineRelations['view_groups_forbidden'].some(objeto => objeto.group === oldGroupAutoExclusionVertical);
        if (foundedPlayRemove || foundedViewRemove) {
          messageRemove = true
          if (foundedPlayRemove) relations = removeAllowedVerticalGroup('play', oldGroupAutoExclusionVertical, relations)
          if (foundedViewRemove) relations = removeAllowedVerticalGroup('view', oldGroupAutoExclusionVertical, relations)
        }
      }

      if (machine.groupAutoExclusionVertical !== undefined && machine.groupAutoExclusionVertical !== null && machine.groupAutoExclusionVertical !== '') {
        const foundedPlay = machineRelations['play_groups_forbidden'].some(objeto => objeto.group === machine.groupAutoExclusionVertical);
        const foundedView = machineRelations['view_groups_forbidden'].some(objeto => objeto.group === machine.groupAutoExclusionVertical);

        if (!foundedPlay || !foundedView) {
          messageAdd = true
          if (!foundedPlay) relations = addAllowedVerticalGroup('play', machine.groupAutoExclusionVertical, relations)
          if (!foundedView) relations = addAllowedVerticalGroup('view', machine.groupAutoExclusionVertical, relations)
        }
      }

      if (messageAdd || messageRemove) {
        setOldGroupAutoExclusionVertical(machine.groupAutoExclusionVertical)
        props.showAlert(t("Machine details"), t("Modified Forbidden Vertical Group"), [<button type='button' className='btn btn-primary'
          onClick={(evt) => {
            handleSubmit(relations)
            setMachineRelations(relations)
            props.handleCloseAlert()
          }}>
          {t('Ok')}</button>])
      } else handleSubmit()
    }
  }

  const showLoading = () => {
    if (props.showAlert) props.showAlert("LOADING", t("Loading"), true);
  }
  const closeLoading = () => {
    if (props.handleCloseAlert) props.handleCloseAlert()
  }

  const handleSubmit = (relations) => {
    let relationsSubmit = { ...machineRelations }

    if (relations !== undefined) relationsSubmit = relations
    console.log("Machine to be save: " + JSON.stringify(machine));
    if (machine.sub_provider === '') machine.sub_provider = machine.provider;
    if (machine.external_launcher_id === '') machine.external_launcher_id = null;
    let adminClient = new AdminClient();
    if (machine)
      var promise = adminClient.saveMachine(
        session,
        company.company,
        JSON.stringify(machine),
        JSON.stringify(relationsSubmit['tagsChecked']),
        JSON.stringify(relationsSubmit['view_groups_allowed']),
        JSON.stringify(relationsSubmit['view_groups_forbidden']),
        JSON.stringify(relationsSubmit['play_groups_allowed']),
        JSON.stringify(relationsSubmit['play_groups_forbidden'])
      );

    showLoading();
    promise.then(
      function (msg) {
        closeLoading();
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          if (machineId === 0 || machineId === -1) {
            utils.setSessionItem("current-machine", msg.data.machine)
            setMachine(msg.data)
          }
          setGrantsLoad(relationsSubmit['view_groups_allowed'].length > 0 || relationsSubmit['play_groups_allowed'].length > 0);
          props.showAlert(t("Ok"), t("Maquina Salvada correctamente"))
        } else {
          props.showAlert(t("Error"), t("Error salvando Maquina"))
        }
      }, function (err) {
        closeLoading();
        console.log("Save operation error " + err.code);
        props.showAlert(t("Error"), t("Error salvando Maquina"), undefined, undefined, err)
      });


  }

  const handleAddToPromotion = () => {
    let adminClient = new AdminClient()
    let promises = []
    promotions.forEach(promotion => {
      let promotionDetails = promotionsEnabled.filter((promo) => promo.promotion === promotion);
      let machineDetailsPromotion = {
        machine: machine.machine,
        name: machine.name,
        provider: machine.provider,
        sub_provider: machine.sub_provider === "" ? machine.provider : machine.sub_provider
      }

      promotionDetails = promotionDetails[0]
      if (promotionDetails.type === 'EXTERNAL') {
        if (promotionDetails.config.externalConfig.machines.indexOf(machineDetailsPromotion) === -1) {
          promotionDetails.config.externalConfig.machines.push(machineDetailsPromotion);
        }
      } else {
        if (promotionDetails.config.internalConfig.machines.indexOf(machineDetailsPromotion) === -1) {
          promotionDetails.config.internalConfig.machines.push(machineDetailsPromotion);
        }
      }
      promises.push(adminClient.addMachineToPromotion(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(promotionDetails)))
    });
    Promise.all(promises).then(
      function (msg) {
        if (msg.every((objeto) => objeto.result === 'OK')) {
          props.showAlert("Ok", t("Machine added correctly"))
        } else {
          props.showAlert("Error", t("Error adding Machine"))
        }
      }, function (err) {
        console.log("Save operation error " + err.code);
        props.showAlert("Error", t("Error adding Machine"))
      });
  }
  const dataHeader = {
    backLink: goBack,
    title: t("Machine details"),
    urlHelp: "",
    idDoc: props.idDoc
  }

  let langs = company.langs.split(',')
  let indexDefLang = langs.indexOf(company.default_lang);
  langs.splice(indexDefLang, 1);
  langs.splice(0, 0, company.default_lang);



  return (
    <>
      <LayoutSection {...props} dataHeader={dataHeader}>
        <div className="row">
          <div className="col-12 col-md-3  ">
            <TextField disabled name="machine" label={t("Identifier")} type="number" value={machine.machine} />
          </div>
          <div className="col-12 col-md-3  ">
            <TextField name="external_id" label={t("External Identifier")} type="text" value={machine.external_id} onChange={onChangeHandler}
              error={errorMessage && machine.external_id === ""}
              helperText={(errorMessage && machine.external_id) === "" ? t('Required') : ' '}
            />
          </div>
          <div className="col-12 col-md-3  ">
            <TextField name="external_launcher_id" label={t("External launcher Identifier")} type="text" value={machine.external_launcher_id} onChange={onChangeHandler} />
          </div>
          <div className="col-12 col-md-3">
            <TextField label={t("Name")} name="name" type="text" value={machine.name} onChange={onChangeHandler}
              error={errorMessage && machine.name === ""}
              helperText={(errorMessage && machine.name) === "" ? t('Required') : ' '}
            />
          </div>
          <div className="col-12 col-md-3">
            <BasicSelect
              idProperty="type"
              name="type"
              label="Machine types"
              basicInfoName="MachinesTypes"
              onChange={onChangeMachineTypeHandler}
              value={machine.type}
              disableClearable
            />
          </div>
          <div className="col-12 col-md-3">
            <BasicSelect
              idProperty="set"
              name="set"
              label="Sets"
              basicInfoName="AccountSets"
              onChange={onChangeHandler}
              value={machine.set}
              disableClearable
              noEmptyValue={machineId === -1}

            />
          </div>
          <div className="col-12 col-md-3">
            <BasicSelect
              idProperty="provider"
              name="provider"
              label="Providers"
              basicInfoName="Providers"
              filters={[{ property: "type", value: "PROVIDER" }]}
              onChange={onChangeHandler}
              value={machine.provider}
              disableClearable
              noEmptyValue={machineId === -1}
            />
          </div>
          <div className="col-12 col-md-3">
            <BasicSelect
              idProperty="provider"
              name="sub_provider"
              label="SubProviders"
              basicInfoName="Providers"
              filters={[{ property: "type", value: "SUBPROVIDER" },
              { property: "super_provider", value: machine.provider, initVal: true }]}
              onChange={onChangeHandler}
              value={machine.sub_provider}
            />
          </div>
          <div className="col-12 col-md-3">
            <TextField name="rtp" label={t("rtp")} type="number" value={machine.rtp === null ? '' : machine.rtp} onChange={onChangeHandlerRTP}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                inputProps: { min: 0 }
              }}
            />
          </div>

          <div className="col-12 col-md-2">
            <FormControlLabel control={<Checkbox onChange={handleAllowedDemoEnabled} name={"demoAllowed"} checked={(machine.demoAllowed === 0) ? false : true} />} label={t("Demo Allowed")} />
          </div>
          <div className="col-12 col-md-2">
            <FormControlLabel control={<Checkbox onChange={handleStatus} name={"status"} checked={(machine.status !== 'ENABLED') ? false : true} />} label={t("Machine status")} />
          </div>
          <div className="col-12 col-md-2">
            <FormControlLabel control={<Checkbox onChange={handleGamingSession} name={"gaming_session_required"} checked={(machine.gaming_session_required === 0) ? false : true} />} label={t("Gaming session required")} />
          </div>
          <div className="col-12 col-md-1">
            <FormControlLabel control={<Checkbox onChange={handleIsLive} name={"is_live"} checked={(machine.is_live === 0) ? false : true} />} label={t("Is live")} />
          </div>
          <div className="col-12 col-md-2">
            <FormControlLabel control={<Checkbox onChange={handleAllowFreespins} name={"allow_freespins"} checked={(machine.allow_freespins === 0) ? false : true} />} label={t("Allow FresSpins")} />
          </div>
          {company.config?.backoffice?.freeRegulationMachinesCodes === true && <>
            <div className="col-12 col-md-3">

              <TextField name="regulation_code" label={t("Regulation")} type="text" value={machine.regulation_code} onChange={(e) => {
                let value = null
                if (e.currentTarget.value !== "") {
                  value = e.currentTarget.value
                }
                onChangeHandler({ "name": 'regulation_code', "value": value })
              }} />

            </div>
          </>}
          {company.config?.backoffice?.freeRegulationMachinesCodes !== true && <>
            <div className="col-12 col-md-3">
              <BasicSelect
                idProperty="code"
                label={t("Regulation")}
                name="regulation_code"
                basicInfoName="RegulationsMachinesCodes"
                filters={[{ property: "regulation", value: company.regulation }]}
                onChange={(e) => {
                  if (e.value === "") {
                    e.value = null;
                  }
                  onChangeHandler(e)
                }}
                value={machine.regulation_code}
                hideOnlyOneOption
              />
            </div>
            <div className="col-12 col-md-3">
              <BasicSelect
                idProperty="version"
                label={t("Regulation Version")}
                name="regulation_version"
                basicInfoName="RegulationsMachinesVersions"
                filters={[{ property: "regulation", value: company.regulation }, { property: "code", value: machine.regulation_code }]}
                onChange={(e) => {
                  if (e.value === "") {
                    e.value = null;
                  }
                  onChangeHandler(e)
                }}
                value={machine.regulation_version}
                hideOnlyOneOption
              />
            </div>
          </>}
        </div>

        <div className="row">
          <div className="col-12">
            {utils.isGranted('SAVE_CONFIGURATION_MACHINES') &&
              <button type="button" className="btn btn-primary" onClick={checkAllowedVerticalGroup}>{t("Save")}</button>
            }
            <button type="button" className="btn btn-secondary" onClick={goBack}>{t("Back")}</button>
          </div>
        </div>

        {(machine.machine > -1) &&
          <>
            {<Tab key="tabMachineDetailsI18n" active={0}>
              {
                langs.map((lang, idx) =>
                  <Tab.TabPane key={`TabMachineDetails-${idx}`} tab={(errorMessageLang[lang] !== undefined && errorMessageLang[lang] === true) ? lang + "🔴" : lang}>
                    <MachineDetailsI18n {...props} errorMessage={errorMessage} errorMessageLang={errorMessageLang} machine={machine} id={`machine_details_${lang}`} lang={lang} onChange={updateI18n} />
                  </Tab.TabPane>
                )
              }
            </Tab>}

            <Tab key="tabContentGroupsTags" active={0}>
              <Tab.TabPane key={`TabContentTags`} tab={t("Tags")}>
                {/* <SelectMachinesTags onChange={handleTagUpdate} tags_checked={machineRelations['tagsChecked']} company={company.company} /> */}
                <BasicSelect
                  idProperty="tag"
                  label={t("Add tag")}
                  basicInfoName="AllMachineTags"
                  onChange={handleTagUpdate}
                  value={machineRelations.tagsChecked}
                  multiple
                />
              </Tab.TabPane>
              <Tab.TabPane key={`TabContentView`} tab={t("View Groups")}>
                <AllowedForbiddenList
                  allowed={machineRelations['view_groups_allowed']}
                  availables={machineRelations['view_groups_availables']}
                  forbidden={machineRelations['view_groups_forbidden']}
                  idProperty={"group"}
                  nameProperty={"name"}
                  handleUpdateGroups={updateGroupsView}
                />
              </Tab.TabPane>
              <Tab.TabPane key={`TabContentPlay`} tab={t("Play Groups")}>
                <AllowedForbiddenList
                  allowed={machineRelations['play_groups_allowed']}
                  availables={machineRelations['play_groups_availables']}
                  forbidden={machineRelations['play_groups_forbidden']}
                  idProperty={"group"}
                  nameProperty={"name"}
                  handleUpdateGroups={updateGroupsPlay}
                />
              </Tab.TabPane>
              {(promotionsEnabled?.length > 0 && grantsLoad) &&
                <Tab.TabPane key={"TabPromotions"} tab={t("Promotions")}>
                  <div className='row'>
                    <div className="col-12">
                      <BasicSelect
                        idProperty="promotion"
                        label={t("Promotions")}
                        addOpts={promotionsEnabled}
                        onChange={(e) => {
                          setPromotions(e.value)
                        }}
                        value={promotions}
                        multiple
                      />
                    </div>
                    <div className="col-3">
                      {utils.isGranted('SAVE_CONFIGURATION_MACHINES') &&
                        <button type="button" style={{ float: "left" }} className="btn btn-primary" onClick={handleAddToPromotion}>{t("Add to promotions")}</button>
                      }
                    </div>
                  </div>
                </Tab.TabPane>
              }
            </Tab>
          </>
        }
      </LayoutSection>
    </>
  )
}

export default withTranslation()(MachineDetails)
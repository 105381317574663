import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import utils from '../../utils';
import { initDataPlayerRegulation } from '../../libs/regulations/regulations';
import AdminClient from '../../AdminClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import BasicSelect from '../../components/basic/BasicSelect';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import useFormValidation from '../../hooks/parsleyValidation';
import moment from "moment";
import NotePopup from '../../components/users/NotePopup';


const UserPersonalDetails = (props) => {
    const company = utils.getCurrentCompany().company;
    const companyDetails = utils.getCurrentCompany(company);
    const statusWithoutValidation = companyDetails.config.backoffice?.editPlayer?.statusWithoutValidation || []
    const adminUser = JSON.parse(utils.getSessionItem("user"))
    const [userDetails, setUserDetails] = useState({ hiddenFields: [], noEditFields: [], requiredFields: [] })
    const [showEdit, setShowEdit] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const { formValidation } = useFormValidation("Form_personaDetails");
    const { t } = props
    const adminClient = new AdminClient()
    const [regulationStatus, setRegulationStatus] = useState('')
    const [statusAvailables, setStatusAvailables] = useState({})


    const hasValidatePlayer = () => {

        if (userDetails.user !== undefined) {
            if (formValidation !== undefined) formValidation.reset()
            if (!statusWithoutValidation.includes(userDetails.user.regulatory_status)) {
                console.log('Tenemos que validar')
                return true
            } else {
                console.log('no tenemos que validar')
                return false
            }
        } else {
            return true
        }
    }



    const notifyError = (fieldValidation) => {
        let error = false
        if (fieldValidation !== undefined && !hasValidatePlayer() && Array.isArray(fieldValidation.validationResult)) fieldValidation.reset()
        if (formValidation !== undefined)
            if (hasValidatePlayer()) {
                error = formValidation.validate()
            } else formValidation.reset()
    }


    useEffect(async () => {

        let data = await adminClient.getDataBasicInfo(adminUser.session, company, "RegulatoryStatus")

        let status = data.status
        let availables = {}
        if (status !== undefined) {
            status.forEach((status) => {
                if (status.status_allowed !== null && status.status_allowed != '') {
                    try {
                        availables[status.status] = [status.status, ...JSON.parse(status.status_allowed)]
                    } catch (err) {
                        console.log(err)
                        availables[status.status] = []
                    }
                } else {
                    availables[status.status] = []
                }
            })
        }
        setStatusAvailables(availables)
    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let newUserDetails = { ...userDetails };
        newUserDetails['user'][name] = value;
        setUserDetails(newUserDetails);
    };

    const onChangeHandlerCheck = (e) => {
        const { name, checked } = e.target || e;
        let newUserDetails = { ...userDetails };
        switch (name) {
            case "verified":
            case "otp":
                newUserDetails['user'][name] = checked
                break;

            default:
                newUserDetails['user'][name] = !checked
                break;
        }
        setUserDetails(newUserDetails);
    }

    const popupSaveUser = () => {
        if (!hasValidatePlayer() || formValidation.validate()) {
            if (companyDetails.config?.backoffice?.editPlayer?.note === "true") {
                props.showAlert(
                    t('Saving user'),
                    <NotePopup
                        handleCloseAlert={props.handleCloseAlert}
                        company={company}
                        adminClient={adminClient}
                        save={saveUser}
                        t={t}
                    />,
                    []
                )
            } else {
                saveUser();
            }
        }
    }



    const saveUser = () => {
        // let dataBirth = new Date(userDetails.user.birthday)
        // let now = new Date()
        // if ((now.getTime() - dataBirth.getTime()) < (18 * 365 * 24 * 60 * 60 * 1000))
        //     props.showAlert(t("Saving user"), t("Less than 18 years"))
        // else {
        adminClient.saveUser(adminUser.session, JSON.stringify(userDetails.user)).then((msg) => {
            setShowEdit(false)
            let newUserDetails = { ...userDetails };
            if (!newUserDetails.user.otp) newUserDetails['user']['otp'] = null;
            setUserDetails(newUserDetails);
            setRegulationStatus(newUserDetails.user.regulatory_status)
            props.showAlert(t("Saving user"), t('Saved succesfull'))
        }, (err) => {
            props.showAlert(t("Saving user"), t('Saving error ') + err.description)
        })
        //}
    }


    useEffect(async () => {

        if (!showEdit) {

            let result = await adminClient.userDetails(adminUser.session, utils.getSessionItem("current-user"));

            let config = utils.getCurrentCompany().config;
            let risk_weighting = 0;
            let loyalty_weighting = 0;
            //Obtenemos la configuración de campos inicial segun la company

            result.user.deposits_disabled = false;
            result.user.payouts_disabled = false;
            result.user.promotions_disabled = false;
            result.user.play_disabled = false;

            result.groups.forEach(element => {
                console.log("element.risk_weighting = " + element.risk_weighting + " element.group = " + element.group);
                risk_weighting += element.risk_weighting;
                loyalty_weighting += element.loyalty_weighting;
                if (element.group === config.groups.deposits_disabled) {
                    result.user.deposits_disabled = true;
                }
                if (element.group === config.groups.payouts_disabled) {
                    result.user.payouts_disabled = true;
                }
                if (element.group === config.groups.play_disabled) {
                    result.user.play_disabled = true;
                }
                if (element.group === config.groups.promotions_disabled) {
                    result.user.promotions_disabled = true;
                }
            });

            let riskLevel = "risk_low";
            if (risk_weighting > 20 && risk_weighting <= 50) {
                riskLevel = "risk_medium";
            }
            if (risk_weighting > 50) {
                riskLevel = "risk_high";
            }

            let loyaltyLevel = "loyalty_high";
            if (loyalty_weighting > 20 && loyalty_weighting <= 50) {
                loyaltyLevel = "loyalty_medium";
            }
            if (loyalty_weighting < 20) {
                loyaltyLevel = "loyalty_low";
            }

            result.riskLevel = riskLevel;
            result.risk_weighting = risk_weighting;
            result.loyaltyLevel = loyaltyLevel;
            result.loyalty_weighting = loyalty_weighting;
            result.olduser = { ...result.user }
            setUserDetails(result)
            setRegulationStatus(result.user.regulatory_status)
        } else if (formValidation !== undefined) formValidation.reset()
    }, [showEdit])

    useEffect(() => {
        if (showEdit) {
            let dataPlayer = initDataPlayerRegulation(userDetails, t)
            setUserDetails(dataPlayer)
        }
    }, [showEdit, showDetails])

    const dataHeader = useMemo(() => {
        let h = {
            backlink: '',
            title: t("Personal Info"),
            buttons: []
        }
        if (showDetails) h.buttons.push({
            name: '', handle: () => {
                setShowDetails(!showDetails)
            }, icon: <FontAwesomeIcon icon="window-minimize" />
        })
        else
            h.buttons.push({
                name: '', handle: () => {
                    setShowDetails(!showDetails)
                }, icon: <FontAwesomeIcon icon="list" />
            })
        if (utils.isGranted('SAVE_USERS_DETAILS')) {
            if (showEdit) {
                h.buttons.push({
                    name: '', handle: () => {
                        popupSaveUser()
                    }, icon: <FontAwesomeIcon icon="save" />
                })
                h.buttons.push({
                    name: '', handle: () => {
                        setShowEdit(false)
                    }, icon: <FontAwesomeIcon icon="times" />
                })
            }
            else h.buttons.push({
                name: '', handle: () => {
                    setShowEdit(!showEdit)
                }, icon: <FontAwesomeIcon icon="edit" />
            })
        }

        return h

    }, [showDetails, showEdit])

    return (
        <LayoutSection className={props.className} dataHeader={(dataHeader !== null) ? dataHeader : ""}>
            <form id="Form_personaDetails" data-parsley-focus="none" data-parsley-ui-enabled="true">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-1">
                        <Link to={"/support/report/user_risk/table"} >
                            <TextField InputLabelProps={{ shrink: userDetails.risk_weighting !== undefined }}
                                InputProps={{
                                    endAdornment: <Link to={"/support/report/user_risk/table"}>
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon icon="eye" />
                                        </InputAdornment>
                                    </Link>
                                }}
                                className={"text-center  a_" + userDetails.riskLevel}
                                label={t("Risk details")} type="text"
                                disabled value={userDetails.risk_weighting + " %"} />
                        </Link>
                    </div>
                    <div className="col-12 col-md-4 col-lg-1">
                        <Link to={"/support/report/user_loyalty/table"} >
                            <TextField InputLabelProps={{ shrink: userDetails.loyalty_weighting !== undefined }}
                                InputProps={{
                                    endAdornment: <Link to={"/support/report/user_loyalty/table"}>
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon icon="eye" />
                                        </InputAdornment>
                                    </Link>
                                }}
                                className={"text-center a_" + userDetails.loyaltyLevel}
                                label={t("Loyalty details")} type="text"
                                disabled value={userDetails.loyalty_weighting + " %"} />
                        </Link>
                    </div>
                    {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('deposits_disabled'))) &&
                        <div className="col-12 col-md-4 col-lg-2">
                            <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('deposits_disabled'))))} control={<Checkbox
                                onChange={onChangeHandlerCheck} name="deposits_disabled" checked={!userDetails.user?.deposits_disabled} />} label={t("Deposits enabled")} />
                        </div>
                    }
                    {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('payouts_disabled'))) &&
                        <div className="col-12 col-md-4 col-lg-2">
                            <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('payouts_disabled'))))} control={<Checkbox onChange={onChangeHandlerCheck} name="payouts_disabled" checked={!userDetails.user?.payouts_disabled} />} label={t("Payouts enabled")} />
                        </div>
                    }
                    {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('play_disabled'))) &&
                        <div className="col-12 col-md-4 col-lg-2">
                            <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('play_disabled'))))} control={<Checkbox onChange={onChangeHandlerCheck} name="play_disabled" checked={!userDetails.user?.play_disabled} />} label={t("Play enabled")} />
                        </div>
                    }
                    {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('promotions_disabled'))) &&
                        <div className="col-12 col-md-4 col-lg-2">
                            <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('promotions_disabled'))))} control={<Checkbox onChange={onChangeHandlerCheck} name="promotions_disabled" checked={!userDetails.user?.promotions_disabled} />} label={t("Promotions enabled")} />
                        </div>
                    }
                    {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('type'))) &&

                        <div className="col-12 col-md-4 col-lg-2">
                            <BasicSelect
                                id="Player_type"
                                idProperty="type"
                                label={t("Player type")}
                                basicInfoName="UsersTypes"
                                name="type"
                                handleNotifiyError={notifyError}
                                onChange={onChangeHandler}
                                value={userDetails.user?.type}
                                disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('type'))))}
                                translate={{ property: 'name', prefix: '' }}
                                disableClearable
                                required
                            />
                        </div>
                    }
                    {showDetails && <>


                        <div className="col-12 col-md-4 col-lg-2">
                            <TextField id="Player_user" required InputLabelProps={{ shrink: userDetails.user.user !== undefined }} name="user" label={t("ID")} type="text" disabled value={userDetails.user.db + "." + userDetails.user.user} />
                        </div>

                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('alias'))) &&
                            <div className="col-12 col-md-4 col-lg-2">
                                <TextField id="Player_alias" required InputLabelProps={{ shrink: userDetails.user.alias !== undefined }} label={t("Alias")} type="text" name="alias" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('alias'))))} value={userDetails.user.alias} onChange={onChangeHandler} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('regulatory_status'))) &&
                            <div className="col-12 col-md-4 col-lg-2">
                                <BasicSelect
                                    id="Player_status"
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('regulatory_status'))))}
                                    label={t("Status")}
                                    idProperty="status"
                                    data="status"
                                    handleNotifiyError={notifyError}
                                    name="regulatory_status"
                                    basicInfoName="RegulatoryStatus"
                                    onChange={onChangeHandler}
                                    value={userDetails.user.regulatory_status}
                                    filters={(statusAvailables[regulationStatus].length > 0) ? [{ property: "status", value: statusAvailables[regulationStatus] }] : []}
                                    disableClearable
                                    required
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('national_id_type'))) &&
                            <div className="col-12 col-md-6 col-lg-2">

                                <BasicSelect
                                    id="Player_national_id_type"
                                    idProperty='national_id'
                                    nameProperty='national_id'
                                    basicInfoName='NationalIdTypes'
                                    value={userDetails.user.national_id_type}
                                    handleNotifiyError={notifyError}
                                    label={t('Type')}
                                    validations={{ 'data-parsley-player-national-id-type': JSON.stringify([userDetails.olduser.national_id_type, userDetails.user.national_id_type]) }}
                                    name="national_id_type"
                                    onChange={onChangeHandler}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('national_id_type'))))}
                                    translate={{ property: 'national_id', prefix: 'DOCUMENTO_TYPE_' }}
                                    multiple={false}
                                    noEmptyValue={false}
                                    disableClearable={false}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('national_id'))) &&
                            <div className="col-12 col-md-6 col-lg-2">
                                <TextField id="Player_national_id" required={userDetails.requiredFields.includes('national_id')} InputLabelProps={{ shrink: userDetails.user.national_id !== undefined && userDetails.user.national_id !== null }} label={t("National ID")} name="national_id" type="text"
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('national_id'))))} value={userDetails.user.national_id} onChange={onChangeHandler} />
                            </div>
                        }

                        <div className="col-12 col-md-4 col-lg-2">
                            <TextField id="Player_currency" InputLabelProps={{ shrink: userDetails.user.currency !== undefined }} required label={t("Currency")} type="text" name="currency" disabled value={userDetails.user.currency} onChange={onChangeHandler} />
                        </div>

                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('lang'))) &&

                            <div className="col-12 col-md-6 col-lg-2">

                                <BasicSelect
                                    idProperty="lang"
                                    name="lang"
                                    label={t("Lang")}
                                    basicInfoName="Languages"
                                    onChange={onChangeHandler}
                                    required={userDetails.requiredFields.includes('lang')}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('lang'))))}
                                    value={userDetails.user.lang}
                                />

                            </div>
                        }

                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('created_date'))) &&
                            <div className="col-12 col-md-8 col-lg-2">

                                <DateAndTimePicker
                                    id="Player_created_date"
                                    required
                                    disabled={true}
                                    label={t("Register at")}
                                    value={utils.formatDate(userDetails.user.created_date)}
                                    showTime={true}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('email'))) &&
                            <div className="col-12 col-md-4 col-lg-4">
                                <TextField id="Player_email" required InputLabelProps={{ shrink: userDetails.user.email !== undefined }} label={t("Email")} type="text"
                                    name="email" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('email'))))} value={userDetails.user.email} onChange={onChangeHandler} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('mobile'))) &&
                            <div className="col-12 col-md-2 col-lg-2">
                                <TextField id="Player_mobile" required={userDetails.requiredFields.includes('mobile')} InputLabelProps={{ shrink: userDetails.user.mobile !== undefined }} label={t("Mobile")} type="text" name="mobile" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('mobile'))))} value={userDetails.user.mobile} onChange={onChangeHandler} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('verified'))) &&
                            <div className="col-12 col-md-1 col-lg-1">
                                <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('verified'))))} control={<Checkbox id="Player_verified" name="verified" onChange={onChangeHandlerCheck} checked={userDetails.user.verified} />} label={t("Verified")} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('otp'))) &&
                            <div className="col-12 col-md-1 col-lg-1">
                                <FormControlLabel disabled={!((showEdit && userDetails.noEditFields.length === 0 && userDetails.user.otp !== null) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('otp')) && userDetails.user.otp !== null))} control={<Checkbox id="Player_otp" name="otp" onChange={onChangeHandlerCheck} checked={userDetails.user.otp} />} label={t("OTP")} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('verification_type'))) &&
                            <div className="col-12 col-md-3 col-lg-3">
                                <BasicSelect
                                    id="Player_verification_type"
                                    required={userDetails.requiredFields.includes('verification_type')}
                                    idProperty="code"
                                    label={t("Regulation")}
                                    handleNotifiyError={notifyError}
                                    name="verification_type"
                                    validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-verification-type': JSON.stringify([userDetails.user.verified, userDetails.user.verification_type]) }}
                                    basicInfoName="RegulationsVerificationCodes"
                                    filters={[{ property: "regulation", value: utils.getCurrentCompany().regulation }]}
                                    onChange={(e) => {
                                        if (e.value === "") {
                                            e.value = null;
                                        }
                                        onChangeHandler(e)
                                    }}
                                    value={userDetails.user.verification_type}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('verification_type'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('first_name'))) &&
                            <div className="col-12 col-md-4 col-lg-4 ">
                                <TextField id="Player_first_name" required InputLabelProps={{ shrink: userDetails.user.first_name !== undefined }} label={t("First name")} type="text" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('first_name'))))} name="first_name" value={userDetails.user.first_name} onChange={onChangeHandler} />
                            </div>
                        }
                        {(props.viewMiddle === undefined || props.viewMiddle === false) && <>

                            {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_name'))) &&
                                <div className="col-12 col-md-4 col-lg-8">
                                    <TextField id="Player_last_name" required InputLabelProps={{ shrink: userDetails.user.last_name !== undefined }} label={t("Last name")} type="text" name="last_name" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('last_name'))))} value={userDetails.user.last_name} onChange={onChangeHandler} />
                                </div>
                            }
                        </>
                        }
                        {/* Comprobar si la compañia tiene activado el middle_name */}
                        {props.viewMiddle && <>

                            {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_name'))) &&
                                <div className="col-12 col-md-4 col-lg-4">
                                    <TextField id="Player_last_name" required InputLabelProps={{ shrink: userDetails.user.last_name !== undefined }} label={t("First last name")} type="text" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('last_name'))))} name="last_name" value={userDetails.user.last_name} onChange={onChangeHandler} />
                                </div>
                            }
                            {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_name'))) &&
                                <div className="col-12 col-md-4 col-lg-4">
                                    <TextField id="Player_middle_name" required={userDetails.requiredFields.includes('middle_name')} InputLabelProps={{ shrink: userDetails.user.middle_name !== undefined }} label={t("Second last name")} type="text" name="middle_name" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('last_name'))))} value={userDetails.user.middle_name} onChange={onChangeHandler} />
                                </div>}

                        </>}


                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('birthday'))) &&
                            <div className="col-12 col-md-4 col-lg-2">
                                <DateAndTimePicker
                                    key="userBirthDate"
                                    id="Player_birthday"
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('birthday'))))}
                                    required={userDetails.requiredFields.includes('birthday')}
                                    renderInput={(props) => <TextField  {...props} />}
                                    label={t("Birthday")}
                                    handleNotifyError={notifyError}
                                    value={userDetails.user.birthday || ""}
                                    showTime={false}
                                    fieldName="birthday"
                                    validations={{ 'data-parsley-player-birth-day': JSON.stringify([userDetails.user.birthday]) }}
                                    callBackFunction={(value, fieldName) => {
                                        if (value !== null) value = moment(new Date(value).getTime()).format("YYYY-MM-DD")
                                        onChangeHandler({ target: { name: fieldName, value: value } });
                                    }}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('gender'))) &&
                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    id="Player_gender"
                                    required={userDetails.requiredFields.includes('gender')}
                                    label="Gender"
                                    idProperty="gender"
                                    name="gender"
                                    data="gender"
                                    handleNotifiyError={notifyError}
                                    basicInfoName="Gender"
                                    onChange={onChangeHandler}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    value={userDetails.user.gender}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('gender'))))}
                                    translate={{ property: "name", prefix: "" }}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('address'))) &&
                            <div className="col-12 col-md-6 col-lg-5">
                                <TextField id="Player_address" required={userDetails.requiredFields.includes('address')} InputLabelProps={{ shrink: userDetails.user.address !== undefined }} label={t("Address")} type="text" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('address'))))} name="address" value={userDetails.user.address} onChange={onChangeHandler} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('zipcode'))) &&
                            <div className="col-12 col-md-4 col-lg-2">
                                <TextField id="Player_zipcode" required={userDetails.requiredFields.includes('zipcode')} InputLabelProps={{ shrink: userDetails.user.zipcode !== undefined }} label={t("Zipcode")} type="text" name="zipcode" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('zipcode'))))} value={userDetails.user.zipcode} onChange={onChangeHandler} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('nationality'))) &&
                            <div className="col-12 col-md-6 col-lg-3">

                                <BasicSelect
                                    id="Player_nationality"
                                    required={userDetails.requiredFields.includes('nationality')}
                                    idProperty="country"
                                    label={t("Nationality")}
                                    data="countries"
                                    handleNotifiyError={notifyError}
                                    basicInfoName="Countries"
                                    validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-nationality': JSON.stringify([userDetails.user.national_id_type, userDetails.user.nationality]) }}
                                    name="nationality"
                                    onChange={onChangeHandler}
                                    value={userDetails.user.nationality}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('nationality'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('country'))) &&
                            <div className="col-12 col-md-6 col-lg-3">

                                <BasicSelect
                                    required
                                    id="Player_country"
                                    idProperty="country"
                                    label={t("Country")}
                                    data="countries"
                                    basicInfoName="Countries"
                                    name="country"
                                    handleNotifiyError={notifyError}
                                    validations={{ 'data-parsley-validate-if-empty': true, 'data-parsley-player-country': JSON.stringify([userDetails.user.national_id_type, userDetails.user.nationality, userDetails.user.country]) }}
                                    onChange={onChangeHandler}
                                    value={userDetails.user.country}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('country'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('state'))) &&
                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    id="Player_state"
                                    required={userDetails.requiredFields.includes('state')}
                                    idProperty="state"
                                    label={t("State")}
                                    basicInfoName="States"
                                    name="state"
                                    handleNotifiyError={notifyError}
                                    params={[{ property: "country", value: userDetails.user.country || "" }]}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    onChange={onChangeHandler}
                                    value={userDetails.user.state}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('state'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('province'))) &&
                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    id="Player_province"
                                    required={userDetails.requiredFields.includes('province')}
                                    idProperty="province"
                                    label={t("Province")}
                                    basicInfoName="Provinces"
                                    name="province"
                                    handleNotifiyError={notifyError}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    params={[
                                        { property: "country", value: userDetails.user.country || "" },
                                        { property: "state", value: userDetails.user.state || "" }
                                    ]}
                                    onChange={onChangeHandler}
                                    value={userDetails.user.province}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('province'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('city'))) &&
                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    id="Player_city"
                                    required={userDetails.requiredFields.includes('city')}
                                    idProperty="city"
                                    label={t("City")}
                                    basicInfoName="Cities"
                                    name="city"
                                    handleNotifiyError={notifyError}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    params={[
                                        { property: "country", value: userDetails.user.country || "" },
                                        { property: "state", value: userDetails.user.state || "" },
                                        { property: "province", value: userDetails.user.province || "" }
                                    ]}
                                    onChange={onChangeHandler}
                                    value={userDetails.user.city}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('city'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('affiliate'))) &&

                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    required={userDetails.requiredFields.includes('affiliate')}
                                    id="Player_affiliate"
                                    idProperty='affiliate'
                                    data='affiliates'
                                    basicInfoName='Affiliates'
                                    value={userDetails.user.affiliate}
                                    name='affiliate'
                                    label={t('affiliate')}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    handleNotifiyError={notifyError}
                                    onChange={onChangeHandler}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('affiliate'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('affiliate_tag'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField id="Player_affiliate_tag" type="text" disabled label={t("Affiliate Tag")} value={userDetails.user.affiliate_tag} name="affiliate_tag"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('agent'))) &&
                            <div className="col-12 col-md-6 col-lg-3">
                                <BasicSelect
                                    id="Player_agent"
                                    required={userDetails.requiredFields.includes('agent')}
                                    idProperty="user"
                                    name="agent"
                                    label={t("Agent")}
                                    basicInfoName="Agents"
                                    handleNotifiyError={notifyError}
                                    onChange={(e) => {
                                        if (e.value === "") {
                                            e.value = 0;
                                        }
                                        onChangeHandler(e)
                                    }}
                                    value={userDetails.user.agent}
                                    validations={{ 'data-parsley-validate-if-empty': true }}
                                    disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('agent'))))}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('promotion'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField id="Player_promotion" InputLabelProps={{ shrink: userDetails.user.promotion !== undefined }} name="promotion" label={t("Promotion")} type="text" disabled value={userDetails.user.promotion} />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('login_errors'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField id="Player_login_errors" type="number" disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('login_errors'))))} label={t("Login attemps")} value={userDetails.user.login_errors} name="login_errors"
                                    onChange={onChangeHandler}
                                    InputProps={{ inputProps: { max: 100, min: 0 } }}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('facebook_id'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField type="text" id="Player_facebook_id" required={userDetails.requiredFields.includes('facebook_id')} disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('facebook_id'))))} label={t("Facebook Id")} value={userDetails.user.facebook_id} name="facebook_id"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('google_id'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField type="text" id="Player_google_id" required={userDetails.requiredFields.includes('google_id')} disabled={!((showEdit && userDetails.noEditFields.length === 0) || (showEdit && !(userDetails.noEditFields.length > 0 && userDetails.noEditFields.includes('google_id'))))} label={t("Google Id")} value={userDetails.user.google_id} name="google_id"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('modified_date'))) &&
                            <div className="col-12 col-md-8 col-lg-2">

                                <DateAndTimePicker
                                    disabled={true}
                                    id="Player_modified_date"
                                    label={t("Last update")}
                                    value={utils.formatDate(userDetails.user.modified_date)}
                                    showTime={true}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_device'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField id="Player_last_device" type="text" disabled label={t("Device")} value={userDetails.user.last_device} name="last_device"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }

                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('website'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField type="text" id="Player_website" disabled label={t("Web")} value={userDetails.user.website} name="website"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_login_date'))) &&
                            <div className="col-12 col-md-8 col-lg-2">

                                <DateAndTimePicker
                                    id="Player_last_login_date"
                                    disabled={true}
                                    label={t("Last login date")}
                                    value={utils.formatDate(userDetails.user.last_login_date)}
                                    showTime={true}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('last_ip'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField id="Player_last_ip" type="text" disabled label={t("Last IP")} value={userDetails.user.last_ip} name="last_ip"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                        {(userDetails.hiddenFields.length === 0 || !(userDetails.hiddenFields.length > 0 && userDetails.hiddenFields.includes('register_ip'))) &&
                            <div className="col-12 col-md-4 col-lg-3">
                                <TextField type="text" id="Player_register_ip" disabled label={t("Register IP")} value={userDetails.user.register_ip} name="register_ip"
                                    onChange={onChangeHandler}
                                />
                            </div>
                        }
                    </>}
                </div>
            </form>
        </LayoutSection>
    )
}

export default withTranslation()(UserPersonalDetails)
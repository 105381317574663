import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from "../../utils"
import AdminClient from '../../AdminClient'
import File from '../../components/basic/File'
import { TextField } from '@mui/material'
import DateAndTimePicker from '../../components/DateAndTimePicker'
import MessagesList from "../../components/basic/MessagesList";



const AlertMessageDetails = (props) => {
  const [message, setMessage] = useState('')
  const [reason, setReason] = useState('')
  const session = JSON.parse(utils.getSessionItem("user")).session;
  const [documentationDetails, setDocumentationDetails] = useState({
    user_details: { national_id_type: "DNI", verified: 0, state: 0, province: 0, city: 0 },
    operations: [],
    user_files: [],
    expiration_date: null,
    created_date: [null, null],
    rejectClientMessage: '',
    rejectReason: ''
  })

  const { t } = props

  const onChangeHandlerReject = ({ code, description }) => {

    setDocumentationDetails((prevState) => {
      let oldState = { ...prevState }
      oldState.rejectReason = code
      oldState.rejectClientMessage = description
      setMessage(description)
      setReason(code)
      return oldState
    });
  }

  return (<>
    <div className="row">
      <div className="row" >
        <MessagesList label={{ "code": t("Reason"), "description": t("Message to client") }} type="DOCUMENTATION_USER_BANK" onChangeHandler={onChangeHandlerReject} />
      </div>
      <div className="row">
        {documentationDetails.user_files !== undefined &&
          documentationDetails.user_files.map((file) => {
            let adminClient = new AdminClient();
            file.user = documentationDetails.user_details.user;
            return <div className="col-md-6">
              <File
                onlyViewer={true}
                inputFile={adminClient.getFile(session, file)}
                inputFileExtension={file.path.substring(file.path.lastIndexOf('.'))}
              />
            </div>
          })
        }
      </div>
    </div>

    <div className="row">
      <div className="col-12" >
        <button type="button" key="cancelAlert" className="btn btn-secondary float-end" onClick={props.handleCloseAlert}>{t("Cancel")}</button>
        <button type="button" key="OKAlert" className="btn btn-primary float-end" onClick={(evt) => {
          props.handleCloseAlert()
          props.actionConfirm(props.fileIndex, props.status, message, reason + '\n\r')

        }}>{t("Ok")}</button>
      </div>
    </div>
  </>
  )
}

const PendingBankDetails = (props) => {


  const { t } = props

  const [accountDetails, setAccountDetails] = useState({ userCompany: '', userId: '', userDb: '', userName: '', userMiddleName: '', userLastName: '', userBirthDate: '', account: {}, bankFiles: [], canApproveAccount: false })

  const goToBack = () => {
    props.history.push("/support/pending_bank_documentation")
  }
  const dataHeader = {
    backLink: "/support/pending_bank_documentation",
    title: t("Pending bank documentation details"),
    urlHelp: "",
    idDoc: props.idDoc
  }

  const checkStatusFiles = (files) => {
    let canApproveAccount = false;
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      canApproveAccount = canApproveAccount || (f.status === 'APPROVED') ? true : false
    }
    return canApproveAccount;
  }

  useEffect(() => {

    let params = JSON.parse(utils.getSessionItem("current_user_bank_files"))
    let { user, userPaymentAccount } = params

    let adminClient = new AdminClient();

    let promises = []
    //Obtenemos datos del player para comparar
    promises.push(adminClient.userDetails(JSON.parse(utils.getSessionItem("user")).session, user))
    //Obtenemos datos del user_payment para comparar
    promises.push(adminClient.getUserPaymentAccountDetails(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), user.split(".")[0], user.split(".")[1], userPaymentAccount))
    //Obtenemos ficheros asociados al payment
    promises.push(adminClient.getUserFilesBank(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), user.split(".")[0], user.split(".")[1], userPaymentAccount))


    Promise.all(promises).then(
      function (result) {
        let { user } = result[0]
        let { data: account } = result[1]
        let accountDetails = {
          userCompany: user.company, userId: user.user, userDb: user.db, userCurrency: user.currency, userName: user.first_name, userMiddleName: user.middle_name, userLastName: user.last_name,
          userBirthDate: user.birthday, account: account, bankFiles: result[2].files, canApproveAccount: checkStatusFiles(result[2].files)
        }
        setAccountDetails(accountDetails)
      }
      , function (err) {
        console.log(err)
      });
  }, [])

  const dataHeaderFiles = {
    backLink: "",
    title: t("Files"),
    urlHelp: ""
  }
  const handleChangeValue = (evt) => {
    const { name, value } = evt.target;
    let deep = name.split(".")
    if (deep.length === 3) {
      setAccountDetails((prevState) => (
        {
          ...prevState,
          [deep[0]]: {
            ...prevState[deep[0]],
            [deep[1]]: {
              ...prevState[deep[0]][deep[1]]
              , [deep[2]]: value
            }
          }
        }
      ))
    }
    else if (deep.length === 2) {
      setAccountDetails((prevState) => (
        {
          ...prevState,
          [deep[0]]: {
            ...prevState[deep[0]],
            [deep[1]]: value
          }
        }

      ))
    }
    else {
      setAccountDetails((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  const refreshFiles = () => {
    let adminClient = new AdminClient()
    adminClient.getUserFilesBank(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), accountDetails.userDb, accountDetails.userId,
      accountDetails.account.payment_account).then((result) => {
        setAccountDetails((prevState) => ({ ...prevState, ['bankFiles']: result.files, ['canApproveAccount']: checkStatusFiles(result.files) }));
      }, (err) => {
        console.log("Error obteniendo ficheros ")
      })

  }


  const prepareFileStatusChange = (fileIndex, status, message, reason) => {
    let adminClient = new AdminClient()
    let promises = []
    if (fileIndex === 'all') {
      //Actualizamos el estado de todos los ficheros en estado pendiente
      for (let i = 0; i < accountDetails.bankFiles.length; i++) {
        const fileData = accountDetails.bankFiles[i];
        if (fileData.status === 'PENDING') {
          promises.push(adminClient.reviewFileBank(JSON.parse(utils.getSessionItem("user")).session, fileData.company, fileData.db, fileData.user, fileData.file, status, reason, message))
        }
      }
    }
    else {
      let file = accountDetails.bankFiles[fileIndex];
      promises.push(adminClient.reviewFileBank(JSON.parse(utils.getSessionItem("user")).session, file.company, file.db, file.user, file.file, status, reason, message))
    }
    return promises;
  }

  const changeStatusFile = (fileIndex, status, message, reason) => {

    let promises = prepareFileStatusChange(fileIndex, status, message, reason)
    if (promises.length > 0) {
      Promise.all(promises).then((result) => {
        refreshFiles()
        if (fileIndex === 'all') props.showAlert(t("Pending bank documentation details"), <p>{t("File") + " " + t('FILE_STATUS_' + status)}</p>)
      }, (err) => {
        console.log("Error en ChangeStatusFile")
      })
    }
    else props.showAlert(t("Pending bank documentation details"), <p>{t("No") + " " + t("Files") + " " + t("FILE_STATUS_PENDING")}</p>)
  }

  const handleStatusFile = (fileIndex, status) => {
    if (status === 'DENIED') {
      props.showAlert(t("Pending bank documentation details"),
        <AlertMessageDetails {...props} actionConfirm={changeStatusFile} fileIndex={fileIndex} status={status} />, [], 'm')
    } else {
      changeStatusFile(fileIndex, status, '', '')
    }
  }

  const handleApprovedAccount = () => {
    let promises = prepareSaveAccount(true)
    Promise.all(promises).then((result) => {
      handleChangeValue({ target: { name: 'account.status', value: 'REVISED' } })
      props.showAlert(t("Pending bank documentation details"), <p>{t("Account Revised")}</p>)
    }, (err) => {
      props.showAlert(t("Error"), <p>{err}</p>)
    })


  }

  const handleApprovedAll = () => {
    let promiseFiles = prepareFileStatusChange('all', 'APPROVED')
    //primero aprobamos todos los ficheros pendientes.     
    let error = []
    Promise.all(promiseFiles).then((resultFiles) => {
      refreshFiles()
      let promiseAccount = prepareSaveAccount(true)
      Promise.all(promiseAccount).then((resultAccount) => {
        props.showAlert(t("Pending bank documentation details"), <p>{t("All approved")}</p>)
      }, (err) => {
        error.push("Error en Approval Account")
      })
    }, (err) => {
      error.push(t('Error Approval Files'))
    })
    //aprobamos cuenta, que tambien salva si habido algo modificado 
    if (error.length) props.showAlert(t("Error"), <p>{error.join("<br/>")}</p>)
  }
  const prepareSaveAccount = (revised) => {
    let promises = []
    let adminClient = new AdminClient()
    let account = accountDetails.account
    if (revised) {
      account.status = 'REVISED'
    }
    promises.push(adminClient.saveUserPaymentAccountDetails(JSON.parse(utils.getSessionItem("user")).session, accountDetails.account.company,
      accountDetails.account.db,
      accountDetails.userId,
      JSON.stringify(account)))
    return promises
  }
  const handleSaveAccount = () => {
    let promises = prepareSaveAccount()
    Promise.all(promises).then((result) => {
      props.showAlert(t("Pending bank documentation details"), <p>{t("Account Saved")}</p>)
    }, (err) => {
      props.showAlert(t("Error"), <p>{err}</p>)
    })
  }
  const showUploadVerification = () => {
    if (document.getElementById('uploadFileVerification').classList.contains("hidden")) {
      document.getElementById('uploadFileVerification').classList.remove("hidden")
    }
    else {
      document.getElementById('uploadFileVerification').classList.add("hidden")
    }
  }
  const saveVerificationFile = (urls) => {
    let adminClient = new AdminClient()
    let file = {
      db: accountDetails.userDb,
      user: accountDetails.userId,
      company: accountDetails.userCompany,
      status: 'APPROVED',
      type: 'VERIFICATION',
      expiration_date: null,
      path: urls[0],
      comments: null,
      extra: null,
      reviewer: JSON.parse(utils.getSessionItem("user")).user,
      review_date: utils.formatDate(new Date()),
      payment_account: accountDetails.account.payment_account
    }
    adminClient.newFile(JSON.parse(utils.getSessionItem("user")).session, JSON.stringify(file)).then((result) => {
      let f = result.data
      adminClient.reviewFileBank(JSON.parse(utils.getSessionItem("user")).session, f.company, f.db, f.user, f.file, 'APPROVED', '', '').then((data) => {
        refreshFiles()
        document.getElementById('uploadFileVerification').classList.add("hidden")
      }, (err) => { console.log("Error validando fichero de verifición") })
    }, (err) => {
      console.log("Error salvando fichero")
    })
  }

  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      {/* Datos player */}
      <div className="row" key="botonera_account">
        <div className="col-12" >
          {utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS') && <>
            <button disabled={!accountDetails.canApproveAccount} className="btn btn-secondary float-end" key="approvedAccount" onClick={handleApprovedAccount}>{t("Approve") + " " + t("Payment Account")} </button>
            <button className="btn btn-secondary float-end" key="saveAccount" onClick={handleSaveAccount}>{t("Save") + " " + t("Payment Account")} </button>

            {utils.isGranted('SAVE_USER_FILES_BANK') &&
              <button className="btn btn-secondary float-end" key="approvedAll" onClick={handleApprovedAll}>{t("Approve") + " " + t("Files") + " " + t("and") + " " + t("Payment Account")}</button>}
            <button className="btn btn-secondary float-end" key="goToBack" onClick={goToBack}>{t("Close")} </button>
          </>}
        </div>
      </div>
      <div className="row" key="Datos">
        <div className="col-12 col-md-3">
          <TextField key="userId" disabled label={t("ID")} InputLabelProps={{ shrink: accountDetails.userName !== null }} value={accountDetails.userId} />
        </div>
        <div className="col-12 col-md-3">
          <TextField key="userName" disabled label={t("Name")} InputLabelProps={{ shrink: accountDetails.userName !== null }} value={accountDetails.userName} />
        </div>
        <div className="col-12 col-md-3">
          <TextField key="userLastName" disabled label={t("LastName")} InputLabelProps={{ shrink: accountDetails.userLastName !== null }} value={accountDetails.userLastName} />
        </div>
        <div className="col-12 col-md-3">
          <TextField key="userMiddleName" disabled label={t("MiddleName")} InputLabelProps={{ shrink: accountDetails.userMiddleName !== null }} value={accountDetails.userMiddleName || ''} />
        </div>

        <div className="col-12 col-md-3">
          <DateAndTimePicker
            key="userBirthDate"
            disabled
            renderInput={(props) => <TextField  {...props} />}
            label={t("Birthday")}
            value={(accountDetails.userBirthDate || '')}
            showTime={false}
          />
        </div>
        {accountDetails.account.payment_account !== undefined && <>
          <div className="col-12 col-md-3">
            <DateAndTimePicker
              key="accountCreatedDate"
              disabled
              renderInput={(props) => <TextField  {...props} />}
              label={t("Created date") + "-" + t("Payment Account")}
              value={(accountDetails.account.created_date || '')}
              showTime={false}
            />
          </div>
          <div className="col-12 col-md-3">
            <TextField key="userCurrency" disabled label={t("Currency")} InputLabelProps={{ shrink: accountDetails.userCurrency !== null }} value={accountDetails.userCurrency} />
          </div>
          <div className="col-12 col-md-3">
            <TextField key="accountStatus" disabled label={t("Status")} InputLabelProps={{ shrink: accountDetails.account.status !== null }} value={accountDetails.account.status || ''} />
          </div>
          <div className="col-12 col-md-3">
            <TextField key="accountPaymentAccount" disabled label={t("Account ID")} InputLabelProps={{ shrink: accountDetails.account.payment_account !== null }} value={accountDetails.account.payment_account} />
          </div>
          {Object.keys(accountDetails.account.details).map((idx) => {
            let field = accountDetails.account.details[idx];
            return (<div className="col-12 col-md-3">
              <TextField key={"account.details." + idx} disabled={!utils.isGranted('SAVE_USER_PAYMENT_ACCOUNTS')} label={t(idx)} name={"account.details." + idx} InputLabelProps={{ shrink: field !== null }} value={field || ''} onChange={handleChangeValue} />

            </div>)
          })}
        </>}
      </div>
      <div className="row" key="botonera_files">
        <div className="col-12">
          {utils.isGranted('SAVE_USER_FILES_BANK') && <> <button className="btn btn-primary float-end" key="approvedAllFiles_button" onClick={() => { handleStatusFile('all', 'APPROVED') }}>{t("Approve") + " " + t("All") + " " + t("Files")}</button>
            <button className="btn btn-primary float-end" key="deniedAllFiles_button" onClick={() => { handleStatusFile('all', 'DENIED') }}>{t("Deny") + " " + t("All") + " " + t("Files")}</button>
            <button className="btn btn-primary float-end" key="uploadVerification_button" onClick={() => { showUploadVerification() }}>{t("Upload") + " " + t("FILE_TYPE_VERIFICATION")}</button></>}
        </div>
      </div>
      <LayoutSection {...props} dataHeader={dataHeaderFiles}>
        <div id="uploadFileVerification" className="row hidden" >
          <div className="Border_Label">
            <File
              dropEnable={true}
              showFile={false}
              showError={true}
              numFiles={1}
              viewerEnable={false}
              deleteEnable={false}
              saveData={(urls) => { saveVerificationFile(urls) }}
              getFileUrl={(data) => {
                return accountDetails.userCompany +
                  "/" +
                  Math.floor(accountDetails.userId / 1000) +
                  "/" +
                  accountDetails.userId +
                  "/" +
                  data.fileName;
              }}
              type={'USER_FILE'}
              owner={JSON.stringify({ user: accountDetails.userId })}
              showAlert={props.showAlert}
              onlyUpload={true}
            />
          </div>
        </div>
        {accountDetails.bankFiles.map((file, idx) => {
          let adminClient = new AdminClient();
          file.user = accountDetails.userId;

          return (<>
            <div className="row">
              <div className="col-12 col-md-6">
                <File
                  key={"fichero_" + file.file}
                  onlyViewer={true}
                  inputFile={adminClient.getFile(JSON.parse(utils.getSessionItem("user")).session, file)}
                  inputFileExtension={file.path.substring(file.path.lastIndexOf('.'))}
                />
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  {file.type === 'VERIFICATION' &&
                    <div className="col-12 col-md-4">
                      <TextField key={"type_" + file.file} disabled label={t("Type")} InputLabelProps={{ shrink: file.type !== null }} value={t("FILE_TYPE_" + file.type) || ''} />
                    </div>
                  }
                  {(file.status === 'APPROVED' || file.status === 'DENIED') && <>
                    <div className="col-12 col-md-4">
                      <TextField key={"reviewer_" + file.file} disabled label={t("Reviewer")} InputLabelProps={{ shrink: file.reviewer_name !== null }} value={file.reviewer_name || ''} />
                    </div>
                    <div className="col-12 col-md-4">
                      <DateAndTimePicker
                        key={"reviewer_date" + file.file}
                        disabled
                        renderInput={(props) => <TextField  {...props} />}
                        label={t("Review date")}
                        value={(utils.formatDate(file.review_date) || '')}
                        showTime={true}
                      />
                    </div>
                  </>}
                  {(file.type === 'BANK') &&
                    <div className="col-12 col-md-4">
                      <TextField key={"status_" + file.file} disabled label={t("Status")} InputLabelProps={{ shrink: file.status !== null }} value={t("FILE_STATUS_" + file.status) || ''} />
                    </div>}
                  {file.status === 'DENIED' &&
                    <div className="col-12 col-md-12">
                      <TextField multiline key={"reason_" + file.file} disabled label={t("Reason")} value={file.comments} name="reason"></TextField>
                    </div>
                  }
                </div>
                {file.type === 'BANK' &&
                  <div className="row">
                    <div className="col-12 col-md-12">
                      {file.status !== 'APPROVED' && utils.isGranted('SAVE_USER_FILES_BANK') && <button className="btn btn-primary float-end" key={"Approved_" + file.file} onClick={() => { handleStatusFile(idx, 'APPROVED') }}>{t("Approve")}</button>}
                      {file.status !== 'DENIED' && utils.isGranted('SAVE_USER_FILES_BANK') && <button className="btn btn-primary float-end" key={"Cancel_" + file.file} onClick={() => { handleStatusFile(idx, 'DENIED') }}>{t("Deny")}</button>}
                    </div>
                  </div>
                }
              </div>
            </div>
          </>)
        })}
      </LayoutSection>
    </LayoutSection>
  )
}

export default withTranslation()(PendingBankDetails)
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import AdminClient from "../../AdminClient"
import utils from "../../utils"

import DoubleList from '../../components/basic/DoubleList';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import BasicSelect from '../../components/basic/BasicSelect';
import { TextField } from '@mui/material';
import useFormValidation from '../../hooks/parsleyValidation';


function UserManualPromotion(props) {
    const adminClient = new AdminClient();
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { t } = props;

    const [promotionDetails, setPromotionDetails] = useState({
        promotion: "",
        base_amount: 0,
        comments: ""
    })

    const handleSubmit = async () => {
        if (formValidation.validate()) {
            let response = await adminClient.getPromotionDetails(session, company, promotionDetails.promotion);
            let promotion = response.data.promotion;
            let type = "fs";
            let amount = JSON.parse(JSON.stringify(promotionDetails)).base_amount;
            if (amount !== '' && amount !== undefined) {
                if (promotion.type === 'INTERNAL' || promotion.config?.externalConfig?.provider === 'altenar' || promotion.config?.externalConfig?.provider === 'kambi') {
                    amount = amount * 100;
                    type = "currency";
                }
            }
            adminClient.batchPromotion(
                session,
                company,
                promotionDetails.promotion,
                amount,
                utils.getSessionItem("current-user"),
                promotionDetails.comments,
                type,
                "" + utils.getSessionItem("current-user") + "." + new Date().getTime()
            ).then(
                function (msg) {
                    if (msg.result === "OK") {
                        props.showAlert(t("Manual promotion"), t("Sent succesfull"));
                    } else {
                        props.showAlert(
                            t("Manual promotion"),
                            t("Sending error") + " " + msg.description
                        );
                    }
                },
                function (err) {
                    props.showAlert(
                        t("Manual promotion"),
                        t("Sending error") + " " + err.description
                    );
                }
            );
        }
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setPromotionDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const goBack = (evt) => {
        props.history.goBack();
    }

    const dataHeader = {
        backLink: goBack,
        title: t("Manual promotion"),
        urlHelp: "",
        idDoc: props.idDoc
    };

    const { formValidation } = useFormValidation("Form_ManualPromotion");

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <form id="Form_ManualPromotion">

                    <div className="row">
                        <div className="col-12    ">

                            <BasicSelect
                                label={t("Promotion")}
                                idProperty="promotion"
                                name="promotion"
                                nameProperty="promotion"
                                data="promotions"
                                basicInfoName="ManualPromotions"
                                onChange={onChangeHandler}
                                value={promotionDetails.promotion}
                                required
                            />
                        </div>
                        <div className="col-12    ">
                            <TextField label={t("Base amount")} type="number" value={promotionDetails.base_amount} name="base_amount" onChange={onChangeHandler} />
                        </div>
                        <div className="col-12    ">
                            <TextField multiline label={t("Comments")} type="number" value={promotionDetails.comments} name="comments" onChange={onChangeHandler} />
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary" onClick={handleSubmit}>{t("Send")}</button>
                    </div>
                </div>

            </LayoutSection>
        </>
    )

}

export default withTranslation()(UserManualPromotion)


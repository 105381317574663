import React from "react"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import utils from '../utils';
import AdminClient from "../AdminClient";

import { TextField } from "@mui/material";
import DocButton from "../components/basic/DocButton";


class BCWinningsBatch extends React.Component {

  constructor(props) {
    super(props);

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }

    this.user = JSON.parse(utils.getSessionItem("user")).user;

    this.update = this.update.bind(this);
    this.handleUpdateComment = this.handleUpdateComment.bind(this);
    this.handleUpdateOperationsList = this.handleUpdateOperationsList.bind(this);


    this.state = { status: [] };



    if (window.sections === undefined || window.sections === null) {
      window.sections = JSON.parse(utils.getSessionItem("sections"));
    }
    if (window.sections === undefined || window.sections === null) {
      this.props.history.push('/login');
    }
    this.adminClient = new AdminClient();

  }


  update() {
    const { t } = this.props;


    var operations = this.state.operations.split(/\r?\n/);
    alert(operations.length);

    var promise = this.adminClient.bcWinningsBatch(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), JSON.stringify(operations), this.state.comment);

    promise.then(
      function (msg) {
        console.log("Result: " + JSON.stringify(msg));

        if (msg.operationsErrors.length > 0) {
          var str = "Error\r\n\r\n";
          str += "" + msg.operationsErrors.length + " operations were not processed.\r\n";
          msg.operationsErrors.forEach(element => {
            str += element.operation + " Reason: " + element.error + ".\r\n";
          });
          alert(str);
        } else {
          alert("OK");
        }
      }.bind(this)
      , function (err) {
        alert("Error: " + err.description);
      });

  }


  handleUpdateOperationsList(evt) {
    this.setState({ operations: evt.target.value });
  }

  handleUpdateComment(evt) {
    this.setState({ comment: evt.target.value });
  }


  render() {
    const { t } = this.props;


    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="card min-vh-75">
          <div className="card-header">
            <div className="row">
              <h3>{t("BC winnings retry")}</h3>
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
            </div>
            <div className="row" >
              <div className="col-12 ">
                <TextField multiline label={t("Operations")} value={this.state.operationsList} onChange={this.handleUpdateOperationsList}></TextField>
              </div>

              <div className="col-12 ">
                <button type="button" className="btn btn-primary" onClick={(this.update)}>{t("Send")}</button>
              </div>

            </div>
          </div>

        </div>

      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(BCWinningsBatch))





import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { TextField } from '@mui/material';
import useFormValidation from '../../hooks/parsleyValidation';
import AdminClient from '../../AdminClient';
import utils from "../../utils";

const PromotionsTSBatch = (props) => {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_batch_promotion')

    const [batchDetails, setBatchDetails] = useState({
        base_amount: "",
        comments: "",
        freebet: "",
        player_list: ""
    })

    const goBack = () => {
        props.history.goBack();
    }
    const dataHeader = {
        backLink: goBack,
        title: t('Batch promotions'),
        urlHelp: '',
        idDoc: props.idDoc
    }
    const onChangeHandler = (evt) => {
        const { name, value } = evt.target || evt;
        setBatchDetails((prevState) => ({ ...prevState, [name]: value }));
    };


    const send = async () => {
        if (formValidation.validate()) {
            adminClient.batchPromotionTS(
                session,
                company,
                batchDetails.freebet,
                batchDetails.base_amount,
                batchDetails.player_list,
                batchDetails.comments,
                "" + new Date().getTime()
            ).then(
                function (msg) {
                    props.showAlert(t("Batch promotion"),
                        <>
                            <div className="row">{t("Batch promotion TS executed")}</div>
                            {msg.errors !== undefined && msg.errors.length > 0 &&

                                msg.errors.map((el, index) => {
                                    return <div className="row">{el.player} : {el.description}</div>
                                })

                            }
                        </>
                    );
                }.bind(this),
                function (err) {
                    props.showAlert(t("Batch promotion"), t("Processing Error") + err.description)
                });
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_batch_promotion">
                <div className="row">
                    <div className="col-12    ">
                        <TextField label={t("Freebet")} type="text" name="freebet" value={batchDetails.freebet} onChange={onChangeHandler} />
                    </div>

                    <div className="col-12    ">
                        <TextField type="number" label={t("Base amount")} name="base_amount" value={batchDetails.base_amount} onChange={onChangeHandler} />

                    </div>
                    <div className="col-12  ">
                        <TextField multiline placeholder={t("national_id<,amount>")} name="player_list" label={t("Players list")} type="text" value={batchDetails.player_list} onChange={onChangeHandler} />
                    </div>
                </div>
            </form>

            <div className="row">
                <div className="col-12">
                    <button className="btn btn-primary" onClick={send}>{t("Execute")}</button>
                </div>
            </div>
        </LayoutSection>
    );
};
export default withTranslation()(PromotionsTSBatch)

import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import JSONInput from 'react-json-editor-ajrm';
import BasicSelect from '../../components/basic/BasicSelect';
import locale from 'react-json-editor-ajrm/locale/en';
import EditorJSON from '../../components/basic/EditorJSON';


function WebhookDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_WebhookDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [JSONErrors, setJSONErrors] = useState(false);
    const webhook = utils.getSessionItem('current-webhook')
    const [webhookDetails, setWebhookDetails] = useState({
        webhook: webhook,
        name: "",
        config: {
            url: "",
            headers: null,
            filter: null,
            preprocessor: null,
            data: null
        }

    });
    const goBack = () => {
        props.history.push('/reports/report/webhooks/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Webhook details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (webhook !== undefined && webhook !== '-1') {
            adminClient.getWebhookDetails(session, company, webhook).then(
                async function (result) {
                    const { config } = result.data
                    result.data.config = JSON.parse(config);
                    setWebhookDetails(result.data);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setWebhookDetails((prevState) => ({ ...prevState, [name]: value }));
    };




    const onChangeHandlerConfig = (e) => {
        const { name, value } = e.target || e;
        setWebhookDetails(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                [name]: value,
            }
        }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            if (JSONErrors) {
                props.showAlert(t('Provider details'), t('JSON format incorrect'))
            }
            else {
                adminClient.saveWebhook(
                    session,
                    company,
                    JSON.stringify(webhookDetails)
                ).then(
                    (msg) => {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Webhook details'), t('Saved succesfull'))
                            if (addOrUpdate === 'add') {
                                setAddOrUpdate('update')
                            }
                        } else {
                            props.showAlert(t('Webhook details'), t('Saving error ') + msg.description)
                        }
                    },
                    (error) => {
                        props.showAlert(t('Webhook details'), t('Saving error ') + t(error.description))
                    }
                )
            }
        }
    }

    const handleRemove = (evt) => {
        props.showAlert(
            t('Role details'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

    }
    const remove = (evt) => {
        adminClient.deleteWebhook(
            session,
            company,
            JSON.stringify(webhookDetails)
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Webhook details'), t('Deleted succesfull'))
                    goBack()
                } else {
                    props.showAlert(t('Webhook details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('Webhook details'), t('Deleting error ') + t(error.description))
            }
        )
    }

    const onChangeHandlerJSON = (data, name) => {
        setWebhookDetails(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                [name]: data,
            }
        }));
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_WebhookDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="webhook"
                            required
                            disabled
                            type="text"
                            value={webhookDetails.webhook}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-5">
                        <TextField
                            label={t("Name")}
                            name="name"
                            type="text"
                            required
                            value={webhookDetails.name}
                            onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            label={t("Event")}
                            nameProperty="event"
                            idProperty="event"
                            name="event"
                            required
                            basicInfoName="EventTypes"
                            onChange={onChangeHandler}
                            filters={[{ property: "queue", value: "webhooks" }]}
                            translate={{ property: "event", prefix: "" }}
                            value={webhookDetails.event}
                        />
                    </div>
                    <div className="col-12    ">
                        <TextField
                            label={t("URL")}
                            name="url"
                            type="text"
                            value={webhookDetails.config.url}
                            onChange={onChangeHandlerConfig}
                        />
                    </div>
                    <div className="col-md-6">
                        <EditorJSON
                            setError={(status) => { setJSONErrors(status) }}
                            onChange={(data) => onChangeHandlerJSON(data, "headers")}
                            value={webhookDetails.config.headers}
                            name={t("Headers")}
                        />
                    </div>

                    <div className="col-md-6">
                        <EditorJSON
                            setError={(status) => { setJSONErrors(status) }}
                            onChange={(data) => onChangeHandlerJSON(data, "filter")}
                            value={webhookDetails.config.filter}
                            name={t("Webhook filter")}
                        />
                    </div>
                    <div className="col-md-6">
                        <EditorJSON
                            setError={(status) => { setJSONErrors(status) }}
                            onChange={(data) => onChangeHandlerJSON(data, "data")}
                            value={webhookDetails.config.data}
                            name={t("Data")}
                        />
                    </div>
                    <div className="col-md-6">
                        <EditorJSON
                            setError={(status) => { setJSONErrors(status) }}
                            onChange={(data) => onChangeHandlerJSON(data, "preprocessor")}
                            value={webhookDetails.config.preprocessor}
                            name={t("Preprocessor")}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                {utils.isGranted('SAVE_CONFIGURATION_WEBHOOKS') &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleRemove}
                        >
                            {t('Delete')}
                        </button>
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(WebhookDetails);
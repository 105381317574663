import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import AdminClient from '../../AdminClient'
import useFormValidation from '../../hooks/parsleyValidation'
import utils from "../../utils";
import { FormControlLabel, TextField } from '@mui/material'

const UserWagerLimits = (props) => {

    const company = utils.getCurrentCompany().company;
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UserWagerLimits')
    const [limits, setLimits] = useState(null)
    const user = utils.getSessionItem('current-user').split(".")
    const adminClient = new AdminClient()
    const dataHeader = {

        title: t('Wager Limits'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(() => {
        adminClient.getLimitWagersDetails(session, user[0], user[1], company).then((result) => {

            if (result.data.length > 0) {
                setLimits(result.data[0])
            }

        }, (err) => {
            console.log("Error obteniendoo limites")
        })

    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setLimits((prevLimits) => {
            prevLimits[name] = value * 100
            return { ...prevLimits }
        });
    };

    const saveLimits = () => {

        if (formValidation.validate()) {


            adminClient.saveLimitWagersDetails(session, JSON.stringify(limits), user[0], user[1]).then((msg) => {

                if (msg.result === 'OK') {
                    props.showAlert(t("Wager Limits"), <p>{t("Saved succesfull")}</p>);
                }
            }, (err) => {

                props.showAlert(t("Wager Limits"), <p>{t("Saving error ") + " " + err.description}</p>)
            })
        }
    }
    const createLimits = () => {
        setLimits({
            'daily': 0,
            'weekly': 0,
            'monthly': 0,
            'current_daily': 0,
            'current_weekly': 0,
            'current_monthly': 0
        })
    }
    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserWagerLimits">
                {limits !== null &&

                    <div className="row" >
                        <div className="col-12 col-md-4">
                            <TextField label={t("DAILY_WAGER")} required helperText={t("Of your established limit you have paid in the amount of") + " " + (limits.current_daily || 0) / 100} name="daily" type="number" value={limits.daily / 100} onChange={onChangeHandler} />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextField label={t("WEEKLY_WAGER")} required helperText={t("Of your established limit you have paid in the amount of") + " " + (limits.current_weekly || 0) / 100} name="weekly" type="number" InputProps={{ inputProps: { "data-parsley-min": limits.daily / 100, "data-parsley-min-message": t("No puede ser menor que ") + " " + t("DAILY_WAGER") } }} value={limits.weekly / 100} onChange={onChangeHandler} />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextField label={t("MONTHLY_WAGER")} required helperText={t("Of your established limit you have paid in the amount of") + " " + (limits.current_monthly || 0) / 100} name="monthly" type="number" InputProps={{ inputProps: { "data-parsley-min": limits.weekly / 100, "data-parsley-min-message": t("No puede ser menor que ") + " " + t("WEEKLY_WAGER") } }} value={limits.monthly / 100} onChange={onChangeHandler} />
                        </div>
                    </div>
                }
            </form>
            {limits !== null && utils.isGranted('SAVE_USER_LIMITS_WAGERS') && <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={saveLimits}>
                        {t('Save')}
                    </button>

                </div>
            </div>}
            {limits === null &&


                <div className="col-12 col-md-4">
                    <FormControlLabel
                        labelPlacement="start"
                        control={utils.isGranted('SAVE_USER_LIMITS_WAGERS') ? <button
                            type="button"
                            className="btn btn-primary"
                            onClick={createLimits}>
                            {t('Create')}
                        </button> : <></>}
                        label={t('No wager Limits')} />
                </div>



            }

        </LayoutSection>
    </>
    )
}

export default withTranslation()(UserWagerLimits)
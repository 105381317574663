import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import UploaderField from '../../components/basic/UploaderField';
import ProgressBar from '../../components/basic/ProgressBar';
import BasicTable from '../../components/basic/BasicTable';
function DepositsRevisionBatch(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_DepositsRevisionBatch')
    const [onProgress, setOnProgress] = useState(false);
    const [barProgress, setBarProgress] = useState({ show: false, text: "", percentage: "0%", textOK: false, textNOK: false })

    const [depositRevision, setDepositRevision] = useState({
        account: "",
        state: "",
        operations: "",
        results: [],
        resultsCSV: "",
        resultsCSVOperations: ""
    });

    const tableColumns = [
        { Header: t("Operation"), accessor: 'operation', typeCell: 'text', filter: 'text' },
        { Header: t("User"), accessor: 'user', typeCell: 'text', filter: 'text' },
        { Header: t("Amount"), accessor: 'amount', typeCell: 'text', filter: 'text' },
        { Header: t("Status"), accessor: 'status', typeCell: 'translate', filter: 'translate', prefixTranslate: "DEPOSIT_STATUS_" }
    ]

    const goBack = () => {
        props.history.push('/home');
    };

    const dataHeader = {
        backLink: goBack,
        title: t('Deposits revision batch'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {

    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setDepositRevision((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)

        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = percentage + '%';
            auxBarProgress.text = text + ": " + data.partial + "/" + data.total;
            if (data.textOK || data.textOK === 0) auxBarProgress.textOK = t("Operations correct") + ": " + data.textOK
            if (data.textNOK || data.textOK === 0) auxBarProgress.textNOK = t("Operations errors") + ": " + data.textNOK
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });
        if (data.results !== undefined) {
            onChangeHandler({ name: "results", value: data.results })
        }
    };

    const send = (file) => {
        if (file !== undefined || formValidation.validate()) {
            setBarProgress(prevBarProgress => {
                let auxBarProgress = { ...prevBarProgress }
                auxBarProgress.show = true;
                auxBarProgress.percentage = '0%'
                auxBarProgress.text = t('Checking operations')
                auxBarProgress.textOK = false;
                auxBarProgress.textNOK = false;
                return { ...prevBarProgress, ...auxBarProgress };
            });
            onChangeHandler({ name: "results", value: [] });
            setOnProgress(true)
            let operations = "";
            if (file === undefined) {
                operations = depositRevision.operations.split(/\r?\n/);
            }
            setDepositRevision((prevState) => ({ ...prevState, operations: [] }));
            adminClient.manualDepositRevisionBatch(
                session, company, operations, depositRevision.account, depositRevision.state, handleResponse, file
            ).then(
                (msg) => {
                    let resultsCSV = "";
                    let resultsCSVOperations = "";

                    for (let idx in msg.results) {
                        let operation = msg.results[idx];
                        if (operation.db === undefined) {
                            resultsCSVOperations += operation.operation + "\r\n"
                            resultsCSV += operation.operation + "\t" + 0 + "\t" + operation.status + "\r\n";
                        } else {
                            resultsCSVOperations += operation.db + "." + operation.operation + "\r\n"
                            resultsCSV += operation.db + "." + operation.operation + "\t" + operation.amount / 100 + "\t" + operation.status + "\t" + operation.db + "." + operation.user + "\r\n";
                        }

                    }

                    setOnProgress(false);
                    setBarProgress(prevBarProgress => {
                        let auxBarProgress = { ...prevBarProgress }
                        auxBarProgress.percentage = '100%'
                        auxBarProgress.show = true;
                        auxBarProgress.text = t('Checked operations')
                        const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                        return updatedBarProgress;
                    });
                    setDepositRevision((prevState) => ({
                        ...prevState,
                        results: msg.results,
                        resultsCSV: resultsCSV,
                        resultsCSVOperations: resultsCSVOperations
                    }));
                },
                (error) => {
                    setOnProgress(false);

                    props.showAlert(t('Deposits revision'), t('Saving error ') + t(error.description))
                }
            )
        }

    }


    const copyResults = () => {
        props.showAlert(t('Deposits revision'), t('Copy correct'))
        navigator.clipboard.writeText("Operation\t\Amount\t\Status\t\User\n" + depositRevision.resultsCSV);
    }

    const copyResultsOperations = () => {
        props.showAlert(t('Deposits revision'), t('Copy correct'))
        navigator.clipboard.writeText(depositRevision.resultsCSVOperations);
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_DepositsRevisionBatch">
                <div className="row">
                    <div className="col-12 ">
                        <BasicSelect
                            label={t("Method")}
                            idProperty="method"
                            name="account"
                            basicInfoName="DepositMethods"
                            onChange={onChangeHandler}
                            value={depositRevision.account}
                            noEmptyValue
                        />
                    </div>
                    <div className="col-12 ">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            nameProperty="status"
                            name="state"
                            data="status"
                            basicInfoName="DepositsStatus"
                            onChange={onChangeHandler}
                            value={depositRevision.state}
                            translate={{ property: "status", prefix: "DEPOSIT_STATUS_" }}
                            noEmptyValue
                        />
                    </div>
                    <div className="col-12 ">
                        <TextField required multiline label={t("Operations")} name="operations" value={depositRevision.operations} onChange={onChangeHandler}></TextField>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <UploaderField
                        label="CSV"
                        acceptedFiles=".csv"
                        showAlert={props.showAlert}
                        handleCloseAlert={props.handleCloseAlert}
                        callBackFunction={send}
                        disabled={onProgress}
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={
                            (evt) => {
                                send()
                            }
                        }
                        disabled={onProgress}
                    >
                        {t('Send')}
                    </button>
                </div>
                <div className='col-12'>
                    {barProgress.show &&
                        <ProgressBar percentage={barProgress.percentage}
                            text={barProgress.text}
                            textOK={barProgress.textOK}
                            textNOK={barProgress.textNOK}
                        />
                    }
                </div>
            </div>
            {depositRevision.results.length > 0 &&
                <div className="container">
                    <BasicTable data={depositRevision.results} columns={tableColumns} showFilterColumn={true} />
                    <button type="button" className="btn btn-primary" onClick={copyResults}>{t("Copy")}</button>
                    <button type="button" className="btn btn-primary" onClick={copyResultsOperations}>{t("Copy operations")}</button>

                </div>
            }

        </LayoutSection>
    )
}
export default withTranslation()(DepositsRevisionBatch);
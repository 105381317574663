import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Tab from '../../components/basic/BasicTabs';
import AllowedForbiddenList from "../../components/basic/AllowedForbiddenList";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import EditorJSON from '../../components/basic/EditorJSON';
import WebContentData from '../../components/basic/WebContentData';
import { generateColumnsDef } from '../../libs/utilsReports';
import TableAndDetails from '../../components/basic/TableAndDetails';


function PaymentMethodErrorCodes(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PaymentMethodsErrorCodesDetails');
    const [addOrUpdateCode, setAddOrUpdateCode] = useState('');
    const [codeReport, setCodeReport] = useState({
        columns: [],
        data: []
    });
    const langsArray = JSON.parse(utils.getSessionItem("basicInfo-languages"))
    const desiredLangsArray = companyDetails.langs.split(',');
    const langs = langsArray.filter(lang => desiredLangsArray.includes(lang.lang));

    const initialCodeDetails = {
        error_code: "",
        error_code_client: "",
        description: "",
        langs: {
            ...langs.reduce((accumulator, value) => {
                let langPref = value.lang;
                accumulator[langPref] = "";
                return accumulator;
            }, {})
        }
    }
    const [codeDetails, setCodeDetails] = useState(initialCodeDetails);

    useEffect(function () {

        if (props.method !== undefined && props.method !== '-1') {
            Promise.all([
                adminClient.getReport(session, company, "payment_methods_errors", [{ "field": "t.method", "type": "text", "value": props.method }], utils.getSessionItem("current-user")?.split(".")[1], utils.getSessionItem("current-user")?.split(".")[0]),
            ]).then(
                function (result) {
                    let columnsDef = generateColumnsDef(result[0].data.def, t);
                    setCodeReport((prevState) => ({
                        ...prevState,
                        data: result[0].data.data,
                        columns: columnsDef,
                    }));
                },
                function (err) {
                    console.error(err);
                }
            )

        }
    }, []);

    const onChangeHandlerCode = (evt) => {
        const { name, value } = evt.target || evt;
        setCodeDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const onChangeHandlerCodeLang = (evt) => {
        const { name, value } = evt.target || evt;
        setCodeDetails(prevState => ({
            ...prevState,
            langs: {
                ...prevState.langs,
                [name]: value,
            }
        }));
    }
    const reloadReport = () => {
        adminClient.getReport(session, company, "payment_methods_errors", [{ "field": "t.method", "type": "text", "value": props.method }], utils.getSessionItem("current-user")?.split(".")[1], utils.getSessionItem("current-user")?.split(".")[0]).then(
            function (result) {
                let columnsDef = generateColumnsDef(result.data.def, t);
                setCodeReport((prevState) => ({
                    ...prevState,
                    data: result.data.data,
                    columns: columnsDef,
                }));
            },
            function (err) {
                console.error(err);
            }
        )

    }
    const addOrUpdateCodeHandler = async (detailReport) => {
        formValidation?.reset()

        if (detailReport === -1) {
            setCodeDetails(initialCodeDetails)
            setAddOrUpdateCode("add");
        } else {
            adminClient.getPaymentMethodsErrorCodeI18n(session, company, detailReport.method, detailReport.error_code).then(
                function (result) {
                    let detailsI18n = result.data;
                    setCodeDetails((prevState) => {
                        let oldState = { ...prevState };
                        oldState.langs = initialCodeDetails.langs
                        detailsI18n.forEach(element => {
                            oldState.langs[element.lang] = element.description
                        });
                        oldState.error_code = detailReport.error_code;
                        oldState.error_code_client = detailReport.error_code_client;
                        oldState.description = detailReport.description;
                        return oldState;
                    });
                    setAddOrUpdateCode("update");
                },
                function (err) {
                    console.log(err)
                }
            )
        }
    };
    const saveCodeDetails = (evt) => {

        if (formValidation?.validate()) {
            adminClient[addOrUpdateCode + "PaymentMethodsErrorCode"](session, company, props.method, JSON.stringify(codeDetails)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('PaymentMethod details'), t('Saved succesfull'))
                        if (addOrUpdateCode === 'add') {
                            setAddOrUpdateCode('update')
                        }
                        reloadReport();

                    } else {
                        props.showAlert(t('PaymentMethod details'), t('Saving error ') + t(msg.description))
                    }
                },
                (error) => {
                    props.showAlert(t('PaymentMethod details'), t('Saving error ') + t(error.description))
                }
            )
        }
    }
    const deleteCodeDetails = (error_id) => {
        let error_code = codeReport.data[error_id].error_code
        adminClient.deletePaymentMethodsErrorCode(session, company, props.method, error_code).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('PaymentMethod details'), t('Deleted succesfull'))
                    reloadReport();
                } else {
                    props.showAlert(t('PaymentMethod details'), t('Deleting error ') + t(msg.description))
                }
            },
            (error) => {
                props.showAlert(t('PaymentMethod details'), t('Deleting error ') + t(error.description))
            }
        )
    }
    const renderCodes = useCallback((props) => {
        return (
            <>
                <form id="Form_PaymentMethodsErrorCodesDetails">

                    <div className="row">
                        <div className="col-12">
                            <TextField
                                label={t('Error code')}
                                name="error_code"
                                required
                                disabled={addOrUpdateCode === 'update'}
                                inputProps={addOrUpdateCode === "add" ? { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') } : {}}
                                type="text"
                                value={codeDetails.error_code}
                                onChange={onChangeHandlerCode}
                            />
                        </div>
                        <div className="col-12">
                            <TextField
                                label={t('Error client code')}
                                name="error_code_client"
                                required
                                inputProps={addOrUpdateCode === "add" ? { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') } : {}}
                                type="text"
                                value={codeDetails.error_code_client}
                                onChange={onChangeHandlerCode}
                            />
                        </div>
                        <div className="col-12">
                            <TextField
                                label={t("General description")}
                                type="text"
                                name="description"
                                required
                                multiline
                                inputProps={{ maxLength: 1000 }}
                                value={codeDetails.description}
                                onChange={onChangeHandlerCode}
                            />
                        </div>
                        {langs.map((value) => {

                            return (
                                <div className="col-12">
                                    <TextField
                                        label={t(value.name)}
                                        type="text"
                                        name={value.lang}
                                        required
                                        multiline
                                        inputProps={{ maxLength: 1000 }}
                                        value={codeDetails.langs[value.lang]}
                                        onChange={onChangeHandlerCodeLang}
                                    />
                                </div>
                            );
                        })}
                    </div >
                </form>
            </>

        );
    }, [JSON.stringify(props), codeDetails, addOrUpdateCode]);

    return (

        <TableAndDetails
            colTableSize={6}
            sizePageTable={10}
            ShowNew={true}
            columns={codeReport.columns}
            data={codeReport.data}
            renderDetails={renderCodes}
            setDetail={addOrUpdateCodeHandler}
            showDeleteButton={addOrUpdateCode === "update"}
            deletehandler={deleteCodeDetails}
            showSaveButton
            disabledSaveButton={codeDetails.error_code === ""}
            handleSaveDetails={() => { saveCodeDetails() }}
            showAlert={props.showAlert}
            handleCloseAlert={props.handleCloseAlert}
            desactiveForm={true}
        />

    )
}

function PaymentMethodsDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_PaymentMethodsDetails')
    const methodId = utils.getSessionItem('current-method')

    const [addOrUpdate, setAddOrUpdate] = useState('add');

    const [JSONErrors, setJSONErrors] = useState(false);

    const [paymentMethodDetail, setPaymentMethodDetail] = useState({
        method: {
            status: "ENABLED",
            method: methodId,
            company: utils.getSessionItem("Company"),
            groups: [],
            config: {},
            name: "",
            show_order: 0,
            cms: {}
        },
        groups_allowed: [],
        groups_availables: [],
        groups_forbbiden: [],
    });
    //HEADER
    const goBack = () => {
        props.history.push('/reports/report/payment_methods/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Payment method details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //Carga de datos
    useEffect(function () {
        if (methodId !== undefined && methodId !== '-1') {
            adminClient.getMethodDetails(session, company, methodId).then(
                function (result) {
                    let methodDetail = { ...paymentMethodDetail }
                    methodDetail.method = result.data.method;

                    methodDetail.method.groups.forEach(element => {
                        console.log("element : " + JSON.stringify(element))
                        if (element.relationship === 'ALLOWED') {
                            methodDetail.groups_allowed.push(element);
                        } else {
                            methodDetail.groups_forbbiden.push(element);
                        }
                    });

                    methodDetail.method.config = JSON.parse(methodDetail.method.config);
                    if (methodDetail.method.cms !== null)
                        methodDetail.method.cms = JSON.parse(methodDetail.method.cms);
                    else
                        methodDetail.method.cms = {};

                    console.log("Method = " + methodDetail.method.name);

                    methodDetail.groups_availables = result.data.groups_availables;


                    setPaymentMethodDetail(methodDetail);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);

    const onChangeHandlerMethod = (e) => {
        let methodAux = { ...paymentMethodDetail };
        const { name, value } = e.target || e;
        methodAux.method[name] = value;
        setPaymentMethodDetail(methodAux);
    };
    const handleUpdateConfig = (e) => {
        let methodAux = { ...paymentMethodDetail };
        methodAux.method.config = e;
        setPaymentMethodDetail(methodAux);
    };


    const onChangeHandlerGroup = (allowed, forbidden) => {
        let methodAux = { ...paymentMethodDetail };
        methodAux.groups_allowed = allowed;
        methodAux.groups_forbbiden = forbidden;
        setPaymentMethodDetail(methodAux);
    }
    //Manejadores modificacion
    const save = (evt) => {
        let methodAux = { ...paymentMethodDetail };
        let method = methodAux.method;
        let allowed_groups = [];
        let forbidden_groups = [];

        methodAux.groups_allowed.forEach(element => {
            allowed_groups.push(element.group);
        });
        methodAux.groups_forbbiden.forEach(element => {
            forbidden_groups.push(element.group);
        });

        method.forbidden_groups = forbidden_groups.join();
        method.allowed_groups = allowed_groups.join();


        if (formValidation.validate()) {
            if (JSONErrors) {
                props.showAlert(t('PaymentMethod details'), t('JSON format incorrect'))
            }
            else {
                adminClient.saveCompanyMethod(session, JSON.stringify(method)).then(
                    (msg) => {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Payment Method'), t('Saved succesfull'))
                            if (addOrUpdate === 'add') {
                                setAddOrUpdate('update')
                            }
                        } else {
                            props.showAlert(t('Payment Method'), t('Saving error ') + msg.description)
                        }
                    },
                    (error) => {
                        props.showAlert(t('Payment Method'), t('Saving error ') + error.description)
                    }
                )
            }
        }
    }
    const onChangeHandlerCMS = (value) => {
        setPaymentMethodDetail((prevDetails) => {
            let auxConfig = prevDetails.method;
            auxConfig.cms = value;
            return { ...prevDetails, ["method"]: auxConfig };
        });
    }

    let configRender = () => {
        return <>
            <div className="row">
                <div className="col-12">
                    <EditorJSON
                        setError={(status) => { setJSONErrors(status) }}
                        onChange={handleUpdateConfig}
                        value={paymentMethodDetail.method.config}
                    />
                </div>

            </div>
        </>
    }

    let groupsRender = () => {
        return <>
            <AllowedForbiddenList
                allowed={paymentMethodDetail.groups_allowed}
                availables={paymentMethodDetail.groups_availables}
                forbidden={paymentMethodDetail.groups_forbbiden}
                idProperty={"group"}
                nameProperty={"name"}
                handleUpdateGroups={onChangeHandlerGroup}
            ></AllowedForbiddenList>
        </>
    }

    let CMSRender = () => {
        return <>
            <div className="row">
                <div className="col-12">
                    <WebContentData
                        details={paymentMethodDetail.method}
                        cms={paymentMethodDetail.method.cms}
                        id={paymentMethodDetail.method.method}
                        type="PAYMENTMETHOD"
                        pathImage="/cms/img/paymentMethod/"
                        showAlert={props.showAlert}
                        onChangeHandler={onChangeHandlerCMS}
                    />
                </div>

            </div>
        </>
    }

    let ErrorCodesRender = () => {
        return <>
            <div className="row">
                <div className="col-12">
                    <PaymentMethodErrorCodes
                        showAlert={props.showAlert}
                        handleCloseAlert={props.handleCloseAlert}
                        method={paymentMethodDetail.method.method}
                        t={t} />

                </div>

            </div>
        </>
    }

    const [tabsContent, setTabsContent] = useState(
        [
            { title: t("Configuration"), content: configRender },
            { title: t("Groups"), content: groupsRender },
            { title: t("CMS"), content: CMSRender },
            { title: t("Error codes"), content: ErrorCodesRender }

        ]
    );

    useEffect(() => {
        let tabAux = [
            { title: t("Configuration"), content: configRender },
            { title: t("Groups"), content: groupsRender },
            { title: t("CMS"), content: CMSRender },
            { title: t("Error codes"), content: ErrorCodesRender }
        ]
        setTabsContent(tabAux)

    }, [paymentMethodDetail, JSON.stringify(paymentMethodDetail.method), addOrUpdate])



    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            {addOrUpdate === "update" &&
                <div>
                    <form id="Form_PaymentMethodsDetails">

                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <TextField disabled label={t("Method")} name="method" type="text" value={paymentMethodDetail.method.method} onChange={onChangeHandlerMethod} />
                            </div>

                            <div className="col-12 col-sm-4">
                                <TextField disabled label={t("Name")} name="name" type="text" value={paymentMethodDetail.method.name} onChange={onChangeHandlerMethod} />
                            </div>

                            <div className="col-12 col-sm-2">
                                <TextField label={t("Show order")} name="show_order" type="number" value={paymentMethodDetail.method.show_order} onChange={onChangeHandlerMethod} />
                            </div>

                            <div className="col-12 col-sm-2">
                                <BasicSelect
                                    idProperty="name"
                                    name="status"
                                    label={t("Status")}
                                    basicInfoName="EnabledDisabled"
                                    onChange={onChangeHandlerMethod}
                                    value={paymentMethodDetail.method.status}
                                    translate={{ property: "name", prefix: "" }}
                                    disableClearable
                                />
                            </div>
                        </div>

                    </form>
                    <div className="row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={save}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </div>
                    <div className='row'>
                        <Tab active={0}>
                            {
                                tabsContent.map((tab, idx) =>
                                    <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                        {tab.content()}
                                    </Tab.TabPane>
                                )
                            }
                        </Tab>
                    </div>
                </div>
            }
        </LayoutSection>
    )
}
export default withTranslation()(PaymentMethodsDetails);

import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection'
import utils from '../../utils'


const UtilsTestPage = (props) => {

  const dataHeader = {
    backLink: "",
    title: "Uso de Selects",
    urlHelp: "",
    idDoc: props.idDoc
  }

  const [tests, setTests] = useState([]);

  useEffect(async function () {

    let testsAux = [];

    let test1 = await utils.getTranslateBasicInfo("Banners", "banner", 1, "banners");
    testsAux.push(test1);

    let test2 = await utils.getTranslateBasicInfo("MachinesTypes", "type", "VIRTUAL_INTERNAL")
    testsAux.push(test2);

    setTests(testsAux);
  }, [])

  return (
    <>
      <LayoutSection dataHeader={dataHeader}>
        {tests.map((el, index) => {
          return (
            <div>
              <label>{el}</label>
            </div>
          )
        })}
      </LayoutSection>
    </>
  )
}

export default withTranslation()(UtilsTestPage)
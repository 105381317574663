import React from "react";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";

function PluginPage(props) {
    let path = props.location.pathname.split("/")
    let pluginId = path[path.length - 2]
    let url = path[path.length - 1]
    const iframeUrl = 'http://localhost:4000/' + url; // URL que deseas cargar en el iframe

    return (
        <LayoutSection>
            <h1>Contenido en React con iframe</h1>
            <iframe
                src={iframeUrl}
                title="Contenido Externo"
                className="iframePlugin"

            />
        </LayoutSection>
    )
}
export default (PluginPage);
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Report from '../../components/basic/Report';
import DateAndTimePicker from '../../components/DateAndTimePicker';
function BetDetails(props) {
    const companyId = utils.getSessionItem('Company');
    const company = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_BetDetails')
    const betId = utils.getSessionItem('current-bet').split(".");
    const [betDetails, setbetDetails] = useState({
        status: "OPEN",
        db: betId[0],
        operation: betId[1],
        game: "",
        odds: 0,
        winning: 0,
        wager: 0
    });
    const goBack = () => {
        props.history.push("/support/report/user_bets/table");
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Bet details'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    utils.setSessionItem("ReportFilter-events", JSON.stringify([{ field: "t.db", type: "=", value: betId[0] }, { field: "t.operation", type: "=", value: betId[1] }]));
    utils.setSessionItem("ReportFilterView-events", JSON.stringify([]));




    useEffect(function () {
        if (betId !== undefined && betId !== '-1') {
            adminClient.getBetDetails(session, betId[0], betId[1]).then(
                async function (result) {
                    const bet = result.data;
                    setbetDetails(bet);

                    if (company.config?.backoffice?.altenar?.widget_integration !== undefined) {
                        adminClient.newTempUserSession(session, bet.company, bet.db, bet.user, 'altenar').then(
                            async function (result) {
                                var altenarDiv = document.getElementById('altenar_selections');
                                var altenarScriptsDiv = document.getElementById('altenar_selections_scripts');


                                const script = document.createElement('script');
                                script.src = company.config.backoffice.altenar.widget_js;
                                script.id = 'altenarJS';
                                altenarScriptsDiv.appendChild(script);

                                script.onload = function () {
                                    const scriptConfig = document.createElement('script');
                                    let recorte = 0;
                                    if (bet.status === 'OPEN') {
                                        recorte = 90;
                                    }
                                    scriptConfig.innerHTML = ` 
                                    // initialize altenarWSDK
                                    
                                      window.altenarWSDK.init({
                                        integration: '${company.config.backoffice.altenar.widget_integration}',
                                        culture: '${company.config.backoffice.altenar.widget_culture}',
                                        token: "temp_altenar_${result.data.session}"
                                      });

                                      console.log("Altenar session = "+'temp_altenar_${result.data.session}')
                    
                                    setTimeout(() => {
                                      window.WBS = window.altenarWSDK.addWidget({
                                        widget: 'WBetDetails',
                                        props: {betId:${bet.game}},
                                         tokens: {
                                            BetBox: {
                                                paddingHorizontal: 20,
                                                },
                                            },
                                        container: document.getElementById('altenar_selections'),
                                      }
                                        );
                                      if (${recorte} !== 0 ) { 
                                      setTimeout(() => {
                                            let height = document.getElementById('altenar_selections').clientHeight;
                                            console.log("Details height = "+ height );
                                            let style = 'height: '+(height-${recorte})+'px !important; overflow: hidden;';
                                            document.getElementById('altenar_selections').setAttribute('style', style);
                                        //     document.getElementById('altenar_selections').style.overflow = 'hidden'
                                                
                                      }, 1000);
                                      }


                                    }, 1000);

                                        `

                                        ;

                                    console.log("Altenar config = " + JSON.stringify(scriptConfig.innerHTML));
                                    scriptConfig.id = 'altenarConfig';
                                    altenarScriptsDiv.appendChild(scriptConfig);
                                };
                            },
                            function (err) {
                                console.error(err);
                            }
                        );
                    }

                },
                function (err) {
                    console.error(err);
                }
            );
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setbetDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const update = (evt) => {
        if (formValidation.validate()) {
            adminClient.updateBet(
                session,
                companyId,
                JSON.stringify(betDetails)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Bet details'), t('Updated succesfull'))
                        window["reportLogs"].executeReport(0);

                    } else {
                        props.showAlert(t('Bet details'), t('Updating error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Bet details'), t('Updating error ') + error.description)
                }
            )
        }
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_BetDetails">
                <div className="row">
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Identifier")} type="text" value={betDetails.db + "." + betDetails.operation} />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Game")} type="text" value={betDetails.game} />
                    </div>

                    <div className="col-12 col-md-4 col-xxl-2">
                        <DateAndTimePicker
                            disabled
                            renderInput={(props) => <TextField  {...props} />}
                            label={t("Created date")}
                            value={(utils.formatDate(betDetails.created_date) || '')}
                            showTime={true}
                        />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("WAGER")} type="text" value={utils.formatCurrency(betDetails.wager / 100, betDetails.currency)} />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("WINNING")} type="text" value={utils.formatCurrency(betDetails.winning / 100, betDetails.currency)} />
                    </div>
                    <div className="col-12 col-md-4 col-xxl-2">
                        <TextField disabled label={t("Odds")} type="text" value={betDetails.odds} />
                    </div>
                    <div className="col-12 col-md-3 col-xxl-2">
                        <BasicSelect
                            label={t("Current status")}
                            disabled={!utils.isGranted('UPDATE_BET_STATUS')}
                            idProperty="status"
                            name="status"
                            nameProperty="status"
                            basicInfoName="BetStatus"
                            onChange={onChangeHandler}
                            value={betDetails.status}
                            translate={{ property: "status", prefix: "BET_STATUS_" }}
                        />
                    </div>
                </div>
                <div id="altenar_selections" onClick={(evt) => {console.log("Client on altenar_selections");evt.stopPropagation() }}></div>
                <div id="altenar_selections_scripts"></div>

                {(company.config.backoffice?.altenar?.widget_integration === undefined) &&
                    <div className="row">
                        <div className="col 12 ">
                            <Report className="max_width_100" windowRef={"reportLogs"} reportId="events" viewFilter="events" filterId="events"></Report>
                        </div>
                    </div>
                }
            </form>
            <div className="row">
                {utils.isGranted('UPDATE_BET_STATUS') &&
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={update}
                        >
                            {t('Update')}
                        </button>
                    </div>}
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(BetDetails);
import React from "react"
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Report from "../../components/basic/Report";
import utils from '../../utils';

const UserAccounts = (props) => {

    const { t } = props

    const user = JSON.parse(utils.getSessionItem("userDetails")).user;



    if (window.sections === undefined || window.sections === null) {
        window.sections = JSON.parse(utils.getSessionItem("sections"));
    }


    return (<>
        <nav className={"navbar navbar-expand-lg navbar-light bg-light "}>
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarUserMenu" aria-controls="navbarUserMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarUserMenu">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {utils.isGranted('MANUAL_DEPOSIT') &&
                            <li className="nav-item" id="manualDepositButton">
                                <Link className="nav-link " aria-current="page" to={"/support/user_manual_deposit"} >
                                    <FontAwesomeIcon icon="angle-double-down" /> {t("Manual deposit")}    </Link>
                            </li>
                        }
                        {utils.isGranted('MANUAL_PAYOUT') &&
                            <li className="nav-item" id="manualPayoutButton">
                                <Link className="nav-link " aria-current="page" to={"/support/user_manual_payout"} >
                                    <FontAwesomeIcon icon="angle-double-up" /> {t("Manual payout")}  </Link>
                            </li>
                        }
                        {utils.isGranted('MANUAL_ADJUSTMENT') &&
                            <li className="nav-item" id="manualAdjustmentButton">
                                <Link className="nav-link " aria-current="page" to={"/support/user_manual_adjustment"} >
                                    <FontAwesomeIcon icon="angle-double-up" /> {t("Manual adjustment")}  </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav >
        <Report reportId="user_accounts" user={user} location={props.location}></Report>
    </>
    )
}

export default withTranslation()(withRouter(UserAccounts))
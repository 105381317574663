import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import usePagination from "../../hooks/Pagination";
import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function DoubleList(props) {
  const [availables, setAvailables] = useState(props.availables)
  const [configured, setConfigured] = useState(props.configured)

  const [searchQueryAvailables, setQueryAvailable] = useState("")
  const [searchQueryConfigured, setSearchQueryConfigured] = useState("")

  const useTranslationsId = props.useTranslationsId || false
  const idProperty = props.idProperty || 'id'
  const nameProperty = props.nameProperty || 'name'
  const translatePrefix = props.translatePrefix || ''
  const usePlusInfo = props.usePlusInfo || false
  const dataInfo = props.dataInfo || []

  const { t } = props;

  const isDraggable = props.draggable !== undefined ? true : false
  const defaultPageSizeCf = isDraggable ? 1000 : props.pageSizeCf || 5
  const defaultPageSizeAv = props.pageSizeAv || 5



  const {
    pageIndex: pageIndexAv,
    nextPage: nextPageAv,
    previousPage: previousPageAv,
    gotoPage: gotoPageAv,
    setPageIndex: setPageIndexAv,
    pageCount: pageCountAv,
    setData: setDataAv,
    begin: beginAv,
    canNextPage: canNextPageAv,
    canPreviousPage: canPreviousPageAv
  } = usePagination(availables, defaultPageSizeAv)

  const {
    pageIndex: pageIndexCf,
    nextPage: nextPageCf,
    previousPage: previousPageCf,
    gotoPage: gotoPageCf,
    setPageIndex: setPageIndexCf,
    pageCount: pageCountCf,
    setData: setDataCf,
    begin: beginCf,
    canNextPage: canNextPageCf,
    canPreviousPage: canPreviousPageCf
  } = usePagination(configured, defaultPageSizeCf)


  const searchAvailableList = (data) => {
    setDataAv(availables.filter((item) => {
      if (useTranslationsId)
        return t(translatePrefix + item[idProperty])
          .toLowerCase()
          .includes(data.toLowerCase());
      else
        return item[nameProperty]
          .toLowerCase()
          .includes(data.toLowerCase());

    }))
    setQueryAvailable(data);
    setPageIndexAv(0)
  };
  const searchConfiguredList = (data) => {
    setDataCf(configured.filter((item) => {
      if (useTranslationsId)
        return t(translatePrefix + item[idProperty])
          .toLowerCase()
          .includes(data.toLowerCase());
      else
        return item[nameProperty]
          .toLowerCase()
          .includes(data.toLowerCase());

    }))
    setSearchQueryConfigured(data);
    setPageIndexCf(0)
  }


  const sortElements = (a, b) => {
    const { t } = props;
    if (useTranslationsId)
      return t(translatePrefix + a[idProperty]) > t(translatePrefix + b[idProperty]) ? 1 : t(translatePrefix + b[idProperty]) > t(translatePrefix + a[idProperty]) ? -1 : 0;
    else return a[nameProperty] > b[nameProperty] ? 1 : b[nameProperty] > a[nameProperty] ? -1 : 0;
  };

  const moveElement = (id, from, to, sortable) => {
    let itemAux;
    let arFrom = [...from];
    let arTo = [...to];
    for (var i = 0; i < arFrom.length; i++) {
      var item = from[i];
      if (item[idProperty] === id) {
        itemAux = item;
        arTo.push(itemAux);
        arFrom.splice(i, 1);
        break;
      }
    }
    if (sortable) return { from: arFrom, to: arTo.sort(sortElements) };
    else return { from: arFrom, to: arTo };
  };

  const handleSortDraggable = () => {
    let _newCfElements = [...configured]
    const draggedItemContent = _newCfElements.splice(dragItem.current, 1)[0]
    _newCfElements.splice(dragOverItem.current, 0, draggedItemContent)
    dragItem.current = null
    dragOverItem.current = null
    setConfigured(_newCfElements)
    props.handleUpdateList(_newCfElements)


  }
  const dragItem = useRef(null)
  const dragOverItem = useRef(null)

  useEffect(() => {
    let cf = props.configured
    if (!isDraggable) {
      cf = props.configured.sort(sortElements)
    }
    let av = props.availables.sort(sortElements);

    setAvailables(av);
    setConfigured(cf);
    setDataAv(av)
    setDataCf(cf)
  }, [props.availables]);

  const handleClickConfigured = (item) => {

    let result = moveElement(item[idProperty], configured, availables, true);
    setAvailables(result.to);
    setConfigured(result.from);
    if (searchQueryAvailables !== "") {
      setDataAv(result.to.filter((item) => {
        if (useTranslationsId)
          return t(translatePrefix + item[idProperty])
            .toLowerCase()
            .includes(searchQueryAvailables.toLowerCase());
        else
          return item[nameProperty]
            .toLowerCase()
            .includes(searchQueryAvailables.toLowerCase());

      }))
    } else setDataAv(result.to)

    if (searchQueryConfigured !== "") {
      setDataCf(result.from.filter((item) => {
        if (useTranslationsId)
          return t(translatePrefix + item[idProperty])
            .toLowerCase()
            .includes(searchQueryConfigured.toLowerCase());
        else
          return item[nameProperty]
            .toLowerCase()
            .includes(searchQueryConfigured.toLowerCase());

      }))
    } else setDataCf(result.from)

    props.handleUpdateList(result.from)
  }
  return (
    <>

      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="allowedList">
            <b>{props.configuredListName}</b>
          </label>
          {!isDraggable &&
            <TextField
              id="searchListConfigured"
              key="searchListConfigured"
              label={t("Search")}
              defaultValue=""
              onChange={(evt) => { searchConfiguredList(evt.target.value) }}
            />}
          <ul className="machines_list">
            {configured
              .filter((item) => {
                if (useTranslationsId)
                  return t(translatePrefix + item[idProperty])
                    .toLowerCase()
                    .includes(searchQueryConfigured.toLowerCase());
                else
                  return item[nameProperty]
                    .toLowerCase()
                    .includes(searchQueryConfigured.toLowerCase());

              })
              .slice(beginCf(), beginCf() + defaultPageSizeCf)
              .map((item, index) => (
                <li
                  {...(isDraggable ? {
                    draggable: true,
                    onDragStart: (e) => { dragItem.current = index },
                    onDragEnd: (e) => { handleSortDraggable() },
                    onDragEnter: (e) => { dragOverItem.current = index },
                    onDragOver: (e) => { e.preventDefault() }
                  } : {})
                  }
                  className="group_item d-table  w-100"
                  key={"configured_" + item[idProperty]}
                  id={"configured_" + item[idProperty]}
                  onClick={() => {
                    if (!isDraggable && !props.allowDelete) {
                      handleClickConfigured(item)
                    }
                  }
                  }
                >
                  {isDraggable && <>
                    <FontAwesomeIcon className="float-start" icon="bars" />{" "}</>
                  }
                  <div key={"configuredNames-" + item[idProperty]} className="float-start d-flex" style={{ "marginLeft": 3 }}>{useTranslationsId ? t(translatePrefix + item[idProperty]) : item[nameProperty]}</div>
                  {usePlusInfo ? <div className="float-end">
                    {dataInfo.map((dataProperty, index) => {
                      let separator = " - "
                      if (index === 0) separator = ""
                      return separator + (item[dataProperty] ? item[dataProperty] : '')
                    })}</div>
                    : <></>}
                  {isDraggable && props.delete === undefined ?
                    <FontAwesomeIcon className="float-end" onClick={() => { handleClickConfigured(item) }} icon="trash" />
                    :
                    <>{props.allowDelete !== undefined && item[props.allowDelete.property] === props.allowDelete.value ?
                      <FontAwesomeIcon className="float-end" onClick={() => { handleClickConfigured(item) }} icon="trash" />
                      :
                      <></>
                    }</>
                  }
                </li>
              ))}
          </ul>
          {!isDraggable && <Pagination
            gotoPage={gotoPageCf}
            previousPage={previousPageCf}
            nextPage={nextPageCf}
            canPreviousPage={canPreviousPageCf}
            canNextPage={canNextPageCf}
            pageCount={pageCountCf}
            pageIndex={pageIndexCf}
            showInfoPage={true}
            showGoToPage={false}
          ></Pagination>}
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="availableList">
            {/* {t("Availables")} */}
            &nbsp;
          </label>
          <TextField
            id="searchListAvailable"
            key="searchListAvailable"
            label={t("Search") + " " + t("Availables")}
            defaultValue=""
            onChange={(evt) => { searchAvailableList(evt.target.value) }}
          />
          <ul className="machines_list">
            {availables
              .filter((item) => {
                if (useTranslationsId)
                  return t(translatePrefix + item[idProperty])
                    .toLowerCase()
                    .includes(searchQueryAvailables.toLowerCase());
                else
                  return item[nameProperty]
                    .toLowerCase()
                    .includes(searchQueryAvailables.toLowerCase());


              })
              .slice(beginAv(), beginAv() + defaultPageSizeAv)
              .map((item) => (
                <li className="group_item d-table  w-100"
                  key={"available_" + item[idProperty]}
                  id={"available_" + item[idProperty]}
                  onClick={() => {
                    let result = moveElement(item[idProperty], availables, configured, isDraggable ? false : true);
                    setAvailables(result.from);
                    setConfigured(result.to);
                    if (searchQueryAvailables != "") {
                      setDataAv(result.from.filter((item) => {
                        if (useTranslationsId)
                          return t(translatePrefix + item[idProperty])
                            .toLowerCase()
                            .includes(searchQueryAvailables.toLowerCase());
                        else
                          return item[nameProperty]
                            .toLowerCase()
                            .includes(searchQueryAvailables.toLowerCase());

                      }))
                    } else setDataAv(result.from)

                    if (searchQueryConfigured != "") {
                      setDataCf(result.to.filter((item) => {
                        if (useTranslationsId)
                          return t(translatePrefix + item[idProperty])
                            .toLowerCase()
                            .includes(searchQueryConfigured.toLowerCase());
                        else
                          return item[nameProperty]
                            .toLowerCase()
                            .includes(searchQueryConfigured.toLowerCase());
                      }))
                    } else setDataCf(result.to)
                    props.handleUpdateList(result.to)
                  }}
                >
                  <div key={"availablesNames-" + item[idProperty]} className="float-start d-flex  ">{useTranslationsId ? t(translatePrefix + item[idProperty]) : item[nameProperty]}</div>
                  {usePlusInfo ? <div className="float-end">
                    {dataInfo.map((dataProperty, index) => {
                      let separator = " - "
                      if (index === 0) separator = ""
                      return separator + (item[dataProperty] ? item[dataProperty] : '')
                    })}</div>
                    : <></>}
                </li>
              ))}
          </ul>
          <Pagination
            gotoPage={gotoPageAv}
            previousPage={previousPageAv}
            nextPage={nextPageAv}
            canPreviousPage={canPreviousPageAv}
            canNextPage={canNextPageAv}
            pageCount={pageCountAv}
            pageIndex={pageIndexAv}
            showInfoPage={true}
            showGoToPage={false}
          ></Pagination>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(DoubleList);

import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
function RedirectionsDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_RedirectionsDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [redirectionDetails, setRedirectionDetails] = useState({
        url: "",
        config: ""
    });
    const redirection = utils.getSessionItem('current-url')
    const goBack = () => {
        props.history.push('/reports/report/redirections/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Redirections details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (redirection !== undefined && redirection !== '-1') {
            adminClient.getRedirectionDetails(session, company, redirection).then(
                async function (result) {
                    setRedirectionDetails(result.data.redirection);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setRedirectionDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveRedirectionDetails(session, company, JSON.stringify(redirectionDetails)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Redirections details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Redirections details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Redirections details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_RedirectionsDetails">
                <div className="row">
                    <div className="col-12 ">
                        <TextField onChange={onChangeHandler} name="url" label={t("Url")} type="text" value={redirectionDetails.url} />
                    </div>
                    <div className="col-12 ">
                        <TextField multiline onChange={onChangeHandler} name="config" label={t("Config")} type="text" value={redirectionDetails.config} />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted("SAVE_CMS_REDIRECTIONS") &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(RedirectionsDetails);

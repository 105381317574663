import React, { useMemo, useState } from "react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters } from "react-table";
import { cellTableRender, cellTableRenderInner, loadRenderInfo } from '../../libs/utilsReports'
import { useAsyncDebounce } from "../../hooks/Debounce";

import Pagination from "./Pagination";
import { TextField } from "@mui/material";
import { useEffect } from "react";

/*
  Tabla Basica que contiene ordenación de columnas y paginación en cliente, los datos se pasan de la siguiente manera:
  <BasicTable data="datos a presentar" columns="Configuracion de las columnas"/>

  La configuración de las columnas son un elemento por cada columna que queramos visualizar


*/

const GlobalFilter = ({ filter, setFilter, labelFilter }) => {

  const [value, setValue] = useState(filter)

  const onChange = useAsyncDebounce((value) => { setFilter(value || undefined) }, 500)

  return (
    <TextField
      label={labelFilter}
      value={value || ''}
      onChange={(evt) => {
        setValue(evt.target.value)
        onChange(evt.target.value)
      }}
    />
  )
}
const ColumnFilter = (props) => {


  const { filterValue, setFilter, Header } = props.column
  const [value, setValue] = useState(filterValue)

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 500)

  useEffect(() => {
    setValue(filterValue)
  }, [filterValue])

  return (
    <TextField
      label={Header}
      value={value || ''}
      onChange={(evt) => {
        setValue(evt.target.value)
        onChange(evt.target.value)
      }}
    />
  )
}

const BasicTable = (props) => {
  const { t } = props
  const columns = useMemo(() => props.columns, [props.columns]);
  //const data = useMemo(() => props.data, []);  
  const [data, setData] = useState(props.data)
  const defaultColumn = useMemo(() => {
    return {
      typeCell: 'text' || undefined,
      Cell: (props) => cellTableRender(props, t),
      Filter: ColumnFilter
    }
  }, [])
  const getColumnDef = (id) => {
    return columns.find((col) => { if (col.accessor === id) return col })
  }
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          let rowValue = cellTableRenderInner(row.values[id], 'text', row.original, t, { className: 'text' })
          if (rowValue !== '') rowValue = rowValue.props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      date: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'date', row.original, t, { className: 'date' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      time: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'time', row.original, t, { className: 'time' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      translate: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'translate', row.original, t, { className: 'translate' })
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      text_translated: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'text_translated', row.original, t, { className: 'text_translated' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      basicInfo: (rows, id, filterValue) => {
        let cellDef = getColumnDef(id[0])
        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'basicInfo', row.original, t, { renderData: cellDef.renderData, className: 'basicInfo' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      custom: (rows, id, filterValue) => {
        let cellDef = getColumnDef(id[0])
        return cellDef.filterCustom(rows, id, filterValue);
      }

    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    rows,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    prepareRow,
    setAllFilters,
    state
  } = useTable(
    { columns, data, defaultColumn, initialState: { pageIndex: 0, pageSize: props.sizePageTable || 10 }, filterTypes },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;


  useEffect(() => {
    props.columns.forEach((column) => {
      if (column.renderData !== undefined) loadRenderInfo(column.renderData)
    })
  }, [])
  useEffect(() => {
    setAllFilters([])
    setData(props.data)
    if (props.hidePagination) setPageSize(props.data.length)
  }, [props.data])

  return (<>
    {props.showGlobalFilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} labelFilter={t('Search')} />}
    <div className="table-responsive">
      <table {...getTableProps()} className="table table-striped table-hover">
        {!props.hideHeader &&
          <thead>
            {headerGroups.map((headerGroup) => (<>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
              {props.showFilterColumn && <tr key="filter_table">
                {headerGroup.headers.map((column, idx) => (
                  <th key={"filter_table_col_" + idx}>
                    {<div>{column.canFilter ? column.render("Filter") : null}</div>}
                  </th>
                ))}
              </tr>}
              {page.length === 0 && <tr>
                <th key={"no_data_col"} colSpan={headerGroup.headers.length}><div className={"row calimaco_no_data_found"}>{t("No data found")}  </div></th>
              </tr>}
            </>
            ))}
          </thead>
        }
        {page.length > 0 &&
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => {
                  if (props.rowHandle !== undefined) props.rowHandle(row)
                }}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="table-cell">{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
        {!props.hideFooter &&
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => {
                  return (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        }
      </table>
      {!props.hidePagination &&
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          showInfoPage={true}
          showPageSize={true}
          showGoToPage={true}
          showTotalRegisters={true}
          totalRegisters={rows.length}
          sizePageOptions={props.sizePageOptions}
        ></Pagination>
      }
    </div></>
  )
}

export default withTranslation()(BasicTable);

import React, { useEffect, useState } from 'react'
import AppChart from "../../components/AppChart";
import AppKPI from "../../components/AppKPI";
import Report from "../../components/basic/Report";
import utils from '../../utils';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import momentTimeZone from 'moment-timezone';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';

function Dashboard(props) {
    const user = utils.getSessionItem("user");
    const [content, setContent] = useState(<div></div>);
    const [help, setHelp] = useState({ help: false, help_url: "" });
    const { t } = props;
    const [dashboardSettings, setDashboardSettings] = useState({
        elements: []
    })

    useEffect(() => {
        if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
            this.props.history.push('/admin/login');
        }
        if (window.sections === undefined || window.sections === null) {
            window.sections = JSON.parse(utils.getSessionItem("sections"));
        }

        if (window.sections === undefined || window.sections === null) {
            this.props.history.push('/admin/login');
        } else {
            let path = props.location.pathname.split("/");
            let pageId = path[path.length - 1];
            if (pageId === 'home') {
                pageId = 'DASHBOARD';
            }
            let config = window.sections[pageId].config;

            if (config !== undefined && config.help_url !== undefined) {
                setHelp({ help: true, help_url: config.help })
                console.log("help : " + config.help_url);
            }

            let init_date;
            let end_date;

            if (config.date_range !== undefined && config.date_range.enabled === 'true') {
                var from = new Date(new Date().getTime() - (config.date_range.init_date * 24 * 60 * 60 * 1000));
                var to = new Date(new Date().getTime() - (config.date_range.end_date * 24 * 60 * 60 * 1000)).setSeconds(0);

                init_date = momentTimeZone(from).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
                end_date = momentTimeZone(to).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
            }

            setDashboardSettings(
                {
                    init_date: init_date,
                    end_date: end_date,
                    date_range: (config.date_range !== undefined && config.date_range.enabled === 'true'),
                    elements: config.elements
                }
            )
        }

    }, [])

    const search = () => {
        // Actualiza el estado para forzar un re-renderizado
        setDashboardSettings(
            prevSettings =>
            ({
                ...prevSettings,
                elements
                    : [...prevSettings.elements]
            }));
    };

    return (
        <LayoutSection>
            {user !== undefined ? (
                <div className="mt-4">

                    {dashboardSettings.date_range &&
                        <div className="row">
                            <div className="col-12 col-sm-3">
                                <DateAndTimePicker
                                    label={t("Init Date")}
                                    value={dashboardSettings.init_date}
                                    showTime={true}
                                    callBackFunction={(date) => setDashboardSettings((prevState) => ({ ...prevState, init_date: date }))}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <DateAndTimePicker
                                    label={t("End Date")}
                                    value={dashboardSettings.end_date}
                                    showTime={true}
                                    callBackFunction={(date) => setDashboardSettings((prevState) => ({ ...prevState, end_date: date }))}
                                />
                            </div>
                            <div className="col-12 col-sm-3">
                                <button className="btn btn-primary" onClick={search}>{t("Search")}</button>
                            </div>
                        </div>
                    }
                    <div className="row">

                        {dashboardSettings.elements.map((element) => {
                            switch (element.type) {
                                case 'table': return <Report className="col-12  float-left pt-3 max_width_100" reportId={element.report} filter={element.filter}></Report>;
                                case 'chart': return <AppChart windowRef={"chart_" + element.report} report={element.report} filter={[{ field: element.filter["init_date"], type: "time_range", value: [utils.unformatDate(dashboardSettings.init_date), utils.unformatDate(dashboardSettings.end_date)] }]}></AppChart>;
                                case 'kpi': return <AppKPI kpi={element.kpi} filter={element.filter}></AppKPI>
                                case 'newline': return <div className="newline"></div>
                                default: return null;
                            }
                        })}
                    </div>
                </div>

            ) : (
                <></>
            )}
        </LayoutSection>
    );
}

export default withTranslation()(withRouter(Dashboard))
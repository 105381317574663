import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import ProgressBar from '../../components/basic/ProgressBar';
import BasicSelect from '../../components/basic/BasicSelect';
import UploaderField from '../../components/basic/UploaderField';
function DepositsBatch(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const [idempotenceDefault, setIdempotenceDefault] = useState(utils.generateUID())
    const { t } = props;
    const { formValidation } = useFormValidation('Form_DepositsBatch')
    const [progressBar, setProgressBar] = useState({
        show: false, percentage: '0%', textProcess: t('Processing')
    });
    const [operations, setOperations] = useState({
        method: "",
        idempotence: "",
        comment: "",
        players: ""
    });
    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Manual deposits batch'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {

    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setOperations((prevState) => ({ ...prevState, [name]: value }));
    };


    const sendBatch = (data) => {
        let file;
        let players;
        if (!Array.isArray(data)) {
            file = data
        }
        else {
            players = data;
        }
        let proggresBarAux = { show: true, percentage: '0%', textProcess: t('Processing') }
        setProgressBar(proggresBarAux);
        adminClient.manualDepositBatch(
            session,
            company,
            JSON.stringify(players),
            operations.method,
            operations.comment,
            operations.idempotence === "" ? idempotenceDefault : operations.idempotence.trim(),
            handleResponse,
            file
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Manual deposits batch'), t('Updated succesfull'));
                    if (operations.idempotence === "") setIdempotenceDefault(utils.generateUID());
                    let textNOkFormatted = "";
                    if (msg.textNOK !== undefined) {
                        textNOkFormatted = JSON.parse(msg.textNOK).map(item => `User: ${item.user}, Description: ${item.description}`).join(' || ');
                    }
                    setProgressBar((prevState) => {
                        let oldState = { ...prevState }
                        oldState.percentage = '100%'
                        oldState.show = true
                        oldState.text = t('All players updated')
                        oldState.textOK = t("Users correct") + ": " + msg.textOK
                        oldState.textNOK = msg.textNOK && ((msg.textNOK.length !== 0 && textNOkFormatted !== "[]" && textNOkFormatted !== "") ? t("Users with errors") + ": " + textNOkFormatted : false)
                        return oldState;
                    });

                } else {
                    props.showAlert(t('Manual deposits batch'), t('Updating error ') + msg.description)
                }
            },
            (error) => {
                if (error.code !== -34) {
                    props.showAlert(t('Manual deposits batch'), t('Updating error ') + error.description)
                }
            }

        )

    }

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)
        let textNOkFormatted;
        if (data.textNOK !== undefined) {
            textNOkFormatted = JSON.parse(data.textNOK).map(item => `User: ${item.user}, Description: ${item.description}`).join(' || ');
        }

        setProgressBar({
            show: true,
            percentage: percentage + '%',
            text: t(text) + ": " + data.partial + "/" + data.total,
            textOK: data.textOK !== false && (t("Users correct") + ": " + data.textOK),
            textNOK: data.textNOK && ((data.textNOK.length !== 0 && textNOkFormatted !== "[]" && textNOkFormatted !== "") ? t("Users with errors") + ": " + textNOkFormatted : false)
        })
    };

    const update = () => {
        if (formValidation.validate()) {
            let players = operations.players.split(/\r?\n/);
            let playersFiltrado = players.filter(elemento => elemento.trim() !== '');
            sendBatch(playersFiltrado);
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_DepositsBatch">
                <div className="row" >
                    <div className="col-12 ">
                        <BasicSelect
                            label={t("Method")}
                            idProperty="method"
                            name="method"
                            basicInfoName="DepositMethods"
                            onChange={onChangeHandler}
                            value={operations.method}
                            noEmptyValue
                            required
                        />
                    </div>
                    <div className="col-12 ">
                        <TextField multiline label={t("Players")} name="players" value={operations.players} onChange={onChangeHandler} required
                            placeholder="db.player1 , amount&#10;db.player2 , amount&#10;db.player3 , amount"
                        />
                    </div>
                    <div className="col-12 ">
                        <TextField label={t("Idempotence")} name="idempotence" value={operations.idempotence} onChange={onChangeHandler}></TextField>
                    </div>
                    <div className="col-12 ">
                        <TextField multiline label={t("Comment")} name="comment" value={operations.comment} onChange={onChangeHandler}></TextField>
                    </div>

                    {utils.isGranted("MANUAL_DEPOSIT") &&
                        <div className="col-12 ">
                            <UploaderField
                                label="CSV"
                                acceptedFiles=".csv"
                                showAlert={props.showAlert}
                                handleCloseAlert={props.handleCloseAlert}
                                callBackFunction={sendBatch}
                            />
                            <button type="button" className="btn btn-primary" onClick={(update)}>{t("Send")}</button>
                        </div>
                    }
                </div>
                {progressBar.show &&
                    <ProgressBar
                        percentage={progressBar.percentage}
                        text={progressBar.text}
                        textOK={progressBar.textOK}
                        textNOK={progressBar.textNOK}
                    />
                }
            </form>

        </LayoutSection>
    )
}
export default withTranslation()(DepositsBatch);
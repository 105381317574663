import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tab from '../../components/basic/BasicTabs';
import Editor from '../../components/basic/Editor';
import File from '../../components/basic/File';
function EmailTemplates(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();

    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_EmailTemplates')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const templateId = utils.getSessionItem('current-template')
    const [emailTemplate, setEmailTemplate] = useState({
        template: "",
        name: "",
        subject: "",
        text: "",
        config: {},
        content: '',
    });
    const goBack = () => {
        props.history.push('/reports/report/emails_templates/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Email template details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (templateId !== undefined && templateId !== '-1') {
            adminClient.getEmailTemplateDetails(session, company, templateId).then(
                async function (result) {
                    setEmailTemplate(result.data.template);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);

    useEffect(function () {
        if (addOrUpdate == 'update') {
            let newTemplate = { ...emailTemplate };
            newTemplate.content = ' '
            setEmailTemplate(newTemplate);
        }
    }, [addOrUpdate])
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        console.log(name, value)
        setEmailTemplate((prevState) => ({ ...prevState, [name]: value }));
    };


    const save = (evt) => {
        if (formValidation.validate()) {
            var promise = adminClient.saveEmailTemplateDetails(
                session,
                company,
                JSON.stringify(emailTemplate));
            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert(t('Email template details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            utils.setSessionItem('current-template', emailTemplate.template)
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Email template details'), t('Saving error ') + msg.description)
                    }
                }, function (err) {
                    props.showAlert(t('Email template details'), t('Saving error ') + err)

                });
        }
    }


    const saveDataImages = (urls) => {
        let template = { ...emailTemplate }
        template.images = urls;
        setEmailTemplate(template)
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_EmailTemplates">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="template"
                            required
                            disabled={addOrUpdate === 'update'}
                            inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            type="text"
                            value={emailTemplate.template}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <TextField onChange={onChangeHandler} label={t("Name")} type="text" name="name" value={emailTemplate.name} />
                    </div>

                    <div className="col-12  ">
                        <TextField onChange={onChangeHandler} label={t("Subject")} type="text" name="subject" value={emailTemplate.subject} />
                    </div>

                    <div className="col-12  ">
                        <TextField multiline onChange={onChangeHandler} label={t("Text content")} type="text" name="text" value={emailTemplate.text} />
                    </div>

                </div>
            </form>
            {(addOrUpdate === "update") &&
                <>
                    <Tab key="tabMachineDetailsI18n" active={0}>
                        <Tab.TabPane key={`TabContentTags`} tab={t("HTML Content")}>
                            <Editor
                                onChangeHandler={(evt) => onChangeHandler(evt)}
                                info={emailTemplate.content}
                                height={500}
                                oneTime={true}
                            />
                        </Tab.TabPane>
                        <Tab.TabPane key={`TabContentTags`} tab={t("Images")}>
                            <File
                                dropEnable={true}
                                showFile={true}
                                showError={true}
                                viewerEnable={false}
                                deleteEnable={true}
                                saveData={saveDataImages}
                                fileName={emailTemplate.images}
                                fileUrlPref={companyDetails.config.cdn_url + "../"}
                                getFileUrl={(data) => {
                                    return "/cms/img/emails_templates/" + emailTemplate.template + "/" + data.fileName;
                                }}
                                type={'EMAIL_TEMPLATE'}
                                enableCopyUrl={true}
                                showAlert={props.showAlert}
                                owner={JSON.stringify(emailTemplate)}
                            />
                        </Tab.TabPane>
                    </Tab>
                </>
            }
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={save}
                    >
                        {t('Save')}
                    </button>
                </div>
            </div>
        </LayoutSection >
    )
}
export default withTranslation()(EmailTemplates);
import React from "react"
import { withTranslation } from "react-i18next"
import { Handle, Position } from "reactflow"
import FlowConnectionHandle from "./FlowConnectionHandle"

function FlowStatusNode(props) {

    const { t } = props

    const isConnectable = props.isConnectable
    const flowStatusDetail = props.data

    return (
        <>
            <div className="react-flow__node-statusNode" style={{ background: flowStatusDetail.config.diagram.color }}>
                <Handle type="target" className="targetConnector" position={Position.Left} isConnectable={isConnectable} />
                <div className="statusNode-node__header">{flowStatusDetail.name}</div>
                <div className="statusNode-node__body">
                    {flowStatusDetail.config.reacts !== undefined && Object.keys(flowStatusDetail.config.reacts).map((r, index) => {
                        let reactData = {}
                        reactData[r] = flowStatusDetail.config.reacts[r]
                        return (<>
                            <div className="statusNode-node__select">
                                <span>{reactData[r].name}</span>
                                <FlowConnectionHandle className="sourceConnector" type="source" position={Position.Right} id={`edge-${flowStatusDetail.status}-handle_${index}`} isConnectable={isConnectable} />
                            </div>
                        </>)
                    })}
                </div>
            </div>
        </>
    )
}

export default withTranslation()(FlowStatusNode)
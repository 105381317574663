import React, { useEffect, useState } from "react"
import AdminClient from "../../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../../utils";
import { TextField } from "@mui/material";

import { DateAndTimePicker } from "../../components/DateAndTimePicker";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from "../../hooks/parsleyValidation";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import NotePopup from "../../components/users/NotePopup";


function UserAutoexclusionsVerticalDetails(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany(company);

    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_UserAutoexclusionsVerticalDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [autoexclusionVerticalDetails, setAutoexclusionVerticalDetails] = useState({
        db: utils.getSessionItem("current-user").split(".")[0],
        user: utils.getSessionItem("current-user").split(".")[1],
        init_date: utils.getDateTZToday(),
        end_date: utils.getDateTZToday(),
        days: 0,
        status: "ACTIVE"
    });


    const goBack = () => {
        props.history.push("/support/user_autoexclusions");
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Autoexclusions Vertical details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {

        try {
            let init_date = JSON.parse(utils.getSessionItem("current_row_user_vertical_autoexclusions")).init_date;

            if (init_date !== undefined && init_date !== '-1') {
                adminClient.getAutoexclusionVerticalDetails(session, company, autoexclusionVerticalDetails.db, autoexclusionVerticalDetails.user, init_date).then(
                    async function (result) {
                        result.data.init_date = utils.getDateServerToTZ(result.data.init_date)
                        result.data.end_date = utils.getDateServerToTZ(result.data.end_date)

                        setAutoexclusionVerticalDetails(result.data);
                        setAddOrUpdate('update');
                    },
                    function (err) {
                        console.error(err);
                    }
                );
            } else {
                setAddOrUpdate('add');
            }
        } catch (error) {
            console.log('Elemento Nuevo')
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setAutoexclusionVerticalDetails((prevState) => {
            let oldState = { ...prevState }
            if (name === "days") {
                oldState.end_date = utils.addDaysDate(oldState.init_date, value)
            }
            oldState[name] = value;
            return oldState
        });
    };

    const onChangeHandlerDate = (newValue) => {

        setAutoexclusionVerticalDetails((prevState) => {
            let oldState = { ...prevState }
            oldState.init_date = newValue;
            oldState.end_date = utils.addDaysDate(oldState.init_date, oldState.days)
            return oldState
        });
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            let autoexclusion = { ...autoexclusionVerticalDetails }
            autoexclusion.init_date = utils.getDateToServer(autoexclusion.init_date)
            autoexclusion.end_date = utils.getDateToServer(autoexclusion.end_date)
            adminClient.saveAutoexclusionVerticalDetails(
                session,
                company,
                JSON.stringify(autoexclusion)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Autoexclusions Vertical details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        goBack()
                    } else {
                        props.showAlert(t('Autoexclusions Vertical details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Autoexclusions Vertical details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    const notePopup = () => {
        if (formValidation.validate()) {
            props.showAlert(
                t('Saving user'),
                <NotePopup
                    handleCloseAlert={props.handleCloseAlert}
                    company={company}
                    adminClient={adminClient}
                    save={save}
                    t={t}
                />,
                []
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserAutoexclusionsVerticalDetails" data-parsley-focus="none">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <DateAndTimePicker
                            required
                            disabled={addOrUpdate === "update"}
                            label={t("Init Date")}
                            value={autoexclusionVerticalDetails.init_date}
                            showTime={true}
                            callBackFunction={onChangeHandlerDate}
                            id={"init_date"}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField InputLabelProps={{ shrink: autoexclusionVerticalDetails.days !== undefined }} disabled={addOrUpdate === "update"} label={t("Days")} value={autoexclusionVerticalDetails.days} InputProps={{ inputProps: { min: 0 } }} name="days" type="number" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-4">

                        <DateAndTimePicker
                            disabled
                            label={t("End Date")}
                            value={autoexclusionVerticalDetails.end_date}
                            showTime={true}
                            validations={
                                {
                                    "data-parsley-validate-date-range": "init_date",
                                    "data-parsley-validate-date-range-message":
                                        t("PARSLEY_VALIDATION_DATE_RANGE")
                                }
                            }
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            disabled={addOrUpdate === "update"}
                            idProperty='type'
                            required={true}
                            value={autoexclusionVerticalDetails.machine_type}
                            name='machine_type'
                            basicInfoName="MachinesTypes"
                            label={t('Vertical')}
                            filterOptionsFunction={(option) => {
                                if (addOrUpdate !== 'update') {
                                    if (option.autoexclusion_group !== null && option.autoexclusion_group !== '') return option
                                } else return option
                            }}
                            filters={addOrUpdate}
                            onChange={onChangeHandler}
                            disableClearable
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty='status'
                            nameProperty='status'
                            value={autoexclusionVerticalDetails.status}
                            name='status'
                            disabled={!utils.isGranted('SAVE_USER_AUTOEXCLUSION')}
                            label={t('Status')}
                            onChange={onChangeHandler}
                            addOpts={[{ status: 'ACTIVE', value: 'ACTIVE' }, { status: 'DISABLED', value: 'DISABLED' }]}
                            translate={{ property: 'status', prefix: 'AUTOEXCLUSION_STATUS_' }}
                            disableClearable
                        />
                    </div>

                </div>

            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted('SAVE_USER_AUTOEXCLUSION') &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={
                                companyDetails.config?.backoffice?.autoexclusion?.note ?
                                    notePopup :
                                    save}
                        >
                            {t('Save')}
                        </button>
                    }
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={goBack}
                    >
                        {t('Back')}
                    </button>
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(UserAutoexclusionsVerticalDetails);
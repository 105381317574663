import React from 'react'
import { withTranslation } from 'react-i18next'

const ProgressBar = (props) => {
    const {t}=props
    const percentage=props.percentage||'100%'
    const text=props.text||t('Processing')
    const textOK=props.textOK
    const textNOK=props.textNOK
    

    
  return (
    <div class="row">
      <div className='col-md-12 progressContainer'>
          <span className="progressBarText">{text}</span>
          <div className="progress">
              <div className={"progres-bar bg-success progress-bar-animated"} role="progressbar" style={{width:percentage}}>
              </div>
          </div>
          {props.textOK && <div className='col-md-12 progressContainerResultOK'>
              {textOK}
            </div>}
          {props.textNOK && <div className="col-md-12 progressContainerResultNOK">
              {textNOK}
          </div>}
      </div>
      
    </div>
  )
}
export default withTranslation()(ProgressBar)
import React, { useState, useEffect } from 'react'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from '../../components/basic/BasicTabs';
import BasicTable from '../../components/basic/BasicTable';



function PromotionList(props) {

  const { t } = props
  const [tabsContent, setTabsContent] = useState(
    [
      { title: t("Current Promotions"), content: "" },
      { title: t("Future Promotions"), content: "" },
      { title: t("Past Promotions"), content: "" }
    ])

  const handleClick = (id, deleted) => {
    console.log("Promotion  id = " + id);
    utils.setSessionItem("current-promotion-id", id);
    utils.setSessionItem("current-promotion-deleted", deleted);
    props.history.push('/marketing/promotions/details');
  }

  const handleDetailsRow = (row) => {
    handleClick(row.original.promotion, row.original.deleted)
  }
  const handleNewPromotion = () => {
    console.log("New Promotion")
    handleClick(-1)
  }

  useEffect(() => {

    let clientAPI = new AdminClient();
    let promise = clientAPI.getPromotions(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"));
    promise.then((msg) => {

      let currentPromotions = [];
      let futurePromotions = [];
      let pastPromotions = [];
      let deletedPromotions = [];

      const renderEvents = (valueEvents) => {
        let events = "";
        if (valueEvents !== undefined && valueEvents !== null) {
          valueEvents.split(",").forEach(element => {
            if (events.length > 0) {
              events += " " + t("or") + " ";
            }
            events += t(element);
          });
        }
        return events;
      }

      const promotionColumnsDef = [
        { Header: t("ID"), accessor: 'promotion', typeCell: 'text', filter: 'text' },
        { Header: t("Init Date"), accessor: 'config.calendar.init_date', typeCell: 'time', filter: 'time' },
        { Header: t("End Date"), accessor: 'config.calendar.end_date', typeCell: 'time', filter: 'time' },
        { Header: t("Name"), accessor: 'config.name', typeCell: 'text' },
        {
          Header: t("Events"), accessor: 'events', typeCell: 'text',
          Cell: (cellProps) => {
            return renderEvents(cellProps.value);
          },
          filter: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = renderEvents(row.values[id])
              return rowValue !== undefined
                ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
                : true;
            });
          }
        }
      ]

      const promotionFutureColumnsDef = [
        { Header: t("ID"), accessor: 'promotion', typeCell: 'text', filter: 'text' },
        { Header: t("Name"), accessor: 'config.name', typeCell: 'text' }
      ]

      const promotionDeletedColumnsDef = [
        { Header: t("ID"), accessor: 'promotion', typeCell: 'text', filter: 'text' },
        { Header: t("Name"), accessor: 'config.name', typeCell: 'text' }
      ]


      if (msg.result === "OK") {

        let now = utils.unformatDate(new Date())


        // msg.data.forEach(promotion => {
        //   if (promotion.deleted) {
        //     deletedPromotions.push(promotion)
        //   }
        //   else {
        //     if (new Date(promotion.config.calendar.init_date) < new Date(now) && new Date(promotion.config.calendar.end_date) > new Date(now)) {
        //       currentPromotions.push(promotion);
        //     } else {
        //       if (new Date(promotion.config.calendar.end_date) < new Date(now)) {
        //         pastPromotions.push(promotion);
        //       } else {
        //         futurePromotions.push(promotion);
        //       }
        //     }
        //   }
        // });
        msg.data.forEach(promotion => {
          if (promotion.deleted) {
            deletedPromotions.push(promotion)
          }
          else {

            if (utils.getDateServerToTZObj(promotion.config.calendar.init_date).getTime() < utils.getDateTZTodayObj().getTime() && utils.getDateServerToTZObj(promotion.config.calendar.end_date).getTime() > utils.getDateTZTodayObj().getTime()) {
              currentPromotions.push(promotion);
            } else {
              if (utils.getDateServerToTZObj(promotion.config.calendar.end_date).getTime() < utils.getDateTZTodayObj().getTime()) {
                pastPromotions.push(promotion);
              } else {
                futurePromotions.push(promotion);
              }
            }
          }
        });
      }
      //Configuración contenido de las tabs
      let tabContent = [
        { title: t("Current Promotions"), content: <BasicTable key="table-current-Promotions" data={currentPromotions} columns={promotionColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Future Promotions"), content: <BasicTable key="table-future-Promotions" data={futurePromotions} columns={promotionFutureColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Past Promotions"), content: <BasicTable key="table-past-Promotions" data={pastPromotions} columns={promotionColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Deleted Promotions"), content: <BasicTable key="table-deleted-Promotions" data={deletedPromotions} columns={promotionDeletedColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> }
      ]

      setTabsContent(tabContent);

    }
      , (err) => {
        console.log("Get promotions error " + err.code);
        props.showAlert("Error", `${err.code}: ${err.message}`);
      }
    );

  }, [])

  //Configuarción Cabecera Sección
  const dataHeader = {
    backLink: "",
    title: t("Promotions"),
    urlHelp: "",
    idDoc: props.idDoc,
    buttons: [
      { name: t("New promotion"), handle: handleNewPromotion, icon: <FontAwesomeIcon icon="plus-square" /> }
    ]
  }


  return (
    <>
      <LayoutSection dataHeader={dataHeader}>
        <Tab active={0}>
          {
            tabsContent.map((tab, idx) =>
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                {tab.content}
              </Tab.TabPane>
            )
          }
        </Tab>
      </LayoutSection>
    </>
  )
}

export default withTranslation()(PromotionList)
import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons'; // Asegúrate de importar el ícono

import ValleTest from './ValleTest';
import DocButton from '../../components/basic/DocButton';

const ValleTest2 = (props) => {
  const { t } = props;

  function showDocumentation() {
    props.showAlert(t("Documentation"), <ValleTest handleCloseAlert={props.handleCloseAlert} />, true, 'l')
  }

  useEffect(() => {
    // Código dentro del useEffect si es necesario
  }, []); // La dependencia vacía indica que esto solo se ejecuta al montar el componente

  return (
    /*<IconButton onClick={showDocumentation}>
      <FontAwesomeIcon icon={faKey} />
    </IconButton>*/
    <DocButton {...props} idDoc={'26181908'} />
  );
};

export default withTranslation()(ValleTest2);

import React, { useEffect, useMemo } from "react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination } from "react-table";
import { cellTableRender } from '../../libs/utilsReports'

import Pagination from "./Pagination";

const BasicTableServer = (props) => {
  const { t } = props

  const defaultColumn = useMemo(() => {
    return {
      typeCell: 'text' || undefined,
      Cell: (props) => cellTableRender(props, t)
    }
  }, [t])

  const { columns, data, fetchData, loading,
    pageCount: controlledPageCount,
    prevToken,
    nextToken,
    pageSize: controlledPageSize,
    totalRows,
    handlePageSizeChange, initPage
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    dispatch,
    state: { pageIndex, pageSize, customToken, sortBy }
  } = useTable(
    {
      columns, data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: controlledPageSize,
        customToken: 0
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      pageSize: controlledPageSize,
      manualSortBy: true,
      defaultCanSort: false,
      stateReducer: (newState, action, prevState) => {
        switch (action.type) {
          case "setToken":
            return { ...prevState, customToken: action.payload }
          case "setPageIndex":
            return { ...prevState, pageIndex: action.payload }
          default:
            return newState;
        }
      }
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch({ type: "setToken", payload: 0 });
    dispatch({ type: "setPageIndex", payload: 0 });
  }, [initPage])

  useEffect(() => {
    fetchData({ customToken, sortBy });
  }, [fetchData, customToken, sortBy]);


  const customPrevManage = () => {
    dispatch({ type: "setToken", payload: prevToken });
    previousPage();
  };

  const customNextManage = () => {
    dispatch({ type: "setToken", payload: nextToken });
    nextPage();
  };

  const customGoToPage = (page) => {
    let pageToken = page * pageSize
    dispatch({ type: "setToken", payload: pageToken });
    gotoPage(page);

  };

  const customSetPageSize = (size) => {
    setPageSize(size)
    customGoToPage(0)
    handlePageSizeChange(size)


  }
  const canSimpleNextPage = () => {
    return data.length >= pageSize
  }
  const canSimplePreviousPage = () => {
    return customToken > 0
  }

  const getPagination = () => {
    return (<Pagination
      pageSize={pageSize}
      setPageSize={customSetPageSize}
      gotoPage={customGoToPage}
      previousPage={customPrevManage}
      nextPage={customNextManage}
      canPreviousPage={(totalRows === undefined) ? canSimplePreviousPage() : canPreviousPage}
      canNextPage={(totalRows === undefined) ? canSimpleNextPage() : canNextPage}
      pageCount={pageCount}
      pageIndex={pageIndex}
      showInfoPage={(totalRows === undefined) ? false : true}
      showPageSize={true}
      showGoToPage={(totalRows === undefined) ? false : true}
      showTotalRegisters={(totalRows === undefined) ? false : true}
      totalRegisters={totalRows}
    ></Pagination>)
  }

  return (<>
    <div className="table-responsive">
      {data.length > 0 && getPagination()}
      <table {...getTableProps()} className="table table-striped table-hover">
        <thead>
          {headerGroups.map((headerGroup) => (<>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={column.className} {...column.getHeaderProps(
                  column.defaultCanSort ? (column.getSortByToggleProps())
                    : ("")
                )}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown />
                      ) : (
                        <FaSortUp />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
            {loading && <tr>
              <th key={"loading_data"} colSpan={headerGroup.headers.length}><div className={"row calimaco_no_data_found"}>{t("Loading ... ")}  </div></th>
            </tr>}
            {!loading && page.length === 0 && <tr>
              <th key={"no_data_col"} colSpan={headerGroup.headers.length}><div className={"row calimaco_no_data_found"}>{t("No data found")}  </div></th>
            </tr>}
          </>
          ))}
          {!loading && page.length > 0 && footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => {
                return (
                  <td className={column.className}  {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                );
              })}
            </tr>
          ))}
        </thead>
        {!loading && page.length > 0 &&
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={(evt) => {
                  if (props.rowHandle !== undefined && props.columnRowHandle === undefined) props.rowHandle(evt, row)
                }}>
                  {row.cells.map((cell) => {
                    return (
                      <td className={cell.column.className} {...cell.getCellProps()} onClick={(evt) => {
                        if (props.rowHandle !== undefined && props.columnRowHandle !== undefined && props.columnRowHandle === cell.column.id) props.rowHandle(evt, row)
                      }}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
        <tfoot>
          {!loading && page.length > 0 && footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => {
                return (
                  <td className={column.className} {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
      {data.length > 0 && getPagination()}
    </div></>
  )
}

export default withTranslation()(BasicTableServer);

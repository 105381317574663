import React from 'react';
import { getSmoothStepPath } from 'reactflow';

import { getEdgeParams } from './utilsFlows.js';

function FlowConnectionLine({ toX, toY, fromPosition, toPosition, fromNode }) {
    if (!fromNode) {
        return null;
    }

    const targetNode = {
        id: 'connection-target',
        width: 1,
        height: 1,
        positionAbsolute: { x: toX, y: toY }
    };

    const { sx, sy } = getEdgeParams(fromNode, targetNode);
    const [edgePath] = getSmoothStepPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: fromPosition,
        targetPosition: toPosition,
        targetX: toX,
        targetY: toY
    });

    return (
        <path
            fill="none"
            stroke="#222"
            strokeWidth={1.5}
            className="animated"
            d={edgePath}
        />

    );
}

export default FlowConnectionLine;

import React from 'react';
import utils from '../../utils';
import { parsleyValidationSPAIN, parsleyValidationPERU } from './parsleyValidationRegulation'

class InitDataPlayer {

    constructor() { }

    initDataPlayer = (datosPlayer, t, regulation) => {
        console.log('No existe inicialización de validaciones para la regulación ' + regulation)
        return datosPlayer
    }

    initDataPlayerSPAIN = (datosPlayer, t, regulation) => {

        console.log("Inicialización Validaciones de la regulación " + regulation)

        let datosModified = { ...datosPlayer }
        let messagesRegulation = {
            'es': t('Invalid Field For Regulation'),
            'en': t('Invalid Field For Regulation')
        }

        if (document.getElementById('Player_nationality')) window.Parsley.addValidator('playerNationality', { ...parsleyValidationSPAIN['playerNationality'], messages: messagesRegulation });
        if (document.getElementById('Player_country')) window.Parsley.addValidator('playerCountry', { ...parsleyValidationSPAIN['playerCountry'], messages: messagesRegulation });
        if (document.getElementById('Player_national_id_type')) window.Parsley.addValidator('playerNationalIdType', { ...parsleyValidationSPAIN['playerNationalIdType'], messages: messagesRegulation });
        if (document.getElementById('Player_verification_type')) window.Parsley.addValidator('playerVerificationType', { ...parsleyValidationSPAIN['playerVerificationType'], messages: messagesRegulation });
        if (document.getElementById('Player_birthday')) window.Parsley.addValidator('playerBirthDay', { ...parsleyValidationSPAIN['playerBirthDay'], messages: messagesRegulation });



        if (datosPlayer.user.national_id_type === "DNI" || datosPlayer.user.national_id_type === "NIE") {
            //Si el tipo es dni o nie no se permitirá cambiar el número.        
            datosModified.noEditFields.push('national_id')
            datosModified.noEditFields.push('national_id_type')

        }
        return datosModified
    }

    initDataPlayerPERU = (datosPlayer, t, regulation) => {

        console.log("Inicialización Validaciones de la regulación " + regulation)

        let datosModified = { ...datosPlayer }
        let messagesRegulation = {
            'es': t('Invalid Field For Regulation'),
            'en': t('Invalid Field For Regulation')
        }

        if (document.getElementById('Player_nationality')) window.Parsley.addValidator('playerNationality', { ...parsleyValidationPERU['playerNationality'], messages: messagesRegulation });
        if (document.getElementById('Player_country')) window.Parsley.addValidator('playerCountry', { ...parsleyValidationPERU['playerCountry'], messages: messagesRegulation });
        if (document.getElementById('Player_birthday')) window.Parsley.addValidator('playerBirthDay', { ...parsleyValidationPERU['playerBirthDay'], messages: messagesRegulation });

        return datosModified
    }
    initDataPlayerPERUAT = (datosPlayer, t, regulation) => {
        return this.initDataPlayerPERU(datosPlayer, t, regulation)
    }

}

let initRegulationDataPlayer = new InitDataPlayer()

let initDataPlayerRegulation = (datosPlayer, t) => {
    let regulation = utils.getCurrentCompany().regulation

    if ('initDataPlayer' + regulation in initRegulationDataPlayer)
        return initRegulationDataPlayer['initDataPlayer' + regulation](datosPlayer, t, regulation)
    else return initRegulationDataPlayer['initDataPlayer'](datosPlayer, t, regulation)
}

export { initDataPlayerRegulation }
import React from 'react';
import { withTranslation } from 'react-i18next';

import utils from '../../utils';
import AgentsMenu from '../../components/AgentsMenu';
import Report from '../../components/basic/Report';

function MyAgents(props) {
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { t } = props;



    return (
        <>
            <Report className="mt-3" view="table" session={session} reportId="agents_subagents_sql_v5" limit={20} ></Report>

            <Report className="mt-3" view="table" session={session} reportId="agents_subagents_sql" limit={20} ></Report>
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(MyAgents);
import React from "react";
import AdminClient from "../AdminClient";
import utils from "../utils";

const MACHINES = "machine";
const GRANTS = "grant"
const PROVIDERSMACHINES = "providerMachine";

const sortByLabel = (a, b) => {
    return (a.labelOriginal.toUpperCase() > b.labelOriginal.toUpperCase()) ? 1 : ((b.labelOriginal.toUpperCase() > a.labelOriginal.toUpperCase()) ? -1 : 0);
}


function getMachinesForTree(machines, t, tplItem, config) {

    let treeMachines = [];
    let treeAux = {}
    let countMachines = 0
    for (let id in machines) {
        let machine = machines[id];
        machine.index = countMachines

        if (treeAux[machine.provider] === undefined) {
            treeAux[machine.provider] = {};
        }
        if (treeAux[machine.provider][machine.sub_provider] === undefined) {
            treeAux[machine.provider][machine.sub_provider] = [];
        }
        treeAux[machine.provider][machine.sub_provider].push(machine);
        countMachines++
    }

    for (let providerId in treeAux) {
        let subproviders = [];
        for (let subprovider in treeAux[providerId]) {
            let configProvider = config?.find(objeto => objeto.provider === subprovider);
            let machinesAux = [];

            if (configProvider?.config?.promotions?.classification) {
                machinesAux = getProvidersMachinesForTree(treeAux[providerId][subprovider], t, tplItem, configProvider?.config?.promotions?.classification);
            }
            else {
                treeAux[providerId][subprovider].forEach(machine => {
                    machinesAux.push({
                        value: machine.machine,
                        labelOriginal: machine.name,
                        label: tplItem ? tplItem(machine) : machine.name,
                        provider: "provider",
                        className: "rct-machine"
                    });
                });
            }
            subproviders.push({
                label: subprovider,
                labelOriginal: subprovider,
                value: "SUBPROVIDER_" + providerId + "_" + subprovider,
                className: "rct-subprovider",
                children: machinesAux.sort(sortByLabel)
            });
        };
        treeMachines.push({
            label: providerId,
            labelOriginal: providerId,
            value: "PROVIDER_" + providerId,
            className: "rct-provider",
            children: subproviders.sort(sortByLabel)
        });

    };

    treeMachines = treeMachines.sort(sortByLabel);

    return treeMachines
}
function getExpandMachines(list, typeTree, elementsLeaf) {
    let expanded = []
    let elementsLeafInt = []
    if (isTypeInt(typeTree)) {
        elementsLeafInt = elementsLeaf.map((el) => {

            return parseInt(el)
        })
    }

    list.forEach(element => {

        if (elementsLeafInt.includes(element.machine)) {
            let providerExpand = "PROVIDER_" + element.provider
            let subproviderExpand = "SUBPROVIDER_" + element.provider + "_" + element.sub_provider
            if (!expanded.includes(providerExpand)) expanded.push(providerExpand)
            if (!expanded.includes(subproviderExpand)) expanded.push(subproviderExpand)
        }
    });
    return expanded
}

function getGrantsForTree(grantsList, t) {

    let grantsTreeAux = {};

    grantsList.forEach(element => {
        if (grantsTreeAux[element.family] === undefined) {
            grantsTreeAux[element.family] = [];
        }
        grantsTreeAux[element.family].push(element);
    });

    let grantsNodes = generateTree(t, grantsTreeAux, "grant", { value: "grant", label: "grant", labelOriginal: "grant", prefixNode: "GRANT_", prefixParent: "FAMILY_", prefixParentLabel: "FAMILY_" });

    return grantsNodes;
}

function getProvidersMachinesForTree(machines, t, tplItem, config) {
    let treeAux = {}
    let countMachines = 0
    for (let id in machines) {
        let machine = machines[id];
        machine.index = countMachines
        let category = machine[config];
        if (treeAux[category] === undefined) {
            treeAux[category] = [];
        }
        treeAux[category].push(machine);
        countMachines++
    }
    let provider;
    if (Array.isArray(machines)) {
        provider = machines[0]?.sub_provider;
    } else if (typeof machines === 'object' && machines !== null) {
        const firstValue = Object.values(machines)[0];
        provider = firstValue?.sub_provider;
    } else {
        console.error("'machines' no es ni un array ni un objeto válido.");
    }

    let treeMachines = generateTree(t, treeAux, "machine", { value: "machine", label: "name", labelOriginal: "name", prefixParent: provider + "_" });

    return treeMachines
}
function generateNodes(t, treeAux, keyID, type, nodeProps) {
    let nodes = [];
    treeAux[keyID].forEach(member => {
        nodes.push({
            value: member[nodeProps.value],
            label: t((nodeProps.prefixNode || "") + member[nodeProps.label]),
            labelOriginal: t((nodeProps.prefixNode || "") + member[nodeProps.labelOriginal]),
            className: "rct-" + type
        });

    });
    return nodes;
}

function generateTree(t, treeAux, type, nodeProps) {
    let treeComplete = []
    for (let element in treeAux) {
        let nodes = generateNodes(t, treeAux, element, type, nodeProps)
        treeComplete.push({
            label: t((nodeProps.prefixParentLabel || "") + element),
            labelOriginal: t((nodeProps.prefixParentLabel || "") + element),
            value: (nodeProps.prefixParent || "") + element,
            className: "rct-" + type,
            children: nodes.sort(sortByLabel)
        });

    };

    treeComplete = treeComplete.sort(sortByLabel);

    return treeComplete;
}

export async function getDataTree(list, typeTree, t, itemTpl, config, extraConfig) {

    function filterListObjects(list) {
        let filterList = {};
        for (let i in list) {
            let objeto = list[i];
            if (objeto.status !== 'DISABLED' && objeto.external_id !== undefined && objeto.external_id !== null && objeto.external_id !== '') {
                if (extraConfig !== undefined && extraConfig.freeSpin) {
                    if (objeto.allow_freespins === 1) {
                        filterList[i] = objeto;
                    }
                }
                else {
                    filterList[i] = objeto;
                }
            }
        }
        return filterList;
    }

    switch (typeTree) {

        case MACHINES: {
            try {
                let filterList = filterListObjects(list);

                const adminClient = new AdminClient();
                let providers = await adminClient.getProviders(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"))
                let providersParse;
                if (providers !== undefined && providers.data !== undefined) {
                    providersParse = providers.data.map(objeto => {
                        try {
                            if (objeto["config"] !== undefined) {
                                objeto["config"] = JSON.parse(objeto["config"]);
                            }
                            return objeto;
                        } catch (err) {
                            console.log(err);
                            return objeto;
                        }
                    });
                }
                return getMachinesForTree(filterList, t, itemTpl, providersParse)
            }
            catch (err) {
                console.log(err);
            }
        }
        case GRANTS:
            return getGrantsForTree(list, t, itemTpl)
        case PROVIDERSMACHINES:
            let filterList = filterListObjects(list);
            return getProvidersMachinesForTree(filterList, t, itemTpl, config)
        default:
            return []
    }
}


export function getExpandIds(list, typeTree, elementsLeaf) {
    switch (typeTree) {

        case MACHINES:
            return getExpandMachines(list, typeTree, elementsLeaf)
        default:
            return []
    }
}

export function isTypeInt(typeTree) {

    switch (typeTree) {
        case MACHINES:
            return true
        case GRANTS:
            return false
        default:
            return []
    }
}
import React, { useState, useEffect } from 'react'
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from '../../components/basic/BasicTabs';
import BasicTable from '../../components/basic/BasicTable';



function TournamentList(props) {

  const { t } = props
  const [tabsContent, setTabsContent] = useState(
    [
      { title: t("Current Tournaments"), content: "" },
      { title: t("Future Tournaments"), content: "" },
      { title: t("Past Tournaments"), content: "" }
    ])

  const handleClick = (id) => {
    console.log("Tournament  id = " + id);
    utils.setSessionItem("current-tournament", id);
    props.history.push('/marketing/tournaments/details');
  }

  const handleDetailsRow = (row) => {
    handleClick(row.original.id)
  }
  const handleNewTournament = () => {
    console.log("New Tournament")
    handleClick(-1)
  }

  useEffect(() => {

    let clientAPI = new AdminClient();
    let promise = clientAPI.getReport(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), "tournaments", null, null);

    promise.then((msg) => {

      let currentTournaments = [];
      let futureTournaments = [];
      let pastTournaments = [];

      const tournamentColumnsDef = [
        { Header: t("ID"), accessor: 'id', typeCell: 'text', filter: 'text' },
        { Header: t("Init Date"), accessor: 'init_date', typeCell: 'time', filter: 'time' },
        { Header: t("End Date"), accessor: 'end_date', typeCell: 'time', filter: 'time' },
        { Header: t("Name"), accessor: 'name', typeCell: 'text', filter: 'text' },
        { Header: t("Status"), accessor: 'status', typeCell: 'translate', filter: 'translate' }
      ]

      if (msg.result === "OK") {
        msg.data.data.forEach(tournament => {
          if (utils.getDateServerToTZObj(tournament.init_date).getTime() < utils.getDateTZTodayObj().getTime() && utils.getDateServerToTZObj(tournament.end_date).getTime() > utils.getDateTZTodayObj().getTime()) {
            currentTournaments.push(tournament);
          } else {
            if (utils.getDateServerToTZObj(tournament.end_date).getTime() < utils.getDateTZTodayObj().getTime()) {
              pastTournaments.push(tournament);
            } else {
              futureTournaments.push(tournament);
            }
          }
        });
      }
      //Configuración contenido de las tabs
      let tabContent = [
        { title: t("Current Tournaments"), content: <BasicTable key="table-current-tournament" data={currentTournaments} columns={tournamentColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Future Tournaments"), content: <BasicTable key="table-future-tournament" data={futureTournaments} columns={tournamentColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> },
        { title: t("Past Tournaments"), content: <BasicTable key="table-past-tournament" data={pastTournaments} columns={tournamentColumnsDef} rowHandle={handleDetailsRow} showFilterColumn={true} /> }
      ]

      setTabsContent(tabContent);

    }
      , (err) => {
        console.log("Get promotions error " + err.code);
        props.showAlert("Error", `${err.code}: ${err.message}`);
      }
    );

  }, [])

  //Configuarción Cabecera Sección
  const dataHeader = {
    backLink: "",
    title: t("Tournaments"),
    urlHelp: "",
    idDoc: props.idDoc,
    buttons: [
      { name: t("New tournament"), handle: handleNewTournament, icon: <FontAwesomeIcon icon="plus-square" /> }
    ]
  }


  return (
    <>
      <LayoutSection dataHeader={dataHeader}>
        <Tab active={0}>
          {
            tabsContent.map((tab, idx) =>
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                {tab.content}
              </Tab.TabPane>
            )
          }
        </Tab>
      </LayoutSection>
    </>
  )
}

export default withTranslation()(TournamentList)
import React, { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import BasicSelect from "../../components/basic/BasicSelect";
import { TextField } from "@mui/material";
import PrivateLayout from "../../components/NavigationAndLayouts/PrivateLayout";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import useFormValidation from "../../hooks/parsleyValidation";

const UserManualAdjustment = (props) => {

    const { t } = props
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_UserManualAdjustment')

    const user = JSON.parse(utils.getSessionItem("userDetails")).user;
    const company = utils.getCurrentCompany().company;
    const companyDetails = utils.getCurrentCompany(company);
    const [blockAdjustment, setBlockAdjustment] = useState(false)

    const [adjustment, setAdjustment] = useState({
        amount: utils.formatNumber(10, 2),
        account: "",
        comments: ""
    });
    const [accountsList, setAccountsList] = useState([])

    const adminClient = new AdminClient()

    useEffect(() => {
        adminClient.getAccounts(session, company).then(
            function (msg) {
                let accountsListAux = [];
                msg.accounts.forEach(element => {
                    accountsListAux.push({ label: element.name, value: element.account });
                });
                setAccountsList(accountsListAux)
            },
            function (err) {
                console.log("Accounts error " + err.code);
            }
        );


    }, [])

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setAdjustment((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = () => {
        if (formValidation.validate()) {
            setBlockAdjustment(true)
            adminClient.manualAdjustment(
                session, company, user.db, user.user,
                adjustment.account, adjustment.amount.replace(",", ".") * 100,
                adjustment.comments).then(
                    function (msg) {
                        props.showAlert(t("Manual adjustment"), t("Manual adjustment ok explanation"));
                        goBack()

                    }, function (err) {
                        setBlockAdjustment(false)
                        props.showAlert(
                            t("Manual adjustment Error"),
                            t("Manual adjustment ok explanation") + " " + t("ERROR_" + err.code)
                        );
                    }.bind(this)
                );
        }
    }
    const goBack = () => {
        props.history.push("/support/user_accounts");
    };
    const dataHeader = {
        backLink: goBack,
        title: t("Manual adjustment"),
        urlHelp: "",
        idDoc: props.idDoc
    };
    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_UserManualAdjustment">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            required
                            idProperty='account'
                            data='accounts'
                            basicInfoName='Accounts'
                            value={adjustment.account}
                            name='account'
                            label={t('Account')}
                            onChange={onChangeHandler}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField id="amount" label={t("Amount")} type="text" required name="amount" value={adjustment.amount} onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-12">
                        <TextField multiline label={t("Comment")} type="text" name="comments" value={adjustment.comments} onChange={onChangeHandler} />
                    </div>
                </div >
            </form>
            <div className="row">
                <button className="btn btn-primary float-end" disabled={blockAdjustment} onClick={save}>{t("Do adjustment")}</button>

            </div>
        </LayoutSection>
    </>
    )
}

export default withTranslation()(withRouter(UserManualAdjustment))
import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import 'jsoneditor/dist/jsoneditor.css'; // Importa los estilos
import EditorJSON from '../../components/basic/EditorJSON';
import Tab from '../../components/basic/BasicTabs';
import TableAndDetails from '../../components/basic/TableAndDetails';
import { generateColumnsDef } from '../../libs/utilsReports';


function ProviderErrorCodes(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_ProvidersErrorCodesDetails');
    const [addOrUpdateCode, setAddOrUpdateCode] = useState('');
    const [codeReport, setCodeReport] = useState({
        columns: [],
        data: []
    });
    const langsArray = JSON.parse(utils.getSessionItem("basicInfo-languages"))
    const desiredLangsArray = companyDetails.langs.split(',');
    const langs = langsArray.filter(lang => desiredLangsArray.includes(lang.lang));

    const initialCodeDetails = {
        error_code: "",
        description: "",
        langs: {
            ...langs.reduce((accumulator, value) => {
                let langPref = value.lang;
                accumulator[langPref] = "";
                return accumulator;
            }, {})
        }
    }
    const [codeDetails, setCodeDetails] = useState(initialCodeDetails);

    useEffect(function () {

        if (props.provider !== undefined && props.provider !== '-1') {
            Promise.all([
                adminClient.getReport(session, company, "providers_errors", [{ "field": "t.provider", "type": "text", "value": props.provider }], utils.getSessionItem("current-user")?.split(".")[1], utils.getSessionItem("current-user")?.split(".")[0]),
            ]).then(
                function (result) {
                    let columnsDef = generateColumnsDef(result[0].data.def, t);
                    setCodeReport((prevState) => ({
                        ...prevState,
                        data: result[0].data.data,
                        columns: columnsDef,
                    }));
                },
                function (err) {
                    console.error(err);
                }
            )

        }
    }, []);

    const onChangeHandlerCode = (evt) => {
        const { name, value } = evt.target || evt;
        setCodeDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const onChangeHandlerCodeLang = (evt) => {
        const { name, value } = evt.target || evt;
        setCodeDetails(prevState => ({
            ...prevState,
            langs: {
                ...prevState.langs,
                [name]: value,
            }
        }));
    }
    const reloadReport = () => {
        adminClient.getReport(session, company, "providers_errors", [{ "field": "t.provider", "type": "text", "value": props.provider }], utils.getSessionItem("current-user")?.split(".")[1], utils.getSessionItem("current-user")?.split(".")[0]).then(
            function (result) {
                let columnsDef = generateColumnsDef(result.data.def, t);
                setCodeReport((prevState) => ({
                    ...prevState,
                    data: result.data.data,
                    columns: columnsDef,
                }));
            },
            function (err) {
                console.error(err);
            }
        )

    }
    const addOrUpdateCodeHandler = async (detailReport) => {
        formValidation?.reset()

        if (detailReport === -1) {
            setCodeDetails(initialCodeDetails)
            setAddOrUpdateCode("add");
        } else {
            adminClient.getProvidersErrorCodeI18n(session, company, detailReport.provider, detailReport.error_code).then(
                function (result) {
                    let detailsI18n = result.data;
                    setCodeDetails((prevState) => {
                        let oldState = { ...prevState };
                        oldState.langs = initialCodeDetails.langs
                        detailsI18n.forEach(element => {
                            oldState.langs[element.lang] = element.description
                        });
                        oldState.error_code = detailReport.error_code;
                        oldState.description = detailReport.description;
                        return oldState;
                    });
                    setAddOrUpdateCode("update");
                },
                function (err) {
                    console.log(err)
                }
            )
        }
    };
    const saveCodeDetails = (evt) => {

        if (formValidation?.validate()) {
            adminClient[addOrUpdateCode + "ProvidersErrorCode"](session, company, props.provider, JSON.stringify(codeDetails)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Provider details'), t('Saved succesfull'))
                        if (addOrUpdateCode === 'add') {
                            setAddOrUpdateCode('update')
                        }
                        reloadReport();

                    } else {
                        props.showAlert(t('Provider details'), t('Saving error ') + t(msg.description))
                    }
                },
                (error) => {
                    props.showAlert(t('Provider details'), t('Saving error ') + t(error.description))
                }
            )
        }
    }
    const deleteCodeDetails = (error_id) => {
        let error_code = codeReport.data[error_id].error_code
        adminClient.deleteProvidersErrorCode(session, company, props.provider, error_code).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Provider details'), t('Deleted succesfull'))
                    reloadReport();
                } else {
                    props.showAlert(t('Provider details'), t('Deleting error ') + t(msg.description))
                }
            },
            (error) => {
                props.showAlert(t('Provider details'), t('Deleting error ') + t(error.description))
            }
        )
    }
    const renderCodes = useCallback((props) => {
        return (
            <>
                <form id="Form_ProvidersErrorCodesDetails">

                    <div className="row">
                        <div className="col-12">
                            <TextField
                                label={t('Error code')}
                                name="error_code"
                                required
                                disabled={addOrUpdateCode === 'update'}
                                inputProps={addOrUpdateCode === "add" ? { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') } : {}}
                                type="text"
                                value={codeDetails.error_code}
                                onChange={onChangeHandlerCode}
                            />
                        </div>
                        <div className="col-12">
                            <TextField
                                label={t("General description")}
                                type="text"
                                name="description"
                                required
                                multiline
                                inputProps={{ maxLength: 1000 }}
                                value={codeDetails.description}
                                onChange={onChangeHandlerCode}
                            />
                        </div>
                        {langs.map((value) => {

                            return (
                                <div className="col-12">
                                    <TextField
                                        label={t(value.name)}
                                        type="text"
                                        name={value.lang}
                                        required
                                        multiline
                                        inputProps={{ maxLength: 1000 }}
                                        value={codeDetails.langs[value.lang]}
                                        onChange={onChangeHandlerCodeLang}
                                    />
                                </div>
                            );
                        })}
                    </div >
                </form>
            </>

        );
    }, [JSON.stringify(props), codeDetails, addOrUpdateCode]);

    return (

        <TableAndDetails
            colTableSize={6}
            sizePageTable={10}
            ShowNew={true}
            columns={codeReport.columns}
            data={codeReport.data}
            renderDetails={renderCodes}
            setDetail={addOrUpdateCodeHandler}
            showDeleteButton={addOrUpdateCode === "update"}
            deletehandler={deleteCodeDetails}
            showSaveButton
            disabledSaveButton={codeDetails.error_code === ""}
            handleSaveDetails={() => { saveCodeDetails() }}
            showAlert={props.showAlert}
            handleCloseAlert={props.handleCloseAlert}
            desactiveForm={true}
        />

    )
}
function ProvidersDetails(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCurrentCompany();
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_ProvidersDetails')

    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [JSONErrors, setJSONErrors] = useState(false);

    const [providerDetails, setProviderDetails] = useState({
        name: '',
        provider: '',
        status: '',
        company: company,
        config: {},
    });
    const provider = utils.getSessionItem('current-provider')
    const goBack = () => {
        props.history.push('/reports/report/providers/table');
    };

    const dataHeader = {
        backLink: goBack,
        title: t('Provider details') + '',
        idDoc: props.idDoc
    };
    useEffect(function () {


        if (provider !== undefined && provider !== '-1') {
            Promise.all([
                adminClient.getProviderDetails(session, company, provider)
            ]).then(
                function (result) {
                    let providerAux = result[0].data
                    if (providerAux.config === null) providerAux.config = "{}"
                    providerAux.config = JSON.parse(providerAux.config);
                    setProviderDetails(providerAux);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            )

        } else {

            setAddOrUpdate('add');
        }
    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setProviderDetails((prevState) => ({ ...prevState, [name]: value }));
    };
    const onChangeHandlerJSON = (e) => {
        setProviderDetails((prevState) => ({ ...prevState, config: e }));
    };

    const save = (evt) => {
        adminClient.getProviderDetails(session, company, providerDetails.provider).then(
            function (result) {
                if (addOrUpdate === "add" && result.data !== undefined) {
                    props.showAlert(t('Provider details'), t('Id in use'))
                }
                else {
                    if (formValidation.validate()) {
                        if (JSONErrors) {
                            props.showAlert(t('Provider details'), t('JSON format incorrect'))
                        }
                        else {
                            let providerDetailsAux = { ...providerDetails };
                            if (providerDetailsAux.super_provider !== undefined && providerDetailsAux.super_provider !== '' && providerDetailsAux.super_provider !== null) providerDetailsAux.type = 'SUBPROVIDER'
                            else providerDetailsAux.type = 'PROVIDER'
                            let configAux = JSON.parse(JSON.stringify(providerDetailsAux.config))
                            providerDetailsAux.config = JSON.stringify(configAux, null, 2)
                            console.log(providerDetailsAux)
                            adminClient.saveProvider(
                                session,
                                company,
                                JSON.stringify(providerDetailsAux)
                            ).then(
                                (msg) => {
                                    if (msg.result === 'OK') {
                                        props.showAlert(t('Provider details'), t('Saved succesfull'))
                                        if (addOrUpdate === 'add') {
                                            setAddOrUpdate('update')
                                        }
                                    } else {
                                        props.showAlert(t('Provider details'), t('Saving error ') + t(msg.description))
                                    }
                                },
                                (error) => {
                                    props.showAlert(t('Provider details'), t('Saving error ') + t(error.description))
                                }
                            )
                        }
                    }
                }

            },
            function (err) {
                console.log(err)
            }
        )


    }

    const handleRemove = (evt) => {
        props.showAlert(
            t('Role details'),
            <p>{t('Are you sure you want to delete?')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    remove(evt)
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )

    }
    const remove = (evt) => {
        adminClient.deleteProvider(
            session,
            company,
            JSON.stringify(providerDetails)
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Provider details'), t('Deleted succesfull'))
                    goBack();
                } else {
                    props.showAlert(t('Provider details'), t('Deleting error ') + t(msg.description))
                }
            },
            (error) => {
                props.showAlert(t('Provider details'), t('Deleting error ') + t(error.description))
            }
        )
    }



    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_ProvidersDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="provider"
                            required
                            disabled={addOrUpdate === 'update'}
                            InputProps={addOrUpdate === 'add' ? {
                                inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                            } : {}}
                            type="text"
                            value={providerDetails.provider}
                            onChange={onChangeHandler}
                        />
                    </div>

                    <div className="col-12  col-md-6 ">
                        <TextField
                            label={t("Name")}
                            type="text"
                            name="name"
                            value={providerDetails.name}
                            onChange={onChangeHandler} />
                    </div>

                    <div className="col-12 col-sm-2">
                        <BasicSelect
                            idProperty="name"
                            name="status"
                            label={t("Status")}
                            basicInfoName="EnabledDisabled"
                            onChange={onChangeHandler}
                            value={providerDetails.status}
                            translate={{ property: "name", prefix: "" }}
                            disableClereable
                            required

                        />
                    </div>
                    <div className="col-12 col-sm-2">
                        <BasicSelect
                            idProperty="provider"
                            name="super_provider"
                            label={t("Provider")}
                            basicInfoName="Providers"
                            filters={[{ property: "type", value: "PROVIDER" }]}
                            onChange={onChangeHandler}
                            value={providerDetails.super_provider}
                        />

                    </div>
                </div>
            </form>
            {utils.isGranted('SAVE_CONFIGURATION_PROVIDERS') &&

                <div className="row">
                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleRemove}
                        >
                            {t('Delete')}
                        </button>
                    </div>
                </div>
            }
            {addOrUpdate === "update" &&
                <div className="col-12   ">
                    <Tab key="tabContentGroupsTags" active={0}>
                        <Tab.TabPane key={`TabContentView`} tab={t("Configuration")}>
                            <EditorJSON
                                onChange={onChangeHandlerJSON}
                                setError={(status) => { setJSONErrors(status) }}
                                value={providerDetails.config}
                            />
                        </Tab.TabPane>
                        <Tab.TabPane key={`TabContentPlay`} tab={t("Error codes")}>
                            <ProviderErrorCodes showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert} provider={providerDetails.provider} t={t} />

                        </Tab.TabPane>
                    </Tab>
                </div>
            }




        </LayoutSection>
    )
}
export default withTranslation()(ProvidersDetails);
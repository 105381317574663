import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
function InternalTaskDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_InternalTaskDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [internalTask, setInternalTask] = useState({
        task: "",
        name: "",
        config: {
            type: ""
        }
    });
    const task = utils.getSessionItem('current-task')
    const goBack = () => {
        props.history.push('/reports/report/internal_tasks/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Internal task details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        adminClient.getInternalTaskDetails(session, company, task).then(
            function (result) {
                let auxTask = result.data;
                auxTask.config = JSON.parse(auxTask.config);
                setInternalTask(auxTask);
                setAddOrUpdate('update');
            },
            function (err) {
                console.error(err);
            }
        );

    }, []);
    const execute = (evt) => {
        if (formValidation.validate()) {
            adminClient.executeInternalTask(
                session,
                company,
                JSON.stringify(internalTask)
            ).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Internal task details'), t('Executed succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Internal task details'), t('Executing error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Internal task details'), t('Executing error ') + error.description)
                }
            )
        }
    }

    const updateParam = (param, evt) => {
        setInternalTask((prevState) => {
            let oldState = JSON.parse(JSON.stringify(prevState));
            oldState.config.procedure.params.forEach(element => {
                if (element.name === param) {
                    element.default = evt.target.value;
                }
            });
            return oldState;
        });
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_InternalTaskDetails">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('ID')}
                            name="task"
                            disabled
                            type="text"
                            value={internalTask.task}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('Name')}
                            name="name"
                            disabled
                            type="text"
                            value={internalTask.name}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('Type')}
                            name="type"
                            disabled
                            type="text"
                            value={internalTask.config.type}
                        />
                    </div>
                    <div className="row ">
                        {internalTask.config.type === 'procedure' &&
                            <div className="col-12  col-md-12  ">
                                <TextField
                                    label={t("Procedure")}
                                    name="procedure"
                                    disabled
                                    type="text"
                                    value={internalTask.config.procedure.procedure}
                                />
                            </div>
                        }
                        {internalTask.config.type === 'procedure' &&
                            <div className="row p-0 m-0">
                                {internalTask.config.procedure.params.map(function (value, index, arr) {
                                    return (
                                        <div className="col-12  col-md-4 ">
                                            <TextField
                                                label={t(value.label)}
                                                type="text"
                                                value={value.default}
                                                onChange={evt => updateParam(value.name, evt)}
                                            />
                                        </div>)
                                })}
                            </div>
                        }

                    </div>

                    {utils.isGranted('EXECUTE_INTERNAL_TASKS') &&
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary" onClick={execute}>{t("Execute")}</button>
                            </div>
                        </div>
                    }
                </div>
            </form>
        </LayoutSection>
    )
}
export default withTranslation()(InternalTaskDetails);
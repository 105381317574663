
const momentTimeZone = require('moment-timezone');
const moment = require('moment');

var numberFormat = "es-PE";

function formatNumber(number) {
  return new Intl.NumberFormat(numberFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
}

function parseNumber(number) {

  let comparer = formatNumber(10000 / 3);

  let thousandSeparator = comparer[1];
  let decimalSeparator = comparer[5];

  // remove thousand seperator
  let result = number.replace(thousandSeparator, '')
    // remove non-numeric signs except -> , .
    .replace(/[^\d.,-]/g, '')
    // replace original decimalSeparator with english one
    .replace(decimalSeparator, '.');

  // return real number
  return Number(result);


}

function toMenuArray(t, menu, prefix = "MENU") {

  var a = Object.values(menu);
  for (var i in a) {
    var el = a[i];

    el.item.label = t(prefix + "_" + el.item.item);
    if (el.childs !== undefined) {
      el.childs = toMenuArray(t, el.childs, prefix);
    }
  }

  return a;
}

/*
function toMenuArray2(t, menu, prefix="MENU") {
  
  var a = Object.values(menu);
  a.map((el, index) => {
    el.item.label = t(prefix+"_" + el.item.item);
    if (el.childs !== undefined && el.childs !== {}) {
      el.childs = toMenuArray(t,el.childs, prefix);
    }
  })

  return a;
}*/


function formatCurrency(number, currency) {
  if (number !== undefined) {

    var numberFormated = "";

    try {
      numberFormated = new Intl.NumberFormat(numberFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2, style: "currency", currency: currency }).format(number);
      // if (currency === 'PEN') {
      //   numberFormated = new Intl.NumberFormat('es-PE', { style: "currency", currency: currency }).format(number);
      // } else {
      //   if (currency === 'PYG') {
      //     numberFormated = new Intl.NumberFormat('es-ES', {
      //       minimumFractionDigits: 2,
      //       style: "currency",
      //       currency: currency
      //     }).format(number);
      //   }
      //   else {

      //     if (numberFormat === 'es-ES') {
      //       numberFormated = new Intl.NumberFormat(numberFormat, {minimumFractionDigits: 2, maximumFractionDigits: 2 ,style: "currency", currency: currency }).format(number);
      //     } else {
      //       numberFormated = new Intl.NumberFormat(numberFormat, { style: "currency", currency: currency }).format(number);
      //     }
      //   }
      // }
    } catch (err) {
      numberFormated = new Intl.NumberFormat(numberFormat).format(number);
    }
    return numberFormated;
  } else {
    return "";
  }
}


function formatDate(d, formatDate) {
  if (formatDate === undefined)
    formatDate = "YYYY-MM-DD HH:mm:ss";

  //console.log("formatDate d = " + d);
  if (d !== undefined && d !== "") {
    if (typeof d !== Date) {
      d = new Date(d);
      d = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
    }


    //    return momentTimeZone(d).tz(getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    var formateado = momentTimeZone(d).tz(getCurrentCompany().config.timezone).format(formatDate);
    //  console.log("formatDate formateado = " + formateado);
    return formateado;
  } else {
    return "";
  }
}



function unformatDate(d) {
  console.log("unformatDate Fecha en tz actual  =   " + d + " type " + typeof (d));

  if (d instanceof Date) {
    d = momentTimeZone(d).tz(moment.tz.guess()).format(("YYYY-MM-DD HH:mm:ss"));
    console.log("unformatDate Fecha en tz actual  =   " + d + " type " + typeof (d));
  }
  let defaultTimezone = momentTimeZone.tz.defaultTimezone
  momentTimeZone.tz.setDefault(getCurrentCompany().config.timezone);


  console.log("unformatDate d3 = " + momentTimeZone(d).tz(getCurrentCompany().config.timezone).tz("UTC").format(("YYYY-MM-DD HH:mm:ss")));
  let formateado = ""
  if (d !== undefined) {
    //var formateado = momentTimeZone(d).tz('UTC').format(("YYYY-MM-DD hh:mm:ss"));
    formateado = momentTimeZone(d).tz(getCurrentCompany().config.timezone).tz("UTC").format(("YYYY-MM-DD HH:mm:ss"));
    console.log("unformatDate formateado = " + formateado);
  }

  momentTimeZone.tz.setDefault(defaultTimezone)
  return formateado

}

function formatDateUTC(d) {
  if (d !== undefined) {
    if (typeof d !== Date) {
      d = new Date(d);
    }

    return momentTimeZone(d).format(("YYYY-MM-DD HH:mm:ss"));
  } else {
    return "";
  }


}



function formatDate2(d) {
  if (d !== undefined && d !== null) {
    if (typeof d !== Date) {
      d = new Date(d);
    }

    return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
  } else {
    return null;
  }
}

function formatTime(_d) {
  var d;
  if (d !== undefined) {
    d = _d;
    if (typeof d !== Date) {
      d = new Date(d);
    }

  } else {
    d = new Date();
  }
  return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
}

function getCompany(companyId) {
  var company = null;
  JSON.parse(getSessionItem("Companies")).forEach(element => {
    if (element.company === companyId) {

      //console.log("Element = " + JSON.stringify(element));
      company = element;
    }

  });
  //console.log("Company = " + JSON.stringify(company));
  return company;

}

function getCurrentCompany() {
  var companyId = getSessionItem("Company");
  var company = null;
  JSON.parse(getSessionItem("Companies")).forEach(element => {
    if (element.company === companyId) {

      //  console.log("Element = " + JSON.stringify(element));
      company = element;
    }

  });
  //console.log("Company = " + JSON.stringify(company));
  return company;

}

function setNumberFormat(format) {
  numberFormat = format;
  var user = JSON.parse(getSessionItem("user"));
  user.number_format = format;
  setSessionItem("user", JSON.stringify(user));
}
function setSessionItem(item, value) {
  switch (item) {
    case "current-user":
    case "userDetails":

      return sessionStorage.setItem(item, value);

    default:
      return localStorage.setItem(item, value);
  }
}
function getSessionItem(item) {
  switch (item) {
    case "current-user":
    case "userDetails":
      return sessionStorage.getItem(item);

    default:
      return localStorage.getItem(item);
  }
}
function removeSessionItem(item) {
  switch (item) {
    case "current-user":
    case "userDetails":
      sessionStorage.removeItem(item);
      break;
    default: localStorage.removeItem(item);
  }
}

function removeSession() {
  localStorage.clear();
}


var user;
function isGranted(grant, company) {
  if (user === undefined) {
    user = JSON.parse(getSessionItem('user'));
  }
  // console.log("user.grants[getSessionItem('Company')]  = " + JSON.stringify(user.grants[getSessionItem('Company')]));
  // console.log("user.grants[getSessionItem('Company')][grant] = " + user.grants[getSessionItem('Company')][grant]);
  if (company === undefined) {
    company = getSessionItem('Company')
  }
  var isG = user.grants[company][grant] !== undefined;
  //console.log(grant + " isG = " + isG);
  return isG;
}

function setMenus(menusData) {
  console.log("calimaco: Menus ok : total loaded: " + menusData.length);
  let menus = {}; // Guardanos los menus, por ejemplo MAIN y PROFILE
  menusData.forEach(item => {
    if (menus[item.menu] === undefined) {
      menus[item.menu] = {};
    }

    var path = item.path.split("/");
    var aux = menus[item.menu];

    // Recorremos el camino 
    console.log("Path = " + path);
    for (var i = 1; i < path.length; i++) {
      if (aux[path[i]] === undefined) {
        aux[path[i]] = { item: {}, childs: {} };
      }
      if (i === path.length - 1) {
        aux[path[i]].item = item;
      }
      aux = aux[path[i]].childs;
    }
  });

  console.log("Menus = " + JSON.stringify(menus));
  setSessionItem("menus", JSON.stringify(menus));
  window.menus = menus;
}

function setSections(sectionsData) {

  let sectionsAux = {};
  sectionsData.forEach(section => {
    console.log("Section = " + JSON.stringify(section));
    if (section.config !== undefined) {
      section.config = JSON.parse(section.config);
    }
    sectionsAux[section.section] = section;
  });
  setSessionItem("sections", JSON.stringify(sectionsAux));
  window.sections = sectionsAux;
}

function generateUID() {
  let a = Date.now().toString(30)
  let b = Math.random().toString(30).substring(2)
  return a + b
}
function getTZDateToday() {
  return momentTimeZone(new Date().getTime()).tz(getCurrentCompany().config.timezone).format("YYYY-MM-DD HH:mm:ss")
}
function getDateServerToTZ(serverDateStr) {
  return formatDate(serverDateStr)
}
function getDateToServer(TZDateStr) {
  return unformatDate(TZDateStr)
}
function addDaysDate(dateStr, days) {
  return momentTimeZone(dateStr).add(parseInt(days), 'days').format("YYYY-MM-DD HH:mm:ss")
}
function subtractDaysDate(dateStr, days) {
  return momentTimeZone(dateStr).subtract(parseInt(days), 'days').format("YYYY-MM-DD HH:mm:ss")
}
function getDateServerToTZObj(serverDateStr) {
  return momentTimeZone(getDateServerToTZ(serverDateStr)).tz(getCurrentCompany().config.timezone).toDate()
}
function getTZDateTodayObj() {

  return momentTimeZone(getTZDateToday()).tz(getCurrentCompany().config.timezone).toDate()
}

exports.removeSession = removeSession;
exports.isGranted = isGranted;
exports.setNumberFormat = setNumberFormat;
exports.setSessionItem = setSessionItem;
exports.getSessionItem = getSessionItem;
exports.removeSessionItem = removeSessionItem;

exports.formatNumber = formatNumber;
exports.parseNumber = parseNumber;
exports.formatCurrency = formatCurrency;
exports.formatDate = formatDate;
exports.formatDateUTC = formatDateUTC;
exports.formatDate2 = formatDate2;
exports.formatTime = formatTime;
exports.getCompany = getCompany;
exports.getCurrentCompany = getCurrentCompany;
exports.toMenuArray = toMenuArray;
exports.unformatDate = unformatDate;
exports.setMenus = setMenus;
exports.setSections = setSections
exports.generateUID = generateUID
exports.getDateTZToday = getTZDateToday
exports.getDateToServer = getDateToServer
exports.getDateServerToTZ = getDateServerToTZ
exports.addDaysDate = addDaysDate
exports.subtractDaysDate = subtractDaysDate
exports.getDateServerToTZObj = getDateServerToTZObj
exports.getDateTZTodayObj = getTZDateTodayObj


import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import BasicSelect from "../../components/basic/BasicSelect";
import useFormValidation from '../../hooks/parsleyValidation';
import { CKEditor } from "ckeditor4-react";
import Editor from "../../components/basic/Editor";



function EmailContentDetails(props) {

    const { t } = props;

    const company = utils.getCurrentCompany().company;

    // const [ckEditor, setCkEditor] = useState(undefined);

    const [emailDetail, setEmailDetail] = useState({ company: company, content: "", event: "", from: "", to: "", subject: "", website: "" });

    const [newOrUpdate, setNewOrUpdate] = useState("");

    const session = JSON.parse(utils.getSessionItem("user")).session;

    let adminClient = new AdminClient();

    const { formValidation } = useFormValidation("form-emailContentDetails")

    useEffect(function () {
        let isNew = utils.getSessionItem("current-website,event");
        if (isNew === '-1') {
            setNewOrUpdate("new");
        }
        else {
            let currentRow = JSON.parse(utils.getSessionItem("current_row_email_content"));
            let website = currentRow.website;
            let event = currentRow.event;
            adminClient.getEmailContent(session, company, website, event).then((res) => {
                let info = res.data[0];
                Object.entries(info).map(obj => {
                    const key = obj[0];
                    const value = obj[1];
                    if (value === null) info[key] = "";
                });
                setEmailDetail(info);
                setNewOrUpdate("update");

            }, (err) => {
                console.log(err);
            })
        }

    }, []);

    // useEffect(() => {
    //     if (ckEditor !== undefined) ckEditor.setData(emailDetail.content)

    // }, [newOrUpdate])


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        let newInfo = { ...emailDetail };
        newInfo[name] = value;
        setEmailDetail(newInfo);
    };

    const handleDelete = () => {
        let promise = adminClient.deleteEmailContent(
            session,
            company,
            emailDetail.website,
            emailDetail.event
        );

        promise.then(
            function (msg) {
                console.log("Update result " + JSON.stringify(msg));
                if (msg.result === 'OK') {
                    props.showAlert("Ok", "Email borrado correctamente");
                    goBack();

                } else {
                    props.showAlert("Error", "Error borrando Email")
                }
            }, function (err) {
                console.log("Save Email error " + err.event);
                props.showAlert("Error", "Error borrando Email ")
            }
        );



    };

    const handleSubmit = () => {
        if (formValidation.validate()) {
            let promise = adminClient.saveEmailContent(
                session,
                company,
                JSON.stringify(emailDetail)
            );

            promise.then(
                function (msg) {
                    console.log("Update result " + JSON.stringify(msg));
                    if (msg.result === 'OK') {
                        props.showAlert("Ok", "Email guardado correctamente");
                    } else {
                        props.showAlert("Error", "Error guardando Email")
                    }

                }, function (err) {
                    console.log("Error Email " + err.code);
                    props.showAlert("Error", "Error guardando Email " + t(err.description))
                }
            );
        }

    };

    const handleEditorChange = (event) => {
        // const data = event.editor.getData();
        const data = event;

        setEmailDetail((prevCategory) => {
            let aux = prevCategory;
            aux.content = data;
            return { ...prevCategory, aux }
        });
    };


    const goBack = () => {
        props.history.push("/reports/report/email_content/table");
    };


    const dataHeader = {
        backLink: goBack,
        title: t("Email content config"),
        urlHelp: "",
        idDoc: props.idDoc
    };



    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <div className="row" >
                <div className="card-body m-0">
                    <form id="form-emailContentDetails">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <TextField required
                                    disabled={(newOrUpdate === "new") ? false : true} name="website" id="website" label={t("Website")} type="text" value={emailDetail.website} onChange={onChangeHandler}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    label={t("Event")}
                                    nameProperty="event"
                                    idProperty="event"
                                    name="event"
                                    basicInfoName="EventTypes"
                                    onChange={onChangeHandler}
                                    filters={[{ property: "queue", value: "email" }]}
                                    translate={{ property: "event", prefix: "" }}
                                    value={emailDetail.event}
                                    disabled={(newOrUpdate === "new") ? false : true}
                                    noEmptyValue
                                />

                            </div>
                            <div className="col-12 col-md-6">
                                <TextField required
                                    name="from" id="from" label={t("From")} type="text" value={emailDetail.from} onChange={onChangeHandler}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField
                                    name="to" id="to" label={t("Para")} type="text" value={emailDetail.to} onChange={onChangeHandler}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TextField required
                                    name="subject" id="subject" label={t("Subject")} type="text" value={emailDetail.subject} onChange={onChangeHandler}
                                />
                            </div>
                            <div className="col-12 ">
                                <ul className="nav nav-tabs" id="cmsBodyTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="cms-body-tab" data-bs-toggle="tab" data-bs-target="#cms-body" type="button" role="tab" aria-controls="cms-body" aria-selected="false">{t("Content")}</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="cmsBodyContent">
                                    <Editor
                                        onChangeHandler={handleEditorChange}
                                        info={emailDetail.content}
                                        oneTime={true}
                                    />
                                    {/* <CKEditor
                                        config={{ height: 200, allowedContent: true, versionCheck: false }}
                                        initData=""
                                        onChange={handleEditorChange}
                                        onInstanceReady={(evt) => {
                                            setCkEditor(evt.editor);
                                        }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                {t("Save")}
                            </button>
                            <button
                                hidden={newOrUpdate === "new" ? true : false}
                                className="btn btn-secondary"
                                onClick={handleDelete}
                            >
                                {t("Delete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutSection >
    );
}

export default withTranslation()(EmailContentDetails);

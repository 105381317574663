import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';

class VerticalMenu extends React.Component {

  hideMenu() {
      sessionStorage.setItem("menu-visible",false);
      document.getElementById("menuContainer").classList.add("d-none");
  }

  render() {
    if (this.props.items === undefined || this.props.items.length === 0) {
      return <></>
    } else {

      return (
        <div className="d-flex flex-column align-items-center align-items-md-start px-3 pt-2 text-white min-vh-100">
                <Link to="/home" className="link back">
                <picture>
                  <img id="logo-header" src="/img/logo-header.png" alt="logo" className="d-none d-sm-block"></img>
                  <img id="logo-small-header" src="/img/logo-small-header.png" alt="logo" className="d-block d-sm-none logo-small"></img>
                  </picture>
                  </Link>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start  w-100" id="menu">

                {this.props.items.map((el, indexP) => {
         //         console.log("el = "+JSON.stringify(el));
                  return (
                    <>
                      {el.childs.length > 0 &&
                        <li className="dropdown" id={el.item.item + "-" + indexP}  key={el.item.item + "-" + indexP}>
                          <a href={"#ul-"+el.item.item + "-" + indexP}  key={el.item.item}  className="nav-link dropdown-toggle px-0 align-middle"  id={"navbarDropdown-" + el.item.item} data-bs-toggle="collapse">
                            {el.item.icon !== null && <FontAwesomeIcon icon={el.item.icon} />}  <span  >{el.item.label}</span>
                          </a>
                          <ul key={"ul-"+el.item.item + "-" + indexP} id={"ul-"+el.item.item + "-" + indexP}  className="collapse nav flex-column ms-1 submenu unstyled" data-bs-parent="#menu" >
                            {el.childs.map((el2, index) => {
                               return (
                                <>
                                  {el2.childs.length > 0 &&
                                    <li className="dropdown" id={el2.item.item + "-" + index}  key={el2.item.item + "-" + index}>
                                      <a href={"#ul-"+el2.item.item + "-" + index}  key={el2.item.item}  className="nav-link dropdown-toggle px-0 align-middle"  id={"navbarDropdown-" + el2.item.item} data-bs-toggle="collapse">
                                        {el2.item.icon !== null && <FontAwesomeIcon icon={el2.item.icon} />}  <span  >{el2.item.label}</span>
                                      </a>
                                      <ul key={"ul-"+el2.item.item + "-" + index} id={"ul-"+el2.item.item + "-" + index}  className="collapse nav flex-column ms-1 submenu unstyled" data-bs-parent={"#ul-"+el.item.item + "-" + indexP} >
                                        {el2.childs.map((el3, index) => {
                                          return (
                                            <li key={el3.item.item} id={el3.item.item}  >
                                              <NavLink  onClick={this.hideMenu} key={"a-"+el3.item.item} id={"a-"+el3.item.item}   to={el3.item.url} className="nav-link px-0" >{el3.item.icon !== null && <FontAwesomeIcon icon={el3.item.icon} />} <span>{el3.item.label}</span></NavLink>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  }
                                  {el2.childs.length === 0 &&
                                    <li  key={el2.item.item + "-" + index}  id={el2.item.item + "-" + index}>
                                      <Link   key={el2.item.item}  onClick={this.hideMenu} className="nav-link px-0 align-middle" aria-current="page" to={el2.item.url}   >
                                        {el2.item.icon !== null && <FontAwesomeIcon icon={el2.item.icon} />} {el2.item.label}
                                      </Link>
                                    </li>
            
            
                                  }
            
                                </>
            
                              );
                            })}
                          </ul>
                        </li>
                      }
                      {el.childs.length === 0 &&
                        <li  key={el.item.item + "-" + indexP}  id={el.item.item + "-" + indexP}>
                          <Link   key={el.item.item} onClick={this.hideMenu} className="nav-link px-0 align-middle" aria-current="page" to={el.item.url}   >
                            {el.item.icon !== null && <FontAwesomeIcon icon={el.item.icon} />} {el.item.label}
                          </Link>
                        </li>


                      }

                    </>

                  )
                })
                }
              </ul>
            </div>
      
       
      )
    }
  }
}
export default VerticalMenu


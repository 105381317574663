import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import usePagination from "../../hooks/Pagination";
import Pagination from "./Pagination";

function AllowedForbiddenList(props) {

  const [availables, setAvailables] = useState(props.availables)
  const [allowed, setAllowed] = useState(props.allowed)
  const [forbidden, setForbidden] = useState(props.forbidden)
  const [searchQueryAvailables, setSearchQueryAvailables] = useState('')
  const [searchQueryAllowed, setSearchQueryAllowed] = useState('')
  const [searchQueryForbidden, setSearchQueryForbidden] = useState('')

  const defaultPageSize = props.pageSize || 5
  const {
    pageIndex: pageIndexAv,
    nextPage: nextPageAv,
    previousPage: previousPageAv,
    gotoPage: gotoPageAv,
    setPageIndex: setPageIndexAv,
    pageCount: pageCountAv,
    setData: setDataAv,
    begin: beginAv,
    canNextPage: canNextPageAv,
    canPreviousPage: canPreviousPageAv
  } = usePagination(availables, defaultPageSize)

  const {
    pageIndex: pageIndexAl,
    nextPage: nextPageAl,
    previousPage: previousPageAl,
    gotoPage: gotoPageAl,
    setPageIndex: setPageIndexAl,
    pageCount: pageCountAl,
    setData: setDataAl,
    begin: beginAl,
    canNextPage: canNextPageAl,
    canPreviousPage: canPreviousPageAl
  } = usePagination(allowed, defaultPageSize)

  const {
    pageIndex: pageIndexAf,
    nextPage: nextPageAf,
    previousPage: previousPageAf,
    gotoPage: gotoPageAf,
    setPageIndex: setPageIndexAf,
    pageCount: pageCountAf,
    setData: setDataAf,
    begin: beginAf,
    canNextPage: canNextPageAf,
    canPreviousPage: canPreviousPageAf
  } = usePagination(forbidden, defaultPageSize)

  const { t } = props;

  const searchAvailableList = (data) => {
    setDataAv(availables.filter((item) => {
      return item[props.nameProperty]
        .toLowerCase()
        .includes(data.toLowerCase());
    }))
    setSearchQueryAvailables(data)
    setPageIndexAv(0)

  };
  const searchAllowedList = (data) => {
    setDataAl(allowed.filter((item) => {
      return item[props.nameProperty]
        .toLowerCase()
        .includes(data.toLowerCase());
    }))
    setSearchQueryAllowed(data)
    setPageIndexAl(0)
  };
  const searchForbiddenList = (data) => {
    setDataAf(forbidden.filter((item) => {
      return item[props.nameProperty]
        .toLowerCase()
        .includes(data.toLowerCase());
    }))
    setSearchQueryForbidden(data)
    setPageIndexAf(0)
  };


  const sortElements = (a, b) => {
    return a[props.nameProperty] > b[props.nameProperty] ? 1 : b[props.nameProperty] > a[props.nameProperty] ? -1 : 0;
  };

  const moveElement = (id, from, to) => {
    let itemAux;
    let arFrom = [...from];
    let arTo = [...to];
    for (var i = 0; i < arFrom.length; i++) {
      var item = from[i];
      if (Number(item[props.idProperty]) === Number(id)) {
        itemAux = item;
        arTo.push(itemAux);
        arFrom.splice(i, 1);
        break;
      }
    }
    return { from: arFrom, to: arTo.sort(sortElements) };
  };

  useEffect(() => {
    let av = props.availables.sort(sortElements);
    let al = props.allowed.sort(sortElements)
    let af = props.forbidden.sort(sortElements)
    setAvailables(av)
    setAllowed(al)
    setForbidden(af)
    setDataAv(av)
    setDataAl(al)
    setDataAf(af)

  }, [props.availables]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <label htmlFor="allowedList">
            <b>{t("Alloweds")}</b>
          </label>
          <TextField
            id="searchListAllowed"
            key="searchListAllowed"
            label={t("Search")}
            defaultValue=""
            onChange={(evt) => searchAllowedList(evt.target.value)}
          />
          <ul className="machines_list">
            {allowed.filter((item) => {
              return item[props.nameProperty]
                .toLowerCase()
                .includes(searchQueryAllowed.toLowerCase());
            })
              .slice(beginAl(), beginAl() + defaultPageSize)
              .map((item) => (
                <li
                  className="group_item d-table  w-100"
                  key={item[props.idProperty]}
                  id={item[props.idProperty]}
                  onClick={() => {
                    let result = moveElement(item[props.idProperty], allowed, availables);
                    setAvailables(result.to)
                    setAllowed(result.from)

                    if (searchQueryAvailables !== '') {
                      setDataAv(result.to.filter((item) => {
                        return item[props.nameProperty]
                          .toLowerCase()
                          .includes(searchQueryAvailables.toLowerCase());
                      }))
                    } else setDataAv(result.to)

                    if (searchQueryAllowed !== '') {
                      setDataAl(result.from.filter((item) => {
                        return item[props.nameProperty]
                          .toLowerCase()
                          .includes(searchQueryAllowed.toLowerCase());
                      }))
                    } else setDataAl(result.from)

                    props.handleUpdateGroups(result.from, forbidden, result.to)
                  }}
                >
                  {item[props.nameProperty]}
                </li>
              ))}
          </ul>
          <Pagination
            gotoPage={gotoPageAl}
            previousPage={previousPageAl}
            nextPage={nextPageAl}
            canPreviousPage={canPreviousPageAl}
            canNextPage={canNextPageAl}
            pageCount={pageCountAl}
            pageIndex={pageIndexAl}
            showInfoPage={true}
            showGoToPage={false}
          ></Pagination>

        </div>

        <div className="col-12 col-md-4">
          <label htmlFor="availableList">
            <b>{t("Availables")}</b>
          </label>
          <TextField
            id="searchListAvailable"
            key="searchListAvailable"
            label={t("Search")}
            defaultValue=""
            onChange={(evt) => searchAvailableList(evt.target.value)}
          />
          <ul className="machines_list">
            {availables.filter((item) => {
              return item[props.nameProperty]
                .toLowerCase()
                .includes(searchQueryAvailables.toLowerCase());
            })
              .slice(beginAv(), beginAv() + defaultPageSize)
              .map((item) => (
                <li className="group_item d-table  w-100" key={item[props.idProperty]}>
                  <a
                    onClick={() => {
                      let result = moveElement(item[props.idProperty], availables, allowed);
                      setAvailables(result.from)
                      setAllowed(result.to)

                      if (searchQueryAvailables !== '') {
                        setDataAv(result.from.filter((item) => {
                          return item[props.nameProperty]
                            .toLowerCase()
                            .includes(searchQueryAvailables.toLowerCase());
                        }))
                      } else setDataAv(result.from)

                      if (searchQueryAllowed !== '') {
                        setDataAl(result.to.filter((item) => {
                          return item[props.nameProperty]
                            .toLowerCase()
                            .includes(searchQueryAllowed.toLowerCase());
                        }))
                      } else setDataAl(result.to)

                      props.handleUpdateGroups(result.to, forbidden, result.from)
                    }}
                  >
                    <FontAwesomeIcon
                      className="float-start"
                      icon="thumbs-up"
                    ></FontAwesomeIcon>
                  </a>{" "}
                  {item[props.nameProperty]}{" "}
                  <a
                    onClick={() => {
                      let result = moveElement(
                        item[props.idProperty],
                        availables,
                        forbidden
                      );
                      setAvailables(result.from);
                      setForbidden(result.to);

                      if (searchQueryAvailables !== '') {
                        setDataAv(result.from.filter((item) => {
                          return item[props.nameProperty]
                            .toLowerCase()
                            .includes(searchQueryAvailables.toLowerCase());
                        }))
                      } else setDataAv(result.from)

                      if (searchQueryForbidden !== '') {
                        setDataAf(result.to.filter((item) => {
                          return item[props.nameProperty]
                            .toLowerCase()
                            .includes(searchQueryForbidden.toLowerCase());
                        }))
                      } else setDataAf(result.to)

                      props.handleUpdateGroups(allowed, result.to, result.from)
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      className="float-end"
                      icon="ban"
                    ></FontAwesomeIcon>
                  </a>{" "}
                </li>
              ))}
          </ul>
          <Pagination
            gotoPage={gotoPageAv}
            previousPage={previousPageAv}
            nextPage={nextPageAv}
            canPreviousPage={canPreviousPageAv}
            canNextPage={canNextPageAv}
            pageCount={pageCountAv}
            pageIndex={pageIndexAv}
            showInfoPage={true}
            showGoToPage={false}
          ></Pagination>
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="forbiddenList">
            <b>{t("Forbiddens")}</b>
          </label>
          <TextField
            id="searchListForbidden"
            key="searchListForbidden"
            label={t("Search")}
            defaultValue=""
            onChange={(evt) => searchForbiddenList(evt.target.value)}
          />
          <ul className="machines_list">
            {forbidden.filter((item) => {
              return item[props.nameProperty]
                .toLowerCase()
                .includes(searchQueryForbidden.toLowerCase());
            })
              .slice(beginAf(), beginAf() + defaultPageSize)
              .map((item) => (
                <li
                  className="group_item d-table  w-100"
                  key={item[props.idProperty]}
                  onClick={() => {
                    let result = moveElement(item[props.idProperty], forbidden, availables);
                    setAvailables(result.to)
                    setForbidden(result.from)

                    if (searchQueryAvailables !== '') {
                      setDataAv(result.to.filter((item) => {
                        return item[props.nameProperty]
                          .toLowerCase()
                          .includes(searchQueryAvailables.toLowerCase());
                      }))
                    } else setDataAv(result.to)

                    if (searchQueryForbidden !== '') {
                      setDataAf(result.from.filter((item) => {
                        return item[props.nameProperty]
                          .toLowerCase()
                          .includes(searchQueryForbidden.toLowerCase());
                      }))
                    } else setDataAf(result.from)

                    props.handleUpdateGroups(allowed, result.from, result.to)
                  }}
                >
                  {item[props.nameProperty]}
                </li>
              ))}
          </ul>
          <Pagination
            gotoPage={gotoPageAf}
            previousPage={previousPageAf}
            nextPage={nextPageAf}
            canPreviousPage={canPreviousPageAf}
            canNextPage={canNextPageAf}
            pageCount={pageCountAf}
            pageIndex={pageIndexAf}
            showInfoPage={true}
            showGoToPage={false}
          ></Pagination>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(AllowedForbiddenList);

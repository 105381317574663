import React, { useEffect, useState, useRef } from 'react';
import utils from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { Container, TextField, Button, Box, Typography, Paper, Chip } from '@mui/material';
import { withTranslation } from "react-i18next";
import AdminClient from '../../AdminClient';
import LayoutSection from '../NavigationAndLayouts/LayoutSection';

const DocChat = (props) => {
  const { t } = props;
  const prefix = props.context + ' ';
  const [inputValue, setInputValue] = useState(prefix || '');
  const [messages, setMessages] = useState([{ sender: "DocuCalia", text: "Hola, soy DocuCalia. ¿En qué puedo ayudarte?" }]);
  const user = JSON.parse(utils.getSessionItem("user")) || { alias: "User", session: "" };
  const session = user.session;
  const company = utils.getSessionItem("Company") || "";
  const [chatId, setChatId] = useState('');
  const adminClient = new AdminClient();
  const document_key = props.document_key;
  const [isStreaming, setIsStreaming] = useState(false);
  const [currentBotMessage, setCurrentBotMessage] = useState('');
  const initialRecommendations = document_key === "HCD" ? ["¿Cómo configuro una promoción?", "¿Cómo funcionan las plantillas de email?", "¿Que son los balances de usuario?"] : props.recommendations;
  const jsonString = typeof initialRecommendations === 'string' ? JSON.parse(initialRecommendations.replace(/'/g, '"')) : initialRecommendations;
  const [recommendations, setRecommendations] = useState(jsonString);


  // Reference for the container that holds the messages
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentBotMessage, isStreaming]); // Scroll to bottom whenever messages or streaming state changes



  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleResponse = (event, socket) => {
    const botResponse = JSON.parse(event).data;
    console.log('Stream message:', botResponse);

    if (botResponse === "[END]") {
      console.log('Stream message:', currentBotMessage);
      console.log('Stream message:', JSON.parse(event));
      if (JSON.parse(event).recommendations !== undefined) {
        let recommendations = JSON.parse(event).recommendations;
        recommendations = recommendations.replace(/`/g, '"');
        recommendations = JSON.parse(recommendations);

        setRecommendations(JSON.parse(JSON.parse(event).recommendations));
      }
      setCurrentBotMessage((prev) => {
        setMessages((prevMessages) => [...prevMessages, { sender: 'DocuCalia', text: prev }]);
        return "";
      });
      setIsStreaming(false);
      setCurrentBotMessage(''); // Reset the current bot message
      console.log('Stream closed');

      const imagenes = document.querySelectorAll("img[src*='image']");
      if (imagenes.length > 0) {
        Array.from(imagenes).forEach(async (imagen) => {
          if (!imagen.classList.contains("confluence-embedded-image")) {
            if (imagen.src.indexOf('data:image/png;base64') === -1) {
              console.log(imagen);
              let binario = await getImage(imagen.src);
              const base64String = arrayBufferToBase64(binario);
              imagen.src = 'data:image/png;base64,' + base64String;
              imagen.style = 'max-width: 100%; margin-top: 10px; cursor: pointer;';
              imagen.onclick = function () {
                const newTab = window.open();
                newTab.document.body.innerHTML = `<img class="confluence-embedded-image image-center" src="data:image/png;base64,` + base64String + `" style="max-width: 100%; margin-top: 10px;">`;
              };
            }
          }
        });
      }
    } else {
      setCurrentBotMessage((prev) => prev + botResponse);
    }
  };

  const handleStreamError = (event) => {
    console.error('Stream error:', event);
    setIsStreaming(false);
    props.showAlert(t('Error'), t("An error occurred while streaming the response."), [<button type='button' className='btn btn-primary'
      onClick={(evt) => {
        props.closeAlert()
      }}>
      {t('Ok')}</button>])
  };

  const dataHeader = {
    title: prefix === ' ' ? t('General Chat') : t('Chat'),
  };

  async function getImage(url) {
    try {
      let documentationResponse = await adminClient.getDocumentation(session, company, url);
      return documentationResponse.data;
    } catch (error) {
      console.error("Error in getPageHTML " + error);
    }
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    if (buffer !== undefined && buffer.data !== undefined) {
      const bytes = new Uint8Array(buffer.data);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
    }
    return btoa(binary);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') handleSendClick();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputValue, isStreaming]);

  useEffect(() => {
    if (chatId === '') {
      const newChatId = uuidv4();
      setChatId(newChatId);
    }
  }, []);


  const handleSendClick = (message = inputValue) => {
    if (message.trim() === '') {
      props.showAlert(t('Error'), t("Escribe un mensaje antes de enviar"), [
        <button type='button' className='btn btn-primary' onClick={(evt) => { props.closeAlert(); }}>
          {t('Ok')}
        </button>,
      ]);
      return;
    }
    const newMessage = { sender: user.alias, text: message };
    setMessages([...messages, newMessage]);
    setInputValue(prefix);

    if (message.trim() !== '') {
      scrollToBottom();

      const eventSource = adminClient.batchUpdateChat(
        session,
        company,
        message,
        'calia',
        chatId,
        'HCD',
        handleResponse,
        handleStreamError
      );
      setIsStreaming(true);

      eventSource.onerror = (error) => {
        setIsStreaming(false);
        console.error('EventSource failed:', error);
        eventSource.close();
      };
    }
  };

  const handleResetClick = () => {
    setMessages([]);
  };

  return (
    <LayoutSection {...props} dataHeader={{ title: t('Calia Chat') + '' }}>
      <Container
        style={{
          maxHeight: '800px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            flex: 1,
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              style={{
                display: 'flex',
                justifyContent: message.sender === 'DocuCalia' ? 'flex-start' : 'flex-end',
                marginBottom: '10px',
                textAlign: message.sender === 'DocuCalia' ? 'left' : 'right',
              }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: '10px',
                  margin: '10px',
                  backgroundColor: message.sender === 'DocuCalia' ? '#EDEDED' : '#DCF8C6',
                  borderRadius: '15px',
                  maxWidth: '60%',
                  border: '1px solid #ccc',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    color: message.sender === 'DocuCalia' ? 'inherit' : '#00796B',
                  }}
                >
                  {message.sender}
                </span>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message.text
                        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                        .replace(/\`(.*?)\`/g, '<code>$1</code>')
                        .replace(/\n/g, ''),
                    }}
                  />
                </Typography>
              </Paper>
            </Box>
          ))}
          {isStreaming && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '10px',
                textAlign: 'left',
              }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: '10px',
                  margin: '10px',
                  backgroundColor: '#EDEDED',
                  borderRadius: '15px',
                  maxWidth: '60%',
                  border: '1px solid #ccc',
                }}
              >
                <span style={{ fontWeight: 'bold', color: 'inherit' }}>DocuCalia</span>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentBotMessage
                        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                        .replace(/\`(.*?)\`/g, '<code>$1</code>')
                        .replace(/\n/g, ''),
                    }}
                  />
                </Typography>
              </Paper>
            </Box>
          )}
          {/* This div will be used as a reference to scroll to the bottom */}
          <div ref={messagesEndRef} />
        </Box>
        <Box style={{ padding: '10px', borderTop: '1px solid #ccc' }}>
          {!isStreaming && (
            <Box style={{ marginBottom: '16px' }}>
              <Box style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                {recommendations.map((suggestion, index) => (
                  <Chip
                    key={index}
                    label={suggestion}
                    onClick={() => handleSendClick(prefix + suggestion)}
                    color="primary"
                    variant="outlined"
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </Box>
          )}
          <TextField
            label={t("Type a message")}
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '16px',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button variant="contained" disabled={isStreaming} onClick={() => handleSendClick()}>
                {t('Send')}
              </Button>
              <Button onClick={handleResetClick}>
                ↻
              </Button>
            </div>
            <div style={{ marginLeft: '16px', opacity: '50%' }}>{chatId}</div>
          </Box>
        </Box>
      </Container>
    </LayoutSection>
  );
}

export default withTranslation()(DocChat);

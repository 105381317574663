import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import React from 'react'
import utils from "../../utils";
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import DocButton from '../basic/DocButton';

function LayoutSection(props) {
  //Datos que nos llegaran en las props
  //dataHeader={backLink:""/function,title:"",urlHelp:"",buttons:{}}
  const { t } = props
  const idDoc = props.reportDoc ? "report/" + props.reportId : props.idDoc

  const goBack = () => {
    if (props.dataHeader.backLink === "goBackHistory")
      props.history.goBack()
    else props.history.push(props.dataHeader.backLink)
  }

  return (<>
    <div className={props.className || "col-12   float-left pt-3 m-0"}>
      <div className="card">
        {props.dataHeader && <>
          <div className="card-header">
            {props.dataHeader.backLink &&
              <a href="#" className="float-start"
                onClick={(props.dataHeader.backLink instanceof Function) ? props.dataHeader.backLink : goBack}>
                <span><FontAwesomeIcon icon="arrow-left" /></span>
              </a>}

            {props.dataHeader.title}

            {/* {props.dataHeader.urlHelp && <a className="help_link" onClick={e => { window.dispatchEvent(new CustomEvent("showHelp", { detail: { url: props.dataHeader.urlHelp } })) }}><span><FontAwesomeIcon icon="info-circle" /></span></a>} */}

            {(idDoc) && <DocButton {...props} idDoc={idDoc} />}

            {props.dataHeader.buttons && <>
              {props.dataHeader.buttons.map((button, index) => {
                if (button.handle) {
                  return <><IconButton disabled={button.disabled} color="default" key={`Header_button_${index}`} className="float-end" aria-label={button.name} onClick={button.handle}>
                    {button.icon} <span className="headerButton">{button.label}</span>
                  </IconButton>
                  </>
                } else {
                  return <><IconButton color="default" key={`Header_button_${index}`} disabled={button.disabled} className="float-end" aria-label={button.name} href={button.url}>
                    {button.icon} <span className="headerButton">{button.label}</span>
                  </IconButton>
                  </>
                }
              })}
            </>}
          </div>
        </>
        }
        <div className="card-body m-0 ">
          {props.children}
        </div>
      </div>
    </div>
  </>
  )

}
export default withTranslation()(withRouter(LayoutSection))
import { TextField } from "@mui/material";
import JSONInput from "react-json-editor-ajrm";

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";

function MonitorAlertDetails(props) {

  const { t } = props;

  const [alertDetail, setAlertDetail] = useState({ alert: "", alert_time: "", data: [], comments: "" });

  const session = JSON.parse(utils.getSessionItem("user")).session;

  const company = utils.getCurrentCompany().company;

  let adminClient = new AdminClient();

  useEffect(function () {
    let newInfo = { ...alertDetail };

    const parseInfo = (info) => {
      let lengAlert = info.length;
      let alert = info[0];
      for (let i = 1; i < lengAlert - 1; i++) {
        alert = alert + "_" + info[i];
      }
      return [alert, info[lengAlert - 1]];
    }

    let alertInfo = utils.getSessionItem("current-alertKey,alert_time").split("_");
    let info = parseInfo(alertInfo);

    newInfo.alert = info[0];
    newInfo.alert_time = info[1];

    adminClient.getMessageAlert(session, company, info[0], info[1]).then((res) => {
      let alertInfoData = res;
      newInfo.data = alertInfoData.data.data;
      newInfo.comments = alertInfoData.data.comments;
      if (newInfo.comments === undefined) {
        newInfo.comments = "";
      }
      setAlertDetail(newInfo);
    });
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newInfo = { ...alertDetail };
    newInfo[name] = value;

    setAlertDetail(newInfo);
  };

  const handleSubmit = () => {
    let promise = adminClient.saveMessageAlert(
      session,
      company,
      alertDetail.alert,
      alertDetail.alert_time,
      alertDetail.comments
    );

    promise.then(
      function (msg) {
        console.log("Update result " + JSON.stringify(msg));
        if (msg.result === 'OK') {
          props.showAlert("Ok", t("Alert saved"))
        } else {
          props.showAlert("Error", "Error saved alert")
        }
      }, function (err) {
        console.log("Delete I18N error " + err.description.code);
        props.showAlert("Error", "Error saved alert " + t(err.description))
      }
    );

  };

  const goBack = () => {
    props.history.push("/reports/report/monitor_alerts/table");
  };


  const dataHeader = {
    backLink: goBack,
    title: t("Alert config"),
    urlHelp: "",
    idDoc: props.idDoc
  };


  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <div className="row">
        <div className="card-body m-0">
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField
                disabled name="alert" id="alert" label={t("Identifier")} type="text" value={alertDetail.alert} onChange={onChangeHandler}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                disabled name="alert_time" id="alert_time" label={t("Alert Time")} type="text" value={alertDetail.alert_time} onChange={onChangeHandler}
              />
            </div>
            <div className="col-12 ">
              <TextField multiline
                disabled name="data" id="data" label={t("Data")} type="text" value={alertDetail.data} onChange={onChangeHandler}
              />
            </div>
            <div className="col-12 ">
              <TextField multiline
                name="comments" id="comments" label={t("Comments")} type="text" value={alertDetail.comments} onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  );
}

export default withTranslation()(MonitorAlertDetails);

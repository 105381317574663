import React from "react";
import { withTranslation } from "react-i18next";
import ReactModal from "react-modal";



class Alert extends React.Component {
    render() {
        const { t } = this.props;
        const customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)'
              },
            content: {
              top: '20%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-20%, -50%)',
            },
          };

        return (

            <ReactModal
                
                isOpen={this.props.isOpen}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="card">
                    <div className="card-header">
                        
                        <h4>{this.props.title}</h4>
                    </div>
                    <div className="card-body m-0">
                        {this.props.content}
                        <div className="row text-center">
                        <button className="btn btn-primary center" onClick={this.props.close}>{t("Accept")}</button>
                        </div>
                    </div>

                </div>

            </ReactModal>


        );
    }
}
export default withTranslation()(Alert);
import { Position, MarkerType, internalsSymbol } from 'reactflow';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
    // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
    const {
        width: intersectionNodeWidth,
        height: intersectionNodeHeight,
        positionAbsolute: intersectionNodePosition,
    } = intersectionNode;
    const targetPosition = targetNode.positionAbsolute;

    const w = intersectionNodeWidth / 2;
    const h = intersectionNodeHeight / 2;

    const x2 = intersectionNodePosition.x + w;
    const y2 = intersectionNodePosition.y + h;
    const x1 = targetPosition.x + w;
    const y1 = targetPosition.y + h;

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;

    return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
    const n = { ...node.positionAbsolute, ...node };
    const nx = Math.round(n.x);
    const ny = Math.round(n.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);

    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + n.width - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= n.y + n.height - 1) {
        return Position.Bottom;
    }

    return Position.Top;
}

export function getHandleCoordsByPosition(node, handlePosition) {
    // all handles are from type source, that's why we use handleBounds.source here
    let defaultHandle = node[internalsSymbol].handleBounds.target[0]
    let handle = node[internalsSymbol].handleBounds.target.find(
        (h) => h.position === handlePosition
    );
    if (handle === undefined) handle = defaultHandle

    let offsetX = handle.width / 2;
    let offsetY = handle.height / 2;

    // this is a tiny detail to make the markerEnd of an edge visible.
    // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
    // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
    switch (handlePosition) {
        case Position.Left:
            offsetX = 0;
            break;
        case Position.Right:
            offsetX = handle.width;
            break;
        case Position.Top:
            offsetY = 0;
            break;
        case Position.Bottom:
            offsetY = handle.height;
            break;
    }

    const x = node.positionAbsolute.x + handle.x + offsetX;
    const y = node.positionAbsolute.y + handle.y + offsetY;

    return [x, y];
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);

    const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
    const targetPos = getEdgePosition(target, targetIntersectionPoint);

    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos,
    };
}

export function readNodesAndEdges(statusList, onDeleteEdge) {
    let nodes = [];
    let edges = [];
    const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

    //recorremos la lista de estados
    for (let i = 0; i < statusList.length; i++) {
        const statusData = statusList[i];
        nodes.push({ id: statusData.status, data: statusData, type: 'statusNode', position: statusData.config.diagram.position })
        let nodeEdges = getEdgesFromNode(statusData, onDeleteEdge)
        edges = [...edges, ...nodeEdges]
    }
    return { nodes, edges };
}

export function writeFlowStatusList(flow, flowDiagram) {


    return flowDiagram.nodes.map((nd) => {
        let status = {}
        status['config'] = nd.data.config
        status.config.diagram.position = nd.position
        status.status = nd.data.status
        status.name = nd.data.name
        status.flow = flow.flow
        status.company = flow.company
        status.isNew = nd.data.isNew
        return status
    })


}

export function getEdgesFromNode(nodeData, onDeleteEdge) {
    let edges = []
    if (nodeData.config.reacts !== undefined) {
        let reacts = Object.keys(nodeData.config.reacts)
        if (reacts.length > 0) {
            for (let j = 0; j < reacts.length; j++) {
                let reactData = nodeData.config.reacts[reacts[j]]

                if (reactData.actions !== undefined) {
                    let transition = reactData.actions.find((action) => {
                        if (action.type === 'TRANSITION') return action
                    })
                    if (transition) {
                        edges.push({
                            id: `edge-${nodeData.status}-${transition.new_status}-handle_${j}`,
                            target: transition.new_status,
                            source: nodeData.status,
                            sourceHandle: `edge-${nodeData.status}-handle_${j}`,
                            data: {
                                label: reactData.name,
                                deleteEdge: onDeleteEdge
                            },
                            type: 'edgeNode',

                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 20,
                                height: 20,
                                color: nodeData.config.diagram.color,
                            },
                            style: {
                                strokeWidth: 1,
                                stroke: nodeData.config.diagram.color,
                            },
                        });
                    }
                }
            }
        }
    }
    return edges
}

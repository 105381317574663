import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import Tab from '../../components/basic/BasicTabs';
import File from '../../components/basic/File';
import AllowedForbiddenList from '../../components/basic/AllowedForbiddenList';

function BannersDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();

    const { t } = props;

    const { formValidation } = useFormValidation('Form_BannersDetails')

    const [addOrUpdate, setAddOrUpdate] = useState('add');
    let bannerID = utils.getSessionItem('current-banner');

    const [BannerDetails, setBannerDetails] = useState({
        banner: {
            banner: bannerID,
            company: 'ATP',
            config: { url: '', title: '', body: '', button_text: '', image: '' },
            description: null,
            end_date: utils.getDateTZTodayObj().getTime(),
            groups: [],
            init_date: utils.getDateTZTodayObj().getTime(),
            name: '',
            status: 'ENABLED'
        },
        groups: {
            groups_availables: [],
            groups_allowed: [],
            groups_forbbiden: []
        }

    });

    const [companyDetails, setCompanyDetails] = useState({
        config: {
            cdn_url: ""
        }
    });



    //HEADER
    const goBack = () => {
        props.history.push('/cms/banners');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Banners details'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER
    //Carga de datos
    useEffect(function () {
        JSON.parse(localStorage.getItem("Companies")).forEach(company => {
            if (company.company === localStorage.getItem("Company")) {
                setCompanyDetails(company);
            }
        });
        if (bannerID !== undefined && bannerID !== '-1') {
            adminClient.getBannerDetails(session, company, bannerID).then(
                async function (result) {
                    let bannerAux = {}
                    bannerAux.banner = result.data.banner;
                    bannerAux.banner.init_date = utils.formatDate(result.data.banner.init_date);
                    bannerAux.banner.end_date = utils.formatDate(result.data.banner.end_date);
                    bannerAux["groups"] = {
                        groups_availables: [],
                        groups_allowed: [],
                        groups_forbbiden: []
                    }
                    bannerAux["groups"]["groups_availables"] = result.data.groups_availables;
                    bannerAux.banner.groups.forEach(element => {
                        if (element.relationship === 'ALLOWED') {
                            bannerAux["groups"]["groups_allowed"].push(element);
                        } else {
                            bannerAux["groups"]["groups_forbbiden"].push(element);
                        }
                    });
                    setBannerDetails(bannerAux);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            adminClient.getGroups(session, company).then(
                function (result) {
                    let groups = []
                    Object.keys(result.groups).forEach(function (key, index) {
                        groups.push(result.groups[key]);
                    });
                    setBannerDetails(prevState => ({
                        ...prevState,
                        groups: {
                            ...prevState.groups,
                            groups_availables: groups,
                        }
                    }));
                },
                function (err) {
                    console.error(err);
                }
            );
            setAddOrUpdate('add');
        }
    }, []);

    const onChangeHandlerBanner = (e) => {
        const { name, value } = e.target || e;
        setBannerDetails(prevState => ({
            ...prevState,
            banner: {
                ...prevState.banner,
                [name]: value,
            }
        }));
    };

    const onChangeHandlerBannerConfig = (e) => {
        const { name, value } = e.target || e;
        let bannerAux = { ...BannerDetails }
        bannerAux.banner.config[name] = value
        setBannerDetails(bannerAux);
    }

    const onChangeHandlerGroups = (allowed, forbidden) => {
        setBannerDetails(prevState => ({
            ...prevState,
            groups: {
                ...prevState.groups,
                groups_allowed: allowed,
                groups_forbbiden: forbidden
            }
        }));
    }
    const saveDataBanner = (urls) => {
        setBannerDetails((prevState) => {
            let oldState = { ...prevState }
            if (urls.length === 0) oldState.banner.config.image = "";
            else oldState.banner.config.image = urls[0];
            return { ...prevState, oldState }
        });
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            let bannerDetailsAux = { ...BannerDetails };
            let banner = JSON.parse(JSON.stringify(bannerDetailsAux.banner));
            let groups = bannerDetailsAux.groups;
            banner.init_date = utils.unformatDate(
                new Date(banner.init_date)
            );
            banner.end_date = utils.unformatDate(
                new Date(banner.end_date)
            );

            let allowed_groups = [];
            let forbidden_groups = [];

            groups.groups_allowed.forEach(element => {
                allowed_groups.push(element.group);
            });
            groups.groups_forbbiden.forEach(element => {
                forbidden_groups.push(element.group);
            });

            banner.forbidden_groups = forbidden_groups.join();
            banner.allowed_groups = allowed_groups.join();

            adminClient.saveBannerDetails(session, company, JSON.stringify(banner)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        let banner = msg.data.banner;
                        bannerID = banner;
                        utils.setSessionItem("current-banner", banner);
                        props.showAlert(t('Banners details'), t('Saved succesfull'))
                        onChangeHandlerBanner({ name: "banner", value: banner })
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Banners details'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Banners details'), t('Saving error ') + error.description)
                }
            )
        }
    }
    let multimediaRender = () => {
        return (
            <div className="row">
                <File
                    dropEnable={true}
                    showFile={true}
                    showError={true}
                    numFiles={1}
                    viewerEnable={false}
                    deleteEnable={true}
                    saveData={saveDataBanner}
                    fileName={[BannerDetails.banner.config.image]}
                    fileUrlPref={companyDetails.config.cdn_url + "../"}
                    getFileUrl={(data) => {
                        return "/cms/img/banners/" + BannerDetails.banner.banner + "/" + data.fileName;
                    }}
                    type={'BANNER'}
                    showAlert={props.showAlert}
                    owner={JSON.stringify(BannerDetails.banner)}
                    typeFiles={['image', 'video']}
                />
            </div>
        );
    };
    let groupsRender = () => {
        return (
            <div className="row">
                <AllowedForbiddenList
                    allowed={BannerDetails.groups.groups_allowed}
                    availables={BannerDetails.groups.groups_availables}
                    forbidden={BannerDetails.groups.groups_forbbiden}
                    idProperty={"group"}
                    nameProperty={"name"}
                    handleUpdateGroups={onChangeHandlerGroups}
                ></AllowedForbiddenList>
            </div>
        );
    };

    const [tabsContent, setTabsContent] = useState([
        { title: t("Multimedia"), content: multimediaRender },
        { title: t("Groups"), content: groupsRender }
    ]);
    useEffect(() => {
        let tabAux;
        if (addOrUpdate === "update") {
            tabAux = [
                { title: t("Multimedia"), content: multimediaRender },
                { title: t("Groups"), content: groupsRender }
            ];
        }
        else {
            tabAux = [
                { title: t("Groups"), content: groupsRender }
            ];
        }
        setTabsContent(tabAux);
    }, [JSON.stringify(BannerDetails), addOrUpdate]);

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_BannersDetails">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('ID')}
                            name="banner"
                            disabled={true}
                            type="text"
                            value={BannerDetails.banner.banner}
                            onChange={onChangeHandlerBanner}
                        />
                    </div >
                    <div className="col-12 col-md-8">
                        <TextField onChange={onChangeHandlerBanner} name="name" label={t("Name")} type="text" value={BannerDetails.banner.name} />
                    </div>
                    <div className="col-12 ">
                        <TextField onChange={onChangeHandlerBannerConfig} name="url" label={t("Url")} type="text" value={BannerDetails.banner.config.url} />
                    </div>
                    <div className="col-12 col-md-6 ">
                        <DateAndTimePicker
                            label={t("Init Date")}
                            value={BannerDetails.banner.init_date}
                            showTime={true}
                            name="init_date"
                            callBackFunction={(value) => {
                                onChangeHandlerBanner({ target: { name: "init_date", value: value } });
                            }}
                            id={"init_date"}
                        />
                    </div>

                    <div className="col-12 col-md-6 ">
                        <DateAndTimePicker
                            label={t("End Date")}
                            value={BannerDetails.banner.end_date}
                            showTime={true}
                            name="end_date"
                            callBackFunction={(value) => {
                                onChangeHandlerBanner({ target: { name: "end_date", value: value } });
                            }}
                            validations={
                                {
                                    "data-parsley-validate-date-range": "init_date",
                                    "data-parsley-validate-date-range-message":
                                        t("PARSLEY_VALIDATION_DATE_RANGE")
                                }
                            }
                        />
                    </div>

                    <div className="col-12 ">
                        <TextField onChange={onChangeHandlerBannerConfig} name="title" label={t("Title")} type="text" value={BannerDetails.banner.config.title} />
                    </div>
                    <div className="col-12 ">
                        <TextField multiline onChange={onChangeHandlerBannerConfig} name="body" label={t("Body")} type="text" value={BannerDetails.banner.config.body} />
                    </div>
                    <div className="col-12 ">
                        <TextField onChange={onChangeHandlerBannerConfig} label={t("Button text")} name="button_text" type="text" value={BannerDetails.banner.config.button_text} />
                    </div>
                </div>

            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted("SAVE_CMS_BANNERS") &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }
                </div>
            </div>
            <div className="col-12">
                <Tab active={0}>
                    {tabsContent.map((tab, idx) => (
                        <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                            {tab.content()}
                        </Tab.TabPane>
                    ))}
                </Tab>
            </div>

        </LayoutSection >
    )
}
export default withTranslation()(BannersDetails);
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import BasicTable from '../../components/basic/BasicTable';
import AgentsMenu from '../../components/AgentsMenu';
import CustomRenders from '../../context/customRenders';
import useFormValidation from '../../hooks/parsleyValidation';

function AgentsSearch(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [userSearch, setUserSearch] = useState("");
    const [tableData, setTableData] = useState(<></>);

    const { formValidation } = useFormValidation('Form_AgentsSearch')
    const dataHeader = {
        title: t('Agents Search'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        window.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.code === "NumpadEnter") {
                document.getElementById("searchButton").click();
            }
        });

    }, []);
    const onChangeHandler = (e) => {
        const { value } = e.target || e;
        setUserSearch(value);
    };

    const goDetails = (user) => {
        window.open('/agents/subagents/details/' + user, "_blank")
    }

    const usersList = (data) => {

        let renderReports = new CustomRenders()

        const columnsDef = [
            {
                Header: t("Username"), accessor: 'alias', typeCell: 'text', Filter: true,
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.user)}>{renderReports.renderAgentAlias(props.cell.row.original.alias, props.row.original, t, props.column)}</label>
                },
            },
            {
                Header: t("Tokens"), accessor: 'amount', typeCell: 'currency', Filter: true,
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.user)}>{
                        renderReports.renderAgentsPlayersCurrency(props.value, props.row.original, t, props.column)
                    }</label>
                },
            },
            {
                Header: t("Actions"), accessor: 'actionsAmount', typeCell: 'text', Filter: true, extraData: { "finishOperation": search },
                showAlert: props.showAlert, handleCloseAlert: props.handleCloseAlert,
                Cell: (props) => {
                    let rowDataOriginal = props.row.original
                    rowDataOriginal['type'] = 'agent'
                    return renderReports.renderAgentsPlayersOperations(props.value, rowDataOriginal, t, props.column)
                },
            }
        ]

        setTableData(<BasicTable data={data} columns={columnsDef} showFilterColumn={true} />)
    }

    const search = (evt) => {

        if (formValidation.validate()) {
            let searchString = userSearch
            if (userSearch.length > 3) searchString = "%" + userSearch + "%"

            adminClient.agentsAgentsSearch(session, company, searchString).then(
                function (msg) {
                    console.log(" Users  = " + JSON.stringify(msg));
                    usersList(msg.users);

                }, function (err) {
                    console.log("reportPromise error " + err.code);
                    props.showAlert(t("User search"), <p>{t("Error") + t(err.description)}</p>)
                }
            );
        }

    }

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <div className="row">
                    <form onSubmit={(evt) => { evt.preventDefault(); return false; }} id="Form_AgentsSearch">
                        <div className="col-12">


                            <TextField label={t("Enter search term")} type="text" InputProps={{ inputProps: { "data-parsley-required": "true", "minlength": "3" } }} onChange={onChangeHandler} value={userSearch} />
                        </div>
                    </form>
                    <div className="col-12">
                        <button
                            id="searchButton"
                            type="button"
                            className="btn btn-primary"
                            onClick={search}
                        >
                            {t('Search')}
                        </button>
                    </div>
                    <div className='col-12'>
                        {tableData}
                    </div>
                </div>
            </LayoutSection>
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(AgentsSearch);
import React from 'react'
import Report from '../../components/basic/Report';
import { withTranslation } from 'react-i18next';

const Autoexclusions = (props) => {
    const { t } = props

    return (
        <>
            <Report reportId="user_autoexclusions" />
            <Report reportId="user_vertical_autoexclusions" />
        </>

    )
}

export default withTranslation()(Autoexclusions)
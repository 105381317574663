import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";
import utils from "../utils";
import Barcode from 'react-jsbarcode';
import QRCode from "react-qr-code";


class AltenarPrint extends React.Component {

  constructor(props) {

    super(props);
    this.altenarSession = {};
    this.config = { server: {} };

    this.state = { bet: { selections: [] } };


    console.log("AltenarPrint: : constructor " + new Date());


  }

  getParameter(paramName) {
    var searchString = window.location.search.substring(1),
      i, val, params = searchString.split("&");

    for (i = 0; i < params.length; i++) {
      val = params[i].split("=");
      if (val[0] == paramName) {
        return val[1];
      }
    }
    return null;
  }

  componentDidMount() {


    var adminClient = new AdminClient();
    var thisAux = this;
    var game = this.getParameter("game");

    var pendingBetPromise = adminClient.getLastBet(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), game);

    pendingBetPromise.then(
      function (response) {
        console.log("AltenarPrint: pendingBetPromise " + JSON.stringify(response.bet));
        thisAux.setState({ bet: response.bet });
      }, function (err) {
        console.error("AltenarPrint: providerPromise " + err.description);
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className={"container calimaco_ticket_print_container calimaco_ticket_print_copy_" + (this.state.bet.printed_times > 0)} >
        {utils.getCurrentCompany().config.ticket_css &&
          <link rel="stylesheet" href={utils.getCurrentCompany().config.ticket_css} />
        }
        {utils.getCurrentCompany().config.retail && utils.getCurrentCompany().config.retail.ticket_css &&
          <link rel="stylesheet" href={utils.getCurrentCompany().config.retail.ticket_css} />
        }

        <div className="calimaco_ticket_print_container_logo" ></div>
        <div className="row calimaco_ticket_print_created_date">
          <span className="title">{t("Ticket created date")}</span> <span className="value">{utils.formatDate(this.state.bet.created_date, 'DD/MM/YYYY HH:mm')}</span>
        </div>
        <div className="row calimaco_ticket_print_game">
          <span className="title">{t("Ticket game")}</span> <span className="value">{this.state.bet.game}</span>
        </div>
        <div className="row calimaco_ticket_print_type">
          <span className="title">{t("Ticket type")}</span> <span className="value">{this.state.bet.total_selections === 1 ? t('BET_TYPE_SIMPLE') : this.state.bet.is_systen ? t('BET_TYPE_SYSTEM') : t('BET_TYPE_MULTIPLE')}</span>
        </div>
        <div className="row calimaco_ticket_print_creator">
          <span className="title">{t("Ticket created by")}</span> <span className="value"> {JSON.parse(utils.getSessionItem("user")).alias}</span>
        </div>
        <div className="row calimaco_ticket_print_currency">
          <span className="title">{t("Ticket currency")}</span> <span className="value"> {this.state.bet.currency}</span>
        </div>
        <div className="row calimaco_ticket_print_wager" >
          <span className="title">{t("Ticket wager")}</span> <span className="value"> {utils.formatCurrency(this.state.bet.wager / 100, this.state.bet.currency)}</span>
        </div>
        <div className="row  calimaco_ticket_print_odds">
          <span className="title">{t("Ticket odds")}</span> <span className="value"> {(this.state.bet.odds || 0).toFixed(2)}</span>
        </div>
        <div className="row  calimaco_ticket_print_prize">
          <span className="title">{t("Ticket total prize")}</span> <span className="value"> {utils.formatCurrency(this.state.bet.total_prize / 100, this.state.bet.currency)}</span>
        </div>
        <div className="row  calimaco_ticket_disclaimer" dangerouslySetInnerHTML={{ __html: t("Ticket disclaimer") }}>

        </div>
        <div className="row  calimaco_ticket_selections">

          {this.state.bet.selections.map((selection, index) => {
            return (
              <div className="row calimaco_ticket_selection">
                <div className="row calimaco_ticket_selection_championship">
                  <span className="title">{t("Ticket selection championship_name")}</span> <span className="value">{selection.championship_name}</span>
                </div>
                <div className="row calimaco_ticket_selection_event">
                  <span className="title">{t("Ticket selection event_name")}</span> <span className="value"> {selection.event_name}</span>
                </div>
                <div className="row calimaco_ticket_selection_market">
                  <span className="title">{t("Ticket selection market_name")}</span> <span className="value"> {selection.market_name}</span>
                </div>
                <div className="row calimaco_ticket_selection_selection">
                  <span className="title">{t("Ticket selection selection")}</span> <span className="value">{selection.selection}</span>
                </div>
                <div className="row calimaco_ticket_selection_odds">
                  <span className="title">{t("Ticket selection odds")}</span> <span className="value"> {((Math.floor(selection.odds * 100) / 100) || 0).toFixed(2)}</span>
                </div>
                <div className="row calimaco_ticket_selection_event_date">
                  <span className="title">{t("Ticket selection event_date")}</span> <span className="value">{utils.formatDate(selection.event_date, 'DD/MM/YYYY HH:mm')}</span>
                </div>
              </div>

            )

          })
          }
        </div>
        {this.state.bet.code && !this.state.bet.code.length <= 12 &&
          <div className="row calimaco_ticket_barcode">
            <Barcode value={this.state.bet.code} />
          </div>
        }
        {this.state.bet.code && this.state.bet.code.length > 12 &&
          <div className="row calimaco_ticket_barcode">
            <QRCode value={this.state.bet.code} />
          </div>
        }


      </div>
    )
  }
}



export default withTranslation()(withRouter(AltenarPrint))

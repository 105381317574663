import React from 'react'
import { withTranslation } from "react-i18next";
import { FaAngleRight, FaAngleLeft, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { Box } from "@mui/material";


function Pagination(props) {

  const handlePaginationChange = () => {
    if (props.handlePaginationChange !== undefined) props.handlePaginationChange()
  }
  const showPagination = (props.pageCount > 0) ? true : false

  const sizePageOptions = props.sizePageOptions || [10, 50, 100, 200, 400]
  const { t } = props
  return (
    <>
      {showPagination &&
        <Box display="flex" flexWrap="wrap" p={1} alignItems="center" width="100%" justifyContent="flex-end">
          {props.showTotalRegisters &&
            <Box display="flex" alignItems="center" flexDirection="row">
              <span style={{ paddingLeft: 3, paddingRight: 3 }}> {t("Results") + ": "}  {props.totalRegisters} </span>
            </Box>}
          {props.showPageSize && (props.canPreviousPage || props.canNextPage) && <Box display="flex" alignItems="center" flexDirection="row">
            <span style={{ paddingLeft: 3, paddingRight: 3 }}> {" " + t("Rows per page") + " "}</span>
            <select
              style={{ width: '100px' }}
              className="form-control form-select-border-radius"
              value={props.pageSize}
              onChange={e => {
                props.setPageSize(Number(e.target.value))
              }}
            >

              {sizePageOptions.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </Box>}
          {(props.canPreviousPage || props.canNextPage) &&
            <Box display="flex" flexDirection="row" alignItems="center">
              {props.showInfoPage && <IconButton onClick={() => {
                props.gotoPage(0)
                handlePaginationChange()
              }}
                disabled={!props.canPreviousPage}>{<FaAngleDoubleLeft />}</IconButton>}
              <IconButton onClick={() => props.previousPage()} disabled={!props.canPreviousPage}>{<FaAngleLeft />}</IconButton>
              <IconButton onClick={() => props.nextPage()} disabled={!props.canNextPage}>{<FaAngleRight />}</IconButton>
              {props.showInfoPage && <IconButton onClick={() => props.gotoPage(props.pageCount - 1)} disabled={!props.canNextPage}>{<FaAngleDoubleRight />}</IconButton>}
            </Box>}
          {props.showInfoPage &&
            <Box display="flex" alignItems="center" flexDirection="row">
              <span style={{ paddingLeft: 3, paddingRight: 3 }}> {t("Page") + " "}<strong>{props.pageIndex + 1} {t("of")} {props.pageCount}</strong></span>
              {props.showGoToPage && <>
                <span style={{ paddingLeft: 3, paddingRight: 3 }}> | {t("Go to Page")}:</span>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={props.pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    props.gotoPage(page)
                    handlePaginationChange()
                  }}
                  min={1}
                  max={props.pageCount}
                  style={{ width: '100px' }}
                />
              </>}
            </Box>}

        </Box>}
    </>
  )
}

export default withTranslation()(Pagination)
import React from "react"


class TreeAdminUsers extends React.Component {

  render() {
    console.log(" this.props.users = "+JSON.stringify(this.props.users));
    var tabs=this.props.tabs||0;
    return (
      
      <>{Object.keys(this.props.users).map((el, index) => {
        return (
        <div className={this.props.selectedItem===this.props.users[el].user?'tree_admin_selected':'tree_admin_unselected'} key={"user-"+this.props.users[el].user}><a role="button" onClick={()=>this.props.onChange(JSON.stringify(this.props.users[el]))}>{this.props.users[el].alias}</a>
          <div className="tab" key={"user-tab-"+this.props.users[el].user}>
            <TreeAdminUsers selectedItem={this.props.selectedItem} onChange={this.props.onChange} tabs={tabs} users={this.props.users[el].children}></TreeAdminUsers>
            </div>
          </div>
       )
      })}
      </>
    )
  }

}
export default TreeAdminUsers


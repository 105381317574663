import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import DocButton from '../../components/basic/DocButton';
function VirtualGoodDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_VirtualGoodDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const virtual_good = utils.getSessionItem('current-virtual_good')
    const [virtualGood, setVirtualGood] = useState({
        virtual_good: -1,
        name: "",
        status: "",
        description: "",
        company: company
    });
    const goBack = () => {
        props.history.push('/reports/report/virtual_goods/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('VirtualGood details') + '',
        idDoc: props.idDoc
    };
    useEffect(function () {
        if (virtual_good !== undefined && virtual_good !== '-1') {
            adminClient.getVirtualGoodDetails(session, company, virtual_good).then(
                async function (result) {
                    setVirtualGood(result.virtual_good);
                    setAddOrUpdate('update');
                },
                function (err) {
                    console.error(err);
                }
            );
        } else {
            setAddOrUpdate('add');
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setVirtualGood((prevState) => ({ ...prevState, [name]: value }));
    };

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient.saveVirtualGoodDetails(
                session,
                JSON.stringify(virtualGood)
            ).then(
                (msg) => {
                    props.showAlert(t('VirtualGood details'), t('Saved succesfull'))
                    if (addOrUpdate === 'add') {
                        setAddOrUpdate('update')
                        setVirtualGood(msg.virtual_good)
                    }
                },
                (error) => {
                    props.showAlert(t('VirtualGood details'), t('Saving error ') + error.description)
                }
            )
        }
    }

    const remove = (evt) => {
        adminClient.deleteVirtualGoodDetails(
            session,
            company,
            JSON.stringify(virtual_good)
        ).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('VirtualGood details'), t('Deleted succesfull'))
                    goBack()
                } else {
                    props.showAlert(t('VirtualGood details'), t('Deleting error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('VirtualGood details'), t('Deleting error ') + error.description)
            }
        )
    }
    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_VirtualGoodDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="virtual_good"
                            required
                            type="text"
                            disabled
                            value={virtualGood.virtual_good}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('Name')}
                            name="name"
                            required
                            type="text"
                            value={virtualGood.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12  col-md-3  ">
                        <BasicSelect
                            idProperty="name"
                            name="status"
                            label={t("Status")}
                            basicInfoName="EnabledDisabled"
                            onChange={onChangeHandler}
                            value={virtualGood.status}
                            translate={{ property: "name", prefix: "" }}
                            disableClearable
                            required
                        />
                    </div>
                    <div className="col-12 ">
                        <TextField multiline
                            label={t("Description")}
                            name="description"
                            type="text"
                            value={virtualGood.description}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </form>
            <div className="row">
                {utils.isGranted('SAVE_VIRTUAL_GOODS') &&

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                        {/* <button
                        type="button"
                        className="btn btn-primary"
                        onClick={remove}
                    >
                        {t('Delete')}
                    </button> */}
                    </div>
                }
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(VirtualGoodDetails);